import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    FieldsetFormGroup,
    FloatingLabelInput,
    ShowErrorMessages,
    useSelector,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { salesSelectors } from "../../../store/reducers/salesReducer"
import { updateSalesCheckoutPageLinkThunk } from "../../../store/thunks/salesSettingsThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IUpdateSalesCheckoutPageLinkFormModel } from "../../../type/sales/IUpdateSalesCheckoutPageLinkFormModel"
import SubmitButton from "../../buttons/SubmitButton"
import ContentRightButtonContainer from "../../layouts/ContentRightButtonContainer"
import CheckoutScriptClipboardField from "./SalesCheckoutScriptCopyField"

interface ISalesCheckoutPageLinkFormProps {}

const SalesCheckoutPageLinkForm = ({}: ISalesCheckoutPageLinkFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const { handleSubmit, formState, register } = useForm<IUpdateSalesCheckoutPageLinkFormModel>()

    const settings = useSelector(salesSelectors.settings)

    const onSubmit: SubmitHandler<IUpdateSalesCheckoutPageLinkFormModel> = async (data, e) => {
        try {
            await dispatch(updateSalesCheckoutPageLinkThunk(data))
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ContentRightButtonContainer button={<SubmitButton isSmall formState={formState} title={t("common.save")} />}>
                    <FieldsetFormGroup field>
                        <FloatingLabelInput
                            defaultValue={settings ? settings.checkoutPageLink : ""}
                            name="CheckoutPageLink"
                            innerRef={register(
                                valFuncRequiredAndPattern(
                                    validationPatterns.salesCheckoutPageLink,
                                    t("offersettings.validationerrors.checkoutPageLink")
                                )
                            )}
                            placeholder={t("offersettings.checkoutPageLink.inputPlaceholder")}
                        />
                    </FieldsetFormGroup>
                </ContentRightButtonContainer>
                <ShowErrorMessages formState={formState} />
            </Form>
            <CheckoutScriptClipboardField
                value="<div id='root'></div><script src='https://test-sms.swiipe.com/asset/apply'></script>"
                title={t("checkoutscript.title")}
            />
        </>
    )
}

export default SalesCheckoutPageLinkForm
