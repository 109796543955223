import $ from "jquery"
import { EInvoicingMarket, TEnv } from "swiipe.portal.shared"
import { ETermsType } from "../store/reducers/legalReducer"
import { addModalThunk } from "../store/thunks/modalThunks"
import { ELegalRelationType, ITermsAcceptanceStatusDto } from "../type/ILegalData"
import { TUserRelation } from "../type/TUserRelation"

export function getLinkForTerms(
    termsType: ETermsType,
    userRelationType: TUserRelation,
    relationId?: string,
    fallbackMarket?: EInvoicingMarket
) {
    return `/terms?termsType=${termsType}&relationType=${userRelationType}${relationId ? "&relationId=" + relationId : ""}${
        fallbackMarket ? "&market=" + fallbackMarket : ""
    }`
}

export function handleLegalAnchorsInModal() {
    const navigators = $("#legal-modal a")
    for (let i = 0; i < navigators.length; ++i) {
        const link = navigators[i] as HTMLLinkElement
        link.onclick = (event) => {
            event.preventDefault()
            event.stopPropagation()

            const element = event.target as HTMLElement
            const href = element.getAttribute("href")
            if (!href?.startsWith("#")) {
                return
            }
            const target = $("#legal-modal " + href)
            if (target.length === 0) {
                return
            }

            const top = target[0].offsetTop
            const modal = $("#legal-modal")[0]
            if (!modal || !modal.parentElement) {
                return
            }
            modal.parentElement.scrollTo(0, top)
        }
    }
}

export function handleLegalPopupsInModal(
    dispatch: any,
    relationId: string | undefined,
    relationType: TUserRelation,
    fallbackMarket: EInvoicingMarket | undefined
) {
    const legalPopups = $("#legal-modal a[termspopup]")
    for (let i = 0; i < legalPopups.length; i++) {
        const legalPopup = legalPopups[i]
        legalPopup.onclick = (event) => {
            event.preventDefault()
            event.stopPropagation()

            const element = event.target as HTMLElement
            const textType = element.getAttribute("termspopup") ?? ""
            const userRelationType = (element.getAttribute("termspopup-relation-type") ?? relationType) as TUserRelation

            if (!textType) {
                return
            }

            dispatch(
                addModalThunk({
                    type: "terms",
                    userRelationType: userRelationType,
                    relationId: userRelationType === relationType ? relationId : undefined,
                    termsType: textType as ETermsType,
                    fallbackMarket,
                })
            )

            const modal = $("#legal-modal")[0]
            if (!modal || !modal.parentElement) {
                return
            }
            modal.parentElement.scrollTo({
                top: modal.offsetTop,
                behavior: "smooth",
            })
        }
    }
}

export const mapUserRelationToLegalRelation = (userRelationType: TUserRelation): ELegalRelationType => {
    if (userRelationType === "CallCenter") {
        throw "CallCenter not supported for terms"
    }
    return userRelationType
}

export function getTermsTypesForUserRelationType(
    userRelationType: TUserRelation,
    env: TEnv
): { terms: ETermsType; privacy: ETermsType } {
    switch (userRelationType) {
        case "User":
            return { terms: "TermsUser", privacy: "PrivacyPolicyShared" }
        case "Merchant":
            return { terms: "TermsMerchant", privacy: "PrivacyPolicyShared" }
        case "Partner":
            return { terms: "TermsPartner", privacy: "PrivacyPolicyShared" }
        default:
            throw "Terms not supported for user relation type"
    }
}

export function isAllTermsAccepted(terms: ETermsType[], termsStatuses: ITermsAcceptanceStatusDto[]) {
    return !terms.find((termsType) => !isTermAccepted(termsType, termsStatuses))
}

export function isTermAccepted(term: ETermsType, termsStatuses: ITermsAcceptanceStatusDto[]) {
    return !!termsStatuses.find((ts) => term === ts.termsType && ts.isAnyAccepted && !ts.isConsentRequired)
}
