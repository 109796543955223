import React from "react"
import { Control } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FieldsetFormGroup, TClearError, TSetError } from "swiipe.portal.shared"
import { IClearhausUpdateInfoForm } from "../../../../type/clearhaus/IClearhausUpdateInfoForm"
import FileInput, { megabyteInBytes } from "../../input/FileInput"
import "./ClearhausUpdateDocumentsFields.scss"

interface IClearhausUpdateDocumentsFieldsProps {
    control: Control<IClearhausUpdateInfoForm>
    setError: TSetError<any>
    clearErrors: TClearError<any>
}

const ClearhausUpdateDocumentsFields = ({ control, setError, clearErrors }: IClearhausUpdateDocumentsFieldsProps) => {
    const { t } = useTranslation()

    return (
        <div className="clearhaus-update-documents-fields">
            <div className="clearhaus-update-documents-fields__description">
                <p>{t("clearhausUpdateInfo.texts.documentation.pleaseUpload")}</p>
                <p className="font-italic">{t("clearhausUpdateInfo.texts.documentation.forExample")}</p>
            </div>
            <FieldsetFormGroup>
                <FileInput
                    name="confirmationDocument"
                    accept="image/*"
                    minFilesAmount={1}
                    maxFilesAmount={1}
                    maxFileSizeBytes={megabyteInBytes * 10}
                    triggerValidation={control.trigger}
                    setError={setError}
                    clearError={clearErrors}
                    setValue={control.setValue}
                    getValues={control.getValues}
                    register={control.register}
                    requiredErrorMessage={t("clearhausUpdateInfo.errors.confirmationDocument")}
                    multiple
                />
            </FieldsetFormGroup>
        </div>
    )
}

export default ClearhausUpdateDocumentsFields
