import React from "react"
import { useTranslation } from "react-i18next"
import { DropDownList, IDropdownOption } from "swiipe.portal.shared"
import IconOrderAscBlue from "../../images/icons/sorting/icon-order-asc-blue.svg"
import IconOrderAscGray from "../../images/icons/sorting/icon-order-asc.svg"
import IconOrderDescBlue from "../../images/icons/sorting/icon-order-desc-blue.svg"
import IconOrderDescGray from "../../images/icons/sorting/icon-order-desc.svg"
import "./SortDropdownBox.scss"

interface ISortDropdownBox<T> {
    title: string
    options: IDropdownOption<T>[]
    defaultValue?: any
    isDirectionDesc: boolean
    onChangeDropdown: (e: React.ChangeEvent<HTMLInputElement>, value: T) => void
    onSwitchSortDirection: (sortOrder: "desc" | "asc") => void
}

export const SortDropdownBox = <T extends string | number>({
    title,
    options,
    defaultValue,
    isDirectionDesc,
    onChangeDropdown,
    onSwitchSortDirection,
}: ISortDropdownBox<T>) => {
    const { t } = useTranslation()

    return (
        <div id="sort-dropdown-box">
            <span>{title}</span>
            <DropDownList<T>
                options={options}
                defaultValue={defaultValue}
                onChangeDropDown={(e, value: T) => {
                    onChangeDropdown(e, value)
                }}
            />
            <img
                src={isDirectionDesc ? IconOrderDescBlue : IconOrderDescGray}
                title={t("table.sorting.descending")}
                className="sort-dropdown-box__icon desc"
                onClick={() => {
                    onSwitchSortDirection("desc")
                }}
            />
            <img
                src={isDirectionDesc ? IconOrderAscGray : IconOrderAscBlue}
                title={t("table.sorting.ascending")}
                className="sort-dropdown-box__icon asc"
                onClick={() => {
                    onSwitchSortDirection("asc")
                }}
            />
        </div>
    )
}
