import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form, ModalBody, Row } from "reactstrap"
import {
    BaseModal,
    FieldsetFormGroup,
    FloatingLabelInput,
    IModalBase,
    IModalControlProps,
    ModalFooter,
    StandardButtonWithSpinner,
} from "swiipe.portal.shared"
import { reportActionFailedThunk } from "../../store/thunks/transactionThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { IReportOrderActionData } from "../../type/IReportOrderActionData"
import "./ModalTransactionActionError.scss"

export interface IModalTransactionActionError extends IModalBase {
    type: "transactionActionError"
    errorMessage: string
    action: string
    reportData: IReportOrderActionData
}

interface IFormModel {
    comment: string
}

const ModalTransactionActionError = ({
    title,
    errorMessage,
    action,
    reportData,
    callback,
    ...otherProps
}: IModalTransactionActionError & IModalControlProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const { handleSubmit, register } = useForm<IFormModel>()
    const [showForm, setShowForm] = useState(false)

    const onSubmit: SubmitHandler<IFormModel> = async (data, e) => {
        try {
            await dispatch(reportActionFailedThunk(action, data.comment, reportData))

            callback("accepted")
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <BaseModal
            {...otherProps}
            modalClassName="tx-action-error-modal"
            callback={callback}
            centered
            title={title || t("common.error_title")}
        >
            <ModalBody>
                {showForm ? (
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <FieldsetFormGroup field>
                            <FloatingLabelInput
                                name="comment"
                                type="textarea"
                                textareaNoAutoGrow
                                rows={3}
                                innerRef={register}
                                placeholder={t("transactions.actionErrorModal.provideInfo")}
                            />
                        </FieldsetFormGroup>
                    </Form>
                ) : (
                    <>
                        <div>
                            <p className="mb-0 text-center errormessage-text">{errorMessage}</p>
                        </div>
                        <div className="report-btn" onClick={() => setShowForm(true)}>
                            {t("transactions.actionErrorModal.reportIssue")}
                        </div>
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <Row className="justify-content-between">
                    <div />
                    <StandardButtonWithSpinner
                        onClick={async () => {
                            if (!showForm) {
                                callback("accepted")
                                return
                            }

                            await handleSubmit(onSubmit)()
                        }}
                        width={100}
                        className="mr-2"
                    >
                        {showForm ? t("contactform.send") : t("common.close")}
                    </StandardButtonWithSpinner>
                </Row>
            </ModalFooter>
        </BaseModal>
    )
}

export default ModalTransactionActionError
