import React from "react"

interface IPageSectionProps {
    children?: React.ReactNode
}

const PageSection = ({ children }: IPageSectionProps) => {
    return (
        <>
            <div className="top-separator"></div>
            {children}
        </>
    )
}

export default PageSection
