import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { ICompanyData, companySearchSelectors, requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { TOrganizationType } from "../../type/TOrganizationType"
import { StoreState } from "../StoreState"
import { fetchMerchantDataThunk } from "./merchantThunks"

export const getSelectedCompanyDetailsFromSearch =
    (companyNumber: string): ThunkAction<Promise<ICompanyData | undefined>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const companyDetails = companySearchSelectors.selected(getState())

        if (companyDetails && companyNumber === companyDetails.companyNumber.toString()) {
            return {
                companyType: companyDetails.companyType,
                companyPhone: companyDetails.companyPhone,
                companyCountry: companyDetails.companyCountry,
                companyEmail: companyDetails.companyEmail,
                companyAddress: companyDetails.companyAddress,
                companyPostcode: companyDetails.companyPostcode,
                companyCity: companyDetails.companyCity,
            } as ICompanyData
        }

        return undefined
    }

export const setOrganizationDeletedThunk =
    (
        organizationId: string,
        organizationType: TOrganizationType,
        deleted: boolean,
        isExpedite?: boolean,
        shouldRevive?: boolean
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk<ICompanyData>(endpoints.Identity.setOrganizationDeleted, {
                data: {
                    organizationId,
                    organizationType,
                    deleted,
                    isExpedite,
                    revivalOptions:
                        !deleted && shouldRevive
                            ? {
                                  setSwPlan: "None",
                                  resetToTrialDays: 90,
                              }
                            : undefined,
                },
            })
        )

        switch (organizationType) {
            case TOrganizationType.SwMerchant: {
                await dispatch(fetchMerchantDataThunk(true, true))
                break
            }
            case TOrganizationType.Partner:
                //not implemented
                break
            case TOrganizationType.CallCenter:
                //not implemented
                break
        }
    }

export const requestOrganizationDeletion =
    (organizationId: string, organizationType: TOrganizationType): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk<ICompanyData>(endpoints.Identity.requestOrganizationDeletion, {
                data: {
                    organizationId,
                    organizationType,
                },
            })
        )
    }
