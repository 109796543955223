import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { IPaymentMethod, addQueryParamsToUrl, delay, getLanguage, requestThunk } from "swiipe.portal.shared"
import { navigationService } from "../../services/navigationService"
import { mapUserRelationTypeToOrganizationType } from "../../services/userRelationService"
import { TransactionStatus } from "../../type/Transaction"
import { IPaymentMethodRelation, TPaymentMethodRelation } from "../../type/paymentMethod/IPaymentMethodRelation"
import { endpoints } from "./../../data/endpoints"
import { IUserRelationFlattened } from "./../../type/IUserRelation"
import { IAddPaymentMethod } from "./../../type/paymentMethod/IAddPaymentMethod"
import { StoreState } from "./../StoreState"
import { paymentMethodReducerActions, paymentMethodSelectors } from "./../reducers/paymentMethodReducer"
import { userRelationSelectors } from "./../reducers/userRelationReducer"

export const fetchRelatedPaymentMethodsThunk =
    (relationType: TPaymentMethodRelation, force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType === "User") {
            return
        }

        if (!force && paymentMethodSelectors.paymentMethodRelations(getState(), relationType)) {
            // Payment methods already fetched
            return
        }

        const resp = await dispatch(
            requestThunk<{
                paymentMethods: IPaymentMethod[]
                primaryPaymentMethodId: string
            }>(endpoints.Payments.getPaymentMethodRelations, {
                params: {
                    organizationId: currentUserRelation.id,
                    organizationType: mapUserRelationTypeToOrganizationType(currentUserRelation.relationType),
                    paymentMethodRelationType: relationType,
                },
            })
        )

        const relatedPaymentMethods: IPaymentMethodRelation[] = resp.paymentMethods.map((pm) => {
            const rel: IPaymentMethodRelation = {
                ...pm,
                isPrimary: pm.paymentId === resp.primaryPaymentMethodId,
            }
            return rel
        })

        dispatch(
            paymentMethodReducerActions.setPaymentMethodRelations(currentUserRelation.id, relationType, relatedPaymentMethods)
        )
    }

export const setPrimaryPaymentMethodRelationThunk =
    (relationType: TPaymentMethodRelation, paymentMethodId: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType === "User") {
            return
        }

        await dispatch(
            requestThunk(endpoints.Payments.setPrimaryPaymentMethodRelation, {
                data: {
                    paymentMethodId: paymentMethodId,
                    organizationId: currentUserRelation.id,
                    organizationType: mapUserRelationTypeToOrganizationType(currentUserRelation.relationType),
                    paymentMethodRelationType: relationType,
                },
            })
        )

        await dispatch(fetchRelatedPaymentMethodsThunk(relationType, true))
    }

export const deletePaymentMethodRelationThunk =
    (relationType: TPaymentMethodRelation, paymentMethodId: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType === "User") {
            return
        }

        await dispatch(
            requestThunk(endpoints.Payments.deletePaymentMethodRelation, {
                data: {
                    paymentMethodId: paymentMethodId,
                    organizationId: currentUserRelation.id,
                    organizationType: mapUserRelationTypeToOrganizationType(currentUserRelation.relationType),
                    paymentMethodRelationType: relationType,
                },
            })
        )

        await dispatch(fetchRelatedPaymentMethodsThunk(relationType, true))
    }

export const initiateAddPaymentMethodThunk =
    (
        paymentMethod: IAddPaymentMethod,
        relationType: TPaymentMethodRelation | undefined // undefined means for user
    ): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation) {
            return
        }
        if (currentUserRelation.relationType === "User" && relationType === "Billing") {
            throw "Billing flow for User type not supported"
        }
        if (currentUserRelation.relationType !== "User" && !relationType) {
            throw "Add card flow for Organization not supported"
        }

        const currentBase = window.location.href.split("?")[0].split("#")[0]
        const successUrl = addQueryParamsToUrl(currentBase, {
            status: "success",
            relationType: relationType,
        })
        const failureUrl = addQueryParamsToUrl(currentBase, {
            status: "failure",
            relationType: relationType,
        })
        const cancelUrl = addQueryParamsToUrl(currentBase, {
            status: "cancel",
            relationType: relationType,
        })

        const resp = await dispatch(
            requestThunk<{
                orderId: string
                requiresRedirect: boolean
                redirectHandlerUrl: string
                transactionId: string
                status: TransactionStatus
                errorMessage?: string
                errorForUser?: string
                reason: string
            }>(endpoints.Payments.initiateAddPaymentMethodFlow, {
                data: {
                    paymentMethodRelationType: relationType,
                    paymentMethod: paymentMethod,
                    organizationId: currentUserRelation.id,
                    organizationType: mapUserRelationTypeToOrganizationType(currentUserRelation.relationType),
                    successRedirectUrl: successUrl,
                    failureRedirectUrl: failureUrl,
                    cancelRedirectUrl: cancelUrl,
                    language: getLanguage(),
                },
            })
        )

        if (resp.status === TransactionStatus.Success) {
            navigationService.navigateSamePageChangeParams(
                {
                    status: "success",
                    orderId: resp.orderId,
                },
                "keepExistingParams"
            )
            return
        }
        if (resp.status === TransactionStatus.Pending) {
            if (resp.requiresRedirect && resp.redirectHandlerUrl) {
                window.location.href = resp.redirectHandlerUrl
                return
            }
            // Polling for tx status not yet implemented
            navigationService.navigateSamePageChangeParams(
                {
                    status: "failure",
                },
                "keepExistingParams"
            )
            return
        }
        if (resp.status === TransactionStatus.Cancelled) {
            navigationService.navigateSamePageChangeParams(
                {
                    status: "cancel",
                },
                "keepExistingParams"
            )
            return
        }
        navigationService.navigateSamePageChangeParams(
            {
                status: "failure",
                errorForUser: resp.errorForUser ? resp.errorForUser : undefined,
            },
            "keepExistingParams"
        )
    }

export const completeAddPaymentMethodThunk =
    (
        orderId: string,
        relationType: TPaymentMethodRelation | undefined // undefined means for user
    ): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation) {
            return
        }
        if (currentUserRelation.relationType === "User" || !relationType) {
            // Nothing to do for a user
            return
        }

        async function tryToCompleteFlow(userRelation: IUserRelationFlattened) {
            const resp = await dispatch(
                requestThunk<{
                    paymentMethodWasReady: boolean
                }>(endpoints.Payments.completeAddPaymentMethodFlow, {
                    data: {
                        paymentMethodRelationType: relationType,
                        orderId: orderId,
                        organizationId: userRelation.id,
                        organizationType: mapUserRelationTypeToOrganizationType(userRelation.relationType),
                    },
                })
            )
            return resp.paymentMethodWasReady
        }

        const retries = 10
        for (let i = 0; i < retries; i++) {
            const success = await tryToCompleteFlow(currentUserRelation)
            if (success) {
                await dispatch(fetchRelatedPaymentMethodsThunk(relationType, true))
                return true
            }
            if (i < retries - 1) {
                await delay(1000)
            }
        }

        // Payment method was never created
        return false
    }
