import React, { useState } from "react"
import cn from "classnames"
import "./ListOfMessagesSection.scss"

interface IListOfMessagesSection {
    header: string
    showMoreText: string
    messages: string[]
    showBeforeExpanded?: number
    styleError?: boolean
    className?: string
}

const ListOfMessagesSection = ({
    header,
    showMoreText,
    messages,
    showBeforeExpanded,
    styleError,
    className,
}: IListOfMessagesSection) => {
    const [expanded, setExpanded] = useState(false)

    if (!messages.length) {
        return null
    }

    return (
        <div className={cn("list-of-messages text-center", className, { "style-error": styleError })}>
            <p className="mb-0">{header}</p>
            {showBeforeExpanded &&
                messages.slice(0, showBeforeExpanded).map((m) => (
                    <p key={m} className="message">
                        {m}
                    </p>
                ))}
            {!expanded && messages.length > (showBeforeExpanded ?? 0) && (
                <span onClick={() => setExpanded(true)}>{showMoreText}</span>
            )}
            {expanded &&
                messages.slice(showBeforeExpanded ?? 0, messages.length).map((m) => (
                    <p key={m} className="message">
                        {m}
                    </p>
                ))}
        </div>
    )
}

export default ListOfMessagesSection
