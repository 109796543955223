import React, { useState } from "react"
import "./ApplePayOnboardingStepsContainer.scss"

interface IApplePayOnboardingStepsContainerProps {
    children: React.ReactNode
}

const ApplePayOnboardingStepsContainer = ({ children }: IApplePayOnboardingStepsContainerProps) => {
    return <div className="applepay-onboarding-steps">{children}</div>
}

export default ApplePayOnboardingStepsContainer
