import React from "react"
import cn from "classnames"

interface IMessageBoxProps {
    title: string
    titleInBody?: boolean
    id?: string
    centerContent?: boolean
    contentClass?: string
    smallTopBottomPadding?: boolean
    children?: React.ReactNode
}

const MessageBox = ({
    title,
    titleInBody,
    smallTopBottomPadding,
    id,
    centerContent,
    contentClass,
    children,
}: IMessageBoxProps) => {
    return (
        <div id={id} className="message-box">
            {!titleInBody && (
                <div className="message-box-head">
                    <p>{title}</p>
                </div>
            )}
            <div
                className={cn(
                    "message-box-body",
                    smallTopBottomPadding && "pt-3 pb-md-3 pb-1",
                    { center: centerContent },
                    contentClass
                )}
            >
                {titleInBody && (
                    <p>
                        <strong>{title}</strong>
                    </p>
                )}
                {children}
            </div>
        </div>
    )
}

export default MessageBox
