import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { PageContainer, PageWrapper, SearchBox, useSelector } from "swiipe.portal.shared"
import { filterRelationsBySearchValue } from "../../services/userRelationService"
import { partnerSelectors } from "../../store/reducers/partnerReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import UserRelationInfo from "./../../components/user/UserRelationInfo"

interface IManageUserRelationsPageProps {}

const ManageUserRelationsPage = ({}: IManageUserRelationsPageProps) => {
    const { t } = useTranslation()
    const [searchValue, setSearchValue] = useState("")

    const hasMasterPartner = useSelector(partnerSelectors.partnersContainMasterPartner)
    const userRelations = useSelector(userRelationSelectors.userRelationsFlattened)!
    const userRelationsSearchData = useSelector(userRelationSelectors.getRelationSearchData)

    const userRelationsFiltered = hasMasterPartner ? userRelations : userRelations.filter((r) => !r.markedDeletion)

    const userRelationsToShow = !searchValue
        ? userRelationsFiltered
        : filterRelationsBySearchValue(searchValue, userRelationsFiltered, userRelationsSearchData)

    const partners = userRelations.filter((ur) => ur.relationType === "Partner")

    return (
        <PageWrapper>
            <PageContainer transparent title={t("organizationUsers.manageuserRelations")}>
                <SearchBox
                    currentValue={searchValue}
                    placeholder={t("switchaccountspage.searchfield")}
                    onValueChange={(val) => {
                        setSearchValue(val)
                    }}
                    delayOnChangeMs={700}
                />
                {userRelationsToShow.map((rel) => {
                    return (
                        <UserRelationInfo
                            key={rel.id + (rel.parentId || "")}
                            userRelation={rel}
                            parentUserRelation={rel.parentId ? partners.find((p) => p.id === rel.parentId) : undefined}
                        />
                    )
                })}
            </PageContainer>
        </PageWrapper>
    )
}

export default ManageUserRelationsPage
