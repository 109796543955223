import cn from "classnames"
import React, { useState } from "react"
import useDimensions from "react-cool-dimensions"
import { getFormattedNumber, sortDescending } from "swiipe.portal.shared"
import MerchantDashboardCellContainer from "../MerchantDashboardCellContainer"
import MerchantDashboardCellTitle from "../MerchantDashboardCellTitle"
import MerchantDashboardCircleDiagram, { IMerchantDashboardCircleDiagramValue } from "../MerchantDashboardCircleDiagram"
import MerchantDashboardCompareDiff from "../MerchantDashboardCompareDiff"
import "./MerchantDashboardTileCircleWithList.scss"
import MerchantDashboardNoData from "./MerchantDashboardTileNoData"

interface IMerchantDashboardTileCircleWithListProps {
    title: string
    subtitle: string
    values?: { title: string; value: number }[]
    compareValues?: { title: string; value: number }[]
    notEnabled?: boolean
    otherContent?: React.ReactNode
}

const colors = ["#153C64", "#2D6CAE", "#81ACD1", "#C4D9E9", "#000000", "blue", "green", "yellow"]

const MerchantDashboardTileCircleWithList = ({
    title,
    subtitle,
    values,
    compareValues,
    notEnabled,
    otherContent,
}: IMerchantDashboardTileCircleWithListProps) => {
    const dataContainerDims = useDimensions<HTMLDivElement>({
        breakpoints: { XS: 0, SM: 400 },
        updateOnBreakpointChange: true,
    })
    const [hoveredDataKey, setHoveredDataKey] = useState<string | undefined>(undefined)
    const [hoveredDiagramKey, setHoveredDiagramKey] = useState<string | undefined>(undefined)
    const mappedValues: IMerchantDashboardCircleDiagramValue[] = sortDescending(values ?? [], (v) => v.value).map((v, i) => ({
        color: colors[i],
        isSelected: hoveredDataKey === i + "_data",
        key: i + "_data",
        value: v.value,
        title: v.title,
    }))

    if (notEnabled || !values || values.length === 0) {
        return <MerchantDashboardNoData title={title} subtitle={subtitle} isSwiipePaymentsNotEnabled={notEnabled} />
    }

    return (
        <MerchantDashboardCellContainer
            className={cn("merchant-dashboard-tile-circle-with-list", { rows2: dataContainerDims.width < 400 })}
            valuesDisabled={!values.find((v) => v.value !== 0)}
        >
            <MerchantDashboardCellTitle title={title} subtitle={subtitle} />
            <div className="data-container" ref={dataContainerDims.observe}>
                <MerchantDashboardCircleDiagram
                    className="merchant-dashboard-circle-container"
                    values={mappedValues}
                    onSelection={(key?: string) => {
                        setHoveredDiagramKey(key)
                    }}
                />
                <div className={cn("data-list", { "lots-of-rows": mappedValues.length > 5 })}>
                    {mappedValues.map((d, i) => {
                        return (
                            <div
                                className={cn("data-item", { odd: i % 2 === 0 })}
                                key={i}
                                onMouseEnter={() => {
                                    setHoveredDataKey(d.key)
                                }}
                                onMouseLeave={() => {
                                    if (hoveredDataKey === d.key) {
                                        setHoveredDataKey(undefined)
                                    }
                                }}
                            >
                                <div
                                    className={cn("data-color", { selected: hoveredDiagramKey === d.key })}
                                    style={{ backgroundColor: d.color }}
                                ></div>
                                <div className="data-title">{d.title}</div>
                                <div className="data-value">
                                    {getFormattedNumber(d.value, {
                                        numberFormat: "forceTwoDecimals",
                                    })}
                                    %
                                </div>
                                {compareValues && compareValues.length > i && (
                                    <div className="data-compare">
                                        <MerchantDashboardCompareDiff
                                            diffValuePoints
                                            alignRight
                                            value={d.value}
                                            compareValue={compareValues[i].value}
                                            showDashIfSame
                                        />
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
                {!!otherContent && <div className="other-content">{otherContent}</div>}
            </div>
        </MerchantDashboardCellContainer>
    )
}

export default MerchantDashboardTileCircleWithList
