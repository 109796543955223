import React from "react"
import { DataType, TRegister, TSetValue, TWatch } from "swiipe.portal.shared"
import { IEditFormBase } from "../../type/form/IEditFormBase"
import "./InfoBoxAdder.scss"
import InfoBoxEditor from "./InfoBoxEditor"
import { InfoBoxState } from "./InfoBoxState"

interface IEInfoBoxAdderProps<T> extends IEditFormBase {
    iconClass?: string
    renderFormFields?: (
        register: TRegister,
        setValue: TSetValue<T>,
        getValues: () => any,
        watch: TWatch<any>
    ) => React.ReactElement
    renderExtraFields?: (register: TRegister) => React.ReactElement
    adderLabel?: string
    onClickAdder?: () => Promise<void>
    title: string
    onSave?: (model: T) => Promise<void>
    isFirstItem: boolean
    renderFirstItemPreview: () => React.ReactElement
    state: InfoBoxState
}

const InfoBoxAdder = <T extends DataType>({
    iconClass,
    title,
    renderFormFields,
    renderExtraFields,
    adderLabel,
    onClickAdder,
    onSave,
    isInEditMode,
    onSwitchEditMode,
    isFirstItem,
    renderFirstItemPreview,
    state,
}: IEInfoBoxAdderProps<T>) => {
    return (
        <InfoBoxEditor<T>
            state={state}
            iconClass={iconClass}
            title={title}
            isInEditMode={isInEditMode}
            editBtnTextType="adding"
            renderPreviewIncludesContainer={!isFirstItem}
            renderPreview={() => {
                if (isFirstItem) {
                    return renderFirstItemPreview()
                }
                return (
                    <div
                        className="infobox-add-container"
                        onClick={() => {
                            if (onClickAdder) {
                                onClickAdder()
                            }

                            onSwitchEditMode(true)
                        }}
                    >
                        <span className="icon icon-add-new"></span>
                        {adderLabel && <span className="label">{adderLabel}</span>}
                    </div>
                )
            }}
            renderFormFields={renderFormFields}
            renderExtraFields={renderExtraFields}
            onSave={onSave}
            onSwitchEditMode={onSwitchEditMode}
        />
    )
}

export default InfoBoxAdder
