import { updateOrderFiltersThunk } from "../store/thunks/orderThunks"
import { ReduxDispatch } from "../store/useReduxDispatch"
import { OrdersFilter, TOrderStatusFilter } from "../type/Order"

export const onChangeOrderFilters = (
    filterValue: any,
    transactionFilterPropName: string,
    checked: boolean,
    currentFilters: OrdersFilter,
    dispatch: ReduxDispatch
) => {
    const transactionFilter = { ...currentFilters } as OrdersFilter

    const filterPropValue = transactionFilter[transactionFilterPropName]

    if (checked && (!filterPropValue || !filterPropValue.includes(filterValue))) {
        if (!transactionFilter[transactionFilterPropName]) {
            transactionFilter[transactionFilterPropName] = []
        }

        transactionFilter[transactionFilterPropName].push(filterValue)
    }

    if (
        !checked &&
        filterPropValue &&
        filterPropValue.map((fpv: any) => JSON.stringify(fpv)).indexOf(JSON.stringify(filterValue)) >= 0
    ) {
        transactionFilter[transactionFilterPropName] = transactionFilter[transactionFilterPropName].filter(
            (c: any) => JSON.stringify(c) != JSON.stringify(filterValue)
        )
    }

    dispatch(
        updateOrderFiltersThunk((filters) => {
            Object.keys(transactionFilter).map((k) => {
                filters[k] = transactionFilter[k]
            })
        })
    )
}

export const getSelectedValueForCheckboxDropDown = (value?: string[]) => {
    return value?.length
        ? `${value[0]} ${value.length > 1 ? "(+" : ""}${value.length > 1 ? value.length - 1 : ""}${value.length > 1 ? ")" : ""}`
        : undefined
}

export const getSelectedValueForFromToDropDown = (valueFrom?: string, valueTo?: string) => {
    return valueFrom || valueTo ? `${valueFrom ? valueFrom : ""} - ${valueTo ? valueTo : ""}` : undefined
}

export function prepareOrderFiltersForRequest(filters: OrdersFilter) {
    const sorting = {
        sortByProperty: filters.sortByProperty,
        isOrderByDescending: typeof filters.isOrderByDescending === "undefined" ? true : filters.isOrderByDescending,
    }

    const filterParams = {
        dateFrom: filters.dateFrom,
        dateTo: filters.dateTo,
        amountFrom: filters.amountFrom,
        amountTo: filters.amountTo,
        filterCurrency: filters.currency || [],
        filterActions: filters.transactionAction || [],
        filterPaymentType: filters.transactionPaymentType,
        orderStatusFilter: filters.orderStatus,
        txStatusFilter:
            !filters.orderStatus || filters.orderStatus === TOrderStatusFilter.AuthFailed ? filters.txStatus : undefined,
        referenceOrderIdQuery: filters.referenceOrderIdQuery,
        filterOrderId: filters.orderId,
        filterIsPaymentLinkOrders: filters.isPaymentLinkOrders,
        filterSubscriptionOrderTypes: filters.subscriptionOrderTypes,
    }

    return {
        sorting,
        filtering: filterParams,
    }
}
