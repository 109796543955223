import { DeliveryLocationDto } from "swiipe.portal.shared"
interface DeliveryLocationDtoExt extends DeliveryLocationDto {
    groupByString: string
}
export const groupDeliveryLocations = (deliveryLocations: DeliveryLocationDto[]) => {
    const extArray = deliveryLocations.map((x) => {
        const ext = x as DeliveryLocationDtoExt
        ext.groupByString = createKey(x)
        return ext
    })
    const groupedMap = extArray.reduce(
        (entryMap, e) => entryMap.set(e.groupByString, [...(entryMap.get(e.groupByString) || []), e]),
        new Map()
    )
    return groupedMap
}
export const createKey = (x: DeliveryLocationDto) => {
    return `${handleKeyPart(x.fullName)}_${handleKeyPart(x.companyName)}_${handleKeyPart(x.address)}_${handleKeyPart(
        x.postCode
    )}_${handleKeyPart(x.city)}_${handleKeyPart(x.country, "DK")}_${handleKeyPart(x.region)}`
}

function handleKeyPart(keyPart: string | undefined | null, defaultValue?: string) {
    if (!keyPart) {
        return defaultValue || "x"
    }
    return keyPart
}
