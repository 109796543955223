import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PageContainer, PageWrapper, useSelector, userSelectors } from "swiipe.portal.shared"
import GoBackContainer from "../../components/navigations/GoBackContainer"
import SwishLogo from "../../images/swPaymentTypes/swish-logo.png"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { getOnboardingStatusThunk } from "../../store/thunks/swiipePaymentsThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import SwishCertificateStep from "./SwishCertificateStep"
import SwishDetails from "./SwishDetails"
import SwishIdStep from "./SwishIdStep"
import "./SwishOnboardingPage.scss"

interface ISwishOnboardingPageProps {
    webshopIds?: string[]
    isEdit?: boolean
    onGoBack: () => void
    onOnboarded: () => void
}
interface ISwishOnboardingPageInnerProps extends ISwishOnboardingPageProps {
    merchantDetails: MixedMerchantDetails
    selectedWebshopId?: string
}

const SwishOnboardingPage = (props: ISwishOnboardingPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const userData = useSelector(userSelectors.userData)
    const selectedWebshopId =
        props.webshopIds && props.webshopIds?.length > 0 ? props.webshopIds[0] : useSelector(merchantSelectors.selectedWebshopId)

    if (!merchantDetails || !userData || !merchantDetails.webshops.length) {
        return null
    }

    return (
        <SwishOnboardingPageInner
            merchantDetails={merchantDetails}
            selectedWebshopId={selectedWebshopId}
            {...props}
        ></SwishOnboardingPageInner>
    )
}

const SwishOnboardingPageInner = ({
    merchantDetails,
    webshopIds,
    selectedWebshopId,
    isEdit,
    onGoBack,
    onOnboarded,
}: ISwishOnboardingPageInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [showCancelService, setShowCancelService] = useState(false)
    const [swishId, setSwishId] = useState("")
    const [currentStep, setCurrentStep] = useState(isEdit ? 2 : 0)

    useEffect(() => {
        merchantDetails?.webshops.forEach(
            async (w) =>
                await dispatch(getOnboardingStatusThunk(merchantDetails?.swMerchant.swMerchantId, w.webshopId, false, ["Swish"]))
        )
    }, [merchantDetails])

    if (!webshopIds && !isEdit) {
        return null
    }

    const getComponentToShow = () => {
        switch (currentStep) {
            case 0: {
                return (
                    <SwishIdStep
                        onNextStep={incrementStep}
                        onBackStep={async () => {
                            decrementStep()
                            onGoBack()
                        }}
                        swishId={swishId}
                        setSwishId={setSwishId}
                    />
                )
            }
            case 1: {
                return (
                    <SwishCertificateStep
                        webshopId={selectedWebshopId ?? ""}
                        merchantDetails={merchantDetails}
                        swishId={swishId}
                        onNextStep={onOnboarded}
                        onBackStep={decrementStep}
                    />
                )
            }
            case 2: {
                return (
                    <SwishDetails webshopId={selectedWebshopId ?? ""} merchantDetails={merchantDetails} onBackStep={onGoBack} />
                )
            }
            default:
                return onGoBack()
        }
    }

    const incrementStep = () => {
        const maxSteps = 2
        const nextStep = currentStep + 1
        if (nextStep >= maxSteps) {
            return
        }
        setCurrentStep(currentStep + 1)
    }
    const decrementStep = async () => {
        setCurrentStep(currentStep == 0 ? currentStep : currentStep - 1)
    }

    return (
        <>
            <PageWrapper className={cn({ "d-none": showCancelService })}>
                {isEdit && <GoBackContainer goBackText={t("commononboardingtexts.backToAllService")} onGoBack={onGoBack} />}

                <PageContainer>
                    <div className="swish-onboarding-container">
                        <>
                            <div className="swish-form-head">
                                <img className="swish-logo" src={SwishLogo} />
                            </div>
                            {getComponentToShow()}
                        </>
                    </div>
                </PageContainer>
            </PageWrapper>
        </>
    )
}

export default SwishOnboardingPage
