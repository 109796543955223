import { ActionsUnion, createAction } from "swiipe.portal.shared"
import { IResursStore } from "../../type/resurs/IResursStore"
import { StoreState } from "../StoreState"

export type ResursState = Readonly<{
    resursStores: {
        [merchantId: string]: IResursStore[]
    }
}>
const initialState: ResursState = { resursStores: {} }

export const resursReducerActions = {
    setResursStores: (merchantId: string, resursStores: IResursStore[]) =>
        createAction("APP/RESURS_SET_MERCHANT_STORES", { merchantId, resursStores }),
}

export type ResursActions = ActionsUnion<typeof resursReducerActions>

export const resursReducer = (state: ResursState = initialState, action: ResursActions) => {
    switch (action.type) {
        case "APP/RESURS_SET_MERCHANT_STORES": {
            return {
                ...state,
                resursStores: {
                    ...state.resursStores,
                    [action.merchantId]: action.resursStores,
                },
            }
        }
    }

    return state
}

export const resursSelectors = {
    resursStores: (state: StoreState, merchantId: string) => state.resurs.resursStores[merchantId],
}
