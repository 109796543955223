import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { getText } from "swiipe.portal.shared"
import { StoreState } from "../StoreState"
import { sendServiceConfirmationEmailThunk } from "./swiipeServicesThunks"
import { setWebshopFeaturesThunk } from "./webshopThunks"

export const returnSellOnboardingThunk =
    (swMerchantId: string, webshopIds: string[], enabled: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(setWebshopFeaturesThunk(webshopIds, "ReturnSell", enabled, swMerchantId))

        if (!enabled) {
            return
        }

        await dispatch(
            sendServiceConfirmationEmailThunk(swMerchantId, "ReturnSellConfirmation", getText("serviceNames.returnSell"))
        )
    }
