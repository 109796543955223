import React from "react"
import { Alert } from "reactstrap"
import "./CompletedFieldsReview.scss"

interface ICompletedFieldsReviewProps {
    children: React.ReactNode
}

export const CompletedFieldsReview = ({ children }: ICompletedFieldsReviewProps) => {
    return (
        <Alert isOpen={true} className="completed-fields-review" color="dark">
            {children}
        </Alert>
    )
}
