import React from "react"
import { route, map, mount, NaviRequest } from "navi"
import MobileCheckoutFlowPage from "./../pages/mobileCheckout/MobileCheckoutFlowPage"
import MobileCheckoutCompletedPage from "./../pages/mobileCheckout/MobileCheckoutCompletedPage"
import { INavigationContext } from "./INavigationContext"
import MobileCheckoutNotFoundPage from "./../pages/mobileCheckout/MobileCheckoutNotFoundPage"
import MobileCheckoutMockPaymentWindowPage from "./../pages/mobileCheckout/MobileCheckoutMockPaymentWindow"

export const mobileCheckoutRoutes = mount<INavigationContext>({
    // "*": map<INavigationContext>(async (request, context) => {
    //     return await getMobileCheckoutSubPageRouter(request)
    // }),
})
export default mobileCheckoutRoutes
