import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Row } from "reactstrap"
import {
    BillingAddressDto,
    CountryInputComp,
    TRegister,
    TSetValue,
    lookupCityByZipcodeThunk,
    valFuncPattern,
    valFuncRequiredAndPattern,
    validationPatterns,
    validationPatternsWithLocale,
} from "swiipe.portal.shared"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import EditFormInput from "../input/EditFormInput"
import RegionFormFields from "./RegionFormFields"

interface IAddressFormFieldsProps<T> {
    register: TRegister
    setValue: TSetValue<BillingAddressDto>
    getValues: () => BillingAddressDto
    address?: BillingAddressDto
    noSave?: boolean
    omitFirstAndLastName?: boolean
    disableCountry?: boolean
}

const AddressFormFields = <T extends BillingAddressDto>({
    getValues,
    setValue,
    register,
    address,
    omitFirstAndLastName,
    disableCountry,
    noSave = false,
}: IAddressFormFieldsProps<T>) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [currentCountry, setCurrentCountry] = useState(address ? address.country || "DK" : "DK")

    return (
        <div className="edit-form">
            {!omitFirstAndLastName && (
                <Row>
                    <div className="col-12 pl-3 col-md-6">
                        <EditFormInput
                            disabled={noSave}
                            defaultValue={address ? address.firstName : ""}
                            name="firstName"
                            placeholder={t("personal.firstName")}
                            innerRef={register(
                                valFuncRequiredAndPattern(validationPatterns.firstName, t("personal.validationerrors.firstName"))
                            )}
                        />
                    </div>
                    <div className="col-12 pl-3 col-md-6 pl-md-0">
                        <EditFormInput
                            disabled={noSave}
                            defaultValue={address ? address.lastName : ""}
                            name="lastName"
                            placeholder={t("personal.lastName")}
                            innerRef={register(
                                valFuncRequiredAndPattern(validationPatterns.lastName, t("personal.validationerrors.lastName"))
                            )}
                        />
                    </div>
                </Row>
            )}
            <Row>
                <div className="col-12">
                    <EditFormInput
                        disabled={noSave}
                        defaultValue={address?.companyName ?? ""}
                        name="companyName"
                        placeholder={t("personal.company_name")}
                        innerRef={register(
                            valFuncPattern(validationPatterns.companyName, t("personal.validationerrors.companyname"))
                        )}
                    />
                </div>
            </Row>
            <Row>
                <div className="col-12">
                    <EditFormInput
                        disabled={noSave}
                        defaultValue={address?.address ?? ""}
                        name="address"
                        placeholder={t("personal.address")}
                        innerRef={register(
                            valFuncRequiredAndPattern(validationPatterns.addressStreet, t("personal.validationerrors.street"))
                        )}
                    />
                </div>
            </Row>
            <Row>
                <div className="col-12"></div>
            </Row>
            <Row>
                <div className="col-5 pl-3">
                    <EditFormInput
                        disabled={noSave}
                        onChange={async (e) => {
                            const formValues = getValues()

                            if (/D[ea]nmark/i.test(formValues.country || "") || /DK/i.test(formValues.country || "")) {
                                const zipcode = e.currentTarget.value
                                const city = await dispatch(lookupCityByZipcodeThunk(zipcode))
                                if (city) {
                                    setValue("city", city, { shouldValidate: true })
                                }
                            }
                        }}
                        defaultValue={address?.postCode ?? ""}
                        name="postCode"
                        placeholder={t("personal.postcode")}
                        innerRef={register(
                            valFuncRequiredAndPattern(
                                validationPatternsWithLocale.addressPostalCode(getValues().country || "DK"),
                                t("personal.validationerrors.postcode")
                            )
                        )}
                    />
                </div>
                <div className="col-7 pl-0">
                    <EditFormInput
                        disabled={noSave}
                        defaultValue={address?.city ?? ""}
                        name="city"
                        placeholder={t("personal.city")}
                        innerRef={register(
                            valFuncRequiredAndPattern(validationPatterns.addressCity, t("personal.validationerrors.city"))
                        )}
                    />
                </div>
            </Row>
            <Row>
                <div className="col-12 form-group">
                    <CountryInputComp
                        disabled={noSave || disableCountry}
                        defaultValue={currentCountry}
                        inputName="country"
                        register={register}
                        onChange={(e) => setCurrentCountry(e.target.value)}
                    />
                </div>
            </Row>
            <Row>
                <div className="col-12 form-group">
                    <RegionFormFields
                        noSave={noSave}
                        setValue={setValue}
                        register={register}
                        currentCountry={currentCountry}
                        countryDefault={address?.country}
                        regionDefault={address?.region}
                    />
                </div>
            </Row>
        </div>
    )
}

export default AddressFormFields
