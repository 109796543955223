import { map, redirect, route } from "navi"
import { TEnv } from "swiipe.portal.shared"

export const getRouteIncludingEnv = (view: any, enableForEnv: TEnv[]) => {
    return map(async (request, context: any) => {
        if (!enableForEnv.includes(context.env)) {
            return redirect("/")
        }
        return route({
            view: view,
        })
    })
}

export const enableOnlyForEnvSandbox: TEnv[] = ["Sandbox"]
export const enableOnlyForEnvSandboxAndTest: TEnv[] = ["Sandbox", "Test", "Test2", "QA"]
export const disableForEnvSandbox: TEnv[] = ["Betatest", "Development", "Production", "Test", "Test2", "QA"]
