import cn from "classnames"
import React from "react"
import "./MerchantOfferContentContainer.scss"

interface IMerchantOfferContentContainerProps {
    className?: string
    children: React.ReactNode
}

export const MerchantOfferContentContainer = ({ className, children }: IMerchantOfferContentContainerProps) => {
    return <div className={cn("merchant-offer-content-container", className)}>{children}</div>
}
