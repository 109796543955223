import { ActionsUnion, SharedStoreState, createAction } from "swiipe.portal.shared"
import { ICompanyFieldsBase } from "../types/company/ICompanyFieldsBase"

export type CompanySearchState = Readonly<{
    results: ICompanyFieldsBase[]
    selected?: ICompanyFieldsBase
    userCompanyDetails?: ICompanyFieldsBase

    executingSearchRequest?: boolean
}>
const initialState: CompanySearchState = { results: [] }

export const companySearchReducerActions = {
    addResult: (result: ICompanyFieldsBase) => createAction("APP/ADD_NEW_COMPANYSEAECH_RESULT", { result }),
    setSelected: (selected?: ICompanyFieldsBase) => createAction("APP/SET_SELECTED_SEARCH_COMPANY", { selected }),
    addUserCompanyDetails: (details: ICompanyFieldsBase) => createAction("APP/ADD_USER_COMPANY_DETAILS", { details }),
    clearAllResults: () => createAction("APP/CLEAR_COMPANYSEAECH_RESULTS", {}),
    setExecutingSearchRequest: (execution: boolean) => createAction("APP/SET_EXECUTING_SEARCH_REQUEST", { execution }),
}

export type CompanySearchActions = ActionsUnion<typeof companySearchReducerActions>

export const companySearchReducer = (state: CompanySearchState = initialState, action: CompanySearchActions) => {
    switch (action.type) {
        case "APP/ADD_NEW_COMPANYSEAECH_RESULT": {
            return {
                ...state,
                results: state.results.find((r) => r.companyNumber === action.result.companyNumber)
                    ? [...state.results]
                    : [action.result, ...state.results],
            }
        }
        case "APP/CLEAR_COMPANYSEAECH_RESULTS": {
            return {
                ...state,
                results: [],
            }
        }
        case "APP/SET_SELECTED_SEARCH_COMPANY": {
            return {
                ...state,
                selected: action.selected,
            }
        }
        case "APP/ADD_USER_COMPANY_DETAILS": {
            return {
                ...state,
                userCompanyDetails: action.details,
            }
        }
        case "APP/SET_EXECUTING_SEARCH_REQUEST": {
            return {
                ...state,
                executingSearchRequest: action.execution,
            }
        }
    }

    return state
}

export const companySearchSelectors = {
    results: (state: SharedStoreState) => state.companySearch.results,
    selected: (state: SharedStoreState) => state.companySearch.selected,
    userCompanyDetails: (state: SharedStoreState) => state.companySearch.userCompanyDetails,
    executingSearchRequest: (state: SharedStoreState) => state.companySearch.executingSearchRequest,
}
