import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { DropDownList, TPlatformValue, getPlatformLogo, useSelector } from "swiipe.portal.shared"
import { removeSchemeFromHostName } from "../../services/merchantService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { setSelectedWebshopIdThunk } from "../../store/thunks/merchantThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import "./WebshopSelect.scss"

interface IWebshopSelectProps {
    merchantDetails: MixedMerchantDetails
    onWebshopChange?: (selectedWebshopId: string) => void
    limitToPlatforms?: TPlatformValue[]
    hidePlatform?: boolean
    hint?: string
}

const WebshopSelect = ({ merchantDetails, onWebshopChange, limitToPlatforms, hint, hidePlatform }: IWebshopSelectProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()

    useRequiredUserRelations([{ requiredType: "Merchant" }])

    const { initialWebshopId, initialPlatform, webshops } = useInitialWebshopValuesForWebshopSelect(
        merchantDetails,
        limitToPlatforms
    )

    const [selectedWebshopId, setSelectedWebshopId] = useState(initialWebshopId)
    const [selectedPlatform, setSelectedPlatform] = useState<TPlatformValue>(initialPlatform as TPlatformValue)

    useEffect(() => {
        onWebshopChange?.(initialWebshopId)
    }, [initialWebshopId])

    return (
        <div id="webshop-select-comp">
            <div className="webshop-select">
                <p className="heading mb-2" data-html2canvas-ignore>
                    {t("webshopselect.selectwebshop")}
                </p>
                <DropDownList
                    options={webshops.map((w) => {
                        return { text: removeSchemeFromHostName(w.hostName), value: w.webshopId }
                    })}
                    onChange={(e) => {
                        setSelectedPlatform(webshops.find((w) => w.webshopId === e.target.value)!.platform)
                        setSelectedWebshopId(e.target.value)

                        localStorage[merchantDetails.swMerchant.swMerchantId + "_selectedWebshopId"] = e.target.value
                        dispatch(setSelectedWebshopIdThunk(merchantDetails.swMerchant.swMerchantId, e.target.value))
                        onWebshopChange?.(e.target.value)
                    }}
                    value={selectedWebshopId}
                />
                {!hidePlatform && <img className="platform-logo" src={getPlatformLogo(selectedPlatform)} />}
            </div>
            {hint && (
                <div className="hint-webshop-select">
                    <span>{hint}</span>
                </div>
            )}
        </div>
    )
}

export function useInitialWebshopValuesForWebshopSelect(
    merchantDetails: MixedMerchantDetails,
    limitToPlatforms?: TPlatformValue[]
) {
    const webshops = merchantDetails.webshops?.filter((w) => !limitToPlatforms || limitToPlatforms.includes(w.platform)) || []
    const initialWebshopId = useSelector(merchantSelectors.selectedWebshopId)

    if (webshops.length === 0) {
        return {
            initialWebshopId: "",
            initialPlatform: "",
            webshops,
        }
    }

    if (initialWebshopId && webshops.find((w) => w.webshopId === initialWebshopId)) {
        return {
            initialWebshopId,
            initialPlatform: webshops.find((w) => w.webshopId === initialWebshopId)!.platform,
            webshops,
        }
    }

    return {
        initialWebshopId: webshops[0].webshopId,
        initialPlatform: webshops[0].platform,
        webshops,
    }
}

export default WebshopSelect
