import React from "react"
import { useTranslation } from "react-i18next"
import { StandardButton } from "swiipe.portal.shared"
import WinbackIcon from "../../../../images/swFeatureServices/winback-icon.png"
import { getWinBackValues } from "../../../../services/merchantDashboardService"
import { navigationService } from "../../../../services/navigationService"
import { IMerchantDashboardServerData } from "../../../../type/merchantDashboard/IMerchantDashboardServerData"
import MerchantDashboardSimpleValues from "../../tiles/MerchantDashboardTileSimpleValues"

interface IDashboardWinBackDisplayProps {
    dashboardData: IMerchantDashboardServerData
    paymentsEnabled: boolean
    winBackEnabled: boolean
}

const DashboardWinBackDisplay = ({ dashboardData, paymentsEnabled, winBackEnabled }: IDashboardWinBackDisplayProps) => {
    const { t } = useTranslation()
    const winBackValues = getWinBackValues(dashboardData.mainData, paymentsEnabled, winBackEnabled)

    const winBack = {
        main: dashboardData.mainData.winBack,
        compare: dashboardData.compareData?.winBack,
    }

    return (
        <MerchantDashboardSimpleValues
            title={t("merchantDashboard.winBackTitle")}
            icon={WinbackIcon}
            subtitle={t("merchantDashboard.viaSwiipe")}
            simpleValues={[
                { value: { amount: winBackValues.winBackSucceeded }, subtitle: t("merchantDashboard.winBackTotal") },
                {
                    value: {
                        amount:
                            winBackValues.winBackNotified === 0
                                ? 0
                                : (winBackValues.winBackSucceeded / winBackValues.winBackNotified) * 100,
                        isPercent: true,
                    },
                    subtitle: t("merchantDashboard.winBackSucceeded"),
                },
                {
                    value: { amount: winBackValues.winBackAmountTotal, currency: dashboardData.currency },
                    subtitle: t("merchantDashboard.winBackTurnover"),
                },
            ]}
            compareValues={
                (winBack.compare &&
                    winBackEnabled && [
                        {
                            value: { amount: winBack.compare.succeeded },
                            subtitle: t("merchantDashboard.winBackTotal"),
                        },
                        {
                            value: {
                                amount:
                                    winBack.compare.notified === 0
                                        ? 0
                                        : (winBack.compare.succeeded / winBack.compare.notified) * 100,
                                isPercent: true,
                            },
                            subtitle: t("merchantDashboard.winBackSucceeded"),
                        },
                        {
                            value: { amount: winBack.compare.amountTotal, currency: dashboardData.currency },
                            subtitle: t("merchantDashboard.winBackTurnover"),
                        },
                    ]) ||
                undefined
            }
            notEnabled={!paymentsEnabled}
            overlayContent={
                winBackEnabled ? undefined : (
                    <div className="service-activation-container">
                        <StandardButton
                            isSmall
                            title={t("merchantDashboard.winBackActivate")}
                            onClick={() => navigationService.navigate("/winback_onboard")}
                        />
                    </div>
                )
            }
        />
    )
}

export default DashboardWinBackDisplay
