import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { StandardButtonWithSpinner, sharedConfigurationSelectors, useSelector } from "swiipe.portal.shared"
import { getMerchantContact } from "../../services/merchantService"
import { navigationService } from "../../services/navigationService"
import {
    getIconClassForRelation,
    getRootUrlForRelation,
    getTermsStatusByUserRelation,
    getconfirmEmailResentByMerchantId,
} from "../../services/userRelationService"
import { partnerSelectors } from "../../store/reducers/partnerReducer"
import { getMerchantDataThunk } from "../../store/thunks/merchantThunks"
import { switchUserThunk } from "../../store/thunks/startupThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { IUserRelationFlattened } from "../../type/IUserRelation"
import ResendConfirmationEmail from "../partner/ResendConfirmationEmail"
import RequestSentText from "../textFormat/RequestSentText"
import "./UserRelationInfo.scss"

interface IUserRelationInfoProps {
    userRelation: IUserRelationFlattened
    parentUserRelation?: IUserRelationFlattened
}

const UserRelationInfo = ({ userRelation, parentUserRelation }: IUserRelationInfoProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()
    const [isBtnActive, setBtnActive] = useState(false)

    const partnerMerchantRelationsAll = useSelector(partnerSelectors.partnerMerchantRelationsAll)
    const termsAcceptedByMerchant = getTermsStatusByUserRelation(userRelation, partnerMerchantRelationsAll)

    const [activeFormMerchantEmail, setActiveFormMerchantEmail] = useState("")
    const env = useSelector(sharedConfigurationSelectors.environment)

    const [confirmEmailResent, setConfirmEmailResent] = useState(
        userRelation.relationType === "Merchant"
            ? getconfirmEmailResentByMerchantId(userRelation.id, userRelation.parentId, partnerMerchantRelationsAll)
            : false
    )

    return (
        <div className="swiipe-user-relation-info px-md-5">
            <span className={cn("icon", getIconClassForRelation(userRelation))}></span>
            <div className="userrelation ml-4 mr-1" onClick={() => setBtnActive(!isBtnActive)}>
                <div className="name">
                    {userRelation.name}
                    {userRelation.markedDeletion && <div className="alert alert-danger deleted">Deleted</div>}
                </div>
                {parentUserRelation && <div className="parentname">{parentUserRelation.name}</div>}
            </div>
            <div className={cn("div-fullwidth")}>
                <div className="action-box">
                    {!termsAcceptedByMerchant && (
                        <div className="mb-1 mt-3 text-above-button">{t("partnermerchantrelations.awaitingconfirm")}</div>
                    )}
                    <StandardButtonWithSpinner
                        isDelete={!termsAcceptedByMerchant}
                        inverted={!termsAcceptedByMerchant}
                        dark
                        className={cn(
                            "btn-switch",
                            "action-button",
                            { "d-sm-block": !confirmEmailResent && activeFormMerchantEmail === "" },
                            { "request-btn": !termsAcceptedByMerchant },
                            { "d-none": !isBtnActive || (confirmEmailResent && activeFormMerchantEmail !== "") },
                            { "border-0": termsAcceptedByMerchant },
                            { "mt-3": termsAcceptedByMerchant }
                        )}
                        title={termsAcceptedByMerchant ? t("switchaccountspage.choose") : t("partnermerchantrelations.request")}
                        onClick={async () => {
                            if (termsAcceptedByMerchant) {
                                await dispatch(switchUserThunk(userRelation))
                                navigationService.navigate(getRootUrlForRelation(userRelation, env))
                            } else {
                                if (userRelation.relationType === "Merchant" && userRelation.parentId) {
                                    const merchantDetails = await dispatch(getMerchantDataThunk(userRelation.id))
                                    const swMerchantContact = getMerchantContact(merchantDetails)

                                    if (!swMerchantContact) {
                                        return
                                    }
                                    setActiveFormMerchantEmail(swMerchantContact.email)
                                }
                            }
                        }}
                    />
                    <RequestSentText show={confirmEmailResent} text={t("partnermerchantrelations.requestsent")} />
                </div>
            </div>
            {!!userRelation.parentId && activeFormMerchantEmail !== "" && (
                <ResendConfirmationEmail
                    merchantEmail={activeFormMerchantEmail}
                    merchantId={userRelation.id}
                    partnerId={userRelation.parentId}
                    toggleCallback={(relId: string) => {
                        setActiveFormMerchantEmail("")
                        setConfirmEmailResent(true)
                    }}
                />
            )}
        </div>
    )
}

export default UserRelationInfo
