import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { SpinnerContainer, useSelector, userSelectors } from "swiipe.portal.shared"
import A2aAccountOverview from "../../components/paymentsOnboarding/A2aAccountOverview"
import CountrySelectDialog from "../../components/servicesonboarding/CountrySelectDialog"
import { navigationService } from "../../services/navigationService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { IGatewayDto, gatewaySelectors } from "../../store/reducers/gatewayReducer"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { a2aEnableOnboardingThunk, ensureA2aGatewayThunk } from "../../store/thunks/a2aThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"

interface IA2aOnboardingPageProps {
    webshopIds?: string[]
    isEdit?: boolean
    onGoBack: () => void
}

interface IA2aOnboardingPageInnerProps extends IA2aOnboardingPageProps {
    merchantDetails: MixedMerchantDetails
    selectedWebshopId?: string
}

const A2aOnboardingPage = (props: IA2aOnboardingPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const userData = useSelector(userSelectors.userData)

    const selectedWebshopIdFromStore = useSelector(merchantSelectors.selectedWebshopId)
    const selectedWebshopId = props.webshopIds && props.webshopIds?.length > 0 ? props.webshopIds[0] : selectedWebshopIdFromStore

    if (!merchantDetails || !userData || !merchantDetails.webshops.length) {
        return null
    }

    return (
        <A2aOnboardingPageInner
            merchantDetails={merchantDetails}
            selectedWebshopId={selectedWebshopId}
            {...props}
        ></A2aOnboardingPageInner>
    )
}

const A2aOnboardingPageInner = ({ selectedWebshopId, isEdit, onGoBack }: IA2aOnboardingPageInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [selectedCountryCode, setSelectedCountryCode] = useState<string>()
    const [isEditable, setIsEditable] = useState<boolean>(isEdit ?? false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [currentStep, setCurrentStep] = useState(isEditable ? 1 : 0)
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)!
    const merchantId = currentUserRelation.id

    const gateway = useSelector<StoreState, IGatewayDto | undefined>((state) =>
        gatewaySelectors.getGateway(state, merchantId, "AccountToAccount", selectedWebshopId ?? "")
    )

    const getComponentToShow = () => {
        switch (currentStep) {
            case 0: {
                return (
                    <SpinnerContainer showSpinner={isLoading}>
                        <CountrySelectDialog
                            serviceName={t("serviceNames.a2a")}
                            defaultValue="DK"
                            customCountryFilter={["DK", "SE"]}
                            noticeText={t("a2aonboarding.selectCountryNotice")}
                            isPaymentType
                            onCountrySelect={async (countryCode) => {
                                setIsLoading(true)

                                if (gateway && !gateway.enabled) {
                                    await dispatch(a2aEnableOnboardingThunk(merchantId, gateway.webshopId, gateway.gatewayId))

                                    // gateway status is set after country is selected since it depends on any previously selected accounts
                                    await dispatch(
                                        ensureA2aGatewayThunk(merchantId, gateway?.webshopId, gateway.gatewayId, [countryCode])
                                    )
                                    setIsEditable(true)
                                }
                                setSelectedCountryCode(countryCode)
                                setCurrentStep(1)
                                setIsLoading(false)
                            }}
                            onGoBack={onGoBack}
                        />
                    </SpinnerContainer>
                )
            }
            case 1: {
                return (
                    <A2aAccountOverview
                        selectedCountryCode={selectedCountryCode}
                        selectedWebshopId={selectedWebshopId ?? ""}
                        onContinue={() => {}}
                        isEdit={isEditable}
                        onGoBack={
                            isEditable
                                ? () => navigationService.navigate("/plugins")
                                : () => {
                                      setCurrentStep(currentStep - 1)
                                  }
                        }
                    />
                )
            }
            default:
                return onGoBack()
        }
    }

    return <>{getComponentToShow()}</>
}

export default A2aOnboardingPage
