import cn from "classnames"
import htmlToReactParser from "html-react-parser"
import React, { useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    IInvoicingPrice,
    ShowErrorMessages,
    TextButton,
    getResolvedInvoicingPrice,
    getText,
    getTextWithInvoicingPrices,
    useSelector,
} from "swiipe.portal.shared"
import DankortLogo from "../../images/swCardLogos/dankort-card-logo.png"
import ForbrugsforeningenLogo from "../../images/swCardLogos/forbrugsforeningen-card-logo.png"
import AnywhereMobileLogo from "../../images/swFeatureServices/anywhere-mobile-logo.svg"
import PlusSellLogo from "../../images/swFeatureServices/plussell-logo.png"
import ReturnSellLogo from "../../images/swFeatureServices/returnsell-logo.png"
import WinbackLogo from "../../images/swFeatureServices/winback-logo.png"
import MobilepayTextLogo from "../../images/swPaymentTypes/mobilepay-text-logo.png"
import SwishLogo from "../../images/swPaymentTypes/swish-logo.png"
import { getImage } from "../../services/imageLanguageService"
import { isAllTermsAccepted } from "../../services/legalService"
import { TOnboardingType, getTermsTypeForOnboardingType } from "../../services/onboardingService"
import { billingSelectors } from "../../store/reducers/billingReducer"
import { legalSelectors } from "../../store/reducers/legalReducer"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { ensureTermsStatusThunk, markTermsAsAcceptedThunk } from "../../store/thunks/termsThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { TSwiipePlan } from "../../type/IProvider"
import SubmitButton from "../buttons/SubmitButton"
import TermsAndConditionsCheckbox from "../form/checkboxes/TermsAndConditionsCheckbox"
import { IFormHeaderLogo } from "../form/title/FormHeaderLogoContainer"
import PspPriceRow from "../paymentsOnboarding/psp/PspPriceRow"
import TextTranslatedWithLinks from "../translated/TextTranslatedWithLinks"
import OnboardingPriceLineLarge from "./OnboardingPriceLineLarge"
import "./OnboardingPricingDialog.scss"
import OnboardingPricingLine from "./OnboardingPricingLine"
import ServicesOnboardingPageWrapper from "./ServicesOnboardingPageWrapper"

type IPricingDialogsConfig = {
    [Type in TOnboardingType]: {
        customLogo?: string
        customLogoWidth?: string
        customLogoClass?: string
        additionalLogos?: IFormHeaderLogo[]

        headingTextKey: string
        subHeading?: {
            textKey: string
            link?: {
                keyInText: string
                translationKey: string
                address: string
                openInNewTab: boolean
            }
        }

        priceLines?: JSX.Element[]
        hideTransactionFeeHeader?: boolean

        additionalTextKey?: string

        termsLinkTextKey: string
    }
}

interface IFormModel {
    acceptTerms: boolean
}

export const getPriceDialogConfigs = (prices: IInvoicingPrice[], swiipePlan: TSwiipePlan | undefined): IPricingDialogsConfig => {
    return {
        viabill: {
            customLogo: getImage("viabillLogo"),
            headingTextKey: "viabillOnboarding.pricingPage.cost",
            termsLinkTextKey: "viabillOnboarding.pricingPage.termsAndConditions",
            priceLines: [<PspPriceRow key="price-per-payment-same-as-psp" priceType="transaction" prices={prices} />],
        },
        dankort: {
            customLogo: getImage("cardPaymentsLogo"),
            additionalLogos: [
                {
                    src: DankortLogo,
                    alt: "Dankort",
                    width: "45px",
                },
            ],
            headingTextKey: "dankortonboarding.pricingPage.cost",
            termsLinkTextKey: "dankortonboarding.pricingPage.termsAndConditions",
            priceLines: [<PspPriceRow key="price-per-payment-same-as-psp" priceType="transaction" prices={prices} />],
        },
        forbrugsforeningen: {
            customLogo: getImage("cardPaymentsLogo"),
            additionalLogos: [
                {
                    src: ForbrugsforeningenLogo,
                    alt: "Forbrugsforeningen",
                    width: "45px",
                },
            ],
            headingTextKey: "forbrugsforeningenonboarding.pricingPage.cost",
            termsLinkTextKey: "forbrugsforeningenonboarding.pricingPage.termsAndConditions",
            priceLines: [<PspPriceRow key="price-per-payment-same-as-psp" priceType="transaction" prices={prices} />],
        },
        cards: {
            headingTextKey: "clearhausonboarding.intro.acquiringCost",
            termsLinkTextKey: "clearhausonboarding.intro.termsAndConditions",
            additionalTextKey: "clearhausonboarding.intro.pleaseHaveDigitalDocumentsCopy",
            priceLines: [
                <OnboardingPricingLine
                    enlargeSpans
                    key="card-within-eu-price"
                    priceHtml={getTextWithInvoicingPrices("clearhausonboarding.intro.transactionPct", prices, {
                        transactionPct: { category: "AcquirerTransactionPct" },
                    })}
                />,
                <OnboardingPricingLine
                    enlargeSpans
                    key="card-min-tx-price"
                    priceHtml={getTextWithInvoicingPrices("clearhausonboarding.intro.priceMinimumTx", prices, {
                        acquirerMinTx: { type: "AcquirerMinTransactionShared" },
                    })}
                />,
            ],
        },
        plusSell: {
            customLogo: PlusSellLogo,
            customLogoWidth: "180px",
            headingTextKey: "plusSellOnboarding.salesCost",
            additionalTextKey: "plusSellOnboarding.notice",
            termsLinkTextKey: "plusSellOnboarding.termsLinkText",
            hideTransactionFeeHeader: true,
            priceLines: [
                <OnboardingPricingLine
                    enlargeSpans
                    key="plusSell-1"
                    priceHtml={getTextWithInvoicingPrices("plugins.plusSellPrice", prices, {
                        plusSellTx: { category: "SwiipePlusSellPct", wrapPriceInElement: "span" },
                    })}
                />,
            ],
        },
        returnSell: {
            customLogo: ReturnSellLogo,
            customLogoWidth: "220px",
            headingTextKey: "returnSellOnboarding.salesCost",
            priceLines: [
                <OnboardingPricingLine
                    enlargeSpans
                    key="returnSell"
                    priceHtml={getTextWithInvoicingPrices("plugins.returnSellPrice", prices, {
                        returnSellMonthly: { category: "SwiipeReturnSellMonthly", wrapPriceInElement: "span" },
                        returnSellTx: { category: "SwiipeReturnSellTransactionPct", wrapPriceInElement: "span" },
                        returnSellExtraMonthly: {
                            category: "SwiipeReturnSellExtraWebshopMonthly",
                            wrapPriceInElement: "span",
                        },
                    })}
                />,
            ],
            additionalTextKey: "returnSellOnboarding.notice",
            termsLinkTextKey: "returnSellOnboarding.termsLinkText",
        },
        anywhereMobile: {
            customLogo: AnywhereMobileLogo,
            customLogoWidth: "220px",
            headingTextKey: "anywhereMobileOnboarding.salesCost",
            priceLines: [
                <OnboardingPricingLine
                    enlargeSpans
                    key="anywhereMobile"
                    priceHtml={getTextWithInvoicingPrices("plugins.anywhereMobilePrice", prices, {
                        anywhereMobileMonthly: {
                            category: "SwiipeAnywhereMobileMonthly",
                            wrapPriceInElement: "span",
                        },
                        anywhereMobileExtraDomainMonthly: {
                            category: "SwiipeAnywhereMobileExtraWebshopMonthly",
                            wrapPriceInElement: "span",
                        },
                    })}
                />,
            ],
            additionalTextKey: "anywhereMobileOnboarding.notice",
            termsLinkTextKey: "anywhereMobileOnboarding.termsLinkText",
        },
        winback: {
            customLogo: WinbackLogo,
            customLogoWidth: "230px",
            headingTextKey: "winbackonboarding.salesCost",
            additionalTextKey: "winbackonboarding.pricingtext1",
            termsLinkTextKey: "winbackonboarding.termslinktext",
            hideTransactionFeeHeader: true,
            priceLines: [
                <OnboardingPricingLine
                    enlargeSpans
                    key="winback-basic-1"
                    priceHtml={getTextWithInvoicingPrices("winbackonboarding.pricing1", prices, {
                        winbackPrice: { type: "SwiipeWinbackPctBasic", wrapPriceInElement: "span" },
                    })}
                />,
                <OnboardingPricingLine enlargeSpans key={"winback-basic-2"} priceHtml={getText("winbackonboarding.pricing2")} />,
            ],
        },
        mpo: {
            customLogo: MobilepayTextLogo,
            customLogoWidth: "130px",
            headingTextKey: "mpoonboarding.pricingDialog.cost",
            additionalTextKey: "mpoonboarding.pricingDialog.addonToCardPayments",
            termsLinkTextKey: "mpoonboarding.pricingDialog.termsLinkText",
            priceLines: [
                <OnboardingPricingLine
                    enlargeSpans
                    key="mpo"
                    priceHtml={getTextWithInvoicingPrices("plugins.mobilepayOnlinePrice", prices, {
                        mpoMonthly: { type: "MobilePayOnlineMonthlyShared" },
                        mpoTx: { type: "MobilePayOnlineTransactionStep1Shared" },
                    })}
                />,
            ],
        },
        swish: {
            customLogo: SwishLogo,
            customLogoWidth: "160px",
            headingTextKey: "swishonboarding.pricingDialog.cost",
            termsLinkTextKey: "swishonboarding.pricingDialog.termsLinkText",
            priceLines: [
                <OnboardingPricingLine
                    enlargeSpans
                    key="swish"
                    priceHtml={getTextWithInvoicingPrices("plugins.swishPrice", prices, {
                        swishTx: { type: "SwishTransactionShared" },
                    })}
                />,
            ],
        },
        klarna: {
            customLogo: getImage("klarnaLogo"),
            customLogoWidth: "170px",
            headingTextKey: "klarnaonboarding.pricingDialog.cost",
            termsLinkTextKey: "klarnaonboarding.pricingDialog.termsLinkText",
            priceLines: [
                <OnboardingPricingLine
                    enlargeSpans
                    key="klarna"
                    priceHtml={getTextWithInvoicingPrices("plugins.klarnaPrice", prices, {
                        klarnaTx: { type: "KlarnaTransactionShared" },
                    })}
                />,
            ],
        },
        vipps: {
            customLogo: getImage("vippsLogo"),
            customLogoWidth: "170px",
            headingTextKey: "vippsonboarding.pricingDialog.cost",
            additionalTextKey: "vippsonboarding.pricingDialog.addonToCardPayments",
            termsLinkTextKey: "vippsonboarding.pricingDialog.termsLinkText",
            priceLines: [
                <OnboardingPricingLine
                    enlargeSpans
                    key="vipps"
                    priceHtml={getTextWithInvoicingPrices("plugins.vippsPrice", prices, {
                        vippsTx: { type: "VippsTransactionShared" },
                    })}
                />,
            ],
        },
        applepay: {
            customLogo: getImage("applePayLogo"),
            customLogoWidth: "80px",
            customLogoClass: "applepay-logo",
            headingTextKey: "applepayonboarding.pricingDialog.cost",
            additionalTextKey: "applepayonboarding.pricingDialog.addonToCardPayments",
            termsLinkTextKey: "applepayonboarding.pricingDialog.termsLinkText",
            priceLines: [
                <OnboardingPricingLine
                    enlargeSpans
                    key="applepay"
                    priceHtml={getTextWithInvoicingPrices("plugins.applePayPrice", prices, {
                        applePayTx: { type: "ApplePayTransactionShared" },
                    })}
                />,
            ],
        },
        accountToAccount: {
            customLogo: getImage("a2aLogo"),
            customLogoWidth: "200px",
            customLogoClass: "a2a-logo",
            additionalLogos: [
                {
                    src: getImage("poweredByAiiaLogo"),
                    alt: "powered-by-aiia-logo",
                    width: "110px",
                },
            ],
            headingTextKey: "a2aonboarding.pricingDialog.cost",
            termsLinkTextKey: "a2aonboarding.pricingDialog.termsLinkText",
            priceLines: [
                <OnboardingPricingLine
                    enlargeSpans
                    key="a2a"
                    priceHtml={getTextWithInvoicingPrices("plugins.a2aPrice", prices, {
                        a2aMonthly: { type: "A2aMonthlyShared" },
                        a2aMinTx: { type: "A2aMinTransactionShared" },
                    })}
                />,
            ],
        },
        resurs: {
            customLogo: getImage("resursLogo"),
            customLogoWidth: "200px",
            customLogoClass: "resurs-logo",
            headingTextKey: "resursOnboarding.pricingDialog.cost",

            termsLinkTextKey: "resursOnboarding.termsAndConditionsCheckbox.legalTextAfterLink",
            priceLines: [
                <OnboardingPricingLine key={"ResursInvoicePctTransactionShared"}>
                    <OnboardingPriceLineLarge
                        priceValue={getResolvedInvoicingPrice(prices, {
                            type: "ResursInvoicePctTransactionShared",
                            hidePercentSign: true,
                        })}
                        customPriceSymbol="%"
                        serviceName={getText("serviceNames.resursInvoice").replace("Resurs", "")}
                    />
                </OnboardingPricingLine>,
                <OnboardingPricingLine key={"ResursPartPaymentPctTransactionShared"}>
                    <OnboardingPriceLineLarge
                        priceValue={getResolvedInvoicingPrice(prices, {
                            type: "ResursPartPaymentPctTransactionShared",
                            hidePercentSign: true,
                        })}
                        customPriceSymbol="%"
                        serviceName={getText("serviceNames.resursPartPayment").replace("Resurs", "")}
                    />
                </OnboardingPricingLine>,
                <OnboardingPricingLine key={"ResursRevolvingCreditPctTransactionShared"}>
                    <OnboardingPriceLineLarge
                        priceValue={getResolvedInvoicingPrice(prices, {
                            type: "ResursRevolvingCreditPctTransactionShared",
                            hidePercentSign: true,
                        })}
                        customPriceSymbol="%"
                        serviceName={getText("serviceNames.resursRevolvingCredit").replace("Resurs", "")}
                    />
                </OnboardingPricingLine>,
            ],
        },
        externalAnalytics: {
            customLogo: getImage("googleAnalyticsLogo"),
            customLogoWidth: "140px",

            headingTextKey: "analyticsOnboarding.toConnect",
            subHeading: {
                textKey: "analyticsOnboarding.dontHaveOne",
                link: {
                    keyInText: "{{googleAnalyticsLink}}",
                    translationKey: "common.here",
                    address: "https://analytics.google.com",
                    openInNewTab: true,
                },
            },

            termsLinkTextKey: "analyticsOnboarding.termsLinkTextKey",
        },
    }
}

export interface IOnboardingPricingDialog {
    onboardingType: TOnboardingType
    onGoBack: () => void
    onGoNext: () => void
}
export const OnboardingPricingDialog = ({ onboardingType, onGoBack, onGoNext }: IOnboardingPricingDialog) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const prices = useSelector(billingSelectors.resolvedPrices)
    const termsStatuses = useSelector(legalSelectors.termsStatuses)
    const swiipePlan = useSelector(merchantSelectors.swiipePlan)
    const { handleSubmit, register, formState } = useForm<IFormModel>()

    const configs: IPricingDialogsConfig = getPriceDialogConfigs(prices ?? [], swiipePlan)
    const config = configs[onboardingType]
    const termsType = getTermsTypeForOnboardingType(onboardingType)

    useEffect(() => {
        if (!termsStatuses) {
            dispatch(ensureTermsStatusThunk(false))
            return
        }
        if (isAllTermsAccepted([termsType], termsStatuses ?? [])) {
            onGoNext()
        }
    }, [termsStatuses])

    const onSubmit: SubmitHandler<IFormModel> = async (data) => {
        //accept terms
        await dispatch(markTermsAsAcceptedThunk([termsType]))
        onGoNext()
    }

    if (!termsStatuses) {
        return null
    }

    return (
        <ServicesOnboardingPageWrapper
            containerClass="onboarding-shared-pricing"
            customLogo={config.customLogo}
            customLogoWidth={config.customLogoWidth}
            customLogoClass={config.customLogoClass}
            additionalLogos={config.additionalLogos}
        >
            <div className="onboarding-shared-pricing-wrapper">
                <p className="text-thin text-center">{htmlToReactParser(t(config.headingTextKey))}</p>
                {config.subHeading?.textKey && (
                    <p className="text-thin text-center">
                        {config.subHeading.link ? (
                            <TextTranslatedWithLinks
                                textTranslationKey={config.subHeading.textKey}
                                linkKeyInText={config.subHeading.link.keyInText}
                                linkTranslationKey={config.subHeading.link.translationKey}
                                linkAddress={config.subHeading.link.address}
                                openInNewTab={config.subHeading.link.openInNewTab}
                            />
                        ) : (
                            htmlToReactParser(t(config.subHeading.textKey))
                        )}
                    </p>
                )}
                {config.priceLines && config.priceLines.length !== 0 && (
                    <div className={cn("onboarding-price-container", "mb-0", "text-center")}>
                        {!config.hideTransactionFeeHeader && (
                            <p className="onboarding-price--transaction-fees">{t("swiipeservicesonboarding.transactionFees")}</p>
                        )}
                        {config.priceLines.map((priceLine) => priceLine)}
                    </div>
                )}
                {config.additionalTextKey && <p className="text-blue text-center mt-4">{t(config.additionalTextKey)}</p>}

                <Form onSubmit={handleSubmit(onSubmit)} className="onboarding-shared-pricing__form mt-5">
                    <TermsAndConditionsCheckbox
                        inputName="acceptTerms"
                        termsType={termsType}
                        parentFormRegister={register}
                        linkText={t(config.termsLinkTextKey)}
                        centered={false}
                    />

                    <ShowErrorMessages formState={formState} />

                    <SubmitButton dark noBorder containerClass="submit-btn  mt-4" formState={formState}>
                        {t("swiipeservicesonboarding.continue")}
                    </SubmitButton>
                </Form>

                <TextButton
                    text={t("commononboardingtexts.goback")}
                    className={cn("mt-3 mb-2")}
                    onClick={async () => onGoBack()}
                />
            </div>
        </ServicesOnboardingPageWrapper>
    )
}

export default OnboardingPricingDialog
