import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { TFeatureType, requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { TOnboardingStatus } from "../../type/IProvider"
import { IWebshopDetails } from "../../type/IWebshopDetails"
import { StoreState } from "../StoreState"
import { merchantReducerActions, merchantSelectors } from "../reducers/merchantReducer"
import { fetchWebshopFeaturesDetailsThunk } from "./merchantThunks"

export type TWebshopFileType = "WebshopLogo"

export const uploadWebshopFileThunk =
    (
        merchantId: string,
        webshopId: string,
        fileType: TWebshopFileType,
        file: File
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(
                endpoints.Identity.uploadWebshopFile,
                {
                    data: {
                        swMerchantId: merchantId,
                        webshopId: webshopId,
                        fileType: fileType,
                        file: file,
                    },
                },
                {
                    asFormData: true,
                }
            )
        )

        dispatch(getWebshopDetailsThunk(webshopId, true))
    }

export const setWebshopFeaturesThunk =
    (
        webshopIds: string[],
        featureType: TFeatureType,
        featureEnabled: boolean,
        swMerchantId: string,
        onboardingStatus?: TOnboardingStatus
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.Identity.setFeatureStatus, {
                data: {
                    featureType: featureType,
                    webshopIds: webshopIds,
                    enabled: featureEnabled,
                    onboardingStatus: onboardingStatus,
                    swMerchantId: swMerchantId,
                },
            })
        )

        for (let i = 0; i < webshopIds.length; i++) {
            await dispatch(fetchWebshopFeaturesDetailsThunk(webshopIds[i], true))
        }
    }

export const getWebshopDetailsThunk =
    (webshopId: string, force?: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const existingDetails = merchantSelectors.webshopDetails(getState(), webshopId)

        if (existingDetails && !force) {
            return
        }

        const details = await dispatch(
            requestThunk<IWebshopDetails>(endpoints.Identity.getWebshopDetails, {
                params: {
                    webshopId: webshopId,
                },
            })
        )

        dispatch(merchantReducerActions.setWebshopDetails(webshopId, details))
    }

export const setWebshopDetailsThunk =
    (
        swMerchantId: string,
        webshopIds: string[],
        webshopDetails: IWebshopDetails[]
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<IWebshopDetails>(endpoints.Identity.setWebshopDetails, {
                data: {
                    swMerchantId,
                    webshopIds,
                    webshopDetails,
                },
            })
        )

        for (let i = 0; i < webshopIds.length; i++) {
            await dispatch(getWebshopDetailsThunk(webshopIds[i], true))
        }
    }
