import { getCulture } from "../locale/lang"
import { TNumberFormat } from "../types/general/TNumberFormat"

export function getFormattedNumber(
    number: number,
    options?: {
        numberFormat?: TNumberFormat
        omitThousandSeparator?: boolean
    }
) {
    let formattedAmount = formatNumber(number, options?.omitThousandSeparator)
    if (options?.numberFormat === "forceTwoDecimals") {
        formattedAmount = formatNumberWithDecimals(number, 2, true, options?.omitThousandSeparator)
    } else if (options?.numberFormat === "forceZeroDecimals") {
        formattedAmount = formatNumberWithDecimals(number, 0, true, options?.omitThousandSeparator)
    } else if (options?.numberFormat === "maxTwoDecimals") {
        formattedAmount = formatNumberWithDecimals(number, 2, false)
    } else if (!options?.numberFormat || options?.numberFormat === "decimalsNormalForCurrency") {
        // default
        // Use currency service, for more advanced decimals per currency
        formattedAmount = formatNumberWithDecimals(number, 2, true)
        if (formattedAmount.endsWith("00")) {
            formattedAmount = formatNumberWithDecimals(number, 0, true)
        }
    }
    return formattedAmount
}

export function numberToFixedDecimals(input: string | number, decimals: number): string {
    const i = typeof input === "string" ? parseFloat(input as string) : input
    return i.toFixed(decimals)
}

function formatNumberWithDecimals(
    input: string | number,
    decimals: number,
    forceDecimals: boolean,
    omitThousandSeparator?: boolean
): string {
    if (forceDecimals) {
        const rounded = roundNumberToMaxDecimals(input, decimals)
        return formatNumber(rounded, omitThousandSeparator, decimals)
    }

    // maxTwoDecimals is default
    const rounded = roundNumberToMaxDecimals(input, decimals)
    return formatNumber(rounded, omitThousandSeparator)
}

export function roundNumberToMaxDecimals(input: string | number, maxDecimals: number): number {
    const i = typeof input === "string" ? parseFloat(input as string) : input
    const enInput = i.toLocaleString("en-GB", { maximumFractionDigits: maxDecimals })
    const parts = enInput.split(".")
    const calcedDecimals = Math.min(parts.length < 2 ? 0 : parts[1].length, maxDecimals)
    return roundToDecimals(i, calcedDecimals)
}

function roundToDecimals(num: number, decimals: number): number {
    const tenPowered = Math.pow(10, decimals)
    const m = Number((Math.abs(num) * tenPowered).toPrecision(15))
    return (Math.round(m) / tenPowered) * Math.sign(num)
}

function formatNumber(num: number, omitThousandSeparator?: boolean, forceDecimals?: number) {
    return num.toLocaleString(getCulture(), {
        useGrouping: !(omitThousandSeparator ?? false),
        minimumFractionDigits: forceDecimals,
        maximumFractionDigits: forceDecimals,
    })
}

export function getFullNameFromFirstAndLastName(firstName: string | undefined, lastName: string | undefined) {
    if (firstName && lastName) {
        return `${firstName} ${lastName}`
    }
    return firstName || lastName || ""
}
