import React from "react"
import { Link } from "react-navi"
import cn from "classnames"
import "./LinkButton.scss"
import { navigationService } from "../../services/navigationService"

interface ILinkButtonProps {
    href?: string
    className?: string
    id?: string
    textOnly?: boolean
    title?: string
    children?: React.ReactNode
    isSandbox?: boolean
    goBack?: boolean
    dark?: boolean
    inline?: boolean
    target?: React.HTMLAttributeAnchorTarget
}

const LinkButton = ({
    textOnly,
    title,
    dark,
    children,
    isSandbox,
    href,
    id,
    className,
    goBack,
    inline,
    target,
}: ILinkButtonProps) => {
    if (textOnly) {
        return (
            <Link
                target={target}
                style={{ display: inline ? "inline" : undefined }}
                onClick={(e) => {
                    if (goBack) {
                        e.preventDefault()
                        navigationService.navigateBack()
                    }
                }}
                href={href ?? ""}
                id={id}
                className={cn("swiipe-link-href", { "sandbox-link": isSandbox }, className)}
            >
                <p className="p-link mb-0">
                    {title}
                    {children}
                </p>
            </Link>
        )
    }
    return (
        <button
            className={cn(
                "swiipe-link-button btn swiipe-btn",
                {
                    "swiipe-btn-white": !dark,
                    "swiipe-btn-dark": dark,
                },
                className
            )}
            id={id}
            type="button"
            onClick={() => (goBack ? navigationService.navigateBack() : navigationService.navigate(href ?? ""))}
        >
            {title}
            {children}
        </button>
    )
}

export default LinkButton
