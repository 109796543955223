import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigation } from "react-navi"
import {
    EMerchantOfferStep,
    MerchantOfferContentContainer,
    MerchantOfferFooter,
    MerchantOfferHeader,
    MerchantOfferIntro,
    MerchantOfferOrderSummary,
    MerchantOfferSteps,
    PageWrapper,
    SpinnerContainer,
    StandardButtonWithSpinner,
    organizationSelectors,
    parseMerchantOfferStep,
    useSelector,
    userSelectors,
} from "swiipe.portal.shared"
import MerchantOfferTerms from "../../components/merchantOffer/MerchantOfferTerms"
import { isAllTermsAccepted } from "../../services/legalService"
import {
    getRequiredTermsForMerchantPreOnboardingServices,
    useMerchantPreOnboardingDetails,
} from "../../services/merchantOfferService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { billingSelectors } from "../../store/reducers/billingReducer"
import { legalSelectors } from "../../store/reducers/legalReducer"
import { merchantPreOnboardingActions, merchantPreOnboardingSelectors } from "../../store/reducers/merchantPreOnboardingSlice"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { partnerSelectors } from "../../store/reducers/partnerReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { fetchInvoicingPricesThunk } from "../../store/thunks/billingThunks"
import {
    getMerchantPreOnboardingDetailsThunk,
    merchantOfferAddEventThunk,
    merchantOfferNavigateToStepThunk,
} from "../../store/thunks/merchantPreOnboardingThunks"
import { ensureTermsStatusThunk } from "../../store/thunks/termsThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import "./MerchantOfferPage.scss"

interface IMerchantOfferPageProps {}

const MerchantOfferPage = ({}: IMerchantOfferPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const { t } = useTranslation()
    const navigation = useNavigation()
    const dispatch = useReduxDispatch()

    const userData = useSelector(userSelectors.userData)
    const hasMasterPartner = !!useSelector(partnerSelectors.partnersContainMasterPartner)
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    const initialNavigationDone = useSelector(merchantPreOnboardingSelectors.initialNavigationDone)
    const preOnboardingDetails = useMerchantPreOnboardingDetails()
    const prices = useSelector(billingSelectors.resolvedPrices)
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const termsStatuses = useSelector(legalSelectors.termsStatuses)
    const step = parseMerchantOfferStep(navigation.getCurrentValue().url.query.step)
    const [maxStepAllowed, setMaxStepAllowed] = useState<number>(-1)
    const company = useSelector((state: StoreState) =>
        organizationSelectors.companyData(
            state,
            preOnboardingDetails?.exists ? preOnboardingDetails?.preOnboarding.companyRegistrationNumber ?? "" : ""
        )
    )
    const needsAcquiring = preOnboardingDetails?.preOnboarding?.requiresAcquiring ?? false

    const preOnb = preOnboardingDetails?.preOnboarding
    const owner = preOnboardingDetails?.owner
    const creator = preOnboardingDetails?.creator

    useEffect(() => {
        dispatch(fetchInvoicingPricesThunk(false))
        dispatch(getMerchantPreOnboardingDetailsThunk())
    }, [currentUserRelation?.id])

    useEffect(() => {
        dispatch(ensureTermsStatusThunk(false))
    }, [currentUserRelation?.id])

    const changeStep = (newStep: EMerchantOfferStep) => dispatch(merchantOfferNavigateToStepThunk(newStep))

    useEffect(() => {
        if (preOnboardingDetails && !preOnboardingDetails.exists) {
            navigation.navigate("/")
            return
        }
        if (preOnboardingDetails && ((owner?.email && userData?.user.email === owner.email) || !hasMasterPartner)) {
            dispatch(merchantOfferAddEventThunk("Offer viewed", `Viewed by ${userData?.user.email}`, "Viewed"))
        }
        if (preOnboardingDetails && !preOnboardingDetails.preOnboarding?.isSigned) {
            const terms = getRequiredTermsForMerchantPreOnboardingServices(preOnboardingDetails.services)
            const allAccepted = isAllTermsAccepted(terms, termsStatuses ?? [])
            if (allAccepted) {
                dispatch(merchantOfferAddEventThunk("Offer signed", `Offer signed by ${userData?.user.email ?? ""}`, "Signed"))
            }
        }
        if (preOnboardingDetails && termsStatuses) {
            const termsRequired = getRequiredTermsForMerchantPreOnboardingServices(preOnboardingDetails.services)
            const allTermsAccepted = isAllTermsAccepted(termsRequired, termsStatuses)
            if (allTermsAccepted) {
                setMaxStepAllowed(3)
            } else {
                setMaxStepAllowed(1)
            }
            if (!initialNavigationDone) {
                dispatch(merchantPreOnboardingActions.setInitialNavigationDone(currentUserRelation?.id ?? ""))
                if (allTermsAccepted) {
                    if (needsAcquiring) {
                        changeStep("kyc")
                    } else {
                        navigation.navigate("/plugins")
                    }
                } else {
                    changeStep("info")
                }
            }
        }
    }, [preOnboardingDetails, termsStatuses])

    return (
        <PageWrapper fillAll className="merchant-offer-page">
            <MerchantOfferHeader
                company={company}
                countryCode={preOnboardingDetails?.preOnboarding?.countryCode ?? merchantDetails?.swMerchant.countryCode}
                owner={owner}
                creator={creator}
                created={preOnb?.firstSentTime ?? preOnb?.created}
            />
            <SpinnerContainer
                showSpinner={!initialNavigationDone}
                className={cn(
                    "merchant-offer-page__content-spinner",
                    !preOnboardingDetails && "merchant-offer-page__content-spinner--loading"
                )}
            >
                <MerchantOfferContentContainer>
                    <MerchantOfferSteps
                        currentStep={step}
                        className="merchant-offer-page__steps"
                        maxStepAllowed={maxStepAllowed}
                        onChangeStep={(newStep) => {
                            changeStep(newStep)
                        }}
                        includeKyc={needsAcquiring}
                        marginBottom={45}
                    />
                    {step === "info" && (
                        <MerchantOfferIntro
                            services={preOnboardingDetails?.exists ? preOnboardingDetails?.services : undefined}
                            owner={owner}
                            message={preOnb?.merchantOfferMessage}
                        />
                    )}
                    {step === "info" && (
                        <MerchantOfferOrderSummary
                            prices={prices}
                            services={preOnboardingDetails?.exists ? preOnboardingDetails?.services : undefined}
                            webshops={merchantDetails?.webshops}
                        />
                    )}
                    {step === "info" && (
                        <div className="merchant-offer-page__nav-button-container">
                            <StandardButtonWithSpinner
                                containerClass="merchant-offer-page__nav-button"
                                className="merchant-offer-page__nav-button-inner"
                                title={step === "info" ? t("common.continue") : t("Accept and sign")}
                                isOrange
                                onClick={async () => {
                                    if (step === "info") {
                                        setMaxStepAllowed(Math.max(2, maxStepAllowed))
                                        changeStep("terms")
                                    }
                                }}
                            />
                        </div>
                    )}
                    {step === "terms" && owner && (
                        <MerchantOfferTerms
                            owner={owner}
                            creator={creator}
                            swMerchantId={preOnb?.swMerchantId ?? ""}
                            services={preOnboardingDetails?.services}
                            termsStatuses={termsStatuses}
                            requiresKyc={needsAcquiring}
                            onContinue={() => {
                                if (needsAcquiring) {
                                    setMaxStepAllowed(Math.max(3, maxStepAllowed))
                                    changeStep("kyc")
                                } else {
                                    navigation.navigate("/plugins")
                                }
                            }}
                        />
                    )}
                    {step === "kyc" && <div>Step 3</div>}

                    <MerchantOfferFooter creator={creator} />
                </MerchantOfferContentContainer>
            </SpinnerContainer>
        </PageWrapper>
    )
}

export default MerchantOfferPage
