import cn from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { DeepMap, FieldError } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Row } from "reactstrap"
import { getFullVatNumber } from "../../../services/companyDetailsMapper"
import {
    getCompanyNumberPlaceholder,
    getSearchBarOptions,
    getSearchBarPlaceholder,
    isLookupSupported,
    searchForCompany,
} from "../../../services/companySearchService"
import { companySearchSelectors } from "../../../store/companySearchReducer"
import { useReduxDispatchShared } from "../../../store/useReduxDispatchShared"
import { lookupCityByZipcodeThunk } from "../../../thunks/addressLookupThunk"
import { setSelectedSearchCompanyThunk } from "../../../thunks/companyThunks"
import { ICompanyFieldsBase } from "../../../types/company/ICompanyFieldsBase"
import { TClearError, TRegister, TSetError, TSetValue, TWatch } from "../../../types/form/ReactHooksFormTypes"
import { TCountryCode } from "../../../types/general/countryCodes"
import { valFuncRequiredAndPattern, validationPatterns, validationPatternsWithLocale } from "../../../utils/validationUtil"
import { IconButton } from "../../buttons/IconButton"
import { SpinnerContainer } from "../../loading/SpinnerContainer"
import { FieldsetFormGroup } from "../FieldsetFormGroup"
import { FloatingLabelInput } from "../input/FloatingLabelInput"
import { CompanyDetailsPreviewComp } from "./CompanyDetailsPreviewComp"
import "./CompanyNameAndCVRInput.scss"

export interface ICompanyNameAndCVRInputModel {
    country: TCountryCode

    searchBar: string

    companyCity: string
    companyName: string
    companyNumber: string
    companyAddress: string
    companyPostcode: string
}

interface ICompanyNameAndCVRInput<TForm> {
    register: TRegister
    getValues: () => TForm
    setValue: TSetValue<TForm>
    clearError: TClearError<TForm>
    setError: TSetError<TForm>
    watch: TWatch<TForm>
    errors: DeepMap<ICompanyNameAndCVRInputModel, FieldError> | undefined
    fieldSelectorPrefix?: string
    getModelFromForm?: (form: TForm) => ICompanyNameAndCVRInputModel

    isDisabled?: boolean
    isInitiallyCompanyFieldsShown?: boolean
    // For adding company fields to form fields - not always necessary
    resetCompanyFields?: (model: ICompanyNameAndCVRInputModel) => void
    allowGoBackToSearch?: boolean
}

export const CompanyNameAndCVRInput = <TForm extends {}>({
    register,
    getValues,
    setValue,
    clearError,
    setError,
    watch,
    errors,
    isDisabled,
    fieldSelectorPrefix,
    getModelFromForm,
    isInitiallyCompanyFieldsShown,
    resetCompanyFields,
    allowGoBackToSearch,
}: ICompanyNameAndCVRInput<TForm>) => {
    const [isSearchStateActive, setIsSearchActive] = useState(!isInitiallyCompanyFieldsShown)
    const isSearchActive = isSearchStateActive && !isDisabled

    const [showSearchResults, setShowSearchResults] = useState(false)
    const [focusOnSearchContainer, setFocusOnSearchContainer] = useState(false)
    const [hasLookupErrors, setHasLookupErrors] = useState(false)
    const searchInputTimerRef = useRef<NodeJS.Timeout | undefined>()
    const searchResults = useSelector(companySearchSelectors.results)
    const selectedCompany = useSelector(companySearchSelectors.selected)
    const executingSearchRequest = useSelector(companySearchSelectors.executingSearchRequest)
    const { t } = useTranslation()
    const dispatch = useReduxDispatchShared()

    const getFieldSelector = (fieldName: string) => ((fieldSelectorPrefix ?? "") + fieldName) as any
    const getModel: () => ICompanyNameAndCVRInputModel = () =>
        getModelFromForm ? getModelFromForm(getValues()) : (getValues() as unknown as ICompanyNameAndCVRInputModel)

    useEffect(() => {
        //Drop selected company on component initialization
        dispatch(setSelectedSearchCompanyThunk())
    }, [])

    useEffect(() => {
        if (isSearchActive) {
            setNotFoundError()
        }
    }, [searchResults])

    const companySearchActions = {
        setVatNotCorrespondsToCountryError: () => {
            setError(getFieldSelector("searchBar"), { message: t("companysearch.errors.vatNotCorrespondsToCountry") })
        },
        clearError: () => {
            clearError(getFieldSelector("searchBar"))
        },
        lookupErrorCallback: (notFound: boolean) => {
            if (notFound) {
                setNotFoundError()
                return
            }

            setHasLookupErrors(true)
            setIsSearchActive(false)
        },
    }

    useEffect(() => {
        if (!isSearchActive) {
            return
        }

        const searchBar = getModel().searchBar
        const country = getModel().country

        if (!isLookupSupported(country)) {
            setIsSearchActive(false)
        }

        const options = getSearchBarOptions(searchBar, country)

        if ((options.isVatSameWithSelectedCountry && options.isValidVat) || options.usingCvrApi) {
            clearError(getFieldSelector("searchBar"))
            searchForCompany(searchBar, country, dispatch, companySearchActions)
            setShowSearchResults(true)
        }
    }, [watch(getFieldSelector("country"))])

    const setNotFoundError = () => {
        const query = getModel().searchBar

        if (query != "" && searchResults.length == 0) {
            if (!errors?.searchBar) {
                setError(getFieldSelector("searchBar"), { message: t("companysearch.errors.companyNotFound") })
            }
        } else if (searchResults.length > 0) {
            if (errors?.searchBar) {
                clearError(getFieldSelector("searchBar"))
            }
        }
    }

    const setSelectedCompany = (company?: ICompanyFieldsBase) => {
        dispatch(setSelectedSearchCompanyThunk(company))
    }

    const showChangeToSearch =
        !isDisabled && allowGoBackToSearch && !isSearchActive && !hasLookupErrors && isLookupSupported(getModel().country)

    const getSearchBarError = () => {
        const options = getSearchBarOptions(getModel().searchBar, getModel().country)
        if (!options.isVatSameWithSelectedCountry && !options.usingCvrApi) {
            return t("companysearch.errors.vatNotCorrespondsToCountry")
        }
        if (!selectedCompany) {
            return t("companysearch.errors.companyNotSelected")
        }
        return undefined
    }

    return (
        <div className="company-name-cvr-input">
            <>
                <FieldsetFormGroup className={cn({ "d-none": isSearchActive }, "pt-3")} field>
                    <FloatingLabelInput
                        disabled={isDisabled}
                        name={getFieldSelector("companyName")}
                        innerRef={register(
                            valFuncRequiredAndPattern(validationPatterns.companyName, t("common.companyName.errorMessage"))
                        )}
                        placeholder={t("companysearch.companyName")}
                    />
                </FieldsetFormGroup>
                <FieldsetFormGroup className={cn({ "d-none": isSearchActive })} field>
                    <FloatingLabelInput
                        disabled={isDisabled}
                        name={getFieldSelector("companyNumber")}
                        innerRef={register(
                            valFuncRequiredAndPattern(
                                validationPatternsWithLocale.cvr(getModel().country),
                                t("companysearch.errors.enterActiveCvr")
                            )
                        )}
                        placeholder={getCompanyNumberPlaceholder(getModel().country)}
                    />
                </FieldsetFormGroup>
                <FieldsetFormGroup className={cn({ "d-none": isSearchActive })} field>
                    <FloatingLabelInput
                        disabled={isDisabled}
                        name={getFieldSelector("companyAddress")}
                        innerRef={register(
                            valFuncRequiredAndPattern(validationPatterns.addressStreet, t("common.address.errorMessage"))
                        )}
                        placeholder={t("common.address.label")}
                    />
                </FieldsetFormGroup>
                <FieldsetFormGroup className={cn({ "d-none": isSearchActive }, !showChangeToSearch && "pb-3")} field>
                    <Row>
                        <div className="col-12 pl-3 col-md-6">
                            <FloatingLabelInput
                                disabled={isDisabled}
                                name={getFieldSelector("companyPostcode")}
                                onChange={async (e) => {
                                    const zipcode = e.currentTarget.value
                                    const city = await dispatch(lookupCityByZipcodeThunk(zipcode))
                                    if (city) {
                                        setValue(getFieldSelector("companyCity"), city, { shouldValidate: true })
                                    }
                                }}
                                innerRef={register(
                                    valFuncRequiredAndPattern(
                                        validationPatternsWithLocale.addressPostalCode(getModel().country),
                                        t("common.postCode.errorMessage")
                                    )
                                )}
                                placeholder={t("common.postCode.label")}
                            />
                        </div>
                        <div className="col-12 pl-3 col-md-6 pl-md-0">
                            <FloatingLabelInput
                                disabled={isDisabled}
                                name={getFieldSelector("companyCity")}
                                innerRef={register(
                                    valFuncRequiredAndPattern(validationPatterns.addressCity, t("common.city.errorMessage"))
                                )}
                                placeholder={t("common.city.label")}
                            />
                        </div>
                    </Row>
                </FieldsetFormGroup>
            </>

            {showChangeToSearch && (
                <div className={cn("select-manually")}>
                    <IconButton
                        className="mb-4"
                        text={t("companysearch.useSearch")}
                        onClick={() => {
                            setIsSearchActive(true)
                        }}
                        isSmall
                        icon="add"
                    />
                </div>
            )}

            {isSearchActive && (
                <FieldsetFormGroup className={cn({ "d-none": !!selectedCompany })} field>
                    <FloatingLabelInput
                        autoCompleteOff
                        innerRef={register({
                            validate: (value: string) => {
                                return getSearchBarError() ?? true
                            },
                            required: getSearchBarOptions(getModel().searchBar, getModel().country).usingCvrApi
                                ? t("companysearch.errors.enterActiveCvrOrName")
                                : t("companysearch.errors.enterActiveCvr"),
                        })}
                        name={getFieldSelector("searchBar")}
                        placeholder={getSearchBarPlaceholder(getModel().country)}
                        onChange={() => {
                            if (searchInputTimerRef.current) {
                                clearTimeout(searchInputTimerRef.current)
                            }
                            searchInputTimerRef.current = setTimeout(() => {
                                searchForCompany(getModel().searchBar, getModel().country, dispatch, companySearchActions)
                            }, 400)
                        }}
                        onBlur={() => setShowSearchResults(false)}
                        onFocus={() => setShowSearchResults(true)}
                    />
                    <div
                        className={cn("search-container", {
                            "d-none":
                                ((searchResults.length === 0 && !getSearchBarError()) || !showSearchResults) &&
                                !focusOnSearchContainer &&
                                !executingSearchRequest,
                        })}
                    >
                        <SpinnerContainer
                            className={cn({ "mt-3 mb-4": !!executingSearchRequest })}
                            showSpinner={!!executingSearchRequest}
                        >
                            <div
                                className="wrapper"
                                onMouseOver={() => setFocusOnSearchContainer(true)}
                                onMouseLeave={() => setFocusOnSearchContainer(false)}
                            >
                                {searchResults.map((result) => {
                                    return (
                                        <div
                                            className="option"
                                            key={result.companyNumber}
                                            onClick={() => {
                                                setSelectedCompany(result)
                                                if (resetCompanyFields) {
                                                    resetCompanyFields({
                                                        companyAddress: result.companyAddress ?? "",
                                                        companyCity: result.companyCity ?? "",
                                                        companyName: result.companyName ?? "",
                                                        companyNumber: result.companyNumber ?? "",
                                                        companyPostcode: result.companyPostcode ?? "",
                                                        country: result.companyCountry as TCountryCode,
                                                        searchBar: getModel().searchBar,
                                                    })
                                                } else {
                                                    setValue(getFieldSelector("companyAddress"), result.companyAddress ?? "")
                                                    setValue(getFieldSelector("companyCity"), result.companyCity ?? "")
                                                    setValue(getFieldSelector("companyName"), result.companyName ?? "")
                                                    setValue(getFieldSelector("companyNumber"), result.companyNumber ?? "")
                                                    setValue(getFieldSelector("companyPostcode"), result.companyPostcode ?? "")
                                                    setValue(getFieldSelector("searchBar"), getModel().searchBar)
                                                    setValue(getFieldSelector("country"), result.companyCountry)
                                                }
                                            }}
                                        >
                                            {getFullVatNumber(result)} {result.companyName} -{" "}
                                            <span className="cityname">{result.companyCity}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </SpinnerContainer>
                        <div
                            className={cn("select-manually", { "mt-0": searchResults.length === 0 })}
                            onMouseOver={() => setFocusOnSearchContainer(true)}
                            onMouseLeave={() => setFocusOnSearchContainer(false)}
                        >
                            <IconButton
                                text={t("companysearch.entermanualy")}
                                onClick={() => {
                                    setIsSearchActive(!isSearchActive)
                                }}
                                isSmall
                                icon="add"
                            />
                        </div>
                    </div>
                </FieldsetFormGroup>
            )}

            {!!selectedCompany && (
                <CompanyDetailsPreviewComp details={selectedCompany} wrongCompanyAction={() => setSelectedCompany()} />
            )}
        </div>
    )
}
