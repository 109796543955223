import { ActionsUnion } from "../types/state/actionsUnion"
import { createAction } from "../types/state/createAction"
import { IPaymentMethods } from "../types/user/IPaymentMethods"
import { ISearchedUser } from "../types/user/ISearchedUser"
import { ISignupDetails } from "../types/user/ISignupDetails"
import { UserDataDto } from "../types/user/UserDataDto"
import { UserIdentityDto } from "../types/user/UserDto"
import { SharedStoreState } from "./SharedStoreState"

export type ELoginSessionState =
    | "waitingForUserInteraction"
    | "userInteracted"
    | "checking"
    | "renewing"
    | "expired"
    | "renewNotPossible"

export type UserState = Readonly<{
    users: { [userId: string]: UserIdentityDto | undefined }
    userData?: UserDataDto
    userRegisterData?: ISignupDetails
    userEmailLoginFormPrefill?: string
    userPaymentMethods?: IPaymentMethods
    loginSession: {
        validUntil?: Date
        timeToRenew?: Date
        state?: ELoginSessionState
    }
    searchedUsers?: ISearchedUser[]
}>
const initialState: UserState = {
    loginSession: {},
    users: {},
}

export const userReducerActions = {
    setUserBaseData: (userData: UserIdentityDto) => createAction("APP/SET_USER_BASE_DATA", { userData }),
    setUserData: (userData: UserDataDto) => createAction("APP/SET_USER_DATA", { userData }),
    setUserRegisterData: (userRegisterData: ISignupDetails) => createAction("APP/SET_USER_REGISTER_DATA", { userRegisterData }),
    removeUserRegisterData: () => createAction("APP/REMOVE_USER_REGISTER_DATA", {}),
    setUserEmailLoginFormPrefill: (email?: string) => createAction("APP/SET_EMAIL_LOGIN_FORM_PREFILL", { email }),
    setUserPaymentMethdos: (paymentMethods: IPaymentMethods) => createAction("APP/SET_USER_PAYMENT_MEHTODS", { paymentMethods }),
    setLoginSession: (validUntil: Date, timeToRenew: Date) => createAction("APP/SET_LOGIN_SESSION", { validUntil, timeToRenew }),
    setLoginSessionState: (state: ELoginSessionState) => createAction("APP/SET_LOGIN_SESSION_STATE", { state }),
    setSearchedUsers: (users: ISearchedUser[] | undefined) => createAction("APP/SET_SEARCHED_USERS", { users }),
}

export type UserActions = ActionsUnion<typeof userReducerActions>

export const userReducer = (state: UserState = initialState, action: UserActions) => {
    switch (action.type) {
        case "APP/SET_USER_DATA": {
            return {
                ...state,
                userData: action.userData,
            }
        }
        case "APP/SET_USER_BASE_DATA": {
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.userData.userId]: action.userData,
                },
            }
        }
        case "APP/SET_USER_REGISTER_DATA": {
            return {
                ...state,
                userRegisterData: action.userRegisterData,
            }
        }
        case "APP/REMOVE_USER_REGISTER_DATA": {
            return {
                ...state,
                userRegisterData: undefined,
            }
        }
        case "APP/SET_EMAIL_LOGIN_FORM_PREFILL": {
            return {
                ...state,
                userEmailLoginFormPrefill: action.email,
            }
        }
        case "APP/SET_USER_PAYMENT_MEHTODS": {
            return {
                ...state,
                userPaymentMethods: action.paymentMethods,
            }
        }
        case "APP/SET_LOGIN_SESSION": {
            return {
                ...state,
                loginSession: {
                    ...state.loginSession,
                    validUntil: action.validUntil,
                    timeToRenew: action.timeToRenew,
                },
            }
        }
        case "APP/SET_LOGIN_SESSION_STATE": {
            return {
                ...state,
                loginSession: {
                    ...state.loginSession,
                    state: action.state,
                },
            }
        }
        case "APP/SET_SEARCHED_USERS": {
            return {
                ...state,
                searchedUsers: action.users,
            }
        }
    }

    return state
}

export const userSelectors = {
    userById: (state: SharedStoreState, userId: string) => state.user.users[userId],
    userData: (state: SharedStoreState) => state.user.userData,
    userRegisterData: (state: SharedStoreState) => state.user.userRegisterData,
    userEmailLoginFormPrefill: (state: SharedStoreState) => state.user.userEmailLoginFormPrefill,
    userPaymentMethods: (state: SharedStoreState) => state.user.userPaymentMethods,
    loginSession: (state: SharedStoreState) => state.user.loginSession,
    searchedUsers: (state: SharedStoreState) => state.user.searchedUsers,
}
