import React from "react"
import { useTranslation } from "react-i18next"
import { getFormattedNumber } from "swiipe.portal.shared"
import { IMerchantDashboardServerData } from "../../../../type/merchantDashboard/IMerchantDashboardServerData"
import MerchantDashboardValueWithGraph from "../../tiles/MerchantDashboardTileValueWithGraph"

interface IDashboardRefundsDisplayProps {
    dashboardData: IMerchantDashboardServerData
    paymentsEnabled: boolean
}

const DashboardRefundsDisplay = ({ dashboardData, paymentsEnabled }: IDashboardRefundsDisplayProps) => {
    const { t } = useTranslation()

    const refunds = {
        main: dashboardData.mainData.refunds,
        compare: dashboardData.compareData?.refunds,
    }

    const revenue = {
        main: dashboardData.mainData.revenue,
    }

    return (
        <MerchantDashboardValueWithGraph
            title={t("merchantDashboard.refundsTitle")}
            subtitle={t("merchantDashboard.viaSwiipe")}
            value={{
                amount: refunds.main.amountTotal === 0 ? 0 : refunds.main.amountTotal * -1,
                currency: dashboardData.currency,
            }}
            valueFontSize="smaller"
            compareValue={
                refunds.compare && {
                    amount: refunds.compare.amountTotal === 0 ? 0 : refunds.compare.amountTotal * -1,
                    currency: dashboardData.currency,
                }
            }
            forceNegative
            values={refunds.main.amountPerDay}
            extraText={
                getFormattedNumber(
                    revenue.main.amountTotal === 0 ? 0 : (refunds.main.amountTotal / revenue.main.amountTotal) * 100,
                    { numberFormat: "forceTwoDecimals" }
                ) + "%"
            }
            extraSubText={t("merchantDashboard.refundedSales")}
            notEnabled={!paymentsEnabled}
        />
    )
}

export default DashboardRefundsDisplay
