import React from "react"
import "./Adder.scss"

interface IAdderProps {
    onClick: () => void
    buttonText?: string
}

const Adder = ({ onClick, buttonText }: IAdderProps) => {
    return (
        <div className="swiipe-adder col-lg-12 bg-white mx-auto mt-4 rounded">
            <div className="d-flex justify-content-center">
                <button className="btn btn-transparent" onClick={onClick}>
                    <span className="icon icon-addnew"></span>
                    {buttonText && <div className="text-element ml-2 text-uppercase">{buttonText}</div>}
                </button>
            </div>
        </div>
    )
}

export default Adder
