import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Input } from "reactstrap"
import "./SearchBox.scss"

interface ISearchBoxProps {
    className?: string
    currentValue: string
    placeholder: string
    onValueChange: (val: string) => void
    delayOnChangeMs?: number
    iconLeft?: boolean
    iconBlue?: boolean
}

export const SearchBox = ({
    currentValue,
    placeholder,
    onValueChange,
    delayOnChangeMs,
    iconLeft,
    iconBlue,
    className,
}: ISearchBoxProps) => {
    const { t } = useTranslation()
    const [internalVal, setInternalVal] = useState(currentValue)
    useEffect(() => {
        setInternalVal(currentValue)
    }, [currentValue])

    let timer: NodeJS.Timeout | undefined = undefined

    useEffect(() => {
        if (internalVal === currentValue) {
            return
        }

        timer = setTimeout(() => {
            if (internalVal !== currentValue) {
                onValueChange(internalVal)
            }
        }, delayOnChangeMs ?? 0)
        return () => {
            if (timer) {
                clearTimeout(timer)
                timer = undefined
            }
        }
    }, [internalVal])
    return (
        <div className={cn("swiipe-search-box px-md-5", { "icon-left": iconLeft }, { "icon-blue": iconBlue }, className)}>
            <Input
                value={internalVal}
                onChange={(e) => {
                    setInternalVal(e.target.value)
                }}
                onKeyUp={(e) => {
                    if (e.key === "Enter" || e.keyCode === 13) {
                        if (currentValue === internalVal) {
                            return
                        }
                        if (timer) {
                            clearTimeout(timer)
                            timer = undefined
                        }
                        onValueChange(internalVal)
                    }
                }}
                placeholder={placeholder}
                className="pl-md-4"
            />
        </div>
    )
}
