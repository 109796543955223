import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { StandardButton, TextButton } from "swiipe.portal.shared"
import { navigationService } from "../../services/navigationService"
import { TOnboardingType } from "../../services/onboardingService"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import WebshopsToOnboardSelect from "../form/paymentsonboarding/WebshopsToOnboardSelect"
import UnorderedListSection from "../sections/UnorderedListSection"
import ServicesOnboardingPageWrapper from "./ServicesOnboardingPageWrapper"

export interface IWebshopSelectDialog {
    isWebshopsSelectedState: [boolean, (isWebshopsSelected: boolean) => void]
    onWebshopIdsChanged: (webshopIds: string[]) => void
    merchantDetails: MixedMerchantDetails
    onboardingType: TOnboardingType
    webshopsToPreselect?: string[]
    skipStepAfterWebshopsPreselected?: boolean
}

export const WebshopSelectDialog = ({
    isWebshopsSelectedState,
    onWebshopIdsChanged,
    merchantDetails,
    onboardingType,
    webshopsToPreselect,
    skipStepAfterWebshopsPreselected,
}: IWebshopSelectDialog) => {
    const { t } = useTranslation()

    const [isWebshopsSelected, setIsWebshopsSelected] = isWebshopsSelectedState

    const [isWebshopsStatusesLoaded, setIsWebshopsStatusesLoaded] = useState(false)
    const [selectedWebshopIds, setSelectedWebshopIds] = useState<string[]>([])
    useEffect(() => {
        onWebshopIdsChanged(selectedWebshopIds)
    }, [isWebshopsStatusesLoaded, selectedWebshopIds])
    useEffect(() => {
        if (
            !skipStepAfterWebshopsPreselected ||
            !webshopsToPreselect ||
            selectedWebshopIds.length !== webshopsToPreselect.length
        ) {
            return
        }
        const allPreselectedWebshopsSelected = selectedWebshopIds.every((selectedWebshopId) =>
            webshopsToPreselect.includes(selectedWebshopId)
        )
        if (!allPreselectedWebshopsSelected) {
            return
        }
        setIsWebshopsSelected(true)
    }, [selectedWebshopIds, webshopsToPreselect])

    const shouldAllowMultipleWebshops = (onboardingType: TOnboardingType) => {
        const nonMultipleOnboardings: TOnboardingType[] = [
            "swish",
            "viabill",
            "applepay",
            "accountToAccount",
            "resurs",
            "externalAnalytics",
        ]
        return !nonMultipleOnboardings.includes(onboardingType)
    }

    return (
        <ServicesOnboardingPageWrapper hideContent={isWebshopsSelected}>
            <div>
                <p className="block-heading text-center mb-4">{t("swiipeservicesonboarding.choosewebshops")}</p>

                <div className="webhsop-select">
                    <WebshopsToOnboardSelect
                        merchantDetails={merchantDetails}
                        onboardingType={onboardingType}
                        onSelectedWebshopIdsChanged={(selectedWebshopIds) => setSelectedWebshopIds(selectedWebshopIds)}
                        webshopsToPreselect={webshopsToPreselect}
                        allowMultipleWebshops={shouldAllowMultipleWebshops(onboardingType)}
                        onIsStatusesLoadedChanged={(isLoaded) => setIsWebshopsStatusesLoaded(isLoaded)}
                    />

                    {(onboardingType === "mpo" || onboardingType === "cards") && (
                        <span className="text-normal d-block pt-4">
                            <strong>{t("clearhausonboarding.messagetitles.tocomplyrules")}</strong>
                            <UnorderedListSection
                                className="pl-3 mt-2"
                                listItems={[
                                    t("clearhausonboarding.messagetitles.visamastercardrules.1"),
                                    t("clearhausonboarding.messagetitles.visamastercardrules.2"),
                                    t("clearhausonboarding.messagetitles.visamastercardrules.3"),
                                    t("clearhausonboarding.messagetitles.visamastercardrules.4"),
                                    t("clearhausonboarding.messagetitles.visamastercardrules.5"),
                                ]}
                            />
                        </span>
                    )}
                </div>

                <StandardButton
                    dark
                    noBorder
                    className="mt-5"
                    onClick={() => setIsWebshopsSelected(true)}
                    disabled={!isWebshopsStatusesLoaded}
                >
                    {t("clearhausonboarding.buttons.continue")}
                </StandardButton>

                <TextButton
                    text={t("commononboardingtexts.goback")}
                    onClick={async () => navigationService.navigate("/plugins")}
                    className={cn("mt-3 mb-2")}
                />
            </div>
        </ServicesOnboardingPageWrapper>
    )
}

export default WebshopSelectDialog
