import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { sharedConfigurationSelectors } from "swiipe.portal.shared"
import { ISalesSendOfferFormModel, ISalesSendOfferFormRequestModel } from "../../type/sales/ISalesSendOfferFormModel"
import { ISalesUserDataModel } from "../../type/sales/ISalesUserDataModel"
import { salesSelectors } from "../reducers/salesReducer"
import { StoreState } from "./../StoreState"
import { userRelationSelectors } from "./../reducers/userRelationReducer"
import { checkUserExistsThunk } from "./userThunks"

export const checkUserEmailDataThunk =
    (inputEmail: string): ThunkAction<Promise<ISalesUserDataModel>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const email = inputEmail.trim().toLowerCase()
        const exists = await dispatch(checkUserExistsThunk(email))
        if (!exists) {
            throw new Error("User not found")
        }

        throw new Error("Not implemented")

        // const serverConfiguration = configurationSelectors.serverConfiguration(getState())
        // const endpoint = configurationSelectors.endpointConfig(getState(), "Callcenter")
        // return await dispatch(
        //     requestThunk<ISalesUserDataModel>(
        //         THttpMethod.GET,
        //         endpoint + serverConfiguration.salesGatewayEndpointIsUserData + "/" + email,
        //         undefined,
        //         { noErrorAlert: true }
        //     )
        // )
    }

export const sendOfferThunk =
    (
        form: ISalesSendOfferFormModel,
        currentSwiipeUser?: ISalesUserDataModel
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const serverConfiguration = sharedConfigurationSelectors.serverConfiguration(getState())
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())

        if (!currentUserRelation || currentUserRelation.relationType !== "CallCenter") {
            throw "Wrong user"
        }

        const sendForm: ISalesSendOfferFormRequestModel = {
            CallCenterId: currentUserRelation.id,
            ProductName: form.productName,
            Amount: form.amount,
            UserEmail: form.email,
            Phone: currentSwiipeUser ? (currentSwiipeUser.isPhoneSet ? undefined : form.swiipePhone) : form.phone,
            FullName: currentSwiipeUser ? (currentSwiipeUser.isNameSet ? undefined : form.swiipeFullName) : form.fullName,
            Message: currentSwiipeUser ? form.swiipeTextMessage || "" : form.textMessage || "",
            ExistingUser: !!currentSwiipeUser,
        }
        // const endpoint = configurationSelectors.endpointConfig(getState(), "Callcenter")
        // const response = await dispatch(
        //     authenticatedRequestThunk<ISalesSendOfferFormResponseModel>(
        //         THttpMethod.POST,
        //         endpoint + serverConfiguration.salesGatewayEndpointCreateOffer,
        //         {
        //             data: prepareFormData(sendForm, []),
        //         },
        //         { defaultErrorMessage: getText("sendoffer.defaultError") }
        //     )
        // )
        // await dispatch(ensureSalesOfferHistoryThunk(true))
        // navigationService.navigate(`/offersent?n=${response.orderID}&a=${sendForm.Amount}`)
    }

export const ensureSalesOfferHistoryThunk =
    (force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const historyOffers = salesSelectors.historyOffers(getState())
        if (!force && historyOffers) {
            return
        }

        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())!
        // const serverConfiguration = configurationSelectors.serverConfiguration(getState())
        // const endpoint = configurationSelectors.endpointConfig(getState(), "Callcenter")
        // const offers = await dispatch(
        //     authenticatedRequestThunk<ISalesOffer[]>(
        //         THttpMethod.GET,
        //         endpoint + serverConfiguration.salesGatewayEndpointGetCompanyOffer + "/" + currentUserRelation.id
        //     )
        // )
        // dispatch(salesReducerActions.setHistoryOffers(offers))
    }

export const cancelSalesOfferThunk =
    (
        offerId: string,
        reason: string,
        byManager: boolean,
        fromHistory: boolean
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        // const serverConfiguration = configurationSelectors.serverConfiguration(getState())
        // const endpoint = configurationSelectors.endpointConfig(getState(), "Callcenter")
        // await dispatch(
        //     requestThunk<void>(THttpMethod.PUT, endpoint + serverConfiguration.salesGatewayEndpointCancelOffer + "/" + offerId, {
        //         data: { ReasonCancelled: reason, CancelledByManager: byManager },
        //     })
        // )
        if (fromHistory) {
            await dispatch(ensureSalesOfferHistoryThunk(true))
        } else {
            await dispatch(fetchSalesOfferThunk(offerId))
        }
    }

export const fetchSalesOfferThunk =
    (offerId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        // const serverConfiguration = configurationSelectors.serverConfiguration(getState())
        // const endpoint = configurationSelectors.endpointConfig(getState(), "Callcenter")
        // const offer = await dispatch(
        //     requestThunk<ISalesOffer>(
        //         THttpMethod.GET,
        //         endpoint + serverConfiguration.salesGatewayEndpointGetByShortId + "/" + offerId
        //     )
        // )
        // dispatch(salesReducerActions.setOffer(offerId, offer))
    }

export const acceptSalesOfferThunk =
    (offerId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        // const serverConfiguration = configurationSelectors.serverConfiguration(getState())
        // const endpoint = configurationSelectors.endpointConfig(getState(), "Callcenter")
        // await dispatch(
        //     requestThunk<void>(THttpMethod.POST, endpoint + serverConfiguration.salesGatewayEndpointAcceptOffer + "/" + offerId)
        // )
        // await dispatch(fetchSalesOfferThunk(offerId))
    }

export const completeSalesOfferThunk =
    (offerId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        // const serverConfiguration = configurationSelectors.serverConfiguration(getState())
        // const endpoint = configurationSelectors.endpointConfig(getState(), "Callcenter")
        // await dispatch(
        //     requestThunk<void>(THttpMethod.POST, endpoint + serverConfiguration.salesGatewayEndpointCompleteOffer + "/" + offerId)
        // )
        // await dispatch(fetchSalesOfferThunk(offerId))
    }
