import { useEffect, useState } from "react"
import {
    IDropdownOption,
    TEnv,
    areArraysEqual,
    getFilesFromFileList,
    getKeys,
    getQueryValueFromCurrentUrl,
    getText,
    useSelector,
} from "swiipe.portal.shared"
import { StoreState } from "../store/StoreState"
import { clearhausSelectors } from "../store/reducers/clearhausReducer"
import {
    IMerchantProviderConfig,
    IWebshopProviderConfig,
    merchantServicesSelectors,
} from "../store/reducers/merchantServicesReducer"
import { getClearhausApplicationDataThunk, getClearhausApplicationStateThunk } from "../store/thunks/clearhausThunks"
import { getOnboardingStatusThunk } from "../store/thunks/swiipePaymentsThunks"
import { useReduxDispatch } from "../store/useReduxDispatch"
import { TOnboardingStatus } from "../type/IProvider"
import { IClearhausApplicationData } from "../type/clearhaus/IClearhausApplicationData"
import { IClearhausApplicationForm } from "../type/clearhaus/IClearhausApplicationForm"
import { IClearhausApplicationOnboardingState } from "../type/clearhaus/IClearhausApplicationOnboardingState"
import { TClearhausCompanyOwnershipType } from "../type/clearhaus/TClearhausCompanyOwnershipType"
import { TClearhausCompanyType } from "../type/clearhaus/TClearhausCompanyType"
import { TClearhausPersonFormFields } from "../type/clearhaus/TClearhausFormFieldsType"
import { TClearhausFormState } from "../type/clearhaus/TClearhausFormState"
import { TClearhausPersonType } from "../type/clearhaus/TClearhausPersonType"
import { MixedMerchantDetails } from "../type/mixedmerchantdetails"
import { TOnboardingStepId, TOnboardingType, isWebshopOnboardedForFlow } from "./onboardingService"
import { allRequiredOnboardingStatusesFetched } from "./webshopsToOnboardService"

export function getClearhausOwnershipTypesOptions(): IDropdownOption<TClearhausCompanyOwnershipType>[] {
    return [
        {
            value: "Director=Owner",
            text: getText("clearhausonboarding.ownershiptypes.1"),
        },
        {
            value: "Director!=Owner",
            text: getText("clearhausonboarding.ownershiptypes.2"),
        },
        {
            value: "Director+Multiple owners",
            text: getText("clearhausonboarding.ownershiptypes.3"),
        },
        {
            value: "Multiple directors",
            text: getText("clearhausonboarding.ownershiptypes.4"),
        },
    ]
}

export function findClearhausOwnershipTypeOption(
    searchFunction: (ownershipTypOption: IDropdownOption<TClearhausCompanyOwnershipType>) => boolean
) {
    return getClearhausOwnershipTypesOptions().find((option) => searchFunction(option))
}

export function getClearhausCompanyTypesOptions(): IDropdownOption<TClearhausCompanyType>[] {
    return [
        {
            value: "Forening",
            text: getText("clearhausonboarding.companytypes.association"),
        },
        {
            value: "IVS",
            text: getText("clearhausonboarding.companytypes.enterpreneural"),
        },
        {
            value: "Other",
            text: getText("clearhausonboarding.companytypes.other"),
        },
    ]
}

export function findClearhausCompanyTypeOption(
    searchFunction: (companyTypeOption: IDropdownOption<TClearhausCompanyType>) => boolean
) {
    return getClearhausCompanyTypesOptions().find((option) => searchFunction(option))
}

export function getClearhausPersonFormFields(
    personType: TClearhausPersonType,
    index?: number
): TClearhausPersonFormFields<TClearhausPersonType, number>[] {
    const personIndex = index ?? 0
    return [
        `${personType}s[${personIndex}].name`,
        `${personType}s[${personIndex}].personalNumber`,
        `${personType}s[${personIndex}].country`,
        `${personType}s[${personIndex}].dateOfBirth`,
        `${personType}s[${personIndex}].address`,
        `${personType}s[${personIndex}].postCode`,
        `${personType}s[${personIndex}].city`,
        `${personType}s[${personIndex}].legitimationFiles`,
        `${personType}s[${personIndex}].addressFiles`,
    ]
}

export function getClearhausInstantOnboardingAllowed(currency: string, turnover: number) {
    switch (currency) {
        case "DKK":
            return turnover >= 50000
        case "EUR":
            return turnover >= 6500
        case "GBP":
            return turnover >= 5500
        case "NOK":
            return turnover >= 70000
        case "SEK":
            return turnover >= 70000
        case "USD":
            return turnover >= 8000
    }

    return false
}

export function shouldAdditionalFieldsBeHidden(chFormState: TClearhausFormState, applicationData?: IClearhausApplicationData) {
    if (chFormState !== "submitted") {
        return false
    }

    if (!applicationData) {
        return true
    }

    const hasComments = !applicationData.applicationFields.additionalInfo?.comments
    const hasUploadedFiles = !applicationData.applicationFields.additionalInfo?.uploadedFiles?.length

    return hasComments || hasUploadedFiles
}

interface IClearhausPluginStatus {
    isOnboarded: boolean
    isFetched: boolean
    isSavedNoStagesSubmitted?: boolean
    isAwaitingApproval?: boolean
    isNeedMoreInfo?: boolean
    canEditApplication?: boolean
    canViewApplicationData?: boolean
    showLimitedAccessHint?: boolean
    isChangesRequested?: boolean
    buttonLink?: string
    buttonBlue?: boolean
}

export function getClearhausPluginStatus(statusType: TClearhausPluginStatusType, webshopId: string): IClearhausPluginStatus {
    const editApplicationLink = `/clearhaus_edit?webshopId=${webshopId}`
    const viewApplicationLink = `/view_clearhaus_application?webshopId=${webshopId}`
    const finishApplicationLink = `/swiipepayments_onboard?forceWebshopId=${webshopId}`

    switch (statusType) {
        case "notFetched":
            return {
                isFetched: false,
                isOnboarded: false,
            }
        case "notOnboarded":
            return {
                isFetched: true,
                isOnboarded: false,
            }
        case "savedNoStagesSubmitted": {
            return {
                isFetched: true,
                isOnboarded: true,
                isSavedNoStagesSubmitted: true,
                canEditApplication: true,
                buttonBlue: true,
            }
        }
        case "onboarded":
            return {
                isFetched: true,
                isOnboarded: true,
                canViewApplicationData: true,
                buttonLink: viewApplicationLink,
            }
        case "onboardedSandbox": {
            return {
                isFetched: true,
                isOnboarded: true,
            }
        }
        case "onboardedChangesRequested":
            return {
                isFetched: true,
                isOnboarded: true,
                isChangesRequested: true,
            }
        case "needsEditing":
            return {
                isFetched: true,
                isOnboarded: true,
                canEditApplication: true,
                isNeedMoreInfo: true,
                buttonLink: editApplicationLink,
                buttonBlue: true,
            }
        case "awaitingApproval":
            return { isFetched: true, isOnboarded: true, isAwaitingApproval: true }
        case "awaitingApprovalNeedsCompletion":
            return {
                isFetched: true,
                isOnboarded: true,
                isAwaitingApproval: true,
                canEditApplication: true,
                buttonLink: finishApplicationLink,
                buttonBlue: true,
            }
        case "limitedAccessNeedsCompletion":
            return {
                isFetched: true,
                isOnboarded: false,
                showLimitedAccessHint: true,
                canEditApplication: true,
                buttonLink: finishApplicationLink,
                buttonBlue: true,
            }
        case "awaitingApprovalHasLimitedAccess":
            return {
                isFetched: true,
                isOnboarded: true,
                isAwaitingApproval: true,
                showLimitedAccessHint: true,
            }
    }
}

type TClearhausPluginStatusType =
    | "notFetched"
    | "notOnboarded"
    | "onboarded"
    | "onboardedSandbox"
    | "onboardedChangesRequested"
    | "savedNoStagesSubmitted"
    | "needsEditing"
    | "awaitingApproval"
    | "awaitingApprovalNeedsCompletion"
    | "limitedAccessNeedsCompletion"
    | "awaitingApprovalHasLimitedAccess"
export function getClearhausPluginStatusType(
    swiipePlanSelected: boolean,
    merchantDeletionPending: boolean,
    env: TEnv,
    webshopProviders?: IWebshopProviderConfig,
    clearhausCcStatus?: TOnboardingStatus,
    applicationState?: IClearhausApplicationOnboardingState
): TClearhausPluginStatusType {
    const status = clearhausCcStatus
    const isWebshopProvidersLoaded = !!webshopProviders
    const isSavedNoStagesSubmitted =
        !applicationState?.firstStageSubmitted && !applicationState?.secondStageSubmitted && !!applicationState?.isSaved

    if (merchantDeletionPending) {
        return "notOnboarded"
    }

    if (isWebshopProvidersLoaded && !status && applicationState && isSavedNoStagesSubmitted) {
        // When clearhaus status has never been set before, but application saved
        return "savedNoStagesSubmitted"
    }

    if (isWebshopProvidersLoaded && !status) {
        return "notOnboarded"
    }

    if (!webshopProviders || !status) {
        return "notFetched"
    }

    if (!swiipePlanSelected) {
        return "notOnboarded"
    }

    if (env === "Sandbox") {
        if (status === "InProgress" || status === "Ready") {
            return "onboardedSandbox"
        }

        return "notOnboarded"
    }

    if (!applicationState) {
        return "notFetched"
    }

    const {
        firstStageSubmitted,
        secondStageSubmitted,
        editable,
        instantOnboarding,
        instantNotificationSentDate,
        fullNotificationSentDate,
        isPendingUpdate,
        isWithoutData,
    } = applicationState

    if (isWithoutData && status === "InProgress") {
        return "awaitingApproval"
    }

    if (isSavedNoStagesSubmitted) {
        return "savedNoStagesSubmitted"
    }

    if (clearhausCcStatus === "ActionRequired") {
        return "notOnboarded"
    }

    //Fallback for old applications
    if (!firstStageSubmitted && !secondStageSubmitted && !editable) {
        if (status === "InProgress") {
            return "awaitingApproval"
        }
        if (status === "Ready") {
            return "onboarded"
        }
    }

    if (firstStageSubmitted && secondStageSubmitted && editable) {
        return "needsEditing"
    }

    if (firstStageSubmitted && secondStageSubmitted && status === "Ready" && isPendingUpdate) {
        return "onboardedChangesRequested"
    }

    if (instantOnboarding) {
        if (firstStageSubmitted && !secondStageSubmitted && status == "Ready" && instantNotificationSentDate) {
            return "limitedAccessNeedsCompletion"
        }

        if (firstStageSubmitted && !secondStageSubmitted && (status == "InProgress" || !instantNotificationSentDate)) {
            return "awaitingApprovalNeedsCompletion"
        }

        if (
            firstStageSubmitted &&
            secondStageSubmitted &&
            status === "Ready" &&
            instantNotificationSentDate &&
            !fullNotificationSentDate
        ) {
            return "awaitingApprovalHasLimitedAccess"
        }
    }

    if (firstStageSubmitted && secondStageSubmitted && status === "InProgress") {
        return "awaitingApproval"
    }

    if (firstStageSubmitted && secondStageSubmitted && status === "Ready") {
        return "onboarded"
    }

    return "notOnboarded"
}

export function getFilesToBeUploadedCount(data: IClearhausApplicationForm): number {
    let totalCount = 0
    // documentation
    totalCount += getFilesFromFileList(data.documentation?.files).length
    // additional files
    totalCount += getFilesFromFileList(data.additionalInfo?.files).length
    // directors' files
    getKeys(data.directors || {}).forEach((key) => {
        totalCount += getFilesFromFileList(data.directors[key].addressFiles).length
        totalCount += getFilesFromFileList(data.directors[key].legitimationFiles).length
    })
    // owners' files
    getKeys(data.owners || {}).forEach((key) => {
        totalCount += getFilesFromFileList(data.owners![key].addressFiles).length
        totalCount += getFilesFromFileList(data.owners![key].legitimationFiles).length
    })
    return totalCount
}

export function useClearhausServicesOnboardingPageSettings(
    selectedWebshopIds: string[],
    merchantDetails: MixedMerchantDetails,
    onboardingType: TOnboardingType,
    addStepPreventSkip: (stepId: TOnboardingStepId) => void,
    addStepShouldSkip: (stepId: TOnboardingStepId) => void
): {
    webshopsToPreselect?: string[]
    skipWebshopSelectStep: boolean
} {
    const { skipAlreadyHasClearhausDialog, webshopsToPreselect, skipWebshopSelectStep, preventSkpClearhausOnboarding } =
        onboardingType === "cards"
            ? useClearhausServicesOnboardingPageSettingsInner(selectedWebshopIds, merchantDetails)
            : {
                  skipAlreadyHasClearhausDialog: false,
                  webshopsToPreselect: undefined,
                  skipWebshopSelectStep: false,
                  preventSkpClearhausOnboarding: undefined,
              }

    useEffect(() => {
        if (preventSkpClearhausOnboarding) {
            addStepPreventSkip("clearhausOnboarding")
        }
        if (skipAlreadyHasClearhausDialog) {
            addStepShouldSkip("clearhausWithoutDataOnboarding")
        }
    }, [preventSkpClearhausOnboarding, skipAlreadyHasClearhausDialog])

    return {
        webshopsToPreselect,
        skipWebshopSelectStep,
    }
}

function useClearhausServicesOnboardingPageSettingsInner(
    selectedWebshopIds: string[],
    merchantDetails: MixedMerchantDetails
): {
    skipAlreadyHasClearhausDialog: boolean
    webshopsToPreselect?: string[]
    skipWebshopSelectStep: boolean
    preventSkpClearhausOnboarding?: boolean
} {
    const dispatch = useReduxDispatch()
    const swMerchantId = merchantDetails.swMerchant.swMerchantId
    const webshops = merchantDetails.webshops

    const [initialized, setInitialized] = useState(false)
    const [initialWebshopId, setInitialWebshopId] = useState<string | undefined>()
    useEffect(() => {
        if (initialized || selectedWebshopIds.length === 0) {
            return
        }
        setInitialWebshopId(selectedWebshopIds[0])
        setInitialized(true)
    }, [selectedWebshopIds])

    const [forceWebshopIds, setForceWebshopIds] = useState<string[] | undefined>()
    useEffect(() => {
        const forceWebshopId = getQueryValueFromCurrentUrl("forceWebshopId")
        if (!forceWebshopId) {
            return
        }

        setForceWebshopIds([forceWebshopId])
    }, [])

    useEffect(() => {
        if (!initialWebshopId) {
            return
        }

        merchantDetails.webshops.forEach((w) => {
            dispatch(getOnboardingStatusThunk(swMerchantId, w.webshopId, false, ["CreditCard"]))
        })

        dispatch(getClearhausApplicationDataThunk(swMerchantId, [initialWebshopId]))
        dispatch(getClearhausApplicationStateThunk(swMerchantId, initialWebshopId, false))
    }, [initialWebshopId])

    const applicationData = useSelector(
        (state: StoreState) => clearhausSelectors.getOnboardingData(state, initialWebshopId || ""),
        //prevent unnecessary state updates, as we only need 'webshopIds' from applicationData
        (prevApplicationData?: IClearhausApplicationData, nextApplicationData?: IClearhausApplicationData) => {
            if (!prevApplicationData || !nextApplicationData) {
                return false
            }
            return areArraysEqual(prevApplicationData.webshopIds, nextApplicationData.webshopIds)
        }
    )
    const onboardingStatuses = useSelector<StoreState, IMerchantProviderConfig | undefined>((state) =>
        merchantServicesSelectors.merchantStatuses(state, swMerchantId)
    )
    const clearhausApplicationState = useSelector<StoreState, IClearhausApplicationOnboardingState | undefined>((state) =>
        clearhausSelectors.applicationState(state, initialWebshopId || "")
    )

    if (forceWebshopIds) {
        return {
            skipAlreadyHasClearhausDialog: true,
            webshopsToPreselect: forceWebshopIds,
            skipWebshopSelectStep: true,
            preventSkpClearhausOnboarding: true,
        }
    }

    if (!applicationData || !clearhausApplicationState) {
        return {
            skipAlreadyHasClearhausDialog: false,
            skipWebshopSelectStep: false,
        }
    }

    if (!onboardingStatuses || !allRequiredOnboardingStatusesFetched(onboardingStatuses, webshops)) {
        // not all onboarding statuses fetched
        return {
            skipAlreadyHasClearhausDialog: false,
            skipWebshopSelectStep: false,
        }
    }

    const hasNotOnboardedWebshopsNotSelectedForCurrentApplication = webshops
        .filter((w) => !applicationData.webshopIds.includes(w.webshopId))
        .some((w) => !isWebshopOnboardedForFlow(onboardingStatuses[w.webshopId], "cards"))

    const isSaved = clearhausApplicationState.isSaved

    return {
        skipAlreadyHasClearhausDialog: isSaved,
        webshopsToPreselect: applicationData.webshopIds,
        skipWebshopSelectStep: isSaved && !hasNotOnboardedWebshopsNotSelectedForCurrentApplication,
    }
}

export function getCompanyDocumentationFileUploadRequired(companyCountry?: string, companyType?: TClearhausCompanyType) {
    if (!companyCountry) {
        return false
    }

    const config = {
        DK: companyType && companyType !== "Other",
        GB: false,
    }

    return config[companyCountry] ?? true
}

export function getClearhausCompanyTypeFromCvrApiCompanyCode(companyCode?: number) {
    if (!companyCode) {
        return undefined
    }

    switch (companyCode) {
        case 100:
            return getText("clearhausonboarding.companytypes.association")
        case 115:
            return getText("clearhausonboarding.companytypes.association")
        case 81:
            return getText("clearhausonboarding.companytypes.enterpreneural")
        default:
            return getText("clearhausonboarding.companytypes.other")
    }
}
