import React from "react"
import { useTranslation } from "react-i18next"
import MerchantDashboardCellContainer from "../MerchantDashboardCellContainer"
import MerchantDashboardCellTitle from "../MerchantDashboardCellTitle"
import cn from "classnames"
import "./MerchantDashboardTileNoData.scss"

interface IMerchantDashboardTileNoDataProps {
    title: string
    icon?: string
    subtitle: string
    noDataText?: string
    isSwiipePaymentsNotEnabled?: boolean
    children?: React.ReactNode
}

const MerchantDashboardTileNoData = ({
    title,
    icon,
    subtitle,
    noDataText,
    isSwiipePaymentsNotEnabled,
    children,
}: IMerchantDashboardTileNoDataProps) => {
    const { t } = useTranslation()
    return (
        <MerchantDashboardCellContainer className="merchant-dashboard-tile-no-data">
            <MerchantDashboardCellTitle title={title} icon={icon} subtitle={subtitle} isDisabled={!children} />
            <div className={cn("no-data-text", { "text-disabled": !children })}>
                {noDataText ??
                    (isSwiipePaymentsNotEnabled
                        ? t("merchantDashboard.swiipePaymentsNotEnabledError")
                        : t("merchantDashboard.noData"))}
            </div>
            {children}
        </MerchantDashboardCellContainer>
    )
}

export default MerchantDashboardTileNoData
