import Parser from "html-react-parser"
import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, ModalFooter } from "swiipe.portal.shared"
import { OrderTransaction } from "../../type/Transaction"
import "./ModalTransactionTechnicalDetails.scss"

export interface IModalTransactionDetails extends IModalBase {
    type: "transactionTechnicalDetails"
    transaction: OrderTransaction
}

const ModalTransactionDetails = ({ transaction, callback, ...otherProps }: IModalTransactionDetails & IModalControlProps) => {
    const { t } = useTranslation()
    const technicalDetailsHTML = prepareTechnicalDetails(transaction)
    return (
        <BaseModal
            {...otherProps}
            modalClassName="info-modal"
            callback={callback}
            centered
            title={t("transactions.technicalDetailsModel.title")}
        >
            <ModalBody>
                <section className="technical-details">{Parser(technicalDetailsHTML ?? "")}</section>
            </ModalBody>
            <ModalFooter rightButton={{ onClick: async () => callback("accepted"), text: t("common.close") }} />
        </BaseModal>
    )
}

const prepareTechnicalDetails = (transaction: OrderTransaction): string => {
    const detailsParts: string[] = [
        transaction.notes,
        transaction.paymentCallbackDetails,
        transaction.errorDetails,
        transaction.swPaymentErrorCategory,
    ]
        .map((part) => part?.trim() ?? "")
        .filter((part) => !!part)

    const detailsSection = detailsParts.join("<br/><br/>")
    return detailsSection
}

export default ModalTransactionDetails
