import React from "react"
import { IPaymentMethod } from "swiipe.portal.shared"
import { deleteUserPaymentMethodThunk } from "../../../store/thunks/userPersonalInfoThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import InfoBoxEditor, { TSetShowSpinnerFunc } from "../../infobox/InfoBoxEditor"
import { InfoBoxState } from "../../infobox/InfoBoxState"

interface IA2aEditor {
    paymentMethod: IPaymentMethod
    cardNumberInEditMode: string | undefined
    onSwitchEditMode: (cardNumber: string, isInEditMode: boolean) => void
}

const A2aEditor = ({ paymentMethod, cardNumberInEditMode, onSwitchEditMode }: IA2aEditor) => {
    const pm = paymentMethod.account
    const dispatch = useReduxDispatch()

    if (!pm) {
        return null
    }

    return (
        <InfoBoxEditor
            key={pm.accountNo}
            state={InfoBoxState.DELETEONLY}
            title=""
            isInEditMode={cardNumberInEditMode === pm.accountNo}
            editBtnTextType="editing"
            renderPreview={() => (
                <div className="a2a-preview-container">
                    <img className="bank-logo" src={pm.providerLogo} />
                    <br />
                    <span className="account-name">{pm.name}</span>
                </div>
            )}
            renderFormFields={(register) => (
                <div className="a2a-preview-container form-fields">
                    <img className="bank-logo" src={pm.providerLogo} />
                    <br />
                    <span className="account-name">{pm.name}</span>
                    <br />
                    <span className="bban-number">{pm.accountNo}</span>
                </div>
            )}
            noSave
            allowDelete
            onDelete={async (setShowSpinner: TSetShowSpinnerFunc) => {
                await dispatch(deleteUserPaymentMethodThunk(paymentMethod.paymentId))
                setShowSpinner(false)
            }}
            onSwitchEditMode={(isInEditMode) => onSwitchEditMode(pm.accountNo, isInEditMode)}
        />
    )
}

export default A2aEditor
