import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { SpinnerContainer } from "swiipe.portal.shared"
import FixedLabelInput from "../../components/form/input/FixedLabelInput"
import CancelService from "../../components/merchant/CancelService"
import { SharedCancelContainer } from "../../components/servicesonboarding/SharedCancelContainer"
import { getSwishOnboardingDataThunk, setPiqOnboardingStatusThunk } from "../../store/thunks/swiipePaymentsThunks"
import { sendServiceCancellationEmailThunk } from "../../store/thunks/swiipeServicesThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import "./SwishDetails.scss"

interface ISwishDetailsProps {
    webshopId: string
    merchantDetails: MixedMerchantDetails
    onBackStep: () => void
}
const SwishDetails = ({ webshopId, merchantDetails, onBackStep }: ISwishDetailsProps) => {
    const { t } = useTranslation()
    const [showCancelService, setShowCancelService] = useState(false)
    const [swishId, setSwishId] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useReduxDispatch()

    useEffect(() => {
        if (webshopId) {
            ;(async () => {
                const swishData = await dispatch(getSwishOnboardingDataThunk(webshopId, merchantDetails.swMerchant.swMerchantId))
                setSwishId(swishData.swishId)
                setIsLoading(false)
            })()
        }
    }, [webshopId])

    return (
        <SpinnerContainer showSpinner={isLoading}>
            {showCancelService ? (
                <CancelService
                    serviceName={t("swishonboarding.swish")}
                    cancelText={t("cancelService.swish.cancelText")}
                    customCancelledText={t("cancelService.swish.cancelledText")}
                    hintText={t("cancelService.swish.hintText")}
                    hideLogo
                    onCancel={async () => {
                        await dispatch(
                            setPiqOnboardingStatusThunk(merchantDetails.swMerchant.swMerchantId, [webshopId!], {
                                paymentType: "Swish",
                                providerName: "Swish",
                                providerType: "PspAndAquirer",
                                status: "ActionRequired",
                            })
                        )

                        await dispatch(
                            sendServiceCancellationEmailThunk(
                                merchantDetails.swMerchant.swMerchantId,
                                "GeneralServiceCancellationEopEmail",
                                t("serviceNames.swish"),
                                true
                            )
                        )
                    }}
                    onGoBack={() => setShowCancelService(false)}
                />
            ) : (
                <div className="swish-details">
                    <FixedLabelInput
                        className="swish-id-input"
                        label={t("swishonboarding.swishid")}
                        name="swishId"
                        value={swishId}
                        disabled={true}
                    />

                    <SharedCancelContainer
                        cancelText={t("swishonboarding.cancel")}
                        onCancel={() => {
                            setShowCancelService(true)
                        }}
                    />
                </div>
            )}
        </SpinnerContainer>
    )
}

export default SwishDetails
