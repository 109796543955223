import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    DropDownList,
    PageContainer,
    PageWrapper,
    TPlatformValue,
    getPlatformLogo,
    getPlatformName,
    isPlatform,
} from "swiipe.portal.shared"
import "./PlatformSelect.scss"

interface IPlatformSelect {
    onPlatformChange: (platform: TPlatformValue | string) => void
    otherOptions?: { value: string; label: string }[]
}

const PlatformSelect = ({ onPlatformChange, otherOptions }: IPlatformSelect) => {
    const { t } = useTranslation()

    const [selectedPlatform, setSelectedPlatform] = useState("woocommerce" as TPlatformValue | string)
    const platforms: TPlatformValue[] = ["woocommerce", "magento", "magento2", "prestashop"]

    useEffect(() => {
        onPlatformChange(selectedPlatform)
    })

    return (
        <>
            <PageWrapper disableMinHeight>
                <PageContainer id="platform-select-comp">
                    <p className="heading mb-2">{t("platformselect.selectplatform")}</p>
                    <div className="platform-select">
                        <DropDownList
                            className="ddl-comp"
                            options={[...platforms, ...(otherOptions ?? []).map((o) => o.value)].map((p) => {
                                return {
                                    text: isPlatform(p)
                                        ? getPlatformName(p as TPlatformValue)
                                        : (otherOptions ?? []).find((o) => o.value === p)?.label ?? "",
                                    value: p,
                                }
                            })}
                            onChange={(e) => {
                                setSelectedPlatform(e.target.value as TPlatformValue)
                            }}
                        ></DropDownList>
                        {isPlatform(selectedPlatform) && (
                            <img className="platform-logo" src={getPlatformLogo(selectedPlatform as TPlatformValue)} />
                        )}
                    </div>
                </PageContainer>
            </PageWrapper>
        </>
    )
}

export default PlatformSelect
