import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { DropDownList, StandardButtonWithSpinner, useSelector } from "swiipe.portal.shared"
import { getPaymentMethodsTitlesForExport } from "../../../services/merchantDashboardPaymentMethodsService"
import { getDashboardExportFileName, useMerchantDashboardServicesInfo } from "../../../services/merchantDashboardService"
import { makeScreenshotByRefAndDownload } from "../../../services/screenshotService"
import { StoreState } from "../../../store/StoreState"
import { merchantDashboardSelectors } from "../../../store/reducers/merchantDashboardReducer"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { exportDashboardDataThunk } from "../../../store/thunks/merchantDashboardThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../../type/mixedmerchantdetails"
import MerchantDashboardCellContainer from "../MerchantDashboardCellContainer"
import { IMerchantDashboardSelectedDates } from "../header/dateFilters/MerchantDashboardDateFilters"
import "./MerchantDashboardFooter.scss"

interface IMerchantDashboardFooterProps {
    merchantDetails: MixedMerchantDetails
    selectedDates: IMerchantDashboardSelectedDates
    dashboardPageRef: React.MutableRefObject<HTMLElement | null | undefined>
}

const MerchantDashboardFooter = ({ merchantDetails, selectedDates, dashboardPageRef }: IMerchantDashboardFooterProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()

    const [exportType, setExportType] = useState("Csv")

    const selectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)
    const merchantDashboardServicesInfo = useMerchantDashboardServicesInfo(merchantDetails, selectedWebshopId)
    const dashboardData = useSelector((state: StoreState) =>
        merchantDashboardSelectors.dashboardData(state, selectedWebshopId ?? "", selectedDates)
    )

    if (!dashboardData) {
        return null
    }

    return (
        <MerchantDashboardCellContainer className="merchant-dashboard-footer" html2CanvasIgnore>
            <div className="export-text">{t("merchantDashboard.exportText")}</div>
            <DropDownList
                value={exportType}
                onChange={(e) => setExportType(e.target.value)}
                options={[
                    {
                        text: t("merchantDashboard.exportTypes.excel"),
                        value: "Excel",
                    },
                    {
                        text: t("merchantDashboard.exportTypes.csv"),
                        value: "Csv",
                    },
                    {
                        text: t("merchantDashboard.exportTypes.png"),
                        value: "Png",
                    },
                ]}
            />
            <StandardButtonWithSpinner
                title={t("merchantDashboard.download")}
                isSmall
                onClick={async () => {
                    if (exportType === "Png") {
                        const fileName = getDashboardExportFileName(
                            merchantDetails.webshops.find((w) => w.webshopId === selectedWebshopId)?.hostName || "",
                            selectedDates.dates.fromDateUTC!,
                            selectedDates.dates.toDateUTC!,
                            "png"
                        )
                        await makeScreenshotByRefAndDownload(dashboardPageRef, fileName)
                        return
                    }

                    await dispatch(
                        exportDashboardDataThunk(
                            exportType,
                            merchantDetails.swMerchant.swMerchantId,
                            selectedWebshopId ?? "",
                            selectedDates,
                            getPaymentMethodsTitlesForExport(dashboardData.serverData),
                            merchantDashboardServicesInfo
                        )
                    )
                }}
            />
        </MerchantDashboardCellContainer>
    )
}

export default MerchantDashboardFooter
