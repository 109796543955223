import { TCurrency, TPayment } from "swiipe.portal.shared"
import { TransactionStatus } from "./Transaction"

export type Order = {
    platformOrderId: string
    orderId: string
    created: string
    updated: string
    currency: TCurrency
    amount: number
    paymentInfo: string
    paymentType: TPayment
    paymentSubType: string
    orderStatus: TOrderStatus
    txStatus: TransactionStatus
    actions: TransactionActions
    subscriptionOrderId?: string
    subscriptionOrderType?: ESubscriptionOrderType
    winbackOriginalOrderId: string
    plusSellParentOrderId?: string
    plusSellChildOrderId?: string
    returnSellParentOrderId?: string
    returnSellChildOrderId?: string
    acquirer?: string
    paymentLinkUrl?: string
}

export enum TOrderStatus {
    AuthFailed = "AuthFailed",
    Authorized = "Authorized",
    PartiallyCaptured = "PartiallyCaptured",
    Captured = "Captured",
    Refunded = "Refunded",
    PartiallyRefunded = "PartiallyRefunded",
    Voided = "Voided",
    Purchased = "Purchased",
}

export type TransactionActions = {
    canCancel: boolean
    canCapture: boolean
    canPartialCapture: boolean
    canRefund: boolean
    canPartialRefund: boolean
    captureAmountLeft: number
    refundAmountLeft: number
}

export type OrdersFilter = {
    dateFrom?: string
    dateTo?: string
    amountFrom?: number
    amountTo?: number
    currency?: string[]
    transactionAction?: OrderActionsFilter[]
    transactionPaymentType?: OrderPaymentTypeFilter[]
    referenceOrderIdQuery?: string
    orderStatus?: TOrderStatusFilter
    txStatus?: TransactionStatus[]
    sortByProperty?: SortOrdersByPropType
    isOrderByDescending?: boolean
    orderId?: string
    isPaymentLinkOrders?: boolean
    subscriptionOrderTypes?: ESubscriptionOrderType[]
}

export enum TOrderStatusFilter {
    Authorized = "Authorized",
    Captured = "Captured",
    Refunded = "Refunded",
    AuthFailed = "AuthFailed",
    Voided = "Voided",
    WithWinback = "WithWinback",
    WithPlusSell = "WithPlusSell",
    WithReturnSell = "WithReturnSell",
}

export enum SortOrdersByPropType {
    Created = "Created",
    PlatformOrderId = "PlatformOrderId",
    Updated = "Updated",
}
export enum OrderActionsFilter {
    CanCapture = "CanCapture",
    CanCancel = "CanCancel",
    CanRefund = "CanRefund",
}

export enum ESubscriptionOrderType {
    InitialPayment = "InitialPayment",
    SubsequentPayment = "SubsequentPayment",
    UpdatePayment = "UpdatePayment",
}

export type OrderPaymentTypeFilter = {
    paymentType: TPayment
    paymentSubType?: string
}
