import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
    IMerchantOnboardingServicesSubForm,
    IMerchantPreOnboarding,
    IMerchantPreOnboardingEvent,
    IMerchantPreOnboardingInternalUser,
    IMerchantPreOnboardingOwner,
} from "swiipe.portal.shared"
import { StoreState } from "../StoreState"

export type IMerchantPreOnboardingDetails =
    | {
          swMerchantId: string
          preOnboarding: IMerchantPreOnboarding
          creator: IMerchantPreOnboardingInternalUser
          owner: IMerchantPreOnboardingOwner
          events: IMerchantPreOnboardingEvent[]
          services: IMerchantOnboardingServicesSubForm
          exists: true
      }
    | {
          swMerchantId: string
          exists: false
          preOnboarding: undefined
          creator: undefined
          owner: undefined
          events: undefined
          services: undefined
      }

export interface IMerchantPreOnboardingStoreAddition {
    merchantPreOnboarding: IMerchantPreOnboardingSliceState
}

interface IMerchantPreOnboardingSliceState {
    details: {
        [swMerchantId: string]: IMerchantPreOnboardingDetails | undefined
    }
    initialNavigationDone: {
        [swMerchantId: string]: boolean
    }
}

const initialState: IMerchantPreOnboardingSliceState = {
    details: {},
    initialNavigationDone: {},
}

export const merchantPreOnboardingSlice = createSlice({
    name: "merchantPreOnboarding",
    initialState,
    reducers: {
        setMerchantPreOnboardingDetails: (
            state,
            action: PayloadAction<
                | {
                      swMerchantId: string
                      exists: true
                      preOnboarding: IMerchantPreOnboarding
                      creator: IMerchantPreOnboardingInternalUser
                      owner: IMerchantPreOnboardingOwner
                      events: IMerchantPreOnboardingEvent[]
                      services: IMerchantOnboardingServicesSubForm
                  }
                | { swMerchantId: string; exists: false }
            >
        ) => {
            if (!action.payload.exists) {
                state.details[action.payload.swMerchantId] = {
                    swMerchantId: action.payload.swMerchantId,
                    exists: false,
                    creator: undefined,
                    events: undefined,
                    owner: undefined,
                    preOnboarding: undefined,
                    services: undefined,
                }
            } else {
                state.details[action.payload.swMerchantId] = {
                    preOnboarding: action.payload.preOnboarding,
                    creator: action.payload.creator,
                    owner: action.payload.owner,
                    events: action.payload.events,
                    services: action.payload.services,
                    swMerchantId: action.payload.swMerchantId,
                    exists: true,
                }
            }
        },
        setInitialNavigationDone: (state, action: PayloadAction<string>) => {
            state.initialNavigationDone[action.payload] = true
        },
    },
})

export const merchantPreOnboardingActions = merchantPreOnboardingSlice.actions
export const merchantPreOnboardingSelectors = {
    details: (state: StoreState) => state.merchantPreOnboarding.details[state.userRelation.currentRelation?.id ?? ""],
    initialNavigationDone: (state: StoreState) =>
        state.merchantPreOnboarding.initialNavigationDone[state.userRelation.currentRelation?.id ?? ""] ?? false,
}
