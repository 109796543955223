import { ICompanyData } from "../types/company/ICompanyData"
import { ActionsUnion } from "../types/state/actionsUnion"
import { createAction } from "../types/state/createAction"
import { SharedStoreState } from "./SharedStoreState"

export type OrganizationState = Readonly<{
    companyData: { [cvr: string]: ICompanyData }
    isCvrNotFound: { [cvr: string]: boolean | undefined }
}>

const initialState: OrganizationState = {
    companyData: {},
    isCvrNotFound: {},
}

export const organizationReducerActions = {
    setCompanyDetails: (cvr: string, companyData: ICompanyData) => createAction("APP/SET_COMPANY_DATA", { cvr, companyData }),
    setIsCvrNotFound: (cvr: string, notFound: boolean) => createAction("APP/SET_IS_CVR_NOT_FOUND", { cvr, notFound }),
}

export type OrganizationActions = ActionsUnion<typeof organizationReducerActions>

export const organizationReducer = (state: OrganizationState = initialState, action: OrganizationActions) => {
    switch (action.type) {
        case "APP/SET_COMPANY_DATA": {
            return {
                ...state,
                companyData: {
                    ...state.companyData,
                    [action.cvr]: action.companyData,
                },
            }
        }
        case "APP/SET_IS_CVR_NOT_FOUND": {
            return {
                ...state,
                isCvrNotFound: {
                    ...state.isCvrNotFound,
                    [action.cvr]: action.notFound,
                },
            }
        }
    }

    return state
}

export const organizationSelectors = {
    companyData: (state: SharedStoreState, cvr: string) =>
        state.organizations.companyData[cvr] ? state.organizations.companyData[cvr] : undefined,
    isCvrNotFound: (state: SharedStoreState, cvr: string) => state.organizations.isCvrNotFound[cvr],
}
