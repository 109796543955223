import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
    CleanHtml,
    PageContainer,
    PageWrapper,
    StandardButton,
    StandardButtonWithSpinner,
    TextButton,
} from "swiipe.portal.shared"
import SwiipeLogoImage from "../../images/swiipe-logo.png"
import { openContactUsWidget } from "../../services/contactUsService"
import { navigationService } from "../../services/navigationService"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import FormHeaderLogoContainer from "../form/title/FormHeaderLogoContainer"
import "./CancelService.scss"

interface ICancelServiceProps {
    serviceName: string
    cancelText?: string
    customCancelledText?: string
    hintText?: string
    hideLogo?: boolean
    onGoBack: () => void
    onCancel: () => Promise<void>
}

const CancelService = ({
    serviceName,
    cancelText,
    customCancelledText,
    hintText,
    hideLogo,
    onGoBack,
    onCancel,
}: ICancelServiceProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [cancelled, setCancelled] = useState(false)
    const cancelledText = customCancelledText ? customCancelledText : t("cancelService.cancelled.defaultCancelled")

    return (
        <PageWrapper>
            <PageContainer id="cancel-service-container" transparent>
                <div className={cn("main-container", { "no-logo-container": hideLogo })}>
                    {!(hideLogo ?? true) && (
                        <FormHeaderLogoContainer
                            mainLogo={{ src: SwiipeLogoImage, alt: "swiipe-acquiring-logo", width: "140px" }}
                        />
                    )}

                    {!cancelled && (
                        <>
                            <p>{t("cancelService.wantToCancel", { serviceName: serviceName })}</p>

                            {cancelText && (
                                <div className={cn("cancel-text mt-4", { "mb-5": !hintText })}>
                                    <CleanHtml element="p" html={cancelText} />
                                </div>
                            )}

                            {hintText && <div className="mb-5 mt-2 text-center">{hintText}</div>}

                            <StandardButtonWithSpinner
                                invertedRed
                                noBorderRadius
                                onClick={async () => {
                                    await onCancel()

                                    setCancelled(true)
                                }}
                                className={cn("mb-4", { "mt-5": !cancelText })}
                            >
                                {t("cancelService.cancelBtnText", { serviceName: serviceName })}
                            </StandardButtonWithSpinner>

                            <TextButton
                                text={t("commononboardingtexts.back")}
                                className="mt-3 mb-2"
                                onClick={async () => onGoBack()}
                            />
                        </>
                    )}

                    {cancelled && (
                        <div className="cancelled-box">
                            <CleanHtml element="p" html={cancelledText} />
                            <p>
                                {t("cancelService.cancelled.anyQuestion")}
                                <span onClick={openContactUsWidget}>{t("cancelService.cancelled.contactUs")}</span>
                            </p>

                            <StandardButton
                                className="mt-4 mb-5"
                                dark
                                noBorder
                                noBorderRadius
                                onClick={() => navigationService.navigate("/plugins")}
                            >
                                {t("common.ok")}
                            </StandardButton>
                        </div>
                    )}
                </div>
            </PageContainer>
        </PageWrapper>
    )
}

export default CancelService
