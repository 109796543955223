import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { downloadBlob, getCultureWithFallback, getText, requestThunk } from "swiipe.portal.shared"
import { IMerchantDashboardSelectedDates } from "../../components/merchantDashboard/header/dateFilters/MerchantDashboardDateFilters"
import { endpoints } from "../../data/endpoints"
import { TMerchantDashboardServicesInfo, getDashboardExportFileName } from "../../services/merchantDashboardService"
import { IMerchantDashboardServerData } from "../../type/merchantDashboard/IMerchantDashboardServerData"
import { StoreState } from "../StoreState"
import { merchantDashboardReducerActions, merchantDashboardSelectors } from "../reducers/merchantDashboardReducer"
import { merchantSelectors } from "../reducers/merchantReducer"

export const getDashboardDataThunk =
    (
        force: boolean,
        swMerchantId: string,
        webshopId: string,
        selectedDates: IMerchantDashboardSelectedDates
    ): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const data = merchantDashboardSelectors.dashboardData(getState(), webshopId, selectedDates)

        if (data && !force) {
            return
        }

        if (!selectedDates.dates.fromDateUTC || !selectedDates.dates.toDateUTC) {
            return
        }

        const dataFromServer = await dispatch(
            requestThunk<IMerchantDashboardServerData>(endpoints.Analytics.getDashboardData, {
                params: {
                    swMerchantId: swMerchantId,
                    webshopId: webshopId,
                    from: selectedDates.dates.fromDateUTC.toISOString(),
                    to: selectedDates.dates.toDateUTC.toISOString(),
                    compareFrom: selectedDates.comparePeriod.fromDateUTC?.toISOString(),
                    compareTo: selectedDates.comparePeriod.toDateUTC?.toISOString(),
                },
            })
        )

        dispatch(merchantDashboardReducerActions.setMerchantDashboardData(dataFromServer, webshopId, selectedDates))
    }

export const getDashboardUpdatedDateThunk =
    (webshopId: string, force?: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const data = merchantDashboardSelectors.latestReportDate(getState(), webshopId)

        if ((data || data === null) && !force) {
            return
        }

        const response = await dispatch(
            requestThunk<{ latestReportDate?: string }>(endpoints.Analytics.getDashboardUpdatedDate, {
                params: {
                    webshopId: webshopId,
                },
            })
        )

        dispatch(
            merchantDashboardReducerActions.setWebshopDashboardLatestReportDate(
                webshopId,
                response.latestReportDate ? new Date(response.latestReportDate) : undefined
            )
        )
    }

export const exportDashboardDataThunk =
    (
        exportType: string,
        swMerchantId: string,
        webshopId: string,
        selectedDates: IMerchantDashboardSelectedDates,
        paymentMethods: string[],
        merchantDashboardServicesInfo: TMerchantDashboardServicesInfo
    ): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const res = await dispatch(
            requestThunk<any>(endpoints.Analytics.getDashboardDataExport, {
                data: {
                    swMerchantId: swMerchantId,
                    webshopId: webshopId,
                    from: selectedDates.dates.fromDateUTC?.toISOString(),
                    to: selectedDates.dates.toDateUTC?.toISOString(),
                    compareFrom: selectedDates.comparePeriod.fromDateUTC?.toISOString(),
                    compareTo: selectedDates.comparePeriod.toDateUTC?.toISOString(),
                    paymentMethods: paymentMethods,
                    includeWinBack: merchantDashboardServicesInfo.winback.isEnabled,
                    includePlusSell: merchantDashboardServicesInfo.plusSell.isEnabled,
                    includeExternalAnalytics: merchantDashboardServicesInfo.externalAnalytics.isEnabled,
                    exportType: exportType,
                    culture: getCultureWithFallback(),
                    translations: {
                        revenue: getText("merchantDashboard.export.revenue"),
                        refunds: getText("merchantDashboard.export.refunds"),
                        orders: getText("merchantDashboard.export.orders"),
                        avgOrderSize: getText("merchantDashboard.export.avgOrderSize"),
                        winBackSuccesses: getText("merchantDashboard.export.winBackSuccesses"),
                        winBackSuccessPct: getText("merchantDashboard.export.winBackSuccessPct"),
                        winBackTurnover: getText("merchantDashboard.export.winBackTurnover"),
                        paymentMethods: getText("merchantDashboard.export.paymentMethods"),
                        otherCreditCardPaymentMethods: getText("merchantDashboard.export.otherCreditCardPaymentMethods"),
                        plusSellCount: getText("merchantDashboard.plusSell.count"),
                        plusSellAdditionalSaleAmount: getText("merchantDashboard.plusSell.additionalSaleAmount"),
                        plusSellAvgOrderIncrease: getText("merchantDashboard.plusSell.avgOrderIncrease"),
                        analyticsConversion: getText("merchantDashboard.extAnalytics.conversionTitle"),
                    },
                },
                responseType: "blob",
            })
        )

        const fileName = getDashboardExportFileName(
            merchantSelectors.merchantDetails(getState())?.webshops?.find((w) => w.webshopId === webshopId)?.hostName ||
                webshopId,
            selectedDates.dates.fromDateUTC!,
            selectedDates.dates.toDateUTC!,
            exportType === "Excel" ? "xlsx" : "csv"
        )

        downloadBlob(new Blob([res]), fileName)
    }
