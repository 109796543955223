import cn from "classnames"
import React from "react"
import { StandardButtonWithSpinner, TextButton } from "swiipe.portal.shared"
import OkImage from "../../images/icons/ok-green.png"
import "./SendEmailBox.scss"

export interface ISendEmailBoxProps {
    isAvailable: boolean | undefined
    isAlreadySent: boolean
    alreadySentText?: string
    sendEmail: () => Promise<void>
    className?: string
}

export const SendEmailBox = ({ isAvailable, isAlreadySent, alreadySentText, sendEmail, className }: ISendEmailBoxProps) => {
    return (
        <div className={cn("send-email-box", className)}>
            {!isAvailable && <>Not available</>}

            {isAvailable && isAlreadySent && (
                <div className="send-email-box__already-sent">
                    <span>
                        <img src={OkImage} />
                        {alreadySentText}
                    </span>
                    <TextButton
                        color="grey"
                        textDecoration="underline"
                        text="Send again"
                        onClick={async () => {
                            await sendEmail()
                        }}
                    />
                </div>
            )}

            {isAvailable && !isAlreadySent && (
                <StandardButtonWithSpinner
                    containerClass="send-email-box__send-button-container"
                    inverted
                    title="Send email"
                    onClick={async () => {
                        await sendEmail()
                    }}
                />
            )}
        </div>
    )
}
