import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector, userSelectors } from "swiipe.portal.shared"
import { getUserPaymentMethodsThunk } from "../../../store/thunks/userPersonalInfoThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import InfoBoxContainer from "../../infobox/InfoBoxContainer"
import A2aEditor from "../paymentmethods/A2aEditor"
import CreditCardEditor from "../paymentmethods/CreditCardEditor"
import "./UserPaymentMethodsForm.scss"

interface IUserPaymentMethodsFormProps {
    inEditModeIdentifier?: string
    onSwitchEditMode: (cardNumber: string, isInEditMode: boolean) => void
}

const UserPaymentMethodsForm = ({ inEditModeIdentifier, onSwitchEditMode }: IUserPaymentMethodsFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    dispatch(getUserPaymentMethodsThunk(false))
    const userPaymentMethods = useSelector(userSelectors.userPaymentMethods)

    if (!userPaymentMethods || !userPaymentMethods.paymentMethods.length) {
        return null
    }

    const savedCreditCards = userPaymentMethods.paymentMethods.filter((pm) => pm.paymentType === "CreditCard")
    const savedA2a = userPaymentMethods.paymentMethods.filter((pm) => pm.paymentType === "AccountToAccount")

    return (
        <>
            {savedCreditCards.length > 0 && (
                <InfoBoxContainer isInEditMode={!!inEditModeIdentifier} iconClass="icon-payment" title={t("personal.cards")}>
                    {userPaymentMethods.paymentMethods.map((pm) => (
                        <CreditCardEditor
                            key={pm.paymentId}
                            paymentMethod={pm}
                            cardNumberInEditMode={inEditModeIdentifier}
                            onSwitchEditMode={onSwitchEditMode}
                        />
                    ))}
                </InfoBoxContainer>
            )}
            {savedA2a.length > 0 && (
                <InfoBoxContainer
                    isInEditMode={!!inEditModeIdentifier}
                    iconClass="icon-a2a-payment"
                    title={t("personal.a2a-accounts")}
                >
                    {userPaymentMethods.paymentMethods.map((pm) => (
                        <A2aEditor
                            key={pm.paymentId}
                            paymentMethod={pm}
                            cardNumberInEditMode={inEditModeIdentifier}
                            onSwitchEditMode={onSwitchEditMode}
                        />
                    ))}
                </InfoBoxContainer>
            )}
        </>
    )
}

export default UserPaymentMethodsForm
