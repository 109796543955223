import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { EInvoicingMarket, sharedConfigurationSelectors, useSelector } from "swiipe.portal.shared"
import { getLinkForTerms, getTermsTypesForUserRelationType } from "../../services/legalService"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { TUserRelation } from "../../type/TUserRelation"
import { ETermsType } from "./../../store/reducers/legalReducer"
import "./LegalTextWithLinks.scss"

interface IGeneralTermsAndPrivacyLinksProps {
    userRelationType: TUserRelation
    userRelationId?: string
    inForm?: boolean
    fallbackMarket?: EInvoicingMarket
}

interface IText {
    userRelationType: TUserRelation
    text1: string
    linkTerms: string
    text2: string
    linkPrivacy: string
}

const texts: IText[] = [
    {
        userRelationType: "User",
        text1: "signup.acceptterms1",
        linkTerms: "signup.terms",
        text2: "signup.acceptterms2",
        linkPrivacy: "signup.privacy",
    },
    {
        userRelationType: "Merchant",
        text1: "merchantsignup.acceptterms1",
        linkTerms: "merchantsignup.terms",
        text2: "merchantsignup.acceptterms2",
        linkPrivacy: "merchantsignup.privacy",
    },
    {
        userRelationType: "Partner",
        text1: "merchantsignup.acceptterms1",
        linkTerms: "merchantsignup.terms",
        text2: "merchantsignup.acceptterms2",
        linkPrivacy: "merchantsignup.privacy",
    },
    {
        userRelationType: "CallCenter",
        text1: "merchantsignup.acceptterms1",
        linkTerms: "merchantsignup.terms",
        text2: "merchantsignup.acceptterms2",
        linkPrivacy: "merchantsignup.privacy",
    },
]

const GeneralTermsAndPrivacyLinks = ({
    userRelationType,
    userRelationId,
    inForm,
    fallbackMarket,
}: IGeneralTermsAndPrivacyLinksProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const env = useSelector(sharedConfigurationSelectors.environment)
    const termsTypes = getTermsTypesForUserRelationType(userRelationType, env)
    const textsForUserRelationType = texts.find((t) => t.userRelationType === userRelationType)

    const showLegalPage = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, termsType: ETermsType) => {
        e.preventDefault()
        dispatch(
            addModalThunk({
                type: "terms",
                userRelationType,
                termsType,
                relationId: userRelationId,
                fallbackMarket,
            })
        )
    }

    if (!textsForUserRelationType) {
        return null
    }
    return (
        <p className={cn("swiipe-accept-terms", "text-center", { "in-form": inForm })}>
            {t(textsForUserRelationType.text1)}
            <a
                onClick={(e) => showLegalPage(e, termsTypes.terms)}
                href={getLinkForTerms(termsTypes.terms, userRelationType, userRelationId, fallbackMarket)}
            >
                {t(textsForUserRelationType.linkTerms)}
            </a>
            {t(textsForUserRelationType.text2)}
            <a
                onClick={(e) => showLegalPage(e, termsTypes.privacy)}
                href={getLinkForTerms(termsTypes.privacy, userRelationType, userRelationId, fallbackMarket)}
            >
                {t(textsForUserRelationType.linkPrivacy)}
            </a>
        </p>
    )
}

export default GeneralTermsAndPrivacyLinks
