import React from "react"
import { Input, InputProps } from "reactstrap"
import { TRegister } from "swiipe.portal.shared"

interface IFormToFilterInputs extends InputProps {
    labelFrom: string
    labelTo: string
    inputNameFrom: string
    inputNameTo: string
    filterNameFrom: string
    filterNameTo: string
    placeholder: string
    onKeyDownInputs?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    register: TRegister
}

const FromToFilterInputs = ({
    labelFrom,
    labelTo,
    inputNameFrom,
    inputNameTo,
    filterNameFrom,
    filterNameTo,
    placeholder,
    onKeyDownInputs,
    register,
    ...otherProps
}: IFormToFilterInputs) => {
    return (
        <>
            <span className="input-title">{labelFrom}</span>
            <Input
                {...otherProps}
                name={inputNameFrom}
                className="from-to-input"
                placeholder={placeholder}
                innerRef={register}
                onKeyDown={(e) => {
                    if (onKeyDownInputs) {
                        onKeyDownInputs(e)
                    }

                    if (otherProps.onKeyDown) {
                        otherProps.onKeyDown(e)
                    }
                }}
            />
            <span className="input-title">{labelTo}</span>
            <Input
                {...otherProps}
                name={inputNameTo}
                className="from-to-input"
                placeholder={placeholder}
                innerRef={register}
                onKeyDown={(e) => {
                    if (onKeyDownInputs) {
                        onKeyDownInputs(e)
                    }

                    if (otherProps.onKeyDown) {
                        otherProps.onKeyDown(e)
                    }
                }}
            />
        </>
    )
}

export default FromToFilterInputs
