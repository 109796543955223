import { ECommentArea, IInternalComment, sortDescending } from "swiipe.portal.shared"

export function getLatestCommentForArea(comments: IInternalComment[] | undefined, area: ECommentArea) {
    if (!comments) {
        return undefined
    }
    const sorted = sortDescending(
        comments.filter((c) => c.area === area),
        (c) => c.created
    )
    return sorted.length > 0 ? sorted[0] : undefined
}
