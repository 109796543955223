import React from "react"
import { CheckmarkCircle, StandardBox, StandardButton, StandardButtonWithSpinner } from "swiipe.portal.shared"
import { ClearhausOnboardingFooter } from "./ClearhausOnboardingFooter"
import "./ClearhausOnboardingMessage.scss"

interface IClearhausOnboardingMessage {
    mainText: React.ReactNode
    secondaryText?: React.ReactNode
    checkmarkHidden?: boolean
    mainButton: {
        text: string
        onClick: () => void | Promise<void>
    }
    leftButton?: {
        text: string
        onClick: () => void
    }
}

const ClearhausOnboardingMessage = ({
    mainText,
    secondaryText,
    checkmarkHidden,
    mainButton,
    leftButton,
}: IClearhausOnboardingMessage) => {
    return (
        <div className="clearhaus-onboarding-message">
            <StandardBox className="mt-2" padding="35-20" margin="0" borders="rounded">
                <div className="clearhaus-onboarding-message__main-message">
                    <div className="clearhaus-onboarding-message__circle">
                        {!checkmarkHidden && <CheckmarkCircle size="big" checked={true} />}
                    </div>
                    <div className="clearhaus-onboarding-message__text">{mainText}</div>
                </div>
                {secondaryText && (
                    <div className="clearhaus-onboarding-message__message-after-underline">
                        <hr />
                        <div className="content">{secondaryText}</div>
                    </div>
                )}
            </StandardBox>
            <ClearhausOnboardingFooter
                left={
                    leftButton && (
                        <StandardButton
                            className="clearhaus-onboarding-message__left-button"
                            inverted
                            onClick={leftButton.onClick}
                        >
                            {leftButton.text}
                        </StandardButton>
                    )
                }
                middle={
                    <StandardButtonWithSpinner
                        className="clearhaus-onboarding-message__main-button"
                        onClick={async (setShowSpinner) => {
                            setShowSpinner(true)
                            await mainButton.onClick()
                            setShowSpinner(false)
                        }}
                        dark
                        isBlue
                        noBorder
                    >
                        {mainButton.text}
                    </StandardButtonWithSpinner>
                }
            />
        </div>
    )
}

export default ClearhausOnboardingMessage
