import React from "react"
import { useTranslation } from "react-i18next"
import { Row } from "reactstrap"
import { useSelector, userSelectors, valFuncRequiredAndPattern, validationPatterns } from "swiipe.portal.shared"
import { deleteUserFullNameThunk, updateUserDataThunk } from "../../../store/thunks/userPersonalInfoThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IEditFormBase } from "../../../type/form/IEditFormBase"
import { ChangeUserFullNameForm } from "../../../type/personal/changeuserfullnameform"
import InfoBoxEditor from "../../infobox/InfoBoxEditor"
import { InfoBoxState } from "../../infobox/InfoBoxState"
import InfoBoxContainer from "./../../infobox/InfoBoxContainer"
import EditFormInput from "./../input/EditFormInput"

interface IUserFullnameFromProps extends IEditFormBase {}

const UserFullNameForm = ({ isInEditMode, onSwitchEditMode }: IUserFullnameFromProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const userData = useSelector(userSelectors.userData)
    if (!userData || !userData.user) {
        return null
    }

    return (
        <InfoBoxContainer isInEditMode={isInEditMode} iconClass="icon-fullname" title={t("personal.name")}>
            <InfoBoxEditor<ChangeUserFullNameForm>
                state={InfoBoxState.DEFAULT}
                iconClass="icon-fullname"
                title={userData.user.fullName ? t("personal.editname") : t("personal.addname")}
                isInEditMode={isInEditMode}
                editBtnTextType={userData.user.fullName ? "editing" : "adding"}
                renderPreview={() =>
                    userData.user.fullName ? (
                        <div>{userData.user.fullName}</div>
                    ) : (
                        <div>
                            <strong>{t("personal.addname")}</strong>
                        </div>
                    )
                }
                renderFormFields={(register) => (
                    <div className="edit-form">
                        <Row>
                            <div className="col-12 pl-3 col-md-6">
                                <EditFormInput
                                    defaultValue={userData.user.firstName}
                                    name="firstName"
                                    innerRef={register(
                                        valFuncRequiredAndPattern(
                                            validationPatterns.firstName,
                                            t("personal.validationerrors.firstName")
                                        )
                                    )}
                                    placeholder={t("personal.firstName")}
                                />
                            </div>
                            <div className="col-12 pl-3 mt-2 col-md-6 pl-md-0 mt-md-0">
                                <EditFormInput
                                    defaultValue={userData.user.lastName}
                                    name="lastName"
                                    innerRef={register(
                                        valFuncRequiredAndPattern(
                                            validationPatterns.lastName,
                                            t("personal.validationerrors.lastName")
                                        )
                                    )}
                                    placeholder={t("personal.lastName")}
                                />
                            </div>
                        </Row>
                    </div>
                )}
                onSave={async (model) => {
                    await dispatch(
                        updateUserDataThunk({
                            ...model,
                            type: "nameForm",
                        })
                    )
                }}
                onDelete={async (model) => {
                    await dispatch(deleteUserFullNameThunk())
                }}
                allowDelete={true}
                onSwitchEditMode={onSwitchEditMode}
            />
        </InfoBoxContainer>
    )
}

export default UserFullNameForm
