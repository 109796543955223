import cn from "classnames"
import React from "react"
import { Input, InputProps } from "reactstrap"
import "./DropDownList.scss"

export interface IDropdownOption<T> {
    text: string
    value: T
}

export interface IDropDownListProps<T> extends InputProps {
    options: IDropdownOption<T>[]
    onChangeDropDown?: (e: React.ChangeEvent<HTMLInputElement>, value: T) => void
    isSmall?: boolean
    noBorderRight?: boolean
    smallBorderRadius?: boolean
}

export const DropDownList = <T extends string | number>({
    options,
    onChangeDropDown,
    onChange,
    isSmall,
    noBorderRight,
    smallBorderRadius,
    placeholder,
    defaultValue,
    ...otherProps
}: IDropDownListProps<T>) => {
    return (
        <Input
            type="select"
            {...otherProps}
            className={cn(
                "drop-down-list-select",
                isSmall && "small",
                { "no-border-right": noBorderRight, "small-border-radius": smallBorderRadius },
                otherProps.className
            )}
            defaultValue={defaultValue}
            onChange={(e) => {
                if (onChange) {
                    onChange(e)
                }

                if (onChangeDropDown) {
                    onChangeDropDown(e, e.target.value as T)
                }
            }}
        >
            {placeholder && (
                <option key="" value="">
                    {placeholder}
                </option>
            )}
            {options.map((p) => (
                <option key={p.value} value={p.value}>
                    {p.text}
                </option>
            ))}
        </Input>
    )
}
