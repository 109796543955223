import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Row } from "reactstrap"
import {
    FormGroupWithCheckbox,
    PhoneNumberInput,
    useSelector,
    valFuncPattern,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { InfoBoxDeleteDialogModel } from "../../../../src/type/InfoBoxDeleteDialogModel"
import ToggleButtonCheckbox from "../../../components/buttons/ToggleButtonCheckbox"
import { getTextIdForRole } from "../../../services/userRelationService"
import { partnerSelectors } from "../../../store/reducers/partnerReducer"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IRelationForOrganization } from "../../../type/IRelationsForOrganization"
import { TUserRole } from "../../../type/TUserRole"
import { IEditFormBase } from "../../../type/form/IEditFormBase"
import InfoBoxContainer from "../../infobox/InfoBoxContainer"
import InfoBoxEditor from "../../infobox/InfoBoxEditor"
import { InfoBoxState } from "../../infobox/InfoBoxState"
import Adder from "../common/Adder"
import EditFormInput from "../input/EditFormInput"
import {
    addUserRelationThunk,
    changeActiveStateForOrganizationThunk,
    changeAdminStateForOrganizationThunk,
    changeOwnerForOrganizationThunk,
    changeUserDataAsMasterPartner,
    deleteUserRelationThunk,
} from "./../../../store/thunks/userRelationThunks"
import { IAddUserRelation } from "./../../../type/sales/IUpdateSalesEmployee"
import "./OrganizationUsersForm.scss"

interface IOrganizationUsersFormProps extends IEditFormBase {
    userRelation?: IRelationForOrganization
    organizationOwnerId?: string
    isAddNew?: boolean
}

const OrganizationUsersForm = ({
    userRelation,
    isInEditMode,
    organizationOwnerId,
    onSwitchEditMode,
    isAddNew,
}: IOrganizationUsersFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const [isAdmin, setIsAdmin] = useState(userRelation && userRelation.userRole >= TUserRole.Admin)
    const [chooseAsOwnerOnSave, setChooseAsOwnerOnSave] = useState(false)

    const hasMasterPartner = useSelector(partnerSelectors.partnersContainMasterPartner)

    const onSaveUser = () => {
        if (userRelation) {
            return async (model: IAddUserRelation) => {
                if (model.isAdmin ? userRelation.userRole < TUserRole.Admin : userRelation.userRole == TUserRole.Admin) {
                    await dispatch(changeAdminStateForOrganizationThunk(userRelation.userId, !!model.isAdmin))
                }

                if (hasMasterPartner) {
                    await dispatch(changeUserDataAsMasterPartner(model, userRelation.userId, userRelation.email))
                }

                if (chooseAsOwnerOnSave) {
                    if (organizationOwnerId) {
                        await dispatch(changeOwnerForOrganizationThunk(organizationOwnerId, userRelation.userId))
                    }
                }
            }
        } else {
            return async (model: IAddUserRelation) => {
                await dispatch(addUserRelationThunk(model))
            }
        }
    }

    const infoboxDeleteDialogModel: InfoBoxDeleteDialogModel = {
        dialogHeading: t("organizationUsers.removeusertitle"),
        dialogText: t("organizationUsers.removeusertext", { email: userRelation ? userRelation.email : "" }),
        cancelBtnText: t("organizationUsers.goback"),
        confirmDeleteBtnText: t("organizationUsers.yesdelete"),
    }

    if (!userRelation && !isInEditMode) {
        return <Adder onClick={() => onSwitchEditMode(true)} buttonText={t("organizationUsers.adduser")} />
    }

    return (
        <InfoBoxContainer
            narrow
            alwaysShowTitle
            showOnMobile={false}
            isInEditMode={isInEditMode}
            iconClass="icon-employee"
            className="organization-users"
            iconToTop={!userRelation ? false : userRelation.userRole > TUserRole.Admin ? false : true}
            noPadding
            title={
                userRelation
                    ? t(getTextIdForRole(userRelation.userRole))
                    : t(getTextIdForRole(isAdmin ? TUserRole.Admin : TUserRole.Read))
            }
        >
            <InfoBoxEditor<IAddUserRelation>
                state={
                    !userRelation
                        ? InfoBoxState.NONE
                        : userRelation.userRole > TUserRole.Admin && !hasMasterPartner
                        ? InfoBoxState.NOEDIT
                        : userRelation.isActive
                        ? InfoBoxState.DEFAULT
                        : InfoBoxState.DELETEONLY
                }
                title={userRelation ? t("organizationUsers.editinfo") : t("organizationUsers.adduser")}
                isInEditMode={isInEditMode}
                editBtnTextType="editing"
                editBtnBindTop
                deleteButtonTitle={t("organizationUsers.delete")}
                componentUnmountsAfterDelete
                confirmDeleteDialog={infoboxDeleteDialogModel}
                iconClass={userRelation ? "icon-employee-smaller" : undefined}
                renderPreview={() =>
                    !userRelation ? (
                        <div></div>
                    ) : (
                        <>
                            <div>{userRelation.name}</div>
                            <div className="mail-container">{userRelation.email}</div>
                            <div className="phone-container">{userRelation.phone}</div>
                        </>
                    )
                }
                renderFormFields={(register, setValue, getValues, watch) => {
                    if (isAddNew) {
                        return (
                            <div className="edit-form">
                                <Row>
                                    <div className="col-12">
                                        <EditFormInput
                                            defaultValue={userRelation ? userRelation.email : ""}
                                            name="email"
                                            innerRef={register(
                                                valFuncRequiredAndPattern(
                                                    validationPatterns.email,
                                                    t("common.email.errorMessage")
                                                )
                                            )}
                                            disabled={!!userRelation}
                                            placeholder={t("organizationUsers.email")}
                                        />
                                    </div>
                                </Row>
                            </div>
                        )
                    } else if (hasMasterPartner) {
                        return (
                            <div className="edit-form">
                                <Row>
                                    <div className="col-12 pl-3 col-md-6">
                                        <EditFormInput
                                            defaultValue={userRelation ? userRelation.firstName : ""}
                                            name="firstName"
                                            innerRef={register(
                                                valFuncPattern(
                                                    validationPatterns.firstName,
                                                    t("personal.validationerrors.firstName")
                                                )
                                            )}
                                            placeholder={t("personal.firstName")}
                                        />
                                    </div>
                                    <div className="col-12 pl-3 col-md-6 pl-md-0">
                                        <EditFormInput
                                            defaultValue={userRelation ? userRelation.lastName : ""}
                                            name="lastName"
                                            innerRef={register(
                                                valFuncPattern(
                                                    validationPatterns.lastName,
                                                    t("personal.validationerrors.lastName")
                                                )
                                            )}
                                            placeholder={t("personal.lastName")}
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-12">
                                        <EditFormInput
                                            defaultValue={userRelation ? userRelation.email : ""}
                                            name="email"
                                            innerRef={register(
                                                valFuncRequiredAndPattern(
                                                    validationPatterns.email,
                                                    t("common.email.errorMessage")
                                                )
                                            )}
                                            placeholder={t("organizationUsers.email")}
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-12">
                                        <PhoneNumberInput<IAddUserRelation>
                                            inputName="phone"
                                            defaultValue={userRelation ? userRelation.phone : ""}
                                            register={register}
                                            setValue={setValue}
                                            getValues={getValues}
                                            watch={watch}
                                            required={false}
                                        />
                                    </div>
                                </Row>
                            </div>
                        )
                    } else {
                        return (
                            <div className="edituser-box my-3">
                                <div className="name-container">{userRelation && userRelation.name}</div>
                                <div className="mail-container">{userRelation && userRelation.email}</div>
                                <div className="phone-container">{userRelation && userRelation.phone}</div>
                            </div>
                        )
                    }
                }}
                renderExtraFields={(register) => {
                    return (
                        <>
                            <FormGroupWithCheckbox
                                defaultChecked={userRelation && userRelation.userRole >= TUserRole.Admin}
                                onChange={(e) => {
                                    if (!userRelation) {
                                        setIsAdmin(e.target.checked)
                                    }
                                }}
                                className="personal-checkbox"
                                innerRef={register}
                                name="isAdmin"
                                title={t("organizationUsers.giverights")}
                            />
                            {hasMasterPartner &&
                                !!organizationOwnerId &&
                                !!userRelation &&
                                userRelation.userId !== organizationOwnerId &&
                                userRelation.isActive && (
                                    <FormGroupWithCheckbox
                                        className="personal-checkbox"
                                        title={t("organizationUsers.makeanowner")}
                                        onChange={(e) => {
                                            setChooseAsOwnerOnSave(e.target.checked)
                                        }}
                                    />
                                )}
                        </>
                    )
                }}
                allowDelete={false}
                onDelete={async () => {
                    if (!userRelation) {
                        return
                    }
                    await dispatch(deleteUserRelationThunk(userRelation.userId))
                }}
                saveButtonTitle={userRelation ? t("organizationUsers.save") : t("organizationUsers.invite")}
                onSave={onSaveUser()}
                onSwitchEditMode={(inEditMode) => {
                    if (!inEditMode && !userRelation) {
                        // Reset isAdmin value when closing adder
                        setIsAdmin(false)
                    }
                    onSwitchEditMode(inEditMode)
                }}
            />
            {userRelation && userRelation.userRole < TUserRole.Owner && !isInEditMode && (
                <div className="switch-container mt-1">
                    <span className={cn("switch-text", { active: userRelation.isActive })}>
                        {userRelation.isActive ? t("organizationUsers.enabled") : t("organizationUsers.disabled")}
                    </span>
                    <ToggleButtonCheckbox
                        id={"switch-" + userRelation.userId}
                        onClick={async () => {
                            await dispatch(changeActiveStateForOrganizationThunk(userRelation.userId, !userRelation.isActive))
                        }}
                        checked={userRelation.isActive}
                    />
                </div>
            )}
        </InfoBoxContainer>
    )
}

export default OrganizationUsersForm
