import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { PageWrapper, sharedConfigurationSelectors, useSelector } from "swiipe.portal.shared"
import FormFooter from "../../components/form/footer/FormFooter"
import FormHeader from "../../components/form/title/FormHeader"
import MessageBox from "../../components/layouts/MessageBox"
import MerchantSignupForm from "./../../components/form/signup/MerchantSignupForm"

interface IMerchantSignupPageProps {}

const MerchantSignupPage = ({}: IMerchantSignupPageProps) => {
    const [hasSignedUpWithEmail, setEmail] = useState("")
    const { t } = useTranslation()
    const env = useSelector(sharedConfigurationSelectors.environment)

    if (hasSignedUpWithEmail) {
        return (
            <PageWrapper>
                <MessageBox id="signup" title={t("merchantsignup.welcometitle")} centerContent>
                    <p className="mb-4">
                        <strong>{t("merchantsignup.welcomemsg")}</strong>
                        <br />
                        {t("merchantsignup.welcomemsg1", { email: hasSignedUpWithEmail })}
                        <br />
                        {t("merchantsignup.welcomemsg3")}
                    </p>
                    <p>{t("merchantsignup.welcomemsg2")}</p>
                    <p className="small-text mt-5">{t("merchantsignup.welcomemsg4")}</p>
                </MessageBox>
            </PageWrapper>
        )
    }
    return (
        <PageWrapper>
            <div id="merchant-signup" className="center-form">
                <div className="panel">
                    <FormHeader
                        title={t("signup.merchantFormHeader.title")}
                        showSandboxTitle={env === "Sandbox"}
                        titleWeightMedium
                    />
                    <div className="panel-body mb-5">
                        <MerchantSignupForm onSignedUp={(email) => setEmail(email)} />
                    </div>
                    <FormFooter />
                </div>
            </div>
        </PageWrapper>
    )
}

export default MerchantSignupPage
