import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, ModalFooter, SpinnerContainer, getKeys } from "swiipe.portal.shared"
import ListOfMessagesSection from "../../components/sections/ListOfMessagesSection"
import checkmarkImg from "../../images/icons/ok-green.png"
import { getPlatformOrderIdFormatted } from "../../services/orderService"
import { TBulkActionType, bulkActionsThunk } from "../../store/thunks/transactionThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { IBulkActionsResponse } from "../../type/IBulkActionsResponse"
import { Order } from "../../type/Order"
import "./ModalBulkActions.scss"

export interface IModalBulkActions extends IModalBase {
    type: "bulkActions"
    actionType: TBulkActionType
    webshopId: string
    selectedOrders: Order[]
    onUpdate?: () => Promise<void>
}

const ModalBulkActions = ({
    title,
    actionType,
    webshopId,
    selectedOrders,
    onUpdate,
    callback,
    ...otherProps
}: IModalBulkActions & IModalControlProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()

    const [bulkActionsResponse, setBulkActionsResponse] = useState(undefined as IBulkActionsResponse | undefined)

    useEffect(() => {
        ;(async () => {
            const ordersToExecute = selectedOrders.filter((order) => {
                if (actionType === "Capture") {
                    return order.actions.canCapture || order.actions.canPartialCapture
                }
                if (actionType === "Refund") {
                    return order.actions.canRefund || order.actions.canPartialRefund
                }
                if (actionType === "Void") {
                    return order.actions.canCancel
                }
                return true
            })

            const result = await dispatch(bulkActionsThunk(webshopId, ordersToExecute, actionType))

            setBulkActionsResponse(result)
            onUpdate && (await onUpdate())
        })()
    }, [])

    const getSuccessfulOrdersText = () => {
        if (!bulkActionsResponse) {
            return null
        }

        const responseKeys = getKeys(bulkActionsResponse.results)
        const successfulOrdersCount = responseKeys.filter((key) => bulkActionsResponse.results[key].success).length
        const addBottomMargin = successfulOrdersCount < responseKeys.length

        if (successfulOrdersCount) {
            return (
                <div className={cn("success-container", { "mb-4": addBottomMargin })}>
                    <p className="mb-0 text-center">
                        {t(`transactions.bulkActionModals.messages.${actionType.toLowerCase()}Success`, {
                            amountOfOrders: successfulOrdersCount,
                        })}
                    </p>
                    <img src={checkmarkImg} />
                </div>
            )
        }

        return null
    }

    const getFailedOrdersList = () => {
        const failedOrdersIds = getKeys(bulkActionsResponse?.results || {}).filter(
            (key) => !bulkActionsResponse?.results[key].success
        )
        const failedOrdersCount = failedOrdersIds.length

        if (failedOrdersCount) {
            return (
                <ListOfMessagesSection
                    header={t(`transactions.bulkActionModals.messages.actionFail.title${actionType}`, {
                        amountOfOrders: failedOrdersCount,
                    })}
                    showMoreText={t("transactions.bulkActionModals.messages.actionFail.seeMore")}
                    messages={failedOrdersIds.map((id) =>
                        t(`transactions.bulkActionModals.errors.${actionType.toLowerCase()}Error`, {
                            platformOrderId: getPlatformOrderIdFormatted(
                                selectedOrders.find((o) => o.orderId === id)?.platformOrderId
                            ),
                        })
                    )}
                    styleError
                />
            )
        }

        return null
    }

    return (
        <BaseModal {...otherProps} modalClassName="modal-bulk-actions" callback={callback} centered title={title} showCloseButton>
            <ModalBody>
                <SpinnerContainer
                    className={cn({ "empty-spinner-container": !bulkActionsResponse })}
                    showSpinner={!bulkActionsResponse}
                >
                    {!!bulkActionsResponse && (
                        <div className="content-container">
                            {getSuccessfulOrdersText()}
                            {getFailedOrdersList()}
                        </div>
                    )}
                </SpinnerContainer>
            </ModalBody>
            <ModalFooter rightButton={{ onClick: async () => callback("accepted"), text: t("common.close") }} />
        </BaseModal>
    )
}

export default ModalBulkActions
