import React from "react"
import { useTranslation } from "react-i18next"
import { StandardButtonWithSpinner, TextButton } from "swiipe.portal.shared"
import { getImage } from "../../../../services/imageLanguageService"
import { IApplePayOnboardingData, applePayOnboardingThunk } from "../../../../store/thunks/applePayThunks"
import { useReduxDispatch } from "../../../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../../../type/mixedmerchantdetails"
import "./ApplePayNoCertsStep.scss"

interface IApplePayNoCertsStep {
    onBackStep(): Promise<void>
    onNextStep(): void
    merchantDetails: MixedMerchantDetails
    webshopId: string
}

const ApplePayNoCertsStep = ({ onNextStep, onBackStep, merchantDetails, webshopId }: IApplePayNoCertsStep) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()

    const onboard = async () => {
        try {
            const formData: IApplePayOnboardingData = {
                applePayButtonLocation: "SwiipeDomain",
                swMerchantId: merchantDetails.swMerchant.swMerchantId,
                swMerchantEmail: merchantDetails.swMerchantContacts[0]?.email,
                swMerchantName: merchantDetails.swMerchant.name,
                merchantContactName: merchantDetails.swMerchantContacts[0]?.name,
                merchantContactPhone: merchantDetails.swMerchantContacts[0]?.phone,
                webshopIds: [webshopId],
            }

            await dispatch(applePayOnboardingThunk(formData, false))
            onNextStep()
        } catch {
            //stops spinner
        }
    }

    return (
        <section className="applepay-id-step">
            <div className="applepay-form-head">
                <img className="applepay-logo" src={getImage("applePayLogo")} />
            </div>
            <div className="apple-pay-no-certs-step">
                <p className="mt-5 mb-5">{t("applepayonboarding.noCertsOnboarding.activateDesc")}</p>
                <StandardButtonWithSpinner dark smallBorderRadius isBlue onClick={onboard}>
                    {t("applepayonboarding.noCertsOnboarding.activateBtn")}
                </StandardButtonWithSpinner>
            </div>
            <TextButton text={t("commononboardingtexts.goback")} className="mb-5 mt-4" onClick={onBackStep} />
        </section>
    )
}

export default ApplePayNoCertsStep
