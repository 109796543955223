import cn from "classnames"
import React from "react"
import { TCreditCardType } from "swiipe.portal.shared"
import { getLogoByCardType } from "../../../services/paymentService"
import { ESubscriptionOrderType } from "../../../type/Order"
import "./CreditCardTypeLogo.scss"

import InitialPaymentLogo from "../../../images/subscription/subscription_initial_payment.svg"
import SubsequentPaymentLogo from "../../../images/subscription/subscription_subsequent_payment.svg"
import UpdatePaymentLogo from "../../../images/subscription/subscription_update_payment.svg"

export interface ICreditCardTypeLogo {
    paymentSubType?: TCreditCardType
    subscriptionType?: ESubscriptionOrderType
    onDefaultEmpty?: boolean
    className?: string
}

const subscriptionFrameMapping: { [type in ESubscriptionOrderType]: string } = {
    InitialPayment: InitialPaymentLogo,
    SubsequentPayment: SubsequentPaymentLogo,
    UpdatePayment: UpdatePaymentLogo,
}

const CreditCardTypeLogo = ({ paymentSubType, subscriptionType, onDefaultEmpty, className }: ICreditCardTypeLogo) => {
    const iconSrc = getLogoByCardType(paymentSubType, onDefaultEmpty)
    if (!iconSrc) {
        return null
    }
    return (
        <span
            className={cn(
                "sw-credit-card-type-logo",
                subscriptionType && "sw-credit-card-type-logo--has-subscription",
                className
            )}
        >
            {subscriptionType && (
                <>
                    <img
                        className="sw-credit-card-type-logo__subscription-frame"
                        src={subscriptionFrameMapping[subscriptionType]}
                    ></img>
                    <div
                        className="sw-credit-card-type-logo__card-logo"
                        style={{ backgroundImage: `url(${iconSrc})`, margin: !paymentSubType ? "3px" : "0" }}
                    ></div>
                </>
            )}
            {!subscriptionType && <img className="sw-credit-card-type-logo__card-logo" src={iconSrc}></img>}
        </span>
    )
}

export default CreditCardTypeLogo
