import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { ServerError, getText, requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { IBasePaymentOnboardingData } from "../../type/IBasePaymentOnboardingData"
import { StoreState } from "../StoreState"
import { onboardClearhausForPaymentTypeThunk } from "./clearhausThunks"
import { getOnboardingStatusThunk } from "./swiipePaymentsThunks"
import { sendServiceConfirmationEmailThunk } from "./swiipeServicesThunks"

export interface IApplePayOnboardingData extends IBasePaymentOnboardingData {
    applePayButtonLocation: "WebshopDomain" | "SwiipeDomain"
    applePayId?: string
    merchantCertificateFile?: File
    merchantCertificatePrivateKeyFile?: File
    paymentProcessingPrivateKeyFile?: File
}

export const applePayOnboardingThunk =
    (data: IApplePayOnboardingData, isEdit: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        // Should be only one webshop id when onboarding for Apple Pay
        if (data.webshopIds.length !== 1) {
            return
        }

        await dispatch(
            requestThunk<void>(
                endpoints.PaymentIQ.createApplePayOnboarding,
                {
                    data,
                },
                {
                    asFormData: true,
                    errorHandlers: [
                        {
                            errorType: "CertificateInvalidError",
                            handleError: async (error: ServerError) => {
                                await dispatch(
                                    addModalThunk({
                                        type: "error",
                                        errorMessage: getText("applepayonboarding.errorMessages.invalidCert"),
                                    })
                                )
                                return true
                            },
                        },
                        {
                            errorType: "PrivateKeyInvalidError",
                            handleError: async (error: ServerError) => {
                                await dispatch(
                                    addModalThunk({
                                        type: "error",
                                        errorMessage: getText("applepayonboarding.errorMessages.invalidKey"),
                                    })
                                )
                                return true
                            },
                        },
                    ],
                }
            )
        )

        if (!isEdit) {
            await dispatch(sendServiceConfirmationEmailThunk(data.swMerchantId, "ApplePayConfirmation", "ApplePay"))
        }

        await dispatch(onboardClearhausForPaymentTypeThunk(data.swMerchantId, data.webshopIds, "ApplePay"))
        await dispatch(getOnboardingStatusThunk(data.swMerchantId, data.webshopIds[0], true, ["ApplePay"]))
    }

export const getApplePayOnboardingDataThunk =
    (
        webshopId: string,
        merchantId: string
    ): ThunkAction<
        Promise<{ applePayId: string; paymentProcessingCsr: string; merchantIdentityCsr: string }>,
        StoreState,
        null,
        AnyAction
    > =>
    async (dispatch) => {
        const response = await dispatch(
            requestThunk<{ applePayId: string; paymentProcessingCsr: string; merchantIdentityCsr: string }>(
                endpoints.PaymentIQ.getApplePayOnboardingData,
                {
                    params: {
                        webshopId: webshopId,
                        swMerchantId: merchantId,
                    },
                }
            )
        )

        return response
    }

export const createApplePayCsrThunk =
    (
        webshopId: string,
        merchantId: string,
        applePayId: string
    ): ThunkAction<Promise<{ paymentProcessingCsr: string; merchantIdentityCsr: string }>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const response = await dispatch(
            requestThunk<{ paymentProcessingCsr: string; merchantIdentityCsr: string }>(endpoints.PaymentIQ.createApplePayCsr, {
                data: {
                    swMerchantId: merchantId,
                    webshopId: webshopId,
                    applePayId: applePayId,
                },
            })
        )
        return response
    }
