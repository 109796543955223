import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, StandardButton } from "swiipe.portal.shared"
import { getDateToLocalStringWithOptions } from "../../services/dateTimeService"
import "./ModalPaymentLinkError.scss"

export interface IModalPaymentLinkError extends IModalBase {
    type: "paymentLinkError"
    platformOrderId: string
    created: string
    canBeOverridden: boolean
}

const ModalPaymentLinkError = ({
    platformOrderId,
    created,
    canBeOverridden,
    callback,
    ...otherProps
}: IModalPaymentLinkError & IModalControlProps) => {
    const { t } = useTranslation()
    return (
        <BaseModal
            {...otherProps}
            modalClassName="payment-link-error-modal"
            callback={callback}
            centered
            showCloseButton
            title={t("paymentLinkErrorModal.title", { platformOrderId })}
        >
            <ModalBody>
                <div>
                    <p className="mb-0 text-center errormessage-text">
                        {t(`paymentLinkErrorModal.${canBeOverridden ? "canBeOverridden" : "cannotBeOverridden"}.exists`, {
                            platformOrderId,
                            createdOn: getDateToLocalStringWithOptions(created, {
                                dateStyle: "long",
                            }),
                        })}
                    </p>
                    <p className="mb-0 text-center errormessage-text">
                        {t(`paymentLinkErrorModal.${canBeOverridden ? "canBeOverridden" : "cannotBeOverridden"}.replace`)}
                    </p>

                    <div className="mt-4 buttons">
                        {canBeOverridden ? (
                            <>
                                <StandardButton inverted onClick={() => callback("declined")}>
                                    {t("paymentLinkErrorModal.btn.goBack")}
                                </StandardButton>
                                <StandardButton invertedBlueBorder onClick={() => callback("accepted")}>
                                    {t("paymentLinkErrorModal.btn.replace")}
                                </StandardButton>
                            </>
                        ) : (
                            <>
                                <StandardButton onClick={() => callback("declined")}>{t("common.ok")}</StandardButton>
                            </>
                        )}
                    </div>
                </div>
            </ModalBody>
        </BaseModal>
    )
}

export default ModalPaymentLinkError
