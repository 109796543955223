import { ActionsUnion, createAction } from "swiipe.portal.shared"
import { IOnboardingApplication } from "../../type/IOnboardingApplication"
import { StoreState } from "../StoreState"

export type OnboardingApplicationState = Readonly<{
    applications?: IOnboardingApplication[]
}>

const initialState: OnboardingApplicationState = {}

export const onboardingApplicationReducerActions = {
    setApplications: (applications: IOnboardingApplication[]) =>
        createAction("APP/SET_ONBOARDING_APPLICATIONS", { applications }),
}

export type OnboardingApplicationsActions = ActionsUnion<typeof onboardingApplicationReducerActions>

export const onboardingApplicationReducer = (
    state: OnboardingApplicationState = initialState,
    action: OnboardingApplicationsActions
) => {
    switch (action.type) {
        case "APP/SET_ONBOARDING_APPLICATIONS": {
            return {
                ...state,
                applications: action.applications,
            }
        }
    }

    return state
}

export const onboardingApplicationSelectors = {
    applications: (state: StoreState) => state.onboardingApplications.applications,
}
