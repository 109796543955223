import cn from "classnames"
import $ from "jquery"
import React, { useEffect, useRef, useState } from "react"
import { Alert, Input, InputGroup, InputProps } from "reactstrap"
import { HintIconWithTextOnClick, delay, numberInputOnKeyDown, numberInputOnWheelPreventChange } from "swiipe.portal.shared"
import "./FloatingLabelInput.scss"

export interface IFloatingLabelsInputProps extends InputProps {
    placeholder?: string
    extraLabel?: string
    textareaNoAutoGrow?: boolean // By default textarea auto grows to it's content size
    autoCompleteOff?: boolean
    disableHintAfter?: number
    hintInsideInput?: string
    textInsideInput?: ITextInsideInput
    showHintOnHover?: boolean
    allowNegativeNumbers?: boolean
    containerClass?: string
    inputTextPrefix?: string
    noBorderRadiusLeft?: boolean
    smallBorderRadius?: boolean
    maxDecimals?: number
}

export interface ITextInsideInput {
    text: string
    color?: "red" | "green" | "grey"
    fontStyle?: "italic"
}

const selectors = {
    targetClass: "placeholder-hidden",
}

const handleValueChange = ($element: JQuery<HTMLElement>) => {
    if (!$element.hasClass(selectors.targetClass) && $element.val()) {
        $element.addClass(selectors.targetClass)
        $element.parent().find("span").addClass(selectors.targetClass)
    }
}

const applyListeners = ($element: JQuery<HTMLElement>) => {
    const handleGotFocus = () => {
        if (!$element.hasClass(selectors.targetClass)) {
            $element.addClass(selectors.targetClass)
            $element.parent().find("span.floating-label").addClass(selectors.targetClass)
        }
    }
    const handleLostFocus = () => {
        if ($element.hasClass(selectors.targetClass) && !$element.val()) {
            $element.removeClass(selectors.targetClass)
            $element.parent().find("span.floating-label").removeClass(selectors.targetClass)
        }
    }

    handleValueChange($element)

    $element.on("focusout", () => {
        handleLostFocus()
    })
    $element.on("focusin", () => {
        handleGotFocus()
    })
    $element.on("keydown", () => {
        handleGotFocus()
    })
    $element.on("input", () => {
        handleValueChange($element)
    })
}

const getInputsFromParentNode = (node: HTMLDivElement) => {
    return $(node).find("input, textarea.auto-grow, textarea.no-auto-grow")
}

const applyFloatingLabels = (node: HTMLDivElement) => {
    const $inputs = getInputsFromParentNode(node)
    //Apply listeners to all inputs
    $inputs.each((_, input) => applyListeners($(input)))
    //Apply auto grow for textareas
    $inputs.each((_, input) => {
        if (!$(input).is("textarea.auto-grow")) {
            return
        }
        input.addEventListener("input", function () {
            this.style.overflow = "hidden"
            this.style.height = "0"
            this.style.height = this.scrollHeight + "px"
        })
    })
}

export const FloatingLabelInput = ({
    placeholder,
    extraLabel,
    bottomHint,
    type,
    containerClass,
    inputTextPrefix,
    textareaNoAutoGrow,
    onFocus,
    onBlur,
    onChange,
    autoCompleteOff,
    disableHintAfter,
    hintInsideInput,
    textInsideInput,
    showHintOnHover,
    allowNegativeNumbers,
    noBorderRadiusLeft,
    smallBorderRadius,
    maxDecimals,
    onKeyDown,
    ...otherProps
}: IFloatingLabelsInputProps) => {
    const parentNodeHandle = useRef<HTMLDivElement | null | undefined>()
    const spanHandle = useRef<HTMLSpanElement | null | undefined>()

    const [initialized, setInitialized] = useState(false)
    const [showHint, setHint] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        if (parentNodeHandle.current) {
            applyFloatingLabels(parentNodeHandle.current)
            // First animate placeholders after the initial setup
            // If input starts with a value, placeholder should float without an animation
            delay(100).then(() => setInitialized(true))
        }
    }, [])

    if (parentNodeHandle.current) {
        const $inputs = getInputsFromParentNode(parentNodeHandle.current)
        $inputs.each((_, input) => {
            handleValueChange($(input))
        })
    }

    return (
        <div ref={(ref) => (parentNodeHandle.current = ref)} className={cn("form-label-group mb-0", containerClass)}>
            {type === "password" ? (
                <InputGroup>
                    <Input
                        {...otherProps}
                        autoComplete={autoCompleteOff ? "new-password" : ""}
                        className="password-input"
                        type={showPassword ? "text" : "password"}
                        onFocus={onFocus ? onFocus : () => setHint(true)}
                        onBlur={onBlur ? onBlur : () => setHint(false)}
                    />
                    <span
                        className={cn(initialized && "initialized", "floating-label")}
                        ref={(ref) => (spanHandle.current = ref)}
                        data-value={placeholder}
                    ></span>
                    <div className="show-password" onClick={() => setShowPassword(!showPassword)}>
                        <span
                            className={cn("show-password-text", "no-select", "icon", showPassword ? "icon-hide" : "icon-show")}
                        ></span>
                    </div>
                </InputGroup>
            ) : (
                <InputGroup>
                    {inputTextPrefix && <span className="input-prefix">{inputTextPrefix}</span>}
                    <Input
                        {...otherProps}
                        autoComplete={autoCompleteOff ? "new-password" : ""}
                        className={cn(
                            type === "textarea" && (textareaNoAutoGrow ? "no-auto-grow" : "auto-grow"),
                            { "with-prefix": inputTextPrefix },
                            { "no-border-radius-left": noBorderRadiusLeft },
                            { "small-border-radius": smallBorderRadius }
                        )}
                        type={type || "text"}
                        onWheel={(type === "number" && numberInputOnWheelPreventChange) || undefined}
                        onKeyDown={(e) => {
                            if (onKeyDown) {
                                onKeyDown(e)
                            }

                            if (type !== "number") {
                                return
                            }

                            numberInputOnKeyDown({
                                event: e,
                                allowNegativeNumbers,
                                isDecimal: otherProps.inputMode == "decimal",
                                maxDecimals,
                            })
                        }}
                        onFocus={onFocus ? onFocus : () => setHint(true)}
                        onBlur={onBlur ? onBlur : () => setHint(false)}
                        onChange={(e) => {
                            if (disableHintAfter) {
                                e.target.value.length > disableHintAfter ? setHint(false) : setHint(true)
                            }
                            if (onChange) {
                                onChange(e)
                            }
                        }}
                    />
                    <span
                        className={cn(initialized && "initialized", "floating-label")}
                        ref={(ref) => (spanHandle.current = ref)}
                        data-value={placeholder}
                    ></span>
                    {hintInsideInput && (
                        <HintIconWithTextOnClick
                            className="hint-inside-input"
                            showHintOnHover={showHintOnHover}
                            hintText={hintInsideInput}
                        />
                    )}
                    {textInsideInput && (
                        <span
                            className={cn(
                                "text-inside-input",
                                textInsideInput.color && `text-inside-input--color-${textInsideInput.color}`,
                                textInsideInput.fontStyle && `text-inside-input--font-style-${textInsideInput.fontStyle}`
                            )}
                        >
                            {textInsideInput.text}
                        </span>
                    )}
                </InputGroup>
            )}
            {extraLabel && <div className="extra-label">{extraLabel}</div>}
            <Hint hintText={bottomHint} showElement={showHint}></Hint>
        </div>
    )
}

const Hint = ({ hintText, showElement }: { hintText?: string; showElement: boolean }) => {
    return (
        <>
            {hintText && (
                <Alert isOpen={showElement} className="alert-f2" color="dark">
                    <span className={cn("icon page-icon icon-exclamation-mark")}></span> {hintText}
                </Alert>
            )}
        </>
    )
}
