import cn from "classnames"
import React from "react"
import { IMerchantPreOnboardingInternalUser, getFullNameFromFirstAndLastName } from "swiipe.portal.shared"
import "./MerchantOfferFooter.scss"

interface IMerchantOfferFooterProps {
    className?: string
    creator: IMerchantPreOnboardingInternalUser | undefined
}

export const MerchantOfferFooter = ({ className, creator }: IMerchantOfferFooterProps) => {
    return (
        <div className={cn("merchant-offer-footer", className)}>
            <div className="merchant-offer-footer__divider"></div>
            <div className="merchant-offer-footer__title mb-2">
                {getFullNameFromFirstAndLastName(creator?.firstName, creator?.lastName)}
            </div>
            <div>{creator?.email}</div>
            <div>{creator?.phone}</div>
            <div className="mt-4">Swiipe Payment ApS</div>
            <div>Ryvangs Allé 91</div>
            <div>2900 Hellerup</div>
            <div>Denmark</div>
        </div>
    )
}
