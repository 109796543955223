import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "swiipe.portal.shared"
import InfoBoxAdder from "../../components/infobox/InfoBoxAdder"
import InfoBoxContainer from "../../components/infobox/InfoBoxContainer"
import InfoBoxEditor, { TSetShowSpinnerFunc } from "../../components/infobox/InfoBoxEditor"
import { InfoBoxState } from "../../components/infobox/InfoBoxState"
import { isWebshopPaymentsAccountDeletable } from "../../services/a2aService"
import { navigationService } from "../../services/navigationService"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import {
    deleteA2aWebshopAccountThunk,
    getMerchantA2aAccountConnectUrlThunk,
    setA2aWebshopPaymentsAccountThunk,
} from "../../store/thunks/a2aThunks"
import { sendServiceConfirmationEmailThunk } from "../../store/thunks/swiipeServicesThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { IA2aUserAccount } from "../../type/a2a/IA2aUserAccount"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import A2aAccount from "./A2aAccount"
import "./A2aAccounts.scss"

interface IA2aAccounts {
    title: string
    adderLabel?: string
    selectedCountryCode?: string
    accounts: IA2aUserAccount[]
    merchantDetails: MixedMerchantDetails
    selectedWebshopId: string
    accountIdInEditMode: string | undefined
    disableAdder?: boolean
    isOnboarding?: boolean
    onOnboarding?: () => void
    isUnsupported?: boolean
    onSwitchEditMode: (accountId: string, isInEditMode: boolean) => void
}

const A2aAccounts = ({
    title,
    adderLabel,
    accounts,
    merchantDetails,
    selectedWebshopId,
    selectedCountryCode,
    accountIdInEditMode,
    disableAdder,
    isOnboarding,
    onOnboarding,
    isUnsupported,
    onSwitchEditMode,
}: IA2aAccounts) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)!

    return (
        <div className="a2a-infobox-container">
            <InfoBoxContainer
                state={isUnsupported ? InfoBoxState.DISABLED : undefined}
                className="px-0"
                alwaysShowTitle
                showOnMobile
                isInEditMode={!!accountIdInEditMode}
                title={title}
            >
                {accounts.map((account) => {
                    const unsupportedState = isUnsupported ? InfoBoxState.DISABLED : undefined

                    const isChosen = account.selectedForWebshopIds.includes(selectedWebshopId)
                    const chosenState =
                        account.isVerifiedForPayments && account.canReceivePayments && account.isActive
                            ? isChosen
                                ? InfoBoxState.DEFAULT
                                : InfoBoxState.NEUTRAL
                            : InfoBoxState.DELETEONLY

                    return (
                        <InfoBoxEditor
                            key={account.userAccountId}
                            shortEditText
                            state={unsupportedState ?? chosenState}
                            title={t("a2aonboarding.editAccount")}
                            isInEditMode={accountIdInEditMode === account.userAccountId}
                            editBtnTextType="editing"
                            renderAdditionalTop={isChosen ? () => <AccountSelectedHeader /> : undefined}
                            renderPreview={() => <A2aAccount account={account} webshopId={selectedWebshopId} />}
                            renderFormFields={(register) => <A2aAccount account={account} webshopId={selectedWebshopId} />}
                            noSave={isChosen}
                            saveButtonTitle={t("a2aonboarding.chooseAsRecipientAccount")}
                            onSave={async (model) => {
                                await dispatch(
                                    setA2aWebshopPaymentsAccountThunk(
                                        merchantDetails.swMerchant.swMerchantId,
                                        selectedWebshopId,
                                        account.userAccountId,
                                        selectedCountryCode ? [selectedCountryCode] : []
                                    )
                                )
                                if (isOnboarding && onOnboarding) {
                                    // Triggered when merchant has previously provided consent
                                    onOnboarding()
                                    await dispatch(
                                        sendServiceConfirmationEmailThunk(
                                            merchantDetails.swMerchant.swMerchantId,
                                            "AccountToAccountConfirmation",
                                            t("serviceNames.a2a")
                                        )
                                    )
                                    navigationService.navigate("/account_to_account_details")
                                }
                            }}
                            onSwitchEditMode={(isInEditMode) => onSwitchEditMode(account.userAccountId, isInEditMode)}
                            allowDelete={isWebshopPaymentsAccountDeletable(currentUserRelation, account, selectedWebshopId)}
                            onDelete={async (setShowSpinner: TSetShowSpinnerFunc) => {
                                setShowSpinner(true)
                                await dispatch(
                                    deleteA2aWebshopAccountThunk(
                                        merchantDetails.swMerchant.swMerchantId,
                                        selectedWebshopId,
                                        account.userAccountId
                                    )
                                )
                                setShowSpinner(false)
                            }}
                        />
                    )
                })}
                {accounts.length === 0 && <span id="a2a-infobox__no_accounts_text">{t("a2aonboarding.noAddedAccounts")}</span>}
                {!disableAdder && (
                    <InfoBoxAdder
                        state={InfoBoxState.DEFAULT}
                        title=""
                        adderLabel={adderLabel}
                        isInEditMode={false}
                        onSwitchEditMode={() => {}}
                        isFirstItem={false}
                        renderFirstItemPreview={() => <></>}
                        onClickAdder={async () => {
                            const redirectUrl = await dispatch(
                                getMerchantA2aAccountConnectUrlThunk(
                                    merchantDetails.swMerchant.swMerchantId,
                                    selectedWebshopId,
                                    selectedCountryCode ? [selectedCountryCode] : []
                                )
                            )
                            document.location.href = redirectUrl
                        }}
                    />
                )}
            </InfoBoxContainer>
        </div>
    )
}

const AccountSelectedHeader = () => {
    const { t } = useTranslation()
    return (
        <div className="a2a-account__selected-header">
            <span>{t("a2aonboarding.recipientAccount")}</span>
        </div>
    )
}

export default A2aAccounts
