import React from "react"
import "./A2aAccount.scss"
import { IA2aUserAccount } from "../../type/a2a/IA2aUserAccount"
import { useTranslation } from "react-i18next"
import cn from "classnames"

interface A2aAccountProps {
    account: IA2aUserAccount
    webshopId: string
    isUnsupported?: boolean
}
const A2aAccount = ({ account, webshopId, isUnsupported }: A2aAccountProps) => {
    const { t } = useTranslation()
    return (
        <div className="a2a-account">
            <div className="a2a-account__info-container">
                <div className="a2a-account__logo-currency-container">
                    <img className="a2a-account__providerLogo" src={account.providerLogoUrl} />
                    <div className="a2a-account__currency">{account.currency}</div>
                </div>
                <div className="a2a-account__bankDetails-container">
                    <div className="a2a-account__name">{account.name}</div>
                    <div className="a2a-account__bban">{account.bBan}</div>
                </div>
            </div>
            {isUnsupported && <AccountNotification warning message={t("a2aonboarding.accountUnsupported")} />}
            {!account.canReceivePayments && <AccountNotification error message={t("a2aonboarding.accountCannotReceive")} />}
            {!account.isVerifiedForPayments && <AccountNotification warning message={t("a2aonboarding.pendingVerify")} />}
            {!account.canSendPayments && <AccountNotification warning message={t("a2aonboarding.accountCannotRefund")} />}
            {!account.isActive && <AccountNotification error message={t("a2aonboarding.accountLostConnection")} />}
        </div>
    )
}

interface IAccountNotification {
    message: string
    warning?: boolean
    error?: boolean
}
const AccountNotification = ({ message, warning, error }: IAccountNotification) => {
    return (
        <div
            className={cn(
                "a2a-account__notification",
                warning && "a2a-account__notification--warning",
                error && "a2a-account__notification--error"
            )}
        >
            {message}
        </div>
    )
}

export default A2aAccount
