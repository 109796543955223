import { getFormattedNumber } from "../utils/formatUtil"
import { IDropdownOption } from "../components/form/input/DropDownList"
import { TCurrency, TCurrencyFormat } from "../types/general/TCurrency"
import { TNumberFormat } from "../types/general/TNumberFormat"

export interface ICurrency {
    displayTitle: string
    symbol: string
    isSymbolInFront?: boolean
    id: TCurrency
}

const DKK: ICurrency = {
    displayTitle: "DKK",
    id: "DKK",
    symbol: "kr.",
}

const EUR: ICurrency = {
    displayTitle: "EUR",
    id: "EUR",
    symbol: "€",
}

const SEK: ICurrency = {
    displayTitle: "SEK",
    id: "SEK",
    symbol: "kr.",
}

const NOK: ICurrency = {
    displayTitle: "NOK",
    id: "NOK",
    symbol: "kr.",
}

const GBP: ICurrency = {
    displayTitle: "GBP",
    id: "GBP",
    symbol: "£",
}

const USD: ICurrency = {
    displayTitle: "USD",
    id: "USD",
    symbol: "$",
}

const PLN: ICurrency = {
    displayTitle: "PLN",
    id: "PLN",
    symbol: "zł",
}

export const currencies: ICurrency[] = [DKK, EUR, GBP, NOK, SEK, USD, PLN]

export function getPriceWithCurrency(
    amount: number,
    currency?: TCurrency,
    options?: {
        currencyFormat?: TCurrencyFormat
        numberFormat?: TNumberFormat
        wrapPriceInElement?: string
        useThousandSeparator?: boolean
    }
) {
    let formattedAmount = getFormattedNumber(amount, options)
    // TODO: Implement different default decimals per currency

    if (options?.wrapPriceInElement) {
        formattedAmount = `<${options.wrapPriceInElement}>${formattedAmount}</${options.wrapPriceInElement}>`
    }
    if (!currency || options?.currencyFormat === "none") {
        return formattedAmount
    }
    const resolvedFormat = options?.currencyFormat ?? "standard"
    if (resolvedFormat === "standard") {
        return currency + " " + formattedAmount
    }
    if (resolvedFormat === "standardReversed") {
        return formattedAmount + " " + currency
    }

    const currencyConfig = currencies.find((c) => c.id === currency)
    if (!currencyConfig) {
        if (resolvedFormat === "symbolAndCashEnding") {
            return `${formattedAmount},-`
        }
        return formattedAmount
    }
    if (resolvedFormat === "symbolAndCashEnding") {
        return `${currencyConfig.symbol} ${formattedAmount},-`
    }
    if (currencyConfig.isSymbolInFront || resolvedFormat === "symbolInFront") {
        return `${currencyConfig.symbol} ${formattedAmount}`
    }
    return `${formattedAmount} ${currencyConfig.symbol}`
}

// Only used for legacy pages
const currentCurrency = "DKK"

// Only used for legacy pages
function getCurrentCurrency() {
    return currencies.find((c) => c.id === currentCurrency) || DKK
}

// Only used for legacy pages
export function getCurrentCurrencyTitle() {
    return getCurrentCurrency().displayTitle
}

export function getCurrencyDropdownOptions() {
    const options: IDropdownOption<TCurrency>[] = []

    currencies.map((c) => options.push({ text: c.displayTitle, value: c.id }))

    return options
}
