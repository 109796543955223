import { getLanguage } from "swiipe.portal.shared"

interface ISupportLinkLanguages {
    [languageCode: string]: ISupportLinkConfig
}

interface ISupportLinkConfig {
    returnSell: {
        guide: string
    }
}

const supportLinks: ISupportLinkLanguages = {
    en: {
        returnSell: {
            guide: "https://swiipe-support.zendesk.com/hc/da/sections/16962854427025-Return-sell",
        },
    },
    da: {
        returnSell: {
            guide: "https://swiipe-support.zendesk.com/hc/da/sections/16962854427025-Return-sell",
        },
    },
}

export const getSupportLink = (selector: (config: ISupportLinkConfig) => string | undefined): string => {
    const language = getLanguage()
    if (supportLinks[language]) {
        const currentLanguageValue = selector(supportLinks[language])
        if (currentLanguageValue) {
            return currentLanguageValue
        }
    }

    const enValue = selector(supportLinks["en"])
    if (enValue) {
        return enValue
    }

    const daValue = selector(supportLinks["da"])
    if (daValue) {
        return daValue
    }

    return ""
}
