import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { IUpdateSalesCheckoutPageLinkFormModel } from "../../type/sales/IUpdateSalesCheckoutPageLinkFormModel"
import { IUpdateSalesPspFormModel } from "../../type/sales/IUpdateSalesPspFormModel"
import { IUpdateSalesSendersNameFormModel } from "../../type/sales/IUpdateSalesSendersNameFormModel"
import { IUpdateSalesTermsFormModel } from "../../type/sales/IUpdateSalesTermsFormModel"
import { StoreState } from "./../StoreState"
import { salesSelectors } from "./../reducers/salesReducer"

export const ensureSalesSettingsThunk =
    (force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const settings = salesSelectors.settings(getState())
        if (!force && settings) {
            return
        }

        // const serverConfiguration = configurationSelectors.serverConfiguration(getState())
        // const currentUserRelation = userRelationSelectors.currentUserRelation(getState())!
        // const endpoint = configurationSelectors.endpointConfig(getState(), "Callcenter")
        // const newSettings = await dispatch(
        //     authenticatedRequestThunk<ISalesSettings>(
        //         THttpMethod.GET,
        //         endpoint + serverConfiguration.salesGatewayEndpointGetSettings + "/" + currentUserRelation.id
        //     )
        // )
        // dispatch(salesReducerActions.setSettings(newSettings))
    }

export const updateSalessendersNameThunk =
    (form: IUpdateSalesSendersNameFormModel): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        // const serverConfiguration = configurationSelectors.serverConfiguration(getState())
        // const currentUserRelation = userRelationSelectors.currentUserRelation(getState())!
        // const endpoint = configurationSelectors.endpointConfig(getState(), "Callcenter")
        // await dispatch(
        //     authenticatedRequestThunk<void>(THttpMethod.POST, endpoint + serverConfiguration.salesGatewayEndpointUpdateSettings, {
        //         data: { ...prepareFormData(form, []), callCenterId: currentUserRelation.id },
        //     })
        // )
        // await dispatch(ensureSalesSettingsThunk(true))
    }

export const updateSalesCheckoutPageLinkThunk =
    (form: IUpdateSalesCheckoutPageLinkFormModel): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        // const serverConfiguration = configurationSelectors.serverConfiguration(getState())
        // const currentUserRelation = userRelationSelectors.currentUserRelation(getState())!
        // const endpoint = configurationSelectors.endpointConfig(getState(), "Callcenter")
        // await dispatch(
        //     authenticatedRequestThunk<void>(THttpMethod.POST, endpoint + serverConfiguration.salesGatewayEndpointUpdateSettings, {
        //         data: { ...prepareFormData(form, []), callCenterId: currentUserRelation.id },
        //     })
        // )
        // await dispatch(ensureSalesSettingsThunk(true))
    }

export const updateSalesTermsLinkThunk =
    (form: IUpdateSalesTermsFormModel): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        // const serverConfiguration = configurationSelectors.serverConfiguration(getState())
        // const currentUserRelation = userRelationSelectors.currentUserRelation(getState())!
        // const endpoint = configurationSelectors.endpointConfig(getState(), "Callcenter")
        // await dispatch(
        //     authenticatedRequestThunk<void>(THttpMethod.POST, endpoint + serverConfiguration.salesGatewayEndpointUpdateSettings, {
        //         data: { ...prepareFormData(form, []), callCenterId: currentUserRelation.id },
        //     })
        // )
        // await dispatch(ensureSalesSettingsThunk(true))
    }

export const updateSalesPspThunk =
    (form: IUpdateSalesPspFormModel): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        // const serverConfiguration = configurationSelectors.serverConfiguration(getState())
        // const currentUserRelation = userRelationSelectors.currentUserRelation(getState())!
        // const endpoint = configurationSelectors.endpointConfig(getState(), "Callcenter")
        // await dispatch(
        //     authenticatedRequestThunk<void>(THttpMethod.POST, endpoint + serverConfiguration.salesGatewayEndpointUpdateSettings, {
        //         data: { ...prepareFormData(form, []), callCenterId: currentUserRelation.id },
        //     })
        // )
        // await dispatch(ensureSalesSettingsThunk(true))
    }
