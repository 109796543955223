import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { SharedStoreState, endpointsShared, requestThunk } from "swiipe.portal.shared"

export interface IExampleEmailResponse {
    subject: string
    body: string
}

export const getExampleEmail =
    (
        swMerchantId: string,
        emailType: string,
        language: string,
        data: { [key: string]: any }
    ): ThunkAction<Promise<IExampleEmailResponse>, SharedStoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const resp = await dispatch(
            requestThunk<IExampleEmailResponse>(endpointsShared.Send.getExampleEmail, {
                data: {
                    swMerchantId: swMerchantId,
                    emailType,
                    language,
                    data,
                },
            })
        )

        return resp
    }

export interface IExampleSmsResponse {
    body: string
}

export const getExampleSms =
    (
        swMerchantId: string,
        smsType: string,
        language: string,
        data: { [key: string]: any }
    ): ThunkAction<Promise<IExampleSmsResponse>, SharedStoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const resp = await dispatch(
            requestThunk<IExampleSmsResponse>(endpointsShared.Send.getExampleSms, {
                data: {
                    swMerchantId: swMerchantId,
                    smsType,
                    language,
                    data,
                },
            })
        )

        return resp
    }
