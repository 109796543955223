import i18next from "i18next"

// The culture to use for displaying dates and numbers
// Use culture based on the users Country if logged in
// Fallback to browser culture (use undefined)
let culture: string | undefined = undefined
export const getText = (textId: string, options?: { [replacementKey: string]: string }, withLanguage?: string) => {
    if (withLanguage) {
        const t = i18next.getFixedT(withLanguage)
        return t(textId, options)
    }

    return i18next.t(textId, options)
}

export const getLanguage = () => {
    return i18next.language
}

export const changeLanguage = (language?: string) => {
    if (!language) {
        return
    }

    i18next.changeLanguage(language)
}

export const setCulture = (newCulture?: string) => {
    culture = newCulture
}

export const getCulture: () => string | undefined = () => {
    return culture
}

export const getCultureWithFallback: () => string | undefined = () => {
    return culture || i18next.language
}
