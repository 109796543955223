import { getImageDimensions, getKeys } from "swiipe.portal.shared"
import { getText } from "../locale/lang"
import { TEnv } from "../types/general/TEnv"

export const validationPatterns = {
    //Email regular expression
    email: /^[\wÆæøØåÅ+]+([.-]?[\wÆæøØåÅ+]+)*@[\wÆæøØåÅ]+([.-]?\w+)*(\.[\wÆæøØåÅ]{2,})+$/,
    //At least one upper case
    //At least one lower case
    //At least one number
    //Length  minimum 8
    password: /^(?=.*?[A-ZÅÆØ])(?=.*?[a-zåæø])(?=.*?[0-9]).{8,}$/,
    fullName: /^[\w\søØåÅæÆ-]{1,}\s[\w\søØåÅæÆ-]{1,}$/,
    firstName: /^[\w\søØåÅæÆ-]{2,}$/,
    lastName: /^[\w\søØåÅæÆ-]{2,}$/,
    domainProd: /^((?:https:\/\/)?[A-ZÆØÅa-zæøå0-9-]+(?:\.[A-ZÆØÅa-zæøå0-9-]+)+)$/,
    domainSandbox: /^((?:https?:\/\/)?[A-ZÆØÅa-zæøå0-9-]+(?:\.[A-ZÆØÅa-zæøå0-9-]+)+)(:[0-9]+)?$/,
    domainTest: /^((?:https?:\/\/)?[A-ZÆØÅa-zæøå0-9-]+(?:\.[A-ZÆØÅa-zæøå0-9-]+)*)(:[0-9]+)?$/,
    cvr: /^[0-9]*$/,
    sxCvr: /^[0-9]{8}$/,
    //ddMMyy-XXXX
    cprDk: /^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[012])\d\d-[0-9]{4}/,
    phoneNumber: /\+?[\d]+/,
    companyName: /^.{3,}$/,
    ean: /^[0-9]{13}$/,
    regNum: /^[0-9]{4}$/,
    accNum: /^[0-9]{10}|[0-9]{9}|[0-9]{7}$/, //Allows account numbers of 7, 9 and 10 digits. Needs logic to apply zeroes when needed

    currency: /^[\w.]{2,}$/,
    userLanguage: /^[\w]{2,}$/,

    mccNumber: /^\d{4}/,

    //dd/MM/YYYY
    date: /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.]((?:19|20)\d\d)/,

    inputText: /^.{1,}$/,
    inputNum: /^\d+$/,
    inputFloatNum: /^-?\d+.*(\d+)*$/,

    addressStreet: /^.{3,}$/,
    addressCity: /^[\w\søØåÅæÆ][^0-9]{2,}$/,
    countryISO2Code: /^[A-Za-z]{2}$/,

    salessendersName: /^([a-zA-Z0-9]*[a-zA-Z ][a-zA-Z0-9 ]*)$/,
    salesTermsUrl: /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?/,
    salesCheckoutPageLink: /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?/,

    swiftNumber: /^([A-Z]{4})([A-Z]{2})([2-9A-Z][0-9A-NP-Z])(X{3}|[0-9A-WY-Z][0-9A-Z][0-9A-Z])?$/,
    ibanNumber:
        /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|IS\d{26}|BE\d{14}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28}|SE\d{22}|LT\d{18})$/,

    googleAnalyticsPropertyId: /^\d{9}$/,
    googleAnalyticsStreamId: /^\d{10}$/,

    vatNumberEU:
        /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/,
    companyNumberSweden: /^(\d{6}-\d{4}|\d{10})(01)?$/,

    paymentLinkOrderId: /^(?!_)((?!__)[A-Z0-9_]){1,10}$/, //Max length: 10. Should only contain: uppercase en letters, underscores(in the middle, one in a row), digits.

    smsSenderName: /^(?=.*[a-zA-Z])(?=.*[a-zA-Z0-9])([a-zA-Z0-9 ]{1,11})$/,
}

export const validationPatternsWithLocale = {
    cpr: (country: string) => {
        switch (country) {
            case "DK":
                //ddMMyy-XXXX
                return validationPatterns.cprDk
            case "SE":
                //yyMMdd-XXXX
                return /^\d\d(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])-[0-9]{4}/
            case "PL":
                //YYMMDDZZZXQ (PESEL)
                return /^[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[0-9]{5}$/
            default:
                return /^[0-9-]{4,30}/
        }
    },
    cvr: (country: string) => {
        switch (country) {
            case "DK":
                return /^[0-9]*$/
            case "SE":
                return new RegExp(`(${validationPatterns.companyNumberSweden.source})|(${validationPatterns.vatNumberEU.source})`)
            case "CH":
                return /^CHE-\d{3}\.\d{3}\.\d{3}/
            case "GB":
                return /^([A-Z]{2}|\d{2})[0-9]{6}$/
            default:
                return validationPatterns.vatNumberEU
        }
    },
    addressPostalCode: (country: string) => {
        switch (country) {
            case "GB":
                return /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/
            default:
                return /^\d{3,}$/
        }
    },
}

export function getDomainValidationPattern(env: TEnv) {
    if (env === "Production") {
        return validationPatterns.domainProd
    }
    if (env === "Sandbox") {
        return validationPatterns.domainSandbox
    }
    return validationPatterns.domainTest
}

export const countryCodes = {
    da: "+45",
}

// Sadly, react-hook-form fire validation pattern for non-required field, even when the field has no value.
// To avoid this bug, we instead use the custom validation funtion

export function valFuncPattern(pattern: RegExp, patternErrorMeessage: string): { validate: (value: string) => true | string } {
    return {
        validate: (value: string) => {
            if (!value) {
                return true
            }
            const matches = value.match(pattern)
            if (matches && matches.length > 0 && matches[0] === value) {
                return true
            }
            return patternErrorMeessage
        },
    }
}

export function valFuncRequired(requiredErrorMeessage: string): { required: string } {
    return {
        required: requiredErrorMeessage,
    }
}

export function valFuncFileQuantitiesAndProperties(
    requiredErrorMeessage: string,
    minFilesAmount?: number,
    maxFilesAmount?: number,
    maxFileSizeInBytes?: number,
    wrongTypeFilesErrorMessage?: string,
    requiredTypes?: string[],
    requiredHeight?: number,
    requiredWidth?: number
): { validate: (value: FileList) => Promise<string | true> } {
    return {
        validate: async (value: FileList) => {
            const filesKeys = getKeys(value)

            if (minFilesAmount && filesKeys.length < minFilesAmount) {
                return requiredErrorMeessage
            }

            if (maxFilesAmount && filesKeys.length > maxFilesAmount) {
                return requiredErrorMeessage
            }

            if (maxFileSizeInBytes) {
                const hasLargeFiles = filesKeys.find((key) => (value[key as any].size > maxFileSizeInBytes ? true : false))

                if (hasLargeFiles) {
                    return requiredErrorMeessage
                }
            }

            if (requiredTypes) {
                const hasWrongTypeFiles = filesKeys.find((key) => !requiredTypes.includes(value[key as any].type))

                if (hasWrongTypeFiles) {
                    return wrongTypeFilesErrorMessage || ""
                }
            }

            //TODO: handle separately in the future?
            if (requiredHeight && requiredWidth) {
                let validDimensions = true
                for (let i = 0; i < filesKeys.length; i++) {
                    if (value[filesKeys[i] as any]) {
                        const objectUrl = (window.URL || window.webkitURL).createObjectURL(value[filesKeys[i] as any])

                        const img = await getImageDimensions(objectUrl)

                        if (img.width !== requiredWidth || img.height !== requiredHeight) {
                            validDimensions = false
                            break
                        }
                    }
                }

                if (!validDimensions) {
                    return getText("fileupload.errormessages.wrongSizeInPixels", {
                        width: requiredWidth.toString(),
                        height: requiredHeight.toString(),
                    })
                }
            }

            return true
        },
    }
}

export function filterShouldValidate<T extends { required?: string }>(
    validationRules: T,
    fieldName: string,
    validatedFields: string[] | undefined
): T {
    if (!validatedFields || validatedFields.includes(fieldName)) {
        return validationRules
    }
    return {
        ...validationRules,
        required: undefined,
    }
}

export function valFuncRequiredAndPattern(
    pattern: RegExp,
    patternErrorMeessage: string,
    requiredErrorMessage?: string
): { validate: (value: string) => true | string; required: string } {
    return {
        validate: (value: string) => {
            value = pattern === validationPatterns.email ? value.trim() : value
            if (!value) {
                return true
            }
            const matches = value.match(pattern)
            if (matches && matches.length > 0 && matches[0] === value) {
                return true
            }
            return patternErrorMeessage
        },
        required: requiredErrorMessage || patternErrorMeessage,
    }
}

export function valFuncRequiredAndWithinRange(
    minAmount: number,
    maxAmount: number,
    rangeErrorMeessage: string,
    requiredErrorMessage?: string
): { validate: (value: string) => true | string; required: string } {
    return {
        validate: (value: string) => {
            if (!value) {
                return true
            }
            const floatValue = parseFloat(value)
            if (floatValue >= minAmount && floatValue <= maxAmount) {
                return true
            }
            return rangeErrorMeessage
        },
        required: requiredErrorMessage || rangeErrorMeessage,
    }
}

export const isValidEmail = (email: string): boolean => {
    email = email.trim()
    const matches = email.match(validationPatterns.email)
    if (matches && matches.length > 0 && matches[0] === email) {
        return true
    }

    return false
}
