import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "./OnboardingPriceLineLarge.scss"
import cn from "classnames"

export interface IOnboardingPriceLineLarge {
    className?: string
    serviceName: string
    priceValue: string
    customPriceSymbol?: string
    customSymbolBeforePrice?: boolean
    customTextAfterPrice?: string
}

/**
 * Used to display a price line in the shared price dialog where the amount value is large
 */
export const OnboardingPriceLineLarge = ({
    className,
    serviceName,
    priceValue,
    customPriceSymbol,
    customSymbolBeforePrice,
    customTextAfterPrice,
}: IOnboardingPriceLineLarge) => {
    const { t } = useTranslation()

    return (
        <div className={cn("onboarding-price-line-large", className)}>
            <span className="price-value--large">
                {customSymbolBeforePrice ? customPriceSymbol : undefined}
                {priceValue}
            </span>
            <span>
                {!customSymbolBeforePrice ? customPriceSymbol : undefined}
                {!customTextAfterPrice
                    ? t("plugins.sharedOnboardingDialogPrice", {
                          serviceName: serviceName,
                      })
                    : customTextAfterPrice}
            </span>
        </div>
    )
}

export default OnboardingPriceLineLarge
