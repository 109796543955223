import React from "react"
import cn from "classnames"
import { InfoBoxState } from "./InfoBoxState"
import "./InfoBoxContainer.scss"

interface IInfoBoxContainerProps {
    iconClass?: string
    iconToTop?: boolean
    className?: string
    title: string
    alwaysShowTitle?: boolean
    isInEditMode: boolean
    narrow?: boolean
    showOnMobile?: boolean
    noPadding?: boolean
    children?: React.ReactNode
    transparentBg?: boolean
    noIcon?: boolean
    state?: InfoBoxState
}

const InfoBoxContainer = ({
    iconClass,
    iconToTop,
    className,
    showOnMobile,
    noPadding,
    narrow,
    isInEditMode,
    title,
    alwaysShowTitle,
    children,
    transparentBg,
    noIcon,
    state,
}: IInfoBoxContainerProps) => {
    const childrenWidthAlways = narrow ? "col-10" : "col-9"
    const childrenWidthFullMobile = narrow ? "col-sm-10" : "col-sm-9"
    return (
        <div className={cn("infobox row", { "bg-none": transparentBg }, className ? className : "")}>
            <div
                className={cn("icon-container", {
                    "adaptive-icon": !iconToTop,
                    "px-0": noPadding,
                    "col-3": !narrow,
                    "col-2": narrow,
                    "d-none d-sm-block": isInEditMode && !showOnMobile,
                    "text-only": noIcon,
                    state,
                })}
            >
                <span className={iconClass ? cn("icon", iconClass) : ""}></span>
                <span className={cn("icon-sub-text", alwaysShowTitle && "show-always")}>{title}</span>
            </div>
            <div
                className={cn(
                    "info-container",
                    { isineditmode: isInEditMode },
                    {
                        [childrenWidthFullMobile]: isInEditMode && !showOnMobile,
                        [childrenWidthAlways]: !isInEditMode || showOnMobile,
                    },
                    isInEditMode ? "col-sm-9" : "col-9"
                )}
            >
                {children}
            </div>
        </div>
    )
}

export default InfoBoxContainer
