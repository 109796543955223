import cn from "classnames"
import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    FormGroupWithCheckbox,
    PageContainer,
    PageWrapper,
    ShowErrorMessages,
    TextButton,
    getLanguage,
    getText,
    useSelector,
    valFuncRequired,
} from "swiipe.portal.shared"
import SubmitButton from "../../../components/buttons/SubmitButton"
import DisclaimerToggle from "../../../components/disclaimerToggle/DisclaimerToggle"
import TextAreaWithCopyBtn from "../../../components/inputs/TextAreaWithCopyBtn"
import CancelService from "../../../components/merchant/CancelService"
import GoBackContainer from "../../../components/navigations/GoBackContainer"
import TabsComponent from "../../../components/sections/TabsComponent"
import { PlusSellDisclaimerListWithHints } from "../../../components/servicesonboarding/PlusSellOnboardingDisclaimer"
import { SharedCancelContainer } from "../../../components/servicesonboarding/SharedCancelContainer"
import PlusSellLogo from "../../../images/swFeatureServices/plussell-logo.png"
import { useRequiredUserRelations } from "../../../services/userRelationService"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { plusSellOnboardingThunk } from "../../../store/thunks/plusSellThunks"
import { sendServiceCancellationEmailThunk } from "../../../store/thunks/swiipeServicesThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../../type/mixedmerchantdetails"
import "./PlusSellOnboardingPage.scss"

interface IPlusSellOnboardingPageProps {
    webshopIds: string[]
    isEditMode?: boolean
    onGoBack: () => void
    onOnboarded: () => void
}

interface IPlusSellOnboardingPageInnerProps extends IPlusSellOnboardingPageProps {
    merchantDetails: MixedMerchantDetails
    selectedWebshopId?: string
}

const PlusSellOnboardingPage = (props: IPlusSellOnboardingPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const selectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)

    if (!merchantDetails || !merchantDetails.webshops.length) {
        return null
    }

    return <PlusSellOnboardingPageInner merchantDetails={merchantDetails} selectedWebshopId={selectedWebshopId} {...props} />
}

interface IForm {
    snippetAdded: boolean
    termsAccepted: boolean
}

const PlusSellOnboardingPageInner = ({
    merchantDetails,
    selectedWebshopId,
    webshopIds,
    isEditMode: isEdit,
    onGoBack,
    onOnboarded,
}: IPlusSellOnboardingPageInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const serviceName = t("serviceNames.plusSell")

    const [showCancelService, setShowCancelService] = useState(false)

    const { handleSubmit, register, formState } = useForm<IForm>()

    const onSubmit: SubmitHandler<IForm> = async () => {
        try {
            await dispatch(plusSellOnboardingThunk(merchantDetails.swMerchant.swMerchantId, webshopIds, true))
            onOnboarded()
        } catch {
            //Catch to stop showing spinner
        }
    }

    return (
        <>
            {showCancelService && (
                <CancelService
                    serviceName={serviceName}
                    cancelText={t("cancelService.featureCanceledCommon.cancelText", { serviceName })}
                    customCancelledText={t("cancelService.featureCanceledCommon.cancelledText", {
                        serviceName,
                    })}
                    onCancel={async () => {
                        await dispatch(
                            plusSellOnboardingThunk(merchantDetails.swMerchant.swMerchantId, [selectedWebshopId!], false)
                        )

                        await dispatch(
                            sendServiceCancellationEmailThunk(
                                merchantDetails.swMerchant.swMerchantId,
                                "GeneralServiceCancellationInstantEmail",
                                serviceName
                            )
                        )
                    }}
                    onGoBack={() => setShowCancelService(false)}
                />
            )}
            <PageWrapper className={cn({ "d-none": showCancelService })}>
                {isEdit && <GoBackContainer goBackText={t("commononboardingtexts.backToAllService")} onGoBack={onGoBack} />}
                <PageContainer noHeader={isEdit} disableDefaultPaddingBottom>
                    <div className="plussell-onboarding-container">
                        <div className="plussell-form-head">
                            <img className="plussell-logo" src={PlusSellLogo} />
                        </div>

                        <div className="hint-top my-5">
                            <span className="head">{t("plusSellOnboarding.hint")}</span>
                        </div>

                        <DisclaimerToggle
                            toggleShowText={t("plusSellOnboarding.seeMore")}
                            toggleHideText={t("plusSellOnboarding.seeLess")}
                            className="hint-disclaimer mb-5"
                        >
                            <PlusSellDisclaimerListWithHints className="list-container" />
                        </DisclaimerToggle>

                        <div className="plussell-info plussell-form mt-4 mb-3">
                            {isEdit ? t("plusSellOnboarding.toUse") : t("plusSellOnboarding.toBeActivated")}
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)} className="plussell-form">
                            <div className="plussell-onboarding-form">
                                <TabsComponent
                                    tabs={[
                                        {
                                            name: "Dansk",
                                            id: "da",
                                            component: (
                                                <TextAreaWithCopyBtn
                                                    name="description"
                                                    rows={9}
                                                    textToCopy={getText("plusSellOnboarding.termsToCopy", {}, "da")}
                                                />
                                            ),
                                        },
                                        {
                                            name: "English",
                                            id: "en",
                                            component: (
                                                <TextAreaWithCopyBtn
                                                    name="description"
                                                    rows={9}
                                                    textToCopy={getText("plusSellOnboarding.termsToCopy", {}, "en")}
                                                />
                                            ),
                                        },
                                        {
                                            name: "Svenska",
                                            id: "sv",
                                            component: (
                                                <TextAreaWithCopyBtn
                                                    name="description"
                                                    rows={9}
                                                    textToCopy={getText("plusSellOnboarding.termsToCopy", {}, "sv")}
                                                />
                                            ),
                                        },
                                    ]}
                                    //For now as we don't have "Swedish" language option
                                    //fallback to "sv" option based on merchant country
                                    withActiveTabId={merchantDetails.swMerchant.countryCode === "SE" ? "sv" : getLanguage()}
                                />

                                {!isEdit && (
                                    <>
                                        <FormGroupWithCheckbox
                                            className="mt-5"
                                            name="snippetAdded"
                                            innerRef={register(valFuncRequired(t("plusSellOnboarding.errors.snippetCheckbox")))}
                                        >
                                            <span className="checkbox-text">{t("plusSellOnboarding.checkbox1")}</span>
                                        </FormGroupWithCheckbox>

                                        <ShowErrorMessages formState={formState} />

                                        <SubmitButton dark noBorder containerClass="submit-btn" formState={formState}>
                                            {t("commononboardingtexts.submitTheService")}
                                        </SubmitButton>
                                        <TextButton
                                            text={t("commononboardingtexts.back")}
                                            className="mt-4 mb-4"
                                            onClick={async () => onGoBack()}
                                        />
                                    </>
                                )}
                            </div>
                        </Form>
                    </div>
                </PageContainer>
                {isEdit && (
                    <SharedCancelContainer
                        cancelText={t("plusSellOnboarding.cancel")}
                        onCancel={() => setShowCancelService(true)}
                    />
                )}
            </PageWrapper>
        </>
    )
}

export default PlusSellOnboardingPage
