import cn from "classnames"
import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import { PageContainer, PageWrapper, ShowErrorMessages, TextButton, useSelector } from "swiipe.portal.shared"
import SubmitButton from "../../../components/buttons/SubmitButton"
import CancelService from "../../../components/merchant/CancelService"
import GoBackContainer from "../../../components/navigations/GoBackContainer"
import UnorderedListSection from "../../../components/sections/UnorderedListSection"
import { SharedCancelContainer } from "../../../components/servicesonboarding/SharedCancelContainer"
import ReturnSellLogo from "../../../images/swFeatureServices/returnsell-logo.png"
import { getSupportLink } from "../../../locale/supportLinks"
import { useRequiredUserRelations } from "../../../services/userRelationService"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { returnSellOnboardingThunk } from "../../../store/thunks/returnSellThunk"
import { sendServiceCancellationEmailThunk } from "../../../store/thunks/swiipeServicesThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../../type/mixedmerchantdetails"
import "./ReturnSellOnboardingPage.scss"

interface IReturnSellOnboardingPageProps {
    webshopIds: string[]
    isEditMode?: boolean
    onGoBack: () => void
    onOnboarded: () => void
}

interface IReturnSellOnboardingPageInnerProps extends IReturnSellOnboardingPageProps {
    merchantDetails: MixedMerchantDetails
    selectedWebshopId?: string
}

const ReturnSellOnboardingPage = (props: IReturnSellOnboardingPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const selectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)

    if (!merchantDetails || !merchantDetails.webshops.length) {
        return null
    }

    return <ReturnSellOnboardingPageInner merchantDetails={merchantDetails} selectedWebshopId={selectedWebshopId} {...props} />
}

const ReturnSellOnboardingPageInner = ({
    merchantDetails,
    selectedWebshopId,
    webshopIds,
    isEditMode: isEdit,
    onGoBack,
    onOnboarded,
}: IReturnSellOnboardingPageInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const serviceName = t("serviceNames.returnSell")

    const [showCancelService, setShowCancelService] = useState(false)

    const { handleSubmit, formState } = useForm<any>()

    const onSubmit: SubmitHandler<any> = async () => {
        try {
            await dispatch(returnSellOnboardingThunk(merchantDetails.swMerchant.swMerchantId, webshopIds, true))

            onOnboarded()
        } catch {
            //Catch to stop showing spinner
        }
    }

    return (
        <>
            {showCancelService && (
                <CancelService
                    serviceName={serviceName}
                    cancelText={t("cancelService.featureCanceledCommon.cancelText", { serviceName })}
                    customCancelledText={t("cancelService.featureCanceledCommon.cancelledText", {
                        serviceName,
                    })}
                    onCancel={async () => {
                        await dispatch(
                            returnSellOnboardingThunk(merchantDetails.swMerchant.swMerchantId, [selectedWebshopId!], false)
                        )

                        await dispatch(
                            sendServiceCancellationEmailThunk(
                                merchantDetails.swMerchant.swMerchantId,
                                "GeneralServiceCancellationInstantEmail",
                                serviceName
                            )
                        )
                    }}
                    onGoBack={() => setShowCancelService(false)}
                />
            )}
            <PageWrapper className={cn({ "d-none": showCancelService })}>
                {isEdit && <GoBackContainer goBackText={t("commononboardingtexts.backToAllService")} onGoBack={onGoBack} />}
                <PageContainer noHeader={isEdit} disableDefaultPaddingBottom>
                    <div className="returnsell-onboarding-container">
                        <div className="returnsell-form-head">
                            <img className="returnsell-logo" src={ReturnSellLogo} />
                        </div>

                        <div className="desc my-5">
                            <div className="text-center mb-3 font-weight-bold">{t("returnSellOnboarding.hint")}</div>
                            <UnorderedListSection
                                className="desc-list mx-5"
                                listItems={[t("returnSellOnboarding.list.1"), t("returnSellOnboarding.list.2")]}
                            />

                            <p className="read-guide text-center">
                                <a target="_blank" rel="noreferrer" href={getSupportLink((c) => c.returnSell.guide)}>
                                    {t("returnSellOnboarding.seeGuide")}
                                </a>
                            </p>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)} className="returnsell-form">
                            <div className="returnsell-onboarding-form">
                                {!isEdit && (
                                    <>
                                        <ShowErrorMessages formState={formState} />

                                        <SubmitButton
                                            className="mt-4"
                                            dark
                                            noBorder
                                            containerClass="submit-btn"
                                            formState={formState}
                                        >
                                            {t("commononboardingtexts.submitTheService")}
                                        </SubmitButton>
                                        <TextButton
                                            text={t("commononboardingtexts.back")}
                                            className={cn("mt-4", "mb-4")}
                                            onClick={async () => onGoBack()}
                                        />
                                    </>
                                )}
                            </div>
                        </Form>
                    </div>
                </PageContainer>
                {isEdit && (
                    <SharedCancelContainer
                        cancelText={t("returnSellOnboarding.cancel")}
                        onCancel={() => setShowCancelService(true)}
                    />
                )}
            </PageWrapper>
        </>
    )
}

export default ReturnSellOnboardingPage
