import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IMerchantAcquiringKyc } from "../types/merchant/IMerchantAcquiring"
import { ISwMerchantDetails } from "../types/merchant/ISwMerchantDetails"
import { SharedStoreState } from "./SharedStoreState"

export interface ISwMerchantStoreAddition {
    swMerchant: ISwMerchantSliceState
}

interface ISwMerchantSliceState {
    details: { [swMerchantId: string]: ISwMerchantDetails | undefined }
    acquiringKycs: {
        [swMerchantId: string]: IMerchantAcquiringKyc[] | undefined
    }
}

const initialState: ISwMerchantSliceState = {
    details: {},
    acquiringKycs: {},
}

export const swMerchantSlice = createSlice({
    name: "swMerchant",
    initialState,
    reducers: {
        setDetails: (state, action: PayloadAction<{ swMerchantId: string; details: ISwMerchantDetails }>) => {
            state.details[action.payload.swMerchantId] = action.payload.details
        },
        setAcquiringKycs: (state, action: PayloadAction<{ swMerchantId: string; acquiringKycs: IMerchantAcquiringKyc[] }>) => {
            state.acquiringKycs[action.payload.swMerchantId] = action.payload.acquiringKycs
        },
    },
})

export const swMerchantActions = swMerchantSlice.actions
export const swMerchantSelectors = {
    details: (state: SharedStoreState, swMerchantId: string) => state.swMerchant.details[swMerchantId],
    acquiringKycs: (state: SharedStoreState, swMerchantId: string) => state.swMerchant.acquiringKycs[swMerchantId],
}
