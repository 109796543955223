import Oidc from "oidc-client"
import { useRef, useState } from "react"
import { waitForever } from "swiipe.portal.shared"
import { ServerConfiguration } from "../type/serverconfiguration"
import { navigationService } from "./navigationService"

interface IAuthService {
    serverConfiguration?: ServerConfiguration
    getIsLoggedIn: () => boolean
    setIsLoggedIn: (value: boolean) => void
    getIsWaitingForRedirectToAuth: () => boolean
    setIsWaitingForRedirectToAuth: (value: boolean) => void
    getIsWaitingForLinkToLoginPage: () => boolean
    setIsWaitingForLinkToLoginPage: (value: boolean) => void
    userManager?: Oidc.UserManager
    callback?: () => void
    isAuthChecked: () => boolean
    setAuthChecked: (checked: boolean) => void
    setup: (serverConfiguration: ServerConfiguration, authEndpoint: string) => void
    login: (currentPath: string, otac?: string) => Promise<void>
    logout: () => void
}

let isWaitingForRedirectToAuth = false
let isWaitingForLinkToLoginPage = false
let loggedIn = false

export const authService: IAuthService = {
    getIsWaitingForRedirectToAuth: () => {
        return isWaitingForRedirectToAuth
    },
    setIsWaitingForRedirectToAuth: (value: boolean) => {
        isWaitingForRedirectToAuth = value
    },
    getIsWaitingForLinkToLoginPage: () => {
        return isWaitingForLinkToLoginPage
    },
    setIsWaitingForLinkToLoginPage: (value: boolean) => {
        isWaitingForLinkToLoginPage = value
    },
    getIsLoggedIn: () => {
        return loggedIn
    },
    setIsLoggedIn: (value: boolean) => {
        loggedIn = value
    },
    setup(serverConfiguration: ServerConfiguration, authEndpoint: string) {
        this.serverConfiguration = serverConfiguration
        this.userManager = new Oidc.UserManager({
            prompt: "none",
            response_type: "id_token token",
            scope: "openid profile cconfig swiipeportal",
            authority: authEndpoint,
            client_id: serverConfiguration.clientName,
            redirect_uri: serverConfiguration.clientUrl,
        })
    },
    isAuthChecked() {
        return window.sessionStorage.getItem("authChecked") === "true"
    },
    setAuthChecked(checked: boolean) {
        if (checked) {
            window.sessionStorage.setItem("authChecked", "true")
        } else {
            window.sessionStorage.removeItem("authChecked")
        }
    },
    async login(currentPath: string, otac?: string) {
        if (!this.userManager) {
            throw "Cannot login without user manager"
        }
        if (!this.serverConfiguration) {
            throw "There must be a server configuration in order to log in"
        }

        let redirectUrl = currentPath
        if (redirectUrl && !redirectUrl.startsWith("http")) {
            redirectUrl = `${this.serverConfiguration.clientUrl}${redirectUrl.startsWith("/") ? redirectUrl : "/" + redirectUrl}`
        }
        if (redirectUrl && !redirectUrl.startsWith("https%3A%2F%2F") && !redirectUrl.startsWith("http%3A%2F%2F")) {
            redirectUrl = encodeURIComponent(redirectUrl)
        }

        await this.userManager.signinRedirect({
            acr_values: `otac${otac ? otac : "undefined"}`,
            ...(redirectUrl ? { redirect_uri: redirectUrl } : {}),
        })
        await waitForever()
    },
    logout() {
        this.setIsLoggedIn(false)
        window.localStorage.clear()
        if (this.callback) {
            this.callback()
        }
    },
}

export function useShowLoggedInMenus() {
    const [showMenus, setShowMenus] = useState(authService.getIsLoggedIn() && !navigationService.forceHideMenus)
    const showMenusRef = useRef(showMenus)
    showMenusRef.current = showMenus

    const [isSetup, setIsSetup] = useState(false)
    if (!isSetup) {
        setIsSetup(true)
        navigationService.addForceHideMenuListener((forceHideMenus: boolean) => {
            // New route
            const show = authService.getIsLoggedIn() && !forceHideMenus
            if (show !== showMenusRef.current) {
                setShowMenus(show)
            }
        })
    }
    return showMenus
}
