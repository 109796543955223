import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody, Row } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, ModalFooter } from "swiipe.portal.shared"
import "./ModalConfirmDangerousAction.scss"
export interface IModalConfirmDangerousAction extends IModalBase {
    type: "confirmDangerousAction"
    title: string
    promptText: string
    customSubmitText?: string
    customCancelText?: string
}

const ModalConfirmDangerousAction = ({
    callback,
    title,
    promptText,
    customSubmitText,
    customCancelText,
    ...otherProps
}: IModalConfirmDangerousAction & IModalControlProps) => {
    const { t } = useTranslation()
    return (
        <BaseModal {...otherProps} callback={callback} centered>
            <ModalBody className="modal-confirm-dangerous-action">
                <Row className="text-center">
                    <div className="col">
                        <p className={cn("modal__dialog-title", "modal__dialog-title--is_danger")}>{title}</p>
                        <p className="modal__dialog-text">{promptText}</p>
                    </div>
                </Row>
            </ModalBody>
            <ModalFooter
                wideButtons
                leftButton={{
                    onClick: async () => callback("declined"),
                    text: customCancelText ?? t("common.no"),
                    isSmall: true,
                }}
                rightButton={{
                    onClick: async () => callback("accepted"),
                    text: customSubmitText ?? t("common.yes"),
                    isDelete: true,
                    isInvertedColor: true,
                    isSmall: true,
                }}
            />
        </BaseModal>
    )
}

export default ModalConfirmDangerousAction
