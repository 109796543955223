import { TFeatureType } from "swiipe.portal.shared"
import { IFeatureDetails } from "../type/feature/IFeatureDetails"

export const getFeatureEnabled = (
    webshopFeaturesDetails: { [featureType in TFeatureType]: IFeatureDetails } | undefined,
    featureType: TFeatureType
) => {
    return webshopFeaturesDetails && webshopFeaturesDetails[featureType] && webshopFeaturesDetails[featureType].enabled
}

export const getFeatureOnboardingStatus = (
    webshopFeaturesDetails: { [featureType in TFeatureType]: IFeatureDetails } | undefined,
    featureType: TFeatureType
) => {
    if (!webshopFeaturesDetails || !webshopFeaturesDetails[featureType]) {
        return
    }

    return webshopFeaturesDetails[featureType].onboardingStatus
}

export function getFeatureEnabledDate(
    webshopFeaturesDetails: { [featureType in TFeatureType]: IFeatureDetails } | undefined,
    featureType: TFeatureType
) {
    if (!webshopFeaturesDetails || !webshopFeaturesDetails[featureType]) {
        return
    }

    const enabledDate = webshopFeaturesDetails[featureType].enabledDate
    if (!enabledDate) {
        return
    }

    return new Date(enabledDate)
}

export function isFeatureEnabledAndReady(
    webshopFeaturesDetails: { [featureType in TFeatureType]: IFeatureDetails } | undefined,
    featureType: TFeatureType
) {
    return (
        getFeatureEnabled(webshopFeaturesDetails, featureType) &&
        getFeatureOnboardingStatus(webshopFeaturesDetails, featureType) === "Ready"
    )
}
