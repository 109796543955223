import cn from "classnames"
import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    CleanHtml,
    PageContainer,
    PageWrapper,
    ShowErrorMessages,
    TextButton,
    getKeys,
    getTextWithInvoicingPrices,
    useSelector,
} from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import FileInput, { megabyteInBytes } from "../../components/form/input/FileInput"
import FormHeaderLogoContainer from "../../components/form/title/FormHeaderLogoContainer"
import CancelService from "../../components/merchant/CancelService"
import GoBackContainer from "../../components/navigations/GoBackContainer"
import { SharedCancelContainer } from "../../components/servicesonboarding/SharedCancelContainer"
import MobilepayOnlineLogo from "../../images/swPaymentTypes/mobilepay-icon.png"
import MobilepayTextLogo from "../../images/swPaymentTypes/mobilepay-text-logo.png"
import SwiipeAcquiringLogoImage from "../../images/swiipe-logo.png"
import { getMerchantContact, removeSchemeFromHostName } from "../../services/merchantService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { billingSelectors } from "../../store/reducers/billingReducer"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { IMpoOnboardingData, getMpoOnboardingDataThunk, mpoOnboardingThunk } from "../../store/thunks/mobilePayOnlineThunks"
import { setPiqOnboardingStatusThunk } from "../../store/thunks/swiipePaymentsThunks"
import { sendServiceCancellationEmailThunk } from "../../store/thunks/swiipeServicesThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import "./MobilepayOnlineOnboardingPage.scss"

interface IMobilepayOnlineOnboardingPageProps {
    webshopIds?: string[]
    isEdit?: boolean
    hasClearhausAlready?: boolean
    onGoBack: () => void
    onOnboarded: () => void
}
interface IMobilepayOnlineOnboardingInnerProps extends IMobilepayOnlineOnboardingPageProps {
    merchantDetails: MixedMerchantDetails
}

const MobilepayOnlineOnboardingPage = (props: IMobilepayOnlineOnboardingPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)

    if (!merchantDetails || !merchantDetails.webshops.length) {
        return null
    }

    return <MobilepayOnlineOnboardingInner merchantDetails={merchantDetails} {...props}></MobilepayOnlineOnboardingInner>
}

const MobilepayOnlineOnboardingInner = ({
    merchantDetails,
    webshopIds,
    isEdit,
    hasClearhausAlready,
    onGoBack,
    onOnboarded,
}: IMobilepayOnlineOnboardingInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const webshops = merchantDetails.webshops || []
    const selectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)

    const [webshopLogoUrl, setWebshppLogoUrl] = useState("")
    const [showCancelService, setShowCancelService] = useState(false)
    const prices = useSelector(billingSelectors.resolvedPrices)

    useEffect(() => {
        if (isEdit && selectedWebshopId) {
            ;(async () => {
                const mpoOnboardingData = await dispatch(
                    getMpoOnboardingDataThunk(merchantDetails.swMerchant.swMerchantId, selectedWebshopId)
                )
                setWebshppLogoUrl(mpoOnboardingData.webshopLogoUrl)
            })()
        }
    }, [isEdit, selectedWebshopId])

    const { handleSubmit, register, formState, getValues, setError, clearErrors, trigger, setValue } = useForm<any>()

    const onSubmit: SubmitHandler<any> = async (data) => {
        try {
            const webshopids: string[] =
                isEdit && selectedWebshopId
                    ? [selectedWebshopId]
                    : webshopIds && webshopIds.length
                    ? webshopIds
                    : webshops.map((w) => w.webshopId)
            const swMerchantContact = getMerchantContact(merchantDetails)

            const logos = webshopids.map((wsid) => {
                const logos = data[`logo-${wsid}`]

                return getKeys(logos).length > 0 ? logos[0] : null
            })

            const formData: IMpoOnboardingData = {
                swMerchantId: merchantDetails.swMerchant.swMerchantId,
                swMerchantEmail: swMerchantContact?.email || "",
                merchantName: merchantDetails.swMerchant.name,
                merchantContactName: swMerchantContact?.name || "",
                merchantContactPhone: swMerchantContact?.phone || "",
                webshopids: webshopids,
                logos: logos,
                existedAtClearhaus: hasClearhausAlready || false,
                onboardedWithClearhaus: !hasClearhausAlready && !isEdit,
                editMode: !!isEdit,
            }

            await dispatch(mpoOnboardingThunk(formData))

            onOnboarded()
        } catch {
            //Catch to stop showing spinner
        }
    }

    return (
        <>
            {showCancelService && (
                <CancelService
                    serviceName={t("mpoonboarding.mpo")}
                    cancelText={t("cancelService.mpo.cancelText")}
                    customCancelledText={t("cancelService.mpo.cancelledText")}
                    hintText={t("cancelService.mpo.hintText")}
                    onGoBack={() => setShowCancelService(false)}
                    onCancel={async () => {
                        await dispatch(
                            setPiqOnboardingStatusThunk(merchantDetails.swMerchant.swMerchantId, [selectedWebshopId!], {
                                paymentType: "MobilePayOnline",
                                providerName: "MobilePayOnline",
                                providerType: "Other",
                                enabled: false,
                            })
                        )

                        await dispatch(
                            setPiqOnboardingStatusThunk(merchantDetails.swMerchant.swMerchantId, [selectedWebshopId!], {
                                paymentType: "MobilePayOnline",
                                providerName: "Clearhaus",
                                providerType: "Aquirer",
                                enabled: false,
                            })
                        )

                        await dispatch(
                            sendServiceCancellationEmailThunk(
                                merchantDetails.swMerchant.swMerchantId,
                                "GeneralServiceCancellationEopEmail",
                                t("serviceNames.mpo")
                            )
                        )
                    }}
                />
            )}

            <PageWrapper className={cn({ "d-none": showCancelService })}>
                {isEdit && (
                    <GoBackContainer
                        goBackText={t("commononboardingtexts.backToAllService")}
                        onGoBack={onGoBack}
                        noBottomMargin
                    />
                )}

                <PageContainer>
                    <div className="mobilepay-outer-container">
                        <FormHeaderLogoContainer
                            mainLogo={{
                                src: SwiipeAcquiringLogoImage,
                                alt: "swiipe-acquiring-logo",
                                width: "140px",
                            }}
                        />
                        <div className="mobilepay-header-container">
                            <img src={MobilepayOnlineLogo}></img>
                            <img src={MobilepayTextLogo} width="120"></img>
                        </div>
                        {!isEdit && (
                            <>
                                <div className="mobilepay-text5 mb-3">
                                    <CleanHtml element="span" html={t("mpoonboarding.text5")} />
                                </div>
                                <div className="mobilepay-text2 mb-4">
                                    <div className="list-container">
                                        <CleanHtml
                                            element="div"
                                            html={getTextWithInvoicingPrices("mpoonboarding.text3.1", prices, {
                                                initPrice: {
                                                    number: 0,
                                                    numberFormat: "forceTwoDecimals",
                                                    wrapPriceInElement: "b",
                                                },
                                            })}
                                        />
                                        <CleanHtml
                                            element="div"
                                            html={getTextWithInvoicingPrices("mpoonboarding.text3.2", prices, {
                                                mpoMonthly: {
                                                    type: "MobilePayOnlineMonthlyShared",
                                                    numberFormat: "forceTwoDecimals",
                                                    wrapPriceInElement: "b",
                                                },
                                            })}
                                        />
                                        <CleanHtml
                                            element="div"
                                            html={getTextWithInvoicingPrices("mpoonboarding.text3.3", prices, {
                                                mpoTx: {
                                                    type: "MobilePayOnlineTransactionStep1Shared",
                                                    numberFormat: "forceTwoDecimals",
                                                    wrapPriceInElement: "b",
                                                },
                                            })}
                                        />
                                    </div>

                                    <p className="text-center text-footer">{t("mpoonboarding.text4")}</p>
                                </div>

                                <div className="upload-logo-text mb-3">{t("mpoonboarding.uploadyourlogo")}</div>
                            </>
                        )}

                        <CleanHtml
                            element="div"
                            className={cn("mb-2", isEdit ? "mobilepay-text5" : "upload-logo-text-2")}
                            html={t("mpoonboarding.uploadlogo")}
                        />

                        <Form onSubmit={handleSubmit(onSubmit)}>
                            {webshops.map((w) => {
                                const showWebshop =
                                    webshopIds && webshopIds.length
                                        ? webshopIds.includes(w.webshopId)
                                        : isEdit
                                        ? selectedWebshopId === w.webshopId
                                        : true

                                if (showWebshop) {
                                    return (
                                        <div className="webshop-inputs" key={w.webshopId}>
                                            <div className="mobilepayonline-onboarding-form">
                                                <p
                                                    className={cn(
                                                        "webshop-name text-center mt-4 mb-3",
                                                        webshops.length >= 2 ? "font-weight-bold" : ""
                                                    )}
                                                >
                                                    {removeSchemeFromHostName(w.hostName)}
                                                </p>

                                                {webshopLogoUrl && (
                                                    <div className="current-logo-box">
                                                        {t("mpoonboarding.currentLogo")}
                                                        <br />
                                                        <a href={webshopLogoUrl} target="_blank" rel="noreferrer">
                                                            {webshopLogoUrl}
                                                        </a>
                                                    </div>
                                                )}

                                                <FileInput
                                                    name={`logo-${w.webshopId}`}
                                                    triggerValidation={trigger}
                                                    maxFileSizeBytes={megabyteInBytes * 5}
                                                    maxFilesAmount={1}
                                                    clearError={clearErrors}
                                                    setError={setError}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                    register={register}
                                                    accept="image/jpeg, image/png"
                                                    requiredTypes={["image/png"]}
                                                    requiredWidth={250}
                                                    requiredHeight={250}
                                                    wrongTypeFilesErrorMessage={t("mpoonboarding.errormessages.logo")}
                                                ></FileInput>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })}

                            <div className="mobilepayonline-onboarding-form">
                                <ShowErrorMessages formState={formState} />

                                <SubmitButton
                                    className="mt-4"
                                    dark
                                    noBorder
                                    noBorderRadius
                                    containerClass={cn("submit-btn", { "mt-5 mb-4": isEdit })}
                                    formState={formState}
                                >
                                    {isEdit ? t("common.save") : t("commononboardingtexts.submit")}
                                </SubmitButton>
                            </div>
                        </Form>
                        {!isEdit && (
                            <TextButton
                                text={t("commononboardingtexts.back")}
                                className="mt-3 mb-2"
                                onClick={async () => onGoBack()}
                            />
                        )}
                    </div>
                </PageContainer>

                {isEdit && (
                    <SharedCancelContainer
                        cancelText={t("mpoonboarding.cancelMpo")}
                        onCancel={() => setShowCancelService(true)}
                    />
                )}
            </PageWrapper>
        </>
    )
}

export default MobilepayOnlineOnboardingPage
