export function getDistinct<T>(arr: T[], selector?: (item: T) => any) {
    const values: T[] = []
    const results: T[] = []
    arr.forEach((elem) => {
        const val = selector ? selector(elem) : elem
        if (values.indexOf(val) < 0) {
            values.push(val)
            results.push(elem)
        }
    })
    return results
}

export function unique<T>(arr: T[]): T[] {
    const j: { [key: string]: T } = {}
    arr.forEach((v) => {
        j[JSON.stringify(v) + "::" + typeof v] = v
    })
    return Object.values(j)
}

export function hasValue<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && typeof value !== "undefined"
}

export function hasNonEmptyValue<TValue>(value: TValue | null | undefined | false | ""): value is TValue {
    return value !== null && typeof value !== "undefined" && !!value
}

export function sortAscending<T>(arr: T[], selector: (item: T) => any) {
    return [...arr].sort((i1, i2) => {
        const r1 = selector(i1)
        const r2 = selector(i2)
        if (r1 === r2) {
            return 0
        }
        return r1 < r2 ? -1 : 1
    })
}

export function sortDescending<T>(arr: T[], selector: (item: T) => any) {
    return [...arr].sort((i1, i2) => {
        const r1 = selector(i1)
        const r2 = selector(i2)
        if (r1 === r2) {
            return 0
        }
        return r1 < r2 ? 1 : -1
    })
}

export function toDict<T, U extends string, V>(array: T[], getKey: (item: T) => U, getValue: (item: T) => V) {
    const res: { [key in U]?: V } = {}
    array.forEach((i) => {
        res[getKey(i)] = getValue(i)
    })
    return res
}

export function sumArray<T>(arr: T[], selector: (item: T) => number) {
    return arr.reduce((sum, item) => {
        return sum + selector(item)
    }, 0)
}

export function groupBy<T>(arr: T[], selector: (item: T) => string) {
    const groups: { [key: string]: T[] } = {}

    arr.map((item) => {
        const groupKey = selector(item)
        groups[groupKey] = groups[groupKey] ?? []
        groups[groupKey].push(item)
    })

    return groups
}

export function areArraysEqual<T>(array1: T[], array2: T[]): boolean {
    if (array1.length !== array2.length) {
        return false
    }

    for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
            return false
        }
    }

    return true
}

export function removeElementAtIndex<T>(array: T[], index: number): T[] {
    if (index < 0 || index >= array.length) {
        return array
    }

    return [...array.slice(0, index), ...array.slice(index + 1)]
}

export function ensureArrayLength<T>(arr: T[], length: number, createItem: () => T) {
    if (arr.length >= length) {
        return arr.slice(0, length)
    }
    return [...arr, ...Array.from(Array(length - arr.length).map(() => createItem()))]
}
