import React from "react"
import { Control } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
    FieldsetFormGroup,
    FloatingLabelInput,
    TitleWithAbsoluteHint,
    getCompanyNumberPlaceholder,
    useSelector,
    valFuncPattern,
    valFuncRequired,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { merchantSelectors } from "../../../../store/reducers/merchantReducer"
import { IClearhausApplicationData } from "../../../../type/clearhaus/IClearhausApplicationData"
import { IClearhausUpdateInfoForm } from "../../../../type/clearhaus/IClearhausUpdateInfoForm"
import "./ClearhausUpdateCompanyFields.scss"

interface IClearhausUpdateCompanyFieldsProps {
    control: Control<IClearhausUpdateInfoForm>
    applicationData?: IClearhausApplicationData
}

const ClearhausUpdateCompanyFields = ({ control, applicationData }: IClearhausUpdateCompanyFieldsProps) => {
    const { t } = useTranslation()
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const savedFields = applicationData?.applicationFields?.company
    const isCompanyDK = (savedFields?.country || merchantDetails?.swMerchant.countryCode) === "DK"

    return (
        <div className="clearhaus-update-company-fields">
            <div className="clearhaus-update-company-fields__field-pair">
                <FieldsetFormGroup>
                    <FloatingLabelInput
                        placeholder={t("clearhausUpdateInfo.inputs.company.current.name")}
                        name="company.current.name"
                        defaultValue={savedFields?.name || merchantDetails?.swMerchant.name}
                        innerRef={control.register(valFuncRequired(t("onboarding.errmsg.companyname")))}
                    />
                </FieldsetFormGroup>
                <FieldsetFormGroup>
                    <FloatingLabelInput
                        placeholder={t("clearhausUpdateInfo.inputs.company.new.name")}
                        name="company.new.name"
                        innerRef={control.register}
                    />
                </FieldsetFormGroup>
            </div>
            <hr />
            <div className="clearhaus-update-company-fields__field-pair">
                <FieldsetFormGroup>
                    <FloatingLabelInput
                        placeholder={getCompanyNumberPlaceholder(isCompanyDK ? "DK" : "other")}
                        name="company.current.cvr"
                        defaultValue={savedFields?.cvr}
                        innerRef={control.register(valFuncRequiredAndPattern(validationPatterns.cvr, t("onboarding.errmsg.cvr")))}
                    />
                </FieldsetFormGroup>
                <FieldsetFormGroup>
                    <FloatingLabelInput
                        placeholder={t(`common.${isCompanyDK ? "companyDK" : "companyNonDK"}.newCompanyNumber`)}
                        name="company.new.cvr"
                        innerRef={control.register(valFuncPattern(validationPatterns.cvr, t("onboarding.errmsg.cvr")))}
                    />
                </FieldsetFormGroup>
            </div>
            <div className="clearhaus-update-company-fields__explanation-hint">
                <TitleWithAbsoluteHint
                    className="clearhaus-update-company-fields__hint"
                    title={t("clearhausUpdateInfo.hints.noChanges.title")}
                    iconBold
                />
            </div>
        </div>
    )
}

export default ClearhausUpdateCompanyFields
