import Parser from "html-react-parser"
import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import { AccordionWithChildren, ShowErrorMessages, SpinnerContainer, TextButton, valFuncRequired } from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import FixedLabelTextAreaInput from "../../components/form/input/FixedLabelTextAreaInput"
import TextAreaWithCopyBtn from "../../components/inputs/TextAreaWithCopyBtn"
import { ISwishOnboardingData, createSwishCsrThunk, swishOnboardingThunk } from "../../store/thunks/swiipePaymentsThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import "./SwishCertificateStep.scss"

interface SwishCertificateStepProps {
    merchantDetails: MixedMerchantDetails
    webshopId: string
    swishId: string
    onNextStep: () => void
    onBackStep: () => Promise<void>
}

interface IFormModel {
    swishCsrPem: string
    swishCertificate: string
}

const SwishCertificateStep = ({ merchantDetails, webshopId, swishId, onNextStep, onBackStep }: SwishCertificateStepProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()
    const [csrPem, setCsrPem] = useState("")
    const [isLoading, setIsLoading] = useState(true)

    const swMerchantId = merchantDetails.swMerchant.swMerchantId

    const initCsrData = async () => {
        try {
            const createdCsrResponse = await dispatch(createSwishCsrThunk(webshopId, swMerchantId, swishId))
            setCsrPem(createdCsrResponse.swishCsrPem)
            setIsLoading(false)
        } catch {
            setIsLoading(false)
            onBackStep()
        }
    }

    useEffect(() => {
        if (webshopId && swMerchantId && swishId) {
            initCsrData()
        }
    }, [])

    const { handleSubmit, register, formState } = useForm<IFormModel>()
    const onSubmit: SubmitHandler<IFormModel> = async (data) => {
        try {
            const formData: ISwishOnboardingData = {
                swMerchantId: merchantDetails.swMerchant.swMerchantId,
                swMerchantEmail: merchantDetails.swMerchantContacts[0]?.email,
                swMerchantName: merchantDetails.swMerchant.name,
                merchantContactName: merchantDetails.swMerchantContacts[0]?.name,
                merchantContactPhone: merchantDetails.swMerchantContacts[0]?.phone,
                webshopIds: [webshopId],
                swishId: swishId,
                swishCertificate: data.swishCertificate.trim(),
            }

            await dispatch(swishOnboardingThunk(formData, false))
            onNextStep()
        } catch {
            //Catch to stop showing spinner
        }
    }

    return (
        <SpinnerContainer className="swish-cert-step" showSpinner={isLoading}>
            <div className="swish-grey-textbox">{Parser(t("swishonboarding.certificateinstructionssummary"))}</div>

            <section className="detailed-guide-section swish-cert-form">
                <DetailedGuideSteps />
            </section>

            <section className="swish-csr-section swish-cert-form">
                <TextAreaWithCopyBtn
                    label="Certificate Signing Request (CSR)"
                    name="csrPem"
                    rows={2}
                    textToCopy={csrPem}
                    btnPlacement={"right"}
                    btnProps={{ isSmall: true }}
                />
            </section>

            <section className="swish-certificate-section">
                <Form onSubmit={handleSubmit(onSubmit)} className="swish-cert-form">
                    <FixedLabelTextAreaInput
                        className="swish-certificate-text"
                        label={t("swishonboarding.swishcertificate")}
                        name="swishCertificate"
                        placeholder={t("swishonboarding.swishcertificateplaceholder")}
                        innerRef={register(valFuncRequired(t("swishonboarding.swishcerterror")))}
                        rows={3}
                    />
                    <ShowErrorMessages formState={formState} />
                    <SubmitButton className="mt-3" dark noBorder noBorderRadius containerClass="submit-btn" formState={formState}>
                        {t("commononboardingtexts.submit")}
                    </SubmitButton>
                </Form>
            </section>

            <TextButton text={t("commononboardingtexts.goback")} className="mb-5 mt-4" onClick={onBackStep} />
        </SpinnerContainer>
    )
}

const DetailedGuideSteps = () => {
    const { t } = useTranslation()

    const foldedText = t("swishonboarding.seemore")
    const unfoldedText = t("swishonboarding.seeless")
    return (
        <AccordionWithChildren header={foldedText.toUpperCase()} headerExpandedText={unfoldedText.toUpperCase()} isSimple>
            <div className="detailed-guide-steps">
                <b>{t("swishonboarding.certificateinstructionsheader")}</b>
                <ol>
                    <li>{Parser(t("swishonboarding.certificateinstructionsstep1"))}</li>
                    <li>{t("swishonboarding.certificateinstructionsstep2")}</li>
                    <li>{t("swishonboarding.certificateinstructionsstep3")}</li>
                </ol>
                <a
                    href="https://assets.ctfassets.net/zrqoyh8r449h/7K7uUWECMMQqiT9ROKUVwl/ecd3ad934d9ab5d080796e3db733335d/Swish-Certificate-Management-Guide.pdf"
                    target="_blank"
                    rel="noreferrer"
                    id="detailed-guide-link"
                >
                    {t("swishonboarding.detailedguide")}
                </a>
            </div>
        </AccordionWithChildren>
    )
}

export default SwishCertificateStep
