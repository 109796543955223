import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import {
    EMerchantOfferStep,
    IMerchantOnboardingServicesSubForm,
    IMerchantPreOnboarding,
    IMerchantPreOnboardingEvent,
    IMerchantPreOnboardingInternalUser,
    IMerchantPreOnboardingOwner,
    getDistinct,
    getText,
    hasValue,
    requestThunk,
    userSelectors,
} from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { isCurrentPageInMerchantOfferAcquiringOnboardingFlow } from "../../services/merchantOfferService"
import { navigationService } from "../../services/navigationService"
import { addHours } from "../../util/dateUtil"
import { executeInAggregationQueue } from "../../util/queueUtils"
import { StoreState } from "../StoreState"
import { clearhausSelectors } from "../reducers/clearhausReducer"
import {
    IMerchantPreOnboardingDetails,
    merchantPreOnboardingActions,
    merchantPreOnboardingSelectors,
} from "../reducers/merchantPreOnboardingSlice"
import { merchantSelectors } from "../reducers/merchantReducer"
import { userRelationSelectors } from "../reducers/userRelationReducer"
import { addModalThunk } from "./modalThunks"

export const getMerchantPreOnboardingDetailsThunk =
    (force?: "force"): ThunkAction<Promise<IMerchantPreOnboardingDetails | undefined>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType !== "Merchant") {
            return undefined
        }

        const preOnboardingDetails = merchantPreOnboardingSelectors.details(getState())
        if (preOnboardingDetails && force !== "force") {
            if (preOnboardingDetails.exists) {
                dispatch(ensureMerchantPreOnboardingPasswordStatusThunk())
            }
            return preOnboardingDetails
        }

        const response = await dispatch(
            requestThunk<
                | {
                      preOnboarding: IMerchantPreOnboarding
                      creator: IMerchantPreOnboardingInternalUser
                      owner: IMerchantPreOnboardingOwner
                      events: IMerchantPreOnboardingEvent[]
                  }
                | undefined
            >(endpoints.Identity.getMerchantPreOnboardingDetails, {
                params: {
                    swMerchantId: currentUserRelation.id,
                },
            })
        )
        if (!response) {
            dispatch(
                merchantPreOnboardingActions.setMerchantPreOnboardingDetails({
                    swMerchantId: currentUserRelation.id,
                    exists: false,
                })
            )
            return merchantPreOnboardingSelectors.details(getState())
        }

        dispatch(
            merchantPreOnboardingActions.setMerchantPreOnboardingDetails({
                swMerchantId: currentUserRelation.id,
                exists: true,
                preOnboarding: response.preOnboarding,
                creator: response.creator,
                owner: response.owner,
                events: response.events,
                services: JSON.parse(response.preOnboarding.servicesJson ?? "{}") as IMerchantOnboardingServicesSubForm,
            })
        )

        dispatch(ensureMerchantPreOnboardingPasswordStatusThunk())
        return merchantPreOnboardingSelectors.details(getState())
    }

const ensureMerchantPreOnboardingPasswordStatusThunk =
    (): ThunkAction<void, StoreState, null, AnyAction> => async (dispatch, getState) => {
        let preOnboardingDetails = merchantPreOnboardingSelectors.details(getState())
        if (!preOnboardingDetails) {
            await dispatch(getMerchantPreOnboardingDetailsThunk())
        }
        preOnboardingDetails = merchantPreOnboardingSelectors.details(getState())
        if (!preOnboardingDetails?.exists) {
            return
        }

        const userData = userSelectors.userData(getState())
        if (
            !preOnboardingDetails.preOnboarding.isOwnerConfirmed &&
            userData?.user.id === preOnboardingDetails.preOnboarding.ownerId
        ) {
            await dispatch(merchantOfferAddEventThunk("Owner account confirmed", userData?.user.email, "Password"))
        }
    }

export const merchantOfferNavigateToStepThunk =
    (newStep: EMerchantOfferStep): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType !== "Merchant") {
            return
        }

        if (!merchantPreOnboardingSelectors.initialNavigationDone(getState())) {
            dispatch(merchantPreOnboardingActions.setInitialNavigationDone(currentUserRelation.id))
        }

        if (newStep === "info" || newStep === "terms") {
            navigationService.navigate("merchantOffer?step=" + (newStep === "info" ? "info" : "terms"))
        }
        if (newStep === "kyc") {
            navigationService.navigate("/swiipepayments_onboard?flowType=merchantOffer")
        }
    }

export const merchantOfferTryAddKycEventThunk =
    (applicationId?: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const preOnboardingDetails = await dispatch(getMerchantPreOnboardingDetailsThunk())
        if (!preOnboardingDetails?.exists) {
            return
        }

        const merchantDetails = merchantSelectors.merchantDetails(getState())
        const allClearhausApplicationStates = clearhausSelectors.applicationStates(getState())
        const relevantClearhausApplications =
            merchantDetails?.webshops.map((w) => allClearhausApplicationStates?.[w.webshopId]).filter(hasValue) ?? []

        const application = relevantClearhausApplications.find(
            (a) =>
                (!applicationId || applicationId === a.onboardingId) &&
                ((a.instantOnboarding && a.firstStageSubmitted) || a.secondStageSubmitted)
        )

        if (!application) {
            return
        }

        if (!preOnboardingDetails.preOnboarding.isAcquiringOnboarded) {
            dispatch(merchantOfferInitiateCompletionFlowThunk())
        }

        if (application.isWithoutData) {
            dispatch(
                merchantOfferAddEventThunk(
                    "Acquiring onboarded",
                    `The merchant agreed to move their Clearhaus setup to Swiipe`,
                    "Kyc"
                )
            )
            return
        }
        if (!application.secondStageSubmitted && application.instantOnboarding && application.firstStageSubmitted) {
            dispatch(
                merchantOfferAddEventThunk(
                    "Acquiring onboarded",
                    `Merchant qualified for instant onboarding (no documents were submitted)`,
                    "Kyc"
                )
            )
            return
        }
        if (application.secondStageSubmitted) {
            dispatch(
                merchantOfferAddEventThunk(
                    "Acquiring onboarded",
                    `The merchant completed the full Acquiring onboarding form (submitted documents for KYC)`,
                    "Kyc"
                )
            )
            return
        }
    }

export const merchantOfferAddEventThunk =
    (
        eventType: string,
        note: string,
        statusType?: "Created" | "Sent" | "Password" | "Viewed" | "Signed" | "Kyc"
    ): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const preOnboardingDetails = merchantPreOnboardingSelectors.details(getState())
        if (!preOnboardingDetails?.exists) {
            return
        }

        const evt = {
            creatorUserId: preOnboardingDetails.creator?.userId,
            preOnboardingId: preOnboardingDetails.preOnboarding?.preOnboardingId,
            swMerchantId: preOnboardingDetails.swMerchantId,
            created: new Date().toISOString(),
            statusType: statusType,
            eventType: eventType,
            note: note,
        }

        executeInAggregationQueue(
            "lastAddedEventMerchantOffer",
            [evt],
            async (events) => {
                const uniqueEvents = getDistinct(events, (e) => e.eventType + e.note)
                for (let i = 0; i < uniqueEvents.length; i++) {
                    const uniqueEvent = uniqueEvents[i]
                    const cacheKey = `${uniqueEvent.swMerchantId}_${uniqueEvent.statusType ?? ""}_${uniqueEvent.eventType}_${
                        uniqueEvent.note
                    }`
                    if (uniqueEvent.statusType) {
                        const lastAdded = localStorage.getItem(`lastAddedEventMerchantOffer_${cacheKey}`)
                        if (lastAdded && addHours(new Date(lastAdded), 3).getTime() > new Date().getTime()) {
                            continue
                        }
                        if (uniqueEvent.statusType) {
                            localStorage.setItem(`lastAddedEventMerchantOffer_${cacheKey}`, new Date().toISOString())
                        }
                    }

                    try {
                        await dispatch(
                            requestThunk(endpoints.Identity.addMerchantPreOnboardingEvent, {
                                data: {
                                    event: uniqueEvent,
                                },
                            })
                        )
                        await dispatch(getMerchantPreOnboardingDetailsThunk("force"))
                    } catch (err) {
                        if (uniqueEvent.statusType) {
                            localStorage.removeItem(`lastAddedEventMerchantOffer_${cacheKey}`)
                        }
                    }
                }
            },
            500
        )
    }

const getKeyForMerchantOfferCompleted = (currentUserRelationId: string) =>
    "merchant_offer_show_completion_when_out_of_kyc:" + currentUserRelationId
let isModalShown = false
export const merchantOfferCheckForCompletionThunk =
    (): ThunkAction<void, StoreState, null, AnyAction> => async (dispatch, getState) => {
        const preOnb = merchantPreOnboardingSelectors.details(getState())
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!preOnb?.exists || isModalShown) {
            return
        }

        const listenForReloadInitiatedDate = localStorage.getItem(getKeyForMerchantOfferCompleted(currentUserRelation?.id ?? ""))
        if (!listenForReloadInitiatedDate) {
            return
        }
        const date = new Date(listenForReloadInitiatedDate)
        if (date.addDays(1).getTime() < new Date().getTime()) {
            return
        }

        if (isCurrentPageInMerchantOfferAcquiringOnboardingFlow()) {
            dispatch(merchantOfferInitiateCompletionFlowThunk())
            return
        }

        isModalShown = true
        dispatch(
            addModalThunk({
                type: "standardInfo",
                title: getText("merchantOffer.modalCompleted.title"),
                text: getText("merchantOffer.modalCompleted.text"),
            })
        )
        localStorage.removeItem(getKeyForMerchantOfferCompleted(currentUserRelation?.id ?? ""))
    }

let addedToNavigationListeners = false
export const merchantOfferInitiateCompletionFlowThunk =
    (): ThunkAction<void, StoreState, null, AnyAction> => async (dispatch, getState) => {
        const preOnb = merchantPreOnboardingSelectors.details(getState())
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!preOnb?.exists) {
            return
        }

        if (!addedToNavigationListeners && isCurrentPageInMerchantOfferAcquiringOnboardingFlow()) {
            navigationService.addNavigationListener((value) => {
                addedToNavigationListeners = true
                if (!isModalShown && !isCurrentPageInMerchantOfferAcquiringOnboardingFlow()) {
                    isModalShown = true
                    dispatch(
                        addModalThunk({
                            type: "standardInfo",
                            title: getText("merchantOffer.modalCompleted.title"),
                            text: getText("merchantOffer.modalCompleted.text"),
                        })
                    )
                    localStorage.removeItem(getKeyForMerchantOfferCompleted(currentUserRelation?.id ?? ""))
                }
            })

            localStorage.setItem(getKeyForMerchantOfferCompleted(currentUserRelation?.id ?? ""), new Date().toISOString())
        }
    }
