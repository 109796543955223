import { ActionsUnion, createAction } from "swiipe.portal.shared"
import { IClearhausApplicationData } from "../../type/clearhaus/IClearhausApplicationData"
import { IClearhausApplicationOnboardingState } from "../../type/clearhaus/IClearhausApplicationOnboardingState"
import { IClearhausCommentsData } from "../../type/clearhaus/IClearhausCommentsData"
import { StoreState } from "../StoreState"

export type ClearhausState = Readonly<{
    onboardingData: {
        [webshopId: string]: IClearhausApplicationData | undefined
    }
    onboardingDataFetched: {
        [webshopId: string]: boolean
    }
    applicationStates: {
        [webshopId: string]: IClearhausApplicationOnboardingState
    }
    applicationComments: {
        [webshopId: string]: IClearhausCommentsData
    }
}>
const initialState: ClearhausState = {
    onboardingData: {},
    onboardingDataFetched: {},
    applicationStates: {},
    applicationComments: {},
}

export const clearhausReducerActions = {
    setOnboardingData: (webshopId: string, data?: IClearhausApplicationData) =>
        createAction("APP/SET_CH_APPLICATION_DATA", { webshopId, data }),
    setApplicationState: (webshopId: string, state: IClearhausApplicationOnboardingState) =>
        createAction("APP/ADD_CLEARHAUS_APPLICATION_STATE", {
            webshopId,
            state,
        }),
    setApplicationComments: (webshopId: string, comments: IClearhausCommentsData) =>
        createAction("APP/ADD_APPLICATION_COMMENTS", { webshopId, comments }),
}

export type ClearhausActions = ActionsUnion<typeof clearhausReducerActions>

export const clearhausReducer = (state: ClearhausState = initialState, action: ClearhausActions) => {
    switch (action.type) {
        case "APP/SET_CH_APPLICATION_DATA": {
            return {
                ...state,
                onboardingData: {
                    ...state.onboardingData,
                    [action.webshopId]: action.data,
                },
                onboardingDataFetched: {
                    ...state.onboardingDataFetched,
                    [action.webshopId]: true,
                },
            }
        }
        case "APP/ADD_CLEARHAUS_APPLICATION_STATE": {
            return {
                ...state,
                applicationStates: {
                    ...state.applicationStates,
                    [action.webshopId]: action.state,
                },
            }
        }
        case "APP/ADD_APPLICATION_COMMENTS": {
            return {
                ...state,
                applicationComments: {
                    ...state.applicationComments,
                    [action.webshopId]: action.comments,
                },
            }
        }
    }

    return state
}

export const clearhausSelectors = {
    getOnboardingData: (state: StoreState, webshopId: string) => state.clearhaus.onboardingData[webshopId],
    getOnboardingDataFetched: (state: StoreState, webshopId: string) => state.clearhaus.onboardingDataFetched[webshopId],
    applicationState: (state: StoreState, webshopId: string) =>
        state.clearhaus.applicationStates[webshopId] ? state.clearhaus.applicationStates[webshopId] : undefined,
    applicationStates: (state: StoreState) => state.clearhaus.applicationStates,
    getApplicationComments: (state: StoreState, webshopId: string) =>
        state.clearhaus.applicationComments[webshopId] ? state.clearhaus.applicationComments[webshopId] : undefined,
}
