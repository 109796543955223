import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form, FormGroup, Row } from "reactstrap"
import {
    CountryInputComp,
    FieldsetFormGroup,
    FloatingLabelInput,
    PhoneNumberInput,
    ShowErrorMessages,
    valFuncPattern,
    valFuncRequiredAndPattern,
    validationPatterns,
    validationPatternsWithLocale,
} from "swiipe.portal.shared"
import { addDataWithToken, removeUserRegisterDataThunk } from "../../../store/thunks/userThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import EmailInputComp from "../input/EmailInputComp/EmailInputComp"
import RegionFormFields from "../user/RegionFormFields"
import { IUserSignupDataFormModel } from "./../../../type/IUserSignupDataFormModel"
import SubmitButton from "./../../buttons/SubmitButton"
import "./UserSignupDataForm.scss"

interface IUserSignupDataFormProps {
    onSubmitOrSkip: (showWelcome: boolean) => void
    mail: string
    country: string
}

const UserSignupDataForm = ({ onSubmitOrSkip, mail, country }: IUserSignupDataFormProps) => {
    const { handleSubmit, register, formState, setValue, getValues, watch } = useForm<IUserSignupDataFormModel>()
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const onSkip = () => {
        dispatch(removeUserRegisterDataThunk())
        onSubmitOrSkip(true)
    }

    const [currentCountry, setCurrentCountry] = useState("")

    const onSubmit: SubmitHandler<IUserSignupDataFormModel> = async (data) => {
        try {
            data.email = mail
            await dispatch(addDataWithToken(data))
            onSkip()
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    useEffect(() => {
        setCurrentCountry(country)
        setValue("country", country)
    }, [country])

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FieldsetFormGroup field>
                <Row>
                    <div className="col-sm-6 pr-sm-1 mb-fix">
                        <EmailInputComp inputName={"email"} register={register} defaultValue={mail} disabled />
                    </div>
                    <div className="col-sm-6 pl-sm-1">
                        <PhoneNumberInput<IUserSignupDataFormModel>
                            inputName="phone"
                            register={register}
                            setValue={setValue}
                            getValues={getValues}
                            watch={watch}
                        />
                    </div>
                </Row>
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <Row>
                    <div className="col-sm-6 pr-sm-1 mb-fix">
                        <FloatingLabelInput
                            name="firstName"
                            innerRef={register(
                                valFuncRequiredAndPattern(validationPatterns.firstName, t("personal.validationerrors.firstName"))
                            )}
                            placeholder={t("personal.firstName")}
                        />
                    </div>
                    <div className="col-sm-6 pl-sm-1">
                        <FloatingLabelInput
                            name="lastName"
                            innerRef={register(
                                valFuncRequiredAndPattern(validationPatterns.lastName, t("personal.validationerrors.lastName"))
                            )}
                            placeholder={t("personal.lastName")}
                        />
                    </div>
                </Row>
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <FloatingLabelInput
                    name="address"
                    innerRef={register(
                        valFuncRequiredAndPattern(validationPatterns.addressStreet, t("personal.validationerrors.street"))
                    )}
                    placeholder={t("personal.address")}
                />
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <FloatingLabelInput
                    name="companyName"
                    innerRef={register(
                        valFuncPattern(validationPatterns.companyName, t("personal.validationerrors.companyname"))
                    )}
                    placeholder={t("personal.company_name")}
                />
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <Row>
                    <div className="col-sm-6 pr-sm-1 mb-fix">
                        <FloatingLabelInput
                            name="postcode"
                            innerRef={register(
                                valFuncRequiredAndPattern(
                                    validationPatternsWithLocale.addressPostalCode(getValues().country),
                                    t("personal.validationerrors.postcode")
                                )
                            )}
                            placeholder={t("personal.postcode")}
                        />
                    </div>
                    <div className="col-sm-6 pl-sm-1">
                        <FloatingLabelInput
                            name="city"
                            innerRef={register(
                                valFuncRequiredAndPattern(validationPatterns.addressCity, t("personal.validationerrors.city"))
                            )}
                            placeholder={t("personal.city")}
                        />
                    </div>
                </Row>
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <CountryInputComp inputName="country" register={register} onChange={(e) => setCurrentCountry(e.target.value)} />
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <RegionFormFields register={register} setValue={setValue} currentCountry={currentCountry} />
            </FieldsetFormGroup>
            <ShowErrorMessages formState={formState} />
            <FieldsetFormGroup>
                <SubmitButton dark formState={formState} id="merchant-signup-btn" title={t("personal.save")} />
            </FieldsetFormGroup>
            <FormGroup className="text-center">
                <button onClick={onSkip} className="btn-skip">
                    {t("signup.doitlater")}
                </button>
            </FormGroup>
        </Form>
    )
}

export default UserSignupDataForm
