import React, { useState } from "react"
import cn from "classnames"
import "./DropDownWithChildren.scss"
import ArrowDownFilled from "../../images/icons/arrow-down-gray-filled.svg"
import ArrowDown from "../../images/icons/arrow-native.png"

interface IDropDownWithChildren {
    dropDownList?: JSX.Element
    children?: React.ReactNode
    placeholder: string
    selectedValue?: string
    setActiveState: (active: boolean) => void
    active: boolean
    width?: number
    boxWidth?: number
    isSmall?: boolean
    useFilledDropDownArrow?: boolean
    disabled?: boolean
}

const DropDownWithChildren = ({
    dropDownList,
    placeholder,
    selectedValue,
    isSmall,
    children,
    active,
    boxWidth,
    width,
    setActiveState,
    useFilledDropDownArrow,
    disabled,
}: IDropDownWithChildren) => {
    return (
        <div className="children-dropdown-container ml-2">
            {dropDownList ? (
                dropDownList
            ) : (
                <div
                    className={cn("dropdown-main box-styled", disabled && "disabled")}
                    style={{ width: width }}
                    onClick={() => {
                        if (disabled) {
                            return
                        }
                        setActiveState(!active)
                    }}
                    title={selectedValue ? selectedValue : placeholder}
                >
                    {selectedValue ? (
                        <span className={cn("selected", isSmall && "small")} style={{ width: width }}>
                            {selectedValue}
                        </span>
                    ) : (
                        <span>{placeholder}</span>
                    )}{" "}
                    <img className="drop-down-arrow" src={useFilledDropDownArrow ? ArrowDownFilled : ArrowDown} />
                </div>
            )}
            <div className={cn("child box-styled", { "d-none": !active })} style={{ width: boxWidth ? boxWidth : width }}>
                {children}
            </div>
        </div>
    )
}

export default DropDownWithChildren
