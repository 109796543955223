import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FormHeader, PageContainer, PageWrapper, getQueryValueFromCurrentUrl, useSelector } from "swiipe.portal.shared"
import ClearhausOnboardingMessage from "../../../components/clearhaus/ClearhausOnboardingMessage"
import ClearhausUpdateInfoForm from "../../../components/form/clearhaus/ClearhausUpdateInfoForm"
import { navigationService } from "../../../services/navigationService"
import { StoreState } from "../../../store/StoreState"
import { clearhausSelectors } from "../../../store/reducers/clearhausReducer"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { getClearhausApplicationDataThunk } from "../../../store/thunks/clearhausThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"

interface IClearhausUpdateInfoPageInnerProps {
    webshopId: string
    swMerchantId: string
}
export const ClearhausUpdateInfoPage = () => {
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const webshopId = getQueryValueFromCurrentUrl("webshopId")

    if (!merchantDetails || !webshopId) {
        return null
    }

    return <ClearhausUpdateInfoPageInner webshopId={webshopId} swMerchantId={merchantDetails.swMerchant.swMerchantId} />
}

const ClearhausUpdateInfoPageInner = ({ webshopId, swMerchantId }: IClearhausUpdateInfoPageInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [isSubmitted, setIsSubmitted] = useState(false)

    useEffect(() => {
        dispatch(getClearhausApplicationDataThunk(swMerchantId, [webshopId]))
    }, [])

    const applicationData = useSelector((state: StoreState) => clearhausSelectors.getOnboardingData(state, webshopId))

    const onGoBack = () => {
        navigationService.navigate(`view_clearhaus_application?webshopId=${webshopId}`)
    }

    return (
        <PageWrapper widest>
            <PageContainer transparent>
                <FormHeader
                    title={t("clearhausUpdateInfo.header")}
                    onBack={() => {
                        onGoBack()
                    }}
                    onClose={() => navigationService.navigate("/plugins")}
                />
                {!isSubmitted && (
                    <ClearhausUpdateInfoForm
                        onGoBack={onGoBack}
                        onSubmitted={() => setIsSubmitted(true)}
                        applicationData={applicationData}
                        swMerchantId={swMerchantId}
                    />
                )}
                {isSubmitted && (
                    <ClearhausOnboardingMessage
                        mainText={
                            <>
                                <p>
                                    <b>{t("clearhausUpdateInfo.changesSubmitted.yourRequestSubmitted")}</b>
                                </p>
                                <p>{t("clearhausUpdateInfo.changesSubmitted.weWillGetBack")}</p>
                            </>
                        }
                        mainButton={{
                            text: t("common.ok"),
                            onClick: onGoBack,
                        }}
                    />
                )}
            </PageContainer>
        </PageWrapper>
    )
}

export default ClearhausUpdateInfoPage
