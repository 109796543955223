import { combineReducers } from "redux"
import { getSharedRootReducer, SharedStoreState } from "swiipe.portal.shared"

import { a2aReducer, A2aState } from "./reducers/a2aReducer"
import { billingReducer, BillingState } from "./reducers/billingReducer"
import { clearhausReducer, ClearhausState } from "./reducers/clearhausReducer"
import { configurationSlice, IConfigurationStoreAddition } from "./reducers/configurationSlice"
import { gatewayReducer, GatewayState } from "./reducers/gatewayReducer"
import { layoutReducer, LayoutState } from "./reducers/layoutReducer"
import { legalReducer, LegalState } from "./reducers/legalReducer"
import { merchantDashboardReducer, MerchantDashboardState } from "./reducers/merchantDashboardReducer"
import { IMerchantPreOnboardingStoreAddition, merchantPreOnboardingSlice } from "./reducers/merchantPreOnboardingSlice"
import { merchantReducer, MerchantState } from "./reducers/merchantReducer"
import { merchantServicesReducer, MerchantServicesState } from "./reducers/merchantServicesReducer"
import { onboardingApplicationReducer, OnboardingApplicationState } from "./reducers/onboardingApplicationReducer"
import { orderReducer, OrderState } from "./reducers/orderReducer"
import { partnerReducer, PartnerState } from "./reducers/partnerReducer"
import { paymentLinksReducer, PaymentLinksState } from "./reducers/paymentLinkReducer"
import { paymentMethodReducer, PaymentMethodState } from "./reducers/paymentMethodReducer"
import { releaseNoteReducer, ReleaseNoteState } from "./reducers/releaseNoteReducer"
import { resursReducer, ResursState } from "./reducers/resursReducer"
import { salesReducer, SalesState } from "./reducers/salesReducer"
import { userRelationReducer, UserRelationState } from "./reducers/userRelationReducer"

export type StoreState = {
    merchant: MerchantState
    merchantDashboard: MerchantDashboardState
    userRelation: UserRelationState
    partner: PartnerState
    legal: LegalState
    layout: LayoutState
    sales: SalesState
    onboardingApplications: OnboardingApplicationState
    merchantServices: MerchantServicesState
    a2a: A2aState
    resurs: ResursState
    clearhaus: ClearhausState
    releaseNotes: ReleaseNoteState
    orders: OrderState
    paymentLinks: PaymentLinksState
    billing: BillingState
    paymentMethod: PaymentMethodState
    gateway: GatewayState
} & SharedStoreState &
    IConfigurationStoreAddition &
    IMerchantPreOnboardingStoreAddition

export const createRootReducer = () =>
    combineReducers<StoreState>({
        merchant: merchantReducer,
        merchantDashboard: merchantDashboardReducer,
        userRelation: userRelationReducer,
        partner: partnerReducer,
        legal: legalReducer,
        layout: layoutReducer,
        sales: salesReducer,
        onboardingApplications: onboardingApplicationReducer,
        merchantServices: merchantServicesReducer,
        a2a: a2aReducer,
        clearhaus: clearhausReducer,
        releaseNotes: releaseNoteReducer,
        orders: orderReducer,
        paymentLinks: paymentLinksReducer,
        billing: billingReducer,
        paymentMethod: paymentMethodReducer,
        gateway: gatewayReducer,
        resurs: resursReducer,
        configuration: configurationSlice.reducer,
        merchantPreOnboarding: merchantPreOnboardingSlice.reducer,
        ...getSharedRootReducer(),
    })
