import React from "react"
import { splitStringAndKeepDelimiter } from "./stringUtil"

export function injectElementIntoText(text: string, placeholder: string, element: React.ReactNode): JSX.Element {
    if (!text) {
        return <></>
    }

    if (!placeholder || !element) {
        return <span>{text}</span>
    }

    const splittedText = splitStringAndKeepDelimiter(text, placeholder)
    return (
        <>
            {splittedText.map((textToken) => {
                if (textToken === placeholder) {
                    return element
                }
                return <span key={textToken}>{textToken}</span>
            })}
        </>
    )
}
