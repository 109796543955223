import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { DropDownList, SpinnerContainer, StandardButton, useSelector } from "swiipe.portal.shared"
import { TOnboardingType } from "../../../services/onboardingService"
import { useStatusesForWebshopsToOnboardSelect, useWebshopToOnboardSelect } from "../../../services/webshopsToOnboardService"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { MixedMerchantDetails } from "../../../type/mixedmerchantdetails"
import "./WebshopsToOnboardSelect.scss"

interface IWebshopsToOnboardSelectProps {
    merchantDetails: MixedMerchantDetails
    onboardingType: TOnboardingType
    onSelectedWebshopIdsChanged: (selectedWebshopIds: string[]) => void
    webshopsToPreselect?: string[]
    allowMultipleWebshops?: boolean
    navigateToOnEmptyWebshops?: string
    onIsStatusesLoadedChanged?: (isLoaded: boolean) => void
}

const WebshopsToOnboardSelect = ({
    merchantDetails,
    onboardingType,
    onSelectedWebshopIdsChanged,
    webshopsToPreselect,
    allowMultipleWebshops = true,
    navigateToOnEmptyWebshops = "/plugins",
    onIsStatusesLoadedChanged,
}: IWebshopsToOnboardSelectProps) => {
    const { t } = useTranslation()
    const swiipePlan = useSelector(merchantSelectors.swiipePlan)

    const { isStatusesLoaded, featureStatuses, onboardingStatuses } = useStatusesForWebshopsToOnboardSelect(
        merchantDetails,
        onboardingType
    )
    useEffect(() => {
        onIsStatusesLoadedChanged?.(isStatusesLoaded)
    }, [isStatusesLoaded])

    const { initialized, selectedWebshopIds, getDropdownOptions, selectWebshop, deselectWebshop, addWebshop } =
        useWebshopToOnboardSelect(
            isStatusesLoaded,
            merchantDetails,
            onboardingType,
            navigateToOnEmptyWebshops,
            swiipePlan,
            onboardingStatuses,
            featureStatuses
        )
    useEffect(() => {
        onSelectedWebshopIdsChanged(selectedWebshopIds)
    }, [selectedWebshopIds])
    useEffect(() => {
        if (!webshopsToPreselect || !initialized) {
            return
        }
        //deselect all webshops
        selectedWebshopIds.forEach((id) => deselectWebshop(id))
        // select preselected webshops
        webshopsToPreselect.forEach((id) => selectWebshop(id, undefined, true))
    }, [webshopsToPreselect, initialized])

    return (
        <>
            {merchantDetails && (
                <SpinnerContainer className={!isStatusesLoaded ? "mt-5" : ""} showSpinner={!isStatusesLoaded}>
                    {isStatusesLoaded &&
                        selectedWebshopIds.map((webshopId, index, inLoopSelectedWebshopIds) => (
                            <span key={index}>
                                <div className="webshop-fields-container">
                                    <DropDownList
                                        className="mt-2"
                                        options={getDropdownOptions(webshopId)}
                                        value={webshopId}
                                        onChange={(e) => selectWebshop(e.target.value, webshopId)}
                                    />
                                    {index == inLoopSelectedWebshopIds.length - 1 && index != 0 && (
                                        <div className="delete-webshop-field-btn" onClick={() => deselectWebshop(webshopId)}>
                                            ×
                                        </div>
                                    )}
                                </div>
                            </span>
                        ))}

                    {allowMultipleWebshops && addWebshop && (
                        <StandardButton className="btn-adder mt-4 mb-3" invertedBlue onClick={() => addWebshop()}>
                            + {t("webshopfields.addwebshop")}
                        </StandardButton>
                    )}
                </SpinnerContainer>
            )}
        </>
    )
}

export default WebshopsToOnboardSelect
