import React from "react"
import { StandardButton, TextButton } from "swiipe.portal.shared"

export interface IApplyResetButtonsBlockProps {
    onApply: () => void
    onReset: () => Promise<void>
    applyText: string
    resetText: string
}

const ApplyResetButtonsBlock = ({ onApply, onReset, applyText, resetText }: IApplyResetButtonsBlockProps) => {
    return (
        <div className="my-2 mx-2">
            <StandardButton isBlue dark noBorder onClick={onApply}>
                {applyText}
            </StandardButton>
            <TextButton className="mt-2" text={resetText} onClick={onReset} textDecoration="underline" />
        </div>
    )
}

export default ApplyResetButtonsBlock
