import React, { useEffect } from "react"
import { CleanHtml, PageWrapper, SpinnerContainer, getQueryValueFromCurrentUrl, useSelector } from "swiipe.portal.shared"
import { handleLegalAnchorsInModal } from "../services/legalService"
import { StoreState } from "../store/StoreState"
import { ETermsType, legalSelectors } from "../store/reducers/legalReducer"
import { userRelationSelectors } from "../store/reducers/userRelationReducer"
import { ensureTermsThunk } from "../store/thunks/termsThunks"
import { useReduxDispatch } from "../store/useReduxDispatch"
import { TUserRelation } from "../type/TUserRelation"
import "./TermsTextPage.scss"

interface ITermsTextPageProps {
    userRelationType: TUserRelation
    userRelationId?: string | undefined
    termsType: ETermsType
}

const TermsTextPage = ({ userRelationType, userRelationId, termsType }: ITermsTextPageProps) => {
    const dispatch = useReduxDispatch()
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)

    const relationId = userRelationId ?? currentUserRelation?.id
    const termsContent = useSelector((state: StoreState) => legalSelectors.terms(state, termsType, userRelationType, relationId))

    const forceLanguage = getQueryValueFromCurrentUrl("forceLanguage") ?? undefined
    const token = getQueryValueFromCurrentUrl("termsToken") ?? undefined
    const market = getQueryValueFromCurrentUrl("market") ?? undefined

    useEffect(() => {
        if (termsContent) {
            handleLegalAnchorsInModal()
        }
    }, [termsContent])

    useEffect(() => {
        dispatch(ensureTermsThunk(termsType, userRelationType, relationId, token, market, forceLanguage, true))
    }, [termsType, userRelationType, relationId, forceLanguage, market, token])

    return (
        <PageWrapper widest className="terms-text-page">
            <SpinnerContainer showSpinner={!termsContent} className="terms-text-page__spinner-container">
                <CleanHtml element="div" html={termsContent || ""} />
            </SpinnerContainer>
        </PageWrapper>
    )
}

export default TermsTextPage
