import React from "react"
import { useTranslation } from "react-i18next"
import SwiipeLogoImage from "../../../images/swiipe-logo.png"
import SecuredBySwiipeImage from "../../../images/icons/blue_and_green/login.png"
import "./FormFooter.scss"
import cn from "classnames"
interface IFormFooterProps {
    className?: string
}

const FormFooter = ({ className }: IFormFooterProps) => {
    const { t } = useTranslation()
    return (
        <div className={cn("swiipe-form-footer form-footer mb-2 mt-5", className)}>
            <p className="text-center">
                <img src={SecuredBySwiipeImage} className="lock" /> {t("common.secured")}
                <img src={SwiipeLogoImage} className="logo" />
            </p>
        </div>
    )
}

export default FormFooter
