import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PageContainer, PageWrapper, useSelector, userSelectors } from "swiipe.portal.shared"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { getOnboardingStatusThunk } from "../../store/thunks/swiipePaymentsThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import "./ApplePayOnboardingPage.scss"
import ApplePayDetailsStep from "./applepayPageSteps/ApplePayDetailsStep/ApplePayDetailsStep"
import ApplePayExistingCertsStep from "./applepayPageSteps/ApplePayExistingCerts/ApplePayExistingCerts"
import ApplePayGenerateCertsStep from "./applepayPageSteps/ApplePayGenerateCerts/ApplePayGenerateCerts"
import ApplePayIdStep from "./applepayPageSteps/ApplePayIdStep/ApplePayIdStep"
import ApplePayIsNoCertsStep from "./applepayPageSteps/ApplePayIsNoCertsStep/ApplePayIsNoCertsStep"
import ApplePayNoCertsStep from "./applepayPageSteps/ApplePayNoCertsStep/ApplePayNoCertsStep"

interface IApplePayOnboardingPageProps {
    webshopIds?: string[]
    isEdit?: boolean
    onGoBack: () => Promise<void>
    onOnboarded: () => void
}
interface IApplePayOnboardingPageInnerProps extends IApplePayOnboardingPageProps {
    merchantDetails: MixedMerchantDetails
    selectedWebshopId?: string
}

const ApplePayOnboardingPage = (props: IApplePayOnboardingPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const userData = useSelector(userSelectors.userData)
    const selectedWebshopId =
        props.webshopIds && props.webshopIds?.length > 0 ? props.webshopIds[0] : useSelector(merchantSelectors.selectedWebshopId)

    if (!merchantDetails || !userData || !merchantDetails.webshops.length) {
        return null
    }

    return <ApplePayOnboardingPageInner merchantDetails={merchantDetails} selectedWebshopId={selectedWebshopId} {...props} />
}

export type TApplePayOnboardingType = "existingCert" | "generateCert" | "noCert"
const ApplePayOnboardingPageInner = ({
    merchantDetails,
    webshopIds,
    selectedWebshopId,
    isEdit,
    onGoBack,
    onOnboarded,
}: IApplePayOnboardingPageInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [showCancelService, setShowCancelService] = useState(false)
    const [applePayId, setApplePayId] = useState("")
    const [currentStep, setCurrentStep] = useState<"chooseIsNoCertsStep" | "chooseCertificatesSetup" | "onboardingStep" | "edit">(
        isEdit ? "edit" : "chooseIsNoCertsStep"
    )
    const [onboardingType, setOnboardingType] = useState<TApplePayOnboardingType>()

    useEffect(() => {
        merchantDetails?.webshops.forEach(
            async (w) =>
                await dispatch(
                    getOnboardingStatusThunk(merchantDetails?.swMerchant.swMerchantId, w.webshopId, false, ["ApplePay"])
                )
        )
    }, [merchantDetails])

    if (!webshopIds && !isEdit) {
        return null
    }

    const getComponentToShow = () => {
        switch (currentStep) {
            case "chooseIsNoCertsStep": {
                return (
                    <ApplePayIsNoCertsStep
                        onBackStep={onGoBack}
                        onNextStep={(onboardingType) => {
                            if (!onboardingType) {
                                setCurrentStep("chooseCertificatesSetup")
                                return
                            }
                            setOnboardingType(onboardingType)
                            setCurrentStep("onboardingStep")
                        }}
                    />
                )
            }
            case "chooseCertificatesSetup": {
                return (
                    <ApplePayIdStep
                        onNextStep={(onboardingType) => {
                            setOnboardingType(onboardingType)
                            setCurrentStep("onboardingStep")
                        }}
                        onBackStep={async () => setCurrentStep("chooseIsNoCertsStep")}
                        applePayId={applePayId}
                        setApplePayId={setApplePayId}
                    />
                )
            }
            case "onboardingStep": {
                if (onboardingType === "noCert") {
                    return (
                        <ApplePayNoCertsStep
                            webshopId={selectedWebshopId ?? ""}
                            merchantDetails={merchantDetails}
                            onBackStep={async () => setCurrentStep("chooseIsNoCertsStep")}
                            onNextStep={onOnboarded}
                        />
                    )
                }
                if (onboardingType === "existingCert") {
                    return (
                        <ApplePayExistingCertsStep
                            webshopId={selectedWebshopId ?? ""}
                            merchantDetails={merchantDetails}
                            applePayId={applePayId}
                            onNextStep={onOnboarded}
                            onBackStep={async () => setCurrentStep("chooseCertificatesSetup")}
                        />
                    )
                }
                //"generateCert"
                return (
                    <ApplePayGenerateCertsStep
                        webshopId={selectedWebshopId ?? ""}
                        merchantDetails={merchantDetails}
                        applePayId={applePayId}
                        onNextStep={onOnboarded}
                        onBackStep={async () => setCurrentStep("chooseCertificatesSetup")}
                    />
                )
            }
            case "edit": {
                return (
                    <ApplePayDetailsStep
                        webshopId={selectedWebshopId ?? ""}
                        merchantDetails={merchantDetails}
                        onBackStep={onGoBack}
                    />
                )
            }
            default:
                return onGoBack()
        }
    }

    return (
        <>
            <PageWrapper className={cn({ "d-none": showCancelService })}>
                <PageContainer id="applepay-onboarding-container" transparent>
                    <>
                        {getComponentToShow()}
                        <div></div>
                    </>
                </PageContainer>
            </PageWrapper>
        </>
    )
}

export default ApplePayOnboardingPage
