import React, { useState } from "react"
import { DropDownList, IDropdownOption, StandardButton } from "swiipe.portal.shared"
import "./DropDownWithButton.scss"

interface IDropDownWithButton<T> {
    options: IDropdownOption<T>[]
    placeholder: string
    buttonText: string
    onButtonClick: (selectedValue: any) => Promise<void>
}

export const DropDownWithButton = <T extends string | number>({
    options,
    placeholder,
    buttonText,
    onButtonClick,
}: IDropDownWithButton<T>) => {
    const [selectedValue, setSelectedValue] = useState(undefined as any)

    return (
        <div className="dropdown-with-button">
            <DropDownList
                options={options}
                placeholder={placeholder}
                onChange={(e) => {
                    setSelectedValue(e.target.value)
                }}
            />

            <StandardButton className="button" noBorder onClick={() => onButtonClick(selectedValue)}>
                {buttonText}
            </StandardButton>
        </div>
    )
}
