import React from "react"
import { useTranslation } from "react-i18next"
import { InputProps } from "reactstrap"
import { TCountryFilter, getCountriesList } from "../../../services/countriesService"
import { TRegister } from "../../../types/form/ReactHooksFormTypes"
import { TCountryCode } from "../../../types/general/countryCodes"
import { valFuncPattern, valFuncRequiredAndPattern, validationPatterns } from "../../../utils/validationUtil"
import { DropDownList } from "./DropDownList"

export interface ICountryInputComps extends InputProps {
    inputName: string
    defaultValue?: string
    countryFilter?: TCountryFilter
    customCountryFilter?: TCountryCode[]
    isOptional?: boolean
    hidePlaceholder?: boolean
    register: TRegister
}

export const CountryInputComp = ({
    inputName = "country",
    defaultValue = "DK",
    register,
    countryFilter,
    customCountryFilter,
    isOptional,
    hidePlaceholder,
    ...otherProps
}: ICountryInputComps) => {
    const { t } = useTranslation()
    return (
        <DropDownList
            defaultValue={defaultValue}
            name={inputName}
            placeholder={!hidePlaceholder ? t("common.country.label") : undefined}
            innerRef={
                isOptional
                    ? register(valFuncPattern(validationPatterns.countryISO2Code, t("common.country.missingErrorMessage")))
                    : register(
                          valFuncRequiredAndPattern(validationPatterns.countryISO2Code, t("common.country.missingErrorMessage"))
                      )
            }
            {...otherProps}
            options={getCountriesList(countryFilter, customCountryFilter)}
        />
    )
}
