import { ActionsUnion, createAction } from "swiipe.portal.shared"
import { ITermsAcceptanceStatusDto } from "../../type/ILegalData"
import { TUserRelation } from "../../type/TUserRelation"
import { StoreState } from "../StoreState"

export const allTermsTypes = [
    "TermsMerchant",
    "TermsUser",
    "TermsPartner",
    "MerchantPartnerAgreement",
    "PrivacyPolicyShared",
    "TermsReturnSell",
    "TermsPlusSell",
    "TermsWinBack",
    "TermsAnywhereMobile",
    "DataProcessingAgreement",
    "TermsAcquiringClearhaus",
    "TermsA2a",
    "TermsPSP",
    "TermsKlarna",
    "TermsSwish",
    "TermsApplePay",
    "TermsDankort",
    "TermsViaBill",
    "TermsForbrugsforeningen",
    "TermsVipps",
    "TermsMobilePayOnline",
    "TermsResurs",
    "TermsExternalAnalytics",
    "TermsTrygUser",
] as const
export type ETermsType = (typeof allTermsTypes)[number]

export interface LegalPage {
    userRelationType: TUserRelation
    textType: ETermsType
    // The link to the resource
    link: string
    // The site link
    siteLink: string
    text?: string
}

export type LegalState = Readonly<{
    terms: { [relationId: string]: { [termsType in ETermsType]?: string } }
    termsStatuses: { [userRelationId: string]: ITermsAcceptanceStatusDto[] }
    legalMessageShown: { [userRelationId: string]: boolean }
}>
const initialState: LegalState = {
    legalMessageShown: {},
    termsStatuses: {},
    terms: {},
}

export const legalReducerActions = {
    clearTerms: () => createAction("APP/CLEAR_TERMS", {}),
    setTerms: (termsType: ETermsType, relationType: TUserRelation, relationId: string | undefined, content: string) =>
        createAction("APP/SET_TERMS", { termsType, relationType, relationId, content }),
    setTermsStatus: (userRelationId: string, statuses: ITermsAcceptanceStatusDto[]) =>
        createAction("APP/SET_TERMS_STATUS", { userRelationId, statuses }),
    setLegalMessageShown: (userRelationId: string, shown: boolean) =>
        createAction("APP/SET_LEGAL_MESSAGE_SHOWN", { userRelationId, shown }),
}

export type LegalActions = ActionsUnion<typeof legalReducerActions>

export const legalReducer = (state: LegalState = initialState, action: LegalActions): LegalState => {
    switch (action.type) {
        case "APP/CLEAR_TERMS": {
            return { ...state, terms: {} }
        }
        case "APP/SET_TERMS": {
            const relationId = action.relationId || "public_" + action.relationType
            return {
                ...state,
                terms: {
                    ...state.terms,
                    [relationId]: {
                        ...(state.terms[relationId] ?? {}),
                        [action.termsType]: action.content,
                    },
                },
            }
        }
        case "APP/SET_TERMS_STATUS": {
            return {
                ...state,
                termsStatuses: {
                    ...state.termsStatuses,
                    [action.userRelationId]: action.statuses,
                },
            }
        }
        case "APP/SET_LEGAL_MESSAGE_SHOWN": {
            return {
                ...state,
                legalMessageShown: {
                    ...state.legalMessageShown,
                    [action.userRelationId]: action.shown,
                },
            }
        }
    }

    return state
}

export const legalSelectors = {
    terms: (state: StoreState, termsType: ETermsType, relationType: TUserRelation, relationId: string | undefined) =>
        state.legal.terms[relationId || "public_" + relationType]?.[termsType],
    termsStatuses: (state: StoreState) =>
        state.userRelation.currentRelation ? state.legal.termsStatuses[state.userRelation.currentRelation.id] : undefined,
    legalMessageShown: (state: StoreState): boolean =>
        state.userRelation.currentRelation ? !!state.legal.legalMessageShown[state.userRelation.currentRelation.id] : false,
}
