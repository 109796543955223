import React from "react"
import { useTranslation } from "react-i18next"
import { PhoneNumberInput, useSelector, userSelectors } from "swiipe.portal.shared"
import { updateUserDataThunk } from "../../../store/thunks/userPersonalInfoThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IEditFormBase } from "../../../type/form/IEditFormBase"
import { ChangePhonenumberForm } from "../../../type/personal/changephonenumberform"
import InfoBoxEditor from "../../infobox/InfoBoxEditor"
import { InfoBoxState } from "../../infobox/InfoBoxState"
import InfoBoxContainer from "./../../infobox/InfoBoxContainer"

interface IUserPhoneFormProps extends IEditFormBase {}

const UserPhoneForm = ({ isInEditMode, onSwitchEditMode }: IUserPhoneFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const userData = useSelector(userSelectors.userData)
    if (!userData || !userData.user) {
        return null
    }
    const phone = userData.user.phone

    return (
        <InfoBoxContainer isInEditMode={isInEditMode} iconClass="icon-phone" title={t("personal.phone")}>
            <InfoBoxEditor<ChangePhonenumberForm>
                state={InfoBoxState.DEFAULT}
                iconClass="icon-phone"
                title={phone ? t("personal.editphone") : t("personal.addphone")}
                isInEditMode={isInEditMode}
                editBtnTextType={phone ? "editing" : "adding"}
                renderPreview={() =>
                    phone ? (
                        <div>{phone}</div>
                    ) : (
                        <div>
                            <strong>{t("personal.addphone")}</strong>
                        </div>
                    )
                }
                renderFormFields={(register, setValue, getValues, watch) => (
                    <div className="edit-form">
                        <PhoneNumberInput<ChangePhonenumberForm>
                            inputName="phone"
                            defaultValue={phone}
                            register={register}
                            setValue={setValue}
                            getValues={getValues}
                            watch={watch}
                        />
                    </div>
                )}
                onSave={async (model) => {
                    await dispatch(
                        updateUserDataThunk({
                            ...model,
                            type: "phoneForm",
                        })
                    )
                }}
                allowDelete={false}
                onSwitchEditMode={onSwitchEditMode}
            />
        </InfoBoxContainer>
    )
}

export default UserPhoneForm
