import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { getPagesCount, getRecordsForPageNumber, requestThunk } from "swiipe.portal.shared"
import { prepareOrderFiltersForRequest } from "../../services/orderFiltersService"
import { Order, OrdersFilter } from "../../type/Order"
import { OrderDetailsDto } from "../../type/orderDetailsDto"
import { PaymentOrderDetails } from "../../type/paymentOrderDetails"
import { StoreState } from "../StoreState"
import { merchantSelectors } from "../reducers/merchantReducer"
import { orderReducerActions, orderSelectors } from "../reducers/orderReducer"
import { endpoints } from "./../../data/endpoints"

export const getOrdersPerWebshopThunk =
    (
        webshopId: string,
        pageNumber: number,
        perPage: number,
        force: boolean,
        clearBeforeUpdate?: boolean
    ): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentOrders = orderSelectors.getOrdersForWebshop(getState(), webshopId)
        const currentPageOrders = getRecordsForPageNumber(pageNumber, perPage, currentOrders)

        const continuationToken = orderSelectors.getContinuationTokenPerMerchant(getState(), webshopId)
        const pagesCount = getPagesCount(perPage, currentOrders)

        const fetchNewOrders = !!continuationToken && pagesCount === pageNumber

        if (currentPageOrders && !force && !fetchNewOrders) {
            return
        }

        const filters = orderSelectors.getOrderFilter(getState())
        const preparedFilters = prepareOrderFiltersForRequest(filters)

        const data = {
            webshopId: webshopId,
            perPage: perPage,
            // in case we want to fetch orders until certain page
            fetchPagesCount: pageNumber == 1 || pagesCount === pageNumber ? null : pageNumber,
            sorting: preparedFilters.sorting,
            filters: preparedFilters.filtering,
            continuationToken: fetchNewOrders ? continuationToken : null,
        }

        const orders = await dispatch(
            requestThunk<{
                orders: Order[]
                pagesCount: number
                continuationToken: string
            }>(
                endpoints.Payments.getOrdersPerWebshop,
                {
                    data: data,
                },
                undefined
            )
        )

        if (clearBeforeUpdate) {
            dispatch(orderReducerActions.clearOrders(webshopId))
        }

        dispatch(orderReducerActions.setOrders(webshopId, orders.orders, !fetchNewOrders, orders.continuationToken))
    }

export const getOrderThunk =
    (webshopId: string, orderId: string, force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const order = orderSelectors.getOrder(getState(), orderId)

        if (order && !force) {
            return
        }

        const response = await dispatch(
            requestThunk<{
                order: Order
            }>(endpoints.Payments.getSingleOrderPerWebshop(webshopId, orderId))
        )

        dispatch(orderReducerActions.setOrder(webshopId, response.order))
    }

export const getPaymentOrderDetailsThunk =
    (webshopId: string, orderId: string, force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentTxs = orderSelectors.getPaymentOrderDetails(getState(), orderId)?.transactions

        if (currentTxs && !force) {
            return
        }

        const details = await dispatch(
            requestThunk<PaymentOrderDetails>(endpoints.Payments.getPaymentOrderDetails, {
                params: {
                    webshopId: webshopId,
                    orderId: orderId,
                },
            })
        )

        dispatch(orderReducerActions.setPaymentOrderDetails(orderId, details))
    }

export const getInfoForOrderThunk =
    (webshopId: string, orderId: string, force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const details = orderSelectors.getOrderDetails(getState(), orderId)

        if (details && !force) {
            return
        }

        const response = await dispatch(
            requestThunk<OrderDetailsDto>(endpoints.Order.getOrderDetails(orderId), {
                params: {
                    webshopId: webshopId,
                },
            })
        )

        dispatch(orderReducerActions.setOrderDetails(orderId, response))
    }

export const reportFraudThunk =
    (
        webshopId: string,
        orderId: string,
        swMerchantId: string,
        reason: string
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const merchantDetails = merchantSelectors.merchantDetails(getState())

        if (!merchantDetails) {
            return
        }

        await dispatch(
            requestThunk<void>(endpoints.Payments.reportFraud, {
                data: {
                    webshopId,
                    orderId,
                    swMerchantId,
                    reason,
                },
            })
        )
    }

export const updateOrderFiltersThunk =
    (updateFiltersFunc: (filters: OrdersFilter) => void): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const filters = orderSelectors.getOrderFilter(getState())

        updateFiltersFunc(filters)

        dispatch(orderReducerActions.setOrderFilter({ ...filters }))
    }

export const exportOrdersThunk =
    (webshopId: string, exportType: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const filters = orderSelectors.getOrderFilter(getState())
        const preparedFilters = prepareOrderFiltersForRequest(filters)

        const data = {
            webshopId: webshopId,
            sorting: preparedFilters.sorting,
            filters: preparedFilters.filtering,
            exportFormat: exportType,
        }

        const result = await dispatch(
            requestThunk<{ data: Blob; fileName: string }>(
                endpoints.Payments.getOrdersExport,
                {
                    data: data,
                },
                undefined
            )
        )

        const url = window.URL.createObjectURL(new Blob([base64ToArrayBuffer(result.data)], { type: "text/csv" }))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", result.fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

function base64ToArrayBuffer(base64: any) {
    const binaryString = window.atob(base64)
    const binaryLen = binaryString.length
    const bytes = new Uint8Array(binaryLen)
    for (let i = 0; i < binaryLen; i += 1) {
        const ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
    }
    return bytes
}
