import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, ModalFooter } from "swiipe.portal.shared"
import "./ModalSms.scss"

export interface IModalSms extends IModalBase {
    type: "sms"
    body: string
}

const ModalSms = ({ title, body, callback, ...otherProps }: IModalSms & IModalControlProps) => {
    const { t } = useTranslation()
    return (
        <BaseModal {...otherProps} modalClassName="modal-sms" callback={callback} centered title={title}>
            <ModalBody>
                <div className="content-container">
                    <p className="mb-0 text-center">{body}</p>
                </div>
            </ModalBody>
            <ModalFooter rightButton={{ onClick: async () => callback("accepted"), text: t("common.close") }} />
        </BaseModal>
    )
}

export default ModalSms
