import cn from "classnames"
import React, { useEffect } from "react"
import { Control, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
    DropDownList,
    FieldsetFormGroup,
    FloatingLabelInput,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { getCurrencyDropdownOptions } from "../../../services/currencyService"
import { IFormBase } from "../../../types/form/IFormBase"
import { getFormattedNumber } from "../../../utils/formatUtil"
import { CompletedFieldsReview } from "../../form/review/CompletedFieldsReview"
import "./AcquiringTurnoverFields.scss"

export interface IAcquiringTurnoverSubForm {
    transactionsCurrency: string
    monthly: number
    transactionAmount: number
}

interface IAcquiringFieldsProps<TForm extends IFormBase> {
    control: Control<TForm>
    isSubmitted: boolean
    showMainInput: boolean
    showDetailInput: boolean
    getFieldsFromControl: (form: TForm | undefined) => IAcquiringTurnoverSubForm | undefined
    savedFields: IAcquiringTurnoverSubForm | undefined
    fieldSelectorPrefix: string
    onMonthlyTurnoverChanged?: (transactionsCurrency: string | undefined, monthlyTurnover: number | undefined) => void
}

export const AcquiringTurnoverFields = <TForm extends IFormBase>({
    control,
    isSubmitted,
    showMainInput,
    showDetailInput,
    getFieldsFromControl,
    savedFields,
    fieldSelectorPrefix,
    onMonthlyTurnoverChanged,
}: IAcquiringFieldsProps<TForm>) => {
    const { t } = useTranslation()
    const currentFields = getFieldsFromControl(control.getValues() as TForm)

    const getFieldSelector = (fieldName: string) => fieldSelectorPrefix + fieldName

    const fieldsWatch = useWatch({
        control,
        name: [getFieldSelector("transactionsCurrency"), getFieldSelector("monthly")],
    })

    useEffect(() => {
        const values = getFieldsFromControl(control.getValues() as TForm)
        onMonthlyTurnoverChanged?.(values?.transactionsCurrency, values?.monthly)
    }, [fieldsWatch])

    useEffect(() => {
        onMonthlyTurnoverChanged?.(savedFields?.transactionsCurrency, savedFields?.monthly)
    }, [savedFields])

    return (
        <div className="acquiring-turnover-fields">
            <div className={cn({ "d-none": !showMainInput, "mb-3": showMainInput && showDetailInput })}>
                <FieldsetFormGroup field>
                    <DropDownList
                        name={getFieldSelector("transactionsCurrency")}
                        options={getCurrencyDropdownOptions()}
                        innerRef={control.register()}
                    ></DropDownList>
                </FieldsetFormGroup>
                <FieldsetFormGroup className="mb-0" field>
                    <FloatingLabelInput
                        placeholder={t("acquiringOnboarding.common.monthlyTurnover.inputPlaceholder")}
                        name={getFieldSelector("monthly")}
                        type="number"
                        innerRef={control.register(
                            valFuncRequiredAndPattern(
                                validationPatterns.inputNum,
                                t("acquiringOnboarding.common.monthlyTurnover.errorMessage")
                            )
                        )}
                    ></FloatingLabelInput>
                </FieldsetFormGroup>
            </div>

            {showDetailInput && (
                <>
                    {!showMainInput && (
                        <CompletedFieldsReview>
                            {t("acquiringOnboarding.common.monthlyTurnover.inputPlaceholder")}:{" "}
                            {savedFields?.transactionsCurrency || currentFields?.transactionsCurrency}{" "}
                            {getFormattedNumber(savedFields?.monthly || currentFields?.monthly || 0, {
                                numberFormat: "forceTwoDecimals",
                            })}
                        </CompletedFieldsReview>
                    )}

                    <FieldsetFormGroup className="mb-0" field>
                        <FloatingLabelInput
                            placeholder={t("acquiringOnboarding.common.transactionAmount.inputPlaceholder")}
                            name={getFieldSelector("transactionAmount")}
                            type="number"
                            innerRef={control.register(
                                valFuncRequiredAndPattern(
                                    validationPatterns.inputNum,
                                    t("acquiringOnboarding.common.transactionAmount.errorMessage")
                                )
                            )}
                        ></FloatingLabelInput>
                    </FieldsetFormGroup>
                </>
            )}

            {isSubmitted && (
                <CompletedFieldsReview>
                    {t("acquiringOnboarding.common.monthlyTurnover.inputPlaceholder")}: {savedFields?.transactionsCurrency}{" "}
                    {getFormattedNumber(savedFields?.monthly || 0, {
                        numberFormat: "forceTwoDecimals",
                    })}
                    <br />
                    {t("acquiringOnboarding.common.transactionAmount.inputPlaceholder")}: {savedFields?.transactionsCurrency}{" "}
                    {getFormattedNumber(savedFields?.transactionAmount || 0, {
                        numberFormat: "forceTwoDecimals",
                    })}
                </CompletedFieldsReview>
            )}
        </div>
    )
}
