import { IInvoicingPrice, getTextWithInvoicingPrices } from "swiipe.portal.shared"

export type TPspPriceType = "monthly" | "transaction"

export function getPspPriceHtml(priceType: TPspPriceType, prices: IInvoicingPrice[] | undefined) {
    return getTextWithInvoicingPrices(`psponboarding.prices.${priceType}`, prices, {
        pricePayment: { type: "SwiipePspTransactionBasic" }, // if transaction
        priceMonthly: { type: "SwiipePspMonthlyBasic" }, // if monthly
    })
}
