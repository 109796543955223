import htmlToReactParser from "html-react-parser"
import React, { useEffect, useRef } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    PageContainer,
    PageWrapper,
    ShowErrorMessages,
    StandardButton,
    TextButton,
    copyToClipboard,
    sharedConfigurationSelectors,
    useSelector,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import SubmitButton from "../../../components/buttons/SubmitButton"
import FixedLabelInput from "../../../components/form/input/FixedLabelInput"
import { TGoogleAnalyticsModalError } from "../../../components/modal/ModalGAOnboardingErrorDialog"
import TextTranslatedWithLinks from "../../../components/translated/TextTranslatedWithLinks"
import { getGoogleAnalyticsApiClientEmail, getGoogleAnalyticsGuideLink } from "../../../services/gaService"
import { getImage } from "../../../services/imageLanguageService"
import { useRequiredUserRelations } from "../../../services/userRelationService"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { connectToGoogleAnalyticsThunk, getGAWebshopPropertiesThunk } from "../../../store/thunks/googleAnalyticsThunks"
import { addModalThunk } from "../../../store/thunks/modalThunks"
import { setWebshopDetailsThunk, setWebshopFeaturesThunk } from "../../../store/thunks/webshopThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../../type/mixedmerchantdetails"
import "./AnalyticsOnboardingPage.scss"

interface IAnalyticsOnboardingPageProps {
    webshopIds: string[]
    onGoBack: () => Promise<void>
    onOnboarded: () => void
}
interface IAnalyticsOnboardingPageInnerProps extends IAnalyticsOnboardingPageProps {
    merchantDetails: MixedMerchantDetails
    webshopId: string
}

const AnalyticsOnboardingPage = (props: IAnalyticsOnboardingPageProps) => {
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    if (!props.webshopIds.length || !merchantDetails) {
        return null
    }

    return <AnalyticsOnboardingPageInner webshopId={props.webshopIds[0]} merchantDetails={merchantDetails} {...props} />
}

interface IGAOnboardingFormModel {
    propertyId: string
    streamId: string
}

const AnalyticsOnboardingPageInner = ({
    webshopId,
    merchantDetails,
    onGoBack,
    onOnboarded,
}: IAnalyticsOnboardingPageInnerProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])

    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const env = useSelector(sharedConfigurationSelectors.environment)
    const guideLink = getGoogleAnalyticsGuideLink()

    const emailInputRef = useRef<HTMLInputElement | null>()

    const { handleSubmit, register, setValue, formState } = useForm<IGAOnboardingFormModel>()
    const onSubmit: SubmitHandler<IGAOnboardingFormModel> = async (data) => {
        const merchantId = merchantDetails.swMerchant.swMerchantId
        //Try to establish connection with google api
        const connectionResult = await dispatch(
            connectToGoogleAnalyticsThunk(merchantId, webshopId, data.propertyId, data.streamId, false)
        )
        if (!connectionResult.connected) {
            const errorType: TGoogleAnalyticsModalError = connectionResult.error.includes("streamId")
                ? "streamError"
                : "propertyError"
            const errorModalResponse = await dispatch(
                addModalThunk({
                    type: "gaOnboardingErrorDialog",
                    errorType,
                })
            )
            if (
                errorType === "propertyError" || // can't proceed with propertyError
                errorModalResponse.type === "declined" ||
                errorModalResponse.type === "ignored"
            ) {
                return
            }
            // allow onboarding without any data present under selected stream (newly created GA account)
            const retryConnectionResult = await dispatch(
                connectToGoogleAnalyticsThunk(merchantId, webshopId, data.propertyId, data.streamId, true)
            )
            if (!retryConnectionResult.connected) {
                return
            }
        }
        //Set analytics provider
        await dispatch(setWebshopDetailsThunk(merchantId, [webshopId], [{ analyticsProvider: "GoogleAnalytics" }]))
        //Set feature enabled
        await dispatch(setWebshopFeaturesThunk([webshopId], "ExternalAnalytics", true, merchantId))
        onOnboarded()
    }

    useEffect(() => {
        ;(async () => {
            const properties = await dispatch(getGAWebshopPropertiesThunk(merchantDetails.swMerchant.swMerchantId, webshopId))
            if (!properties) {
                return
            }
            //for future use (if there will be edit/view details page)
            setValue("streamId", properties.streamId)
            setValue("propertyId", properties.propertyId)
        })()
    }, [webshopId])

    return (
        <>
            <PageWrapper>
                <PageContainer classNameBody="analytics-onboarding-page" transparent>
                    <div className="analytics-onboarding-page__wrapper-box">
                        <div className="analytics-onboarding-page__header">
                            <img src={getImage("googleAnalyticsLogo")} />
                            <p>
                                {t("analyticsOnboarding.configurationNeeded")} <br />
                                <TextTranslatedWithLinks
                                    textTranslationKey="analyticsOnboarding.guideLink"
                                    linkKeyInText="{{guideLink}}"
                                    linkTranslationKey="analyticsOnboarding.seeOurGuide"
                                    linkAddress={guideLink}
                                    openInNewTab
                                />
                            </p>
                        </div>
                    </div>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <InputBox
                            stepNumber="1."
                            inputsDescription={t("analyticsOnboarding.inputDescriptions.propertyId")}
                            guideLink={guideLink}
                        >
                            <FixedLabelInput
                                labelBold
                                label={t("analyticsOnboarding.inputLabels.propertyId")}
                                name="propertyId"
                                className="analytics-onboarding-page__input"
                                innerRef={register(
                                    valFuncRequiredAndPattern(
                                        validationPatterns.googleAnalyticsPropertyId,
                                        t("analyticsOnboarding.inputErrors.propertyId")
                                    )
                                )}
                            />
                        </InputBox>
                        <InputBox
                            stepNumber="2."
                            inputsDescription={t("analyticsOnboarding.inputDescriptions.streamId")}
                            guideLink={guideLink}
                        >
                            <FixedLabelInput
                                labelBold
                                label={t("analyticsOnboarding.inputLabels.streamId")}
                                name="streamId"
                                className="analytics-onboarding-page__input"
                                innerRef={register(
                                    valFuncRequiredAndPattern(
                                        validationPatterns.googleAnalyticsStreamId,
                                        t("analyticsOnboarding.inputErrors.streamId")
                                    )
                                )}
                            />
                        </InputBox>
                        <InputBox
                            stepNumber="3."
                            inputsDescription={t("analyticsOnboarding.inputDescriptions.apiMail")}
                            guideLink={guideLink}
                        >
                            <div className="analytics-onboarding-page__copy-row">
                                <FixedLabelInput
                                    label=""
                                    className="analytics-onboarding-page__copy-input"
                                    value={getGoogleAnalyticsApiClientEmail(env)}
                                    innerRef={(ref) => (emailInputRef.current = ref as HTMLInputElement)}
                                    readOnly
                                />
                                <StandardButton
                                    className="analytics-onboarding-page__copy-button"
                                    onClick={(e) => {
                                        e.preventDefault()

                                        if (emailInputRef.current) {
                                            copyToClipboard(emailInputRef.current)
                                        }
                                    }}
                                    title={t("common.copy")}
                                    dark
                                    noBorder
                                />
                            </div>
                        </InputBox>

                        <InputBox
                            stepNumber=""
                            bottomElement={
                                <>
                                    <SubmitButton
                                        dark
                                        noBorder
                                        containerClass="analytics-onboarding-page__submit-btn"
                                        formState={formState}
                                    >
                                        {t("commononboardingtexts.submit")}
                                    </SubmitButton>
                                    <TextButton text={t("commononboardingtexts.goback")} onClick={onGoBack} />
                                </>
                            }
                        >
                            <ShowErrorMessages formState={formState} />
                        </InputBox>
                    </Form>
                </PageContainer>
            </PageWrapper>
        </>
    )
}

interface IInputBoxProps {
    stepNumber: string
    inputsDescription?: string
    guideLink?: string
    children?: React.ReactNode
    bottomElement?: JSX.Element
}
const InputBox = ({ stepNumber, inputsDescription, guideLink, children, bottomElement }: IInputBoxProps) => {
    const { t } = useTranslation()

    return (
        <div className="analytics-onboarding-page__wrapper-box">
            <div className="analytics-onboarding-page__step">
                <div className="analytics-onboarding-page__step-number">{stepNumber}</div>
                <div className="inputs">
                    {inputsDescription && (
                        <p className="analytics-onboarding-page__step-description">{htmlToReactParser(inputsDescription)}</p>
                    )}

                    {children}

                    {guideLink && (
                        <TextButton
                            color="grey"
                            textDecoration="underline"
                            width="fit-content"
                            text={t("analyticsOnboarding.seeOurGuide")}
                            onClick={async () => {
                                window.open(guideLink, "_blank")
                            }}
                        />
                    )}
                </div>
            </div>
            {bottomElement}
        </div>
    )
}

export default AnalyticsOnboardingPage
