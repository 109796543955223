import { t } from "i18next"
import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { getText, requestThunk, userSelectors } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { getA2aAccountsModel, tryGetResolvedCountryCode } from "../../services/a2aService"
import { navigationService } from "../../services/navigationService"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { IA2aUserAccount } from "../../type/a2a/IA2aUserAccount"
import { gatewaySelectors } from "../reducers/gatewayReducer"
import { IA2aMerchantRelatedAccounts } from "./../../type/a2a/IA2aMerchantRelatedAccounts"
import { StoreState } from "./../StoreState"
import { a2aReducerActions, a2aSelectors } from "./../reducers/a2aReducer"
import { getGatewaysForMerchantThunk } from "./gatewayThunks"
import { getOnboardingStatusThunk } from "./swiipePaymentsThunks"
import { sendServiceConfirmationEmailThunk } from "./swiipeServicesThunks"

export const getMerchantA2aAccountConnectUrlThunk =
    (
        swMerchantId: string,
        webshopId: string,
        countryCodes: string[]
    ): ThunkAction<Promise<string>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const resp = await dispatch(
            requestThunk<{ redirectUrl: string }>(endpoints.A2A.connectA2aAccounts, {
                data: {
                    swMerchantId: swMerchantId,
                    redirectUrl: document.location.origin + "/account_to_account_details",
                    webshopId: webshopId,
                    countryCodes: countryCodes,
                },
            })
        )
        return resp.redirectUrl
    }

export const initA2aAccountsOverviewThunk =
    (
        swMerchantId: string,
        webshopId: string,
        overwriteCountryCode?: string
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await Promise.all([
            dispatch(getGatewaysForMerchantThunk(swMerchantId, false, true)),
            dispatch(getA2aMerchantRelatedAccountsThunk(swMerchantId, false)),
        ])

        const accounts = a2aSelectors.accounts(getState(), swMerchantId)
        if (!accounts || accounts.length === 0) {
            return
        }

        const userData = userSelectors.userData(getState())
        const gateway = gatewaySelectors.getGateway(getState(), swMerchantId, "AccountToAccount", webshopId)
        const resolvedCountryCode = tryGetResolvedCountryCode(gateway, overwriteCountryCode)

        if (!userData?.user.id || !resolvedCountryCode) {
            return
        }

        const { mainAccounts, otherAccounts, unsupportedAccounts, selectedAccountForWebshop } = getA2aAccountsModel({
            userId: userData.user.id,
            webshopId: webshopId,
            expectedCountryCode: resolvedCountryCode,
            accounts,
        })

        if (selectedAccountForWebshop) {
            return
        }

        if (mainAccounts.length + otherAccounts.length != 1) {
            // Auto onboarding is not supported when having multiple accounts to choose from or if no accounts is supported
            return
        }

        const account = [...mainAccounts, ...otherAccounts][0]
        await dispatch(a2aAutoOnboardSingleAccountThunk(account, swMerchantId, webshopId, resolvedCountryCode))
        navigationService.navigate("/account_to_account_details")
    }

export const getA2aMerchantRelatedAccountsThunk =
    (swMerchantId: string, force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force) {
            const userAccounts = a2aSelectors.accounts(getState(), swMerchantId)
            if (userAccounts) {
                return
            }
        }
        const resp = await dispatch(
            requestThunk<IA2aMerchantRelatedAccounts>(endpoints.A2A.merchantRelatedAccounts, {
                params: {
                    swMerchantId: swMerchantId,
                },
            })
        )
        dispatch(a2aReducerActions.setMerchantAccounts(swMerchantId, resp.userAccounts))
    }

export const setA2aWebshopPaymentsAccountThunk =
    (
        swMerchantId: string,
        webshopId: string,
        userAccountId: string,
        countryCodes: string[]
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk(endpoints.A2A.webshopPaymentsAccount, {
                data: {
                    swMerchantId: swMerchantId,
                    webshopId: webshopId,
                    userAccountId: userAccountId,
                    countryCodes: countryCodes,
                },
            })
        )
        await dispatch(getA2aMerchantRelatedAccountsThunk(swMerchantId, true))
    }

export const deleteA2aWebshopAccountThunk =
    (swMerchantId: string, webshopId: string, userAccountId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const modalAnswer = await dispatch(
            addModalThunk({
                type: "confirmDangerousAction",
                title: getText("a2aonboarding.deleteAccount.deletePrompt"),
                promptText: getText("a2aonboarding.deleteAccount.deleteContext"),
                customSubmitText: getText("common.yesdelete"),
                customCancelText: getText("common.nogoback"),
            })
        )

        if (modalAnswer.type !== "accepted") {
            return
        }

        await dispatch(
            requestThunk(endpoints.A2A.deleteWebshopPaymentsAccount, {
                data: {
                    swMerchantId: swMerchantId,
                    webshopId: webshopId,
                    userAccountId: userAccountId,
                },
            })
        )
        await dispatch(getA2aMerchantRelatedAccountsThunk(swMerchantId, true))
    }
export const a2aDisableOnboardingThunk =
    (swMerchantId: string, webshopId: string, gatewayId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(setA2aGatewayEnableStatusThunk(false, swMerchantId, webshopId, gatewayId))
    }

export const a2aEnableOnboardingThunk =
    (swMerchantId: string, webshopId: string, gatewayId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(setA2aGatewayEnableStatusThunk(true, swMerchantId, webshopId, gatewayId))
    }

export const a2aAutoOnboardSingleAccountThunk =
    (
        usersAccount: IA2aUserAccount,
        swMerchantId: string,
        webshopId: string,
        countryCode: string
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(setA2aWebshopPaymentsAccountThunk(swMerchantId, webshopId, usersAccount.userAccountId, [countryCode]))
        await dispatch(sendServiceConfirmationEmailThunk(swMerchantId, "AccountToAccountConfirmation", t("serviceNames.a2a")))
    }

const setA2aGatewayEnableStatusThunk =
    (
        isEnabled: boolean,
        swMerchantId: string,
        webshopId: string,
        gatewayId: string
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk(endpoints.Payments.patchGateway(gatewayId), {
                data: {
                    webshopId,
                    paymentType: "AccountToAccount",
                    enabled: isEnabled,
                },
            })
        )

        await dispatch(getGatewaysForMerchantThunk(swMerchantId, true))
        await dispatch(getOnboardingStatusThunk(swMerchantId, webshopId, true, ["AccountToAccount"]))
    }

export const ensureA2aGatewayThunk =
    (
        swMerchantId: string,
        webshopId: string,
        gatewayId: string,
        countryCodes: string[]
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.A2A.gatewayDetailsEnsure, {
                data: {
                    swMerchantId,
                    webshopId,
                    gatewayId,
                    countryCodes,
                },
            })
        )

        await dispatch(getGatewaysForMerchantThunk(swMerchantId, true))
        await dispatch(getOnboardingStatusThunk(swMerchantId, webshopId, true, ["AccountToAccount"]))
    }
