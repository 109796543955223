import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { TPayment, allPaymentTypes, getText, piqPaymentTypes, requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { IBasePaymentOnboardingData } from "../../type/IBasePaymentOnboardingData"
import { StoreState } from "../StoreState"
import {
    IPaymentIqMerchantData,
    IWebshopProviderConfig,
    merchantServicesReducerActions,
    merchantServicesSelectors,
} from "../reducers/merchantServicesReducer"
import { IGatewayProvider, TProviderName, TSwiipePlan } from "./../../type/IProvider"
import { onboardClearhausForPaymentTypeThunk } from "./clearhausThunks"
import { getGatewaysForMerchantThunk } from "./gatewayThunks"
import { fetchSwiipePlanThunk } from "./merchantThunks"
import { sendServiceCancellationEmailThunk, sendServiceConfirmationEmailThunk } from "./swiipeServicesThunks"
import { markTermsAsAcceptedThunk } from "./termsThunks"

export const chooseSwiipePspPlanThunk =
    (
        swMerchantId: string | undefined,
        currentPlan: TSwiipePlan | undefined,
        planType: TSwiipePlan
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        if (!swMerchantId) {
            return
        }

        await dispatch(markTermsAsAcceptedThunk(["TermsPSP"]))

        if (currentPlan !== planType) {
            await dispatch(
                requestThunk<void>(endpoints.PaymentIQ.chooseSwiipePspPlan, {
                    data: {
                        swMerchantId: swMerchantId,
                        planType: planType,
                    },
                })
            )

            if (currentPlan === TSwiipePlan.None || !currentPlan) {
                await dispatch(
                    sendServiceConfirmationEmailThunk(swMerchantId, "PspBasicConfirmation", getText("serviceNames.pspstandard"))
                )
            } else if (planType == TSwiipePlan.None) {
                await dispatch(
                    sendServiceCancellationEmailThunk(swMerchantId, "PspCancellationEmail", getText("serviceNames.pspstandard"))
                )
            }

            await dispatch(fetchSwiipePlanThunk(swMerchantId))
        }
    }

export const setPiqOnboardingStatusThunk =
    (
        swMerchantId: string,
        webshopIds: string[],
        gatewayProvider: IGatewayProvider
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<any>(endpoints.PaymentIQ.setOnboardingStatus, {
                data: {
                    merchantId: swMerchantId,
                    webshopIds: webshopIds,
                    gatewayProvider,
                },
            })
        )

        for (let i = 0; i < webshopIds.length; i++) {
            await dispatch(getOnboardingStatusThunk(swMerchantId, webshopIds[i], true))
        }
    }

export const getOnboardingStatusThunk =
    (
        swMerchantId: string | undefined,
        webshopId: string,
        force: boolean,
        limitToPaymentTypes?: TPayment[]
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!swMerchantId || !webshopId) {
            return
        }

        const status = merchantServicesSelectors.statuses(getState(), swMerchantId, webshopId)

        if (!limitToPaymentTypes) {
            limitToPaymentTypes = [...allPaymentTypes]
        }

        const shouldFetchPiqStatus = !!limitToPaymentTypes.find((pt) => piqPaymentTypes.includes(pt))
        const isPiqStatusFetched = merchantServicesSelectors.isPiqStatusFetched(getState(), webshopId)
        if (!status || (shouldFetchPiqStatus && (force || !isPiqStatusFetched))) {
            const newStatus = await dispatch(
                requestThunk<IWebshopProviderConfig>(endpoints.PaymentIQ.getOnboardingStatus, {
                    data: { webshopId },
                })
            )

            dispatch(merchantServicesReducerActions.setPiqStatusesFetched(webshopId))

            await dispatch(
                merchantServicesReducerActions.setWebshopServices(swMerchantId, webshopId, {
                    ...newStatus,
                    isExtended: false,
                })
            )
        }

        await dispatch(getGatewaysForMerchantThunk(swMerchantId, force, false))
    }

export interface PiqDuplicatesDto {
    wsNamesMerchantIdDuplicates: string[]
    wsNamesClientIdDuplicates: string[]
}

export const checkPiqDuplicatesThunk =
    (
        swMerchantId: string,
        webshopId: string,
        piqMerchantId?: string,
        piqClientId?: string
    ): ThunkAction<Promise<PiqDuplicatesDto>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const result = await dispatch(
            requestThunk<PiqDuplicatesDto>(endpoints.PaymentIQ.checkPiqDuplicates, {
                data: {
                    swMerchantId,
                    webshopId,
                    piqMerchantId,
                    piqClientId,
                },
            })
        )

        return result
    }

export const getExtendedOnboardingStatusesThunk =
    (swMerchantId: string, webshopId: string, force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!swMerchantId || !webshopId) {
            return
        }
        if (!force) {
            const status = merchantServicesSelectors.statuses(getState(), swMerchantId, webshopId)
            if (status && status.isExtended) {
                return
            }
        }

        const webshopServicesStatus = await dispatch(
            requestThunk<IWebshopProviderConfig>(endpoints.PaymentIQ.getOnboardingStatuses, {
                data: { webshopId },
            })
        )

        await dispatch(
            merchantServicesReducerActions.setWebshopServices(swMerchantId, webshopId, {
                ...webshopServicesStatus,
                isExtended: true,
            })
        )
    }
export interface ISetOnboardingStatusesDto {
    webshopId: string
    copyFromWebshopId: string
    paymentIqData: IPaymentIqMerchantData
    gatewayProviders: IGatewayProvider[]
}

export const setExtendedOnboardingStatusesThunk =
    (data: ISetOnboardingStatusesDto, swMerchantId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<any>(endpoints.PaymentIQ.setOnboardingStatuses, {
                data: data,
            })
        )

        dispatch(getExtendedOnboardingStatusesThunk(swMerchantId, data.webshopId, true))
    }

export const sandboxOnboardingThunk =
    (
        swMerchantId: string | undefined,
        webshopId: string,
        providerName: TProviderName
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.PaymentIQ.sandboxOnboarding, {
                data: {
                    webshopId: webshopId,
                    configs: [{ providerName }],
                },
            })
        )
        await dispatch(getOnboardingStatusThunk(swMerchantId, webshopId, true))
    }

// PAYMENT METHOD ONBOARDINGS

export interface IDankortOnboardingData extends IBasePaymentOnboardingData {
    dankortId: string
}

export const dankortOnboardingThunk =
    (data: IDankortOnboardingData): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        if (!data.webshopIds.length) {
            return
        }

        await dispatch(
            requestThunk<void>(endpoints.PaymentIQ.createDankortOnboarding, {
                data: data,
            })
        )

        await dispatch(sendServiceConfirmationEmailThunk(data.swMerchantId, "DankortConfirmation", "Dankort"))

        for (let i = 0; i < data.webshopIds.length; i++) {
            await dispatch(getOnboardingStatusThunk(data.swMerchantId, data.webshopIds[i], true, ["CreditCard"]))
        }
    }

export const getDankortOnboardingData =
    (webshopId: string, merchantId: string): ThunkAction<Promise<string>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const response = await dispatch(
            requestThunk<{ dankortId: string }>(endpoints.PaymentIQ.getDankortOnboardingData, {
                params: {
                    webshopId: webshopId,
                    swMerchantId: merchantId,
                },
            })
        )

        return response.dankortId
    }

export interface ISwishOnboardingData extends IBasePaymentOnboardingData {
    swishId: string
    swishCertificate: string
}

export const swishOnboardingThunk =
    (data: ISwishOnboardingData, isEdit: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        if (!data.webshopIds.length) {
            return
        }

        await dispatch(
            requestThunk<void>(endpoints.PaymentIQ.createSwishOnboarding, {
                data: data,
            })
        )

        if (!isEdit) {
            await dispatch(sendServiceConfirmationEmailThunk(data.swMerchantId, "SwishConfirmation", "Swish"))
        }

        for (let i = 0; i < data.webshopIds.length; i++) {
            await dispatch(getOnboardingStatusThunk(data.swMerchantId, data.webshopIds[i], true, ["Swish"]))
        }
    }

export const getSwishOnboardingDataThunk =
    (
        webshopId: string,
        merchantId: string
    ): ThunkAction<Promise<{ swishId: string; swishCsrPem: string }>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const response = await dispatch(
            requestThunk<{ swishId: string; swishCsrPem: string }>(endpoints.PaymentIQ.getSwishOnboardingData, {
                params: {
                    webshopId: webshopId,
                    swMerchantId: merchantId,
                },
            })
        )

        return response
    }

export const createSwishCsrThunk =
    (
        webshopId: string,
        merchantId: string,
        swishId: string
    ): ThunkAction<Promise<{ swishCsrPem: string }>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const response = await dispatch(
            requestThunk<{ swishCsrPem: string }>(endpoints.PaymentIQ.createSwishCsr, {
                data: {
                    swMerchantId: merchantId,
                    webshopId: webshopId,
                    swishId: swishId,
                },
            })
        )
        return response
    }

export interface IForbrugsforeningenOnboardingData extends IBasePaymentOnboardingData {
    netsMerchantId: string
}

export const forbrugsforeningenOnboardingThunk =
    (data: IForbrugsforeningenOnboardingData): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        if (!data.webshopIds.length) {
            return
        }

        await dispatch(
            requestThunk<void>(endpoints.PaymentIQ.createForbrugsforeningenOnboarding, {
                data: data,
            })
        )

        await dispatch(
            sendServiceConfirmationEmailThunk(
                data.swMerchantId,
                "ForbrugsforeningenConfirmation",
                getText("serviceNames.forbrugsforeningen")
            )
        )

        for (let i = 0; i < data.webshopIds.length; i++) {
            await dispatch(getOnboardingStatusThunk(data.swMerchantId, data.webshopIds[i], true, ["CreditCard"]))
        }
    }

export const getForbrugsforeningenOnboardingData =
    (webshopId: string, merchantId: string): ThunkAction<Promise<{ netsMerchantId: string }>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const response = await dispatch(
            requestThunk<{ netsMerchantId: string }>(endpoints.PaymentIQ.getForbrugsforeningenOnboardingData, {
                params: {
                    webshopId: webshopId,
                    swMerchantId: merchantId,
                },
            })
        )

        return response
    }

export interface IVippsOnboardingData extends IBasePaymentOnboardingData {
    vippsId: string
    vippsClientId: string
    vippsClientSecret: string
    vippsSubscriptionKey: string
}

export const vippsOnboardingThunk =
    (data: IVippsOnboardingData, isEdit: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        if (!data.webshopIds.length) {
            return
        }

        await dispatch(
            requestThunk<void>(endpoints.PaymentIQ.createVippsOnboarding, {
                data: data,
            })
        )
        await dispatch(onboardClearhausForPaymentTypeThunk(data.swMerchantId, data.webshopIds, "Vipps"))

        if (!isEdit) {
            await dispatch(sendServiceConfirmationEmailThunk(data.swMerchantId, "VippsConfirmation", "Vipps"))
        }

        for (let i = 0; i < data.webshopIds.length; i++) {
            await dispatch(getOnboardingStatusThunk(data.swMerchantId, data.webshopIds[i], true, ["Vipps"]))
        }
    }

export const getVippsOnboardingDataThunk =
    (webshopId: string, merchantId: string): ThunkAction<Promise<{ vippsId: string }>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const response = await dispatch(
            requestThunk<{ vippsId: string }>(endpoints.PaymentIQ.getVippsOnboardingData, {
                params: {
                    webshopId: webshopId,
                    swMerchantId: merchantId,
                },
            })
        )
        return response
    }

export interface IKlarnaOnboardingData extends IBasePaymentOnboardingData {
    klarnaId: string
    klarnaKey: string
}

export const klarnaOnboardingThunk =
    (data: IKlarnaOnboardingData, isEdit: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        if (!data.webshopIds.length) {
            return
        }

        await dispatch(
            requestThunk<void>(endpoints.PaymentIQ.createKlarnaOnboarding, {
                data: data,
            })
        )

        if (!isEdit) {
            await dispatch(sendServiceConfirmationEmailThunk(data.swMerchantId, "KlarnaConfirmation", "Klarna"))
        }

        for (let i = 0; i < data.webshopIds.length; i++) {
            await dispatch(
                getOnboardingStatusThunk(data.swMerchantId, data.webshopIds[i], true, ["KlarnaFinancing", "KlarnaPayLater"])
            )
        }
    }

export const getKlarnaOnboardingDataThunk =
    (webshopId: string, merchantId: string): ThunkAction<Promise<{ klarnaId: string }>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const response = await dispatch(
            requestThunk<{ klarnaId: string }>(endpoints.PaymentIQ.getKlarnaOnboardingData, {
                params: {
                    webshopId: webshopId,
                    swMerchantId: merchantId,
                },
            })
        )
        return response
    }
