import React from "react"
import { PageWrapper } from "swiipe.portal.shared"
import CloseMerchantAccountComponent from "../../components/merchant/CloseMerchantAccountComponent"
import { useRequiredUserRelations } from "../../services/userRelationService"

interface IMerchantSettingsPage {}

const MerchantSettingsPage = ({}: IMerchantSettingsPage) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])

    return (
        <PageWrapper>
            <CloseMerchantAccountComponent />
        </PageWrapper>
    )
}

export default MerchantSettingsPage
