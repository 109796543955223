import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { Alert } from "reactstrap"
import {
    PageContainer,
    PageWrapper,
    StandardButton,
    TextButton,
    getCountryByISO,
    organizationSelectors,
    useSelector,
} from "swiipe.portal.shared"
import SwiipeAcquiringLogoImage from "../../images/swiipe-logo.png"
import { openContactUsWidget } from "../../services/contactUsService"
import { navigationService } from "../../services/navigationService"
import { StoreState } from "../../store/StoreState"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import FormHeaderLogoContainer from "../form/title/FormHeaderLogoContainer"
import "./CvrNotFoundDialog.scss"

interface ICvrNotFoundDialog {
    merchantDetails: MixedMerchantDetails
    onGoBack?: () => void
}

const CvrNotFoundDialog = ({ merchantDetails, onGoBack }: ICvrNotFoundDialog) => {
    const { t } = useTranslation()

    const isCvrNotFound = useSelector<StoreState, boolean | undefined>((state) =>
        organizationSelectors.isCvrNotFound(state, merchantDetails.swMerchant.registrationNumber)
    )

    if (!isCvrNotFound) {
        return null
    }

    return (
        <>
            <PageWrapper>
                <PageContainer id="cvr-not-found-container" transparent>
                    <div className="message-container">
                        <FormHeaderLogoContainer
                            mainLogo={{ src: SwiipeAcquiringLogoImage, alt: "swiipe-acquiring-logo", width: "140px" }}
                        />
                        <div className="alert-container">
                            <p className="text-normal text-center mt-4 mb-1">
                                <strong>{t("cvrNotFoundDialog.message1")}</strong>
                            </p>
                            <p className="text-center text-larger">
                                {t("cvrNotFoundDialog.message2", {
                                    countryName: getCountryByISO(merchantDetails.swMerchant.countryCode),
                                })}
                            </p>

                            <Alert isOpen={true} className="company-info mb-3 text-center" color="dark">
                                <p className="mb-0">{merchantDetails?.swMerchant.name}</p>
                                <p className="mb-0">{merchantDetails?.swMerchant.registrationNumber}</p>
                            </Alert>

                            <StandardButton dark noBorder noBorderRadius className="mt-4" onClick={openContactUsWidget}>
                                {t("cvrNotFoundDialog.contactUs")}
                            </StandardButton>

                            <TextButton
                                onClick={async () => (onGoBack ? onGoBack() : navigationService.navigate("/plugins"))}
                                text={t("commononboardingtexts.goback")}
                                className={cn("mt-3 mb-2")}
                            />
                        </div>
                    </div>
                </PageContainer>
            </PageWrapper>
        </>
    )
}

export default CvrNotFoundDialog
