import React from "react"
import cn from "classnames"
import "./TextBoxWithStatesContainer.scss"

interface ITextBoxWithStatesContainerProps {
    className?: string
    text: string
    children?: React.ReactNode
    error?: boolean
    warning?: boolean
    info?: boolean
    centerText?: boolean
}

const TextBoxWithStatesContainer = ({
    className,
    error,
    warning,
    centerText,
    info,
    text,
    children,
}: ITextBoxWithStatesContainerProps) => {
    return (
        <div
            className={cn(
                className,
                "textbox-container",
                info && "textbox-container--info",
                warning && "textbox-container--warning",
                error && "textbox-container--error",
                centerText && "textbox-container--center-text"
            )}
        >
            <span className={cn("textbox-container__content")}>{text}</span>
            {children}
        </div>
    )
}

export default TextBoxWithStatesContainer
