import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { IInvoicingPrice, createUrlThunk, downloadBlob, openUrl, requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { getFormattedDate } from "../../services/dateTimeService"
import { mapUserRelationTypeToInvoicingRelationType } from "../../services/invoicingService"
import { mapUserRelationTypeToOrganizationType } from "../../services/userRelationService"
import { IInvoicingOrganizationInfoForm } from "../../type/IInvoicingOrganizationInfoForm"
import { IInvoiceSimple } from "../../type/billing/IInvoice"
import { IInvoicingOrganization } from "../../type/billing/IInvoicingOrganization"
import { StoreState } from "../StoreState"
import { userRelationSelectors } from "../reducers/userRelationReducer"
import { billingReducerActions, billingSelectors } from "./../reducers/billingReducer"

export const fetchSimpleInvoicesThunk =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType === "User") {
            return
        }

        if (!force && billingSelectors.simpleInvoices(getState())) {
            return
        }

        const resp = await dispatch(
            requestThunk<{ invoices: IInvoiceSimple[] }>(endpoints.Invoicing.getInvoicesBookedSimple, {
                params: {
                    organizationId: currentUserRelation.id,
                    organizationType: mapUserRelationTypeToOrganizationType(currentUserRelation.relationType),
                },
            })
        )

        dispatch(billingReducerActions.setSimpleInvoices(currentUserRelation.id, resp.invoices))
    }

export const fetchInvoicingOrganizationThunk =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType === "User") {
            return
        }

        if (!force && billingSelectors.invoicingOrganization(getState())) {
            return
        }

        const resp = await dispatch(
            requestThunk<{ organization: IInvoicingOrganization }>(endpoints.Invoicing.getOrganization, {
                params: {
                    organizationId: currentUserRelation.id,
                    organizationType: mapUserRelationTypeToOrganizationType(currentUserRelation.relationType),
                },
            })
        )

        dispatch(billingReducerActions.setInvoicingOrganization(currentUserRelation.id, resp.organization))
    }

export const updateInvoicingOrganizationInfoThunk =
    (form: IInvoicingOrganizationInfoForm): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType === "User") {
            return
        }

        await dispatch(
            requestThunk<{ organization: IInvoicingOrganization }>(endpoints.Invoicing.patchOrganization, {
                data: {
                    organization: {
                        organizationId: currentUserRelation.id,
                        organizationType: mapUserRelationTypeToOrganizationType(currentUserRelation.relationType),
                        attentionName: form.attentionName,
                        billingEmail: form.billingEmail,
                        companyName: form.companyName,
                        address: form.address,
                        postCode: form.postCode,
                        city: form.city,
                        region: form.region,
                        // country should not be set - invoicing country must be country of merchant
                    },
                },
            })
        )

        await dispatch(fetchInvoicingOrganizationThunk(true))
    }

export const updateInvoicingEanThunk =
    (ean: string, eInvoiceId: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType === "User") {
            return
        }

        await dispatch(
            requestThunk<{ organization: IInvoicingOrganization }>(endpoints.Invoicing.patchOrganization, {
                data: {
                    organization: {
                        organizationId: currentUserRelation.id,
                        organizationType: mapUserRelationTypeToOrganizationType(currentUserRelation.relationType),
                        ean: ean,
                        eInvoiceId: eInvoiceId,
                    },
                },
            })
        )

        await dispatch(fetchInvoicingOrganizationThunk(true))
    }

export const downloadInvoicePdfThunk =
    (invoice: IInvoiceSimple, showInNewTab: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType === "User") {
            return
        }

        const params = {
            invoiceId: invoice.id,
            organizationId: currentUserRelation.id,
            organizationType: mapUserRelationTypeToOrganizationType(currentUserRelation.relationType),
        }

        if (showInNewTab) {
            const url = await dispatch(createUrlThunk(endpoints.Invoicing.getInvoicePdf, params))
            openUrl(url, { inNewTab: true, withFocus: true })
            return
        }

        const resp = await dispatch(
            requestThunk(endpoints.Invoicing.getInvoicePdf, {
                params: params,
                responseType: "blob",
            })
        )

        const blob = new Blob([resp as string], {
            type: "application/pdf;charset=utf-8;",
        })

        downloadBlob(blob, "Invoice_Swiipe_" + getFormattedDate(invoice.from, "slashes-month-year", true))
    }

export const fetchInvoicingPricesThunk =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType !== "Merchant") {
            return
        }

        const resolvedPrices = billingSelectors.resolvedPrices(getState())
        if (!force && resolvedPrices) {
            return
        }

        const resp = await dispatch(
            requestThunk<{ custom: IInvoicingPrice[]; resolvedPrices: IInvoicingPrice[]; prices: IInvoicingPrice[] }>(
                endpoints.Invoicing.getPrices,
                {
                    params: {
                        invoicingRelationId: currentUserRelation.id,
                        invoicingRelationType: mapUserRelationTypeToInvoicingRelationType(currentUserRelation.relationType),
                    },
                }
            )
        )

        const currency = resp.resolvedPrices.find((p) => !!p.currency)?.currency
        dispatch(billingReducerActions.setOrganizationCurrency(currency ?? "EUR", currentUserRelation.id))
        dispatch(billingReducerActions.setInvoicingResolvedPrices(currentUserRelation.id, resp.resolvedPrices))
    }
