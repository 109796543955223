import { getText } from "../locale/lang"
import { ReduxDispatch } from "../store/useReduxDispatchShared"
import { clearAllCompanySearchResultsThunk, lookupByNameCvrOrVatThunk } from "../thunks/companyThunks"
import { TCountryCode } from "../types/general/countryCodes"
import { validationPatterns, validationPatternsWithLocale } from "../utils/validationUtil"

export function isLookupSupported(country: TCountryCode) {
    const availableForRegisterWithoutLookupSupport: TCountryCode[] = ["CH", "GB", "US", "NO"]
    return !availableForRegisterWithoutLookupSupport.includes(country)
}

export function getCompanyNumberRaw(companyNumber: string, country: TCountryCode) {
    companyNumber = companyNumber.replace(country, "")

    if (country === "CH") {
        companyNumber = companyNumber.replace("E-", "").replaceAll(".", "")
    }

    return companyNumber
}

export function getSearchBarPlaceholder(country: TCountryCode) {
    return getText(`companysearch.searchBarPlaceholder.${country === "DK" ? "companyDK" : "companyNonDK"}`)
}

export function getCompanyNumberPlaceholder(country: TCountryCode | "other") {
    return getText(`companysearch.companyNumber.${country === "DK" ? "companyDK" : "companyNonDK"}`)
}

export function getSearchBarOptions(
    vat: string | undefined,
    country: TCountryCode
): { usingCvrApi: boolean; isVatSameWithSelectedCountry: boolean; isValidVat: boolean } {
    const vatRegex = validationPatternsWithLocale.cvr(country)

    const usingCvrApi = country === "DK"
    //exception for sweden because we support search by "organization number"
    //it has no country code in front ("XXXXXX-XXXX(01)?" or "XXXXXXXXXX(01)?" format)
    const isVatSameWithSelectedCountry =
        (validationPatterns.companyNumberSweden.test(vat ?? "") && country === "SE") || vat?.substring(0, 2) === country
    const isValidVat = vatRegex.test(vat ?? "")

    return {
        usingCvrApi,
        isVatSameWithSelectedCountry,
        isValidVat,
    }
}

export function searchForCompany(
    query: string,
    country: TCountryCode,
    dispatch: ReduxDispatch,
    actions: {
        setVatNotCorrespondsToCountryError: () => void
        clearError: () => void
        lookupErrorCallback: (notFound: boolean) => void
    }
) {
    if (query === "") {
        setTimeout(() => {
            dispatch(clearAllCompanySearchResultsThunk())
        }, 400)
        return
    }

    const options = getSearchBarOptions(query, country)
    if (!options.usingCvrApi && !options.isValidVat) {
        return
    }

    if (!options.isVatSameWithSelectedCountry && !options.usingCvrApi) {
        actions.setVatNotCorrespondsToCountryError()
        return
    }

    actions.clearError()
    dispatch(lookupByNameCvrOrVatThunk(country, formatSearchQuery(country, query), actions.lookupErrorCallback))
}

function formatSearchQuery(country: string, query: string) {
    switch (country) {
        case "SE": {
            if (!validationPatterns.companyNumberSweden.test(query)) {
                return query
            }

            const swedishCompanyNumber = query.replace("-", "")
            const vatNumberFormatted =
                swedishCompanyNumber.length === 10
                    ? `SE${swedishCompanyNumber.replace("-", "")}01`
                    : `SE${swedishCompanyNumber.replace("-", "")}`

            return vatNumberFormatted
        }
        default:
            return query
    }
}
