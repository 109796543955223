import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PageContainer, PageWrapper, SpinnerContainer, TextButton, useSelector, userSelectors } from "swiipe.portal.shared"
import FormHeaderLogoContainer from "../../components/form/title/FormHeaderLogoContainer"
import CancelService from "../../components/merchant/CancelService"
import GoBackContainer from "../../components/navigations/GoBackContainer"
import ResursDetailsStep from "../../components/paymentsOnboarding/resurs/ResursDetailsStep"
import ResursNotReadyStep from "../../components/paymentsOnboarding/resurs/ResursNotReadyStep"
import ResursSelectStoreStep from "../../components/paymentsOnboarding/resurs/ResursSelectStoreStep"
import { getImage } from "../../services/imageLanguageService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { resursSelectors } from "../../store/reducers/resursReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { getGatewaysForMerchantThunk } from "../../store/thunks/gatewayThunks"
import { cancelResursForWebshopThunk, getResursStoresForForMerchantThunk } from "../../store/thunks/resursThunks"
import { sendServiceCancellationEmailThunk } from "../../store/thunks/swiipeServicesThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import { IResursStore } from "../../type/resurs/IResursStore"
import "./ResursOnboardingPage.scss"

interface IResursOnboardingPageProps {
    webshopIds?: string[]
    isEdit?: boolean
    onGoBack: () => void
}

interface IResursOnboardingPageInnerProps extends IResursOnboardingPageProps {
    merchantDetails: MixedMerchantDetails
    selectedWebshopId: string
    resursStores: IResursStore[]
    initialStep: number
}

const ResursOnboardingPage = (props: IResursOnboardingPageProps) => {
    const dispatch = useReduxDispatch()
    useRequiredUserRelations([{ requiredType: "Merchant" }])

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const userData = useSelector(userSelectors.userData)
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)!
    const merchantId = currentUserRelation.id

    const selectedWebshopIdFromStore = useSelector(merchantSelectors.selectedWebshopId)
    const selectedWebshopId = props.webshopIds && props.webshopIds?.length > 0 ? props.webshopIds[0] : selectedWebshopIdFromStore
    const [initialStep, setInitialStep] = useState(-1)

    const resursStores: IResursStore[] | undefined = useSelector((state: StoreState) =>
        resursSelectors.resursStores(state, merchantId)
    )

    useEffect(() => {
        if (!merchantDetails || !selectedWebshopId) {
            return
        }
        dispatch(getResursStoresForForMerchantThunk(merchantDetails.swMerchant.swMerchantId, selectedWebshopId))
        dispatch(getGatewaysForMerchantThunk(merchantDetails.swMerchant.swMerchantId, false, true))
    }, [merchantDetails, selectedWebshopId])

    useEffect(() => {
        if (resursStores === undefined) {
            setInitialStep(-1)
            return
        }

        if (props.isEdit) {
            setInitialStep(2)
            return
        }

        setInitialStep(resursStores.length == 0 ? 0 : 1)
    }, [resursStores])

    if (!merchantDetails || !userData || !merchantDetails.webshops.length || !selectedWebshopId) {
        return null
    }

    return (
        <ResursOnboardingPageInner
            merchantDetails={merchantDetails}
            selectedWebshopId={selectedWebshopId}
            resursStores={resursStores}
            initialStep={initialStep}
            {...props}
        />
    )
}

const ResursOnboardingPageInner = ({
    merchantDetails,
    selectedWebshopId,
    resursStores,
    isEdit,
    initialStep,
    onGoBack,
}: IResursOnboardingPageInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const merchantId = merchantDetails.swMerchant.swMerchantId
    const [showCancelService, setShowCancelService] = useState(false)

    const getComponentToShow = () => {
        switch (initialStep) {
            case -1: {
                return <SpinnerContainer className="resurs-onboarding__preloading-container" showSpinner={true} />
            }
            case 0: {
                return <ResursNotReadyStep />
            }
            case 1: {
                return <ResursSelectStoreStep resursStores={resursStores} webshopId={selectedWebshopId ?? ""} />
            }
            case 2: {
                return (
                    <ResursDetailsStep
                        showCancelService={showCancelService}
                        setShowCancelService={(show: boolean) => setShowCancelService(show)}
                    />
                )
            }
            default:
                return onGoBack()
        }
    }

    return (
        <>
            {showCancelService && (
                <CancelService
                    serviceName={t("serviceNames.resursPayments")}
                    cancelText={t("cancelService.resurs.cancelText")}
                    customCancelledText={t("cancelService.resurs.cancelledText")}
                    onCancel={async () => {
                        await dispatch(cancelResursForWebshopThunk(selectedWebshopId, merchantId))
                        await dispatch(
                            sendServiceCancellationEmailThunk(
                                merchantId,
                                "GeneralServiceCancellationEopEmail",
                                t("serviceNames.resursPayments")
                            )
                        )
                    }}
                    onGoBack={() => setShowCancelService(false)}
                />
            )}
            <PageWrapper disableMinHeight className={cn({ "d-none": showCancelService })}>
                {isEdit && <GoBackContainer goBackText={t("commononboardingtexts.backToAllService")} onGoBack={onGoBack} />}
                <PageContainer noHeader={isEdit}>
                    <div className="mt-4">
                        <FormHeaderLogoContainer
                            mainLogo={{
                                src: getImage("resursLogo"),
                                alt: "swiipe-resurs-logo",
                                width: "200px",
                            }}
                        />
                    </div>
                    <div id="resurs-onboarding">{getComponentToShow() ?? <></>}</div>

                    {!isEdit && (
                        <TextButton
                            text={t("commononboardingtexts.goback")}
                            className={cn("mt-3 mb-2")}
                            onClick={async () => onGoBack()}
                        />
                    )}
                </PageContainer>
            </PageWrapper>
        </>
    )
}

export default ResursOnboardingPage
