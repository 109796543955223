import { IMerchantOnboardingServicesSubForm } from "../types/merchant/IMerchantPreOnboarding"
import { TSwiipeService } from "../types/services/TSwiipeService"
import { hasValue } from "../utils/arrayUtil"
import { getKeys } from "../utils/objectUtil"

export const merchantPreOnboardingServicesConfig: {
    inputName: keyof IMerchantOnboardingServicesSubForm
    title: string
    serviceTypes: TSwiipeService[] | undefined
}[][] = [
    [
        {
            inputName: "useAcquiringClearhaus",
            title: "merchantOffer.services.acquiringClearhaus.title",
            serviceTypes: ["AcquiringClearhaus"],
        },
    ],
    [
        { inputName: "usePaymentsCreditCard", title: "merchantOffer.services.cardPayments.title", serviceTypes: ["CreditCard"] },
        {
            inputName: "usePaymentsMobilePayOnline",
            title: "MobilePay",
            serviceTypes: ["MobilePayOnline"],
        },
        { inputName: "usePaymentsDankort", title: "Dankort", serviceTypes: ["Dankort"] },
        {
            inputName: "usePaymentsForbrugsforeningen",
            title: "Forbrugsforeningen",
            serviceTypes: ["Forbrugsforeningen"],
        },
        {
            inputName: "usePaymentsKlarna",
            title: "Klarna",
            serviceTypes: ["KlarnaFinancing", "KlarnaPayLater"],
        },
        { inputName: "usePaymentsApplePay", title: "ApplePay", serviceTypes: ["ApplePay"] },
        { inputName: "usePaymentsViaBill", title: "ViaBill", serviceTypes: ["Viabill"] },
    ],
    [
        { inputName: "usePaymentsSwish", title: "Swish", serviceTypes: ["Swish"] },
        {
            inputName: "usePaymentsResurs",
            title: "Resurs",
            serviceTypes: ["ResursInvoice", "ResursPartPayment", "ResursRevolvingCredit"],
        },
    ],
    [
        { inputName: "useFeatureCheckout", title: "Checkout", serviceTypes: ["Checkout"] },
        { inputName: "useFeatureWinBack", title: "Win-back", serviceTypes: ["Winback"] },
        { inputName: "useFeaturePlusSell", title: "Plus-sell", serviceTypes: ["PlusSell"] },
        { inputName: "useFeatureReturnSell", title: "Return-sell", serviceTypes: ["ReturnSell"] },
        {
            inputName: "useFeatureAnywhereMobile",
            title: "Anywhere Mobile",
            serviceTypes: ["AnywhereMobile"],
        },
    ],
]

export function getmerchantPreOnboardingServiceTitle(inputName: keyof IMerchantOnboardingServicesSubForm): string | undefined {
    return merchantPreOnboardingServicesConfig.flat().find((i) => i.inputName === inputName)?.title
}

export function getMerchantPreOnboardingSwiipeServices(inputName: keyof IMerchantOnboardingServicesSubForm) {
    return merchantPreOnboardingServicesConfig.flat().find((i) => i.inputName === inputName)?.serviceTypes
}

export function mapMerchantOnboardingServicesSubFormToServices(services?: IMerchantOnboardingServicesSubForm): TSwiipeService[] {
    const servicesKeys = getKeys(services ?? {}).filter((key) => services?.[key])
    return servicesKeys
        .map<TSwiipeService[] | undefined>(
            (serviceKey) => merchantPreOnboardingServicesConfig.flat().find((c) => c.inputName === serviceKey)?.serviceTypes
        )
        .filter(hasValue)
        .flat()
}
export function getMerchantOfferIncludesPsp(services?: IMerchantOnboardingServicesSubForm) {
    return (
        getKeys(services ?? {})
            .filter((s) => !!services?.[s])
            .filter((k) => k !== "useFeatureCheckout").length > 0
    )
}
