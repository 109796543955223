import cn from "classnames"
import React, { useEffect, useMemo, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    AcquiringBusinessModelFields,
    AcquiringContactFields,
    AcquiringTurnoverFields,
    FormFieldsSection,
    ICompanyFieldsBase,
    IFormNavigationControl,
    TClearhausDeliveryDelayType,
    TextButton,
    getClearhausDeliveryDelayOptions,
    getClearhausDeliveryDelaysRequiringComment,
    getKeys,
} from "swiipe.portal.shared"
import {
    getClearhausInstantOnboardingAllowed,
    getClearhausPersonFormFields,
    shouldAdditionalFieldsBeHidden,
} from "../../../services/clearhausService"
import { useMerchantPreOnboardingDetails } from "../../../services/merchantOfferService"
import { navigationService } from "../../../services/navigationService"
import {
    clearhausFirstStageOnboardingThunk,
    clearhausSecondStageOnboardingThunk,
    editApplicationThunk,
    saveOnboardingDataThunk,
} from "../../../store/thunks/clearhausThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IClearhausApplicationData } from "../../../type/clearhaus/IClearhausApplicationData"
import { IClearhausApplicationForm } from "../../../type/clearhaus/IClearhausApplicationForm"
import { IClearhausCommentsData } from "../../../type/clearhaus/IClearhausCommentsData"
import { IClearhausRequiredFieldNames } from "../../../type/clearhaus/IClearhausRequiredFieldNames"
import { TClearhausFormState } from "../../../type/clearhaus/TClearhausFormState"
import { MixedMerchantDetails } from "../../../type/mixedmerchantdetails"
import { SaveButton } from "../../buttons/SaveButton"
import SubmitButton from "../../buttons/SubmitButton"
import { ClearhausApplicationComments } from "../../clearhaus/ClearhausApplicationComments"
import { ClearhausOnboardingFooter } from "../../clearhaus/ClearhausOnboardingFooter"
import "./ClearhausOnboardingForm.scss"
import ClearhausAdditionalFields from "./fieldsets/ClearhausAdditionalFields"
import ClearhausBankAccountFields from "./fieldsets/ClearhausBankAccountFields"
import ClearhausCompanyFields from "./fieldsets/ClearhausCompanyFields"
import { ClearhausDocumentationFields } from "./fieldsets/ClearhausDocumentationFields"

interface IClearhausOnboardingForm {
    merchantDetails: MixedMerchantDetails
    companyDetails?: ICompanyFieldsBase
    clearhausFormState: TClearhausFormState
    applicationData?: IClearhausApplicationData
    webshopIds: string[]
    allowInstantOnboarding: boolean
    isEditing?: boolean
    commentsData?: IClearhausCommentsData
    setAllowInstantOnboarding: (allowInstant: boolean) => void
    formStateUpdatedCallback: (state: TClearhausFormState) => void
    onGoBack: () => void
    viewMode?: boolean
    navigationControl?: IFormNavigationControl
}

const ClearhausOnboardingForm = ({
    merchantDetails,
    companyDetails,
    clearhausFormState,
    applicationData,
    webshopIds,
    allowInstantOnboarding,
    isEditing,
    commentsData,
    setAllowInstantOnboarding,
    formStateUpdatedCallback,
    onGoBack,
    viewMode,
    navigationControl,
}: IClearhausOnboardingForm) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const swMerchantId = merchantDetails.swMerchant.swMerchantId
    const { control, formState, handleSubmit, clearErrors, trigger, setError, reset } = useForm<IClearhausApplicationForm>({
        mode: "onChange",
        reValidateMode: "onChange",
    })

    const [showErrors, setShowErrors] = useState(false)

    const [showAdditionalFields, setShowAdditionalFields] = useState(false)
    const preOnboardingDetails = useMerchantPreOnboardingDetails()

    const [directorsCount, setDirectorsCount] = useState(1) // Director is always present
    const [ownersCount, setOwnersCount] = useState(0)

    const [saveJustTriggered, setSaveJustTriggered] = useState(false)

    const validatedFieldsNames: IClearhausRequiredFieldNames = useMemo(() => {
        return {
            contactInfo: {
                firstPage: ["contact.name", "contact.email", "contact.phone"],
            },
            company: {
                firstPage: [
                    "company.name",
                    "company.cvr",
                    "company.type",
                    "company.country",
                    "company.address",
                    "company.postCode",
                    "company.city",
                    "company.phone",
                    "company.email",
                ],
                secondPage: ["company.ownershipType"],
            },
            businessModel: {
                firstPage: ["businessModel.description"],
                secondPage: [
                    "businessModel.tradeName",
                    "businessModel.sellSubscription",
                    "businessModel.sellPhysical",
                    "businessModel.deliveryDelay",
                    "businessModel.delayComment",
                    "businessModel.useDropShipping",
                ],
            },
            turnover: {
                firstPage: ["turnover.transactionsCurrency", "turnover.monthly"],
                secondPage: ["turnover.transactionAmount"],
            },
            bank: { firstPage: ["bank.currency", "bank.iban"], secondPage: ["bank.swiftCode", "bank.name"] },
            documentation: { secondPage: ["documentation.ownershipStructure", "documentation.files"] },
            directors: {
                secondPage: Array.from({ length: directorsCount }, (_, index) =>
                    getClearhausPersonFormFields("director", index)
                ).flat(),
            },
            owners: {
                secondPage: Array.from({ length: ownersCount }, (_, index) =>
                    getClearhausPersonFormFields("owner", index)
                ).flat(),
            },
            additionalFields: { secondPage: ["additionalInfo.files"] },
        }
    }, [directorsCount, ownersCount])

    const documentationValidatedFieldsNames = useMemo(() => {
        if (clearhausFormState !== "second") {
            return []
        }
        return [
            ...validatedFieldsNames.documentation.secondPage,
            ...validatedFieldsNames.directors.secondPage,
            ...validatedFieldsNames.owners.secondPage,
        ]
    }, [validatedFieldsNames, clearhausFormState])

    const commonFieldsSectionProps = {
        control,
        showErrors,
        showValidationCheckmark: clearhausFormState && clearhausFormState !== "submitted",
        formState,
    }

    const commonFieldsProps = {
        control,
        clearhausFormState,
        applicationData,
        viewMode,
    }

    useEffect(() => {
        trigger()
    }, [clearhausFormState])

    useEffect(() => {
        if (!applicationData?.applicationFields) {
            return
        }

        if (saveJustTriggered) {
            // Form just have been saved, makes no sense to reset fields, data is up to date.
            // And since files are not being saved they will be lost during reset
            setSaveJustTriggered(false)
            return
        }

        if (!allowInstantOnboarding && applicationData.isFirstStageSubmitted && !isEditing) {
            // for an instant onboarding we don't need a reset for an application form
            // between first and second stage submits
            return
        }

        reset(applicationData.applicationFields)

        const ownersCount = getKeys(applicationData.applicationFields.owners || {}).length
        const directorsCount = getKeys(applicationData.applicationFields.directors).length
        if (ownersCount || directorsCount) {
            setOwnersCount(ownersCount)
            setDirectorsCount(directorsCount)
        }

        //Trigger validation after fields are updated
        setTimeout(() => {
            trigger()
        }, 300)
    }, [applicationData])

    const onSubmit: SubmitHandler<IClearhausApplicationForm> = async (data) => {
        if (isEditing) {
            if (clearhausFormState === "first") {
                formStateUpdatedCallback("second")
            }

            if (clearhausFormState === "second") {
                if (!applicationData) {
                    return
                }

                await dispatch(editApplicationThunk(swMerchantId, applicationData.onboardingId, applicationData.webshopIds, data))
                formStateUpdatedCallback("submitted")
            }
            return
        }

        if (clearhausFormState === "submitted") {
            return
        }

        if (!allowInstantOnboarding && clearhausFormState === "first") {
            formStateUpdatedCallback("second")
            return
        }

        if (!allowInstantOnboarding && clearhausFormState === "second") {
            const onboardingId = await dispatch(
                clearhausFirstStageOnboardingThunk(
                    swMerchantId,
                    webshopIds,
                    allowInstantOnboarding,
                    data,
                    applicationData?.onboardingId
                )
            )

            await dispatch(
                clearhausSecondStageOnboardingThunk(
                    swMerchantId,
                    data,
                    applicationData!.webshopIds,
                    allowInstantOnboarding,
                    onboardingId
                )
            )

            formStateUpdatedCallback("submitted")
            return
        }

        if (clearhausFormState === "first") {
            await dispatch(
                clearhausFirstStageOnboardingThunk(
                    swMerchantId,
                    webshopIds,
                    allowInstantOnboarding,
                    data,
                    applicationData?.onboardingId
                )
            )
            formStateUpdatedCallback("second")
            return
        }

        if (!applicationData) {
            return
        }

        await dispatch(
            clearhausSecondStageOnboardingThunk(
                swMerchantId,
                data,
                webshopIds,
                allowInstantOnboarding,
                applicationData.onboardingId
            )
        )

        formStateUpdatedCallback("submitted")
    }

    useEffect(() => {
        if (navigationControl) {
            navigationControl.onBack = () => {
                if (
                    (clearhausFormState === "second" && !allowInstantOnboarding && !applicationData?.isFirstStageSubmitted) ||
                    (clearhausFormState === "second" && isEditing)
                ) {
                    reset(control.getValues())
                    formStateUpdatedCallback("first")
                    return
                }

                if (!allowInstantOnboarding && clearhausFormState === "second" && applicationData?.isFirstStageSubmitted) {
                    navigationService.navigate("/plugins")
                }

                onGoBack()
            }
        }
    }, [
        navigationControl,
        clearhausFormState,
        applicationData,
        allowInstantOnboarding,
        isEditing,
        onGoBack,
        formStateUpdatedCallback,
    ])

    return (
        <div className="clearhaus-onboarding-form">
            <Form onSubmit={handleSubmit(onSubmit)}>
                {preOnboardingDetails?.preOnboarding?.merchantKycMessage && (
                    <FormFieldsSection
                        headerClassName="clearhaus-onboarding-form__sales-message-header"
                        header={`Note from ${preOnboardingDetails.creator.firstName}:`}
                        validatedFields={[]}
                    >
                        <div className="clearhaus-onboarding-form__sales-message">
                            {preOnboardingDetails.preOnboarding.merchantKycMessage}
                        </div>
                    </FormFieldsSection>
                )}

                <FormFieldsSection
                    header={`1. ${t("clearhausonboarding.sectiontitles.contactinfo")}`}
                    validatedFields={(clearhausFormState === "first" && validatedFieldsNames.contactInfo.firstPage) || []}
                    {...commonFieldsSectionProps}
                >
                    <AcquiringContactFields<IClearhausApplicationForm>
                        nameType="fullName"
                        showReview={clearhausFormState !== "first"}
                        defaultToUserData
                        getFieldsFromControl={(form) => form?.contact}
                        savedFields={applicationData?.applicationFields?.contact}
                        fieldSelectorPrefix="contact."
                        {...commonFieldsProps}
                    />
                </FormFieldsSection>
                <FormFieldsSection
                    header={`2. ${t("clearhausonboarding.sectiontitles.thecompany")}`}
                    validatedFields={
                        clearhausFormState === "first"
                            ? validatedFieldsNames.company.firstPage
                            : validatedFieldsNames.company.secondPage
                    }
                    className="company-fields"
                    {...commonFieldsSectionProps}
                >
                    <ClearhausApplicationComments commentsData={commentsData} forSection="CompanySection" insideForm />
                    <ClearhausCompanyFields
                        companyDetails={companyDetails}
                        merchantDetails={merchantDetails}
                        {...commonFieldsProps}
                    />
                </FormFieldsSection>
                <FormFieldsSection
                    header={`3. ${t("acquiringOnboarding.common.businessModel.title")}`}
                    validatedFields={
                        clearhausFormState === "first"
                            ? validatedFieldsNames.businessModel.firstPage
                            : validatedFieldsNames.businessModel.secondPage
                    }
                    {...commonFieldsSectionProps}
                >
                    <AcquiringBusinessModelFields<IClearhausApplicationForm, TClearhausDeliveryDelayType>
                        savedFields={applicationData?.applicationFields?.businessModel}
                        getFieldsFromControl={(form) => form?.businessModel}
                        fieldSelectorPrefix="businessModel."
                        isSubmitted={clearhausFormState === "submitted"}
                        showMainInput={clearhausFormState === "first"}
                        showDetailInput={clearhausFormState === "second"}
                        deliveryDelayOptions={getClearhausDeliveryDelayOptions()}
                        deliveryDelaysRequiringComment={getClearhausDeliveryDelaysRequiringComment()}
                        {...commonFieldsProps}
                    />
                </FormFieldsSection>
                <FormFieldsSection
                    header={`4. ${t("clearhausonboarding.sectiontitles.turnover")}`}
                    validatedFields={
                        clearhausFormState === "first"
                            ? validatedFieldsNames.turnover.firstPage
                            : validatedFieldsNames.turnover.secondPage
                    }
                    {...commonFieldsSectionProps}
                >
                    <AcquiringTurnoverFields
                        savedFields={applicationData?.applicationFields?.turnover}
                        getFieldsFromControl={(form) => form?.turnover}
                        fieldSelectorPrefix="turnover."
                        isSubmitted={clearhausFormState === "submitted"}
                        showMainInput={clearhausFormState === "first"}
                        showDetailInput={clearhausFormState === "second"}
                        {...commonFieldsProps}
                        onMonthlyTurnoverChanged={(transactionsCurrency, monthlyTurnover) => {
                            // Keep allowInstantOnboarding updated on fields change
                            // Keep allowInstantOnboarding updated on saved application data loaded
                            if (!transactionsCurrency || !monthlyTurnover) {
                                return
                            }
                            const allowInstant = getClearhausInstantOnboardingAllowed(transactionsCurrency, monthlyTurnover)
                            setAllowInstantOnboarding(allowInstant)
                        }}
                    />
                </FormFieldsSection>
                <FormFieldsSection
                    header={`5. ${t("clearhausonboarding.sectiontitles.bankaccount")}`}
                    validatedFields={
                        clearhausFormState === "first"
                            ? validatedFieldsNames.bank.firstPage
                            : validatedFieldsNames.bank.secondPage
                    }
                    {...commonFieldsSectionProps}
                >
                    <ClearhausApplicationComments commentsData={commentsData} forSection="BankAccountSection" insideForm />
                    {clearhausFormState === "first" && (
                        <p className="text-normal">{t("clearhausonboarding.messagetitles.bankaccountdesc")}</p>
                    )}
                    <ClearhausBankAccountFields {...commonFieldsProps} />
                </FormFieldsSection>
                {clearhausFormState !== "first" && (
                    <FormFieldsSection
                        header={`6. ${t("clearhausonboarding.sectiontitles.documentation")}`}
                        validatedFields={documentationValidatedFieldsNames}
                        {...commonFieldsSectionProps}
                        fieldsIndentLeft="smaller"
                        className="clearhaus-onboarding-form__documentation-fields-section"
                        //In this section errors are shown directly
                        //next to fields so disable for section
                        showErrors={false}
                    >
                        <ClearhausApplicationComments
                            className="clearhaus-onboarding-form__documentation-comments"
                            commentsData={commentsData}
                            forSection="DocumentationSection"
                            insideForm
                        />
                        <ClearhausDocumentationFields
                            companyType={control.getValues().company?.type}
                            ownershipType={control.getValues().company?.ownershipType}
                            ownersCountState={[ownersCount, (ownersCount) => setOwnersCount(ownersCount)]}
                            directorsCountState={[directorsCount, (directorsCount) => setDirectorsCount(directorsCount)]}
                            clearErrors={clearErrors}
                            setError={setError}
                            formState={formState}
                            showErrors={showErrors}
                            saveJustTriggered={saveJustTriggered}
                            {...commonFieldsProps}
                        />
                    </FormFieldsSection>
                )}
                {clearhausFormState !== "first" && (
                    <FormFieldsSection
                        header={`7. ${t("clearhausonboarding.sectiontitles.additionalinfo")}`}
                        validatedFields={validatedFieldsNames.additionalFields.secondPage}
                        {...commonFieldsSectionProps}
                        className={cn({
                            "d-none": shouldAdditionalFieldsBeHidden(clearhausFormState, applicationData),
                        })}
                        headerFont="smaller"
                        folded={!showAdditionalFields && clearhausFormState !== "submitted"}
                    >
                        {!showAdditionalFields && clearhausFormState !== "submitted" ? (
                            <TextButton
                                text={`+ ${t("clearhausonboarding.buttons.addExtraInfo")}`}
                                onClick={async () => setShowAdditionalFields(true)}
                                textDecoration="underline"
                                fontSize="big"
                                display="inline-block"
                                fontWeight="600"
                                color="dark-blue"
                            />
                        ) : (
                            <>
                                <ClearhausApplicationComments
                                    commentsData={commentsData}
                                    forSection="AdditionalFilesSection"
                                    insideForm
                                />
                                <ClearhausAdditionalFields
                                    {...commonFieldsProps}
                                    setError={setError}
                                    clearErrors={clearErrors}
                                    saveJustTriggered={saveJustTriggered}
                                />
                            </>
                        )}
                    </FormFieldsSection>
                )}
                <ClearhausOnboardingFooter
                    className="clearhaus-onboarding-form__footer"
                    left={
                        (clearhausFormState !== "submitted" && (
                            <SaveButton
                                btnContainerClassName="clearhaus-onboarding-form__save-btn-container"
                                savedMsgClassName="clearhaus-onboarding-form__form-saved-msg"
                                saveAction={async () => {
                                    setSaveJustTriggered(true)
                                    await dispatch(saveOnboardingDataThunk(swMerchantId, webshopIds, control.getValues()))
                                }}
                            />
                        )) ||
                        undefined
                    }
                    middle={
                        (clearhausFormState !== "submitted" && (
                            <SubmitButton
                                className="clearhaus-onboarding-form__submit-btn"
                                onClick={() => setShowErrors(!!Object.keys(formState.errors).length)}
                                formState={formState}
                                dark
                                isBlue
                                noBorder
                            >
                                {allowInstantOnboarding || clearhausFormState === "second"
                                    ? t("common.submit")
                                    : t("common.continue")}
                            </SubmitButton>
                        )) ||
                        undefined
                    }
                />
            </Form>
        </div>
    )
}

export default ClearhausOnboardingForm
