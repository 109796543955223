import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    DropDownList,
    PageContainer,
    PageWrapper,
    ShowErrorMessages,
    getKeys,
    useSelector,
    valFuncRequired,
} from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import FileInput, { megabyteInBytes } from "../../components/form/input/FileInput"
import WebshopSelect, { useInitialWebshopValuesForWebshopSelect } from "../../components/merchant/WebshopSelect"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { IGetCsvTransformed, SettlementType, getCsvTransformedFileThunk } from "../../store/thunks/transactionThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import "./BookkeepingPage.scss"

interface IBookkeepingPage {}
interface IBookkeepingPageInner {
    merchantDetails: MixedMerchantDetails
}

const BookkeepingPage = ({}: IBookkeepingPage) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)

    if (!merchantDetails || merchantDetails.webshops.length === 0) {
        return null
    }

    return <BookkeepingPageInner merchantDetails={merchantDetails}></BookkeepingPageInner>
}

type ESettlementExportType =
    | "clearhausStandardValues"
    | "clearhausExistingAndStandardValues"
    | "shift4MissingValues"
    | "shift4StandardValues"
    | "shift4ExistingAndStandardValues"

interface IFormData {
    file: FileList
    exportType: ESettlementExportType
}

const BookkeepingPageInner = ({ merchantDetails }: IBookkeepingPageInner) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const { initialWebshopId } = useInitialWebshopValuesForWebshopSelect(merchantDetails)
    const [selectedWebshopId, setSelectedWebshopId] = useState(initialWebshopId)

    const { handleSubmit, register, formState, getValues, setError, clearErrors, trigger, setValue } = useForm<IFormData>()

    if (!merchantDetails) {
        return null
    }

    const onSubmit: SubmitHandler<IFormData> = async (data) => {
        try {
            const files = getKeys(data.file).map((key) => data.file[key as any])

            let props: IGetCsvTransformed = {
                webshopId: selectedWebshopId,
                files: files,
                settlementType: SettlementType.ClearhausSettlement,
                addFeeRow: true,
                addTotalRow: true,
                insertMissingValues: false,
                addStandardColumns: true,
                exportStandardColumnsOnly: false,
                useCommaAsDecimalSeparatorForOtherColumns: false,
                useCommaAsDecimalSeparatorForStandardColumns: false,
            }

            switch (data.exportType) {
                case "clearhausStandardValues":
                    props = {
                        ...props,
                        settlementType: SettlementType.ClearhausSettlement,
                        addFeeRow: true,
                        addTotalRow: true,
                        insertMissingValues: false,
                        addStandardColumns: true,
                        exportStandardColumnsOnly: true,
                        useCommaAsDecimalSeparatorForStandardColumns: true,
                    }
                    break
                case "clearhausExistingAndStandardValues":
                    props = {
                        ...props,
                        settlementType: SettlementType.ClearhausSettlement,
                        addFeeRow: true,
                        addTotalRow: true,
                        insertMissingValues: false,
                        addStandardColumns: true,
                        exportStandardColumnsOnly: false,
                        useCommaAsDecimalSeparatorForOtherColumns: true,
                        useCommaAsDecimalSeparatorForStandardColumns: true,
                    }
                    break
                case "shift4MissingValues":
                    props = {
                        ...props,
                        settlementType: SettlementType.Shift4Settlement,
                        addFeeRow: false,
                        addTotalRow: false,
                        insertMissingValues: true,
                        addStandardColumns: false,
                        exportStandardColumnsOnly: false,
                    }
                    break
                case "shift4StandardValues":
                    props = {
                        ...props,
                        settlementType: SettlementType.Shift4Settlement,
                        addFeeRow: true,
                        addTotalRow: true,
                        insertMissingValues: true,
                        addStandardColumns: true,
                        exportStandardColumnsOnly: true,
                        useCommaAsDecimalSeparatorForStandardColumns: true,
                    }
                    break
                case "shift4ExistingAndStandardValues":
                    props = {
                        ...props,
                        settlementType: SettlementType.Shift4Settlement,
                        addFeeRow: true,
                        addTotalRow: true,
                        insertMissingValues: true,
                        addStandardColumns: true,
                        exportStandardColumnsOnly: false,
                        useCommaAsDecimalSeparatorForOtherColumns: true,
                        useCommaAsDecimalSeparatorForStandardColumns: true,
                    }
                    break
                default:
                    throw "Missing export type handling"
            }

            await dispatch(getCsvTransformedFileThunk(props))
        } catch {
            //Catch to stop showing spinner
        }
    }

    return (
        <PageWrapper>
            <PageContainer>
                <div className="bookkeeping-main-container">
                    <p className="text-center header-text mb-4">{t("bookkeeping.title")}</p>
                    <p className="text-center mb-4">{t("bookkeeping.togettransactionids")}</p>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="bookkeeping-form">
                            <FileInput
                                name={"file"}
                                triggerValidation={trigger}
                                maxFileSizeBytes={megabyteInBytes * 10}
                                clearError={clearErrors}
                                setError={setError}
                                setValue={setValue}
                                getValues={getValues}
                                accept=".csv"
                                minFilesAmount={1}
                                maxFilesAmount={5}
                                multiple
                                register={register}
                                requiredErrorMessage={t("bookkeeping.uploaderror")}
                            ></FileInput>

                            <WebshopSelect
                                merchantDetails={merchantDetails}
                                onWebshopChange={(selectedWebshopId: string) => {
                                    setSelectedWebshopId(selectedWebshopId)
                                }}
                                hidePlatform
                            ></WebshopSelect>

                            <p className="select-acquirer-heading mb-2">{t("bookkeeping.selectExport")}</p>
                            <DropDownList<ESettlementExportType>
                                className="mb-3"
                                options={(
                                    [
                                        "clearhausStandardValues",
                                        "clearhausExistingAndStandardValues",
                                        "shift4MissingValues",
                                        "shift4StandardValues",
                                        "shift4ExistingAndStandardValues",
                                    ] satisfies ESettlementExportType[]
                                ).map((v) => ({ value: v, text: t("bookkeeping.exportType." + v) }))}
                                name="exportType"
                                innerRef={register(valFuncRequired(t("bookkeeping.selectExportError")))}
                            ></DropDownList>

                            <ShowErrorMessages formState={formState} />
                        </div>
                        <div className="bookkeeping-submit">
                            <SubmitButton
                                dark
                                noBorder
                                noBorderRadius
                                containerClass="submit-btn"
                                formState={formState}
                                className="mb-2"
                            >
                                {t("bookkeeping.exportalldata")}
                            </SubmitButton>
                        </div>
                    </Form>
                    <div className="disclamer mt-4">{t("bookkeeping.disclamerBottom")}</div>
                </div>
            </PageContainer>
        </PageWrapper>
    )
}

export default BookkeepingPage
