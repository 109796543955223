import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { PageContainer, PageWrapper, sortAscending, useSelector } from "swiipe.portal.shared"
import AddApiKeyForm from "../../components/merchant/AddApiKeyForm"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { ensureMerchantApiKeys } from "../../store/thunks/merchantThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import ApiKey from "./../../components/merchant/ApiKey"
import PageIconContainer from "./../../components/page/PageIconContainer"
import { merchantSelectors } from "./../../store/reducers/merchantReducer"
import "./MerchantApiKeyPage.scss"

interface IMerchantApiKeyPageProps {}

const MerchantApiKeyPage = ({}: IMerchantApiKeyPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const apiKeys = useSelector(merchantSelectors.apiKeys)

    useEffect(() => {
        dispatch(ensureMerchantApiKeys(false))
    }, [apiKeys])

    if (!apiKeys) {
        return null
    }

    return (
        <PageWrapper>
            <PageContainer classNameBody="merchant-apikey-page" title={t("apikey.title")}>
                <PageIconContainer iconClass="icon-apikey" textTitle={t("apikey.title")} description={t("apikey.description")} />
                {sortAscending(apiKeys, (ak) => ak.created).map((ak) => (
                    <ApiKey key={ak.apiKey} apiKey={ak} />
                ))}
                <AddApiKeyForm />
            </PageContainer>
        </PageWrapper>
    )
}

export default MerchantApiKeyPage
