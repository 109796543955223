import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { FieldsetFormGroup } from "swiipe.portal.shared"
import "./FormPasswordRequirements.scss"

interface IFormPasswordRequirementsProps {
    inBox?: boolean
    isBlueBubble?: boolean
}

const FormPasswordRequirements = ({ inBox, isBlueBubble }: IFormPasswordRequirementsProps) => {
    const { t } = useTranslation()
    return (
        <FieldsetFormGroup className={cn({ "pw-requirement": inBox }, isBlueBubble && "pw-requirement--blue-bubble")}>
            {isBlueBubble && <div className="pw-requirement__arrow"></div>}
            <p className="dialog-title">{t("common.securitystatment")}</p>
            <ul className="dialog-text">
                <li>{t("common.mincharreq")}</li>
                <li>{t("common.lowercasereq")}</li>
                <li>{t("common.uppercasereq")}</li>
                <li>{t("common.numbersreq")}</li>
            </ul>
        </FieldsetFormGroup>
    )
}

export default FormPasswordRequirements
