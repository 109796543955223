import React from "react"
import { useTranslation } from "react-i18next"
import { Input } from "reactstrap"
import {
    BillingAddressDto,
    DropDownList,
    TRegister,
    TSetValue,
    sharedConfigurationSelectors,
    useSelector,
    valFuncRequired,
} from "swiipe.portal.shared"
import { getRegionDefaultValue, getRegionNameFromCode, getRegionsList, isShowRegionInput } from "../../../services/regionService"
import { IUserSignupDataFormModel } from "../../../type/IUserSignupDataFormModel"

interface IRegionFormFieldsProps {
    register: TRegister
    setValue: TSetValue<IUserSignupDataFormModel | BillingAddressDto>
    currentCountry: string
    countryDefault?: string
    regionDefault?: string
    noSave?: boolean
}

const RegionFormFields = ({
    setValue,
    register,
    currentCountry,
    countryDefault,
    regionDefault,
    noSave = false,
}: IRegionFormFieldsProps) => {
    const { t } = useTranslation()
    const countryRegionConfig = useSelector(sharedConfigurationSelectors.countryRegionConfiguration)

    return isShowRegionInput(currentCountry, countryRegionConfig) ? (
        <>
            <DropDownList
                disabled={noSave}
                defaultValue={getRegionDefaultValue(countryRegionConfig, countryDefault, regionDefault)}
                name="region_overlay"
                placeholder={t("personal.state")}
                innerRef={register(valFuncRequired(t("personal.validationerrors.region")))}
                options={getRegionsList(currentCountry, countryRegionConfig)}
                onChange={(e) => setValue("region", getRegionNameFromCode(e.target.value, currentCountry, countryRegionConfig))}
            />
            <Input type="hidden" name="region" innerRef={register} />
        </>
    ) : null
}

export default RegionFormFields
