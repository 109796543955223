import React, { useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import { FieldsetFormGroup, ShowErrorMessages } from "swiipe.portal.shared"
import { resendConfirmEmailThunk } from "../../store/thunks/partnerThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import SubmitButton from "../buttons/SubmitButton"
import EmailInputComp from "../form/input/EmailInputComp/EmailInputComp"
import FormSectionTitleWithHint from "../form/title/FormSetctionTitleWithHint"
import "./ResendConfirmationEmail.scss"

interface IResendConfirmationEmail {
    partnerId: string
    merchantId: string
    merchantEmail: string
    toggleCallback: (merchantId: string) => void
}

interface IFormModel {
    email: string
}

const ResendConfirmationEmail = ({ partnerId, merchantId, merchantEmail, toggleCallback }: IResendConfirmationEmail) => {
    const { handleSubmit, register, setValue, formState } = useForm<IFormModel>()
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const onSubmit: SubmitHandler<IFormModel> = async (data) => {
        try {
            await dispatch(resendConfirmEmailThunk(partnerId, merchantId, data.email))
            toggleCallback(merchantId)
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    useEffect(() => {
        setValue("email", merchantEmail)
    }, [merchantEmail])

    return (
        <div id="resend-email-box">
            <Form className="resend-email-from" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-sm-12 col-md-8">
                        <FormSectionTitleWithHint
                            title={t("partnermerchantrelations.whoisowner")}
                            hintText={t("partnermerchantrelations.ownerhint")}
                            clickToExpand
                            alignLeft
                        ></FormSectionTitleWithHint>
                    </div>
                </div>

                <FieldsetFormGroup field>
                    <div className="row">
                        <div className="col-8 pr-sm-1 pr-md-0">
                            <EmailInputComp inputName="email" register={register} />
                        </div>
                        <div className="col-4 px-0">
                            <SubmitButton className="resend-btn" formState={formState} dark noBorder>
                                {t("partnermerchantrelations.request")}
                            </SubmitButton>
                        </div>
                    </div>
                </FieldsetFormGroup>
                <div className="row">
                    <div className="col-sm-12 col-md-8">
                        <ShowErrorMessages formState={formState} />
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default ResendConfirmationEmail
