import React, { useState } from "react"
import "./DisclaimerToggle.scss"
import cn from "classnames"

interface DisclaimerToggleProps {
    toggleShowText: string
    toggleHideText: string
    children: React.ReactNode
    className?: string
}

const DisclaimerToggle = ({ toggleShowText, toggleHideText, children, className }: DisclaimerToggleProps) => {
    const [disclaimerOpen, setDisclaimerOpen] = useState(false)

    return (
        <div className={cn("disclaimer-toggle", className)}>
            <span
                className={cn("disclaimer-toggle__trigger", { "mb-3": disclaimerOpen })}
                onClick={() => setDisclaimerOpen((v) => !v)}
            >
                {disclaimerOpen ? toggleHideText : toggleShowText}
            </span>
            <div style={{ display: disclaimerOpen ? "block" : "none" }}>{children}</div>
        </div>
    )
}

export default DisclaimerToggle
