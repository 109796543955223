import { useEffect } from "react"
import { TPlatformValue, sharedConfigurationSelectors, useSelector } from "swiipe.portal.shared"
import { IPlugin } from "../components/merchant/Plugin"
import { StoreState } from "../store/StoreState"
import { billingSelectors } from "../store/reducers/billingReducer"
import { clearhausSelectors } from "../store/reducers/clearhausReducer"
import { IGatewayDto, gatewaySelectors } from "../store/reducers/gatewayReducer"
import { merchantSelectors } from "../store/reducers/merchantReducer"
import { IWebshopProviderConfig, merchantServicesSelectors } from "../store/reducers/merchantServicesReducer"
import { getClearhausApplicationStateThunk } from "../store/thunks/clearhausThunks"
import { fetchWebshopFeaturesDetailsThunk } from "../store/thunks/merchantThunks"
import { getOnboardingStatusThunk, sandboxOnboardingThunk } from "../store/thunks/swiipePaymentsThunks"
import { useReduxDispatch } from "../store/useReduxDispatch"
import { TProviderName } from "../type/IProvider"
import { IClearhausApplicationOnboardingState } from "../type/clearhaus/IClearhausApplicationOnboardingState"
import { MixedMerchantDetails } from "../type/mixedmerchantdetails"

export const filterPluginsByPlatform = (plugins: IPlugin[], platform: TPlatformValue) => {
    return plugins.filter((plugin) => plugin.platforms.includes(platform))
}

export const useCalculatedPluginsDependencies = (merchantDetails: MixedMerchantDetails, selectedWebshopId: string) => {
    const dispatch = useReduxDispatch()
    const webshopId = selectedWebshopId

    const env = useSelector(sharedConfigurationSelectors.environment)
    const swiipePlan = useSelector(merchantSelectors.swiipePlan)

    const merchantDeletionPending = !!merchantDetails?.deletionStatus
    const webshopDetails = merchantDetails?.webshops.find((w) => w.webshopId === webshopId)
    const merchantCountry = merchantDetails?.swMerchant.countryCode

    const prices = useSelector(billingSelectors.resolvedPrices)
    const wProviderConf = useSelector<StoreState, IWebshopProviderConfig | undefined>((state) =>
        merchantServicesSelectors.statuses(state, merchantDetails?.swMerchant.swMerchantId || "", webshopId)
    )
    const gatewaysOfWebshop = useSelector<StoreState, IGatewayDto[] | undefined>((state) =>
        gatewaySelectors.getGatewaysForMerchant(state, merchantDetails?.swMerchant.swMerchantId ?? "")
    )?.filter((gateway) => gateway.webshopId == webshopId)
    const featuresDetails = useSelector((state: StoreState) => merchantSelectors.featuresDetailsForWebshop(state, webshopId))
    const clearhausApplicationState = useSelector<StoreState, IClearhausApplicationOnboardingState | undefined>((state) =>
        clearhausSelectors.applicationState(state, webshopId)
    )
    // const a2aAccounts = useSelector((state: StoreState) =>
    //     a2aSelectors.accounts(state, merchantDetails?.swMerchant.swMerchantId || "")
    // )

    const getSandboxOnboardingAction = (providerName: TProviderName) => {
        if (env !== "Sandbox") {
            return undefined
        }
        return async () => {
            await dispatch(sandboxOnboardingThunk(merchantDetails?.swMerchant.swMerchantId, webshopId, providerName))
        }
    }

    useEffect(() => {
        if (!merchantDetails) {
            return
        }

        dispatch(fetchWebshopFeaturesDetailsThunk(webshopId, false))
        dispatch(getOnboardingStatusThunk(merchantDetails.swMerchant.swMerchantId, webshopId, false))
        // dispatch(getA2aMerchantRelatedAccountsThunk(merchantDetails.swMerchant.swMerchantId, false))

        if (swiipePlan) {
            dispatch(getClearhausApplicationStateThunk(merchantDetails.swMerchant.swMerchantId, webshopId, false))
        }
    }, [merchantDetails, webshopId, swiipePlan, wProviderConf])

    return {
        webshop: {
            id: webshopId,
            details: webshopDetails,
            features: featuresDetails,
            providers: wProviderConf,
        },
        merchant: {
            isDeletionPending: merchantDeletionPending,
            country: merchantCountry,
            gateways: gatewaysOfWebshop,
        },
        global: {
            env,
            invoicingPrices: prices,
            swiipePlan,
        },
        clearhaus: {
            applicationState: clearhausApplicationState,
        },
        sandbox: {
            getSandboxOnboardingAction,
        },
        // a2aAccounts,
    }
}
