import cn from "classnames"
import React from "react"
import {
    GoBackButtonLink,
    ITableNavigationControl,
    PageContainer,
    PageWrapper,
    SpinnerContainer,
    TextButton,
    hasNonEmptyValue,
} from "swiipe.portal.shared"

import { useTranslation } from "react-i18next"
import "./TableItemDetails.scss"

interface ITableItemDetailsProps<T, TNavItem> {
    className: string
    item?: T
    onGoBack: () => void
    backText?: string
    getTopLeftConfig: (item: T) => {
        className?: string
        content: React.ReactNode
    }
    getTopRightConfig: (item: T) => {
        className?: string
        mainRow?: {
            title: string
            value: string
            hideColon?: boolean
            extraContent?: React.ReactNode
        }
        rows: (
            | { type: "titleValue"; title: string; value: string | React.ReactNode; className?: string; hideColon?: boolean }
            | { type: "custom"; content: React.ReactNode; className?: string }
            | undefined
            | false
            | ""
        )[]
    }
    renderBottom?: (item: T) => React.ReactNode
    navigationControl?: ITableNavigationControl<TNavItem>
    getNavigationTitle?: (item: TNavItem, isPrevious: boolean) => string
}

export const TableItemDetails = <T, TNavItem>({
    className,
    item,
    onGoBack,
    backText,
    getTopLeftConfig,
    getTopRightConfig,
    renderBottom,
    navigationControl,
    getNavigationTitle,
}: ITableItemDetailsProps<T, TNavItem>) => {
    const { t } = useTranslation()
    const topLeft = item && getTopLeftConfig(item)
    const topRight = item && getTopRightConfig(item)
    return (
        <>
            <PageWrapper widest disableMinHeight>
                <PageContainer classNameBody="table-item-details-header">
                    <GoBackButtonLink text={backText} onClick={() => onGoBack()} includeArrow />
                </PageContainer>
            </PageWrapper>
            <PageWrapper widest>
                <PageContainer classNameBody={cn("table-item-details", className)}>
                    <SpinnerContainer showSpinner={!item}>
                        {!!item && (
                            <div className="table-item-details__content">
                                <div className="table-item-details__content-top">
                                    <div className={cn("table-item-details__top-left", topLeft?.className)}>
                                        {topLeft?.content}
                                    </div>
                                    <div className={cn("table-item-details__top-right", topRight?.className)}>
                                        {topRight?.mainRow && (
                                            <div className="table-item-details__top-right-title-row-outer">
                                                <div className="table-item-details__top-right-title-row">
                                                    <span className="table-item-details__top-right-title-row-title">
                                                        {topRight.mainRow.title}
                                                        {topRight.mainRow.hideColon ? "" : ":"}
                                                    </span>
                                                    <span className="table-item-details__top-right-title-value">
                                                        {topRight.mainRow.value}
                                                    </span>
                                                </div>
                                                {topRight.mainRow.extraContent && (
                                                    <div className="table-item-details__top-right-title-row-extra">
                                                        {topRight.mainRow.extraContent}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {topRight?.rows.filter(hasNonEmptyValue).map((row, index) => {
                                            return (
                                                <div
                                                    className={cn("table-item-details__top-right-row", row.className)}
                                                    key={index}
                                                >
                                                    {row.type === "titleValue" && (
                                                        <span className="table-item-details__top-right-row-title">
                                                            {row.title}
                                                            {row.hideColon ? "" : ":"}
                                                        </span>
                                                    )}
                                                    {row.type === "titleValue" && (
                                                        <span className="table-item-details__top-right-row-value">
                                                            {row.value}
                                                        </span>
                                                    )}
                                                    {row.type === "custom" && row.content}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                {renderBottom && <div className="table-item-details__content-bottom">{renderBottom(item)}</div>}
                            </div>
                        )}
                    </SpinnerContainer>
                </PageContainer>
            </PageWrapper>
            {navigationControl && (
                <PageWrapper widest disableMinHeight>
                    <PageContainer>
                        <div className="table-item-details-footer">
                            {navigationControl.getPrevious?.() && (
                                <div className="table-item-details-footer__previous">
                                    <TextButton
                                        color="grey"
                                        text={
                                            "< " +
                                            (getNavigationTitle?.(navigationControl.getPrevious(), true) || t("table.previous"))
                                        }
                                        onClick={async () => {
                                            await navigationControl.goPrevious?.()
                                        }}
                                    />
                                </div>
                            )}
                            <div className="table-item-details-footer__spacer"></div>
                            {navigationControl.getNext?.() && (
                                <div className="table-item-details-footer__next">
                                    <TextButton
                                        color="grey"
                                        text={
                                            (getNavigationTitle?.(navigationControl.getNext(), false) || t("table.next")) + " >"
                                        }
                                        onClick={async () => {
                                            await navigationControl.goNext?.()
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </PageContainer>
                </PageWrapper>
            )}
        </>
    )
}
