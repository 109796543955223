import React from "react"
import { useTranslation } from "react-i18next"
import { SpinnerContainer, TitleWithAbsoluteHint, useSelector } from "swiipe.portal.shared"
import { openContactUsWidget } from "../../../services/contactUsService"
import { resolvePaymentTypeName } from "../../../services/paymentService"
import { StoreState } from "../../../store/StoreState"
import { IGatewayDto, gatewaySelectors } from "../../../store/reducers/gatewayReducer"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { userRelationSelectors } from "../../../store/reducers/userRelationReducer"
import { IResursGatewayConfig } from "../../../type/gatewayConfigs/IResursGatewayConfig"
import { injectElementIntoText } from "../../../util/translationUtils"
import { SharedCancelContainer } from "../../servicesonboarding/SharedCancelContainer"
import "./ResursDetailsStep.scss"

export interface IResursDetailsStep {
    showCancelService: boolean
    setShowCancelService: (showCancelService: boolean) => void
}

export const ResursDetailsStep = ({ showCancelService, setShowCancelService }: IResursDetailsStep) => {
    const { t } = useTranslation()
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)!
    const merchantId = currentUserRelation.id
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const selectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)
    const gateways = useSelector<StoreState, IGatewayDto[]>((state) =>
        gatewaySelectors.getSpecificGatewaysForWebshop(state, merchantId, selectedWebshopId ?? "", [
            "ResursInvoice",
            "ResursPartPayment",
            "ResursRevolvingCredit",
        ])
    )

    const isGatewayDataAvailable = gateways && gateways.length > 0 && gateways[0].configuration != undefined
    if (!gateways || !selectedWebshopId || !merchantDetails || !merchantId || !isGatewayDataAvailable) {
        return <SpinnerContainer className="mt-4 py-5" showSpinner={true} />
    }

    const countryCode = (gateways[0].configuration as IResursGatewayConfig).countryCode
    const resursStoreName = (gateways[0].configuration as IResursGatewayConfig).resursStoreName
    const resursStoreNationalId = (gateways[0].configuration as IResursGatewayConfig).resursStoreNationalId

    if (showCancelService) {
        return null
    }

    return (
        <SpinnerContainer showSpinner={!isGatewayDataAvailable}>
            <div id="resurs-onboarding__details-step">
                <span className="resurs-onboarding__store-information-title">{t("resursOnboarding.connectedstore")}</span>
                <div className="resurs-onboarding__store-information">
                    <div className="resurs-onboarding__store-country-code">{countryCode}</div>
                    <div className="resurs-onboarding__store-national-id">{resursStoreNationalId}</div>
                    <div className="resurs-onboarding__store-name">{resursStoreName}</div>
                </div>
                <TitleWithAbsoluteHint title={t("resursOnboarding.activePaymentTypesTitle")} hintIconRight>
                    {injectElementIntoText(
                        t("resursOnboarding.activePaymentTypesHint"),
                        "{{contactUs}}",
                        <span className="resurs-onboarding__contact-us-button" onClick={() => openContactUsWidget()}>
                            {t("common.contactUs")}
                        </span>
                    )}
                </TitleWithAbsoluteHint>
                <div className="resurs-onboarding__paymentTypes">
                    {gateways.map((gateway) => (
                        <div className="resurs-onboarding__paymentType" key={gateway.gatewayId}>
                            {resolvePaymentTypeName(gateway.paymentType).replace("Resurs ", "")}
                        </div>
                    ))}
                </div>
                <SharedCancelContainer cancelText={t("resursOnboarding.cancel")} onCancel={() => setShowCancelService(true)} />
            </div>
        </SpinnerContainer>
    )
}

export default ResursDetailsStep
