import IntlTelInput from "@andrewsantarin/react-intl-tel-input"
import "@andrewsantarin/react-intl-tel-input/dist/main.css"
import cn from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Input } from "reactstrap"
import { useSelector } from "swiipe.portal.shared"
import { TCountryFilter } from "../../../services/countriesService"
import { userSelectors } from "../../../store/userReducer"
import { DataType, TRegister, TSetValue, TWatch } from "../../../types/form/ReactHooksFormTypes"
import { valFuncPattern, valFuncRequired, validationPatterns } from "../../../utils/validationUtil"
import "./PhoneNumberInput.scss"

interface IPhoneNumberInputProps<T> {
    inputName: Extract<keyof T, string>
    defaultValue?: string
    placeholder?: string
    required?: boolean
    customErrorMsg?: string
    preferredCountry?: string
    countryFilter?: TCountryFilter
    register: TRegister
    getValues: (inputName: string) => any
    setValue: TSetValue<T>
    watch: TWatch<T>
    onDataChange?: (number: string, dialCode: string) => void
    onValidPhoneTyped?: () => void
    isDisabled?: boolean
}

export const PhoneNumberInput = <T extends DataType>({
    inputName,
    defaultValue,
    placeholder,
    required = true,
    customErrorMsg,
    preferredCountry,
    countryFilter,
    register,
    getValues,
    setValue,
    watch,
    onDataChange,
    onValidPhoneTyped,
    isDisabled,
}: IPhoneNumberInputProps<T>) => {
    const { t } = useTranslation()
    const userData = useSelector(userSelectors.userData)

    const [floatingLabelReduced, setFloatingLabelReduced] = useState(false)
    const [currentInputVal, setCurrentInputVal] = useState(defaultValue)

    const inputRef = useRef<IntlTelInput | null>()

    const phoneNumberChanged = (isValid: boolean, fullNumber: string, dialCode?: string) => {
        if (!isValid && required) {
            setValue(inputName, "")
            onDataChange && onDataChange("", "")
            return
        }

        if (!isValid && !required) {
            //if part of number is typed in
            //set value that will break validation pattern rule
            setValue(inputName, fullNumber.replace(`+${dialCode}`, "") === "" ? "" : " ")
            onDataChange && onDataChange("", "")
            return
        }

        //Remove whitespaces
        const phone = fullNumber.replace(/ /g, "")
        setValue(inputName, phone)

        onDataChange && onDataChange(dialCode ? phone.replace(`+${dialCode}`, "") : phone, dialCode || "")
    }

    const getErrorMessage = () => {
        if (customErrorMsg) {
            return customErrorMsg
        }

        return t("common.phone.errorMessage")
    }

    useEffect(() => {
        if (currentInputVal) {
            setFloatingLabelReduced(true)
            return
        }

        setFloatingLabelReduced(false)
    }, [currentInputVal])

    useEffect(() => {
        const hiddenInputValue = getValues(inputName)
        const intlTelInputRawValue = inputRef?.current?.getFullNumber(currentInputVal).replace(/ /g, "")

        if (!hiddenInputValue || hiddenInputValue === " ") {
            // do nothing on empty values, as we set hidden input empty if phone isn't valid
            return
        }

        // if hiddenValue and telInput are different than
        // setValue was triggered somewhere, so telInput
        // value also have to be updated
        if (hiddenInputValue !== intlTelInputRawValue) {
            setTimeout(() => {
                inputRef?.current?.setNumber(hiddenInputValue)
                // true magic, small timeout helps
                // `setNumber()` to properly work
                // right after component initialized
            }, 50)
            setCurrentInputVal(hiddenInputValue)
        }
    }, [watch(inputName)])

    return (
        <div className="phone-input-component">
            {!isDisabled && (
                <IntlTelInput
                    fieldName={inputName}
                    preferredCountries={[preferredCountry || userData?.user?.countryCode?.toLocaleLowerCase() || "dk"]}
                    onlyCountries={countryFilter?.map((c) => c.toLocaleLowerCase())}
                    onPhoneNumberChange={(isValid, value, selectedCountryData, fullNumber, extension) => {
                        //remove country code from input value
                        const codeReplaced = inputRef.current?.beforeSetNumber(value, inputRef.current.props)
                        //trigger onPhoneNumberChange if value changed
                        inputRef.current?.updateValFromNumber(codeReplaced, false, value !== codeReplaced)

                        phoneNumberChanged(isValid, fullNumber, selectedCountryData.dialCode)

                        setCurrentInputVal(value)
                        if (onValidPhoneTyped) {
                            onValidPhoneTyped()
                        }
                    }}
                    onSelectFlag={(currentNumber, selectedCountryData, fullNumber, isValid) => {
                        phoneNumberChanged(isValid, fullNumber, selectedCountryData.dialCode)
                    }}
                    defaultValue={defaultValue}
                    separateDialCode
                    ref={(ref) => (inputRef.current = ref)}
                    placeholder={""}
                    onPhoneNumberFocus={() => setFloatingLabelReduced(true)}
                    onPhoneNumberBlur={() => {
                        if (!currentInputVal) {
                            setFloatingLabelReduced(false)
                        }
                    }}
                />
            )}
            {!isDisabled && (
                <span className={cn("phone-input-floating-label", { reduced: floatingLabelReduced })}>
                    {placeholder ? placeholder : t("common.phone.label")}
                </span>
            )}
            <Input
                name={inputName}
                defaultValue={defaultValue}
                className={cn(!isDisabled && "hidden-input")}
                disabled={isDisabled}
                innerRef={register(
                    required
                        ? valFuncRequired(getErrorMessage())
                        : valFuncPattern(validationPatterns.phoneNumber, getErrorMessage())
                )}
            />
        </div>
    )
}
