import React, { useEffect, useState } from "react"
import cn from "classnames"
import "./TabsComponent.scss"

export interface ITabsComponent {
    tabs: ITab[]
    withActiveTabId?: string
    tabWidth?: number
    onSwitchActiveTabId?: (activeTabId: string) => void
}

interface ITab {
    id: string
    name: string
    component: React.ReactNode
}

const TabsComponent = ({ tabs, tabWidth, withActiveTabId, onSwitchActiveTabId }: ITabsComponent) => {
    const [activeTabId, setActiveTabId] = useState(withActiveTabId ? withActiveTabId : tabs[0]?.id || "")

    useEffect(() => {
        if (!onSwitchActiveTabId) {
            return
        }

        onSwitchActiveTabId(activeTabId)
    }, [activeTabId])

    const getTabOption = (name: string, id: string) => {
        return (
            <span key={id} className={cn({ active: activeTabId === id })} onClick={() => setActiveTabId(id)}>
                {name}
            </span>
        )
    }

    return (
        <div className="tabs-box">
            <div
                className="tabs"
                style={{
                    flexBasis: tabWidth ? tabWidth : 100,
                }}
            >
                {tabs.map((t) => getTabOption(t.name, t.id))}
            </div>
            <div className="content">
                {tabs.map((t) => {
                    return (
                        <div className={cn({ "d-none": t.id !== activeTabId })} key={t.id}>
                            {t.component}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default TabsComponent
