import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import { SpinnerContainer, useSelector } from "swiipe.portal.shared"
import { getMerchantContact, removeSchemeFromHostName } from "../../../services/merchantService"
import { navigationService } from "../../../services/navigationService"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { IResursOnboardingData, resursOnboardingThunk } from "../../../store/thunks/resursThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IResursStore } from "../../../type/resurs/IResursStore"
import SubmitButton from "../../buttons/SubmitButton"
import LegalTextLinkPopup from "../../legal/LegalTextLinkPopup"
import ResursSelectStoreRadio from "./ResursSelectStoreRadio"
import "./ResursSelectStoreStep.scss"

export interface IResursSelectStoreFormModel {
    selectedResursStoreId: string
}

export interface IResursSelectStoreStep {
    webshopId: string
    resursStores: IResursStore[]
}

export const ResursSelectStoreStep = ({ webshopId, resursStores }: IResursSelectStoreStep) => {
    const { t } = useTranslation()
    const form = useForm<IResursSelectStoreFormModel>()
    const dispatch = useReduxDispatch()
    const [isOnboarding, setIsOnboarding] = useState(false)
    const [selectedStore, setSelectedStore] = useState<string>(resursStores.length == 1 ? resursStores[0].resursStoreId : "")

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const swMerchantContact = getMerchantContact(merchantDetails)

    const webshopDetails = useSelector(merchantSelectors.merchantDetails)?.webshops.find(
        (webshop) => webshop.webshopId == webshopId
    )
    const webshopName = removeSchemeFromHostName(webshopDetails?.hostName ?? "")

    if (!merchantDetails) {
        return null
    }

    const onSubmit: SubmitHandler<IResursSelectStoreFormModel> = async (data) => {
        try {
            setIsOnboarding(true)

            const formData: IResursOnboardingData = {
                webshopIds: [webshopId],
                resursStoreId: data.selectedResursStoreId,
                swMerchantId: merchantDetails.swMerchant.swMerchantId,
                swMerchantEmail: swMerchantContact?.email || "",
                swMerchantName: merchantDetails.swMerchant.name,
                merchantContactName: swMerchantContact?.name || "",
                merchantContactPhone: swMerchantContact?.phone || "",
            }

            await dispatch(resursOnboardingThunk(formData, false))
            setIsOnboarding(false)
            navigationService.navigate("/resurs_details")
        } catch {
            //Catch to stop showing spinner
            setIsOnboarding(false)
        }
    }

    return (
        <div id="resurs-onboarding__select-store-step">
            <div className="resurs-onboarding__select-store-info">
                {t("resursOnboarding.selectStoreInfo", { webshopName: webshopName })}
            </div>
            <Form onSubmit={form.handleSubmit(onSubmit)} className="resurs-choose-stores__stores-form">
                <div className="resurs-onboarding__stores-radio-group">
                    {resursStores.map((resursStore, index) => (
                        <div key={resursStore.resursStoreId}>
                            <ResursSelectStoreRadio
                                form={form}
                                formModelName={"selectedResursStoreId"}
                                resursStore={resursStore}
                                isInitChecked={resursStores.length == 1}
                                selectedValue={selectedStore}
                                onSelected={(storeId) => setSelectedStore(storeId)}
                            />
                            {index + 1 < resursStores.length && <hr />}
                        </div>
                    ))}
                </div>
                <LegalTextLinkPopup
                    legalTextBeforeLink=""
                    className="terms-link resurs-onboarding__terms-link"
                    linkText={t("resursOnboarding.termsLinkText")}
                    termsType={"TermsResurs"}
                    centered
                ></LegalTextLinkPopup>
                <SpinnerContainer className="resurs-onboarding__activate-button" showSpinner={isOnboarding}>
                    <SubmitButton
                        className="mt-4"
                        disabled={!selectedStore}
                        dark
                        noBorder
                        containerClass="submit-btn"
                        formState={form.formState}
                    >
                        {t("commononboardingtexts.submitTheService")}
                    </SubmitButton>
                </SpinnerContainer>
            </Form>
        </div>
    )
}

export default ResursSelectStoreStep
