import React from "react"
import SwiipeLogoImage from "../../../images/swiipe-logo.png"
import { useTranslation } from "react-i18next"
import cn from "classnames"
import "./FormHeader.scss"

interface IFormHeaderProps {
    title?: string
    titleWeightMedium?: boolean
    showSandboxTitle?: boolean
}

const FormHeader = ({ title = "", titleWeightMedium, showSandboxTitle }: IFormHeaderProps) => {
    const { t } = useTranslation()

    return (
        <div className="form-head">
            <img src={SwiipeLogoImage} className="img-center mt-3" alt="swiipe-logo" />
            {showSandboxTitle ? (
                <span className="sandbox-label">SANDBOX</span>
            ) : (
                <h2 className={cn("title text-center header-message mt-2", titleWeightMedium && "weight-medium")}>
                    {title ? title : t("common.title")}
                </h2>
            )}
        </div>
    )
}

export default FormHeader
