import { ActionsUnion, createAction } from "swiipe.portal.shared"
import { TSwiipePlan } from "../../type/IProvider"
import { IWebshopDetails } from "../../type/IWebshopDetails"
import { IFeatureDetails, IWebshopFeaturesDetails } from "../../type/feature/IFeatureDetails"
import { IWinbackDetails, IWinbackSmsSenderNameStatus } from "../../type/winback/winbackDetails"
import { StoreState } from "../StoreState"
import { IApiKey } from "./../../type/IApiKey"
import { MixedMerchantDetails } from "./../../type/mixedmerchantdetails"

export type MerchantState = Readonly<{
    merchantDetails: { [merchantId: string]: MixedMerchantDetails }
    swiipePlan: { [merchantId: string]: TSwiipePlan }
    apiKeys: { [merchantId: string]: IApiKey[] }
    selectedWebshops: { [merchantId: string]: string }
    featureDetails: {
        [webshopId: string]: IWebshopFeaturesDetails
    }
    webshopDetails: {
        [webshopId: string]: IWebshopDetails
    }
    winbackDetails: {
        [webshopId: string]: IWinbackDetails
    }
    winbackSmsSenderNameStatuses: {
        [merchantId: string]: IWinbackSmsSenderNameStatus[]
    }
}>

const initialState: MerchantState = {
    merchantDetails: {},
    swiipePlan: {},
    apiKeys: {},
    selectedWebshops: {},
    featureDetails: {},
    webshopDetails: {},
    winbackDetails: {},
    winbackSmsSenderNameStatuses: {},
}

export const merchantReducerActions = {
    setMerchantDetails: (merchantId: string, merchantDetails: MixedMerchantDetails) =>
        createAction("APP/SET_MERCHANGT_DETAILS", { merchantId, merchantDetails }),
    setSwiipePlan: (merchantId: string, swiipePlan: TSwiipePlan) =>
        createAction("APP/SET_SWIIPE_PLAN", { merchantId, swiipePlan }),
    setMerchantApiKeys: (merchantId: string, apiKeys: IApiKey[]) => createAction("APP/SET_API_KEYS", { merchantId, apiKeys }),
    setSelectedWebshop: (merchantId: string, webshopId: string) =>
        createAction("APP/SET_SELECTED_WEBSHOP_PER_MERCHANT", { merchantId, webshopId }),
    setFeatureDetails: (webshopId: string, details: IFeatureDetails) =>
        createAction("APP/SET_FEATURE_STATUS", { webshopId, details }),
    setWebshopDetails: (webshopId: string, details: IWebshopDetails) =>
        createAction("APP/SET_WEBSHOP_DETAILS", { webshopId, details }),
    setWinbackDetails: (webshopId: string, details: IWinbackDetails) =>
        createAction("APP/SET_WINBACK_DETAILS", { webshopId, details }),
    setWinbackSmsSenderNameStatuses: (merchantId: string, statues: IWinbackSmsSenderNameStatus[]) =>
        createAction("APP/SET_WINBACK_SMS_SENDER_NAME_STATUSES", { merchantId, statues }),
}

export type MerchantActions = ActionsUnion<typeof merchantReducerActions>

export const merchantReducer = (state: MerchantState = initialState, action: MerchantActions) => {
    switch (action.type) {
        case "APP/SET_FEATURE_STATUS": {
            return {
                ...state,
                featureDetails: {
                    ...state.featureDetails,
                    [action.webshopId]: {
                        ...state.featureDetails[action.webshopId],
                        [action.details.featureType]: {
                            ...action.details,
                        },
                    },
                },
            }
        }
        case "APP/SET_MERCHANGT_DETAILS": {
            return {
                ...state,
                merchantDetails: {
                    ...state.merchantDetails,
                    [action.merchantId]: action.merchantDetails,
                },
            }
        }
        case "APP/SET_SWIIPE_PLAN": {
            return {
                ...state,
                swiipePlan: {
                    ...state.swiipePlan,
                    [action.merchantId]: action.swiipePlan,
                },
            }
        }
        case "APP/SET_API_KEYS": {
            return {
                ...state,
                apiKeys: {
                    ...state.apiKeys,
                    [action.merchantId]: action.apiKeys,
                },
            }
        }
        case "APP/SET_SELECTED_WEBSHOP_PER_MERCHANT": {
            return {
                ...state,
                selectedWebshops: {
                    ...state.selectedWebshops,
                    [action.merchantId]: action.webshopId,
                },
            }
        }
        case "APP/SET_WEBSHOP_DETAILS": {
            return {
                ...state,
                webshopDetails: {
                    ...state.webshopDetails,
                    [action.webshopId]: action.details,
                },
            }
        }
        case "APP/SET_WINBACK_DETAILS": {
            return {
                ...state,
                winbackDetails: {
                    ...state.winbackDetails,
                    [action.webshopId]: action.details,
                },
            }
        }
        case "APP/SET_WINBACK_SMS_SENDER_NAME_STATUSES": {
            return {
                ...state,
                winbackSmsSenderNameStatuses: {
                    ...state.winbackSmsSenderNameStatuses,
                    [action.merchantId]: action.statues,
                },
            }
        }
    }

    return state
}

export const merchantSelectors = {
    merchantDetails: (state: StoreState) =>
        state.userRelation.currentRelation ? state.merchant.merchantDetails[state.userRelation.currentRelation.id] : undefined,
    swiipePlan: (state: StoreState, swMerchantId?: string) => {
        if (swMerchantId) {
            return state.merchant.swiipePlan[swMerchantId]
        }
        return state.userRelation.currentRelation ? state.merchant.swiipePlan[state.userRelation.currentRelation.id] : undefined
    },
    apiKeys: (state: StoreState) =>
        state.userRelation.currentRelation ? state.merchant.apiKeys[state.userRelation.currentRelation.id] : undefined,
    selectedWebshopId: (state: StoreState) =>
        state.userRelation.currentRelation ? state.merchant.selectedWebshops[state.userRelation.currentRelation.id] : undefined,
    featuresDetailsForWebshop: (state: StoreState, webshopId: string) => state.merchant.featureDetails[webshopId],
    featureDetails: (state: StoreState) => state.merchant.featureDetails,
    webshopDetails: (state: StoreState, webshopId: string) =>
        state.merchant.webshopDetails[webshopId] ? state.merchant.webshopDetails[webshopId] : undefined,
    winbackDetails: (state: StoreState, webshopId: string) =>
        state.merchant.winbackDetails[webshopId] ? state.merchant.winbackDetails[webshopId] : undefined,
    winbackSmsSenderNameStatuses: (state: StoreState, merchantId: string) =>
        state.merchant.winbackSmsSenderNameStatuses[merchantId]
            ? state.merchant.winbackSmsSenderNameStatuses[merchantId]
            : undefined,
}
