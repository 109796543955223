import { getCulture } from "swiipe.portal.shared"
import { padZero } from "./numberUtil"
import { removeCharInString, swapCharsInString } from "./stringUtil"

export function getStartOfMonth(date: Date) {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1, 0, 0, 0, 0))
}

export function getSameTimeLastMonth(date: Date) {
    const daysInPreviousMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate()
    const dateExistsInLastMonth = daysInPreviousMonth >= date.getDate()
    const newDate = new Date(
        date.getFullYear(),
        dateExistsInLastMonth ? date.getMonth() - 1 : date.getMonth(),
        dateExistsInLastMonth ? date.getDate() : 0,
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
    )
    return newDate
}

export function addHours(date: Date, hours: number) {
    date = new Date(date.valueOf())
    date.setUTCHours(date.getUTCHours() + hours)
    return date
}

export function addDays(date: Date, days: number) {
    date = new Date(date.valueOf())
    date.setUTCDate(date.getUTCDate() + days)
    return date
}

export function addMonths(date: Date, months: number) {
    date = new Date(date.valueOf())
    date.setUTCMonth(date.getUTCMonth() + months)
    return date
}

export function addYears(date: Date, years: number) {
    date = new Date(date.valueOf())
    date.setUTCFullYear(date.getUTCFullYear() + years)
    return date
}

export function getDaysBetween(from: Date, to: Date) {
    return Math.round((to.getTime() - from.getTime()) / (1000 * 3600 * 24))
}

export function getMonthsBetween(from: Date, to: Date) {
    return (to.getFullYear() - from.getFullYear()) * 12 + (to.getMonth() - from.getMonth())
}

export function formatLocalShortDate(dateOrStr: Date | string | undefined) {
    const date = typeof dateOrStr === "string" ? new Date(dateOrStr) : dateOrStr
    if (!date) {
        return ""
    }
    return date.toLocaleDateString(getCulture())
}

export function formatLocalDate(dateOrStr: Date | string | undefined, dateFormat: "full" | "long" | "medium" | "short") {
    const date = typeof dateOrStr === "string" ? new Date(dateOrStr) : dateOrStr
    if (!date) {
        return ""
    }
    return date.toLocaleDateString(getCulture(), {
        dateStyle: dateFormat,
    })
}

export function formatShortDateWithFormat(date: Date, format: string) {
    const dayOfMonth = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    let result = format
    if (format.includes("dd")) {
        result = result.replace("dd", padZero(dayOfMonth, 2))
    }
    if (format.includes("d")) {
        result = result.replace("d", dayOfMonth + "")
    }
    if (format.includes("MM")) {
        result = result.replace("MM", padZero(month, 2))
    }
    if (format.includes("M")) {
        result = result.replace("M", month + "")
    }
    if (format.includes("yyyy")) {
        result = result.replace("yyyy", year + "")
    }
    if (format.includes("yy")) {
        result = result.replace("yy", (year + "").substring(2, 4))
    }
    return result
}

export function parseDateWithFormat(dateStr: string, format: string) {
    let year = 0
    let month = 0
    let date = 0

    if (format.includes("dd")) {
        const index = format.indexOf("dd")
        date = parseInt(dateStr.slice(index, index + 2))
    } else if (format.includes("d")) {
        const index = format.indexOf("d")
        date = parseInt(dateStr.slice(index, index + 1))
    }

    if (format.includes("MM")) {
        const index = format.indexOf("MM")
        month = parseInt(dateStr.slice(index, index + 2))
    } else if (format.includes("M")) {
        const index = format.indexOf("M")
        month = parseInt(dateStr.slice(index, index + 1))
    }

    if (format.includes("yyyy")) {
        const index = format.indexOf("yyyy")
        year = parseInt(dateStr.slice(index, index + 4))
    } else if (format.includes("yy")) {
        const index = format.indexOf("yy")

        const parsedValue = parseInt(dateStr.slice(index, index + 2))
        const currentYear = new Date().getFullYear()

        year = parsedValue > currentYear - 2000 ? parsedValue : 2000 + parsedValue
    }

    return new Date(Date.UTC(year, month - 1, date))
}

export function parseShortDateWithFormat(dateStr: string, format: string) {
    const formatParts = format.split(/[.-/_]/)
    const dateParts = dateStr.split(/[.-/_]/)
    const yearIndex = formatParts.findIndex((f) => f.includes("y"))
    const monthIndex = formatParts.findIndex((f) => f.includes("M"))
    const dateIndex = formatParts.findIndex((f) => f.includes("d"))
    return new Date(Date.UTC(parseInt(dateParts[yearIndex]), parseInt(dateParts[monthIndex]) - 1, parseInt(dateParts[dateIndex])))
}

export function parseShortDateWithLocalFormat(dateStr: string) {
    const format = getLocaleDateFormat()
    return parseShortDateWithFormat(dateStr, format)
}

export function isValidDate(date: Date) {
    return Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date.getTime())
}

export function isWithinYears(date: Date, years: number) {
    return date.getTime() > addYears(new Date(), -years).getTime() && date.getTime() < addYears(new Date(), years).getTime()
}

let cachedDateFormat: string | undefined = undefined
let cachedDateFormatWithZeroPadding: string | undefined = undefined
export function getLocaleDateFormat(forceZeroPadding?: boolean): string {
    if (!forceZeroPadding && cachedDateFormat) {
        return cachedDateFormat
    }
    if (forceZeroPadding && cachedDateFormatWithZeroPadding) {
        return cachedDateFormatWithZeroPadding
    }
    let testDate = new Date(2031, 5, 7).toLocaleDateString(getCulture())
    testDate = testDate.replace("2031", "yyyy")
    testDate = testDate.replace("31", "yy")
    testDate = testDate.replace("06", "MM")
    testDate = testDate.replace("6", forceZeroPadding ? "MM" : "M")
    testDate = testDate.replace("07", "dd")
    testDate = testDate.replace("7", forceZeroPadding ? "dd" : "d")
    if (forceZeroPadding) {
        cachedDateFormatWithZeroPadding = testDate
    } else {
        cachedDateFormat = testDate
    }
    return testDate
}

let cachedReadableDateFormat: string | undefined = undefined
export function getReadableLocaleDateFormat(): string {
    if (cachedReadableDateFormat) {
        return cachedReadableDateFormat
    }

    let testDate = new Date(2031, 5, 7).toLocaleDateString(getCulture())
    testDate = testDate.replace("2031", "yyyy")
    testDate = testDate.replace("31", "yy")
    testDate = testDate.replace("06", "mm")
    testDate = testDate.replace("6", "mm")
    testDate = testDate.replace("07", "dd")
    testDate = testDate.replace("7", "dd")

    cachedReadableDateFormat = testDate

    return testDate
}

let cachedStringDateFormat: string | undefined = undefined
export function getStringLocaleDateFormat(): string {
    if (cachedStringDateFormat) {
        return cachedStringDateFormat
    }

    let testDate = new Date(2031, 5, 7).toLocaleDateString(getCulture())
    testDate = testDate.replace("2031", "yyyy")
    testDate = testDate.replace("31", "yy")
    testDate = testDate.replace("06", "MM")
    testDate = testDate.replace("6", "M")
    testDate = testDate.replace("07", "dd")
    testDate = testDate.replace("7", "d")

    cachedStringDateFormat = testDate

    return testDate
}

export function ensurePartialDateString(input: string, readableFormat: string): { newValue: string; didSwap: boolean } {
    let didSwap = false
    const specialChars = [".", "-", ",", "_", " ", "/"]
    let result = ""
    let modifiedInput = input
    let inputCharPos = -1
    for (let i = 0; i < readableFormat.length; i++) {
        inputCharPos += 1
        const readableFormatChar = readableFormat[i]
        const char = modifiedInput.length - 1 < inputCharPos ? "" : modifiedInput[inputCharPos]
        if (readableFormatChar === char) {
            result += char
            continue
        }
        if (specialChars.includes(char) && !specialChars.includes(readableFormatChar)) {
            if (inputCharPos === 0 || isNaN(parseInt(modifiedInput[inputCharPos - 1]))) {
                // Two non-int chars in a row - add format char
                result += readableFormatChar
            } else {
                // input probably uses format with 1 char instead of 2, e.g. "d" instead of "dd"
            }
            inputCharPos -= 1
            continue
        }
        if (isNaN(parseInt(char))) {
            modifiedInput = removeCharInString(modifiedInput, inputCharPos)
            result += readableFormatChar
            continue
        }
        // integer
        if (specialChars.includes(readableFormatChar)) {
            // integer on wrong spot (probably just entered), move it to next char
            const oldInput = modifiedInput
            modifiedInput = swapCharsInString(modifiedInput, inputCharPos, inputCharPos + 1)
            didSwap = didSwap || oldInput !== modifiedInput
            result += readableFormatChar
            continue
        }
        // Correctly placed integer
        result += char
    }
    return { newValue: result, didSwap }
}

export function getDateUTCFilteringRange(from: Date | undefined, to: Date | undefined) {
    return {
        dateFromUTC: from && new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, 0)),
        dateToUTC: to && new Date(Date.UTC(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59, 59, 999)),
    }
}

export function getDateRangeStringified(fromDate?: Date, toDate?: Date) {
    const fromDateStr =
        fromDate?.toLocaleDateString(getCulture(), {
            dateStyle: "medium",
        }) || ""
    const toDateStr =
        toDate?.toLocaleDateString(getCulture(), {
            dateStyle: "medium",
        }) || ""

    if (fromDateStr === toDateStr) {
        return fromDateStr
    }
    return `${fromDateStr} - ${toDateStr}`
}
