import { AxiosRequestConfig } from "axios"
import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { ErrorHandler, ServerError, getErrorMessageToErrorType } from "../services/errorService"
import { serverServiceExecuteRequest } from "../services/serverService"
import { SharedStoreState } from "../store/SharedStoreState"
import { sharedConfigurationSelectors } from "../store/sharedConfigurationReducer"
import { IApiEndpoint } from "../types/request/IApiEndpoint"
import { THttpMethod } from "../types/request/THttpMethod"
import { serializeRequestAsFormData } from "../utils/formUtil"
import { addQueryParamsToUrl } from "../utils/urlUtil"
import { addModalThunk } from "./modalThunks"

// Check SPA errorHandlers - argument changed

export interface RequestConfig {
    noErrorAlert?: boolean
    defaultErrorMessage?: string
    errorHandlers?: ErrorHandler[]
    asFormData?: boolean
}

export const createUrlThunk =
    (
        endpointConfig: IApiEndpoint,
        params: { [key: string]: any }
    ): ThunkAction<Promise<string>, SharedStoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const endpointBase = sharedConfigurationSelectors.endpointConfig(getState(), endpointConfig.endpoint)
        const endpoint = endpointBase + endpointConfig.path
        if (params) {
            return addQueryParamsToUrl(endpoint, params)
        }
        return endpoint
    }

export const requestThunk =
    <T>(
        endpointConfig: IApiEndpoint,
        axiosConfig?: AxiosRequestConfig,
        requestConfig?: RequestConfig
    ): ThunkAction<Promise<T>, SharedStoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const endpointBase = sharedConfigurationSelectors.endpointConfig(getState(), endpointConfig.endpoint)
        const response = await dispatch(
            makeRequestThunk<T>(
                endpointConfig.method,
                endpointBase + endpointConfig.path,
                {
                    ...axiosConfig,
                    withCredentials: endpointConfig.authentication === "login",
                },
                requestConfig
            )
        )
        return response
    }

export const makeRequestThunk =
    <T>(
        method: THttpMethod,
        url: string,
        axiosConfig?: AxiosRequestConfig,
        requestConfig?: RequestConfig
    ): ThunkAction<Promise<T>, SharedStoreState, null, AnyAction> =>
    async (dispatch) => {
        if (requestConfig?.asFormData && axiosConfig) {
            axiosConfig.data = serializeRequestAsFormData(axiosConfig.data)
        }

        try {
            const response = await serverServiceExecuteRequest<T>(method, url, axiosConfig, requestConfig)
            return response
        } catch (error) {
            const err = error as any
            if (!!requestConfig?.noErrorAlert || !err.errorToAlert) {
                throw error
            }

            if (err.isFallbackError) {
                if (defaultFallbackErrorHandler) {
                    await defaultFallbackErrorHandler(err)
                } else {
                    await dispatch(addModalThunk({ type: "error", errorMessage: err.errorToAlert }))
                }
                throw error
            }

            let errorHandled = false

            const errorHandlers =
                requestConfig && !!requestConfig.errorHandlers
                    ? requestConfig.errorHandlers.filter((h) => h.errorType === err.errorType || h.errorCode === err.errorCode)
                    : []

            for (let i = 0; i < errorHandlers.length; i++) {
                const handler = errorHandlers[i]
                if (!errorHandled) {
                    errorHandled = await handler.handleError(err, dispatch)
                }
            }

            if (errorHandled) {
                throw error
            }

            const errorMessageFromMapping = getErrorMessageToErrorType().find((m) => m.errorType === err.errorType)
            if (errorMessageFromMapping) {
                await dispatch(
                    addModalThunk({
                        type: "error",
                        errorMessage: errorMessageFromMapping.errorMessage ?? err.errorMessage ?? "",
                    })
                )
                throw error
            }

            await dispatch(addModalThunk({ type: "error", errorMessage: err.errorToAlert }))
            throw error
        }
    }

export const getUrlFromEndpointThunk =
    (
        endpointConfig: IApiEndpoint,
        paramsForGetRequest?: { [key: string]: string | number | boolean | undefined }
    ): ThunkAction<string, SharedStoreState, null, AnyAction> =>
    (dispatch, getState) => {
        const endpointBase = sharedConfigurationSelectors.endpointConfig(getState(), endpointConfig.endpoint)
        const endpoint = endpointBase + endpointConfig.path
        if (paramsForGetRequest) {
            return addQueryParamsToUrl(endpoint, paramsForGetRequest)
        }
        return endpoint
    }

type ICustomErrorFallbackHandler = (err: ServerError) => Promise<void>
let defaultFallbackErrorHandler: ICustomErrorFallbackHandler | undefined
export function setDefaultFallbackErrorhandler(handler: ICustomErrorFallbackHandler) {
    defaultFallbackErrorHandler = handler
}
