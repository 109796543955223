const creditCardHostedFieldsStyle = `
/* RenderAsFloatingLabel */

.single-iframe-input-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.label-input-floating {
  top: 32.5%!important;
  color: #222324 !important;
  font-size: 14px !important;
  opacity: 0.7 !important;
}

.label-input-floating.active {
  margin-top: -12px !important;
}

#label-cccard.label-input-floating.active,
.hosted-input-container .input-container .label-input-floating.active {
  padding-left:46px!important;
  transform: scale(1)!important;
  font-size: 0.8rem!important;
}

.hosted-input-container .input-container input.floating-input {
  padding-top:10px;
}

html,
.hosted-input-container,
.input-container,
p.control {
  background: transparent;
  min-width: unset;
}

.hosted-input-container {
  margin: 0;
}

.single-iframe-input-container > div:nth-child(1) {
  width: 100%;
}

.single-iframe-input-container > div:nth-child(2) {
  width: calc(50%);
}

.single-iframe-input-container > div:nth-child(3) {
  width: calc(50%);
}


.hosted-input-container .input-container input {
  padding-left:46px!important;
  font-size: 14px;
  border: 1px solid #d4d4d4;
  border-radius: 3px!important;
  box-sizing: border-box;
}

.single-iframe-input-container > div:nth-child(2) input {
  border-top-right-radius:0!important;
  border-bottom-right-radius:0!important;
}


.single-iframe-input-container > div:nth-child(3) input {
  border-left:0!important;
  border-top-left-radius:0!important;
  border-bottom-left-radius:0!important;
}


#cvv-icon-container .icon {
  null
}

.creditcardNumber-container #creditcard-icon-container .creditcard-icon.default {
  null
}

#expiry_mm_yyyy-icon-container .icon {
  null
}


.creditcardNumber-container input, .cvv-container input, #expirymmyyyy-input {
  letter-spacing: 1.7px;
}

.cvv-container.input-container:hover label.label {
  display:block;
}


.cvv-container label.label {
  display:none;
  position: fixed;
  z-index: 99999999999999999999;
  top: 17px;
  font-size: 12px;
  background: #686868;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 3px;
}

.cvv-container label.label::after {
  border: #686868;
  background: #686868;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  content: " ";
  position: absolute;
  bottom: -6px;
  left: 50px;
}

@media only screen and (max-width: 360px) {
  .cvv-container label.label {
    left: 13px;
    top: 0px;
    width: 250px;
  }

  .cvv-container label.label::after {
    left:220px;
  }
}



.cvv-container input {
  background-color: #ffffff !important;
  margin-top:5px;
}



.hosted-input-container .input-container {
  overflow: hidden;
}

.hosted-input-container .input-container input {
    outline: 0;
    width: 100%;
    border: 1px solid #d4d4d4;
    box-sizing: border-box;
    border-radius: 0px;
    background-color: #fff;
    display: block;
    height: 46px;
    line-height: 25.6px;
}

.cvv-container.input-container input {
  background-image: url(https://test-cdn.swiipe.com/sharedassets/media/payments/assets/cvc-card-info.png);
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: 95% center;
}

.hosted-input-container .creditcardNumber-container #creditcard-icon-container .creditcard-icon {
  display:block !important;
}

.hosted-input-container .input-container .form-error {
  top: 22px !important; 
}
.hosted-input-container .input-container input.error {
  border: 1px solid #ff3767 !important;
  opacity: 0.6;
}
.hosted-input-container .input-container input.error:focus {
  border: 1px solid  #d4d4d4 !important;
  opacity: 1;
}

.cvv-container.input-container .form-error {
  right: 55px;  
}

@media only screen and (max-width: 530px) {
  .form-error {
    display:none !important
  }
}

@media only screen and (max-width: 510px) {
  .single-iframe-input-container > div {  
    width: 100%;
    display: block;
    position: unset;
  }

  .cvv-container.input-container input {
    width: 100%;
    background-position: 95% center;
    border: 1px solid #d4d4d4 !important;
    border-radius: 0px!important;
  }

  .input-container {
    position: relative !important;
  }

  .hosted-input-container .form-error {
    display: block;
    top: 0;
  }

}


`
export default creditCardHostedFieldsStyle
