import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Input } from "reactstrap"
import { FieldsetFormGroup, PageContainer, PageWrapper, SpinnerContainer, TextButton, useSelector } from "swiipe.portal.shared"
import ViabillRegistrationForm from "../../../components/form/paymentsonboarding/ViabillRegistrationForm"
import LegalTextLinkPopup from "../../../components/legal/LegalTextLinkPopup"
import CancelService from "../../../components/merchant/CancelService"
import GoBackContainer from "../../../components/navigations/GoBackContainer"
import { SharedCancelContainer } from "../../../components/servicesonboarding/SharedCancelContainer"
import { getImage } from "../../../services/imageLanguageService"
import { removeSchemeFromHostName } from "../../../services/merchantService"
import { useRequiredUserRelations } from "../../../services/userRelationService"
import { StoreState } from "../../../store/StoreState"
import { IGatewayDto, gatewaySelectors } from "../../../store/reducers/gatewayReducer"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { getGatewaysForMerchantThunk } from "../../../store/thunks/gatewayThunks"
import { sendServiceCancellationEmailThunk } from "../../../store/thunks/swiipeServicesThunks"
import { getViabillOnboardingDataThunk, viabillDisableOnboardingThunk } from "../../../store/thunks/viabillThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../../type/mixedmerchantdetails"
import "./ViabillOnboardingPage.scss"

interface IViabillOnboardingPageProps {
    webshopIds?: string[]
    isEdit?: boolean
    onGoBack: () => void
    onOnboarded: () => void
}

interface IViabillOnboardingPageInnerProps extends IViabillOnboardingPageProps {
    merchantDetails: MixedMerchantDetails
    selectedWebshopId: string
}

const ViabillOnboardingPage = (props: IViabillOnboardingPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const selectedWebshopId =
        props.webshopIds && props.webshopIds?.length > 0 ? props.webshopIds[0] : useSelector(merchantSelectors.selectedWebshopId)

    if (!merchantDetails || !merchantDetails.webshops.length || !selectedWebshopId) {
        return null
    }

    return <ViabillOnboardingPageInner merchantDetails={merchantDetails} selectedWebshopId={selectedWebshopId} {...props} />
}

const ViabillOnboardingPageInner = ({
    merchantDetails,
    selectedWebshopId,
    isEdit,
    onGoBack,
    onOnboarded,
}: IViabillOnboardingPageInnerProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()

    const [merchantApiKey, setMerchantApiKey] = useState("")
    const [showCancelService, setShowCancelService] = useState(false)

    const webshopName = removeSchemeFromHostName(
        merchantDetails.webshops.find((w) => w.webshopId == selectedWebshopId)?.hostName || ""
    )

    useEffect(() => {
        if (isEdit && selectedWebshopId) {
            ;(async () => {
                const onboardingData = await dispatch(getViabillOnboardingDataThunk(selectedWebshopId))

                setMerchantApiKey(onboardingData.apiKey)
            })()
        }
    }, [isEdit, selectedWebshopId])

    const merchantId = merchantDetails.swMerchant.swMerchantId
    useEffect(() => {
        dispatch(getGatewaysForMerchantThunk(merchantId, false))
    }, [merchantId])

    const gateway = useSelector<StoreState, IGatewayDto | undefined>((state) =>
        gatewaySelectors.getGateway(state, merchantId, "Viabill", selectedWebshopId)
    )

    return (
        <>
            {showCancelService && gateway && (
                <CancelService
                    serviceName={t("viabillOnboarding.viabill")}
                    cancelText={t("cancelService.viabill.cancelText", { webshopName })}
                    customCancelledText={t("cancelService.viabill.cancelledText")}
                    onGoBack={() => setShowCancelService(false)}
                    onCancel={async () => {
                        await dispatch(viabillDisableOnboardingThunk(merchantId, selectedWebshopId, gateway.gatewayId))

                        await dispatch(
                            sendServiceCancellationEmailThunk(
                                merchantId,
                                "ViabillCancellationEmail",
                                t("serviceNames.viabill"),
                                true
                            )
                        )
                    }}
                />
            )}
            <PageWrapper className={cn({ "d-none": showCancelService })}>
                {isEdit && <GoBackContainer goBackText={t("commononboardingtexts.backToAllService")} onGoBack={onGoBack} />}
                <PageContainer noHeader={isEdit} disableDefaultPaddingBottom>
                    <div className="viabill-onboarding-container">
                        <div className="viabill-form-head">
                            <img className="viabill-logo" src={getImage("viabillLogo")} />
                        </div>

                        {!isEdit && (
                            <>
                                <ViabillRegistrationForm
                                    onOnboarded={onOnboarded}
                                    webshopId={selectedWebshopId}
                                    merchantDetails={merchantDetails}
                                />
                                <TextButton
                                    text={t("commononboardingtexts.back")}
                                    className={cn(isEdit ? "mt-5" : "mt-4", "mb-4")}
                                    onClick={async () => onGoBack()}
                                />
                            </>
                        )}

                        {isEdit && (
                            <div className="viabill-edit-container">
                                <p className="edit-mode-text mt-5 mb-4 text-center">{t("viabillOnboarding.enabledNote")}</p>
                                <div className="viabill-edit-form my-5">
                                    <SpinnerContainer showSpinner={!merchantApiKey}>
                                        <FieldsetFormGroup field>
                                            <span className="edit-mode-text blue">{t("viabillOnboarding.merchantApiKey")}</span>
                                            <Input name="apiKey" disabled defaultValue={merchantApiKey} />
                                        </FieldsetFormGroup>
                                    </SpinnerContainer>

                                    <LegalTextLinkPopup
                                        legalTextBeforeLink=""
                                        legalTextAfterLink=""
                                        linkText={t("plugins.onboardedDescription.swiipeTerms") + t("serviceNames.viabill")}
                                        termsType={"TermsViaBill"}
                                        className="mt-5 checkbox-text"
                                        centered
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </PageContainer>
                {isEdit && (
                    <SharedCancelContainer
                        cancelText={t("viabillOnboarding.cancel.buttonText", { webshopName: webshopName })}
                        onCancel={() => setShowCancelService(true)}
                    />
                )}
            </PageWrapper>
        </>
    )
}

export default ViabillOnboardingPage
