import React, { useEffect, useState } from "react"
import ReactDatePicker from "react-datepicker"
import { Input } from "reactstrap"
import { FieldsetFormGroup, TRegister, TSetValue, TTriggerValidation, valFuncRequired } from "swiipe.portal.shared"
import { addYears, formatShortDateWithFormat, getStringLocaleDateFormat } from "../../../util/dateUtil"
import "./DateInput.scss"

interface IDateInput {
    name: string

    placeholder?: string
    errorMessage?: string
    required?: boolean

    register: TRegister
    setValue: TSetValue<any>
    validationTrigger: TTriggerValidation<any>

    valueDateFormat: string
    country?: string
    hiddenForCountries?: string[]
}

const DateInput = ({
    name,
    placeholder,
    errorMessage,
    required,
    country,
    register,
    setValue,
    validationTrigger,
    valueDateFormat,
    hiddenForCountries,
}: IDateInput) => {
    const hiddenDatePicker = country ? hiddenForCountries?.includes(country) : false
    const [dateSelected, setDateSelected] = useState<Date | undefined>(undefined)

    const setDateOfBirthInputValue = (date: Date) => {
        setValue(name, formatShortDateWithFormat(date, valueDateFormat))
        validationTrigger()
    }

    useEffect(() => {
        validationTrigger()
        if (!dateSelected) {
            return
        }
        setDateOfBirthInputValue(dateSelected)
    }, [country])

    return (
        <div id="date-of-birth-input-container">
            <Input
                className="d-none"
                name={name}
                type="text"
                innerRef={required ? register(valFuncRequired(hiddenDatePicker ? "" : errorMessage ?? "")) : register}
            />
            {!hiddenDatePicker && (
                <FieldsetFormGroup field>
                    <ReactDatePicker
                        placeholderText={placeholder}
                        selected={dateSelected}
                        className="form-control"
                        onChange={(date) => {
                            if (date) {
                                setDateSelected(date)
                                setDateOfBirthInputValue(date)
                            }
                        }}
                        minDate={addYears(new Date(), -100)}
                        maxDate={new Date()}
                        dateFormat={getStringLocaleDateFormat()}
                        showYearDropdown
                        showMonthDropdown
                    />
                </FieldsetFormGroup>
            )}
        </div>
    )
}

export default DateInput
