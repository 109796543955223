import React from "react"
import { useTranslation } from "react-i18next"
import { useCurrentRoute } from "react-navi"
import { PageWrapper, sharedConfigurationSelectors, useSelector } from "swiipe.portal.shared"
import FormFooter from "../../components/form/footer/FormFooter"
import LoginForm from "../../components/form/login/LoginForm"
import FormHeader from "../../components/form/title/FormHeader"
import TitleSection from "../../components/sections/TitleSection"
import { authService } from "../../services/authService"
import { ELoginFlow } from "../../type/ELoginFlow"

interface IProps {}

const LoginPage = ({}: IProps) => {
    const { t } = useTranslation()
    const currentRoute = useCurrentRoute()
    const env = useSelector(sharedConfigurationSelectors.environment)
    if (currentRoute.url.query.otac) {
        // TODO Show redirect message
        authService.login("/", currentRoute.url.query.otac)
        return null
    }

    const trimmedForm = !!currentRoute.url.query.fromCheckout
    const flowType = currentRoute.url.query.flowType as ELoginFlow
    const email = currentRoute.url.query.email

    return (
        <PageWrapper>
            <div id="login" className="center-form">
                {!flowType && <FormHeader showSandboxTitle={env === "Sandbox"} />}
                {flowType === "merchantOffer" && (
                    <TitleSection className="pt-4 pb-2" center isSmaller title={t("login.merchantOffer.title")} />
                )}
                <div className="panel-body">
                    <LoginForm flowType={flowType} email={email} isTrimmed={trimmedForm} />
                </div>
                {!flowType && <FormFooter />}
            </div>
        </PageWrapper>
    )
}

export default LoginPage
