import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { SpinnerContainer } from "../loading/SpinnerContainer"
import "./SimpleTable.scss"

interface ISimpleTableCell {
    className?: string
    content: React.ReactNode
}

interface ISimpleTable<T> {
    clasName?: string
    title?: string
    items: T[] | undefined
    renderHeaderCells: () => ISimpleTableCell[]
    renderRowCells: (item: T, index: number) => ISimpleTableCell[]
}

export const SimpleTable = <T extends {}>({ clasName, title, items, renderHeaderCells, renderRowCells }: ISimpleTable<T>) => {
    const { t } = useTranslation()

    return (
        <div className={cn("simple-table-container", clasName)}>
            {title && <p className="simple-table-container-title">{title}</p>}
            <SpinnerContainer showSpinner={!items}>
                {items && (
                    <table className="simple-table">
                        <tbody>
                            <tr>
                                {renderHeaderCells().map((headerCell, index) => (
                                    <th key={index} className={cn("simple-table__table-header-cell", headerCell.className)}>
                                        {headerCell.content}
                                    </th>
                                ))}
                            </tr>
                            {items?.map((item, index) => (
                                <tr key={index}>
                                    {renderRowCells(item, index).map((rowCell, index) => (
                                        <td key={index} className={cn("simple-table__table-row-cell", rowCell.className)}>
                                            {rowCell.content}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </SpinnerContainer>
        </div>
    )
}
