import cn from "classnames"
import React from "react"
import { FormState } from "react-hook-form"
import { IStandardButtonProps, SpinnerContainer, StandardButton } from "swiipe.portal.shared"
import { getImage } from "../../services/imageLanguageService"
import "./SubmitButton.scss"

interface ISubmitButtonProps extends IStandardButtonProps {
    formState: FormState<any>
    isLogin?: boolean
    isSignUp?: boolean
    isSignUpMerchant?: boolean
    isSignUpPartner?: boolean
    isResetPassword?: boolean
    containerClass?: string
    floatRight?: boolean
    disableSpinner?: boolean
}

const SubmitButton = ({
    formState,
    disabled,
    className,
    isLogin,
    isSignUp,
    isSignUpMerchant,
    isSignUpPartner,
    floatRight,
    isResetPassword,
    children,
    containerClass,
    disableSpinner,
    ...otherProps
}: ISubmitButtonProps) => {
    const { isValidating, isSubmitting } = formState

    return (
        <SpinnerContainer
            showSpinner={formState.isSubmitting && !disableSpinner}
            className={cn({ "float-right": floatRight }, containerClass)}
        >
            <StandardButton
                {...otherProps}
                className={cn(
                    "swiipe-btn",
                    { login: isLogin, signup: isSignUp, signupmerchant: isSignUpMerchant, reset_password: isResetPassword },
                    className
                )}
                disabled={isValidating || isSubmitting || disabled}
                type="submit"
                variant="primary"
            >
                {isLogin && <img className="logo-img" src={getImage("logInBtn")} />}
                {isSignUp && <img className="logo-img" src={getImage("createSwiipeLoginBtn")} />}
                {isSignUpMerchant && <img className="logo-img" src={getImage("createAccountBtn")} />}
                {isSignUpPartner && <img className="logo-img" src={getImage("createPartnerBtn")} />}
                {isResetPassword && <img className="logo-img" src={getImage("resetPasswordBtn")} />}
                {children}
            </StandardButton>
        </SpinnerContainer>
    )
}

export default SubmitButton
