import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import {
    CountryRegionConfig,
    getKeys,
    makeRequestThunk,
    requestThunk,
    sharedConfigurationReducerActions,
} from "swiipe.portal.shared"
import {
    ICountriesAssetsResponse,
    ICountryRegionAssetsResponse,
    IRegionsAssetsResponse,
} from "../../type/countryRegionAssetsResponses"
import { StoreState } from "../StoreState"
import { endpoints } from "./../../data/endpoints"

export const getCountryRegionAssets =
    (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
        const links = await dispatch(requestThunk<ICountryRegionAssetsResponse>(endpoints.Content.getCountryRegionAssets))

        // After first request browser caches files from links
        // will not abuse resource until returned links are changed
        const countries = await dispatch(makeRequestThunk<ICountriesAssetsResponse>("get", links.countriesUrl))
        const regions = await dispatch(makeRequestThunk<IRegionsAssetsResponse>("get", links.regionsUrl))

        const countryRegionsConfig: CountryRegionConfig = {}

        getKeys(countries.countries).forEach((countryCode) => {
            const countryConfig = countries.countries[countryCode]
            const regionConfig = regions.countries[countryCode]

            countryRegionsConfig[countryCode] = {
                regionType: countryConfig.regionType,
                langToText: countryConfig.text,
                nameToCode: regionConfig,
            }
        })

        dispatch(sharedConfigurationReducerActions.setCountryRegionsConfiguration(countryRegionsConfig))
    }
