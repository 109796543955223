import React from "react"
import { sanitizeData } from "swiipe.portal.shared"

interface ICleanHtmlProps {
    element: "p" | "div" | "span" | "li"
    className?: string
    html?: string
    children?: React.ReactNode
}

type ElemProps<T extends HTMLElement> = React.DetailedHTMLProps<React.HTMLAttributes<T>, T>

export const CleanHtml = ({ element, className, html, children }: ICleanHtmlProps) => {
    const props: ElemProps<HTMLElement> = {
        className: className,
        dangerouslySetInnerHTML: typeof html === "undefined" ? undefined : { __html: sanitizeData(html) },
    }
    switch (element) {
        case "p":
            return <p {...(props as ElemProps<HTMLParagraphElement>)}>{children}</p>
        case "span":
            return <span {...(props as ElemProps<HTMLSpanElement>)}>{children}</span>
        case "li":
            return <li {...(props as ElemProps<HTMLLIElement>)}>{children}</li>
        default:
            return <div {...(props as ElemProps<HTMLDivElement>)}>{children}</div>
    }
}
