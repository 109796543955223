import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { PageContainer, PageWrapper, useSelector } from "swiipe.portal.shared"
import SalesCheckoutPageLinkForm from "../../components/form/sales/SalesCheckoutPageLinkForm"
import SalesPspForm from "../../components/form/sales/SalesPspForm"
import SalesSendersNameForm from "../../components/form/sales/SalesSendersNameForm"
import SalesTermsLinkForm from "../../components/form/sales/SalesTermsLinkForm"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { salesSelectors } from "../../store/reducers/salesReducer"
import { ensureSalesSettingsThunk } from "../../store/thunks/salesSettingsThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import PageBoxSection from "./../../components/page/PageBoxSection"

interface ISalesOfferSettingsPageProps {}

const SalesOfferSettingsPage = ({}: ISalesOfferSettingsPageProps) => {
    useRequiredUserRelations([{ requiredType: "CallCenter" }])
    const { t } = useTranslation()

    const dispatch = useReduxDispatch()
    const settings = useSelector(salesSelectors.settings)
    useEffect(() => {
        dispatch(ensureSalesSettingsThunk(false))
    }, [])

    if (!settings) {
        return null
    }

    return (
        <PageWrapper>
            <PageContainer transparent>
                <PageBoxSection title={t("offersettings.sendersName.title")}>
                    <p>{t("offersettings.sendersName.message")}</p>
                    <SalesSendersNameForm />
                </PageBoxSection>
                <PageBoxSection title={t("offersettings.checkoutPageLink.title")}>
                    <p>{t("offersettings.checkoutPageLink.message")}</p>
                    <SalesCheckoutPageLinkForm />
                </PageBoxSection>
                <PageBoxSection title={t("offersettings.terms.title")}>
                    <p>{t("offersettings.terms.message")}</p>
                    <SalesTermsLinkForm />
                </PageBoxSection>
                <PageBoxSection title={t("offersettings.psp.title")}>
                    <p>{t("offersettings.psp.message")}</p>
                    <SalesPspForm />
                </PageBoxSection>
            </PageContainer>
        </PageWrapper>
    )
}

export default SalesOfferSettingsPage
