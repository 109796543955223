import cn from "classnames"
import React, { useEffect, useState } from "react"
import { TModalResponseType, useSelector } from "swiipe.portal.shared"
import { getLinkForTerms } from "../../services/legalService"
import { ETermsType } from "../../store/reducers/legalReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import "./LegalTextLinkPopup.scss"

interface ILegalTextLinkPopupProps {
    legalTextBeforeLink: string
    legalTextAfterLink?: string
    linkText: string
    termsType: ETermsType
    inForm?: boolean
    className?: string
    linkClassName?: string
    inPluginDesc?: boolean
    centered?: boolean
    onTermsModalClosed?: (type: TModalResponseType) => void
    forceOpenModalNumber?: number
}

const LegalTextLinkPopup = ({
    legalTextBeforeLink: legalText,
    legalTextAfterLink,
    linkText,
    termsType,
    inForm,
    className,
    linkClassName,
    inPluginDesc,
    centered,
    onTermsModalClosed,
    forceOpenModalNumber,
}: ILegalTextLinkPopupProps) => {
    const dispatch = useReduxDispatch()
    const userRelation = useSelector(userRelationSelectors.currentUserRelation)
    const [isModalOpen, setIsModalOpen] = useState(false)

    if (!userRelation) {
        return null
    }

    const openModal = async () => {
        setIsModalOpen(true)
        const res = await dispatch(
            addModalThunk({
                type: "terms",
                userRelationType: userRelation.relationType,
                relationId: userRelation.id,
                termsType: termsType,
            })
        )

        onTermsModalClosed?.(res.type)
        setIsModalOpen(false)
    }

    useEffect(() => {
        if (forceOpenModalNumber && !isModalOpen) {
            openModal()
        }
    }, [forceOpenModalNumber])

    return (
        <p
            className={cn(
                "swiipe-accept-terms",
                "mb-0",
                { "in-form": inForm },
                { "in-plugin-desc": inPluginDesc },
                { "text-center": centered },
                className ? className : ""
            )}
        >
            {legalText}{" "}
            <a
                className={linkClassName}
                href={getLinkForTerms(termsType, userRelation.relationType, userRelation.id)}
                onClick={(e) => {
                    e.preventDefault()
                    openModal()
                }}
            >
                {linkText}
            </a>
            {legalTextAfterLink}
        </p>
    )
}

export default LegalTextLinkPopup
