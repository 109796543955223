import cn from "classnames"
import React, { useState } from "react"
import { Input } from "reactstrap"
import "./CheckboxWithHint.scss"

interface ICheckboxWithHint {
    checked: boolean
    handleChange: (checked: boolean) => void
    hint?: string
    name?: string
}

export const CheckboxWithHint = ({ checked, hint, handleChange, name }: ICheckboxWithHint) => {
    const [showHint, setShowHint] = useState(false)

    return (
        <>
            <Input
                name={name}
                type="checkbox"
                onChange={() => {
                    handleChange(!checked)
                }}
                checked={checked}
                onMouseOver={() => {
                    if (!hint) {
                        return
                    }

                    setShowHint(true)
                }}
                onMouseOut={() => {
                    if (!hint) {
                        return
                    }

                    setShowHint(false)
                }}
            />
            {hint && <div className={cn("checkbox-hint", { "d-none": !showHint })}>{hint}</div>}
        </>
    )
}
