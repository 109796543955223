import React from "react"
import { IPaymentMethod, TPayment } from "swiipe.portal.shared"
import { getFormattedCardExpiryYear, getFormattedCardNumber, getLogoByCardType } from "../../../services/paymentService"
import "./PaymentMethod.scss"

interface IPaymentMethodProps {
    paymentMethod: IPaymentMethod
}

const PaymentMethod = ({ paymentMethod }: IPaymentMethodProps) => {
    const supportedTypes: TPayment[] = ["CreditCard", "AccountToAccount"]
    if (!supportedTypes.includes(paymentMethod.paymentType)) {
        return null
    }

    let Comp: JSX.Element = <></>
    switch (paymentMethod.paymentType) {
        case "CreditCard":
            Comp = <CreditCard paymentMethod={paymentMethod} />
            break
        case "AccountToAccount":
            Comp = <A2a paymentMethod={paymentMethod} />
            break
    }

    return <div className="payment-method-container">{Comp}</div>
}

interface IPaymentMethodProps {
    paymentMethod: IPaymentMethod
}
const CreditCard = ({ paymentMethod }: IPaymentMethodProps) => {
    if (!paymentMethod.creditCard) {
        return null
    }
    return (
        <div className="credit-card">
            <img className="card-logo" src={getLogoByCardType(paymentMethod.creditCard.cardType)} />
            <br />
            <span className="card-number">{getFormattedCardNumber(paymentMethod.creditCard.maskedAccount)}</span>
            <br />
            <span className="card-date">{`${paymentMethod.creditCard.expiryMonth}/${getFormattedCardExpiryYear(
                paymentMethod.creditCard.expiryYear
            )}`}</span>
        </div>
    )
}

const A2a = ({ paymentMethod }: IPaymentMethodProps) => {
    if (!paymentMethod.account) {
        return null
    }
    return (
        <div className="account2account">
            <img className="bank-logo" src={paymentMethod.account.providerLogo} />
            <br />
            <span className="account-name">{paymentMethod.account.name}</span>
        </div>
    )
}

export default PaymentMethod
