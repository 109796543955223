import ResetPasswordBtn from "../../images/buttons/reset-password-da.png"
import SwiipeLoginBtn from "../../images/buttons/swiipe-login-da.png"
import LogInBtn from "../../images/buttons/log-in-da.png"
import CreateAccountBtn from "../../images/buttons/create-account-da.png"
import CreatePartnerBtn from "../../images/buttons/create-partner-login-da.png"
import ITranslationImages from "../../type/ITranslationImages"
import SwiipeCardPaymentsLogo from "../../images/swFeatureServices/swiipe-card-payments-logo-da.png"
import SwiipeA2ALogo from "../../images/swPaymentTypes/account-to-account-da.png"
import CheckoutLogo from "../../images/swFeatureServices/swiipe-checkout-logo.png"
import CardPaymentsLogoWithSchemes from "../../images/swFeatureServices/swiipe-card-payments-schemes-logo-da.png"
import KlarnaLogo from "../../images/swPaymentTypes/klarna-logo.svg"
import ViabillLogo from "../../images/swPaymentTypes/viabill-logo.png"
import VippsLogo from "../../images/swPaymentTypes/vipps-logo.svg"
import SwishLogo from "../../images/swPaymentTypes/swish-logo.png"
import ApplePayLogo from "../../images/swPaymentTypes/applepay-dark-logo.svg"
import AiiaLogo from "../../images/swPaymentTypes/account-to-account-aiia.svg"
import ResursLogo from "../../images/swPaymentTypes/resurs-logo.png"
import GoogleAnalyticsLogo from "../../images/swFeatureServices/g_analytics_logo.svg"

export const daImages: ITranslationImages = {
    images: {
        resetPasswordBtn: ResetPasswordBtn,
        logInBtn: LogInBtn,
        createSwiipeLoginBtn: SwiipeLoginBtn,
        createAccountBtn: CreateAccountBtn,
        createPartnerBtn: CreatePartnerBtn,
        cardPaymentsLogo: SwiipeCardPaymentsLogo,
        a2aLogo: SwiipeA2ALogo,
        checkoutLogo: CheckoutLogo,
        cardPaymentsLogoWithSchemes: CardPaymentsLogoWithSchemes,
        viabillLogo: ViabillLogo,
        klarnaLogo: KlarnaLogo,
        vippsLogo: VippsLogo,
        swishLogo: SwishLogo,
        applePayLogo: ApplePayLogo,
        poweredByAiiaLogo: AiiaLogo,
        resursLogo: ResursLogo,
        googleAnalyticsLogo: GoogleAnalyticsLogo,
    },
}
