import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { IStandardButtonProps, StandardButton } from "swiipe.portal.shared"

interface ICopyToClipboardBtnProps extends IStandardButtonProps {
    textToCopy: string
}

const CopyToClipboardButton = (props: ICopyToClipboardBtnProps) => {
    const { t } = useTranslation()
    const [isTextCopied, setIsTextCopied] = useState(false)

    const copyToClipboard = (code: string, setIsTextCopied: React.Dispatch<React.SetStateAction<boolean>>) => {
        navigator.clipboard.writeText(code)
        setIsTextCopied(true)
    }
    props = {
        ...props,
        onClick: (e) => {
            e.preventDefault()
            copyToClipboard(props.textToCopy, setIsTextCopied)
        },
    }
    return <StandardButton {...props}>{isTextCopied ? t("common.copied") : t("common.copy")}</StandardButton>
}

export default CopyToClipboardButton
