import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { PageContainer, PageWrapper } from "swiipe.portal.shared"
import ForgotPasswordForm from "./../../components/form/password/ForgotPasswordForm"
import MessageBox from "./../../components/layouts/MessageBox"
import "./ForgotPasswordPage.scss"

interface IForgotPasswordPageProps {}

const ForgotPasswordPage = ({}: IForgotPasswordPageProps) => {
    const [successEmail, setSuccessEmail] = useState("")
    const { t } = useTranslation()

    return (
        <PageWrapper>
            <PageContainer classNameBody="swiipe__forgot-password-page" transparent>
                {successEmail ? (
                    <MessageBox title={t("forgotpw.checkemail")}>
                        <p className="text-center">
                            {t("forgotpw.emailsentmsg1")}
                            <span className="font-weight-bold">{successEmail}</span>
                            {t("forgotpw.emailsentmsg2")}
                        </p>
                        <p className="text-center mb-5">{t("forgotpw.emailsentmsg3")}</p>
                        <p className="text-center mb-0 smaller-text">{t("forgotpw.emailsentmsg4")}</p>
                    </MessageBox>
                ) : (
                    <MessageBox title={t("forgotpw.reset")}>
                        <ForgotPasswordForm
                            onMailSent={(email) => {
                                setSuccessEmail(email)
                            }}
                        />
                    </MessageBox>
                )}
            </PageContainer>
        </PageWrapper>
    )
}

export default ForgotPasswordPage
