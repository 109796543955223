import cn from "classnames"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import { FormGroupWithCheckbox, ShowErrorMessages, TRegister, valFuncRequired } from "swiipe.portal.shared"
import { ETermsType } from "../../../store/reducers/legalReducer"
import SubmitButton from "../../buttons/SubmitButton"
import LegalTextLinkPopup from "../../legal/LegalTextLinkPopup"
import "./TermsAndConditionsCheckbox.scss"

interface ITermsAndConditionsCheckbox {
    inputName?: string

    termsType: ETermsType
    legalText?: string
    legalTextAfterLink?: string
    linkText?: string
    textLarge?: boolean
    acceptText?: React.ReactNode
    onAccept?: () => Promise<void>
    parentFormRegister?: TRegister

    formGroupClassNames?: string
    submitBtnClassNames?: string

    centered?: boolean
}

interface IForm {
    acceptTermsInput: boolean
}

const TermsAndConditionsCheckbox = ({
    termsType,
    legalText,
    legalTextAfterLink,
    linkText,
    textLarge,
    acceptText,
    onAccept,
    parentFormRegister,
    formGroupClassNames,
    submitBtnClassNames,
    inputName = "acceptTermsInput",
    centered = true,
}: ITermsAndConditionsCheckbox) => {
    const { t } = useTranslation()
    const { formState, register, handleSubmit } = useForm<IForm>()

    const onSubmit: SubmitHandler<IForm> = async (data) => {
        try {
            if (!onAccept) {
                return
            }

            await onAccept()
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    const getCheckbox = (register: TRegister) => {
        return (
            <FormGroupWithCheckbox
                innerRef={register(valFuncRequired(t("termsAndConditionsCheckbox.errors.termsAndConditions")))}
                className={cn("mb-3", formGroupClassNames)}
                name={inputName}
            >
                <LegalTextLinkPopup
                    centered={centered}
                    legalTextBeforeLink={legalText ? legalText : t("termsAndConditionsCheckbox.legalText")}
                    legalTextAfterLink={legalTextAfterLink}
                    linkText={linkText ? linkText : t("termsAndConditionsCheckbox.linkText")}
                    termsType={termsType}
                    className={cn(
                        "terms-and-conditions-checkbox__text checkbox-text",
                        textLarge && "terms-and-conditions-checkbox__text--large"
                    )}
                />
            </FormGroupWithCheckbox>
        )
    }

    //Form is already present, return only checkbox
    if (parentFormRegister) {
        return getCheckbox(parentFormRegister)
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {getCheckbox(register)}

            <ShowErrorMessages formState={formState} />

            <SubmitButton formState={formState} dark noBorder noBorderRadius className={cn("mb-3", submitBtnClassNames)}>
                {acceptText}
            </SubmitButton>
        </Form>
    )
}

export default TermsAndConditionsCheckbox
