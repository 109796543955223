import cn from "classnames"
import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Alert, Form, Row } from "reactstrap"
import {
    DropDownList,
    FieldsetFormGroup,
    FloatingLabelInput,
    FormGroupWithCheckbox,
    PageContainer,
    PageWrapper,
    SpinnerContainer,
    StandardButton,
    StandardButtonWithSpinner,
    TEnv,
    TFeatureType,
    sharedConfigurationSelectors,
    useSelector,
} from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import { ISendEmailBoxProps, SendEmailBox } from "../../components/email/SendEmailBox"
import WebshopSelect from "../../components/merchant/WebshopSelect"
import { getDateToLocalStringWithOptions } from "../../services/dateTimeService"
import { getFeatureEnabled, getFeatureOnboardingStatus } from "../../services/featureService"
import { removeSchemeFromHostName } from "../../services/merchantService"
import { navigationService } from "../../services/navigationService"
import { getProvider, getProviderStatus } from "../../services/paymentService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { clearhausSelectors } from "../../store/reducers/clearhausReducer"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { IWebshopProviderConfig, merchantServicesSelectors } from "../../store/reducers/merchantServicesReducer"
import {
    acceptUpdateApplicationInfoThunk,
    getClearhausApplicationDataThunk,
    getClearhausApplicationStateThunk,
    sendOnboardingConfirmationEmailAsAdminThunk,
} from "../../store/thunks/clearhausThunks"
import { adminUpdateMerchantWebshopDataThunk, fetchWebshopFeaturesDetailsThunk } from "../../store/thunks/merchantThunks"
import { mpoOnboardForMpoApiThunk } from "../../store/thunks/mobilePayOnlineThunks"
import { addModalThunk } from "../../store/thunks/modalThunks"
import {
    ISetOnboardingStatusesDto,
    PiqDuplicatesDto,
    checkPiqDuplicatesThunk,
    getExtendedOnboardingStatusesThunk,
    setExtendedOnboardingStatusesThunk,
} from "../../store/thunks/swiipePaymentsThunks"
import { setWebshopFeaturesThunk } from "../../store/thunks/webshopThunks"
import { adminUpdateSmsSenderNameStatusThunk, getWinbackDetailsThunk } from "../../store/thunks/winbackThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { IGatewayProvider, TOnboardingStatus } from "../../type/IProvider"
import { IClearhausApplicationOnboardingState } from "../../type/clearhaus/IClearhausApplicationOnboardingState"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import { IWinbackDetails, TWinbackSmsSenderNameStatus } from "../../type/winback/winbackDetails"
import "./SwitchServicesPage.scss"

interface ISwitchServicesPage {}

const SwitchServicesPage = ({}: ISwitchServicesPage) => {
    useRequiredUserRelations([{ requiredType: "Merchant", requiredContainMasterPartner: true }])

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const webshops = merchantDetails?.webshops || []

    if (!merchantDetails || !webshops.length) {
        return null
    }

    return <SwitchServicesPageInner merchantDetails={merchantDetails} />
}

interface ISwitchServicesPageInner {
    merchantDetails: MixedMerchantDetails
}

interface IOnboardingStatusDropDownItem {
    text: string
    value: TOnboardingStatus | ""
}

let latestRequestTimestamp: number

interface IFormData {
    clientId: string
    clientSecret: string
    piqMerchantId: string

    isExtraPiqMerchantEnabled: boolean
    extraPiqMerchantEnableThresholdAmountEUR: number
    extraPiqMerchantId: string
    extraPiqMerchantClientId: string
    extraPiqMerchantClientSecret: string

    clearhausStatusCc: TOnboardingStatus
    clearhausStatusMpo: TOnboardingStatus
    clearhausStatusVipps: TOnboardingStatus
    clearhausStatusApplePay: TOnboardingStatus
    dankortStatusCc: TOnboardingStatus
    forbrugsforeningenStatus: TOnboardingStatus
    mobilePayStatus: TOnboardingStatus
    swishStatus: TOnboardingStatus
    klarnaStatus: TOnboardingStatus
    vippsStatus: TOnboardingStatus
    applePayStatus: TOnboardingStatus

    plusSellStatus: TOnboardingStatus
    returnSellStatus: TOnboardingStatus
    winbackStatus: TOnboardingStatus
    anywhereMobileStatus: TOnboardingStatus

    //Webshop data
    mccNumber: string
    //Winback data
    winbackSmsSenderNameStatus: TWinbackSmsSenderNameStatus
}

const SwitchServicesPageInner = ({ merchantDetails }: ISwitchServicesPageInner) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()
    const swMerchantId = merchantDetails.swMerchant.swMerchantId

    const webshops = merchantDetails.webshops || []
    const initialWebshopId = useSelector(merchantSelectors.selectedWebshopId)

    const { register, formState, handleSubmit, setValue } = useForm<IFormData>()

    const [selectedWebshopId, setSelectedWebshopId] = useState(initialWebshopId ?? webshops[0].webshopId)
    const [copyFromWebshopId, setCopyFromWebshopId] = useState("")

    const [piqDuplicatesStatus, setPiqDuplicatesStatus] = useState(undefined as PiqDuplicatesDto | undefined)
    const [piqMerchantId, setPiqMerchantId] = useState(undefined as string | undefined)
    const [piqClientId, setPiqClientId] = useState(undefined as string | undefined)

    const env = useSelector(sharedConfigurationSelectors.environment)
    const swiipePlan = useSelector(merchantSelectors.swiipePlan)

    const winbackDetails = useSelector((state: StoreState) => merchantSelectors.winbackDetails(state, selectedWebshopId))

    const webshopServicesStatus = useSelector<StoreState, IWebshopProviderConfig | undefined>((state) =>
        merchantServicesSelectors.statuses(state, swMerchantId, selectedWebshopId)
    )

    const webshopFeaturesDetails = useSelector((state: StoreState) =>
        merchantSelectors.featuresDetailsForWebshop(state, selectedWebshopId)
    )

    const clearhausApplicationState = useSelector<StoreState, IClearhausApplicationOnboardingState | undefined>((state) =>
        clearhausSelectors.applicationState(state, selectedWebshopId)
    )
    const applicationData = useSelector((state: StoreState) => clearhausSelectors.getOnboardingData(state, selectedWebshopId))
    useEffect(() => {
        dispatch(getClearhausApplicationDataThunk(swMerchantId, [selectedWebshopId]))
    }, [selectedWebshopId])

    const gatewayProviders = webshopServicesStatus?.gatewayProviders ?? []

    useEffect(() => {
        dispatch(getExtendedOnboardingStatusesThunk(swMerchantId, selectedWebshopId, true))
        dispatch(fetchWebshopFeaturesDetailsThunk(selectedWebshopId, true))
    }, [selectedWebshopId])

    useEffect(() => {
        const clearhausStatus = getProviderStatus(gatewayProviders, "CreditCard", "Clearhaus", true)
        if (!clearhausStatus || clearhausStatus === "ActionRequired") {
            return
        }

        dispatch(getClearhausApplicationStateThunk(swMerchantId, selectedWebshopId, false))
    }, [gatewayProviders])

    useEffect(() => {
        checkPiqDuplicates(swMerchantId, selectedWebshopId, piqMerchantId, piqClientId)
    }, [piqMerchantId, piqClientId])

    useEffect(() => {
        if (webshopServicesStatus && webshopServicesStatus.isExtended) {
            setValue("piqMerchantId", webshopServicesStatus.piqMerchantId ?? "")
            setValue("clientId", webshopServicesStatus.piqMerchantClientId ?? "")
            setValue("clientSecret", webshopServicesStatus.piqMerchantClientSecret ?? "")

            setValue("isExtraPiqMerchantEnabled", webshopServicesStatus.isExtraPiqMerchantEnabled ?? false)
            setValue(
                "extraPiqMerchantEnableThresholdAmountEUR",
                webshopServicesStatus.extraPiqMerchantEnableThresholdAmountEUR ?? 0
            )
            setValue("extraPiqMerchantId", webshopServicesStatus.extraPiqMerchantId ?? "")
            setValue("extraPiqMerchantClientId", webshopServicesStatus.extraPiqMerchantClientId ?? "")
            setValue("extraPiqMerchantClientSecret", webshopServicesStatus.extraPiqMerchantClientSecret ?? "")

            setPiqMerchantId(webshopServicesStatus.piqMerchantId)
            setPiqClientId(webshopServicesStatus.piqMerchantClientId)
        } else {
            setValue("piqMerchantId", "")
            setValue("clientId", "")
            setValue("clientSecret", "")

            setValue("isExtraPiqMerchantEnabled", false)
            setValue("extraPiqMerchantEnableThresholdAmountEUR", 0)
            setValue("extraPiqMerchantId", "")
            setValue("extraPiqMerchantClientId", "")
            setValue("extraPiqMerchantClientSecret", "")
        }

        setValue("clearhausStatusCc", getProviderStatus(gatewayProviders, "CreditCard", "Clearhaus", true) ?? "NotOnboarded")
        setValue(
            "clearhausStatusMpo",
            getProviderStatus(gatewayProviders, "MobilePayOnline", "Clearhaus", true) ?? "NotOnboarded"
        )
        setValue("clearhausStatusVipps", getProviderStatus(gatewayProviders, "Vipps", "Clearhaus") ?? "NotOnboarded")
        setValue("clearhausStatusApplePay", getProviderStatus(gatewayProviders, "ApplePay", "Clearhaus") ?? "NotOnboarded")
        setValue("dankortStatusCc", getProviderStatus(gatewayProviders, "CreditCard", "DankortEpay", true) ?? "NotOnboarded")
        setValue(
            "forbrugsforeningenStatus",
            getProviderStatus(gatewayProviders, "CreditCard", "ForbrugsforeningenEpay", true) ?? "NotOnboarded"
        )
        setValue(
            "mobilePayStatus",
            getProviderStatus(gatewayProviders, "MobilePayOnline", "MobilePayOnline", true) ?? "NotOnboarded"
        )
        setValue("swishStatus", getProviderStatus(gatewayProviders, "Swish", "Swish") ?? "NotOnboarded")
        setValue("mccNumber", merchantDetails.webshops.find((w) => w.webshopId === selectedWebshopId)?.mcc)
        setValue("klarnaStatus", getProviderStatus(gatewayProviders, "KlarnaPayLater", "Klarna") ?? "NotOnboarded") // sufficient and both pay later and over time status updated at same time
        setValue("vippsStatus", getProviderStatus(gatewayProviders, "Vipps", "Vipps") ?? "NotOnboarded")
        setValue("applePayStatus", getProviderStatus(gatewayProviders, "ApplePay", "ApplePay") ?? "NotOnboarded")
    }, [webshopServicesStatus])

    useEffect(() => {
        if (!webshopFeaturesDetails) {
            return
        }

        setValue("winbackStatus", getFeatureOnboardingStatus(webshopFeaturesDetails, "Winback"))
        setValue("plusSellStatus", getFeatureOnboardingStatus(webshopFeaturesDetails, "PlusSell"))
        setValue("returnSellStatus", getFeatureOnboardingStatus(webshopFeaturesDetails, "ReturnSell"))
        setValue("anywhereMobileStatus", getFeatureOnboardingStatus(webshopFeaturesDetails, "AnywhereMobile"))

        if (getFeatureEnabled(webshopFeaturesDetails, "Winback")) {
            dispatch(getWinbackDetailsThunk(swMerchantId, selectedWebshopId))
        }
    }, [webshopFeaturesDetails])

    const checkPiqDuplicates = async (swMerchantId: string, webshopId: string, piqMerchantId?: string, piqClientId?: string) => {
        const currentTimestamp = Date.now()
        latestRequestTimestamp = currentTimestamp
        const result = await dispatch(checkPiqDuplicatesThunk(swMerchantId, webshopId, piqMerchantId, piqClientId))

        if (latestRequestTimestamp == currentTimestamp) {
            setPiqDuplicatesStatus(result)
        }
    }

    const updateFeatureStatus = (status: TOnboardingStatus, featureType: TFeatureType) => {
        if (status !== getFeatureOnboardingStatus(webshopFeaturesDetails, featureType)) {
            const enabled = getFeatureEnabled(webshopFeaturesDetails, featureType)
            dispatch(setWebshopFeaturesThunk([selectedWebshopId], featureType, enabled || false, swMerchantId, status))
        }
    }

    const onSubmit: SubmitHandler<IFormData> = async (data) => {
        try {
            const gps: IGatewayProvider[] = []
            if (data.clearhausStatusCc) {
                gps.push({
                    paymentType: "CreditCard",
                    providerName: "Clearhaus",
                    providerType: "Aquirer",
                    status: data.clearhausStatusCc,
                })
            }
            if (data.dankortStatusCc) {
                gps.push({
                    paymentType: "CreditCard",
                    providerName: "DankortEpay",
                    providerType: "PspAndAquirer",
                    status: data.dankortStatusCc,
                })
            }
            if (data.forbrugsforeningenStatus) {
                gps.push({
                    paymentType: "CreditCard",
                    providerName: "ForbrugsforeningenEpay",
                    providerType: "PspAndAquirer",
                    status: data.forbrugsforeningenStatus,
                })
            }
            if (data.clearhausStatusMpo) {
                gps.push({
                    paymentType: "MobilePayOnline",
                    providerName: "Clearhaus",
                    providerType: "Aquirer",
                    status: data.clearhausStatusMpo,
                })
            }
            if (data.mobilePayStatus) {
                gps.push({
                    paymentType: "MobilePayOnline",
                    providerName: "MobilePayOnline",
                    providerType: "Other",
                    status: data.mobilePayStatus,
                })
            }
            if (data.swishStatus) {
                gps.push({
                    paymentType: "Swish",
                    providerName: "Swish",
                    providerType: "PspAndAquirer",
                    status: data.swishStatus,
                })
            }
            if (data.klarnaStatus) {
                gps.push({
                    paymentType: "KlarnaPayLater",
                    providerName: "Klarna",
                    providerType: "PspAndAquirer",
                    status: data.klarnaStatus,
                })
                gps.push({
                    paymentType: "KlarnaFinancing",
                    providerName: "Klarna",
                    providerType: "PspAndAquirer",
                    status: data.klarnaStatus,
                })
            }
            if (data.vippsStatus) {
                gps.push({
                    paymentType: "Vipps",
                    providerName: "Vipps",
                    providerType: "Other",
                    status: data.vippsStatus,
                })
            }
            if (data.clearhausStatusVipps) {
                gps.push({
                    paymentType: "Vipps",
                    providerName: "Clearhaus",
                    providerType: "Aquirer",
                    status: data.clearhausStatusVipps,
                })
            }
            if (data.applePayStatus) {
                gps.push({
                    paymentType: "ApplePay",
                    providerName: "ApplePay",
                    providerType: "Other",
                    status: data.applePayStatus,
                })
            }
            if (data.clearhausStatusApplePay) {
                gps.push({
                    paymentType: "ApplePay",
                    providerName: "Clearhaus",
                    providerType: "Aquirer",
                    status: data.clearhausStatusApplePay,
                })
            }

            const structuredData: ISetOnboardingStatusesDto = {
                webshopId: selectedWebshopId,
                copyFromWebshopId: copyFromWebshopId,
                paymentIqData: {
                    piqMerchantId: data.piqMerchantId.trim(),
                    clientId: data.clientId.trim(),
                    clientSecret: data.clientSecret.trim(),
                    isExtraPiqMerchantEnabled: !!data.isExtraPiqMerchantEnabled,
                    extraPiqMerchantEnableThresholdAmountEUR: data.extraPiqMerchantEnableThresholdAmountEUR ?? 0,
                    extraPiqMerchantId: data.extraPiqMerchantId?.trim(),
                    extraPiqMerchantClientId: data.extraPiqMerchantClientId?.trim() ?? "",
                    extraPiqMerchantClientSecret: data.extraPiqMerchantClientSecret?.trim() ?? "",
                },
                gatewayProviders: gps,
            }

            const mcc = merchantDetails.webshops.find((w) => w.webshopId === selectedWebshopId)?.mcc
            if (mcc !== data.mccNumber) {
                await dispatch(
                    adminUpdateMerchantWebshopDataThunk(merchantDetails!.swMerchant.swMerchantId, [
                        { webshopId: selectedWebshopId, mcc: data.mccNumber },
                    ])
                )
            }
            await dispatch(setExtendedOnboardingStatusesThunk(structuredData, merchantDetails!.swMerchant.swMerchantId))

            updateFeatureStatus(data.winbackStatus, "Winback")
            updateFeatureStatus(data.plusSellStatus, "PlusSell")
            updateFeatureStatus(data.returnSellStatus, "ReturnSell")
            updateFeatureStatus(data.anywhereMobileStatus, "AnywhereMobile")

            if (winbackDetails?.smsSenderName && winbackDetails.smsSenderNameStatus !== data.winbackSmsSenderNameStatus) {
                await dispatch(
                    adminUpdateSmsSenderNameStatusThunk(
                        swMerchantId,
                        winbackDetails.smsSenderName,
                        data.winbackSmsSenderNameStatus
                    )
                )
            }
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    const sendClearhausEmail = async (isInstant: boolean): Promise<void> => {
        const response = await dispatch(
            addModalThunk({
                type: "modalDialog",
                title: "Send a confirmation email to the customer?",
                subtitle: isInstant ? "(Instant access)" : "(Fully onboarded)",
                mainTextHtml:
                    "Please ensure the payments have been set up correctly and are ready to be used.<br/> Once sent, emails cannot be unsent.",
                cancelBtnText: "No, go back",
                acceptBtnText: "Yes, send email",
            })
        )

        if (response.type !== "accepted" || !clearhausApplicationState?.onboardingId) {
            return
        }

        await dispatch(sendOnboardingConfirmationEmailAsAdminThunk(isInstant, clearhausApplicationState.onboardingId))
        await dispatch(getClearhausApplicationStateThunk(swMerchantId, selectedWebshopId, true))
    }

    return (
        <PageWrapper>
            <PageContainer id="switchservices-page">
                <WebshopSelect merchantDetails={merchantDetails} onWebshopChange={(wid) => setSelectedWebshopId(wid)} />
                <div className="services-switch">
                    <SpinnerContainer showSpinner={!webshopServicesStatus}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <h5 className="mb-3 font-weight-bold">PaymentIQ</h5>
                            <Row>
                                <div className="col-5">API-Client:</div>
                                <div className="col-7">
                                    <FieldsetFormGroup field className="mb-2">
                                        <FloatingLabelInput
                                            name="piqMerchantId"
                                            innerRef={register({})}
                                            placeholder={t("switchservices.merchantid")}
                                            onChange={(e) => {
                                                setPiqMerchantId(e.target.value)
                                            }}
                                        />
                                        {!!piqDuplicatesStatus?.wsNamesMerchantIdDuplicates.length && (
                                            <Alert color="danger" className="mt-2 mb-0">
                                                {t("switchservices.merchantIdErrorMessage", {
                                                    webshopNames: piqDuplicatesStatus.wsNamesMerchantIdDuplicates.join(", "),
                                                })}
                                            </Alert>
                                        )}
                                    </FieldsetFormGroup>
                                    <FieldsetFormGroup field className="mb-2">
                                        <FloatingLabelInput
                                            name="clientId"
                                            innerRef={register({})}
                                            placeholder={t("switchservices.clientId")}
                                            onChange={(e) => {
                                                setPiqClientId(e.target.value)
                                            }}
                                        />
                                        {!!piqDuplicatesStatus?.wsNamesClientIdDuplicates.length && (
                                            <Alert color="danger" className="mt-2 mb-0">
                                                {t("switchservices.clientIdErrorMessage", {
                                                    webshopNames: piqDuplicatesStatus.wsNamesClientIdDuplicates.join(", "),
                                                })}
                                            </Alert>
                                        )}
                                    </FieldsetFormGroup>
                                    <FieldsetFormGroup field className="mb-4">
                                        <FloatingLabelInput
                                            name="clientSecret"
                                            innerRef={register({})}
                                            placeholder={t("switchservices.clientSecret")}
                                        />
                                    </FieldsetFormGroup>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-5">Extra PaymentIQ merchant for MobilePay above amount:</div>
                                <div className="col-7">
                                    <FormGroupWithCheckbox
                                        innerRef={register}
                                        name="isExtraPiqMerchantEnabled"
                                        title={
                                            "Enable extra PIQ Merchant. Disabling will not remove client Id and secret, so captures, voids, and refunds can still happen on existing transactions"
                                        }
                                    />
                                    <FieldsetFormGroup field className="mb-2">
                                        <FloatingLabelInput
                                            name="extraPiqMerchantEnableThresholdAmountEUR"
                                            innerRef={register({})}
                                            placeholder={"Min. amount (in EUR) to use this PIQ merchant"}
                                            type="number"
                                        />
                                    </FieldsetFormGroup>
                                    <FieldsetFormGroup field className="mb-2">
                                        <FloatingLabelInput
                                            name="extraPiqMerchantId"
                                            innerRef={register({})}
                                            placeholder={t("switchservices.merchantid")}
                                        />
                                    </FieldsetFormGroup>
                                    <FieldsetFormGroup field className="mb-2">
                                        <FloatingLabelInput
                                            name="extraPiqMerchantClientId"
                                            innerRef={register({})}
                                            placeholder={t("switchservices.clientId")}
                                        />
                                    </FieldsetFormGroup>
                                    <FieldsetFormGroup field className="mb-4">
                                        <FloatingLabelInput
                                            name="extraPiqMerchantClientSecret"
                                            innerRef={register({})}
                                            placeholder={t("switchservices.clientSecret")}
                                        />
                                    </FieldsetFormGroup>
                                </div>
                            </Row>
                            <h5 className="mb-3 font-weight-bold mt-4">Payment onboardings:</h5>
                            {!swiipePlan && (
                                <Row>
                                    <div className="col-6 swiipe-plan-missing">Swiipe Plan is not selected</div>
                                </Row>
                            )}
                            <OnboardStatusRow
                                label="Clearhaus CreditCard"
                                fieldName="clearhausStatusCc"
                                register={register}
                                isEnabled={getProvider("CreditCard", "Clearhaus", gatewayProviders)?.enabled ?? false}
                            />
                            <OnboardStatusRow
                                label="Dankort CreditCard"
                                fieldName="dankortStatusCc"
                                register={register}
                                isEnabled={getProvider("CreditCard", "DankortEpay", gatewayProviders)?.enabled ?? false}
                            />
                            <OnboardStatusRow
                                label="Forbrugsforeningen"
                                fieldName="forbrugsforeningenStatus"
                                register={register}
                                isEnabled={
                                    getProvider("CreditCard", "ForbrugsforeningenEpay", gatewayProviders)?.enabled ?? false
                                }
                            />
                            <OnboardStatusRow
                                label="Swish"
                                fieldName="swishStatus"
                                register={register}
                                isEnabled={getProvider("Swish", "Swish", gatewayProviders)?.enabled ?? false}
                            />
                            <OnboardStatusRow
                                label="Klarna"
                                fieldName="klarnaStatus"
                                register={register}
                                isEnabled={
                                    (getProvider("KlarnaPayLater", "Klarna", gatewayProviders)?.enabled ?? false) &&
                                    (getProvider("KlarnaFinancing", "Klarna", gatewayProviders)?.enabled ?? false)
                                }
                            />
                            <OnboardStatusRow
                                label="MobilePayOnline"
                                fieldName="mobilePayStatus"
                                register={register}
                                isEnabled={getProvider("MobilePayOnline", "MobilePayOnline", gatewayProviders)?.enabled ?? false}
                            />
                            <OnboardStatusRow
                                label="Clearhaus MPO"
                                fieldName="clearhausStatusMpo"
                                register={register}
                                isEnabled={getProvider("MobilePayOnline", "Clearhaus", gatewayProviders)?.enabled ?? false}
                            />
                            <OnboardStatusRow
                                label="Vipps"
                                fieldName="vippsStatus"
                                register={register}
                                isEnabled={getProvider("Vipps", "Vipps", gatewayProviders)?.enabled ?? false}
                            />
                            <OnboardStatusRow
                                label="Clearhaus Vipps"
                                fieldName="clearhausStatusVipps"
                                register={register}
                                isEnabled={getProvider("Vipps", "Clearhaus", gatewayProviders)?.enabled ?? false}
                            />
                            <OnboardStatusRow
                                label="ApplePay"
                                fieldName="applePayStatus"
                                register={register}
                                isEnabled={getProvider("ApplePay", "ApplePay", gatewayProviders)?.enabled ?? false}
                            />
                            <OnboardStatusRow
                                label="Clearhaus ApplePay"
                                fieldName="clearhausStatusApplePay"
                                register={register}
                                isEnabled={getProvider("ApplePay", "Clearhaus", gatewayProviders)?.enabled ?? false}
                            />
                            {env !== "Sandbox" && (
                                <>
                                    <p>
                                        <b>Emails:</b>
                                        <br />
                                        When the service (Cards or MobilePay) is all set up and ready to use, notify the merchant
                                        by sending an email:
                                    </p>
                                    <SendEmailBoxRow
                                        className="mb-3"
                                        title="Instant access at Clearhaus granted"
                                        isAvailable={
                                            clearhausApplicationState?.instantOnboarding &&
                                            getProviderStatus(gatewayProviders, "CreditCard", "Clearhaus", true) === "Ready"
                                        }
                                        isAlreadySent={!!clearhausApplicationState?.instantNotificationSentDate}
                                        alreadySentText={
                                            clearhausApplicationState?.instantNotificationSentDate &&
                                            getDateToLocalStringWithOptions(
                                                clearhausApplicationState?.instantNotificationSentDate,
                                                {
                                                    dateStyle: "medium",
                                                }
                                            )
                                        }
                                        sendEmail={async () => await sendClearhausEmail(true)}
                                    />
                                    <SendEmailBoxRow
                                        className="mb-5"
                                        title="Fully onboarded at Clearhaus"
                                        isAvailable={
                                            clearhausApplicationState?.firstStageSubmitted &&
                                            clearhausApplicationState.secondStageSubmitted &&
                                            getProviderStatus(gatewayProviders, "CreditCard", "Clearhaus", true) === "Ready"
                                        }
                                        isAlreadySent={!!clearhausApplicationState?.fullNotificationSentDate}
                                        alreadySentText={
                                            clearhausApplicationState?.fullNotificationSentDate &&
                                            getDateToLocalStringWithOptions(clearhausApplicationState?.fullNotificationSentDate, {
                                                dateStyle: "medium",
                                            })
                                        }
                                        sendEmail={async () => await sendClearhausEmail(false)}
                                    />
                                </>
                            )}
                            {applicationData?.isPendingUpdate && (
                                <>
                                    <p className="font-italic">
                                        <ins>! Changes at Clearhaus are pending</ins>
                                    </p>
                                    <p>Once the changes are approved by Clearhaus, notify the merchant by sending an email:</p>
                                    <SendEmailBoxRow
                                        className="mb-5"
                                        title="Info updated at Clearhaus"
                                        isAvailable={true}
                                        isAlreadySent={false}
                                        sendEmail={async () =>
                                            await dispatch(
                                                acceptUpdateApplicationInfoThunk(
                                                    swMerchantId,
                                                    applicationData?.onboardingId || "",
                                                    applicationData?.webshopIds || []
                                                )
                                            )
                                        }
                                    />
                                </>
                            )}
                            {(getProvider("MobilePayOnline", "MobilePayOnline", gatewayProviders)?.enabled ?? false) && (
                                <MPOAdditionalInfo
                                    webshopServicesStatus={webshopServicesStatus}
                                    env={env}
                                    swMerchantId={swMerchantId}
                                    webshopId={selectedWebshopId}
                                />
                            )}
                            <p className="mb-2">
                                <b>Additional info</b>
                            </p>
                            <KlarnaAdditionalInfo webshopServicesStatus={webshopServicesStatus} />
                            <NetsAdditionalInfo webshopServicesStatus={webshopServicesStatus} />
                            <SwishAdditionalInfo webshopServicesStatus={webshopServicesStatus} />
                            <VippsAdditionalInfo webshopServicesStatus={webshopServicesStatus} />
                            <ApplePayAdditionalInfo webshopServicesStatus={webshopServicesStatus} />
                            <h5 className="mb-3 font-weight-bold">Features onboardings:</h5>
                            <OnboardStatusRow
                                label="Win-back"
                                fieldName="winbackStatus"
                                register={register}
                                isEnabled={getFeatureEnabled(webshopFeaturesDetails, "Winback") ?? false}
                            />
                            <WinbackSmsSenderNameStatus register={register} winbackDetails={winbackDetails} />
                            <OnboardStatusRow
                                label="PlusSell"
                                fieldName="plusSellStatus"
                                register={register}
                                isEnabled={getFeatureEnabled(webshopFeaturesDetails, "PlusSell") ?? false}
                            />
                            <OnboardStatusRow
                                label="ReturnSell"
                                fieldName="returnSellStatus"
                                register={register}
                                isEnabled={getFeatureEnabled(webshopFeaturesDetails, "ReturnSell") ?? false}
                            />
                            <OnboardStatusRow
                                label="Anywhere Mobile"
                                fieldName="anywhereMobileStatus"
                                register={register}
                                isEnabled={getFeatureEnabled(webshopFeaturesDetails, "AnywhereMobile") ?? false}
                            />
                            <h5 className="mb-3 font-weight-bold">Tools:</h5>
                            <Row>
                                <div className="col-6">
                                    Copy onboardings from webshop:
                                    {copyFromWebshopId && (
                                        <div className="warning">Saving changes will only apply the copying</div>
                                    )}
                                </div>
                                <div className="col-6">
                                    <FieldsetFormGroup field className="mb-4">
                                        <DropDownList
                                            defaultValue={copyFromWebshopId}
                                            options={[
                                                { text: "None", value: "" },
                                                ...webshops
                                                    .filter((w) => w.webshopId !== selectedWebshopId)
                                                    .map((w) => {
                                                        return { text: removeSchemeFromHostName(w.hostName), value: w.webshopId }
                                                    }),
                                            ]}
                                            onChange={(e) => {
                                                setCopyFromWebshopId(e.target.value)
                                            }}
                                        ></DropDownList>
                                    </FieldsetFormGroup>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6 submit-container">
                                    <StandardButton
                                        inverted
                                        className="mr-3"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            navigationService.navigate("/plugins")
                                        }}
                                    >
                                        {t("switchservices.close")}
                                    </StandardButton>
                                    <SubmitButton formState={formState} dark noBorder>
                                        {t("switchservices.savechanges")}
                                    </SubmitButton>
                                </div>
                            </Row>
                        </Form>
                    </SpinnerContainer>
                </div>
            </PageContainer>
        </PageWrapper>
    )
}

const NetsAdditionalInfo = (props: { webshopServicesStatus: IWebshopProviderConfig | undefined }) => {
    if (!props.webshopServicesStatus?.netsDankortId && !props.webshopServicesStatus?.netsForbrugsforeningenId) {
        return <></>
    }
    return (
        <Row className="mb-3">
            <AdditionalInfoData label="Nets Id - Dankort" value={props.webshopServicesStatus?.netsDankortId} />
            <AdditionalInfoData
                label="Nets Id - Forbrugsforeningen"
                value={props.webshopServicesStatus?.netsForbrugsforeningenId}
            />
        </Row>
    )
}

const MPOAdditionalInfo = (props: {
    swMerchantId: string
    webshopId: string
    webshopServicesStatus: IWebshopProviderConfig | undefined
    env: TEnv
}) => {
    const dispatch = useReduxDispatch()
    const [mobilePayExternalId, setMobilePayExternalId] = useState(props.webshopServicesStatus?.mobilePayExternalId)

    const useOtherWebshopsOnboarding = !!mobilePayExternalId && mobilePayExternalId !== props.webshopId

    useEffect(() => {
        setMobilePayExternalId(props.webshopServicesStatus?.mobilePayExternalId)
    }, [props.webshopServicesStatus?.mobilePayExternalId])

    const otherFields = (
        <>
            <AdditionalInfoData
                label="MPO Merchant ID"
                value={props.webshopServicesStatus?.mobilePayMerchantId}
                valueSize="small"
            />
            <AdditionalInfoData label="MPO MCC" value={props.webshopServicesStatus?.mobilePayMcc} />
            <AdditionalInfoData label="MPO VAT" value={props.webshopServicesStatus?.mobilePayVatNumber} />
        </>
    )

    const performBtnAction = async () => {
        if (!useOtherWebshopsOnboarding && props.env !== "Production") {
            dispatch(
                addModalThunk({
                    type: "error",
                    errorMessage:
                        "You are not allowed to onboard MobilePay on non-prod environments. Onboard on production, and then use production webshopId for this environment.",
                })
            )
            return
        }
        await dispatch(
            mpoOnboardForMpoApiThunk(
                props.swMerchantId,
                props.webshopId,
                !props.webshopServicesStatus?.mobilePayMerchantId,
                mobilePayExternalId ?? ""
            )
        )
    }

    const actionBtn = (
        <>
            <StandardButtonWithSpinner
                className="switchservices-page__mpo-onboard-btn"
                onClick={async () => {
                    await performBtnAction()
                }}
            >
                {props.webshopServicesStatus?.mobilePayMerchantId
                    ? "Remove onboarding"
                    : useOtherWebshopsOnboarding
                    ? "Onboard at MobilePay API (No fee - using other webshop's onboarding)"
                    : "Onboard at MobilePay API (Swiipe will incur a daily fee)"}
            </StandardButtonWithSpinner>
            <StandardButtonWithSpinner
                className="switchservices-page__mpo-onboard-btn d-none"
                onClick={async () => {
                    // Normally this button is hidden. Reveal to force onboard/offboard, if Swiipe DB status does not match MobilePay account
                    await performBtnAction()
                }}
            >
                {props.webshopServicesStatus?.mobilePayMerchantId ? "Force Remove onboarding" : "Force Onboard at MobilePay API"}
            </StandardButtonWithSpinner>
        </>
    )

    return (
        <>
            <b>Mobile Pay</b>
            {props.env !== "Production" && (
                <>
                    <div className="switchservices-page__mpo-onboard-intro">
                        {
                            "Write the webshopId of a production webshop, to use another webshop's MobilePay onboarding. (Betatest only)"
                        }
                    </div>
                    <FloatingLabelInput
                        name="mobilePayExternalId"
                        value={mobilePayExternalId ?? ""}
                        onChange={(e) => {
                            setMobilePayExternalId(e.currentTarget.value)
                        }}
                        placeholder={"MobilePay External ID (Swiipe webshopId)"}
                    />
                    {actionBtn}
                    <Row className="mb-3">{otherFields}</Row>
                </>
            )}
            {props.env === "Production" && (
                <>
                    {actionBtn}
                    <Row className="mb-3">
                        <AdditionalInfoData
                            label="MPO External ID"
                            value={props.webshopServicesStatus?.mobilePayExternalId}
                            valueSize="small"
                        />
                        {otherFields}
                    </Row>
                </>
            )}

            {props.webshopServicesStatus?.mobilePayShopIconUrl ||
                (props.webshopServicesStatus?.mobilePayShopName && (
                    <Row className="mb-3">
                        <AdditionalInfoData label="MPO Icon Url" value={props.webshopServicesStatus?.mobilePayShopIconUrl} />
                        <AdditionalInfoData label="MPO Shop Name" value={props.webshopServicesStatus?.mobilePayShopName} />
                    </Row>
                ))}
        </>
    )
}

const SwishAdditionalInfo = (props: { webshopServicesStatus: IWebshopProviderConfig | undefined }) => {
    if (!props.webshopServicesStatus?.swishId) {
        return <></>
    }
    return (
        <Row className="mb-3">
            <AdditionalInfoData label="Swish Id" value={props.webshopServicesStatus?.swishId} />
        </Row>
    )
}

const VippsAdditionalInfo = (props: { webshopServicesStatus: IWebshopProviderConfig | undefined }) => {
    if (!props.webshopServicesStatus?.vippsId) {
        return <></>
    }
    return (
        <Row className="mb-3">
            <AdditionalInfoData label="Vipps Id" value={props.webshopServicesStatus?.vippsId} />
        </Row>
    )
}

const ApplePayAdditionalInfo = (props: { webshopServicesStatus: IWebshopProviderConfig | undefined }) => {
    if (!props.webshopServicesStatus?.applePayId) {
        return <></>
    }
    return (
        <Row className="mb-3">
            <AdditionalInfoData label="ApplePay Id" value={props.webshopServicesStatus?.applePayId} />
        </Row>
    )
}

const KlarnaAdditionalInfo = (props: { webshopServicesStatus: IWebshopProviderConfig | undefined }) => {
    if (!props.webshopServicesStatus?.klarnaId || !props.webshopServicesStatus?.klarnaKey) {
        return <></>
    }
    return (
        <Row className="mb-3">
            <AdditionalInfoData label="Klarna Id" value={props.webshopServicesStatus?.klarnaId} />
            <AdditionalInfoData label="Klarna Key" value={props.webshopServicesStatus?.klarnaKey} />
        </Row>
    )
}

const AdditionalInfoData = (props: { label: string; value: string | undefined; valueSize?: "small" | "normal" }) => {
    if (!props.value) {
        return <></>
    }
    return (
        <>
            <div className="col-6">{props.label}:</div>
            <div className={cn("col-6", props.valueSize === "small" && "switchservices-page__info-data-value--small")}>
                {props.value}
            </div>
        </>
    )
}

const OnboardStatusRow = (props: {
    label: string
    fieldName: string
    register: React.Ref<HTMLInputElement>
    isEnabled: boolean
}) => {
    if (!props.isEnabled) {
        return <></>
    }
    return (
        <StatusRowBase
            firstRowLeft={`${props.label} enabled:`}
            firstRowRight={props.isEnabled ? "true" : "false"}
            secondRowLeft="Status:"
            secondRowRight={
                <FieldsetFormGroup field className="mb-2">
                    <DropDownList
                        innerRef={props.register}
                        name={props.fieldName}
                        defaultValue={"NotOnboarded"}
                        options={getDropDownOptions()}
                    />
                </FieldsetFormGroup>
            }
        />
    )
}

const WinbackSmsSenderNameStatus = (props: { winbackDetails?: IWinbackDetails; register: React.Ref<HTMLInputElement> }) => {
    const { t } = useTranslation()
    const winbackDetails = props.winbackDetails
    const senderName = winbackDetails?.smsSenderName

    if (!winbackDetails || !senderName) {
        return <></>
    }

    const isSenderNamePending = winbackDetails.smsSenderNameStatus === "Pending"

    return (
        <StatusRowBase
            firstRowLeft={
                <div className={cn(!isSenderNamePending && "mb-4")}>
                    <span>{t("switchservices.winbackSmsSenderName")}</span>
                    <br />
                    <span>{`"${senderName}"`}</span>
                </div>
            }
            firstRowRight={
                <FieldsetFormGroup field className="mb-2">
                    <DropDownList
                        innerRef={props.register}
                        name="winbackSmsSenderNameStatus"
                        defaultValue={winbackDetails?.smsSenderNameStatus}
                        options={[
                            { text: "Pending", value: "Pending" },
                            { text: "Approved", value: "Approved" },
                        ]}
                    />
                </FieldsetFormGroup>
            }
            secondRowRight={
                (isSenderNamePending && (
                    <Alert color="danger" className="mt-0 mb-4">
                        {t("switchservices.winbackSmsSenderNameStatusAlert")}
                    </Alert>
                )) ||
                undefined
            }
        />
    )
}

const StatusRowBase = (props: {
    firstRowLeft?: JSX.Element | string
    firstRowRight?: JSX.Element | string
    secondRowLeft?: JSX.Element | string
    secondRowRight?: JSX.Element | string
}) => {
    return (
        <>
            <Row>
                <div className="col-6">{props.firstRowLeft}</div>
                <div className="col-6">{props.firstRowRight}</div>
            </Row>
            <Row>
                <div className="col-6">{props.secondRowLeft}</div>
                <div className="col-6">{props.secondRowRight}</div>
            </Row>
        </>
    )
}

const SendEmailBoxRow = (
    props: {
        className?: string
        title: string
    } & ISendEmailBoxProps
) => {
    return (
        <Row className={props.className}>
            <div className="col-6">{props.title}</div>
            <SendEmailBox
                className="col-6"
                isAvailable={props.isAvailable}
                isAlreadySent={props.isAlreadySent}
                alreadySentText={props.alreadySentText}
                sendEmail={props.sendEmail}
            />
        </Row>
    )
}

const getDropDownOptions = (): IOnboardingStatusDropDownItem[] => {
    const states: IOnboardingStatusDropDownItem[] = [
        { text: "Action Required", value: "ActionRequired" },
        { text: "InProgress", value: "InProgress" },
        { text: "Ready", value: "Ready" },
    ]

    return states
}

export default SwitchServicesPage
