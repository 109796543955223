import cn from "classnames"
import React, { useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link, useCurrentRoute } from "react-navi"
import { Form, FormGroup } from "reactstrap"
import {
    FieldsetFormGroup,
    FloatingLabelInput,
    ShowErrorMessages,
    isValidEmail,
    sharedConfigurationSelectors,
    useSelector,
    userReducerActions,
    userSelectors,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { loginThunk } from "../../../store/thunks/loginThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { ELoginFlow } from "../../../type/ELoginFlow"
import { LoginFormModel } from "../../../type/loginform"
import EmailInputComp from "../input/EmailInputComp/EmailInputComp"
import LinkButton from "./../../buttons/LinkButton"
import SubmitButton from "./../../buttons/SubmitButton"
import "./LoginForm.scss"

interface ILoginFormProps {
    isTrimmed: boolean
    email?: string
    flowType?: ELoginFlow
}

const LoginForm = ({ isTrimmed, email, flowType }: ILoginFormProps) => {
    const { handleSubmit, register, formState, getValues, setValue } = useForm<LoginFormModel>()
    const { t } = useTranslation()
    const currentRoute = useCurrentRoute()
    const dispatch = useReduxDispatch()
    const emailToPrefill = useSelector(userSelectors.userEmailLoginFormPrefill)
    const env = useSelector(sharedConfigurationSelectors.environment)

    useEffect(() => {
        if (emailToPrefill && getValues().email === "") {
            setValue("email", emailToPrefill)
        }
    }, [])

    const onSubmit: SubmitHandler<LoginFormModel> = async (data, e) => {
        try {
            await dispatch(
                loginThunk(
                    {
                        ...data,
                        email: email || data.email,
                    },
                    (currentRoute.url.query.returnUrl || "/").split("#")[0]
                )
            )
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
            <FieldsetFormGroup field>
                <EmailInputComp
                    inputName="email"
                    {...(email ? { value: email } : {})}
                    register={register}
                    onChange={() => {
                        if (isValidEmail(getValues().email)) {
                            dispatch(userReducerActions.setUserEmailLoginFormPrefill(getValues().email))
                        } else {
                            dispatch(userReducerActions.setUserEmailLoginFormPrefill())
                        }
                    }}
                />
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <FloatingLabelInput
                    name="password"
                    innerRef={register(
                        valFuncRequiredAndPattern(validationPatterns.password, t("validation.loginform.wrongpassword"))
                    )}
                    type="password"
                    placeholder={t("login.password")}
                />
                <Link
                    id="forgotpw-link"
                    className="float-right"
                    href={
                        "/forgotpassword?returnUrl=" + encodeURIComponent(document.location.pathname + document.location.search)
                    }
                >
                    {t("login.forgotpw")}
                </Link>
            </FieldsetFormGroup>
            <FieldsetFormGroup className="form-group-login-btn pt-4">
                <SubmitButton formState={formState} id="login-btn" isLogin />
            </FieldsetFormGroup>
            <ShowErrorMessages<LoginFormModel>
                formState={formState}
                singleErrorFromCount={{ errorMessage: t("login.errors.emailandpassword"), errorsCount: 2 }}
            />
            {!flowType && (
                <>
                    {env === "Sandbox" ? (
                        <LinkButton title={t("signup.title")} href="/merchant_signup" isSandbox textOnly />
                    ) : (
                        <>
                            <FormGroup className={cn("text-center", { "d-none": isTrimmed })}>
                                <label id="signup-label" htmlFor="signup-btn">
                                    {t("login.signuplabel")}
                                </label>
                                <LinkButton href="/signup" id="signup-btn" title={t("login.signup")} />
                            </FormGroup>
                            <FormGroup className={cn("text-center", { "d-none": isTrimmed })}>
                                <LinkButton textOnly href="/merchant_signup" title={t("login.signupmerchant")} />
                            </FormGroup>
                        </>
                    )}
                </>
            )}
        </Form>
    )
}

export default LoginForm
