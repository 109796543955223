import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import "./PaginationSection.scss"

export interface IPaginationSection {
    label: string
    pagesCount: number
    pageNumber: number
    morePagesAvailable?: boolean
    className?: string
    onPageChange: (pageNumber: number) => void
}

export const PaginationSection = ({
    label,
    pagesCount,
    pageNumber,
    morePagesAvailable,
    className,
    onPageChange,
}: IPaginationSection) => {
    const { t } = useTranslation()

    return (
        <div className={cn("sw-pagination-section", className)}>
            <span className="per-page">{label}</span>
            <div className={pageNumber - 1 > 0 ? "active" : ""} onClick={() => onPageChange(pageNumber - 1)}>
                {"<"}
            </div>
            <span className="page-number">
                {t("table.pagination.paginationNumbers", {
                    pageNumber: pageNumber,
                    pageCount: `${pagesCount}${morePagesAvailable ? "+" : ""}`,
                })}
            </span>
            <div className={pageNumber < pagesCount ? "active" : ""} onClick={() => onPageChange(pageNumber + 1)}>
                {">"}
            </div>
        </div>
    )
}
