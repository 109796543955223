import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "swiipe.portal.shared"
import PlatformSelect from "../../components/merchant/PlatformSelect"
import PageWrapperWithTabs from "../../components/page/PageWrapperWithTabs"
import ReleaseNoteDisplay from "../../components/releaseNotes/ReleaseNoteDisplay"
import { getReleaseNotes } from "../../services/releaseNotesService"
import { releaseNoteSelectors } from "../../store/reducers/releaseNoteReducer"
import { fetchReleaseNotes } from "../../store/thunks/releaseNotesThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { TReleaseNoteContext } from "../../type/releaseNotes/releaseNote"

interface IReleaseNotesPage {}

const ReleaseNotesPage = ({}: IReleaseNotesPage) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const releaseNotes = useSelector(releaseNoteSelectors.releaseNotes)
    const [selectedContext, setSelectedContext] = useState<TReleaseNoteContext>("woocommerce")

    // For now hardcoded to en instead of: userData?.user.language?.toUpperCase()
    const notesToShow = getReleaseNotes(releaseNotes, {
        context: selectedContext,
        language: "en".toUpperCase(),
        fallbackLanguage: "en".toUpperCase(),
    })

    useEffect(() => {
        dispatch(fetchReleaseNotes(false))
    }, [])

    return (
        <>
            <PlatformSelect
                otherOptions={[{ value: "other", label: t("releasenotes.other") }]}
                onPlatformChange={(context) => setSelectedContext(context as TReleaseNoteContext)}
            ></PlatformSelect>

            <PageWrapperWithTabs
                tabs={notesToShow?.map((rn) => {
                    return {
                        title: rn.version,
                        id: rn.releaseNoteId,
                        element: <ReleaseNoteDisplay key={rn.releaseNoteId} releaseNote={rn} />,
                    }
                })}
            ></PageWrapperWithTabs>
        </>
    )
}

export default ReleaseNotesPage
