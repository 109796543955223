import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { endpointsShared } from "../data/endpointsShared"
import { mapCvrApiResponseToCompanyFields, mapVatCheckApiResponseToCompanyFields } from "../services/companyDetailsMapper"
import { isLookupSupported } from "../services/companySearchService"
import { SharedStoreState } from "../store/SharedStoreState"
import { companySearchReducerActions } from "../store/companySearchReducer"
import { organizationReducerActions } from "../store/organizationReducer"
import { ICheckVatResponse } from "../types/company/ICheckVatResponse"
import { ICompanyData } from "../types/company/ICompanyData"
import { ICompanyFieldsBase } from "../types/company/ICompanyFieldsBase"
import { ICvrApiCompanyDetails } from "../types/company/ICvrApiCompanyDetails"
import { TCountryCode } from "../types/general/countryCodes"
import { TOrganizationType } from "../types/organization/TOrganizationType"
import { getKeys } from "../utils/objectUtil"
import { makeRequestThunk, requestThunk } from "./serverThunks"

export const lookupByNameCvrOrVatThunk =
    (
        country: string,
        query: string,
        errorCallBack: (notFound: boolean) => void
    ): ThunkAction<Promise<void>, SharedStoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(companySearchReducerActions.setExecutingSearchRequest(true))

        switch (country) {
            case "DK":
                await dispatch(companyInfoByCvr("lookup", query, errorCallBack))
                await dispatch(companySearchReducerActions.setExecutingSearchRequest(false))
                break
            default:
                //In case full VAT number passed
                query = query.replace(country, "")
                await dispatch(companyInfoByVat("lookup", country, query, errorCallBack))
                await dispatch(companySearchReducerActions.setExecutingSearchRequest(false))
        }
    }

export const getCompanyInfo =
    (
        country: string,
        query: string,
        errorCallBack?: (cvrNotfound: boolean) => void
    ): ThunkAction<Promise<ICompanyFieldsBase | undefined>, SharedStoreState, null, AnyAction> =>
    async (dispatch) => {
        const callBackWrapper = (cvrNotFound: boolean) => {
            // TODO: Implement support for looking up VAT for not supported countries
            if (isLookupSupported(country as TCountryCode)) {
                dispatch(organizationReducerActions.setIsCvrNotFound(query, cvrNotFound))
            }

            if (errorCallBack) {
                errorCallBack(cvrNotFound)
            }
        }

        switch (country) {
            case "DK":
                return await dispatch(companyInfoByCvr("get", query, callBackWrapper))
            default:
                //In case full VAT number passed
                query = query.replace(country, "")
                return await dispatch(companyInfoByVat("get", country, query, callBackWrapper))
        }
    }

const companyInfoByVat =
    (
        actionType: "get" | "lookup",
        country: string,
        vat?: string,
        errorCallBack?: (notFound: boolean) => void
    ): ThunkAction<Promise<ICompanyFieldsBase | undefined>, SharedStoreState, null, AnyAction> =>
    async (dispatch) => {
        if (!vat) {
            return
        }

        try {
            const result = await dispatch(
                requestThunk<ICheckVatResponse>(endpointsShared.Self.checkVat(country, vat), undefined, { noErrorAlert: true })
            )

            if (!result.isValid) {
                //In case "service is unavailable" error is popped
                //do not mark request as not found
                const notFound = !result.userError?.toLocaleLowerCase().includes("service is unavailable")

                if (errorCallBack) {
                    errorCallBack(notFound)
                }
                return
            }

            const companyFields = mapVatCheckApiResponseToCompanyFields(result, country)
            switch (actionType) {
                case "get":
                    if (companyFields.companyNumber !== vat) {
                        if (errorCallBack) {
                            const notFound = true
                            errorCallBack(notFound)
                        }
                        return
                    }

                    dispatch(companySearchReducerActions.addUserCompanyDetails(companyFields))
                    break
                case "lookup":
                    dispatch(companySearchReducerActions.addResult(companyFields))
                    break
            }

            return companyFields
        } catch (e) {
            const resultError = (e as any).error
            if (resultError) {
                console.error("vat check error: " + resultError)
            }

            if (errorCallBack) {
                const notFound = false
                errorCallBack(notFound)
            }
        }

        return
    }

const companyInfoByCvr =
    (
        actionType: "get" | "lookup",
        cvr?: string,
        errorCallBack?: (notfound: boolean) => void
    ): ThunkAction<Promise<ICompanyFieldsBase | undefined>, SharedStoreState, null, AnyAction> =>
    async (dispatch) => {
        const endpointOrigin = "https://cvrapi.dk/api"

        if (!cvr) {
            return
        }

        try {
            const result = await dispatch(
                makeRequestThunk<ICvrApiCompanyDetails>("get", endpointOrigin + "?search=" + cvr + "&country=dk", undefined, {
                    noErrorAlert: true,
                })
            )

            if (result.error) {
                // If response is OK 200, but
                // returned with error
                // We can't use data with error
                // so throw error
                throw result
            }

            const companyFields = mapCvrApiResponseToCompanyFields(result)

            switch (actionType) {
                case "lookup":
                    if (result.enddate === null) {
                        dispatch(companySearchReducerActions.addResult(companyFields))
                    }
                    break
                case "get":
                    if (result.vat.toString() !== cvr) {
                        throw { rawError: "404" }
                    }

                    dispatch(companySearchReducerActions.addUserCompanyDetails(companyFields))
                    break
            }

            return companyFields
        } catch (e) {
            const rawError = (e as any).rawError
            const resultError = (e as any).error

            const notFound = rawError && rawError.includes("404")
            if (errorCallBack) {
                errorCallBack(notFound)
            }

            if (resultError) {
                console.error("cvrapi.dk error: " + resultError)
            }
        }

        return
    }

export const setSelectedSearchCompanyThunk =
    (selected?: ICompanyFieldsBase): ThunkAction<Promise<void>, SharedStoreState, null, AnyAction> =>
    async (dispatch) => {
        dispatch(companySearchReducerActions.setSelected(selected))
    }

export const clearAllCompanySearchResultsThunk =
    (): ThunkAction<Promise<void>, SharedStoreState, null, AnyAction> => async (dispatch) => {
        dispatch(companySearchReducerActions.clearAllResults())
    }

export const fetchOrganizationCompanyDetailsThunk =
    (
        organizationId: string,
        country: string,
        cvr: string,
        organizationType: TOrganizationType
    ): ThunkAction<Promise<void>, SharedStoreState, null, AnyAction> =>
    async (dispatch) => {
        const companyDetails = await dispatch(
            requestThunk<ICompanyData>(
                endpointsShared.Identity.getCompanyDetails,
                {
                    params: {
                        organizationId: organizationId,
                        organizationType: organizationType,
                    },
                },
                {
                    noErrorAlert: true,
                }
            )
        )

        if (getKeys(companyDetails).length) {
            dispatch(organizationReducerActions.setCompanyDetails(cvr, companyDetails))
            return
        }

        dispatch(ensureOrganizationCompanyDetailsThunk(organizationId, country, cvr, organizationType))
    }

export const ensureOrganizationCompanyDetailsThunk =
    (
        organizationId: string,
        country: string,
        vat: string,
        organizationType: TOrganizationType
    ): ThunkAction<Promise<void>, SharedStoreState, null, AnyAction> =>
    async (dispatch) => {
        const companyDataFromApi = await dispatch(getCompanyInfo(country, vat))

        if (!companyDataFromApi || companyDataFromApi.companyNumber.toString() !== vat) {
            return
        }

        const compDetails = companyDataFromApi as ICompanyFieldsBase

        await dispatch(
            requestThunk<ICompanyData>(endpointsShared.Identity.setCompanyDetails, {
                data: {
                    organizationId: organizationId,
                    organizationType: organizationType,
                    name: compDetails.companyName,
                    country: compDetails.companyCountry,
                    type: compDetails.companyType,
                    phone: compDetails.companyPhone,
                    email: compDetails.companyEmail,
                    address: compDetails.companyAddress,
                    postcode: compDetails.companyPostcode,
                    city: compDetails.companyCity,
                },
            })
        )

        const companyData = {
            companyCountry: compDetails.companyCountry,
            companyName: compDetails.companyName,
            companyAddress: compDetails.companyAddress,
            companyCity: compDetails.companyCity,
            companyPostcode: compDetails.companyPostcode,
            companyPhone: compDetails.companyPhone,
            companyEmail: compDetails.companyEmail,
            companyType: compDetails.companyType,
        } as ICompanyData

        dispatch(organizationReducerActions.setCompanyDetails(vat, companyData))
    }
