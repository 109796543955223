import React from "react"
import { useTranslation } from "react-i18next"

import { getTranslatedCountryNameByISO } from "../../services/countriesService"
import { ICompanyData } from "../../types/company/ICompanyData"
import { IMerchantPreOnboardingInternalUser } from "../../types/merchant/IMerchantPreOnboardingCreator"
import { IMerchantPreOnboardingOwner } from "../../types/merchant/IMerchantPreOnboardingOwner"
import { formatLocalDate } from "../../utils/dateUtil"
import { getFullNameFromFirstAndLastName } from "../../utils/formatUtil"
import "./MerchantOfferHeader.scss"

interface IMerchantOfferHeaderProps {
    company: ICompanyData | undefined
    countryCode?: string
    owner: IMerchantPreOnboardingOwner | undefined
    creator: IMerchantPreOnboardingInternalUser | undefined
    created?: string
}

export const MerchantOfferHeader = ({ company, countryCode, owner, creator, created }: IMerchantOfferHeaderProps) => {
    const { t } = useTranslation()

    return (
        <div className="merchant-offer-header">
            <div className="merchant-offer-header-background"></div>
            <div className="merchant-offer-header-content">
                <div className="merchant-offer-header-title">
                    {t("merchantOffer.header.title", {
                        company: company?.companyName,
                    })}
                </div>
                <div className="merchant-offer-header-main">
                    <div className="merchant-offer-header-main-left">
                        <div className="merchant-offer-header-heading">{company?.companyName}</div>
                        {company?.companyAddress && <div>{company?.companyAddress}</div>}
                        {company?.companyPostcode && (
                            <div>
                                {company?.companyPostcode} {company.companyCity}
                            </div>
                        )}
                        {countryCode && <div>{getTranslatedCountryNameByISO(countryCode, t)}</div>}
                        <div className="merchant-offer-header-heading merchant-offer-header-owner">
                            {getFullNameFromFirstAndLastName(owner?.firstName, owner?.lastName)}
                        </div>
                        <div>{owner?.email}</div>
                        <div>{owner?.phone}</div>
                    </div>
                    <div className="merchant-offer-header-main-right">
                        <div>
                            {t("merchantOffer.header.created")}: {formatLocalDate(created, "long")}
                        </div>
                        <div>
                            {t("merchantOffer.header.createdBy")}:{" "}
                            {getFullNameFromFirstAndLastName(creator?.firstName, creator?.lastName)}
                        </div>
                        <div>{creator?.email}</div>
                        <div>{creator?.phone}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
