import { useEffect } from "react"
import { roundNumberToMaxDecimals, useSelector } from "swiipe.portal.shared"
import { IMerchantDashboardSelectedDates } from "../components/merchantDashboard/header/dateFilters/MerchantDashboardDateFilters"
import { StoreState } from "../store/StoreState"
import { IMerchantDashboardSearchData } from "../store/reducers/merchantDashboardReducer"
import { merchantSelectors } from "../store/reducers/merchantReducer"
import { merchantServicesSelectors } from "../store/reducers/merchantServicesReducer"
import { fetchWebshopFeaturesDetailsThunk } from "../store/thunks/merchantThunks"
import { useReduxDispatch } from "../store/useReduxDispatch"
import { IMerchantDashboardServerPeriodData } from "../type/merchantDashboard/IMerchantDashboardServerData"
import { IMerchantDashboardWinBackValues } from "../type/merchantDashboard/IMerchantDashboardWinBackValues"
import { TMerchantDashboardComparePeriod } from "../type/merchantDashboard/TMerchantDashboardComparePeriod"
import { MixedMerchantDetails } from "../type/mixedmerchantdetails"
import {
    addDays,
    addMonths,
    addYears,
    formatLocalShortDate,
    getDateUTCFilteringRange,
    getDaysBetween,
    getMonthsBetween,
} from "../util/dateUtil"
import { getFeatureEnabledDate, isFeatureEnabledAndReady } from "./featureService"
import { removeSchemeFromHostName } from "./merchantService"
import { isProviderEnabledAndReady } from "./paymentService"

export function calculateComparePeriod(
    type: TMerchantDashboardComparePeriod,
    from?: Date,
    to?: Date,
    customFrom?: Date,
    customTo?: Date
) {
    if (!from || !to) {
        return {
            compareFromUTC: undefined,
            compareToUTC: undefined,
            compareFrom: undefined,
            compareTo: undefined,
        }
    }

    const { compareFrom, compareTo } = getComparePeriod(type, from, to, customFrom, customTo)
    const { dateFromUTC, dateToUTC } = getDateUTCFilteringRange(compareFrom, compareTo)

    return {
        // For request
        compareFromUTC: dateFromUTC,
        compareToUTC: dateToUTC && addDays(dateToUTC, -1),
        // For display
        compareFrom,
        compareTo: compareTo && addDays(compareTo, -1),
    }
}

function getComparePeriod(type: TMerchantDashboardComparePeriod, from: Date, to: Date, customFrom?: Date, customTo?: Date) {
    if (type === "lastPeriod" || (type === "customPeriod" && !customFrom && !customTo)) {
        if (from.getDate() === 1 && to.getDate() === 1) {
            const months = getMonthsBetween(from, to)
            return {
                compareFrom: addMonths(from, -months),
                compareTo: from,
            }
        }
        const days = getDaysBetween(from, to)
        return {
            compareFrom: addDays(from, -days),
            compareTo: from,
        }
    }
    if (type === "periodLastYear") {
        return {
            compareFrom: addYears(from, -1),
            compareTo: addYears(to, -1),
        }
    }
    if (type === "customPeriod" && customFrom && customTo) {
        return {
            compareFrom: customFrom,
            compareTo: customTo,
        }
    }
    return {
        compareFrom: undefined,
        compareTo: undefined,
    }
}

export function getWinBackValues(
    period: IMerchantDashboardServerPeriodData,
    paymentsEnabled: boolean,
    winBackEnabled: boolean
): IMerchantDashboardWinBackValues {
    const winBack = period.winBack
    if (!paymentsEnabled) {
        return {
            winBackAmountTotal: 0,
            winBackNotified: 0,
            winBackSucceeded: 0,
        }
    }
    if (winBackEnabled) {
        return {
            winBackAmountTotal: winBack.amountTotal,
            winBackNotified: winBack.notified,
            winBackSucceeded: winBack.succeeded,
        }
    }
    // Showcase data
    const showcaseSucceededPercent = 20
    const showcaseOrderFailedPercent = 5
    const showcaseMinNotified = 0
    const showcaseMinOrderAvgSize = 500
    const notified = Math.max(Math.round((period.orders.count * showcaseOrderFailedPercent) / 100), showcaseMinNotified)
    const succeeded = Math.round((notified * showcaseSucceededPercent) / 100)
    return {
        winBackNotified: notified,
        winBackSucceeded: succeeded,
        winBackAmountTotal: roundNumberToMaxDecimals(succeeded * Math.max(period.orders.avgSize, showcaseMinOrderAvgSize), 2),
    }
}

export const findIndexDashboardDataWithSameDates = (
    dashboardData: IMerchantDashboardSearchData[],
    selectedDates: IMerchantDashboardSelectedDates
) => {
    return dashboardData.findIndex(
        (d) =>
            d.selectedDates.dates.fromDateUTC?.getTime() === selectedDates.dates.fromDateUTC?.getTime() &&
            d.selectedDates.dates.toDateUTC?.getTime() === selectedDates.dates.toDateUTC?.getTime() &&
            d.selectedDates.comparePeriod.fromDateUTC?.getTime() === selectedDates.comparePeriod.fromDateUTC?.getTime() &&
            d.selectedDates.comparePeriod.toDateUTC?.getTime() === selectedDates.comparePeriod.toDateUTC?.getTime()
    )
}

type TMerchantDashboardServices = "payments" | "plusSell" | "winback" | "externalAnalytics"
export type TMerchantDashboardServicesInfo = {
    [key in TMerchantDashboardServices]: {
        isEnabled: boolean
        enabledDate?: Date
    }
}
export function useMerchantDashboardServicesInfo(
    merchantDetails: MixedMerchantDetails | undefined,
    selectedWebshopId: string | undefined
) {
    const dispatch = useReduxDispatch()

    const swiipePlan = useSelector(merchantSelectors.swiipePlan)
    const featureDetails = useSelector((state: StoreState) =>
        merchantSelectors.featuresDetailsForWebshop(state, selectedWebshopId ?? "")
    )
    const paymentMethodStatuses = useSelector((state: StoreState) =>
        merchantServicesSelectors.statuses(state, merchantDetails?.swMerchant.swMerchantId ?? "", selectedWebshopId ?? "")
    )

    const whichMerchantDashboardServicesEnabled: TMerchantDashboardServicesInfo = {
        payments: {
            isEnabled:
                !!swiipePlan && isProviderEnabledAndReady(paymentMethodStatuses?.gatewayProviders, "CreditCard", "Clearhaus"),
        },
        plusSell: {
            isEnabled: isFeatureEnabledAndReady(featureDetails, "PlusSell") || false,
        },
        winback: {
            isEnabled: isFeatureEnabledAndReady(featureDetails, "Winback") || false,
        },
        externalAnalytics: {
            isEnabled: isFeatureEnabledAndReady(featureDetails, "ExternalAnalytics") || false,
            enabledDate: getFeatureEnabledDate(featureDetails, "ExternalAnalytics"),
        },
    }

    useEffect(() => {
        if (!selectedWebshopId || featureDetails) {
            return
        }

        dispatch(fetchWebshopFeaturesDetailsThunk(selectedWebshopId, false))
    }, [selectedWebshopId])

    return whichMerchantDashboardServicesEnabled
}

export function getDashboardExportFileName(
    webshopHostName: string,
    fromDate: Date,
    toDate: Date,
    fileExtension: "xlsx" | "csv" | "png"
) {
    const hostNameSafe = removeSchemeFromHostName(webshopHostName.replace(/\./g, "_"))
    const from = formatLocalShortDate(fromDate)
    const to = formatLocalShortDate(toDate)
    return `${hostNameSafe}_${from}_${to}.${fileExtension}`
}
