import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    DropDownList,
    FieldsetFormGroup,
    FloatingLabelInput,
    ShowErrorMessages,
    useSelector,
    valFuncRequired,
} from "swiipe.portal.shared"
import { Psp, getPsps } from "../../../services/paymentService"
import { salesSelectors } from "../../../store/reducers/salesReducer"
import { updateSalesPspThunk } from "../../../store/thunks/salesSettingsThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IUpdateSalesPspFormModel } from "../../../type/sales/IUpdateSalesPspFormModel"
import SubmitButton from "../../buttons/SubmitButton"
import ContentRightButtonContainer from "../../layouts/ContentRightButtonContainer"

interface ISalesPspFormProps {}

const SalesPspForm = ({}: ISalesPspFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const { handleSubmit, formState, register, reset, setValue } = useForm<IUpdateSalesPspFormModel>()

    const psps = getPsps()
    const settings = useSelector(salesSelectors.settings)
    let defaultPspType = Psp.QUICKPAY
    if (settings) {
        const defaultPsp = psps.find((p) => p.short === settings.psp)
        if (defaultPsp) {
            defaultPspType = defaultPsp.type
        }
    }
    const [currentPspType, setCurrentPspType] = useState(defaultPspType)
    const currentPsp = psps.find((p) => p.type === currentPspType)
    if (!currentPsp) {
        return null
    }

    const onSubmit: SubmitHandler<IUpdateSalesPspFormModel> = async (data, e) => {
        try {
            await dispatch(updateSalesPspThunk(data))
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <ContentRightButtonContainer button={<SubmitButton isSmall formState={formState} title={t("common.save")} />}>
                <FieldsetFormGroup field>
                    <DropDownList
                        innerRef={register({
                            required: true,
                        })}
                        defaultValue={currentPsp.short}
                        onChange={(e) => {
                            const psp = psps.find((p) => p.short === e.target.value)
                            if (psp) {
                                setCurrentPspType(psp.type)
                                if (currentPsp.requireApiKey && !psp.requireApiKey) {
                                    reset()
                                }
                                if (currentPsp.requireMerchantId && !psp.requireMerchantId) {
                                    reset()
                                }
                                setValue("psp", psp.short)
                            }
                        }}
                        name="psp"
                        options={psps.map((psp) => ({ text: psp.title, value: psp.short }))}
                    />
                </FieldsetFormGroup>
                {currentPsp.requireApiKey && (
                    <FieldsetFormGroup field>
                        <FloatingLabelInput
                            name="api_key"
                            defaultValue={settings ? settings.pspApiKey : ""}
                            innerRef={register(valFuncRequired(t("offersettings.validationerrors.pspApiKey")))}
                            placeholder={t("offersettings.psp.inputApiKeyPlaceholder")}
                        />
                    </FieldsetFormGroup>
                )}
                {currentPsp.requireMerchantId && (
                    <FieldsetFormGroup field>
                        <FloatingLabelInput
                            name="merchant_id"
                            defaultValue={settings ? settings.merchantId : ""}
                            innerRef={register(valFuncRequired(t("offersettings.validationerrors.pspMerchantId")))}
                            placeholder={t("offersettings.psp.inputMerchantIdPlaceholder")}
                        />
                    </FieldsetFormGroup>
                )}
            </ContentRightButtonContainer>
            <ShowErrorMessages formState={formState} />
        </Form>
    )
}

export default SalesPspForm
