import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import IconClose from "../../images/icons/close/close-gray.svg"
import IconFilter from "../../images/icons/filtering/icon-filter.svg"
import "./TableFilterBox.scss"

interface ITableFilterBox {
    showFilters: boolean
    closeFiltersCallback: () => void
    children: React.ReactNode
}

export const TableFilterBox = ({ showFilters, closeFiltersCallback, children }: ITableFilterBox) => {
    const { t } = useTranslation()

    return (
        <div className={cn("table-filter-box", { "d-none": !showFilters })}>
            <div className="table-filter-box__filter-text">
                <img src={IconFilter} />
                <span>{t("table.filtering.filterBy")}</span>
            </div>
            <div className={cn("table-filter-box__filter-options")}>{children}</div>
            <div className="table-filter-box__close-box">
                <img
                    src={IconClose}
                    onClick={() => {
                        closeFiltersCallback()
                    }}
                />
            </div>
        </div>
    )
}
