import { ActionsUnion, createAction } from "swiipe.portal.shared"
import { StoreState } from "../StoreState"
import { IA2aUserAccount } from "./../../type/a2a/IA2aUserAccount"

export type A2aState = Readonly<{
    accounts: {
        [merchantId: string]: IA2aUserAccount[]
    }
}>
const initialState: A2aState = { accounts: {} }

export const a2aReducerActions = {
    setMerchantAccounts: (merchantId: string, userAccounts: IA2aUserAccount[]) =>
        createAction("APP/A2A_SET_MERCHANT_ACCOUNTS", { merchantId, userAccounts }),
}

export type A2aActions = ActionsUnion<typeof a2aReducerActions>

export const a2aReducer = (state: A2aState = initialState, action: A2aActions) => {
    switch (action.type) {
        case "APP/A2A_SET_MERCHANT_ACCOUNTS": {
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    [action.merchantId]: action.userAccounts,
                },
            }
        }
    }

    return state
}

export const a2aSelectors = {
    accounts: (state: StoreState, merchantId: string) => (merchantId ? state.a2a.accounts[merchantId] : undefined),
}
