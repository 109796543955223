import React from "react"
import { useTranslation } from "react-i18next"
import { TextButton, useSelector } from "swiipe.portal.shared"
import { getDateToLocalStringWithOptions } from "../../services/dateTimeService"
import { getMerchantAllServicesStopped } from "../../services/merchantService"
import { navigationService } from "../../services/navigationService"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { partnerSelectors } from "../../store/reducers/partnerReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { setOrganizationDeletedThunk } from "../../store/thunks/organizationThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { TOrganizationType } from "../../type/TOrganizationType"
import "./MerchantWillBeDeletedBanner.scss"

interface IMerchantWillBeDeletedBanner {}

const MerchantWillBeDeletedBanner = ({}: IMerchantWillBeDeletedBanner) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    const isMasterPartner = useSelector(partnerSelectors.partnersContainMasterPartner)

    if (currentUserRelation?.relationType !== "Merchant") {
        return null
    }

    const renderRevival = () => {
        if (!isMasterPartner || !getMerchantAllServicesStopped(merchantDetails)) {
            return null
        }
        return (
            <div>
                <TextButton
                    text="Reactivate merchant"
                    className="merchant-will-be-deleted-banner__revival-action"
                    onClick={async () => {
                        await dispatch(
                            setOrganizationDeletedThunk(
                                currentUserRelation.id,
                                TOrganizationType.SwMerchant,
                                false,
                                undefined,
                                true
                            )
                        )
                        location.reload()
                    }}
                />
            </div>
        )
    }

    if (currentUserRelation?.markedDeletion) {
        return (
            <Banner>
                {t("merchantPendingDeletionBanner.deleted")}
                {renderRevival()}
            </Banner>
        )
    }

    if (!merchantDetails || !merchantDetails.deletionStatus) {
        return null
    }

    if (merchantDetails.deletionStatus.deletionReason === "TrialExpired") {
        return (
            <Banner>
                {t("merchantPendingDeletionBanner.trialExpired.text", {
                    willBeDeletedDate: getDateToLocalStringWithOptions(merchantDetails.deletionStatus.allServicesStoppedDate, {
                        dateStyle: "long",
                    }),
                })}
                {renderRevival()}
            </Banner>
        )
    }

    const lastCoreServicesActiveDay = new Date(merchantDetails.deletionStatus.coreServicesStoppedDate).addDays(-1)
    return (
        <Banner>
            {t("merchantPendingDeletionBanner.generalReason.text", {
                coreServicesLastActiveMonth: getDateToLocalStringWithOptions(lastCoreServicesActiveDay, {
                    month: "long",
                }),
                coreServicesLastActiveYear: getDateToLocalStringWithOptions(lastCoreServicesActiveDay, {
                    year: "numeric",
                }),
                closingDate: getDateToLocalStringWithOptions(merchantDetails.deletionStatus.allServicesStoppedDate, {
                    dateStyle: "long",
                }),
            })}{" "}
            <span onClick={() => navigationService.navigate("/merchantsettings")}>
                {t("merchantPendingDeletionBanner.generalReason.reactivate")}
            </span>
            {renderRevival()}
        </Banner>
    )
}

const Banner = (props: { children: React.ReactNode }) => {
    return <div className="banner-top-red">{props.children}</div>
}

export default MerchantWillBeDeletedBanner
