import cn from "classnames"
import React, { useMemo } from "react"
import { SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    FormGroupWithCheckbox,
    IMerchantOnboardingServicesSubForm,
    IMerchantPreOnboardingInternalUser,
    IMerchantPreOnboardingOwner,
    ShowErrorMessages,
    SpinnerContainer,
    StandardButton,
    getFullNameFromFirstAndLastName,
    getKeys,
    useSelector,
    userSelectors,
    valFuncRequired,
} from "swiipe.portal.shared"
import { isAllTermsAccepted, isTermAccepted } from "../../services/legalService"
import { getRequiredTermsForMerchantPreOnboardingServices } from "../../services/merchantOfferService"
import { ETermsType } from "../../store/reducers/legalReducer"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { partnerSelectors } from "../../store/reducers/partnerReducer"
import { getMerchantPreOnboardingDetailsThunk } from "../../store/thunks/merchantPreOnboardingThunks"
import { chooseSwiipePspPlanThunk } from "../../store/thunks/swiipePaymentsThunks"
import { markTermsAsAcceptedThunk } from "../../store/thunks/termsThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { ITermsAcceptanceStatusDto } from "../../type/ILegalData"
import { TSwiipePlan } from "../../type/IProvider"
import SubmitButton from "../buttons/SubmitButton"
import LegalTextLinkPopup from "../legal/LegalTextLinkPopup"
import "./MerchantOfferTerms.scss"

interface IMerchantOfferTermsProps {
    owner: IMerchantPreOnboardingOwner
    creator?: IMerchantPreOnboardingInternalUser
    swMerchantId: string
    className?: string
    requiresKyc: boolean
    services?: IMerchantOnboardingServicesSubForm
    termsStatuses?: ITermsAcceptanceStatusDto[] | undefined
    onContinue: () => void
}

type IForm = {
    [termsType in ETermsType]?: boolean
}

const MerchantOfferTerms = ({
    services,
    className,
    requiresKyc,
    swMerchantId,
    termsStatuses,
    onContinue,
    owner,
    creator,
}: IMerchantOfferTermsProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const userData = useSelector(userSelectors.userData)
    const hasMasterPartner = !!useSelector(partnerSelectors.partnersContainMasterPartner)
    const terms = useMemo(() => getRequiredTermsForMerchantPreOnboardingServices(services), [services])
    const { control, formState, register, handleSubmit, setValue, trigger, getValues, reset } = useForm<IForm>({
        shouldFocusError: false,
    })
    const unacceptedTerms = terms.filter((t) => !isTermAccepted(t, termsStatuses ?? []))
    const swiipePlan = useSelector(merchantSelectors.swiipePlan)
    const showSuccess = isAllTermsAccepted(terms, termsStatuses ?? [])

    const canSubmit = userData?.user.email === owner.email || !hasMasterPartner

    const onSubmit: SubmitHandler<IForm> = async (data) => {
        try {
            const termsToAccept = getKeys(data).filter((type) => !!data[type])
            await dispatch(markTermsAsAcceptedThunk(termsToAccept))
            await dispatch(chooseSwiipePspPlanThunk(swMerchantId, swiipePlan, TSwiipePlan.Basic))
            await dispatch(getMerchantPreOnboardingDetailsThunk("force"))
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    useWatch({
        control,
    })

    const renderTerms = () => {
        return terms.map((termsType) => {
            const isAlreadyAccepted = termsStatuses?.find(
                (ts) => termsType === ts.termsType && ts.isAnyAccepted && !ts.isConsentRequired
            )
            if (isAlreadyAccepted) {
                return (
                    <div className="merchant-offer-terms__terms-no-checkbox-text" key={termsType}>
                        <span>{t("termsAndConditionsCheckbox.alreadyAcceptedWithoutServiceText")}</span>
                        <LegalTextLinkPopup
                            legalTextBeforeLink=""
                            linkText={t("terms.name." + termsType)}
                            termsType={termsType}
                            linkClassName="merchant-offer-terms__terms-link-inner"
                            className={cn("merchant-offer-terms__terms-link")}
                        />
                    </div>
                )
            }
            return (
                <div key={termsType}>
                    <FormGroupWithCheckbox
                        innerRef={register(valFuncRequired(t("terms.name." + termsType)))}
                        className={cn("mb-3")}
                        name={termsType}
                    >
                        <LegalTextLinkPopup
                            legalTextBeforeLink={t("termsAndConditionsCheckbox.fullLinkTextWithoutServiceText")}
                            linkText={t("terms.name." + termsType)}
                            termsType={termsType}
                            linkClassName="merchant-offer-terms__terms-link-inner"
                            className={cn("merchant-offer-terms__terms-link")}
                        />
                    </FormGroupWithCheckbox>
                </div>
            )
        })
    }

    return (
        <div className={cn("merchant-offer-terms", className)}>
            <>
                {showSuccess && (
                    <div className="merchant-offer-terms__success">
                        <div className="merchant-offer-terms__success-title">{t("merchantOffer.confirmation.thankYou")}</div>
                        <div className="merchant-offer-terms__success-title pb-3">{t("merchantOffer.confirmation.signed")}</div>
                        {renderTerms()}
                        <div className="mb-5 mt-5">
                            {requiresKyc ? t("merchantOffer.confirmation.kycText") : t("merchantOffer.confirmation.servicesText")}
                        </div>
                        <div className="merchant-offer-terms__button-container">
                            <div>
                                <StandardButton
                                    isOrange
                                    className={cn("merchant-offer-terms__button")}
                                    onClick={() => onContinue()}
                                >
                                    {requiresKyc
                                        ? t("merchantOffer.confirmation.kycContinue")
                                        : t("merchantOffer.confirmation.servicesContinue")}
                                </StandardButton>
                            </div>
                        </div>
                    </div>
                )}
                {!showSuccess && (
                    <>
                        <div className="merchant-offer-terms__title">
                            {t("common.dear", { name: getFullNameFromFirstAndLastName(owner?.firstName, owner?.lastName) })}
                        </div>
                        <div className="merchant-offer-terms__desc">{t("merchantOffer.terms.desc")}</div>
                        <SpinnerContainer showSpinner={!termsStatuses || !services} maxHeight={100}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className="merchant-offer-terms__checkboxes-container">
                                    <FormGroupWithCheckbox
                                        innerRef={register()}
                                        name="all"
                                        className={cn("mb-3")}
                                        checked={
                                            terms
                                                .filter((t) => !isTermAccepted(t, termsStatuses ?? []))
                                                .filter((t) => !control.getValues()[t]).length === 0
                                        }
                                        onChange={(e) => {
                                            const allChecked =
                                                terms
                                                    .filter((t) => !isTermAccepted(t, termsStatuses ?? []))
                                                    .filter((t) => !control.getValues()[t]).length === 0
                                            terms
                                                .filter((t) => !isTermAccepted(t, termsStatuses ?? []))
                                                .forEach((t) => setValue(t, !allChecked))
                                            trigger()
                                        }}
                                    >
                                        {t("merchantOffer.terms.selectAll")}
                                    </FormGroupWithCheckbox>
                                    <hr />
                                    {renderTerms()}
                                    <ShowErrorMessages
                                        formState={formState}
                                        dynamicError={(errorMessages) => {
                                            if (errorMessages.length === 0) {
                                                return ""
                                            }
                                            const termsCount = unacceptedTerms.length
                                            if (errorMessages.length === termsCount) {
                                                return t("merchantOffer.terms.errorAll")
                                            }
                                            if (errorMessages.length === 1) {
                                                return t("merchantOffer.terms.errorOne", {
                                                    service: errorMessages[0],
                                                    creatorEmail: creator?.email ?? "",
                                                })
                                            }
                                            return t("merchantOffer.terms.errorMany", {
                                                serviceFirst: errorMessages.slice(0, errorMessages.length - 1).join(", "),
                                                serviceLast: errorMessages[errorMessages.length - 1],
                                                creatorEmail: creator?.email ?? "",
                                            })
                                        }}
                                    />
                                </div>

                                <div className="merchant-offer-terms__button-container">
                                    {canSubmit && (
                                        <SubmitButton
                                            formState={formState}
                                            isOrange
                                            className={cn("merchant-offer-terms__button mt-4 mb-4")}
                                        >
                                            {t("merchantOffer.terms.button")}
                                        </SubmitButton>
                                    )}
                                    {!canSubmit && (
                                        <div className="merchant-offer-terms__button-warning">
                                            You cannot submit if you are a Swiipe employee and not the owner
                                        </div>
                                    )}
                                </div>
                            </Form>
                        </SpinnerContainer>
                    </>
                )}
            </>
        </div>
    )
}

export default MerchantOfferTerms
