import React from "react"
import { useTranslation } from "react-i18next"
import { roundNumberToMaxDecimals } from "swiipe.portal.shared"
import { IMerchantDashboardServerData } from "../../../../type/merchantDashboard/IMerchantDashboardServerData"
import MerchantDashboardCircleWithList from "../../tiles/MerchantDashboardTileCircleWithList"

interface IDashboardCustomersDisplayProps {
    dashboardData: IMerchantDashboardServerData
    paymentsEnabled: boolean
}

const DashboardCustomersDisplay = ({ dashboardData, paymentsEnabled }: IDashboardCustomersDisplayProps) => {
    const { t } = useTranslation()

    const users = {
        main: dashboardData.mainData.users,
        compare: dashboardData.compareData?.users,
    }

    return (
        <MerchantDashboardCircleWithList
            title={t("merchantDashboard.customersTitle")}
            subtitle={t("merchantDashboard.viaSwiipe")}
            values={[
                {
                    title: t("merchantDashboard.customers1"),
                    value: roundNumberToMaxDecimals(
                        users.main.visitTotal === 0 ? 0 : (users.main.visit1 / users.main.visitTotal) * 100,
                        2
                    ),
                },
                {
                    title: t("merchantDashboard.customers2"),
                    value: roundNumberToMaxDecimals(
                        users.main.visitTotal === 0 ? 0 : (users.main.visit2 / users.main.visitTotal) * 100,
                        2
                    ),
                },
                {
                    title: t("merchantDashboard.customers3Plus"),
                    value: roundNumberToMaxDecimals(
                        users.main.visitTotal === 0 ? 0 : (users.main.visit3Plus / users.main.visitTotal) * 100,
                        2
                    ),
                },
            ]}
            compareValues={
                users.compare && [
                    {
                        title: t("merchantDashboard.customers1"),
                        value: roundNumberToMaxDecimals(
                            users.compare.visitTotal === 0 ? 0 : (users.compare.visit1 / users.compare.visitTotal) * 100,
                            2
                        ),
                    },
                    {
                        title: t("merchantDashboard.customers2"),
                        value: roundNumberToMaxDecimals(
                            users.compare.visitTotal === 0 ? 0 : (users.compare.visit2 / users.compare.visitTotal) * 100,
                            2
                        ),
                    },
                    {
                        title: t("merchantDashboard.customers3Plus"),
                        value: roundNumberToMaxDecimals(
                            users.compare.visitTotal === 0 ? 0 : (users.compare.visit3Plus / users.compare.visitTotal) * 100,
                            2
                        ),
                    },
                ]
            }
            notEnabled={!paymentsEnabled}
        />
    )
}

export default DashboardCustomersDisplay
