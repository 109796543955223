import cn from "classnames"
import React from "react"
import { UseFormMethods } from "react-hook-form"
import { Input } from "reactstrap"
import { getCountryByISO, valFuncRequired } from "swiipe.portal.shared"
import { resolvePaymentTypeName } from "../../../services/paymentService"
import { IResursStore } from "../../../type/resurs/IResursStore"
import "./ResursSelectStoreRadio.scss"
import { IResursSelectStoreFormModel } from "./ResursSelectStoreStep"

interface IResursSelectStoreRadio {
    resursStore: IResursStore
    formModelName: string
    form: UseFormMethods<IResursSelectStoreFormModel>
    isInitChecked: boolean
    selectedValue: string
    onSelected: (value: string) => void
}
export const ResursSelectStoreRadio = ({
    resursStore,
    formModelName,
    form,
    isInitChecked,
    selectedValue,
    onSelected,
}: IResursSelectStoreRadio) => {
    const radioValue = resursStore.resursStoreId
    const isSelected = selectedValue === radioValue || isInitChecked

    return (
        <div
            className={cn("resurs-onboarding-store-radio", {
                "resurs-onboarding-store-radio--selected": isSelected,
            })}
        >
            <Input
                className="resurs-onboarding-store-radio__radio-button"
                type="radio"
                id={resursStore.resursStoreId}
                value={radioValue}
                innerRef={form.register(valFuncRequired("error"))}
                name={formModelName}
                defaultChecked={isInitChecked}
                onChange={(e) => {
                    onSelected(e.target.value)
                }}
            />
            <div className="resurs-onboarding-store-radio__information">
                <div className="resurs-onboarding-store-radio__store-name">{resursStore.resursStoreName}</div>
                <div className="resurs-onboarding-store-radio__country">{getCountryByISO(resursStore.countryCode)}</div>
            </div>
            <div className="resurs-onboarding-store-radio__payment-types">
                {resursStore.paymentTypes.map((paymentType) => (
                    <div key={resursStore.resursStoreId + paymentType}>{resolvePaymentTypeName(paymentType)}</div>
                ))}
            </div>
        </div>
    )
}

export default ResursSelectStoreRadio
