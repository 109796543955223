import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    FieldsetFormGroup,
    FloatingLabelInput,
    ShowErrorMessages,
    useSelector,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { salesSelectors } from "../../../store/reducers/salesReducer"
import { updateSalessendersNameThunk } from "../../../store/thunks/salesSettingsThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IUpdateSalesSendersNameFormModel } from "../../../type/sales/IUpdateSalesSendersNameFormModel"
import SubmitButton from "../../buttons/SubmitButton"
import ContentRightButtonContainer from "../../layouts/ContentRightButtonContainer"

interface ISalesSendersNameFormProps {}

const SalesSendersNameForm = ({}: ISalesSendersNameFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const { handleSubmit, formState, register } = useForm<IUpdateSalesSendersNameFormModel>()

    const settings = useSelector(salesSelectors.settings)

    const onSubmit: SubmitHandler<IUpdateSalesSendersNameFormModel> = async (data, e) => {
        try {
            await dispatch(updateSalessendersNameThunk(data))
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <ContentRightButtonContainer button={<SubmitButton isSmall formState={formState} title={t("common.save")} />}>
                <FieldsetFormGroup field>
                    <FloatingLabelInput
                        defaultValue={settings ? settings.sendersName : ""}
                        name="sendersName"
                        innerRef={register({
                            maxLength: { value: 11, message: t("offersettings.validationerrors.sendersName") },
                            ...valFuncRequiredAndPattern(
                                validationPatterns.salessendersName,
                                t("offersettings.validationerrors.sendersName")
                            ),
                        })}
                        placeholder={t("offersettings.sendersName.inputPlaceholder")}
                    />
                </FieldsetFormGroup>
            </ContentRightButtonContainer>
            <ShowErrorMessages formState={formState} />
        </Form>
    )
}

export default SalesSendersNameForm
