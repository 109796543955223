import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Row } from "reactstrap"
import { getCountryByISO, useSelector, valFuncRequiredAndPattern, validationPatterns } from "swiipe.portal.shared"
import AddressFormFields from "../../../components/form/user/AddressFormFields"
import { billingSelectors } from "../../../store/reducers/billingReducer"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { userRelationSelectors } from "../../../store/reducers/userRelationReducer"
import { fetchInvoicingOrganizationThunk, updateInvoicingOrganizationInfoThunk } from "../../../store/thunks/billingThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IInvoicingOrganizationInfoForm } from "../../../type/IInvoicingOrganizationInfoForm"
import { IEditFormBase } from "../../../type/form/IEditFormBase"
import InfoBoxContainer from "../../infobox/InfoBoxContainer"
import InfoBoxEditor from "../../infobox/InfoBoxEditor"
import { InfoBoxState } from "../../infobox/InfoBoxState"
import EditFormInput from "../input/EditFormInput"
import EmailInputComp from "../input/EmailInputComp/EmailInputComp"

interface IMerchantInvoicingInfoFormProps extends IEditFormBase {}

const MerchantInvoicingInfoForm = ({ isInEditMode, onSwitchEditMode }: IMerchantInvoicingInfoFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const invoicingOrganization = useSelector(billingSelectors.invoicingOrganization)
    const userRelation = useSelector(userRelationSelectors.currentUserRelation)

    useEffect(() => {
        dispatch(fetchInvoicingOrganizationThunk(false))
    }, [userRelation])

    if (!invoicingOrganization || !merchantDetails || !merchantDetails.swMerchant) {
        return null
    }

    return (
        <>
            <InfoBoxContainer isInEditMode={isInEditMode} className="mb-0" title={t("personal.billingInfo")} transparentBg noIcon>
                <InfoBoxEditor<IInvoicingOrganizationInfoForm>
                    state={InfoBoxState.DEFAULT}
                    iconClass="icon-invoice"
                    title={t("personal.editBillingInfo")}
                    isInEditMode={isInEditMode}
                    editBtnTextType="editing"
                    renderPreview={() => (
                        <>
                            <div>{invoicingOrganization.attentionName ?? ""}</div>
                            <div>{invoicingOrganization.billingEmail ?? ""}</div>
                            <br />
                            {invoicingOrganization.attentionName !== invoicingOrganization.companyName && (
                                <div>{invoicingOrganization.companyName}</div>
                            )}
                            <div>{invoicingOrganization.address}</div>
                            {invoicingOrganization.postCode && invoicingOrganization.city && (
                                <div>
                                    {invoicingOrganization.postCode}, {invoicingOrganization.city}
                                </div>
                            )}
                            <div>{getCountryByISO(invoicingOrganization.countryCode ?? "")}</div>
                            <div>{invoicingOrganization.region}</div>
                        </>
                    )}
                    renderFormFields={(register, setValue, getValues) => (
                        <div className="edit-form">
                            <Row>
                                <div className="col-12">
                                    <EditFormInput
                                        defaultValue={invoicingOrganization.attentionName}
                                        name="attentionName"
                                        innerRef={register(
                                            valFuncRequiredAndPattern(
                                                validationPatterns.companyName,
                                                t("personal.validationerrors.billingName")
                                            )
                                        )}
                                        hintInsideInput={t("contactform.billingNameHint")}
                                        showHintOnHover
                                        placeholder={t("contactform.billingName")}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <EmailInputComp
                                        inputName="billingEmail"
                                        register={register}
                                        defaultValue={invoicingOrganization.billingEmail}
                                    />
                                </div>
                            </Row>
                            <AddressFormFields<IInvoicingOrganizationInfoForm>
                                getValues={getValues}
                                setValue={setValue}
                                register={register}
                                address={{
                                    companyName: invoicingOrganization.companyName,
                                    address: invoicingOrganization.address,
                                    postCode: invoicingOrganization.postCode,
                                    city: invoicingOrganization.city,
                                    country: invoicingOrganization.countryCode,
                                    region: invoicingOrganization.region,
                                }}
                                omitFirstAndLastName
                                disableCountry
                            />
                        </div>
                    )}
                    onSave={async (model) => {
                        await dispatch(updateInvoicingOrganizationInfoThunk(model))
                    }}
                    onSwitchEditMode={onSwitchEditMode}
                />
            </InfoBoxContainer>
            <hr className="divider" />
        </>
    )
}

export default MerchantInvoicingInfoForm
