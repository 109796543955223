import { IPaymentMethod } from "swiipe.portal.shared"
import { allPaymentMethodRelationTypes, TPaymentMethodRelation } from "../type/paymentMethod/IPaymentMethodRelation"
import { IUserRelationFlattened } from "./../type/IUserRelation"
export function resolvePaymentMethodRelationType(str: string | undefined | null): TPaymentMethodRelation | undefined {
    const lowerCaseValue = (str ?? "").toLowerCase()
    for (const key in allPaymentMethodRelationTypes) {
        if (allPaymentMethodRelationTypes[key].toLowerCase() === lowerCaseValue) {
            return allPaymentMethodRelationTypes[key] as TPaymentMethodRelation
        }
    }
    return undefined
}

export function getRootPageForAddPaymentMethodAction(
    userRelation: IUserRelationFlattened | undefined,
    paymentMethodRelation: TPaymentMethodRelation | undefined
) {
    if (!userRelation || (!paymentMethodRelation && userRelation.relationType === "User")) {
        return "/personalinfo"
    }
    if (paymentMethodRelation === "Billing" && userRelation.relationType !== "User") {
        return "/invoices"
    }
    return "/"
}

export function getPaymentMethodInfoString(paymentMethod: IPaymentMethod) {
    switch (paymentMethod.paymentType) {
        case "AccountToAccount":
            return paymentMethod.account?.name ?? ""
        case "CreditCard":
            return paymentMethod.creditCard?.maskedAccount ?? ""
        default:
            return ""
    }
}
