import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface IConfigurationStoreAddition {
    configuration: IConfigurationSliceState
}

interface IConfigurationSliceState {
    swiipeInvoicingPaymentIQMerchantID?: string
}

const initialState: IConfigurationSliceState = {}

export const configurationSlice = createSlice({
    name: "configuration",
    initialState,
    reducers: {
        setSwiipeInvoicingPaymentIQMerchantID: (state, action: PayloadAction<string>) => {
            state.swiipeInvoicingPaymentIQMerchantID = action.payload
        },
    },
})

export const configurationActions = configurationSlice.actions
export const configurationSelectors = {
    swiipeInvoicingPaymentIQMerchantID: (state: IConfigurationStoreAddition) =>
        state.configuration.swiipeInvoicingPaymentIQMerchantID,
}
