import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Row } from "reactstrap"
import { PageContainer, PageWrapper, useSelector, userSelectors } from "swiipe.portal.shared"
import MerchantWebshopsForm from "../../components/form/merchant/MerchantWebshopsForm"
import { navigationService } from "../../services/navigationService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import MerchantMainContactInfoForm from "./../../components/form/merchant/MerchantMainContactInfoForm"
import MerchantContactInfoForm from "./../../components/form/user/MerchantContactInfoForm"
import "./MerchantAccountPage.scss"

interface IMerchantAccountPageProps {}

const MerchantAccountPage = ({}: IMerchantAccountPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const { t } = useTranslation()
    const [formInEditMode, setFormInEditMode] = useState("")
    const userData = useSelector(userSelectors.userData)
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)

    if (!userData || !merchantDetails) {
        return null
    }

    return (
        <PageWrapper className="merchant-accounts-page">
            <PageContainer transparent title={t("menu.accountinfo")}>
                <MerchantMainContactInfoForm
                    onSwitchEditMode={(isInEditMode) => setFormInEditMode(isInEditMode ? "merchantInfo" : "")}
                    isInEditMode={formInEditMode === "merchantInfo"}
                />
                <MerchantContactInfoForm
                    onSwitchEditMode={(id, isInEditMode) => setFormInEditMode(isInEditMode ? "contact_" + id : "")}
                    contactIdInEditMode={
                        formInEditMode.startsWith("contact_") ? formInEditMode.replace("contact_", "") : undefined
                    }
                />
                <MerchantWebshopsForm
                    onSwitchEditMode={(id, isInEditMode) => setFormInEditMode(isInEditMode ? "webshop_" + id : "")}
                    webshopIdInEditMode={
                        formInEditMode.startsWith("webshop_") ? formInEditMode.replace("webshop_", "") : undefined
                    }
                />
            </PageContainer>

            <PageContainer noHeader classNameBody="close-account">
                <Row>
                    <div className="col-3"></div>
                    <div className="col-9">
                        <span onClick={() => navigationService.navigate("/merchantsettings")}>
                            {merchantDetails.deletionStatus
                                ? t("closeMerchantAccount.reactivateAccount")
                                : t("closeMerchantAccount.closeAccount")}
                        </span>
                    </div>
                </Row>
            </PageContainer>
        </PageWrapper>
    )
}

export default MerchantAccountPage
