import React from "react"
import "./FilterContainer.scss"

interface IFilterContainer {
    title: string
    body: JSX.Element
    value?: string
    html2CanvasIgnore?: boolean
}
const FilterContainer = ({ title, body, value, html2CanvasIgnore }: IFilterContainer) => {
    return (
        <div className="merchant-dashboard-filter-container" data-html2canvas-ignore={html2CanvasIgnore}>
            <div className="merchant-dashboard-filter-container__title">{title}:</div>
            <div className="merchant-dashboard-filter-container__filter">{body}</div>
            {value && <div className="merchant-dashboard-filter-container__value">{value}</div>}
        </div>
    )
}

export default FilterContainer
