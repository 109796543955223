import React, { useEffect, useState } from "react"
import { Input, Label } from "reactstrap"
import "./FilterCheckbox.scss"

interface IFilterCheckbox {
    label: string
    showFilters: boolean
    onChange: (checked: boolean) => void
    isChecked?: boolean
}

export const FilterCheckbox = ({ label, showFilters, isChecked, onChange }: IFilterCheckbox) => {
    const [checked, setChecked] = useState(false)
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (!initialized) {
            setInitialized(true)
            return
        }

        onChange(checked)
    }, [checked])

    useEffect(() => {
        if (typeof isChecked === "undefined") {
            return
        }
        setChecked(isChecked)
    }, [isChecked])

    useEffect(() => {
        setChecked(typeof isChecked !== "undefined" ? isChecked : false)
    }, [showFilters])

    return (
        <div className="filter-checkbox-container">
            <Label>
                <Input
                    type="checkbox"
                    onChange={() => {
                        setChecked(!checked)
                    }}
                    checked={checked}
                />
                {label}
            </Label>
        </div>
    )
}
