import React from "react"
import { SharedStoreState, useSelector } from "swiipe.portal.shared"
import { modalSelectors } from "../../../store/modalReducer"
import { IGenericModalPropsBase, SharedSingleModal } from "./SharedSingleModal"
import { IModalCallback, IModalControlProps } from "./modalTypes"

interface IModalRendererProps<TModals extends IGenericModalPropsBase> {
    renderModal: (modalWithoutCallback: TModals & IModalCallback, controlProps: IModalControlProps) => React.ReactNode
}

export const ModalRenderer = <TModals extends IGenericModalPropsBase>({ renderModal }: IModalRendererProps<TModals>) => {
    const modals = useSelector((state: SharedStoreState) => modalSelectors.modals<TModals>(state))

    return (
        <div>
            {modals.map((modal, index) => {
                return <SharedSingleModal<TModals> key={modal.type + index} modal={modal} renderModal={renderModal} />
            })}
        </div>
    )
}
