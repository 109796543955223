import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentRoute, useNavigation } from "react-navi"
import { PageWrapper, SpinnerContainer, sharedConfigurationSelectors, useSelector } from "swiipe.portal.shared"
import FormFooter from "../../components/form/footer/FormFooter"
import MessageBox from "../../components/layouts/MessageBox"
import { isUserConfirmedThunk } from "../../store/thunks/userThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { ELoginFlow } from "../../type/ELoginFlow"
import { TUserRelation } from "../../type/TUserRelation"
import ConfirmAccountForm from "./../../components/form/password/ConfirmAccountForm"
import FormHeader from "./../../components/form/title/FormHeader"

interface IConfirmAccountPageProps {}

const ConfirmAccountPage = ({}: IConfirmAccountPageProps) => {
    const currentRoute = useCurrentRoute()
    const dispatch = useReduxDispatch()
    const navigation = useNavigation()
    const [userConfirmed, setUserConfirmed] = useState<boolean | undefined>()
    const token = decodeURIComponent(currentRoute.url.query.token || "").replace(/ /g, "+")
    const email = currentRoute.url.query.email
    const termsTypes = currentRoute.url.query.termsTypes
    const relatesToId = currentRoute.url.query.relatesToId
    const relationId = currentRoute.url.query.relationId
    const flowType = currentRoute.url.query.flowType as ELoginFlow
    const relationType = (currentRoute.url.query.relationType as TUserRelation) ?? "User"
    const relatesToSecondaryId = currentRoute.url.query.relatesToSecondaryId
    const returnUrl =
        flowType === "merchantOffer" ? `/merchantOffer?relationId=${relationId}&email=${email}&flowType=${flowType}` : undefined

    const [successEmail, setSuccessEmail] = useState("")
    const { t } = useTranslation()

    const env = useSelector(sharedConfigurationSelectors.environment)

    useEffect(() => {
        if (!token || !email) {
            // TODO: Show error
        }

        ;(async () => {
            const isConfirmed = await dispatch(isUserConfirmedThunk(email))
            if (!isConfirmed) {
                setUserConfirmed(false)
            } else {
                navigation.navigate(returnUrl || "/")
            }
        })()
    }, [])

    if (!token || !email) {
        return null
    }

    return (
        <PageWrapper>
            <SpinnerContainer showSpinner={typeof userConfirmed === "undefined"}>
                {successEmail ? (
                    <MessageBox title={t("confirmaccount.welcometitle")}>
                        <p>{t("confirmaccount.welcomemsg", { email: successEmail })}</p>
                        <p>{t("confirmaccount.welcomemsg2")}</p>
                    </MessageBox>
                ) : (
                    <div id="confirmaccount" className="center-form">
                        {!flowType && <FormHeader showSandboxTitle={env === "Sandbox"} />}
                        <div className="panel-body">
                            <ConfirmAccountForm
                                email={email}
                                token={token}
                                termsTypes={termsTypes}
                                relationType={relationType}
                                relatesToId={relatesToId}
                                relatesToSecondaryId={relatesToSecondaryId}
                                onMailSend={(sentToEmail) => setSuccessEmail(sentToEmail)}
                                flowType={flowType}
                                returnUrl={returnUrl}
                            />
                        </div>
                        {!flowType && <FormFooter />}
                    </div>
                )}
            </SpinnerContainer>
        </PageWrapper>
    )
}

export default ConfirmAccountPage
