import cn from "classnames"
import React, { useState } from "react"
import { Alert } from "reactstrap"
import { FieldsetFormGroup } from "swiipe.portal.shared"

interface IFormSectionTitleWithHintProps {
    title: string
    hintText: string
    alignLeft?: boolean
    clickToExpand?: boolean
    className?: string
}

const FormSectionTitleWithHint = ({ title, hintText, alignLeft, clickToExpand, className }: IFormSectionTitleWithHintProps) => {
    const [showAlert, setShowAlert] = useState(clickToExpand ? false : true)

    return (
        <FieldsetFormGroup className={cn(className ? className : "")}>
            <p className={cn("swiipe-form-title", { "text-center": !alignLeft })}>
                <span className={clickToExpand ? "pointer" : ""} onClick={() => setShowAlert(clickToExpand ? !showAlert : true)}>
                    {title}{" "}
                    <span
                        className={cn("icon page-icon icon-info", showAlert ? "witharrow" : "", clickToExpand ? "pointer" : "")}
                    ></span>
                </span>
            </p>
            <Alert isOpen={showAlert} className="alert-f2" color="dark">
                {hintText}
            </Alert>
        </FieldsetFormGroup>
    )
}

export default FormSectionTitleWithHint
