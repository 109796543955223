import { IPartnerMerchantRelation } from "../type/IPartnerMerchantRelation"
import { toUpperAndTrim } from "../util/stringUtil"

export const setEmailResent = (partnerMerchantRelations: IPartnerMerchantRelation[], merchantId: string) => {
    partnerMerchantRelations.map((r) => {
        if (r.merchantId === merchantId) {
            r.confirmEmailResent = true
        }

        return r
    })
}

export const filterPartnerMerchantRelationsASC = (
    partnerMerchantRelations: IPartnerMerchantRelation[]
): IPartnerMerchantRelation[] => {
    return partnerMerchantRelations
        .sort((a, b) =>
            toUpperAndTrim(a.merchantName) < toUpperAndTrim(b.merchantName)
                ? -1
                : toUpperAndTrim(a.merchantName) > toUpperAndTrim(b.merchantName)
                ? 1
                : 0
        )
        .sort((a, b) =>
            a.termsAcceptedByMerchantOwner > b.termsAcceptedByMerchantOwner
                ? -1
                : a.termsAcceptedByMerchantOwner < b.termsAcceptedByMerchantOwner
                ? 1
                : 0
        )
}
