import $ from "jquery"

export function scrollToTop() {
    $([document.documentElement, document.body]).animate(
        {
            scrollTop: 0,
        },
        500
    )
}
