import cn from "classnames"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
    PageContainer,
    PageWrapper,
    StandardButton,
    StandardButtonWithSpinner,
    getQueryValueFromCurrentUrl,
    getTextWithInvoicingPrices,
    useSelector,
} from "swiipe.portal.shared"
import LinkButton from "../../components/buttons/LinkButton"
import PaymentIQHostedFields, { IHostedFieldsCallbackRefObject } from "../../components/form/fieldsets/PaymentIQHostedFields"
import { navigationService } from "../../services/navigationService"
import { getRootPageForAddPaymentMethodAction, resolvePaymentMethodRelationType } from "../../services/paymentMethodService"
import { billingSelectors } from "../../store/reducers/billingReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { completeAddPaymentMethodThunk, initiateAddPaymentMethodThunk } from "../../store/thunks/paymentMethodThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import "./AddPaymentMethodPage.scss"

interface IAddPaymentMethodPage {}

const AddPaymentMethodPage = ({}: IAddPaymentMethodPage) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()

    const currenUserRelation = useSelector(userRelationSelectors.currentUserRelation)

    const orderId = getQueryValueFromCurrentUrl("orderId") ?? ""
    const relationType = resolvePaymentMethodRelationType(getQueryValueFromCurrentUrl("relationType"))
    const status = (getQueryValueFromCurrentUrl("status") ?? "").toLowerCase()
    const errorForUser = getQueryValueFromCurrentUrl("errorForUser") ?? ""
    const prices = useSelector(billingSelectors.resolvedPrices)

    useEffect(() => {
        if (orderId && status === "success") {
            dispatch(completeAddPaymentMethodThunk(orderId, relationType))
        }
    }, [orderId])

    // getData property will be assigned by PaymentIQHostedFields component
    const hostedFieldsGetDataReference: IHostedFieldsCallbackRefObject = { getData: undefined }

    const getMessageInfo = (heading: string, message: string, btnText: string, iconClass?: string) => {
        return (
            <>
                <p className="block-heading text-center mb-3">{heading}</p>

                {iconClass && <span className={cn("icon", iconClass)}></span>}

                <p className="text-normal text-center mb-3 mt-4">{message}</p>
                {status === "failure" && !!errorForUser && <p className="text-normal text-center mb-3">{errorForUser}</p>}

                <StandardButton
                    className="btn-close mt-4"
                    dark
                    noBorder
                    onClick={() => {
                        if (status === "success") {
                            navigationService.navigate(getRootPageForAddPaymentMethodAction(currenUserRelation, relationType))
                            return
                        }
                        navigationService.navigateSamePageChangeParams(
                            { status: undefined, errorForUser: undefined },
                            "keepExistingParams"
                        )
                    }}
                >
                    {btnText}
                </StandardButton>
            </>
        )
    }

    return (
        <PageWrapper>
            <PageContainer id="add-credit-card-page">
                <LinkButton
                    className="back-button"
                    title={"< " + t("addPaymentMethod.goBack")}
                    href={getRootPageForAddPaymentMethodAction(currenUserRelation, relationType)}
                    textOnly
                />
                <div className="main-container">
                    {status === "success" &&
                        getMessageInfo(
                            t("addPaymentMethod.success.heading"),
                            t("addPaymentMethod.success.message"),
                            t("addPaymentMethod.close"),
                            "icon-ok"
                        )}

                    {status === "cancel" &&
                        getMessageInfo(
                            t("addPaymentMethod.cancelled.heading"),
                            t("addPaymentMethod.cancelled.message"),
                            t("common.ok")
                        )}

                    {status === "failure" &&
                        getMessageInfo(
                            t("addPaymentMethod.failed.heading"),
                            t("addPaymentMethod.failed.message"),
                            t("common.ok")
                        )}

                    {!["success", "failure", "cancel"].includes(status) && (
                        <>
                            <p className="block-heading text-center mb-3">
                                {t("addPaymentMethod.creditcard")}
                                <span className="text-card-logos">
                                    <span className="icon icon-visa"></span>
                                    <span className="icon icon-mastercard"></span>
                                </span>
                            </p>

                            <PaymentIQHostedFields
                                reloadFieldsOn={[currenUserRelation]}
                                getDataReference={hostedFieldsGetDataReference}
                            ></PaymentIQHostedFields>

                            <p className="text-normal text-center mb-5 mt-4">
                                {getTextWithInvoicingPrices("addPaymentMethod.completepurchase", prices, {
                                    zeroPrice: { number: 0, numberFormat: "forceTwoDecimals" },
                                })}
                            </p>

                            <StandardButtonWithSpinner
                                className="btn-confirm"
                                dark
                                noBorder
                                onClick={async () => {
                                    try {
                                        const hostedFields = await hostedFieldsGetDataReference.getData?.()
                                        if (!hostedFields || hostedFields.errors) {
                                            return
                                        }
                                        await dispatch(
                                            initiateAddPaymentMethodThunk(
                                                {
                                                    paymentType: "CreditCard",
                                                    creditCard: {
                                                        encCreditcardNumber: hostedFields.encCreditcardNumber,
                                                        encCvv: hostedFields.encCvv,
                                                        expiryMonth: hostedFields.expiryMonth,
                                                        expiryYear: hostedFields.expiryYear,
                                                    },
                                                },
                                                relationType
                                            )
                                        )
                                    } catch (err) {
                                        // Catch to stop showing spinner
                                    }
                                }}
                            >
                                {t("addPaymentMethod.confirm")}
                            </StandardButtonWithSpinner>
                        </>
                    )}
                </div>
            </PageContainer>
        </PageWrapper>
    )
}

export default AddPaymentMethodPage
