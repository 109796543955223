import { ActionsUnion, createAction } from "swiipe.portal.shared"
import { ISalesEmployee } from "../../type/sales/ISalesEmployee"
import { ISalesOffer } from "../../type/sales/ISalesOffer"
import { StoreState } from "../StoreState"
import { ISalesSettings } from "./../../type/sales/ISalesSettings"

export type SalesState = Readonly<{
    employees?: ISalesEmployee[]
    settings?: ISalesSettings
    historyOffers?: ISalesOffer[]
    offers: { [shortId: string]: ISalesOffer }
}>
const initialState: SalesState = { offers: {} }

export const salesReducerActions = {
    setEmployees: (employees: ISalesEmployee[]) => createAction("APP/SET_SALES_EMPLOYEES", { employees }),
    setSettings: (settings: ISalesSettings) => createAction("APP/SET_SALES_SETTINGS", { settings }),
    setHistoryOffers: (historyOffers: ISalesOffer[]) => createAction("APP/SET_SALES_HISTORY_OFFERS", { historyOffers }),
    setOffer: (shortId: string, offer: ISalesOffer) => createAction("APP/SET_SALES_OFFER", { shortId, offer }),
}

export type SalesActions = ActionsUnion<typeof salesReducerActions>

export const salesReducer = (state: SalesState = initialState, action: SalesActions): SalesState => {
    switch (action.type) {
        case "APP/SET_SALES_EMPLOYEES": {
            return {
                ...state,
                employees: action.employees,
            }
        }
        case "APP/SET_SALES_SETTINGS": {
            return {
                ...state,
                settings: action.settings,
            }
        }
        case "APP/SET_SALES_HISTORY_OFFERS": {
            return {
                ...state,
                historyOffers: action.historyOffers,
            }
        }
        case "APP/SET_SALES_OFFER": {
            return {
                ...state,
                offers: {
                    ...state.offers,
                    [action.shortId]: action.offer,
                },
            }
        }
    }

    return state
}

export const salesSelectors = {
    employees: (state: StoreState) => state.sales.employees,
    settings: (state: StoreState) => state.sales.settings,
    historyOffers: (state: StoreState) => state.sales.historyOffers,
    offer: (state: StoreState, shortId: string) => state.sales.offers[shortId],
}
