import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { getText, requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { StoreState } from "../StoreState"
import { onboardClearhausForPaymentTypeThunk } from "./clearhausThunks"
import { getExtendedOnboardingStatusesThunk, getOnboardingStatusThunk } from "./swiipePaymentsThunks"
import { sendServiceConfirmationEmailThunk } from "./swiipeServicesThunks"

export interface IMpoOnboardingData {
    swMerchantId: string
    swMerchantEmail: string
    merchantName: string
    merchantContactName: string
    merchantContactPhone: string
    webshopids: string[]
    logos: (File | null)[]
    existedAtClearhaus: boolean
    onboardedWithClearhaus: boolean
    editMode: boolean
}

export const mpoOnboardingThunk =
    (data: IMpoOnboardingData): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const formData = new FormData()

        formData.append("swMerchantId", data.swMerchantId)
        formData.append("swMerchantEmail", data.swMerchantEmail)
        formData.append("swMerchantName", data.merchantName)
        formData.append("merchantContactName", data.merchantContactName)
        formData.append("merchantContactPhone", data.merchantContactPhone)
        formData.append("existedAtClearhaus", String(data.existedAtClearhaus))
        formData.append("onboardedWithClearhaus", String(data.onboardedWithClearhaus))
        formData.append("editMode", String(data.editMode))

        for (let i = 0; i < data.webshopids.length; i++) {
            formData.append("webshopIds", data.webshopids[i])

            const logo = data.logos[i]
            if (logo) {
                formData.append("logosWebshopIds", data.webshopids[i])
                formData.append("logos", logo)
            }
        }

        await dispatch(
            requestThunk<void>(endpoints.PaymentIQ.createMpoOnboarding, {
                data: formData,
            })
        )

        if (data.editMode) {
            return
        }

        await dispatch(
            sendServiceConfirmationEmailThunk(data.swMerchantId, "MobilePayOnlineConfirmation", getText("serviceNames.mpo"))
        )

        await dispatch(onboardClearhausForPaymentTypeThunk(data.swMerchantId, data.webshopids, "MobilePayOnline"))
        for (let i = 0; i < data.webshopids.length; i++) {
            await dispatch(getOnboardingStatusThunk(data.swMerchantId, data.webshopids[i], true, ["MobilePayOnline"]))
        }
    }

export interface IMpoOnboardingDataResp {
    webshopLogoUrl: string
}

export const getMpoOnboardingDataThunk =
    (
        merchantId: string,
        webshopId: string,
        force?: "force"
    ): ThunkAction<Promise<IMpoOnboardingDataResp>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const resp = await dispatch(
            requestThunk<IMpoOnboardingDataResp>(endpoints.PaymentIQ.getMpoOnboardingData, {
                params: {
                    swMerchantId: merchantId,
                    webshopId: webshopId,
                    force: !!force,
                },
            })
        )

        return resp
    }

export const mpoOnboardForMpoApiThunk =
    (
        merchantId: string,
        webshopId: string,
        shouldBeOnboarded: boolean,
        differentExternalId: string,
        force?: "force"
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.PaymentIQ.mpoOnboardForMpoApi, {
                data: {
                    webshopId,
                    shouldBeOnboarded,
                    force: !!force,
                    differentExternalId: !differentExternalId ? undefined : differentExternalId,
                },
            })
        )
        await dispatch(getExtendedOnboardingStatusesThunk(merchantId, webshopId, true))
    }
