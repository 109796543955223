import React from "react"
import { Alert } from "reactstrap"
import { SpinnerContainer, StandardButton } from "swiipe.portal.shared"
import { InfoBoxDeleteDialogModel } from "../../../src/type/InfoBoxDeleteDialogModel"
import BoldTextFromatter from "../../components/textFormat/BoldTextFormatter"
import "./InfoBoxDeleteDialog.scss"

interface IInfoboxDeleteDialog {
    confirmDeleteDialog: InfoBoxDeleteDialogModel
    onCancelDelete: () => void
    onConfirmDelete: () => void
    isDeleting: boolean
    isSaving: boolean
}

const InfoboxDeleteDialog = ({
    confirmDeleteDialog,
    onCancelDelete,
    onConfirmDelete,
    isDeleting,
    isSaving,
}: IInfoboxDeleteDialog) => {
    return (
        <Alert isOpen className="alert-confirmdelete px-2 px-md-4" color="dark">
            <p className="confirmdelete-heading">{confirmDeleteDialog.dialogHeading}</p>
            <p className="confirmdelete-text">
                <BoldTextFromatter text={confirmDeleteDialog.dialogText} />
            </p>
            <div className="col-md-5 d-inline-block pl-0 pr-0">
                <StandardButton
                    isSmall
                    disabled={isDeleting}
                    dark
                    noBorder
                    className="btn btn-secondary mt-2 edit-form-submit"
                    onClick={async () => {
                        await onCancelDelete()
                    }}
                    title={confirmDeleteDialog.cancelBtnText}
                />
            </div>
            <span className="d-block d-md-inline-block col-md-2"></span>
            <div className="col-md-5 mt-md-2 d-inline-block pl-0 pr-0">
                <SpinnerContainer showSpinner={isDeleting}>
                    <StandardButton
                        isSmall
                        disabled={isSaving}
                        className="btn btn-secondary mt-2 edit-form-delete"
                        onClick={async (e) => {
                            e.preventDefault()
                            await onConfirmDelete()
                        }}
                        title={confirmDeleteDialog.confirmDeleteBtnText}
                    />
                </SpinnerContainer>
            </div>
        </Alert>
    )
}

export default InfoboxDeleteDialog
