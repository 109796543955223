import React from "react"
import { route } from "navi"
import MobilepayOnlineOnboardingPage from "../pages/mobilepay/MobilepayOnlineOnboardingPage"
import { navigationService } from "../services/navigationService"
import SwiipeServicesOnboardingPage from "../pages/swiipepayments/SwiipeServicesOnboardingPage"
const mobilepayRoutes = {
    "/mobilepayonline_onboarding": route({
        view: <SwiipeServicesOnboardingPage onboardingType={"mpo"} />,
    }),
    "/mobilepayonline_onboarding_single": route({
        view: (
            <MobilepayOnlineOnboardingPage
                webshopIds={[]}
                onGoBack={() => {
                    navigationService.navigate("/plugins")
                }}
                onOnboarded={() => {
                    navigationService.navigate("/plugins")
                }}
            />
        ),
    }),
}
export default mobilepayRoutes
