import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { TPayment, getFilesFromFileList, requestThunk, sharedConfigurationSelectors, useSelector } from "swiipe.portal.shared"
import { getFilesToBeUploadedCount } from "../../services/clearhausService"
import { IOnboardingApplication } from "../../type/IOnboardingApplication"
import { IClearhausApplicationData } from "../../type/clearhaus/IClearhausApplicationData"
import { IClearhausApplicationForm } from "../../type/clearhaus/IClearhausApplicationForm"
import { IClearhausApplicationOnboardingState } from "../../type/clearhaus/IClearhausApplicationOnboardingState"
import { IClearhausCommentsData } from "../../type/clearhaus/IClearhausCommentsData"
import { IClearhausUpdateInfoForm } from "../../type/clearhaus/IClearhausUpdateInfoForm"
import { StoreState } from "../StoreState"
import { clearhausReducerActions, clearhausSelectors } from "../reducers/clearhausReducer"
import { merchantServicesSelectors } from "../reducers/merchantServicesReducer"
import { onboardingApplicationReducerActions, onboardingApplicationSelectors } from "../reducers/onboardingApplicationReducer"
import { endpoints } from "./../../data/endpoints"
import { merchantOfferTryAddKycEventThunk } from "./merchantPreOnboardingThunks"
import { setPiqOnboardingStatusThunk } from "./swiipePaymentsThunks"

export const clearhausFirstStageOnboardingThunk =
    (
        swMerchantId: string,
        webshopIds: string[],
        isInstant: boolean,
        data: IClearhausApplicationForm,
        onboardingId?: string
    ): ThunkAction<Promise<string>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const response = await dispatch(
            requestThunk<{ onboardingId: string }>(endpoints.Clearhaus.onboard, {
                data: {
                    onboardingId,
                    onboardingStep: "FirstStep",
                    swMerchantId,
                    webshopIds,
                    isInstant,
                    ...{
                        ...data,
                        //ignore second page fields
                        directors: [],
                        owners: [],
                    },
                    filesToBeUploadedCount: getFilesToBeUploadedCount(data),
                },
            })
        )

        if (isInstant) {
            //submit and sign in case of instant onboarding
            await dispatch(submitApplicationAtClearhaus(swMerchantId, response.onboardingId, true))
        }

        await dispatch(getClearhausApplicationDataThunk(swMerchantId, webshopIds, true))
        await dispatch(onboardClearhausForPaymentTypeThunk(swMerchantId, webshopIds, "CreditCard"))

        for (let i = 0; i < webshopIds.length; i++) {
            dispatch(getClearhausApplicationStateThunk(swMerchantId, webshopIds[i], true))
        }

        return response.onboardingId
    }

export const clearhausSecondStageOnboardingThunk =
    (
        swMerchantId: string,
        data: IClearhausApplicationForm,
        webshopIds: string[],
        isInstant: boolean,
        onboardingId: string
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const onboardingResult = await dispatch(
            requestThunk<{
                ownerIds: string[]
                directorIds: string[]
            }>(endpoints.Clearhaus.onboard, {
                data: {
                    onboardingId,
                    onboardingStep: "SecondStep",
                    swMerchantId,
                    webshopIds,
                    isInstant,
                    ...data,
                },
            })
        )

        await dispatch(
            uploadApplicationFilesThunk(swMerchantId, onboardingId, data, onboardingResult.ownerIds, onboardingResult.directorIds)
        )

        if (!isInstant) {
            //submit in case of full onboarding
            await dispatch(submitApplicationAtClearhaus(swMerchantId, onboardingId, false))
        }

        const tasks = []
        for (let i = 0; i < webshopIds.length; i++) {
            tasks.push(dispatch(getClearhausApplicationStateThunk(swMerchantId, webshopIds[i], true)))
        }
        tasks.push(dispatch(getClearhausApplicationDataThunk(swMerchantId, webshopIds, true)))

        await Promise.all(tasks)
        dispatch(merchantOfferTryAddKycEventThunk(onboardingId))
    }

export const sendOnboardingConfirmationEmailAsAdminThunk =
    (isInstant: boolean, onboardingId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(endpoints.Clearhaus.sendConfirmationEmail, {
                data: {
                    isInstant,
                    onboardingId,
                },
            })
        )
    }

export const getClearhausApplicationDataThunk =
    (swMerchantId: string, webshopIds: string[], force?: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const env = sharedConfigurationSelectors.environment(getState())
        if (env === "Sandbox") {
            return
        }

        if (webshopIds.length === 0) {
            return
        }

        const data = clearhausSelectors.getOnboardingData(getState(), webshopIds[0])
        if (!force && data) {
            return
        }

        const onboardingData: IClearhausApplicationData | undefined = await dispatch(
            requestThunk<IClearhausApplicationData>(endpoints.Clearhaus.getOnboardingData, {
                params: {
                    swMerchantId,
                    webshopIds: webshopIds,
                },
            })
        )

        for (let i = 0; i < webshopIds.length; i++) {
            dispatch(clearhausReducerActions.setOnboardingData(webshopIds[i], onboardingData))
        }
    }

export const registerOnboardingStartedThunk =
    (swMerchantId: string, webshopIds: string[]): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.Clearhaus.registerOnboardingStarted, {
                data: {
                    swMerchantId,
                    webshopIds,
                },
            })
        )

        await dispatch(getClearhausApplicationDataThunk(swMerchantId, webshopIds, true))
    }

export const updateOnboardingIsInstantStatusThunk =
    (swMerchantId: string, isInstant: boolean, onboardingId?: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        if (!onboardingId) {
            return
        }

        await dispatch(
            requestThunk(endpoints.Clearhaus.updateIsInstantStatus, {
                params: {
                    swMerchantId,
                    onboardingId,
                    isInstant,
                },
            })
        )
    }

export const saveOnboardingDataThunk =
    (
        swMerchantId: string,
        webshopIds: string[],
        data: IClearhausApplicationForm
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.Clearhaus.saveOnboardingData, {
                data: {
                    swMerchantId,
                    webshopIds,
                    ...data,
                },
            })
        )

        await dispatch(getClearhausApplicationDataThunk(swMerchantId, webshopIds, true))
        for (let i = 0; i < webshopIds.length; i++) {
            await dispatch(getClearhausApplicationStateThunk(swMerchantId, webshopIds[i], true))
        }
    }

export const clearhausOnboardingAsExistingUserThunk =
    (
        swMerchantId: string,
        swMerchantEmail: string,
        swMerchantName: string,
        merchantContactName: string,
        merchantContactPhone: string,
        webshopIds: string[]
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(endpoints.Clearhaus.alreadyHasClearhausOnboarding, {
                data: {
                    swMerchantId,
                    swMerchantEmail,
                    swMerchantName,
                    merchantContactName,
                    merchantContactPhone,
                    webshopIds,
                },
            })
        )

        for (let i = 0; i < webshopIds.length; i++) {
            await dispatch(getClearhausApplicationStateThunk(swMerchantId, webshopIds[0], true))
        }
    }

export const onboardClearhausIfOnboardedForOtherPaymentType =
    (
        swMerchantId: string,
        webshopIds: string[],
        paymentType: TPayment
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const merchantProviderConfig = merchantServicesSelectors.merchantStatuses(getState(), swMerchantId)
        if (!merchantProviderConfig) {
            return
        }
        const webshopIdsToOnboard = webshopIds.filter((wid) => {
            const webshopProviderConfig = merchantProviderConfig[wid]
            if (!webshopProviderConfig || !webshopProviderConfig.gatewayProviders) {
                return false
            }
            const ownClearhausProvider = webshopProviderConfig.gatewayProviders.find(
                (gp) => gp.paymentType === paymentType && gp.providerName == "Clearhaus" && gp.status != "ActionRequired"
            )
            const otherClearhausProvider = webshopProviderConfig.gatewayProviders.find(
                (gp) => gp.paymentType !== paymentType && gp.providerName == "Clearhaus" && gp.status != "ActionRequired"
            )
            return !ownClearhausProvider && !!otherClearhausProvider
        })
        if (webshopIdsToOnboard.length > 0) {
            await dispatch(onboardClearhausForPaymentTypeThunk(swMerchantId, webshopIdsToOnboard, paymentType))
        }
    }

export const onboardClearhausForPaymentTypeThunk =
    (
        swMerchantId: string,
        webshopIds: string[],
        paymentType: TPayment
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            setPiqOnboardingStatusThunk(swMerchantId, webshopIds, {
                paymentType: paymentType,
                providerName: "Clearhaus",
                providerType: "Aquirer",
                status: "InProgress",
                enabled: true,
            })
        )
    }

interface IOnboardingApplicationRequest {
    applications: IOnboardingApplication[]
}

export const adminGetApplicationsListThunk =
    (force?: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const applications = useSelector(onboardingApplicationSelectors.applications)

        if (!force && applications) {
            return
        }

        const result = await dispatch(requestThunk<IOnboardingApplicationRequest>(endpoints.Clearhaus.adminGetApplicationsList))

        dispatch(onboardingApplicationReducerActions.setApplications(result.applications))
    }

export const adminDownloadClearhausApplicationZipThunk =
    (onboardingId: string, zipName: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const result = await dispatch(
            requestThunk<any>(endpoints.Clearhaus.adminDownloadApplicationZip(onboardingId), { responseType: "blob" })
        )

        const url = window.URL.createObjectURL(new Blob([result]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `${zipName}.zip`)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

export const getClearhausApplicationStateThunk =
    (swMerchantId: string, webshopId: string, force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(getClearhausApplicationStatesThunk(swMerchantId, [webshopId], force))
    }

export const getClearhausApplicationStatesThunk =
    (swMerchantId: string, webshopIds: string[], force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const env = sharedConfigurationSelectors.environment(getState())
        if (env === "Sandbox") {
            return
        }
        if (!force && !webshopIds.find((wid) => !clearhausSelectors.applicationState(getState(), wid))) {
            return
        }
        const result = await dispatch(
            requestThunk<{ onboardingsStates: IClearhausApplicationOnboardingState[] }>(
                endpoints.Clearhaus.getApplicationOnboardingState,
                {
                    params: {
                        swMerchantId,
                        webshopIds,
                    },
                }
            )
        )
        result.onboardingsStates.forEach((onboardingState) => {
            dispatch(clearhausReducerActions.setApplicationState(onboardingState.webshopId, onboardingState))
        })
    }

export const fetchClearhausApplicationCommentsThunk =
    (swMerchantId: string, webshopId: string, force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && clearhausSelectors.getApplicationComments(getState(), webshopId)) {
            return
        }
        const result = await dispatch(
            requestThunk<IClearhausCommentsData>(endpoints.Clearhaus.getApplicationComments(swMerchantId, webshopId))
        )

        dispatch(clearhausReducerActions.setApplicationComments(webshopId, result))
    }

export const updateApplicationInfo =
    (
        swMerchantId: string,
        onboardingId: string,
        webshopIds: string[],
        data: IClearhausUpdateInfoForm
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<IClearhausCommentsData>(
                endpoints.Clearhaus.updateApplicationInfo,
                {
                    data: {
                        swMerchantId,
                        onboardingId,
                        webshopIds,
                        ...data,
                        confirmationDocument: data.confirmationDocument[0],
                    },
                },
                {
                    asFormData: true,
                }
            )
        )

        await dispatch(getClearhausApplicationDataThunk(swMerchantId, webshopIds, true))
        for (let i = 0; i < webshopIds.length; i++) {
            await dispatch(getClearhausApplicationStateThunk(swMerchantId, webshopIds[i], true))
        }
    }

export const acceptUpdateApplicationInfoThunk =
    (swMerchantId: string, onboardingId: string, webshopIds: string[]): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<IClearhausCommentsData>(endpoints.Clearhaus.acceptUpdateApplicationInfo, {
                data: {
                    onboardingId,
                },
            })
        )

        await dispatch(getClearhausApplicationDataThunk(swMerchantId, webshopIds, true))
        for (let i = 0; i < webshopIds.length; i++) {
            await dispatch(getClearhausApplicationStateThunk(swMerchantId, webshopIds[i], true))
        }
    }

export const editApplicationThunk =
    (
        swMerchantId: string,
        onboardingId: string,
        webshopIds: string[],
        data: IClearhausApplicationForm
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const editResult = await dispatch(
            requestThunk<{
                onboardingId: string
                ownerIds: string[]
                directorIds: string[]
            }>(endpoints.Clearhaus.editApplication, {
                data: {
                    swMerchantId,
                    onboardingId,
                    ...data,
                },
            })
        )

        await dispatch(uploadApplicationFilesThunk(swMerchantId, onboardingId, data, editResult.ownerIds, editResult.directorIds))

        // submit changes
        await dispatch(submitApplicationAtClearhaus(swMerchantId, editResult.onboardingId, true))

        //update state
        for (let i = 0; i < webshopIds.length; i++) {
            dispatch(getClearhausApplicationStateThunk(swMerchantId, webshopIds[i], true))
        }
        await dispatch(getClearhausApplicationDataThunk(swMerchantId, webshopIds, true))
    }

const submitApplicationAtClearhaus =
    (swMerchantId: string, onboardingId: string, shouldSign: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(endpoints.Clearhaus.submitAtClearhaus, {
                data: {
                    swMerchantId,
                    onboardingId,
                    shouldSign,
                },
            })
        )
    }

export const cancelOnboardingThunk =
    (swMerchantId: string, onboardingId?: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        if (!onboardingId) {
            return
        }

        await dispatch(
            requestThunk<void>(endpoints.Clearhaus.cancelOnboarding, {
                data: {
                    swMerchantId,
                    onboardingId,
                },
            })
        )
    }

export enum FileFromFormType {
    DocumentationFile = 0,
    AdditionalFile = 1,
    OwnerOrDirectorFile = 2,
}

const uploadApplicationFilesThunk =
    (
        swMerchantId: string,
        onboardingId: string,
        data: IClearhausApplicationForm,
        ownerIds: string[],
        directorIds: string[]
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        //Upload documentation
        for (const fileToUpload of getFilesFromFileList(data.documentation?.files)) {
            await dispatch(
                uploadFileToClearhausThunk({
                    swMerchantId: swMerchantId,
                    file: fileToUpload,
                    fileType: FileFromFormType.DocumentationFile,
                    onboardingId: onboardingId,
                })
            )
        }

        //Upload additional files
        for (const fileToUpload of getFilesFromFileList(data.additionalInfo?.files)) {
            await dispatch(
                uploadFileToClearhausThunk({
                    swMerchantId: swMerchantId,
                    file: fileToUpload,
                    fileType: FileFromFormType.AdditionalFile,
                    onboardingId: onboardingId,
                })
            )
        }

        //Upload director files
        for (let i = 0; i < directorIds.length; i++) {
            for (const fileToUpload of getFilesFromFileList(data.directors[i].addressFiles)) {
                await dispatch(
                    uploadFileToClearhausThunk({
                        swMerchantId: swMerchantId,
                        file: fileToUpload,
                        fileType: FileFromFormType.OwnerOrDirectorFile,
                        onboardingId: onboardingId,
                        belongsToDirectorId: directorIds[i],
                        clearhausLabel: "address_legitimation",
                    })
                )
            }

            for (const fileToUpload of getFilesFromFileList(data.directors[i].legitimationFiles)) {
                await dispatch(
                    uploadFileToClearhausThunk({
                        swMerchantId: swMerchantId,
                        file: fileToUpload,
                        fileType: FileFromFormType.OwnerOrDirectorFile,
                        onboardingId: onboardingId,
                        belongsToDirectorId: directorIds[i],
                        clearhausLabel: "picture_legitimation",
                    })
                )
            }
        }

        //Upload owner files
        for (let i = 0; i < ownerIds.length; i++) {
            for (const fileToUpload of getFilesFromFileList(data.owners?.[i].addressFiles)) {
                await dispatch(
                    uploadFileToClearhausThunk({
                        swMerchantId: swMerchantId,
                        file: fileToUpload,
                        fileType: FileFromFormType.OwnerOrDirectorFile,
                        onboardingId: onboardingId,
                        belongsToOwnerId: ownerIds[i],
                        clearhausLabel: "address_legitimation",
                    })
                )
            }

            for (const fileToUpload of getFilesFromFileList(data.owners?.[i].legitimationFiles)) {
                await dispatch(
                    uploadFileToClearhausThunk({
                        swMerchantId: swMerchantId,
                        file: fileToUpload,
                        fileType: FileFromFormType.OwnerOrDirectorFile,
                        onboardingId: onboardingId,
                        belongsToOwnerId: ownerIds[i],
                        clearhausLabel: "picture_legitimation",
                    })
                )
            }
        }
    }

interface IUploadFileProps {
    swMerchantId: string
    file: File
    fileType: FileFromFormType
    onboardingId: string
    belongsToDirectorId?: string
    belongsToOwnerId?: string
    clearhausLabel?: string
}
const uploadFileToClearhausThunk =
    (props: IUploadFileProps): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(
                endpoints.Clearhaus.uploadFile,
                {
                    data: {
                        swMerchantId: props.swMerchantId,
                        file: props.file,
                        fileType: props.fileType.toString(),
                        onboardingId: props.onboardingId,
                        belongsToOwnerId: props.belongsToOwnerId,
                        belongsToDirectorId: props.belongsToDirectorId,
                        clearhausLabel: props.clearhausLabel,
                    },
                },
                { asFormData: true }
            )
        )
    }
