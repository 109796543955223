import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { SharedStoreState, endpointsShared, requestThunk } from "swiipe.portal.shared"

interface ICityByPostalCodeLookupResponse {
    postal_code: string
    city: string
}

export const lookupCityByZipcodeThunk =
    (inputZipcode: string): ThunkAction<Promise<string | undefined>, SharedStoreState, null, AnyAction> =>
    async (dispatch) => {
        try {
            if (!inputZipcode || inputZipcode.length !== 4) {
                return
            }

            const response = await dispatch(
                requestThunk<ICityByPostalCodeLookupResponse>(endpointsShared.Core.cityByZipcodeLookup(inputZipcode), undefined, {
                    noErrorAlert: true,
                })
            )
            return response.city
        } catch (err) {
            return
        }
    }
