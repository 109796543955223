import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { getText, requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { IWinbackDetails, IWinbackSmsSenderNameStatus, TWinbackSmsSenderNameStatus } from "../../type/winback/winbackDetails"
import { StoreState } from "../StoreState"
import { merchantReducerActions, merchantSelectors } from "../reducers/merchantReducer"
import { sendServiceConfirmationEmailThunk } from "./swiipeServicesThunks"
import { setWebshopFeaturesThunk, uploadWebshopFileThunk } from "./webshopThunks"

export const winbackOnboardingThunk =
    (
        swMerchantId: string,
        webshopIds: string[],
        winbackDetails: IWinbackDetails[],
        sendConfirmation: boolean,
        logos: (File | null)[]
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(setWebshopFeaturesThunk(webshopIds, "Winback", true, swMerchantId))

        await dispatch(
            requestThunk(endpoints.Identity.setWinbackDetails, {
                data: {
                    swMerchantId,
                    webshopIds,
                    winbackDetails,
                },
            })
        )

        try {
            await dispatch(
                requestThunk(endpoints.Plugin.recordWebshopSnapshot, {
                    data: {
                        merchantId: swMerchantId,
                        webshopIds,
                    },
                })
            )
        } catch {
            // Dispose error when this call fails - don't stop from onboarding merchant for Win-back
        }

        const uploadLogos = logos.map(async (logo, i) => {
            if (logo === null) {
                return
            }
            return dispatch(uploadWebshopFileThunk(swMerchantId, webshopIds[i], "WebshopLogo", logo))
        })
        const updateSenderNameStatuses = dispatch(getWinbackSmsSenderNameStatusesThunk(swMerchantId, true))
        const updateWinbackDetails = webshopIds.map(async (id) => {
            return dispatch(getWinbackDetailsThunk(swMerchantId, id, true))
        })

        if (sendConfirmation) {
            const serviceConfirmationEmail = dispatch(
                sendServiceConfirmationEmailThunk(swMerchantId, "WinbackConfirmation", getText("serviceNames.winback"))
            )
            await Promise.all([...uploadLogos, updateSenderNameStatuses, ...updateWinbackDetails, serviceConfirmationEmail])
            return
        }

        await Promise.all([...uploadLogos, updateSenderNameStatuses, ...updateWinbackDetails])
    }

export const getWinbackDetailsThunk =
    (swMerchantId: string, webshopId: string, force?: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const details = merchantSelectors.winbackDetails(getState(), webshopId)
        if (details && !force) {
            return
        }

        const response = await dispatch(
            requestThunk<IWinbackDetails>(endpoints.Identity.getWinbackDetails, {
                params: {
                    swMerchantId,
                    webshopId,
                },
            })
        )

        dispatch(merchantReducerActions.setWinbackDetails(webshopId, response))
    }

export const adminUpdateSmsSenderNameStatusThunk =
    (
        swMerchantId: string,
        smsSenderName: string,
        status: TWinbackSmsSenderNameStatus
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const response = await dispatch(
            requestThunk<{
                updatedWebshopIds: string[]
            }>(endpoints.Identity.updateWinbackSmsSenderNameStatus, {
                data: {
                    swMerchantId,
                    smsSenderName,
                    status,
                },
            })
        )

        const updateSenderNameStatuses = dispatch(getWinbackSmsSenderNameStatusesThunk(swMerchantId, true))
        const updateWinbackDetails = response.updatedWebshopIds.map(async (updatedWebshopId) => {
            return dispatch(getWinbackDetailsThunk(swMerchantId, updatedWebshopId, true))
        })
        await Promise.all([updateSenderNameStatuses, ...updateWinbackDetails])
    }

export const getWinbackSmsSenderNameStatusesThunk =
    (swMerchantId: string, force?: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const details = merchantSelectors.winbackSmsSenderNameStatuses(getState(), swMerchantId)
        if (details && !force) {
            return
        }

        const response = await dispatch(
            requestThunk<{
                statuses: IWinbackSmsSenderNameStatus[]
            }>(endpoints.Identity.getWinbackSmsSenderNameStatusesAll, {
                params: {
                    swMerchantId,
                },
            })
        )

        dispatch(merchantReducerActions.setWinbackSmsSenderNameStatuses(swMerchantId, response.statuses))
    }
