import cn from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form, FormGroup } from "reactstrap"
import {
    CheckmarkCircle,
    DropDownList,
    FloatingLabelInput,
    FormGroupWithCheckbox,
    ShowErrorMessages,
    StandardButton,
    TextButton,
    TitleWithAbsoluteHint,
    copyToClipboard,
    getCurrencyDropdownOptions,
    getExampleEmail,
    getFormattedNumber,
    getLanguage,
    openUrl,
    valFuncPattern,
    valFuncRequired,
    valFuncRequiredAndPattern,
    valFuncRequiredAndWithinRange,
    validationPatterns,
} from "swiipe.portal.shared"
import { getFormattedDate } from "../../../services/dateTimeService"
import { getLanguagesList } from "../../../services/languageService"
import { getWebshopHostName } from "../../../services/merchantService"
import { supportedLanguages } from "../../../services/paymentLinkService"
import { addModalThunk } from "../../../store/thunks/modalThunks"
import {
    ICreatePaymentLinkRequest,
    ICreatePaymentLinkResponse,
    createPaymentLinkAsMerchantThunk,
    getLatestPaymentDataThunk,
} from "../../../store/thunks/paymentLinkThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../../type/mixedmerchantdetails"
import SubmitButton from "../../buttons/SubmitButton"
import ContainerWithCloseButton from "../../layouts/ContainerWithCloseButton"
import "./CreatePaymentLinkContainer.scss"

interface IForm {
    paymentLinkOrderId: string
    amount: number
    currency: string
    customerLanguage: string
    customerEmail: string
    customerFirstName: string

    enableAutoReceipt: boolean
}

export interface ICreatedPaymentLinkModelForUI {
    request: ICreatePaymentLinkRequest
    response: ICreatePaymentLinkResponse

    notificationSent?: boolean
}

const C_SHARP_MAX_INTEGER = 2147483647

interface ICreatePaymentLinkContainerProps {
    merchantDetails: MixedMerchantDetails
    selectedWebshopId: string
    paymentLinkPrefix: string
    isOpened: boolean
    setIsOpened: (isOpened: boolean) => void
}

const CreatePaymentLinkContainer = ({
    merchantDetails,
    selectedWebshopId,
    paymentLinkPrefix,
    isOpened,
    setIsOpened,
}: ICreatePaymentLinkContainerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [createdPaymentLink, setCreatedPaymentLink] = useState<ICreatedPaymentLinkModelForUI | undefined>(undefined)
    const [latestPaymentLinkPlatformOrderId, setLatestPaymentLinkPlatformOrderId] = useState<string | undefined>(undefined)

    const paymentLinkInputRef = useRef<HTMLTextAreaElement | HTMLInputElement | null>()
    const { handleSubmit, register, getValues, setValue, formState } = useForm<IForm>()

    const onSubmit: SubmitHandler<IForm> = async (data) => {
        const webshopId = selectedWebshopId
        const swMerchantId = merchantDetails.swMerchant.swMerchantId
        const amountInMinors = Math.round(data.amount * 100)

        const request: ICreatePaymentLinkRequest = {
            swMerchantId,
            webshopId,
            customerLanguage: data.customerLanguage,
            customerEmail: data.customerEmail,
            customerFirstName: data.customerFirstName,

            paymentLinkOrderId: data.paymentLinkOrderId,
            amountInMinors,
            currency: data.currency,

            enableAutoReceipt: data.enableAutoReceipt,

            allowOrderOverride: false,
        }

        const response = await dispatch(createPaymentLinkAsMerchantThunk(request))
        if (response.success) {
            setPaymentLinkCreated(request, response)
            return
        }

        const modalResponse = await dispatch(
            addModalThunk({
                type: "paymentLinkError",
                platformOrderId: response.paymentLinkDetails.platformOrderId,
                created: response.paymentLinkDetails.created,
                canBeOverridden: response.canBeOverridden,
            })
        )

        if (response.canBeOverridden && modalResponse.type === "accepted") {
            request.allowOrderOverride = true

            const response = await dispatch(createPaymentLinkAsMerchantThunk(request))

            if (response.success) {
                setPaymentLinkCreated(request, response)
            }
        }
    }

    const loadLatestPaymentLinkData = async () => {
        const latestPaymentLinkData = await dispatch(
            getLatestPaymentDataThunk(merchantDetails.swMerchant.swMerchantId, selectedWebshopId, "Portal")
        )

        setValue("currency", latestPaymentLinkData.currency)
        setValue("customerLanguage", latestPaymentLinkData.language)
        setLatestPaymentLinkPlatformOrderId(latestPaymentLinkData.platformOrderId)
    }

    const setPaymentLinkCreated = (request: ICreatePaymentLinkRequest, response: ICreatePaymentLinkResponse) => {
        const created: ICreatedPaymentLinkModelForUI = {
            request,
            response,
        }
        setCreatedPaymentLink(created)
    }

    useEffect(() => {
        if (!selectedWebshopId) {
            return
        }

        if (createdPaymentLink?.request.webshopId !== selectedWebshopId) {
            setCreatedPaymentLink(undefined)
        }

        loadLatestPaymentLinkData()
    }, [selectedWebshopId, merchantDetails])

    return (
        <ContainerWithCloseButton
            className="create-pl-container"
            titleOpened={
                <div className={cn("create-pl-title", createdPaymentLink ? "mb-5" : "mb-4")}>
                    {createdPaymentLink ? (
                        <>
                            <CheckmarkCircle checked />
                            <p>{t("paymentLinkPage.createdSuccess.title")}</p>
                        </>
                    ) : (
                        <p>{t("paymentLinkPage.title")}</p>
                    )}
                </div>
            }
            titleClosed={
                <div className="create-pl-title closed">
                    <p>{t("paymentLinksHistoryPage.goBack")}</p>
                </div>
            }
            isOpened={isOpened}
            setIsOpened={(isOpened) => {
                if (createdPaymentLink && !isOpened) {
                    setCreatedPaymentLink(undefined)
                    loadLatestPaymentLinkData()
                    return
                }

                setIsOpened(isOpened)
            }}
        >
            <>
                {createdPaymentLink && (
                    <div className="pl-created">
                        <div className="summary mb-4">
                            <div className="order-id">
                                {t("paymentLinkPage.createdSuccess.orderId")}:{" "}
                                {createdPaymentLink.response.paymentLinkDetails.platformOrderId}
                            </div>
                            <div className="amount">
                                {createdPaymentLink.request.currency}{" "}
                                {getFormattedNumber(createdPaymentLink.request.amountInMinors / 100, {
                                    numberFormat: "forceTwoDecimals",
                                })}
                            </div>
                        </div>

                        <FormGroup className="copy-link-group">
                            <span className="label">{t("paymentLinkPage.createdSuccess.paymentLink")}:</span>
                            <FloatingLabelInput
                                readOnly
                                innerRef={(ref) => (paymentLinkInputRef.current = ref)}
                                defaultValue={createdPaymentLink.response.paymentLinkDetails.paymentLink}
                            />
                            <StandardButton
                                dark
                                className="copy-link-btn mt-3"
                                onClick={() => {
                                    if (paymentLinkInputRef.current) {
                                        copyToClipboard(paymentLinkInputRef.current as HTMLInputElement)
                                    }
                                }}
                            >
                                {t("paymentLinkPage.createdSuccess.copy")}
                            </StandardButton>
                        </FormGroup>

                        {createdPaymentLink.notificationSent && (
                            <div className="sent-to-customer-text">{t("paymentLinkPage.createdSuccess.sentToCustomer")}</div>
                        )}
                        <StandardButton
                            className="send-to-customer-btn mb-5"
                            invertedBlueBorder
                            onClick={() => {
                                dispatch(
                                    addModalThunk({
                                        type: "paymentLinkSend",
                                        merchantDetails,
                                        paymentLinkData: {
                                            webshopId: selectedWebshopId,
                                            orderId: createdPaymentLink.response.paymentLinkDetails.orderId,
                                            platformOrderId: createdPaymentLink.response.paymentLinkDetails.platformOrderId,

                                            paymentLink: createdPaymentLink.response.paymentLinkDetails.paymentLink,

                                            customerLanguage: createdPaymentLink.request.customerLanguage,
                                            customerEmail: createdPaymentLink.request.customerEmail,
                                            customerFirstName: createdPaymentLink.request.customerFirstName,
                                        },
                                        notificationSentCallback: () => {
                                            setCreatedPaymentLink({ ...createdPaymentLink, notificationSent: true })
                                            dispatch(
                                                addModalThunk({
                                                    type: "info",
                                                    text: t("paymentLinkPage.createdSuccess.paymentLinkSent"),
                                                    showCheckmark: true,
                                                    noBordersInside: true,
                                                    hideCloseButton: true,
                                                })
                                            )
                                        },
                                    })
                                )
                            }}
                        >
                            {createdPaymentLink.notificationSent
                                ? t("paymentLinkPage.createdSuccess.sendAgain")
                                : t("paymentLinkPage.createdSuccess.sendToCustomer")}
                        </StandardButton>

                        <TextButton
                            text={t("paymentLinkPage.createdSuccess.openInBrowser")}
                            color="grey"
                            textDecoration="underline"
                            onClick={async () =>
                                openUrl(createdPaymentLink.response.paymentLinkDetails.paymentLink, { inNewTab: true })
                            }
                        />
                    </div>
                )}

                {!createdPaymentLink && (
                    <Form onSubmit={handleSubmit(onSubmit)} className="create-pl-form">
                        <FormGroup>
                            <div className="field-section">
                                <FloatingLabelInput
                                    name="paymentLinkOrderId"
                                    innerRef={register(
                                        valFuncRequiredAndPattern(
                                            validationPatterns.paymentLinkOrderId,
                                            t("paymentLinkPage.validationErrors.orderId")
                                        )
                                    )}
                                    placeholder={t("paymentLinkPage.placeholders.orderId")}
                                    inputTextPrefix={paymentLinkPrefix}
                                />
                            </div>
                            <div className="hint-section">
                                <TitleWithAbsoluteHint
                                    hintTextWithHtml={`${t("paymentLinkPage.hints.orderId.1")}${
                                        latestPaymentLinkPlatformOrderId
                                            ? t("paymentLinkPage.hints.orderId.2", { latestPaymentLinkPlatformOrderId })
                                            : ""
                                    }`}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className="mb-4">
                            <div className="field-section">
                                <div className="row">
                                    <div className="col-5 col-md-5 pr-0">
                                        <DropDownList
                                            name="currency"
                                            options={getCurrencyDropdownOptions()}
                                            placeholder={t("paymentLinkPage.placeholders.currency")}
                                            innerRef={register(valFuncRequired(t("paymentLinkPage.validationErrors.currency")))}
                                            noBorderRight
                                        />
                                    </div>
                                    <div className="col-7 col-md-7 pl-0">
                                        <FloatingLabelInput
                                            name="amount"
                                            type="number"
                                            noBorderRadiusLeft
                                            inputMode="decimal"
                                            step={0.01}
                                            innerRef={register(
                                                valFuncRequiredAndWithinRange(
                                                    0.01,
                                                    C_SHARP_MAX_INTEGER / 100,
                                                    t("paymentLinkPage.validationErrors.amount")
                                                )
                                            )}
                                            placeholder={t("paymentLinkPage.placeholders.amount")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="hint-section"></div>
                        </FormGroup>
                        <FormGroup>
                            <div className="field-section">
                                <DropDownList
                                    name="customerLanguage"
                                    options={getLanguagesList(supportedLanguages)}
                                    placeholder={t("paymentLinkPage.placeholders.customersLanguage")}
                                    innerRef={register(valFuncRequired(t("paymentLinkPage.validationErrors.customersLanguage")))}
                                />
                            </div>
                            <div className="hint-section"></div>
                        </FormGroup>
                        <FormGroup>
                            <div className="field-section">
                                <FloatingLabelInput
                                    name="customerEmail"
                                    innerRef={register(
                                        valFuncRequiredAndPattern(
                                            validationPatterns.email,
                                            t("paymentLinkPage.validationErrors.customerEmail")
                                        )
                                    )}
                                    placeholder={t("paymentLinkPage.placeholders.customerEmail")}
                                />
                            </div>
                            <div className="hint-section">
                                <TitleWithAbsoluteHint hintText={t("paymentLinkPage.hints.customerEmail")} />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div className="field-section">
                                <FloatingLabelInput
                                    name="customerFirstName"
                                    innerRef={register(
                                        valFuncPattern(
                                            validationPatterns.firstName,
                                            t("paymentLinkPage.validationErrors.customerFirstName")
                                        )
                                    )}
                                    placeholder={t("paymentLinkPage.placeholders.customerFirstName")}
                                />
                            </div>
                            <div className="hint-section"></div>
                        </FormGroup>
                        <div className="checkbox-container mt-4 mb-5">
                            <div className="field-section">
                                <FormGroupWithCheckbox
                                    name="enableAutoReceipt"
                                    innerRef={register}
                                    className="mb-1"
                                    defaultChecked={true}
                                >
                                    <div className="checkbox">{t("paymentLinkPage.enableAutoReceipt")}</div>
                                </FormGroupWithCheckbox>
                                <TextButton
                                    className="preview-receipt-btn"
                                    color="grey"
                                    textDecoration="underline"
                                    text={t("paymentLinkPage.previewReceipt")}
                                    onClick={async () => {
                                        const currentInputsValues = getValues()

                                        const email = await dispatch(
                                            getExampleEmail(
                                                merchantDetails.swMerchant.swMerchantId,
                                                "PaymentLinkOrderReceipt",
                                                currentInputsValues.customerLanguage
                                                    ? currentInputsValues.customerLanguage
                                                    : getLanguage(),
                                                {
                                                    customerName: currentInputsValues.customerFirstName
                                                        ? currentInputsValues.customerFirstName
                                                        : "Hans",
                                                    platformOrderId: currentInputsValues.paymentLinkOrderId
                                                        ? `${paymentLinkPrefix}${currentInputsValues.paymentLinkOrderId}`
                                                        : "PL234",
                                                    webshopName: getWebshopHostName(merchantDetails, selectedWebshopId),
                                                    paymentMethod: "Credit Card",
                                                    amount: currentInputsValues.amount ? currentInputsValues.amount : "100.00",
                                                    currency: currentInputsValues.currency ? currentInputsValues.currency : "EUR",
                                                    dateTime: getFormattedDate(new Date().toString(), "dash-international"),
                                                }
                                            )
                                        )

                                        await dispatch(
                                            addModalThunk({
                                                type: "email",
                                                title: email.subject,
                                                body: email.body,
                                            })
                                        )
                                    }}
                                />
                            </div>
                            <div className="hint-section">
                                <TitleWithAbsoluteHint hintTextWithHtml={t("paymentLinkPage.hints.enableAutoReceipt")} />
                            </div>
                        </div>
                        <ShowErrorMessages formState={formState} />
                        <SubmitButton dark noBorder containerClass="submit-btn" formState={formState}>
                            {t("paymentLinkPage.createBtnText")}
                        </SubmitButton>
                    </Form>
                )}
            </>
        </ContainerWithCloseButton>
    )
}

export default CreatePaymentLinkContainer
