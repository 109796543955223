import { CommentState, commentReducer } from "./commentReducer"
import { CompanySearchState, companySearchReducer } from "./companySearchReducer"
import { ModalState, modalReducer } from "./modalReducer"
import { OrganizationState, organizationReducer } from "./organizationReducer"
import { SharedConfigurationState, sharedConfigurationReducer } from "./sharedConfigurationReducer"
import { ISwMerchantStoreAddition, swMerchantSlice } from "./swMerchantSlice"
import { UserState, userReducer } from "./userReducer"

export type SharedStoreState = {
    sharedConfiguration: SharedConfigurationState
    companySearch: CompanySearchState
    modal: ModalState
    organizations: OrganizationState
    user: UserState
    comment: CommentState
} & ISwMerchantStoreAddition

export const getSharedRootReducer = () => ({
    sharedConfiguration: sharedConfigurationReducer,
    companySearch: companySearchReducer,
    modal: modalReducer as any, // any to counter type error for mixing TModals and TAllSharedModals
    organizations: organizationReducer,
    user: userReducer,
    comment: commentReducer,
    swMerchant: swMerchantSlice.reducer,
})
