import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import IconFilter from "../../images/icons/filtering/icon-filter.svg"
import IconRefresh from "../../images/icons/icon-refresh.svg"
import { getPagesCount, getRecordsForPageNumber } from "../../services/pagingService"
import { DropDownWithButton } from "../form/input/DropDownWithButton"
import { SearchBox } from "../form/input/SearchBox"
import { CheckboxWithHint } from "../form/input/checkbox/CheckboxWithHint"
import { SpinnerContainer } from "../loading/SpinnerContainer"
import { PaginationSection } from "./PaginationSection"
import { SortDropdownBox } from "./SortDropdownBox"
import "./TableContainer.scss"
import { TableFilterBox } from "./TableFilterBox"

export interface ITableCell {
    className?: string
    onClick?: () => Promise<void>
    content: React.ReactNode
}

type TResetFunc = (resetOptions: { resetPage?: boolean; resetSearch?: boolean; resetData?: boolean }) => void
export interface ITableResetControl {
    reset?: TResetFunc
}

export interface ITableNavigationControl<T> {
    getNext?: () => T
    getPrevious?: () => T
    goNext?: () => Promise<void>
    goPrevious?: () => Promise<void>
}

interface ITableContainer<T, TSortingType> {
    className?: string
    items?: T[]
    title: string
    emptyDescription: string
    renderHeaderCells: () => ITableCell[]
    renderRowCells: (item: T, isSelected: boolean, selectItem: () => void, refresh: () => Promise<void>) => ITableCell[]
    fetchItems: (pageNumber: number, perPage: number, force: boolean) => Promise<void>
    morePagesAvailable?: boolean
    getItemId: (item: T) => string
    shownItemId?: string
    onShowItemId?: (itemId: string) => Promise<void>
    showRefresh?: boolean
    fetchItemsHash: string

    resetControl?: ITableResetControl
    navigationControl?: ITableNavigationControl<T>

    search?: {
        handleSearch: (search: string) => Promise<void>
        searchPlaceholder: string
    }

    sorting?: {
        sortingItems: { value: TSortingType; title: string }[]
        onSortingChange: (value: TSortingType, direction: "ascending" | "descending") => void
        initialSortingValue: TSortingType
        initialSortingDirection: "ascending" | "descending"
    }

    bulkActions?: {
        items: { value: string; title: string }[]
        onBulkAction: (value: string, items: T[], refresh: () => Promise<void>) => Promise<void>
    }

    tabs?: {
        tabItems: { id: string | undefined; title: string }[]
        onTabChange: (id: string | undefined) => void
        initialTabId: string | undefined
    }

    filtering?: {
        renderInlineFilters?: () => React.ReactNode
        renderSubMenuFilters?: (showFilters: boolean) => React.ReactNode
    }
}

export const TableContainer = <T, TSortingType extends string | number>({
    className,
    items,
    title,
    emptyDescription,
    renderHeaderCells,
    renderRowCells,
    morePagesAvailable,
    fetchItems,
    getItemId,
    onShowItemId,
    showRefresh,
    fetchItemsHash,

    resetControl,
    navigationControl,
    shownItemId,

    search,
    sorting,
    bulkActions,
    tabs,
    filtering,
}: ITableContainer<T, TSortingType>) => {
    const { t } = useTranslation()
    const [pageNumber, setPageNumber] = useState(1)
    const perPage = 50
    const itemsCurrentPage = getRecordsForPageNumber(pageNumber, perPage, items)
    const pagesCount = getPagesCount(perPage, items)

    const [showSingleWithId, setShowSingleWithId] = useState<string | undefined>(shownItemId)
    const [searchValue, setSearchValue] = useState("")
    const [currentTab, setCurrentTab] = useState<string | undefined>(tabs?.initialTabId)

    const [selectAllCheckbox, setSelectAllCheckbox] = useState(false)
    const [selectedItems, setSelectedItems] = useState<T[]>([])
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false)

    const [sortingDirection, setSortingDirection] = useState<"ascending" | "descending">(
        sorting?.initialSortingDirection ?? "descending"
    )
    const [sortingValue, setSortingValue] = useState<TSortingType | undefined>(sorting?.initialSortingValue)
    const [showFilters, setShowFilters] = useState<boolean>(false)

    const handlePageChange = (pageNumber: number) => {
        if (!pagesCount) {
            return
        }
        if (pageNumber >= 1 && pageNumber <= pagesCount) {
            setPageNumber(pageNumber)
            fetchItems(pageNumber, perPage, false)
        }
    }

    useEffect(() => {
        if (searchValue || searchValue === "") {
            search?.handleSearch(searchValue)
        }
    }, [searchValue])

    useEffect(() => {
        tabs?.onTabChange(currentTab)
    }, [currentTab])

    useEffect(() => {
        sorting?.onSortingChange(sortingValue!, sortingDirection)
    }, [sortingDirection, sortingValue])

    useEffect(() => {
        setSelectAllCheckbox(false)
        setSelectedItems([])
    }, [pageNumber])

    useEffect(() => {
        if (resetControl) {
            resetControl.reset = (resetOptions) => {
                if (resetOptions.resetPage) {
                    setPageNumber(1)
                    setSelectAllCheckbox(false)
                    setSelectedItems([])
                }
                if (resetOptions.resetSearch) {
                    setSearchValue("")
                }
                if (resetOptions.resetData) {
                    refresh()
                }
            }
        }
    }, [resetControl])

    useEffect(() => {
        if (onShowItemId && navigationControl && items && showSingleWithId) {
            const currentItemIndex = items.findIndex((i) => getItemId(i) === showSingleWithId)
            const indexFound = currentItemIndex === 0 || !!currentItemIndex
            if (indexFound && currentItemIndex - 1 < items.length) {
                navigationControl.getNext = () => items[currentItemIndex + 1]
                navigationControl.goNext = async () => {
                    setShowSingleWithId(getItemId(items[currentItemIndex + 1]))
                    await onShowItemId(getItemId(items[currentItemIndex + 1]))
                    const newPageNumber = Math.ceil((currentItemIndex + 1 + 1) / perPage)
                    if (newPageNumber !== pageNumber) {
                        handlePageChange(newPageNumber)
                    }
                }
            } else {
                navigationControl.getNext = undefined
                navigationControl.goNext = undefined
            }
            if (indexFound && currentItemIndex > 0) {
                navigationControl.getPrevious = () => items[currentItemIndex - 1]
                navigationControl.goPrevious = async () => {
                    setShowSingleWithId(getItemId(items[currentItemIndex - 1]))
                    await onShowItemId(getItemId(items[currentItemIndex - 1]))
                    const newPageNumber = Math.ceil((currentItemIndex - 1 + 1) / perPage)
                    if (newPageNumber !== pageNumber) {
                        handlePageChange(newPageNumber)
                    }
                }
            } else {
                navigationControl.getPrevious = undefined
                navigationControl.goPrevious = undefined
            }
        }
    }, [navigationControl, items, showSingleWithId])

    useEffect(() => {
        fetchItems(pageNumber, perPage, false)
    }, [fetchItemsHash])

    useEffect(() => {
        setShowSingleWithId(shownItemId)
    }, [shownItemId])

    const refresh = async () => {
        setIsRefreshing(true)
        await fetchItems(pageNumber, perPage, true)
        setSelectAllCheckbox(false)
        setSelectedItems([])
        setIsRefreshing(false)
    }

    return (
        <div>
            <SpinnerContainer
                className="table-container__spinner-container"
                showSpinner={
                    !itemsCurrentPage || (!!showSingleWithId && !itemsCurrentPage?.find((i) => getItemId(i) === showSingleWithId))
                }
            >
                <div className="table-container__main-title-row">
                    <p className="table-container__main-title-row-title mr-3">{title}</p>
                    {search && (
                        <SearchBox
                            className="table-container__search-box"
                            currentValue={searchValue}
                            delayOnChangeMs={3000}
                            placeholder={search.searchPlaceholder || "Search"}
                            onValueChange={(val) => {
                                setSearchValue(val)
                            }}
                            iconLeft
                            iconBlue
                        />
                    )}
                    {search && searchValue != "" && (
                        <span
                            className="table-container__search-box-to-overview"
                            onClick={() => {
                                setSearchValue("")
                            }}
                        >
                            {t("common.navigation.backToOverview")}
                        </span>
                    )}
                    <PaginationSection
                        className="table-container__pagination align-right w-100"
                        label={t("table.pagination.perpage", { number: perPage })}
                        pageNumber={items && items.length === 0 ? 0 : pageNumber}
                        pagesCount={pagesCount ? pagesCount : 0}
                        morePagesAvailable={morePagesAvailable}
                        onPageChange={handlePageChange}
                    />
                </div>
                {tabs && (
                    <div className="table-container__tabs_row">
                        {tabs.tabItems.map((tab) => {
                            return (
                                <div
                                    key={tab.id ?? "none"}
                                    className={cn(
                                        "table-container__tabs",
                                        tab.id === currentTab && "table-container__tabs--active"
                                    )}
                                    onClick={() => {
                                        setCurrentTab(tab.id)
                                    }}
                                >
                                    {tab.title.toUpperCase()}
                                </div>
                            )
                        })}
                    </div>
                )}
                <div className="table-container__controls-box">
                    <div className="table-container__search-sort-box">
                        {showRefresh && (
                            <span
                                className={cn(
                                    "table-container__refresh-icon",
                                    isRefreshing && "table-container__refresh-icon--refresh-animating"
                                )}
                                onClick={async () => {
                                    await refresh()
                                }}
                            >
                                <img src={IconRefresh} alt={t("table.refresh")} />
                            </span>
                        )}
                        {sorting && (
                            <SortDropdownBox<TSortingType>
                                title={t("table.sorting.sortBy")}
                                options={sorting.sortingItems.map((si) => ({ text: si.title, value: si.value }))}
                                defaultValue={sorting.initialSortingValue}
                                isDirectionDesc={sortingDirection === "descending"}
                                onChangeDropdown={(e, value) => setSortingValue(value)}
                                onSwitchSortDirection={(sortOrder) =>
                                    setSortingDirection(sortOrder === "desc" ? "descending" : "ascending")
                                }
                            />
                        )}
                    </div>
                    <div className="table-container__bulk-actions-and-filter-box">
                        {bulkActions && (
                            <div className="table-container__bulk-actions">
                                <DropDownWithButton
                                    options={bulkActions.items.map((i) => ({ text: i.title, value: i.value }))}
                                    placeholder={t("table.bulkActions.placeholder")}
                                    buttonText={t("table.bulkActions.apply")}
                                    onButtonClick={async (selectedValue) => {
                                        await bulkActions.onBulkAction(selectedValue, selectedItems, refresh)
                                    }}
                                />
                            </div>
                        )}
                        {filtering?.renderInlineFilters && filtering.renderInlineFilters()}
                        {filtering?.renderSubMenuFilters && (
                            <div className={cn("table-container__filter-button-box", { "d-none": showFilters })}>
                                <span
                                    className="table-container__filter-button"
                                    onClick={() => {
                                        setShowFilters(true)
                                    }}
                                >
                                    <img src={IconFilter} />
                                    {t("table.filtering.filter")}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                {filtering?.renderSubMenuFilters && (
                    <TableFilterBox
                        closeFiltersCallback={() => {
                            setShowFilters(false)
                        }}
                        showFilters={showFilters}
                    >
                        {filtering.renderSubMenuFilters(showFilters)}
                    </TableFilterBox>
                )}

                <table className={cn("table-container__table", className)}>
                    <tbody>
                        {items && items.length === 0 && (
                            <tr className="table-container__empty-message">
                                <td>{emptyDescription}</td>
                            </tr>
                        )}
                        {items && items.length > 0 && (
                            <>
                                <tr>
                                    {bulkActions && (
                                        <th
                                            className={cn(
                                                "table-container__table-header-cell",
                                                "table-container__table-header-cell--select"
                                            )}
                                        >
                                            <CheckboxWithHint
                                                checked={selectAllCheckbox}
                                                handleChange={(checked: boolean) => {
                                                    setSelectAllCheckbox(checked)

                                                    if (!checked) {
                                                        setSelectedItems([])
                                                        return
                                                    }

                                                    setSelectedItems(itemsCurrentPage ?? [])
                                                }}
                                                hint={t("table.bulkActions.selectAll")}
                                            />
                                        </th>
                                    )}
                                    {renderHeaderCells().map((headerCell, index) => (
                                        <th
                                            key={index}
                                            className={cn("table-container__table-header-cell", headerCell.className)}
                                            onClick={headerCell.onClick}
                                        >
                                            {headerCell.content}
                                        </th>
                                    ))}
                                </tr>
                                {itemsCurrentPage?.map((item, index) => (
                                    <tr key={index}>
                                        {bulkActions && (
                                            <td
                                                className={cn(
                                                    "table-container__table-row-cell",
                                                    "table-container__table-row-cell-select"
                                                )}
                                            >
                                                <CheckboxWithHint
                                                    handleChange={(checked: boolean) => {
                                                        setSelectAllCheckbox(false)

                                                        if (checked) {
                                                            setSelectedItems([...selectedItems, item])
                                                            return
                                                        }

                                                        setSelectedItems(selectedItems.filter((i) => i !== item))
                                                    }}
                                                    checked={selectedItems.includes(item)}
                                                />
                                            </td>
                                        )}
                                        {renderRowCells(
                                            item,
                                            !!selectedItems.find((i) => getItemId(i) === getItemId(item)),
                                            async () => {
                                                if (onShowItemId) {
                                                    setShowSingleWithId(getItemId(item))
                                                    await onShowItemId(getItemId(item))
                                                }
                                            },
                                            refresh
                                        ).map((rowCell, index) => (
                                            <td
                                                key={index}
                                                className={cn("table-container__table-row-cell", rowCell.className)}
                                                onClick={rowCell.onClick}
                                            >
                                                {rowCell.content}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </>
                        )}
                    </tbody>
                </table>
                <PaginationSection
                    className="table-container__pagination align-right"
                    label={t("table.pagination.perpage", { number: perPage })}
                    pageNumber={items && items.length === 0 ? 0 : pageNumber}
                    pagesCount={pagesCount ? pagesCount : 0}
                    morePagesAvailable={morePagesAvailable}
                    onPageChange={handlePageChange}
                />
            </SpinnerContainer>
        </div>
    )
}
