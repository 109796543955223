import { Field, FieldTypes, HostedFields, IHostedFieldsCallback } from "hosted-fields-sdk"
import $ from "jquery"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { SpinnerContainer, sharedConfigurationSelectors, useSelector } from "swiipe.portal.shared"
import { configurationSelectors } from "../../../store/reducers/configurationSlice"

export type IHostedFieldsCallbackRefObject = { getData?: () => Promise<IHostedFieldsCallback> }

export interface IPaymentIQHostedFieldsProps<T> {
    reloadFieldsOn: any[]
    getDataReference: IHostedFieldsCallbackRefObject
}

let hostedFieldsGetResult: IHostedFieldsCallback | undefined = undefined

const PaymentIQHostedFields = <T extends any>({ reloadFieldsOn, getDataReference }: IPaymentIQHostedFieldsProps<T>) => {
    const hostedFieldsWrapperRef = useRef<HTMLDivElement | null | undefined>()
    const [showSpinner, setShowSpinner] = useState(true)
    const env = useSelector(sharedConfigurationSelectors.environment)
    const merchantId = useSelector(configurationSelectors.swiipeInvoicingPaymentIQMerchantID)
    const { t } = useTranslation()

    getDataReference.getData = () => {
        return new Promise((resolve) => {
            hostedFieldsGetResult = undefined
            HostedFields.get()
            const interval: ReturnType<typeof setTimeout> = setInterval(() => {
                if (hostedFieldsGetResult) {
                    resolve(hostedFieldsGetResult)
                    clearInterval(interval)
                }
            }, 50)
        })
    }

    function getFieldConfig() {
        const creditCard: Field = {
            type: FieldTypes.CREDITCARD_NUMBER,
            id: "frmCCNum",
            name: "cardnumber",
            label: t("creditCardFields.number.title"),
            error: t("creditCardFields.number.error"),
            helpKey: t("creditCardFields.number.title"),
            visible: true,
            required: true,
            noAttributeValueFormatting: true,
            autocomplete: "cc-number",
            showIcon: true,
            pattern: "[0-9]*",
        }

        const expiryDate: Field = {
            type: FieldTypes.EXPIRY_MM_YYYY,
            autocomplete: "cc-exp",
            error: t("creditCardFields.expiry.error"),
            helpKey: t("creditCardFields.expiry.title"),
            id: "frmCCExp",
            key: "creditcard.main.expirymmyyyy",
            name: "cc-exp",
            noAttributeValueFormatting: true,
            pattern: "[0-9]*",
            required: true,
            requiredNewPayment: true,
            requiredRepeatPayment: false,
            showIcon: true,
            value: undefined,
            visible: true,
            visibleOnNewPayment: true,
            visibleOnRepeatPayment: false,
        }

        const cvv: Field = {
            type: FieldTypes.CVV,
            autocomplete: "cc-csc",
            label: t("creditCardFields.cvv.title"),
            error: t("creditCardFields.cvv.error"),
            helpKey: t("creditCardFields.cvv.title"),
            id: "frmCCCVC",
            key: "creditcard.main.cvc",
            name: "cvc",
            noAttributeValueFormatting: true,
            pattern: "[0-9]*",
            required: true,
            requiredNewPayment: true,
            requiredRepeatPayment: true,
            showIcon: true,
            value: undefined,
            visible: true,
            visibleOnNewPayment: true,
            visibleOnRepeatPayment: true,
        }

        return [creditCard, expiryDate, cvv]
    }

    const fieldsConfig = getFieldConfig()

    const resetHostedFields = (ref: React.MutableRefObject<HTMLDivElement | null | undefined>) => {
        HostedFields.reset()

        if (!ref.current) {
            return
        }

        const $hostedFieldsWrapper = $(ref.current)

        $hostedFieldsWrapper.empty()
    }

    useEffect(() => {
        setShowSpinner(true)
        resetHostedFields(hostedFieldsWrapperRef)
        HostedFields.setup({
            merchantId: parseInt(merchantId ?? "0"),
            renderMode: "single",
            hostedfieldsurl:
                env === "Betatest" || env === "Production"
                    ? "https://hostedpages.paymentiq.io/1.0.36/index.html"
                    : "https://test-hostedpages.paymentiq.io/1.0.36/index.html",
            fields: fieldsConfig,
            service: "",
            autoFocusNext: true,
            styles: "/* RenderAsFloatingLabel */ " + require("./creditCardHostedFieldsStyle.ts").default,
            callback: () => (c: IHostedFieldsCallback) => {
                hostedFieldsGetResult = c
            },
            onLoadCallback: () => () => {
                setShowSpinner(false)
            },
            el: "#hosted-fields-wrapper",
        })
    }, reloadFieldsOn)

    return (
        <SpinnerContainer showSpinner={showSpinner}>
            <div id="hosted-fields-wrapper" ref={(ref) => (hostedFieldsWrapperRef.current = ref)}></div>
        </SpinnerContainer>
    )
}

export default PaymentIQHostedFields
