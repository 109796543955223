import React from "react"
import { FieldsetFormGroup, FloatingLabelInput, IFloatingLabelsInputProps } from "swiipe.portal.shared"

interface IEditFormInputProps extends IFloatingLabelsInputProps {
    groupClassName?: string
}

const EditFormInput = ({ groupClassName, ...otherProps }: IEditFormInputProps) => {
    return (
        <FieldsetFormGroup field className={groupClassName}>
            <FloatingLabelInput {...otherProps} />
        </FieldsetFormGroup>
    )
}

export default EditFormInput
