import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, ModalFooter } from "swiipe.portal.shared"
import ListOfMessagesSection from "../../components/sections/ListOfMessagesSection"
import { getPlatformOrderIdFormatted } from "../../services/orderService"
import { Order } from "../../type/Order"

export interface IModalConfirmCaptureRefund extends IModalBase {
    type: "confirmCaptureRefund"
    selectedOrders: Order[]
    isRefund: boolean
}

const ModalConfirmCaptureRefund = ({
    selectedOrders,
    isRefund,
    callback,
    ...otherProps
}: IModalConfirmCaptureRefund & IModalControlProps) => {
    const { t } = useTranslation()

    const ordersWithActionAvailable = selectedOrders.filter((o) => (isRefund ? o.actions.canRefund : o.actions.canCapture))
    const modalTitle = t(`transactions.${isRefund ? "refund" : "capture"}Modal.title`)

    const getModalMessage = () => {
        if (ordersWithActionAvailable.length === 0) {
            return ""
        }

        if (selectedOrders.length === ordersWithActionAvailable.length) {
            return t(`transactions.bulkActionModals.messages.${isRefund ? "refund" : "capture"}`, {
                amountOfOrders: selectedOrders.length,
            })
        }

        return t(`transactions.bulkActionModals.messages.${isRefund ? "refund" : "capture"}Filtered`, {
            amountOfOrders: ordersWithActionAvailable.length,
            outOfAmount: selectedOrders.length,
        })
    }

    return (
        <BaseModal
            {...otherProps}
            modalClassName="modal-confirm-capture-refund"
            callback={callback}
            centered
            title={modalTitle}
            showCloseButton
        >
            <ModalBody>
                <div className="content-container">
                    {!!ordersWithActionAvailable?.length && <p className="text-center mb-4 mt-2">{getModalMessage()}</p>}
                    <ListOfMessagesSection
                        header={t("transactions.bulkActionModals.errors.actionNotAvailable")}
                        showMoreText={t("transactions.bulkActionModals.messages.actionFail.seeMore")}
                        messages={selectedOrders
                            .filter((o) => (isRefund ? !o.actions.canRefund : !o.actions.canCapture))
                            .map((o) => getPlatformOrderIdFormatted(o.platformOrderId))}
                        showBeforeExpanded={3}
                    />
                </div>
            </ModalBody>
            <ModalFooter
                rightButton={{
                    onClick: async () => callback(ordersWithActionAvailable.length ? "accepted" : "declined"),
                    text: ordersWithActionAvailable.length ? modalTitle : t("common.close"),
                }}
            />
        </BaseModal>
    )
}

export default ModalConfirmCaptureRefund
