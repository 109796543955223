import { ICompanyData, ICompanyFieldsBase, validationPatterns, validationPatternsWithLocale } from "swiipe.portal.shared"
import { MixedMerchantDetails } from "../type/mixedmerchantdetails"
import { getMerchantContact } from "./merchantService"

export function getAvailableCompanyInfo(
    company?: ICompanyData,
    companyDetails?: ICompanyFieldsBase,
    merchantDetails?: MixedMerchantDetails,
    companyType?: {
        companyType: string
        isValid: boolean
    }
) {
    const swMerchantContact = getMerchantContact(merchantDetails)

    const name = company?.companyName || companyDetails?.companyName || merchantDetails?.swMerchant.name
    const cvr = companyDetails?.companyNumber || merchantDetails?.swMerchant.registrationNumber
    const country = companyDetails?.companyCountry || merchantDetails?.swMerchant.countryCode || "DK"
    const address = company?.companyAddress || companyDetails?.companyAddress
    const postCode = company?.companyPostcode || companyDetails?.companyPostcode
    const city = company?.companyCity || companyDetails?.companyCity
    const phone = company?.companyPhone || companyDetails?.companyPhone || swMerchantContact?.phone
    const email = company?.companyEmail || swMerchantContact?.email

    const companyFields = {
        name: {
            defaultValue: name,
            isValid: !!name,
        },
        cvr: {
            defaultValue: cvr,
            isValid: cvr && validationPatterns.cvr.test(cvr),
        },
        type: {
            defaultValue: companyType?.companyType,
            isValid: !companyType || companyType.isValid, //if not specified than valid by default
        },
        country: {
            defaultValue: country,
            isValid: country && validationPatterns.countryISO2Code.test(country),
        },
        address: {
            defaultValue: address,
            isValid: address && validationPatterns.addressStreet.test(address),
        },
        postCode: {
            defaultValue: postCode,
            isValid: postCode && validationPatternsWithLocale.addressPostalCode(country).test(postCode),
        },
        city: {
            defaultValue: city,
            isValid: city && validationPatterns.addressCity.test(city),
        },
        phone: {
            defaultValue: phone,
            isValid: phone && validationPatterns.phoneNumber.test(phone),
        },
        email: {
            defaultValue: email,
            isValid: email && validationPatterns.email.test(email),
        },
    }

    const defaultValues: {
        [K in keyof typeof companyFields]?: (typeof companyFields)[K]["defaultValue"]
    } = {}
    for (const [fieldName, field] of Object.entries(companyFields)) {
        defaultValues[fieldName as keyof typeof companyFields] = field.defaultValue
    }

    return {
        allCompanyInfoAvailable: Object.values(companyFields).every((field) => field.isValid),
        defaultValues,
    }
}
