import { EInvoicingMarket } from "../types/invoicing/IInvoicingPrice"

export function mapCountryToMarket(country: string): EInvoicingMarket {
    switch (country.toUpperCase()) {
        case "DK":
            return "Denmark"
        case "SE":
            return "Sweden"
        default:
            return "EU"
    }
}
