import React from "react"
import { useTranslation } from "react-i18next"
import { StandardButton } from "swiipe.portal.shared"
import { navigationService } from "../../../../services/navigationService"
import {
    IDashboardExternalAnalytics,
    IMerchantDashboardServerData,
} from "../../../../type/merchantDashboard/IMerchantDashboardServerData"
import { getDaysBetween } from "../../../../util/dateUtil"
import MerchantDashboardSimpleValues from "../../tiles/MerchantDashboardTileSimpleValues"

interface IDashboardConversionDisplayProps {
    dashboardData: IMerchantDashboardServerData
    analyticsEnabled: boolean
    analyticsEnabledDate?: Date
}

const DashboardConversionDisplay = ({
    dashboardData,
    analyticsEnabled,
    analyticsEnabledDate,
}: IDashboardConversionDisplayProps) => {
    const { t } = useTranslation()

    const analytics = {
        main: dashboardData.mainData.externalAnalytics,
        compare: dashboardData.compareData?.externalAnalytics,
    }

    const hasAnyAnalyticsData = (data: IDashboardExternalAnalytics) => {
        return data.startedCheckoutsTotal > 0 || data.engagedSessionsTotal > 0
    }

    const getCheckoutConversionPercentage = (data: IDashboardExternalAnalytics) => {
        if (data.startedCheckoutsTotal === 0) {
            return 0
        }

        return (data.completedCheckoutsTotal / data.startedCheckoutsTotal) * 100
    }

    const getGeneralConversionPercentage = (data: IDashboardExternalAnalytics) => {
        if (data.engagedSessionsTotal === 0) {
            return 0
        }

        return (data.completedCheckoutsTotal / data.engagedSessionsTotal) * 100
    }

    const onboardedRecently = analyticsEnabled && analyticsEnabledDate && getDaysBetween(analyticsEnabledDate, new Date()) <= 3 // less than 3 days ago
    const hasAnyData = hasAnyAnalyticsData(analytics.main) || (analytics.compare && hasAnyAnalyticsData(analytics.compare))

    return (
        <MerchantDashboardSimpleValues
            title={t("merchantDashboard.extAnalytics.conversionTitle")}
            subtitle={t("merchantDashboard.extAnalytics.conversionSubTitle")}
            simpleValues={
                onboardedRecently && !hasAnyData
                    ? []
                    : [
                          {
                              value: {
                                  amount: getGeneralConversionPercentage(analytics.main),
                                  isPercent: true,
                              },
                              subtitle: t("merchantDashboard.extAnalytics.conversion.general"),
                          },
                          {
                              value: {
                                  amount: getCheckoutConversionPercentage(analytics.main),
                                  isPercent: true,
                              },
                              subtitle: t("merchantDashboard.extAnalytics.conversion.checkout"),
                          },
                      ]
            }
            compareValues={
                analytics.compare && [
                    {
                        value: {
                            amount: getGeneralConversionPercentage(analytics.compare),
                            isPercent: true,
                        },
                        subtitle: "",
                    },
                    {
                        value: {
                            amount: getCheckoutConversionPercentage(analytics.compare),
                            isPercent: true,
                        },
                        subtitle: "",
                    },
                ]
            }
            notEnabled={!analyticsEnabled}
            noDataText={
                (!analyticsEnabled && t("merchantDashboard.extAnalytics.notEnabledText")) ||
                t("merchantDashboard.extAnalytics.awaitingDataText")
            }
            overlayContent={
                (!analyticsEnabled && (
                    <div className="service-activation-container bind-right">
                        <StandardButton
                            isSmall
                            title={t("merchantDashboard.extAnalytics.connectButtonText")}
                            onClick={() => navigationService.navigate("/analytics_onboard")}
                        />
                    </div>
                )) ||
                undefined
            }
        />
    )
}

export default DashboardConversionDisplay
