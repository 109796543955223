import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { StandardButtonWithSpinner } from "swiipe.portal.shared"
import IconSavedStandard from "../../images/icons/ok-green.png"

interface ISaveButton {
    saveAction: () => Promise<void>
    iconSaved?: string
    savedText?: string
    buttonText?: string
    savedMsgClassName?: string
    btnContainerClassName?: string
    isWide?: boolean
}
export const SaveButton = ({
    saveAction,
    iconSaved,
    savedText,
    buttonText,
    savedMsgClassName,
    btnContainerClassName,
    isWide,
}: ISaveButton) => {
    const [saved, setSaved] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        if (!saved) {
            return
        }

        setTimeout(() => {
            setSaved(false)
        }, 2500)
    }, [saved])

    return (
        <div className="sw-save-button">
            {saved ? (
                <div className={cn("sw-save-button__saved-msg", savedMsgClassName)}>
                    <img src={iconSaved ?? IconSavedStandard} />
                    {savedText ?? t("common.saved")}
                </div>
            ) : (
                <StandardButtonWithSpinner
                    isWide={isWide}
                    containerClass={btnContainerClassName}
                    onClick={async (setShowSpinner, e) => {
                        e.preventDefault()
                        setShowSpinner(true)

                        await saveAction()

                        setShowSpinner(false)
                        setSaved(true)
                    }}
                    inverted
                >
                    {buttonText ?? t("common.save")}
                </StandardButtonWithSpinner>
            )}
        </div>
    )
}
