import React from "react"
import { IPaymentMethod } from "swiipe.portal.shared"
import { getFormattedCardExpiryYear, getFormattedCardNumber, getLogoByCardType } from "../../../services/paymentService"
import { deleteUserPaymentMethodThunk } from "../../../store/thunks/userPersonalInfoThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import InfoBoxEditor, { TSetShowSpinnerFunc } from "../../infobox/InfoBoxEditor"
import { InfoBoxState } from "../../infobox/InfoBoxState"

interface ICreditCardEditor {
    paymentMethod: IPaymentMethod
    cardNumberInEditMode: string | undefined
    onSwitchEditMode: (cardNumber: string, isInEditMode: boolean) => void
}

const CreditCardEditor = ({ paymentMethod, cardNumberInEditMode, onSwitchEditMode }: ICreditCardEditor) => {
    const pm = paymentMethod.creditCard
    const dispatch = useReduxDispatch()

    if (!pm) {
        return null
    }

    return (
        <InfoBoxEditor
            key={pm.maskedAccount}
            state={InfoBoxState.DELETEONLY}
            title=""
            isInEditMode={cardNumberInEditMode === pm.maskedAccount}
            editBtnTextType="editing"
            renderPreview={() => (
                <div className="card-previev-container">
                    <img className="card-logo" src={getLogoByCardType(pm.cardType)} />
                    <br />
                    <span className="card-number">{getFormattedCardNumber(pm.maskedAccount)}</span>
                    <br />
                    <span className="card-date">{`${pm.expiryMonth}/${getFormattedCardExpiryYear(pm.expiryYear)}`}</span>
                </div>
            )}
            renderFormFields={(register) => (
                <div className="card-previev-container form-fields">
                    <img className="card-logo" src={getLogoByCardType(pm.cardType)} />
                    <br />
                    <span className="card-number">{getFormattedCardNumber(pm.maskedAccount)}</span>
                </div>
            )}
            noSave
            allowDelete
            onDelete={async (setShowSpinner: TSetShowSpinnerFunc) => {
                await dispatch(deleteUserPaymentMethodThunk(paymentMethod.paymentId))
                setShowSpinner(false)
            }}
            onSwitchEditMode={(isInEditMode) => onSwitchEditMode(pm.maskedAccount, isInEditMode)}
        />
    )
}

export default CreditCardEditor
