import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    FieldsetFormGroup,
    FloatingLabelInput,
    ShowErrorMessages,
    useSelector,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { salesSelectors } from "../../../store/reducers/salesReducer"
import { updateSalesTermsLinkThunk } from "../../../store/thunks/salesSettingsThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IUpdateSalesTermsFormModel } from "../../../type/sales/IUpdateSalesTermsFormModel"
import SubmitButton from "../../buttons/SubmitButton"
import ContentRightButtonContainer from "../../layouts/ContentRightButtonContainer"

interface ISalesTermsLinkFormProps {}

const SalesTermsLinkForm = ({}: ISalesTermsLinkFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const { handleSubmit, formState, register } = useForm<IUpdateSalesTermsFormModel>()

    const settings = useSelector(salesSelectors.settings)

    const onSubmit: SubmitHandler<IUpdateSalesTermsFormModel> = async (data, e) => {
        try {
            await dispatch(updateSalesTermsLinkThunk(data))
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <ContentRightButtonContainer button={<SubmitButton isSmall formState={formState} title={t("common.save")} />}>
                <FieldsetFormGroup field>
                    <FloatingLabelInput
                        defaultValue={settings ? settings.termsLink : ""}
                        name="TermsLink"
                        innerRef={register(
                            valFuncRequiredAndPattern(
                                validationPatterns.salesTermsUrl,
                                t("offersettings.validationerrors.termsLink")
                            )
                        )}
                        placeholder={t("offersettings.terms.inputPlaceholder")}
                    />
                </FieldsetFormGroup>
            </ContentRightButtonContainer>
            <ShowErrorMessages formState={formState} />
        </Form>
    )
}

export default SalesTermsLinkForm
