import React from "react"
import { useTranslation } from "react-i18next"
import { Row } from "reactstrap"
import {
    FieldsetFormGroup,
    FloatingLabelInput,
    PhoneNumberInput,
    TRegister,
    TSetValue,
    TWatch,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { SwMerchantContactDto } from "../../../type/SwMerchantMainContactDto"
import EditFormInput from "../input/EditFormInput"

interface MerchantContactInfoFields {
    contact?: SwMerchantContactDto
    register: TRegister
    setValue: TSetValue<SwMerchantContactDto>
    getValues: () => any
    watch: TWatch<any>
}

const MerchantContactInfoFields = ({ contact, register, setValue, getValues, watch }: MerchantContactInfoFields) => {
    const { t } = useTranslation()

    return (
        <div className="edit-form">
            <Row>
                <div className="col-12 pl-3 col-md-6">
                    <EditFormInput
                        defaultValue={contact?.firstName || ""}
                        name="firstName"
                        innerRef={register(
                            valFuncRequiredAndPattern(validationPatterns.firstName, t("personal.validationerrors.firstName"))
                        )}
                        placeholder={t("personal.firstName")}
                    />
                </div>
                <div className="col-12 pl-3 col-md-6 pl-md-0">
                    <EditFormInput
                        defaultValue={contact?.lastName || ""}
                        name="lastName"
                        innerRef={register(
                            valFuncRequiredAndPattern(validationPatterns.lastName, t("personal.validationerrors.lastName"))
                        )}
                        placeholder={t("personal.lastName")}
                    />
                </div>
            </Row>
            <FieldsetFormGroup field>
                <PhoneNumberInput<SwMerchantContactDto>
                    inputName="phone"
                    defaultValue={contact?.phone}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    watch={watch}
                />
            </FieldsetFormGroup>
            <EditFormInput
                defaultValue={contact?.email || ""}
                name="email"
                innerRef={register(valFuncRequiredAndPattern(validationPatterns.email, t("personal.validationerrors.email")))}
                placeholder={t("common.email.label")}
            />
            <FieldsetFormGroup field>
                <FloatingLabelInput
                    name="comment"
                    type="textarea"
                    defaultValue={contact?.comment || ""}
                    placeholder={t("personal.commentOptional")}
                    innerRef={register}
                ></FloatingLabelInput>
            </FieldsetFormGroup>
        </div>
    )
}

export default MerchantContactInfoFields
