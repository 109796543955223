import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { TextButton, getQueryValueFromCurrentUrl } from "swiipe.portal.shared"
import { navigationService } from "../../services/navigationService"
import { IClearhausApplicationData } from "../../type/clearhaus/IClearhausApplicationData"
import "./ClearhausUpdateInfoButton.scss"

interface IClearhausUpdateInfoButtonProps {
    applicationData?: IClearhausApplicationData
    customUpdateInfoText?: string
    isInsideForm?: boolean
}

export const ClearhausUpdateInfoButton = ({
    applicationData,
    customUpdateInfoText,
    isInsideForm = true,
}: IClearhausUpdateInfoButtonProps) => {
    const { t } = useTranslation()
    const isPendingUpdate = applicationData?.isPendingUpdate
    return (
        <div className={cn("clearhaus-update-info", isInsideForm && "clearhaus-update-info--inside-form")}>
            <TextButton
                className="clearhaus-update-info__main-btn"
                display="inline-block"
                text={
                    isPendingUpdate
                        ? t("clearhausUpdateInfo.updateInfoButton.pendingChanges")
                        : customUpdateInfoText || t("clearhausUpdateInfo.updateInfoButton.updateInfo")
                }
                textDecoration={(!isPendingUpdate && "underline") || undefined}
                fontStyle={(isPendingUpdate && "italic") || undefined}
                onClick={async () => {
                    if (isPendingUpdate) {
                        return
                    }
                    navigationService.navigate(`/clearhaus_update_info?webshopId=${getQueryValueFromCurrentUrl("webshopId")}`)
                }}
            />
        </div>
    )
}
