import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { StandardButton, TextButton } from "swiipe.portal.shared"
import ServicesOnboardingPageWrapper from "./ServicesOnboardingPageWrapper"

export interface INoteDialog {
    children: React.ReactNode
    onGoBack: () => void
    onGoNext: () => void
}

export const NoteDialog = ({ children, onGoBack, onGoNext }: INoteDialog) => {
    const { t } = useTranslation()
    return (
        <ServicesOnboardingPageWrapper>
            {children}

            <StandardButton
                dark
                noBorder
                noBorderRadius
                className="mt-5"
                onClick={() => {
                    onGoNext()
                }}
            >
                {t("clearhausonboarding.buttons.continue")}
            </StandardButton>

            <TextButton text={t("commononboardingtexts.goback")} className={cn("mt-3 mb-2")} onClick={async () => onGoBack()} />
        </ServicesOnboardingPageWrapper>
    )
}

export default NoteDialog
