import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { TitleWithAbsoluteHint, getCulture, useSelector } from "swiipe.portal.shared"
import { StoreState } from "../../../../store/StoreState"
import { merchantDashboardSelectors } from "../../../../store/reducers/merchantDashboardReducer"
import { getDashboardUpdatedDateThunk } from "../../../../store/thunks/merchantDashboardThunks"
import { useReduxDispatch } from "../../../../store/useReduxDispatch"
import "./MerchantDashboardLatestReportDate.scss"

interface ILatestReportDateProps {
    selectedWebshopId: string | undefined
    paymentsEnabled: boolean
}
const MerchantDashboardLatestReportDate = ({ selectedWebshopId, paymentsEnabled }: ILatestReportDateProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const latestReportDate = useSelector((state: StoreState) =>
        merchantDashboardSelectors.latestReportDate(state, selectedWebshopId)
    )

    useEffect(() => {
        if (!selectedWebshopId || !paymentsEnabled) {
            return
        }

        dispatch(getDashboardUpdatedDateThunk(selectedWebshopId))
    }, [selectedWebshopId, paymentsEnabled])

    if (!latestReportDate) {
        return null
    }

    return (
        <div className="merchant-dashboard-header-latest-report-date">
            <TitleWithAbsoluteHint
                title={`${t("merchantDashboard.lastUpdated")} ${latestReportDate.toLocaleDateString(getCulture(), {
                    dateStyle: "long",
                })}`}
                className="merchant-dashboard-header-latest-report-date__title"
                titleLight
                hintText={t("merchantDashboard.lastUpdatedHint")}
                iconLightGray
                hintBoxWhite
                hintAppearanceBottom
                hintIconRight
            />
        </div>
    )
}

export default MerchantDashboardLatestReportDate
