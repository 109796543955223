import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    PageWrapper,
    StandardButtonWithSpinner,
    TStandardButtonSetShowSpinnerFunc,
    getCurrentCurrencyTitle,
    getFormattedNumber,
    useSelector,
} from "swiipe.portal.shared"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { cancelSalesOfferThunk, ensureSalesOfferHistoryThunk } from "../../store/thunks/salesOfferThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { ISalesOffer } from "../../type/sales/ISalesOffer"
import { salesSelectors } from "./../../store/reducers/salesReducer"
import { getBreakPointIsUpToMedium } from "./../../util/breakPointUtil"
import "./SalesOfferHistoryPage.scss"

const StatusField = ({ offer }: { offer: ISalesOffer }) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    return (
        <td className="nowrap position-relative">
            {!offer.offerCancelled && !offer.offerCompleted && <span>{t("offerhistory.statePending")}</span>}
            {offer.offerCompleted && <span>{t("offerhistory.stateCompleted")}</span>}
            {offer.offerCancelled && offer.cancelledByManager && <span>{t("offerhistory.stateCancelled")}</span>}
            {offer.offerCancelled && !offer.cancelledByManager && (
                <>
                    <span>{t("offerhistory.stateRefused")}</span>
                    <span
                        className="info-icon"
                        onClick={() => {
                            if (getBreakPointIsUpToMedium()) {
                                dispatch(
                                    addModalThunk({
                                        type: "salesHistoryInfo",
                                        title: `${t("offerhistory.offerNo")}${offer.orderID}: ${t("offerhistory.stateRefused")}`,
                                        reason: offer.reasonCancelled,
                                        fullName: offer.fullName,
                                    })
                                )
                            }
                        }}
                    ></span>
                    <div className="reason-refused">
                        <span className="text font-italic">&quot;{offer.reasonCancelled}&quot;</span>
                        <span className="name">{offer.fullName}</span>
                    </div>
                </>
            )}
        </td>
    )
}

const ActionField = ({ offer }: { offer: ISalesOffer }) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    return (
        <td>
            {!offer.offerCancelled && !offer.offerCompleted && (
                <StandardButtonWithSpinner
                    title={t("offerhistory.allTab.cancelButtonTitle")}
                    isDelete
                    inverted
                    isTiny
                    onClick={async (setShowSpinner: TStandardButtonSetShowSpinnerFunc) => {
                        setShowSpinner(false)
                        const response = await dispatch(
                            addModalThunk({
                                type: "salesHistoryCancel",
                                title: `${t("offerhistory.offerNo")}${offer.orderID}: ${offer.productName}`,
                            })
                        )
                        if (response.type === "accepted") {
                            setShowSpinner(true)
                            await dispatch(cancelSalesOfferThunk(offer.id, "", true, true))
                        }
                    }}
                />
            )}
        </td>
    )
}

const TabAll = ({ offers }: { offers: ISalesOffer[] }) => {
    const { t } = useTranslation()
    return (
        <table className="table table-responsive-md">
            <thead>
                <tr>
                    <th scope="col">{t("offerhistory.allTab.colOrderId")}</th>
                    <th scope="col">{t("offerhistory.allTab.colProduct")}</th>
                    <th scope="col">{t("offerhistory.allTab.colAmount", { currency: getCurrentCurrencyTitle() })}</th>
                    <th scope="col">{t("offerhistory.allTab.colRecipient")}</th>
                    <th scope="col">{t("offerhistory.allTab.colDate")}</th>
                    <th scope="col">{t("offerhistory.allTab.colStatus")}</th>
                    <th scope="col">{t("offerhistory.allTab.colAgent")}</th>
                    <th scope="col">{t("offerhistory.allTab.colActions")}</th>
                </tr>
            </thead>
            <tbody>
                {offers.map((offer: ISalesOffer) => {
                    return (
                        <tr key={offer.orderID}>
                            <td>{offer.orderID}</td>
                            <td>{offer.productName}</td>
                            <td>{getFormattedNumber(offer.amount)}</td>
                            <td className="nowrap">{offer.phone}</td>
                            <td>{offer.date}</td>
                            <StatusField offer={offer} />
                            <td>{offer.agentName}</td>
                            <ActionField offer={offer} />
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

const TabCompleted = ({ offers }: { offers: ISalesOffer[] }) => {
    const { t } = useTranslation()
    return (
        <table className="table table-responsive-md">
            <thead>
                <tr>
                    <th scope="col">{t("offerhistory.completedTab.colOrderId")}</th>
                    <th scope="col">{t("offerhistory.completedTab.colProduct")}</th>
                    <th scope="col">{t("offerhistory.completedTab.colAmount", { currency: getCurrentCurrencyTitle() })}</th>
                    <th scope="col">{t("offerhistory.completedTab.colRecipient")}</th>
                    <th scope="col">{t("offerhistory.completedTab.colDate")}</th>
                </tr>
            </thead>
            <tbody>
                {offers.map((offer: ISalesOffer) => {
                    return (
                        <tr key={offer.orderID}>
                            <td>{offer.orderID}</td>
                            <td>{offer.productName}</td>
                            <td>{getFormattedNumber(offer.amount)}</td>
                            <td className="nowrap">{offer.phone}</td>
                            <td>{offer.date}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

const TabPending = ({ offers }: { offers: ISalesOffer[] }) => {
    const { t } = useTranslation()
    return (
        <table className="table table-responsive-md">
            <thead>
                <tr>
                    <th scope="col">{t("offerhistory.pendingTab.colOrderId")}</th>
                    <th scope="col">{t("offerhistory.pendingTab.colProduct")}</th>
                    <th scope="col">{t("offerhistory.pendingTab.colAmount", { currency: getCurrentCurrencyTitle() })}</th>
                    <th scope="col">{t("offerhistory.pendingTab.colRecipient")}</th>
                    <th scope="col">{t("offerhistory.pendingTab.colDate")}</th>
                    <th scope="col">{t("offerhistory.pendingTab.colStatus")}</th>
                    <th scope="col">{t("offerhistory.pendingTab.colAgent")}</th>
                    <th scope="col">{t("offerhistory.pendingTab.colActions")}</th>
                </tr>
            </thead>
            <tbody>
                {offers.map((offer: ISalesOffer) => {
                    return (
                        <tr key={offer.orderID}>
                            <td>{offer.orderID}</td>
                            <td>{offer.productName}</td>
                            <td>{getFormattedNumber(offer.amount)}</td>
                            <td className="nowrap">{offer.phone}</td>
                            <td>{offer.date}</td>
                            <StatusField offer={offer} />
                            <td>{offer.agentName}</td>
                            <ActionField offer={offer} />
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

interface ISalesOfferHistoryPageProps {}

const SalesOfferHistoryPage = ({}: ISalesOfferHistoryPageProps) => {
    useRequiredUserRelations([{ requiredType: "CallCenter" }])
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const [currentTab, setCurrentTab] = useState("pending")

    useEffect(() => {
        dispatch(ensureSalesOfferHistoryThunk(false))
    }, [])

    const offers = useSelector(salesSelectors.historyOffers)
    if (!offers) {
        return null
    }

    return (
        <PageWrapper fullWidth>
            <div id="offerhistory">
                <div className="col-lg-12 mt-5 px-0 buttons-container">
                    <div
                        className={cn("btn-switch", currentTab === "pending" && "active")}
                        onClick={() => setCurrentTab("pending")}
                    >
                        {t("offerhistory.pendingTab.tabTitle")}
                    </div>
                    <div
                        className={cn("btn-switch", currentTab === "completed" && "active")}
                        onClick={() => setCurrentTab("completed")}
                    >
                        {t("offerhistory.completedTab.tabTitle")}
                    </div>
                    <div className={cn("btn-switch", currentTab === "all" && "active")} onClick={() => setCurrentTab("all")}>
                        {t("offerhistory.allTab.tabTitle")}
                    </div>
                </div>
                <div className="col-lg-12 bg-white mx-auto table-container py-4 px-2">
                    {currentTab === "pending" && (
                        <TabPending offers={offers.filter((o) => !o.offerCompleted && !o.offerCancelled)} />
                    )}
                    {currentTab === "completed" && <TabCompleted offers={offers.filter((o) => o.offerCompleted)} />}
                    {currentTab === "all" && <TabAll offers={offers} />}
                </div>
            </div>
        </PageWrapper>
    )
}

export default SalesOfferHistoryPage
