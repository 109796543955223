import { ActionsUnion, createAction } from "swiipe.portal.shared"
import { PaymentLinkDto, PaymentLinksFilter } from "../../type/PaymentLink"
import { StoreState } from "../StoreState"

export type PaymentLinksState = Readonly<{
    webshops: {
        [webshopId: string]: PaymentLinks
    }
    paymentLinksFilter: PaymentLinksFilter
}>

type PaymentLinks = Readonly<{
    continuationToken?: string
    paymentLinks: PaymentLinkDto[]
}>

const initialState: PaymentLinksState = {
    webshops: {},
    paymentLinksFilter: {},
}

export const paymentLinksReducerActions = {
    setPaymentLinks: (
        webshopId: string,
        paymentLinks: PaymentLinkDto[],
        replacePaymentLinks: boolean,
        continuationToken?: string
    ) => createAction("APP/SET_PAYMENT_LINKS_PER_WEBSHOP", { webshopId, paymentLinks, replacePaymentLinks, continuationToken }),
    setPaymentLinksFilter: (paymentLinksFilter: PaymentLinksFilter) =>
        createAction("APP/SET_PAYMENT_LINKS_FILTER", { paymentLinksFilter }),
}

export type PaymentLinksActions = ActionsUnion<typeof paymentLinksReducerActions>

export const paymentLinksReducer = (state: PaymentLinksState = initialState, action: PaymentLinksActions) => {
    switch (action.type) {
        case "APP/SET_PAYMENT_LINKS_PER_WEBSHOP": {
            return {
                ...state,
                webshops: {
                    ...(state.webshops || {}),
                    [action.webshopId]: {
                        continuationToken: action.continuationToken,
                        paymentLinks: action.replacePaymentLinks
                            ? action.paymentLinks
                            : [...(state.webshops[action.webshopId]?.paymentLinks || []), ...action.paymentLinks],
                    },
                },
            }
        }
        case "APP/SET_PAYMENT_LINKS_FILTER": {
            return {
                ...state,
                webshops: {},
                paymentLinksFilter: action.paymentLinksFilter,
            }
        }
    }

    return state
}

export const paymentLinksSelectors = {
    getPaymentLinksPerMerchant: (state: StoreState, webshopId: string) =>
        state.paymentLinks.webshops[webshopId] && state.paymentLinks.webshops[webshopId]?.paymentLinks
            ? state.paymentLinks.webshops[webshopId]?.paymentLinks
            : undefined,
    getContinuationTokenPerMerchant: (state: StoreState, webshopId: string) =>
        state.paymentLinks.webshops[webshopId] && state.paymentLinks.webshops[webshopId]?.continuationToken,
    getPaymentLinksFilter: (state: StoreState) => state.paymentLinks.paymentLinksFilter,
}
