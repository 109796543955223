import { ActionsUnion, createAction } from "swiipe.portal.shared"
import { IPaymentMethodRelation, TPaymentMethodRelation } from "../../type/paymentMethod/IPaymentMethodRelation"
import { StoreState } from "../StoreState"

export type PaymentMethodState = Readonly<{
    paymentMethodRelations: { [organizationId_relationType: string]: IPaymentMethodRelation[] }
}>

const initialState: PaymentMethodState = {
    paymentMethodRelations: {},
}

export const paymentMethodReducerActions = {
    setPaymentMethodRelations: (
        organizationId: string,
        relationType: TPaymentMethodRelation,
        paymentMethodRelations: IPaymentMethodRelation[]
    ) =>
        createAction("APP/SET_PAYMENT_METHOD_RELATIONS", {
            organizationId,
            relationType,
            paymentMethodRelations,
        }),
}

export type PaymentMethodActions = ActionsUnion<typeof paymentMethodReducerActions>

export const paymentMethodReducer = (state: PaymentMethodState = initialState, action: PaymentMethodActions) => {
    switch (action.type) {
        case "APP/SET_PAYMENT_METHOD_RELATIONS": {
            return {
                ...state,
                paymentMethodRelations: {
                    ...state.paymentMethodRelations,
                    [action.organizationId + "_" + action.relationType]: action.paymentMethodRelations,
                },
            }
        }
    }

    return state
}

export const paymentMethodSelectors = {
    paymentMethodRelations: (state: StoreState, relationType: TPaymentMethodRelation) =>
        state.userRelation.currentRelation
            ? state.paymentMethod.paymentMethodRelations[state.userRelation.currentRelation.id + "_" + relationType]
            : undefined,
}
