import cn from "classnames"
import React, { useEffect } from "react"
import { Control, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { IFormBase } from "../../../types/form/IFormBase"
import { valFuncRequired } from "../../../utils/validationUtil"
import { FieldsetFormGroup } from "../../form/FieldsetFormGroup"
import { DropDownList, IDropdownOption } from "../../form/input/DropDownList"
import { FloatingLabelInput } from "../../form/input/FloatingLabelInput"
import { FormGroupWithCheckbox } from "../../form/input/FormGroupWithCheckbox"
import { CompletedFieldsReview } from "../../form/review/CompletedFieldsReview"
import { StandardParagraph } from "../../text/StandardParagraph"
import { TitleWithAbsoluteHint } from "../../text/TitleWithAbsoluteHint"
import "./AcquiringBusinessModelFields.scss"

export interface IAcquiringBusinessModelSubForm<TDeliveryDelay> {
    description: string
    tradeName: string
    sellSubscription: boolean
    sellPhysical: boolean
    deliveryDelay: TDeliveryDelay
    delayComment: string
    useDropShipping: boolean
}

interface IBusinessModelFieldsProps<TForm extends IFormBase, TDeliveryDelay extends string> {
    control: Control<TForm>
    isSubmitted: boolean
    showMainInput: boolean
    showDetailInput: boolean
    getFieldsFromControl: (form: TForm | undefined) => IAcquiringBusinessModelSubForm<TDeliveryDelay> | undefined
    savedFields: IAcquiringBusinessModelSubForm<TDeliveryDelay> | undefined
    fieldSelectorPrefix: string
    deliveryDelayOptions: IDropdownOption<TDeliveryDelay>[]
    deliveryDelaysRequiringComment: TDeliveryDelay[]
}

export const AcquiringBusinessModelFields = <TForm extends IFormBase, TDeliveryDelay extends string>({
    control,
    getFieldsFromControl,
    savedFields,
    fieldSelectorPrefix,
    isSubmitted,
    showMainInput,
    showDetailInput,
    deliveryDelayOptions,
    deliveryDelaysRequiringComment,
}: IBusinessModelFieldsProps<TForm, TDeliveryDelay>) => {
    const { t } = useTranslation()
    const currentFields = getFieldsFromControl(control.getValues() as TForm)

    const getFieldSelector = (fieldName: string) => fieldSelectorPrefix + fieldName

    useWatch({
        control,
        name: [getFieldSelector("sellPhysical"), getFieldSelector("deliveryDelay")],
    })

    useEffect(() => {
        control.trigger()
    }, [currentFields?.deliveryDelay])

    const savedDeliveryDelayOption = deliveryDelayOptions.find((o) => o.value === savedFields?.deliveryDelay)

    return (
        <div className="acquiring-business-model-fields">
            {isSubmitted && (
                <CompletedFieldsReview>
                    {savedFields?.description}
                    <br />
                    {t("acquiringOnboarding.common.tradeName.inputPlaceholder")}: {savedFields?.tradeName}
                    <br />
                    {savedFields?.sellSubscription && (
                        <>
                            <br />
                            {t("acquiringOnboarding.common.subscriptions.checkboxText")}
                            <br />
                        </>
                    )}
                    {savedFields?.sellPhysical && (
                        <>
                            {t("acquiringOnboarding.common.physical.checkboxText")}
                            <br />
                        </>
                    )}
                    {savedDeliveryDelayOption?.text && (
                        <>
                            {savedDeliveryDelayOption?.text}
                            <br />
                        </>
                    )}
                    {savedFields?.useDropShipping && (
                        <>
                            {t("acquiringOnboarding.common.dropshipping.checkboxText")}
                            <br />
                        </>
                    )}
                    {savedFields?.delayComment}
                </CompletedFieldsReview>
            )}
            {!isSubmitted && (
                <>
                    <div className="acquiring-business-model-fields__fields">
                        <div className="acquiring-business-model-fields__fields-content">
                            <div className={cn({ "d-none": !showMainInput, "mb-4": showMainInput && showDetailInput })}>
                                <StandardParagraph>
                                    {t("acquiringOnboarding.common.businessModel.describe.pleaseDescribe")}
                                    <br />
                                    {t("acquiringOnboarding.common.businessModel.describe.forExample")}:{" "}
                                    <i>{t("acquiringOnboarding.common.businessModel.describe.example")}</i>
                                </StandardParagraph>
                                <FieldsetFormGroup className="mb-0" field>
                                    <FloatingLabelInput
                                        name={getFieldSelector("description")}
                                        type="textarea"
                                        placeholder={t("acquiringOnboarding.common.businessModel.inputPlaceholder")}
                                        innerRef={control.register(
                                            valFuncRequired(t("acquiringOnboarding.common.businessModel.errorMessage"))
                                        )}
                                    ></FloatingLabelInput>
                                </FieldsetFormGroup>
                            </div>

                            {showDetailInput && (
                                <>
                                    {!showMainInput && (
                                        <>
                                            {(currentFields?.description || savedFields?.description) && (
                                                <CompletedFieldsReview>
                                                    {currentFields?.description || savedFields?.description}
                                                </CompletedFieldsReview>
                                            )}
                                        </>
                                    )}
                                    <FieldsetFormGroup field>
                                        <FloatingLabelInput
                                            placeholder={t("acquiringOnboarding.common.tradeName.inputPlaceholder")}
                                            hintInsideInput={t("acquiringOnboarding.common.tradeName.hint")}
                                            className="mt-2"
                                            name={getFieldSelector("tradeName")}
                                            type="text"
                                            innerRef={control.register(
                                                valFuncRequired(t("acquiringOnboarding.common.tradeName.errorMessage"))
                                            )}
                                        ></FloatingLabelInput>
                                    </FieldsetFormGroup>
                                    <FormGroupWithCheckbox
                                        innerRef={control.register()}
                                        name={getFieldSelector("sellSubscription")}
                                        hint={t("acquiringOnboarding.common.subscriptions.hintText")}
                                    >
                                        <StandardParagraph className="my-0 d-inline-block">
                                            {t("acquiringOnboarding.common.subscriptions.checkboxText")}
                                        </StandardParagraph>
                                    </FormGroupWithCheckbox>
                                    <FormGroupWithCheckbox
                                        className="mb-3"
                                        innerRef={control.register()}
                                        name={getFieldSelector("sellPhysical")}
                                        hint={t("acquiringOnboarding.common.physical.hintText")}
                                    >
                                        <StandardParagraph className="my-0 d-inline-block">
                                            {t("acquiringOnboarding.common.physical.checkboxText")}
                                        </StandardParagraph>
                                    </FormGroupWithCheckbox>
                                    {currentFields?.sellPhysical && (
                                        <div>
                                            <FieldsetFormGroup field>
                                                <DropDownList
                                                    placeholder={t("acquiringOnboarding.clearhaus.deliveryDelayOptions.label")}
                                                    name={getFieldSelector("deliveryDelay")}
                                                    options={deliveryDelayOptions}
                                                    innerRef={control.register(
                                                        valFuncRequired(
                                                            t("acquiringOnboarding.clearhaus.deliveryDelayOptions.label")
                                                        )
                                                    )}
                                                />
                                            </FieldsetFormGroup>
                                            <StandardParagraph className="mt-4">
                                                {t("acquiringOnboarding.clearhaus.deliveryDelayOptions.desc")}
                                            </StandardParagraph>

                                            <FieldsetFormGroup field>
                                                <FloatingLabelInput
                                                    name={getFieldSelector("delayComment")}
                                                    type="textarea"
                                                    placeholder={t("acquiringOnboarding.common.comments.inputPlaceholder")}
                                                    innerRef={control.register({
                                                        validate: {
                                                            required: (value) =>
                                                                !value &&
                                                                deliveryDelaysRequiringComment.includes(
                                                                    currentFields?.deliveryDelay
                                                                )
                                                                    ? t(
                                                                          "acquiringOnboarding.clearhaus.deliveryDelayComment.errorMessage"
                                                                      )
                                                                    : undefined,
                                                        },
                                                    })}
                                                ></FloatingLabelInput>
                                            </FieldsetFormGroup>
                                            <FormGroupWithCheckbox
                                                className="mb-4"
                                                innerRef={control.register()}
                                                name={getFieldSelector("useDropShipping")}
                                            >
                                                <StandardParagraph className="my-0 d-inline-block">
                                                    {t("acquiringOnboarding.common.dropshipping.checkboxText")}
                                                </StandardParagraph>
                                            </FormGroupWithCheckbox>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div>
                        {showDetailInput && (
                            <TitleWithAbsoluteHint
                                className="acquiring-business-model-fields__info-hint"
                                titleClassName="acquiring-business-model-fields__hint-title"
                                title={t("acquiringOnboarding.common.whyDoWeNeedInfo")}
                                hintText={t("acquiringOnboarding.common.whyInfoDesc.business")}
                            />
                        )}

                        <TitleWithAbsoluteHint
                            className="acquiring-business-model-fields__info-hint"
                            titleClassName="acquiring-business-model-fields__hint-title"
                            title={t("acquiringOnboarding.common.whyInfoDesc.regulatedProductsHeader")}
                            hintText={t("acquiringOnboarding.common.whyInfoDesc.regulatedProducts")}
                        />
                    </div>
                </>
            )}
        </div>
    )
}
