import MagentoLogo from "../images/platforms/magento-logo.png"
import Magento2Logo from "../images/platforms/magento2-logo.png"
import PrestashopLogo from "../images/platforms/prestashop-logo.png"
import WooCommerceLogo from "../images/platforms/woocommerce.png"
import { allPlatforms, TPlatformValue } from "../types/webshop/webshopdetails"

export interface IPlatform {
    text: string
    value: TPlatformValue
}

const platforms: IPlatform[] = [
    { text: "WooCommerce", value: "woocommerce" },
    { text: "Magento", value: "magento" },
    { text: "Magento 2", value: "magento2" },
    { text: "Prestashop", value: "prestashop" },
]

export function getAvailablePlatforms() {
    return platforms
}

export function getPlatformLogo(platform: TPlatformValue | undefined) {
    if (!platform) {
        return undefined
    }

    switch (platform) {
        case "woocommerce":
            return WooCommerceLogo
        case "magento":
            return MagentoLogo
        case "magento2":
            return Magento2Logo
        case "prestashop":
            return PrestashopLogo
    }
}

export function isPlatform(platform: string): boolean {
    return allPlatforms.map((p) => p + "").includes(platform)
}

export function getPlatformName(platform: TPlatformValue) {
    switch (platform) {
        case "woocommerce":
            return "WooCommerce"
        case "magento":
            return "Magento"
        case "magento2":
            return "Magento 2"
        case "prestashop":
            return "Prestashop"
    }
}

export function getPlatformShortName(platform: TPlatformValue) {
    switch (platform) {
        case "woocommerce":
            return "Woo"
        case "magento":
            return "Mag1"
        case "magento2":
            return "Mag2"
        case "prestashop":
            return "Presta"
    }
    return undefined
}

export function getPlatformColor(platform: TPlatformValue) {
    switch (platform) {
        case "woocommerce":
            return "plum"
        case "magento":
            return "lightsalmon"
        case "magento2":
            return "darkorange"
        case "prestashop":
            return "mediumvioletred"
    }
    return undefined
}
