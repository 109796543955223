import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector, userSelectors } from "swiipe.portal.shared"
import "./MerchantDashboardGreeting.scss"

const MerchantDashboardGreeting = () => {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.userData)
    const userName = user?.user.firstName ?? user?.user.fullName ?? ""

    return (
        <div className="merchant-dashboard-header-greeting" data-html2canvas-ignore>
            {userName ? t("merchantDashboard.greetingUser", { user: userName }) : t("merchantDashboard.greetingNoUser")}
        </div>
    )
}

export default MerchantDashboardGreeting
