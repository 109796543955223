import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { StandardButton, TextButton } from "swiipe.portal.shared"
import { openContactUsWidget } from "../../services/contactUsService"
import { navigationService } from "../../services/navigationService"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import ServicesOnboardingPageWrapper from "./ServicesOnboardingPageWrapper"

export interface IApplicationSubmittedDialog {
    firstLine: string
    secondLine: string
    logo?: string
    logoWidth?: string
    disableHelp?: boolean
    showCloseBtn?: boolean
    overrideSubmitText?: string
    overrideSubmitAction?: () => void
}

export const ApplicationSubmittedDialog = ({
    firstLine,
    secondLine,
    logo,
    logoWidth,
    disableHelp,
    showCloseBtn,
    overrideSubmitText,
    overrideSubmitAction,
}: IApplicationSubmittedDialog) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    return (
        <ServicesOnboardingPageWrapper customLogo={logo} customLogoWidth={logoWidth}>
            <>
                <p className="text-thin text-center">{t(firstLine)}</p>

                <p className="text-thin text-center">{t(secondLine)}</p>

                <StandardButton
                    dark
                    noBorder
                    noBorderRadius
                    className="mt-5"
                    onClick={() => {
                        if (overrideSubmitAction) {
                            overrideSubmitAction()

                            return
                        }

                        navigationService.navigate("/plugins")
                    }}
                >
                    {overrideSubmitText ? t(overrideSubmitText) : t("common.ok")}
                </StandardButton>

                {!disableHelp && (
                    <div className={cn("contact-us-btn mt-3 mb-2")}>
                        <span onClick={openContactUsWidget}>{t("common.needhelpcontact")}</span>
                    </div>
                )}

                {showCloseBtn && (
                    <TextButton
                        className="mt-3"
                        text={t("common.close")}
                        onClick={async () => {
                            navigationService.navigate("/plugins")
                        }}
                    />
                )}
            </>
        </ServicesOnboardingPageWrapper>
    )
}

export default ApplicationSubmittedDialog
