import cn from "classnames"
import is from "is_js"
import React from "react"
import { getKeys } from "swiipe.portal.shared"
import "./UploadedFilesOverview.scss"

interface IUploadedFilesOverviewProps {
    files?: FileList
    className?: string
    onRemoveFile?: (fileIndex: number) => void
}

interface IUploadedFilesOverviewTextProps {
    fileNames?: string[]
    wrongFilesOverview?: boolean
    className?: string
}

const UploadedFilesOverview = ({ files, className, onRemoveFile }: IUploadedFilesOverviewProps) => {
    //Create file list is not supported by IE and Safari
    //So we can't remove files one by one and should show only
    //one delete button to not confuse users
    const singleDeleteButton = is.safari() || is.ie()

    return (
        <div className={cn("swiipe-files-list", className)}>
            {files &&
                getKeys(files).map((key) => {
                    return (
                        <div className="file-option" key={key}>
                            <span className="file">{files[key].name}</span>
                            {onRemoveFile && (!singleDeleteButton || key === "0") && (
                                <div
                                    className="delete-file-btn"
                                    onClick={() => {
                                        onRemoveFile(parseInt(key))
                                    }}
                                >
                                    ×
                                </div>
                            )}
                        </div>
                    )
                })}
        </div>
    )
}

export const UploadedFilesOverviewText = ({ fileNames, wrongFilesOverview, className }: IUploadedFilesOverviewTextProps) => {
    return (
        <div className={cn("swiipe-files-list", wrongFilesOverview ? "mb-smaller" : "", className)}>
            {fileNames &&
                fileNames.map((name, i) => {
                    return (
                        <div className={cn("file-option", wrongFilesOverview ? "red-color" : "")} key={i}>
                            <span className="file">{name}</span>
                        </div>
                    )
                })}
        </div>
    )
}

export default UploadedFilesOverview
