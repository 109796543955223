import { IImageDimensions } from "../types/general/ImageDimensions"

export const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
    })

export function downloadBlob(blob: Blob, fileName: string) {
    const a = document.createElement("a")
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        // IE
        ;(window.navigator as any).msSaveOrOpenBlob(blob, fileName)
    } else {
        // Chrome, Safari, Firefox, Edge
        a.href = URL.createObjectURL(blob)
        a.download = fileName
        a.style.display = "none"
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }
}

export function getImageDimensions(objectUrl: string) {
    return new Promise((resolve: (value: IImageDimensions) => void, reject) => {
        const img = new Image()
        img.onload = () =>
            resolve({
                height: img.height,
                width: img.width,
            })
        img.onerror = reject
        img.src = objectUrl
    })
}

export function getFilesFromFileList(files: FileList | undefined): File[] {
    return Array.from(files ?? { length: 0 }).filter((file) => file !== null) as File[]
}
