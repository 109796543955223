import { getText, roundNumberToMaxDecimals, sortAscending, sortDescending, sumArray } from "swiipe.portal.shared"
import {
    IMerchantDashboardServerData,
    IMerchantDashboardServerPeriodData,
} from "../type/merchantDashboard/IMerchantDashboardServerData"

export function getPaymentMethodsTitlesForExport(serverData: IMerchantDashboardServerData) {
    return getPaymentMethodsData(serverData).mainPaymentMethods.map((pm) => pm.title)
}

interface IMerchantDashboardPaymentMethodResolvedModel {
    currency: string
    mainPaymentMethods: { title: string; value: number }[]
    comparePaymentMethods?: { title: string; value: number }[]
    mainOtherCreditCardPaymentMethods: { title: string; value: number }[]
    compareOtherCreditCardPaymentMethods?: { title: string; value: number }[]
}
export function getPaymentMethodsData(serverData: IMerchantDashboardServerData): IMerchantDashboardPaymentMethodResolvedModel {
    const { main: mainPaymentMethods, compare: comparePaymentMethods } = getMainAndComparePaymentMethodsSortedAndEmptyRemoved(
        getSubCreditCardAndOtherPaymentMethods(serverData.mainData),
        serverData.compareData && getSubCreditCardAndOtherPaymentMethods(serverData.compareData)
    )
    const { main: mainOtherCreditCardPaymentMethods, compare: compareOtherCreditCardPaymentMethods } =
        getMainAndComparePaymentMethodsSortedAndEmptyRemoved(
            getOtherCreditCardPaymentMethods(serverData.mainData),
            serverData.compareData && getOtherCreditCardPaymentMethods(serverData.compareData)
        )

    return {
        currency: serverData.currency,
        mainPaymentMethods: translatePaymentMethods(mainPaymentMethods),
        comparePaymentMethods: comparePaymentMethods && translatePaymentMethods(comparePaymentMethods),
        mainOtherCreditCardPaymentMethods,
        compareOtherCreditCardPaymentMethods,
    }
}

function getMainAndComparePaymentMethodsSortedAndEmptyRemoved(
    mainData: { title: string; value: number }[],
    compareData: { title: string; value: number }[] | undefined
) {
    const sortedMain = sortDescending(mainData, (pm) => pm.value)
    const sortedCompare =
        compareData && sortAscending(compareData, (pm) => sortedMain.findIndex((mainPm) => mainPm.title === pm.title))
    // remove elements where both main and compare values are 0
    const main = sortedMain.filter(
        (pm) => pm.value !== 0 || (sortedCompare?.find((cpm) => pm.title === cpm.title)?.value ?? 0) !== 0
    )
    const compare = sortedCompare?.filter((cpm) => {
        const mainValue = sortedMain.find((mainPm) => mainPm.title === cpm.title)?.value ?? 0
        return mainValue !== 0 || cpm.value !== 0
    })
    return { main, compare }
}

function getSubCreditCardAndOtherPaymentMethods(period: IMerchantDashboardServerPeriodData) {
    const creditCardSubPaymentMethodsOriginal = period.payment.creditCardSubPaymentMethods
    const creditCardSubPaymentMethodsSanitized = [
        ...creditCardSubPaymentMethodsOriginal.filter((sub) => sub.title !== "Electron" && sub.title !== "Maestro"),
    ]

    //Push "Maestro" into "MasterCard"
    const maestro = creditCardSubPaymentMethodsOriginal.find((sub) => sub.title === "Maestro")
    if (maestro) {
        const masterCard = creditCardSubPaymentMethodsSanitized.find((sub) => sub.title === "MasterCard")
        if (!masterCard) {
            creditCardSubPaymentMethodsSanitized.push({ title: "MasterCard", usages: maestro.usages })
        } else {
            masterCard.usages += maestro.usages
        }
    }

    //Push "Electron" into "Visa"
    const electron = creditCardSubPaymentMethodsOriginal.find((sub) => sub.title === "Electron")
    if (electron) {
        const visa = creditCardSubPaymentMethodsSanitized.find((sub) => sub.title === "Visa")
        if (!visa) {
            creditCardSubPaymentMethodsSanitized.push({ title: "Visa", usages: electron.usages })
        } else {
            visa.usages += electron.usages
        }
    }

    const nonCreditCardPaymentMethods = period.payment.paymentMethods.filter(
        (pm) => !period.payment.creditCardPaymentMethods.find((cc) => cc.title === pm.title)
    )

    const relevantToShow = [...creditCardSubPaymentMethodsSanitized, ...nonCreditCardPaymentMethods]
    const totalUsages = sumArray(relevantToShow, (r) => r.usages)

    return relevantToShow.map((pm) => ({
        title: pm.title,
        value: roundNumberToMaxDecimals(totalUsages === 0 ? 0 : (pm.usages / totalUsages) * 100, 2),
    }))
}

function getOtherCreditCardPaymentMethods(period: IMerchantDashboardServerPeriodData) {
    const allPaymentMethods = period.payment.paymentMethods
    const creditCardPaymentMethods = period.payment.creditCardPaymentMethods
    const relevantToShow = [...creditCardPaymentMethods.filter((pm) => pm.title !== "CreditCard")]

    const totalUsages = sumArray(allPaymentMethods, (r) => r.usages)
    return relevantToShow.map((pm) => ({
        title: pm.title,
        value: roundNumberToMaxDecimals(totalUsages === 0 ? 0 : (pm.usages / totalUsages) * 100, 2),
    }))
}

function translatePaymentMethods(paymentMethods: { title: string; value: number }[]) {
    return paymentMethods.map((pm) => {
        if (pm.title === "Other") {
            return {
                title: getText("merchantDashboard.otherCreditCardPaymentMethods"),
                value: pm.value,
            }
        }
        return pm
    })
}
