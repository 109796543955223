import cn from "classnames"
import React, { useState } from "react"
import { SpinnerContainer } from "swiipe.portal.shared"
import "./TextButton.scss"

export interface ITextButton {
    text: string
    color?: "grey" | "red" | "blue" | "dark-blue"
    textDecoration?: "underline" | "underline-dotted"
    fontSize?: "big" | "smaller" | "smallest"
    fontStyle?: "italic"
    fontWeight?: "600"
    display?: "inline-block"
    width?: "fit-content"
    onClick: () => Promise<void>
    disableSpinner?: boolean
    className?: string
}

export const TextButton = ({
    text,
    color = "blue",
    textDecoration,
    fontSize,
    fontStyle,
    fontWeight,
    display,
    width,
    disableSpinner,
    onClick,
    className,
}: ITextButton) => {
    const [showSpinner, setShowSpinner] = useState(false)

    return (
        <div
            className={cn(
                "text-btn",
                color && `text-btn--color-${color}`,
                textDecoration && `text-btn--text-decoration-${textDecoration}`,
                fontSize && `text-btn--font-size-${fontSize}`,
                fontStyle && `text-btn--font-style-${fontStyle}`,
                fontWeight && `text-btn--font-weight-${fontWeight}`,
                display && `text-btn--display-${display}`,
                width && `text-btn--width-${width}`,
                className
            )}
        >
            <SpinnerContainer className="text-btn__spinner-container" showSpinner={showSpinner}>
                <span
                    onClick={async () => {
                        if (disableSpinner) {
                            await onClick()
                            return
                        }

                        setShowSpinner(true)
                        try {
                            await onClick()
                            setShowSpinner(false)
                        } catch {
                            setShowSpinner(false)
                        }
                    }}
                >
                    {text}
                </span>
            </SpinnerContainer>
        </div>
    )
}
