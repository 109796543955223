import cn from "classnames"
import React from "react"
import { ReactComponent as IconCheckmark } from "../../images/icons/icon-checkmark.svg"
import { ReactComponent as IconInfo } from "../../images/icons/info/info-icon.svg"
import "./StandardIcon.scss"

type TStandardIcon = "checkmark" | "info"

interface IStandardIcon {
    padding?: number
    backgroundType?: "circle"
    backgroundColor?: string
    src?: string
    svgComponent?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title: string | undefined }>
    standardType?: TStandardIcon
    componentForegroundColor?: string
    width: number
    className?: string
    title?: string
}

export const StandardIcon = ({
    src,
    svgComponent: Component,
    backgroundType,
    padding,
    width,
    backgroundColor = "transparent",
    standardType,
    componentForegroundColor,
    className,
    title,
}: IStandardIcon) => {
    const backgroundTypeStyles: React.CSSProperties = {}
    if (backgroundType === "circle") {
        backgroundTypeStyles.borderRadius = width + "px"
    }

    const StandardComponent = getIconSrc(standardType)
    const resolvedPadding = padding ?? 0

    return (
        <div
            className={cn("standard-icon", backgroundType && "standard-icon--" + (backgroundType ?? "none"), className)}
            style={{ ...backgroundTypeStyles, padding, width, height: width, ...(backgroundColor ? { backgroundColor } : {}) }}
        >
            {src && (
                <img
                    src={src}
                    title={title}
                    className="standard-icon__icon"
                    style={{
                        width: width - 2 * resolvedPadding,
                        height: width - 2 * resolvedPadding,
                        top: resolvedPadding,
                        left: resolvedPadding,
                    }}
                />
            )}
            {Component && (
                <Component
                    className="standard-icon__icon"
                    title={title}
                    style={{ top: resolvedPadding, left: resolvedPadding }}
                    width={width - 2 * resolvedPadding}
                    height={width - 2 * resolvedPadding}
                    // All stroke attributes of SVG must be moved to top svg level in svg-file for this to work
                    {...(componentForegroundColor ? { stroke: componentForegroundColor } : {})}
                />
            )}
            {StandardComponent && (
                <StandardComponent
                    className="standard-icon__icon"
                    title={title}
                    style={{ top: resolvedPadding, left: resolvedPadding }}
                    width={width - 2 * resolvedPadding}
                    height={width - 2 * resolvedPadding}
                    // All stroke attributes of SVG must be moved to top svg level in svg-file for this to work
                    {...(componentForegroundColor ? { stroke: componentForegroundColor } : {})}
                />
            )}
        </div>
    )
}

function getIconSrc(type?: TStandardIcon) {
    if (type === "checkmark") {
        return IconCheckmark
    }
    if (type === "info") {
        return IconInfo
    }
    return undefined
}
