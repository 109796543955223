import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, StandardButton, TextButton } from "swiipe.portal.shared"
import { merchantOfferNavigateToStepThunk } from "../../store/thunks/merchantPreOnboardingThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import "./ModalMerchantOfferNavigateKyc.scss"

export interface IModalMerchantOfferNavigateKyc extends IModalBase {
    type: "merchantOfferNavigateKyc"
    countryCode: string
}

const ModalMerchantOfferNavigateKyc = ({
    callback,
    countryCode,
    ...otherProps
}: IModalMerchantOfferNavigateKyc & IModalControlProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    return (
        <BaseModal
            {...otherProps}
            callback={callback}
            size="lg"
            modalClassName="modal-merchant-offer-navigate-kyc"
            centered
            noBorderTop
            title={t("merchantOffer.modalKyc.title")}
        >
            <ModalBody>
                <div className="modal-merchant-offer-navigate-kyc__intro">{t("merchantOffer.modalKyc.intro")}</div>
                <div className="modal-merchant-offer-navigate-kyc__box">
                    {countryCode.toLowerCase() === "dk"
                        ? t("merchantOffer.modalKyc.textDaCompany")
                        : t("merchantOffer.modalKyc.textNonDaCompany")}
                </div>
                <div className={cn("buttons mt-5 mb-3 centered")}>
                    <StandardButton
                        isMedium
                        onClick={() => {
                            callback("accepted")
                            dispatch(merchantOfferNavigateToStepThunk("kyc"))
                        }}
                    >
                        {t("merchantOffer.modalKyc.acceptButton")}
                    </StandardButton>
                </div>
                <div>
                    <TextButton
                        text={t("merchantOffer.modalKyc.declineButton")}
                        className="mt-5"
                        color="grey"
                        textDecoration="underline"
                        fontSize="smaller"
                        onClick={async () => callback("ignored")}
                    />
                </div>
            </ModalBody>
        </BaseModal>
    )
}

export default ModalMerchantOfferNavigateKyc
