import { getKeys } from "swiipe.portal.shared"

export function getUrlSchemeAndHost() {
    return document.location.protocol + "//" + document.location.host
}

export function getQueryStringFromObject(o: { [key: string]: any }) {
    return getKeys(o)
        .map((key) => key + "=" + o[key])
        .join("&")
}

export function getQueryValueFromCurrentUrl(queryToLookFor: string) {
    const query = new URLSearchParams(decodeURIComponent(document.location.search))
    return query.get(queryToLookFor) ?? undefined
}

export function getQueryValueFromUrl(url: string, queryToLookFor: string) {
    const parts = url.split("#")[0].split("?")
    const query = new URLSearchParams(decodeURIComponent(parts.length > 1 ? "?" + parts[1] : "?"))
    return query.get(queryToLookFor) ?? undefined
}

export function removeQueryValueFromCurrentUrl(queryToDelete: string) {
    const query = new URLSearchParams(document.location.search)
    query.delete(queryToDelete)
    const updatedQuery = !query.toString() ? "" : "?" + query.toString()
    window.history.replaceState(null, document.title, getUrlSchemeAndHost() + document.location.pathname + updatedQuery)
}

export function addQueryParamsToUrl(
    url: string,
    params: { [key: string]: string | number | boolean | undefined },
    removeExistingParams = false
) {
    const query = removeExistingParams
        ? new URLSearchParams()
        : new URLSearchParams(new URL(url.startsWith("http") ? url : "https://dummy.com" + url).search)
    getKeys(params).forEach((key) => {
        query.delete(key)
        if (typeof params[key] !== "undefined") {
            query.append(key, params[key] + "")
        }
    })
    const updatedQuery = !query.toString() ? "" : "?" + query.toString()
    return url.split(/\?|#/)[0] + updatedQuery
}

export function removeOriginFromUrl(urlStr: string) {
    const url = new URL(urlStr)
    return `${url.pathname}${url.search}${url.hash}`
}

export function removeProtocolFromUrl(urlStr: string, removeTrailingSlash?: boolean) {
    if (!urlStr.split("?")[0].includes("://")) {
        return urlStr
    }
    const url = new URL(urlStr)
    const res = `${url.hostname}${url.pathname}${url.search}${url.hash}`
    if (removeTrailingSlash && res.endsWith("/")) {
        return res.slice(0, res.length - 1)
    }
    return res
}

export function getCurrentUrlPath(includeSchemeAndHost: boolean, queryParams?: { [key: string]: string }) {
    const queryString = !queryParams
        ? ""
        : getKeys(queryParams).reduce((qStr, key) => {
              const part = key + "=" + queryParams[key]
              return qStr.length === 0 ? part : qStr + "&" + part
          }, "")

    return (
        (includeSchemeAndHost ? getUrlSchemeAndHost() : "") +
        document.location.pathname +
        (queryString.length === 0 ? "" : "?" + queryString)
    )
}

export function openUrl(
    url: string,
    options?: {
        inNewTab?: boolean
        withFocus?: boolean
    }
) {
    const result = window.open(url, (options?.inNewTab && "_blank") || undefined)
    if (options?.withFocus) {
        result?.focus()
    }
}
