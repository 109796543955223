import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { IBasePaymentOnboardingData } from "../../type/IBasePaymentOnboardingData"
import { StoreState } from "../StoreState"
import { IResursStore } from "./../../type/resurs/IResursStore"
import { resursReducerActions } from "./../reducers/resursReducer"
import { getGatewaysForMerchantThunk } from "./gatewayThunks"
import { getOnboardingStatusThunk } from "./swiipePaymentsThunks"
import { sendServiceConfirmationEmailThunk } from "./swiipeServicesThunks"

export interface IResursOnboardingData extends IBasePaymentOnboardingData {
    resursStoreId: string
}

export const resursOnboardingThunk =
    (data: IResursOnboardingData, isEdit: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!data.webshopIds || !data.resursStoreId || !data.swMerchantId) {
            return
        }
        const resp = await dispatch(
            requestThunk(endpoints.Resurs.onboardWebshop, {
                data: {
                    swMerchantId: data.swMerchantId,
                    webshopId: data.webshopIds[0],
                    swMerchantEmail: data.swMerchantEmail,
                    swMerchantName: data.swMerchantName,
                    merchantContactName: data.merchantContactName,
                    merchantContactPhone: data.merchantContactPhone,
                    resursStoreId: data.resursStoreId,
                },
            })
        )

        if (!isEdit) {
            await dispatch(sendServiceConfirmationEmailThunk(data.swMerchantId, "ResursConfirmation", "Resurs"))
            await dispatch(getGatewaysForMerchantThunk(data.swMerchantId, true, true))
            await dispatch(
                getOnboardingStatusThunk(data.swMerchantId, data.webshopIds[0], true, [
                    "ResursInvoice",
                    "ResursPartPayment",
                    "ResursRevolvingCredit",
                ])
            )
        }
    }

export interface IResursNoStoresNotifyData extends IBasePaymentOnboardingData {}
export const resursOnboardingNoStoresNotifyThunk =
    (data: IResursNoStoresNotifyData): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk(endpoints.Resurs.notifyNoStoresSignUp, {
                data: {
                    swMerchantId: data.swMerchantId,
                    webshopId: data.webshopIds[0],
                    swMerchantEmail: data.swMerchantEmail,
                    swMerchantName: data.swMerchantName,
                    merchantContactName: data.merchantContactName,
                    merchantContactPhone: data.merchantContactPhone,
                },
            })
        )
    }

export const cancelResursForWebshopThunk =
    (webshopId: string, swMerchantId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const resp = await dispatch(
            requestThunk(endpoints.Resurs.cancelOnboarding, {
                data: {
                    webshopId: webshopId,
                },
            })
        )
        await dispatch(getGatewaysForMerchantThunk(swMerchantId, true, true))
        await dispatch(
            getOnboardingStatusThunk(swMerchantId, webshopId, true, [
                "ResursInvoice",
                "ResursPartPayment",
                "ResursRevolvingCredit",
            ])
        )
    }

export const getResursStoresForForMerchantThunk =
    (swMerchantId: string, webshopId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const resp = await dispatch(
            requestThunk<{ resursStores: IResursStore[]; errorMessage: string }>(endpoints.Resurs.getResursStores, {
                params: {
                    swMerchantId: swMerchantId,
                    webshopId: webshopId,
                },
            })
        )

        if (resp.errorMessage) {
            await dispatch(
                addModalThunk({
                    type: "error",
                    errorMessage: resp.errorMessage,
                })
            )
            return
        }

        dispatch(resursReducerActions.setResursStores(swMerchantId, resp.resursStores))
    }
