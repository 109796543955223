import React from "react"
import { useTranslation } from "react-i18next"
import { PageWrapper } from "swiipe.portal.shared"
import "./Home.scss"

interface IHomeProps {}

const Home = ({}: IHomeProps) => {
    const { t } = useTranslation()
    return (
        <PageWrapper>
            <div id="home">
                <div className="home-header">
                    <h1>{t("home.title")}</h1>
                </div>
                <div className="home-body">
                    <p> {t("home.body")}</p>
                </div>
            </div>
        </PageWrapper>
    )
}

export default Home
