import { TFunction } from "react-i18next"
import { TCountryCode } from "../types/general/countryCodes"

export interface ICountry {
    value: TCountryCode
    text: string
}

const countries: ICountry[] = [
    { value: "AF", text: "Afghanistan" },
    { value: "AX", text: "Aland Islands" },
    { value: "AL", text: "Albania" },
    { value: "DZ", text: "Algeria" },
    { value: "AS", text: "American Samoa" },
    { value: "AD", text: "Andorra" },
    { value: "AO", text: "Angola" },
    { value: "AI", text: "Anguilla" },
    { value: "AQ", text: "Antarctica" },
    { value: "AG", text: "Antigua And Barbuda" },
    { value: "AR", text: "Argentina" },
    { value: "AM", text: "Armenia" },
    { value: "AW", text: "Aruba" },
    { value: "AU", text: "Australia" },
    { value: "AT", text: "Austria" },
    { value: "AZ", text: "Azerbaijan" },
    { value: "BS", text: "Bahamas" },
    { value: "BH", text: "Bahrain" },
    { value: "BD", text: "Bangladesh" },
    { value: "BB", text: "Barbados" },
    { value: "BY", text: "Belarus" },
    { value: "BE", text: "Belgium" },
    { value: "BZ", text: "Belize" },
    { value: "BJ", text: "Benin" },
    { value: "BM", text: "Bermuda" },
    { value: "BT", text: "Bhutan" },
    { value: "BO", text: "Bolivia" },
    { value: "BA", text: "Bosnia And Herzegovina" },
    { value: "BW", text: "Botswana" },
    { value: "BV", text: "Bouvet Island" },
    { value: "BR", text: "Brazil" },
    { value: "IO", text: "British Indian Ocean Territory" },
    { value: "BN", text: "Brunei Darussalam" },
    { value: "BG", text: "Bulgaria" },
    { value: "BF", text: "Burkina Faso" },
    { value: "BI", text: "Burundi" },
    { value: "KH", text: "Cambodia" },
    { value: "CM", text: "Cameroon" },
    { value: "CA", text: "Canada" },
    { value: "CV", text: "Cape Verde" },
    { value: "KY", text: "Cayman Islands" },
    { value: "CF", text: "Central African Republic" },
    { value: "TD", text: "Chad" },
    { value: "CL", text: "Chile" },
    { value: "CN", text: "China" },
    { value: "CX", text: "Christmas Island" },
    { value: "CC", text: "Cocos (Keeling) Islands" },
    { value: "CO", text: "Colombia" },
    { value: "KM", text: "Comoros" },
    { value: "CG", text: "Congo" },
    { value: "CD", text: "Congo, Democratic Republic" },
    { value: "CK", text: "Cook Islands" },
    { value: "CR", text: "Costa Rica" },
    { value: "CI", text: "Cote D'Ivoire" },
    { value: "HR", text: "Croatia" },
    { value: "CU", text: "Cuba" },
    { value: "CY", text: "Cyprus" },
    { value: "CZ", text: "Czech Republic" },
    { value: "DK", text: "Denmark" },
    { value: "DJ", text: "Djibouti" },
    { value: "DM", text: "Dominica" },
    { value: "DO", text: "Dominican Republic" },
    { value: "EC", text: "Ecuador" },
    { value: "EG", text: "Egypt" },
    { value: "SV", text: "El Salvador" },
    { value: "GQ", text: "Equatorial Guinea" },
    { value: "ER", text: "Eritrea" },
    { value: "EE", text: "Estonia" },
    { value: "ET", text: "Ethiopia" },
    { value: "FK", text: "Falkland Islands (Malvinas)" },
    { value: "FO", text: "Faroe Islands" },
    { value: "FJ", text: "Fiji" },
    { value: "FI", text: "Finland" },
    { value: "FR", text: "France" },
    { value: "GF", text: "French Guiana" },
    { value: "PF", text: "French Polynesia" },
    { value: "TF", text: "French Southern Territories" },
    { value: "GA", text: "Gabon" },
    { value: "GM", text: "Gambia" },
    { value: "GE", text: "Georgia" },
    { value: "DE", text: "Germany" },
    { value: "GH", text: "Ghana" },
    { value: "GI", text: "Gibraltar" },
    { value: "GR", text: "Greece" },
    { value: "GL", text: "Greenland" },
    { value: "GD", text: "Grenada" },
    { value: "GP", text: "Guadeloupe" },
    { value: "GU", text: "Guam" },
    { value: "GT", text: "Guatemala" },
    { value: "GG", text: "Guernsey" },
    { value: "GN", text: "Guinea" },
    { value: "GW", text: "Guinea-Bissau" },
    { value: "GY", text: "Guyana" },
    { value: "HT", text: "Haiti" },
    { value: "HM", text: "Heard Island & Mcdonald Islands" },
    { value: "VA", text: "Holy See (Vatican City State)" },
    { value: "HN", text: "Honduras" },
    { value: "HK", text: "Hong Kong" },
    { value: "HU", text: "Hungary" },
    { value: "IS", text: "Iceland" },
    { value: "IN", text: "India" },
    { value: "ID", text: "Indonesia" },
    { value: "IR", text: "Iran, Islamic Republic Of" },
    { value: "IQ", text: "Iraq" },
    { value: "IE", text: "Ireland" },
    { value: "IM", text: "Isle Of Man" },
    { value: "IL", text: "Israel" },
    { value: "IT", text: "Italy" },
    { value: "JM", text: "Jamaica" },
    { value: "JP", text: "Japan" },
    { value: "JE", text: "Jersey" },
    { value: "JO", text: "Jordan" },
    { value: "KZ", text: "Kazakhstan" },
    { value: "KE", text: "Kenya" },
    { value: "KI", text: "Kiribati" },
    { value: "KR", text: "Korea" },
    { value: "KW", text: "Kuwait" },
    { value: "KG", text: "Kyrgyzstan" },
    { value: "LA", text: "Lao People's Democratic Republic" },
    { value: "LV", text: "Latvia" },
    { value: "LB", text: "Lebanon" },
    { value: "LS", text: "Lesotho" },
    { value: "LR", text: "Liberia" },
    { value: "LY", text: "Libyan Arab Jamahiriya" },
    { value: "LI", text: "Liechtenstein" },
    { value: "LT", text: "Lithuania" },
    { value: "LU", text: "Luxembourg" },
    { value: "MO", text: "Macao" },
    { value: "MK", text: "Macedonia" },
    { value: "MG", text: "Madagascar" },
    { value: "MW", text: "Malawi" },
    { value: "MY", text: "Malaysia" },
    { value: "MV", text: "Maldives" },
    { value: "ML", text: "Mali" },
    { value: "MT", text: "Malta" },
    { value: "MH", text: "Marshall Islands" },
    { value: "MQ", text: "Martinique" },
    { value: "MR", text: "Mauritania" },
    { value: "MU", text: "Mauritius" },
    { value: "YT", text: "Mayotte" },
    { value: "MX", text: "Mexico" },
    { value: "FM", text: "Micronesia, Federated States Of" },
    { value: "MD", text: "Moldova" },
    { value: "MC", text: "Monaco" },
    { value: "MN", text: "Mongolia" },
    { value: "ME", text: "Montenegro" },
    { value: "MS", text: "Montserrat" },
    { value: "MA", text: "Morocco" },
    { value: "MZ", text: "Mozambique" },
    { value: "MM", text: "Myanmar" },
    { value: "NA", text: "Namibia" },
    { value: "NR", text: "Nauru" },
    { value: "NP", text: "Nepal" },
    { value: "NL", text: "Netherlands" },
    { value: "AN", text: "Netherlands Antilles" },
    { value: "NC", text: "New Caledonia" },
    { value: "NZ", text: "New Zealand" },
    { value: "NI", text: "Nicaragua" },
    { value: "NE", text: "Niger" },
    { value: "NG", text: "Nigeria" },
    { value: "NU", text: "Niue" },
    { value: "NF", text: "Norfolk Island" },
    { value: "MP", text: "Northern Mariana Islands" },
    { value: "NO", text: "Norway" },
    { value: "OM", text: "Oman" },
    { value: "PK", text: "Pakistan" },
    { value: "PW", text: "Palau" },
    { value: "PS", text: "Palestinian Territory, Occupied" },
    { value: "PA", text: "Panama" },
    { value: "PG", text: "Papua New Guinea" },
    { value: "PY", text: "Paraguay" },
    { value: "PE", text: "Peru" },
    { value: "PH", text: "Philippines" },
    { value: "PN", text: "Pitcairn" },
    { value: "PL", text: "Poland" },
    { value: "PT", text: "Portugal" },
    { value: "PR", text: "Puerto Rico" },
    { value: "QA", text: "Qatar" },
    { value: "RE", text: "Reunion" },
    { value: "RO", text: "Romania" },
    { value: "RU", text: "Russian Federation" },
    { value: "RW", text: "Rwanda" },
    { value: "BL", text: "Saint Barthelemy" },
    { value: "SH", text: "Saint Helena" },
    { value: "KN", text: "Saint Kitts And Nevis" },
    { value: "LC", text: "Saint Lucia" },
    { value: "MF", text: "Saint Martin" },
    { value: "PM", text: "Saint Pierre And Miquelon" },
    { value: "VC", text: "Saint Vincent And Grenadines" },
    { value: "WS", text: "Samoa" },
    { value: "SM", text: "San Marino" },
    { value: "ST", text: "Sao Tome And Principe" },
    { value: "SA", text: "Saudi Arabia" },
    { value: "SN", text: "Senegal" },
    { value: "RS", text: "Serbia" },
    { value: "SC", text: "Seychelles" },
    { value: "SL", text: "Sierra Leone" },
    { value: "SG", text: "Singapore" },
    { value: "SK", text: "Slovakia" },
    { value: "SI", text: "Slovenia" },
    { value: "SB", text: "Solomon Islands" },
    { value: "SO", text: "Somalia" },
    { value: "ZA", text: "South Africa" },
    { value: "GS", text: "South Georgia And Sandwich Isl." },
    { value: "ES", text: "Spain" },
    { value: "LK", text: "Sri Lanka" },
    { value: "SD", text: "Sudan" },
    { value: "SR", text: "Suriname" },
    { value: "SJ", text: "Svalbard And Jan Mayen" },
    { value: "SZ", text: "Swaziland" },
    { value: "SE", text: "Sweden" },
    { value: "CH", text: "Switzerland" },
    { value: "SY", text: "Syrian Arab Republic" },
    { value: "TW", text: "Taiwan" },
    { value: "TJ", text: "Tajikistan" },
    { value: "TZ", text: "Tanzania" },
    { value: "TH", text: "Thailand" },
    { value: "TL", text: "Timor-Leste" },
    { value: "TG", text: "Togo" },
    { value: "TK", text: "Tokelau" },
    { value: "TO", text: "Tonga" },
    { value: "TT", text: "Trinidad And Tobago" },
    { value: "TN", text: "Tunisia" },
    { value: "TR", text: "Turkey" },
    { value: "TM", text: "Turkmenistan" },
    { value: "TC", text: "Turks And Caicos Islands" },
    { value: "TV", text: "Tuvalu" },
    { value: "UG", text: "Uganda" },
    { value: "UA", text: "Ukraine" },
    { value: "AE", text: "United Arab Emirates" },
    { value: "GB", text: "United Kingdom" },
    { value: "US", text: "United States" },
    { value: "UM", text: "United States Outlying Islands" },
    { value: "UY", text: "Uruguay" },
    { value: "UZ", text: "Uzbekistan" },
    { value: "VU", text: "Vanuatu" },
    { value: "VE", text: "Venezuela" },
    { value: "VN", text: "Viet Nam" },
    { value: "VG", text: "Virgin Islands, British" },
    { value: "VI", text: "Virgin Islands, U.S." },
    { value: "WF", text: "Wallis And Futuna" },
    { value: "EH", text: "Western Sahara" },
    { value: "YE", text: "Yemen" },
    { value: "ZM", text: "Zambia" },
    { value: "ZW", text: "Zimbabwe" },
]

export function getTranslatedCountryNameByISO(iso: string, t: TFunction<"translation", undefined>) {
    const translationKey = `countries.${iso}`
    const countryTranslated = t(translationKey)

    if (countryTranslated !== translationKey) {
        return countryTranslated
    }

    return countries.find((c) => c.value === iso.toUpperCase())?.text
}

export function getTranslatedCountryRelationByISO(iso: string, t: TFunction<"translation", undefined>) {
    // ie danish, swedish etc.
    const countryRelation = t(`countryLanguages.${iso}`)

    if (countryRelation.includes("countryLanguages." || !countryRelation)) {
        return ""
    }

    return countryRelation
}

export function getCountryByISO(iso: string) {
    const country = countries.find((c) => {
        return c.value == iso
    })
    if (typeof country === "undefined") {
        return ""
    }
    return country.text
}
export function getIsoByCountryName(countryName: string) {
    const country = countries.find((c) => {
        return c.text == countryName
    })
    if (typeof country === "undefined") {
        return ""
    }
    return country.value
}

export function getCountriesList(countryFilter?: TCountryFilter, customCountryFilter?: TCountryCode[]) {
    let filteredCountries = countries

    if (countryFilter) {
        filteredCountries = filteredCountries.filter((c) => countryFilter.includes(c.value as any))
    }

    if (customCountryFilter) {
        filteredCountries = filteredCountries.filter((c) => customCountryFilter.includes(c.value as any))
    }

    return filteredCountries
}

export type TCountryFilter =
    | typeof denmarkOnlyCountryFilter
    | typeof scandinaviaOnlyCountryFilter
    | typeof withinEuropeCountryFilter
    | typeof euAndEaaCountryFilter
    | typeof companyNumberLookupSupportedCountries

export const denmarkOnlyCountryFilter = ["DK"] as const
export const scandinaviaOnlyCountryFilter = ["DK", "NO", "SE", "FI"] as const
export const withinEuropeCountryFilter = [
    //EU
    "BE",
    "EL",
    "LT",
    "PT",
    "BG",
    "ES",
    "LU",
    "RO",
    "CZ",
    "FR",
    "HU",
    "SI",
    "DK",
    "HR",
    "MT",
    "SK",
    "DE",
    "IT",
    "NL",
    "FI",
    "EE",
    "CY",
    "AT",
    "SE",
    "IE",
    "LV",
    "PL",
    //Non eu
    "AL",
    "AD",
    "AM",
    "BY",
    "BA",
    "FO",
    "GE",
    "GI",
    "IS",
    "IM",
    "XK",
    "LI",
    "MK",
    "MD",
    "MC",
    "ME",
    "NO",
    "RU",
    "SM",
    "RS",
    "CH",
    "TR",
    "UA",
    "GB",
    "VA",
] as const
export const euAndEaaCountryFilter = [
    "AT",
    "BE",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "DE",
    "EL",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
    "NO",
    "IS",
    "LI",
] as const
export const companyNumberLookupSupportedCountries = [...euAndEaaCountryFilter, "CH", "GB", "US"]
