import { CountryRegionConfig, IDropdownOption, getKeys } from "swiipe.portal.shared"

export const getRegionsList = (
    country: string,
    countryRegionConfig: CountryRegionConfig | undefined
): IDropdownOption<string>[] => {
    if (!countryRegionConfig || !countryRegionConfig[country] || !countryRegionConfig[country].nameToCode) {
        return []
    }

    const regionNameToCode = countryRegionConfig[country].nameToCode
    const options = getKeys(regionNameToCode).map((key) => {
        return { text: key, value: regionNameToCode[key] }
    })

    //Filter regions duplicates
    const codesIncluded: string[] = []
    const finalOptions = options.filter((o) => {
        if (!codesIncluded.includes(o.value)) {
            codesIncluded.push(o.value)
            return true
        }

        return false
    })

    return finalOptions
}

export const getRegionDefaultValue = (
    countryRegionConfig: CountryRegionConfig | undefined,
    country?: string,
    region?: string
) => {
    if (!country || !region || !countryRegionConfig || !countryRegionConfig[country]) {
        return undefined
    }

    if (getKeys(countryRegionConfig).includes(country) && getKeys(countryRegionConfig[country].nameToCode).includes(region)) {
        return countryRegionConfig[country].nameToCode[region]
    }

    return undefined
}

export const getRegionNameFromCode = (code: string, country: string, countryRegionConfig: CountryRegionConfig | undefined) => {
    let regionName = ""

    if (!countryRegionConfig || !countryRegionConfig[country]) {
        return regionName
    }

    getKeys(countryRegionConfig![country].nameToCode).find((key) => {
        if (countryRegionConfig![country].nameToCode[key] === code) {
            regionName = key
            return true
        }

        return false
    })

    return regionName
}

export const isShowRegionInput = (currentCountry: string, countryRegionConfig: CountryRegionConfig | undefined) => {
    return (
        countryRegionConfig &&
        getKeys(countryRegionConfig).includes(currentCountry) &&
        countryRegionConfig[currentCountry].regionType === "select"
    )
}
