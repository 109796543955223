import Parser from "html-react-parser"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form, Input } from "reactstrap"
import { ShowErrorMessages, TextButton, TitleWithFoldableHint, valFuncRequired } from "swiipe.portal.shared"
import SubmitButton from "../../../../components/buttons/SubmitButton"
import FixedLabelInput from "../../../../components/form/input/FixedLabelInput"
import { getImage } from "../../../../services/imageLanguageService"
import { TApplePayOnboardingType } from "../../ApplePayOnboardingPage"
import "./ApplePayIdStep.scss"

interface IApplePayIdStepProps {
    onNextStep: (onboardingType: TApplePayOnboardingType) => void
    onBackStep: () => Promise<void>
    applePayId: string
    setApplePayId: (applePayId: string) => void
}

interface IFormModel {
    applePayId: string
    applePayFlowType: TApplePayOnboardingType
}

const ApplePayIdStep = ({ onNextStep, onBackStep, applePayId, setApplePayId }: IApplePayIdStepProps) => {
    const { t } = useTranslation()
    const { handleSubmit, register, formState } = useForm<IFormModel>({ mode: "onChange" })
    const onSubmit: SubmitHandler<IFormModel> = async (data) => {
        setApplePayId(data.applePayId)
        onNextStep(data.applePayFlowType)
    }

    return (
        <>
            <section className="applepay-id-step">
                <div className="applepay-form-head">
                    <img className="applepay-logo" src={getImage("applePayLogo")} />
                </div>
                <div className="applepay-grey-textbox">{t("applepayonboarding.toactivate")}</div>
                <div className="applepay-sub-text">{Parser(t("applepayonboarding.donthaveone"))}</div>

                <Form onSubmit={handleSubmit(onSubmit)} className="applepay-id-form">
                    <FixedLabelInput
                        label={t("applepayonboarding.applepayid")}
                        name="applePayId"
                        innerRef={register(valFuncRequired(t("applepayonboarding.applepayiderror")))}
                        defaultValue={applePayId}
                        className="applepay-id-input"
                    />
                    {/*TODO MISSING GUIDE <span className="applepay-id-input-subtext">{Parser(t("applepayonboarding.appleidhow"))}</span> */}

                    <div className="applepay-yesno-radiogroup">
                        <TitleWithFoldableHint
                            title={t("applepayonboarding.previouslyhadcert")}
                            hint={t("applepayonboarding.existingcertinfo")}
                            titleFontSize="16"
                            icon="lightGray"
                            theme="grey"
                        />
                        <div className="applepay-yesno-radiogroup__radio-container">
                            <Input
                                type="radio"
                                id="applepay-cert-radio-yes"
                                value="existingCert"
                                innerRef={register(valFuncRequired("error"))}
                                name="applePayFlowType"
                            />
                            <label className="applepay-radio-label" htmlFor="applepay-cert-radio-yes">
                                {t("common.yes")}
                            </label>
                        </div>
                        <div className="applepay-yesno-radiogroup__radio-container">
                            <Input
                                type="radio"
                                id="applepay-cert-radio-no"
                                value="generateCert"
                                innerRef={register(valFuncRequired("error"))}
                                name="applePayFlowType"
                            />
                            <label className="applepay-radio-label" htmlFor="applepay-cert-radio-no">
                                {t("common.no")}
                            </label>
                        </div>
                    </div>

                    <ShowErrorMessages formState={formState} />
                    <SubmitButton
                        dark
                        noBorder
                        containerClass="submit-btn"
                        formState={formState}
                        disabled={!formState.isValid}
                        title={t("common.continue")}
                    />
                </Form>

                <TextButton text={t("commononboardingtexts.goback")} className="mb-5 mt-4" onClick={onBackStep} />
            </section>
        </>
    )
}

export default ApplePayIdStep
