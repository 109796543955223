import cn from "classnames"
import React, { useEffect, useState } from "react"
import { Control, SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Alert, Form, FormGroup, Input } from "reactstrap"
import {
    DropDownList,
    FloatingLabelInput,
    FormGroupWithCheckbox,
    HintIconWithTextOnClick,
    ITextInsideInput,
    PageContainer,
    PageWrapper,
    ShowErrorMessages,
    SpinnerContainer,
    TCurrency,
    TextButton,
    getExampleEmail,
    getExampleSms,
    getLanguage,
    toBase64,
    useSelector,
    valFuncPattern,
    valFuncRequired,
    validationPatterns,
} from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import DisclaimerToggle from "../../components/disclaimerToggle/DisclaimerToggle"
import FileInput, { megabyteInBytes } from "../../components/form/input/FileInput"
import CancelService from "../../components/merchant/CancelService"
import GoBackContainer from "../../components/navigations/GoBackContainer"
import { UploadedFilesOverviewText } from "../../components/overview/UploadedFilesOverview"
import { SharedCancelContainer } from "../../components/servicesonboarding/SharedCancelContainer"
import TextTranslatedWithLinks from "../../components/translated/TextTranslatedWithLinks"
import SwiipeWinbackLogo from "../../images/swFeatureServices/winback-logo.png"
import { removeSchemeFromHostName } from "../../services/merchantService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { sendServiceCancellationEmailThunk } from "../../store/thunks/swiipeServicesThunks"
import { getWebshopDetailsThunk, setWebshopFeaturesThunk } from "../../store/thunks/webshopThunks"
import {
    getWinbackDetailsThunk,
    getWinbackSmsSenderNameStatusesThunk,
    winbackOnboardingThunk,
} from "../../store/thunks/winbackThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import { IWinbackDetails } from "../../type/winback/winbackDetails"
import "./WinbackOnboardingPage.scss"

interface IWinbackOnboardingPageProps {
    webshopIds: string[]
    isEditMode?: boolean
    onGoBack: () => void
    onOnboarded: () => void
}

interface IWinbackOnboardingPageInnerProps extends IWinbackOnboardingPageProps {
    merchantDetails: MixedMerchantDetails
}

const WinbackOnboardingPage = (props: IWinbackOnboardingPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const selectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)

    if (!merchantDetails || !merchantDetails.webshops.length) {
        return null
    }

    const webshopIds =
        props.isEditMode || props.webshopIds.length === 0
            ? [selectedWebshopId || merchantDetails.webshops.at(0)!.webshopId]
            : props.webshopIds

    return <WinbackOnboardingPageInner {...props} merchantDetails={merchantDetails} webshopIds={webshopIds} />
}

const WinbackOnboardingPageInner = ({
    merchantDetails,
    webshopIds,
    isEditMode,
    onGoBack,
    onOnboarded,
}: IWinbackOnboardingPageInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const serviceName = t("serviceNames.winback")
    const [webshopRedirectUrl, setWebshopRedirectUrl] = useState<string>("")

    const { control, handleSubmit, register, trigger, clearErrors, setError, getValues, setValue, formState } = useForm<any>()

    const [showCancelService, setShowCancelService] = useState(false)

    const swMerchantId = merchantDetails.swMerchant.swMerchantId

    const webshopDetails = useSelector((state: StoreState) => merchantSelectors.webshopDetails(state, webshopIds[0]))
    const winbackDetails = useSelector((state: StoreState) => merchantSelectors.winbackDetails(state, webshopIds[0]))
    const { statusInsideInput, showNoticeUnderInput, smsSenderMaxLength } = useSmsSenderInputConfig(swMerchantId, control)

    const supportedCurrencies: { text: TCurrency; value: TCurrency }[] = [
        { text: "DKK", value: "DKK" },
        { text: "SEK", value: "SEK" },
        { text: "NOK", value: "NOK" },
    ]

    useEffect(() => {
        if (!isEditMode) {
            return
        }
        dispatch(getWebshopDetailsThunk(webshopIds[0]))
        dispatch(getWinbackDetailsThunk(swMerchantId, webshopIds[0]))
    }, [])

    useEffect(() => {
        if (!winbackDetails || !isEditMode) {
            return
        }

        setValue("smsSenderName-0", winbackDetails.smsSenderName)
        setValue("signer-0", winbackDetails.signerName)
        setValue("useWebshopRedirect-0", winbackDetails.useWebshopRedirect)
        setWebshopRedirectUrl(winbackDetails.webshopRedirectUrl ?? "")
        setValue("emailEnabled", winbackDetails.emailEnabled ? "on" : false)
        setValue("smsEnabled", winbackDetails.smsEnabled ? "on" : false)
        setValue("orderPriceLimitationsCurrency", winbackDetails.orderPriceLimitationsCurrency)

        if (winbackDetails.minOrderAmount > 0) {
            setValue("minOrderAmount", (parseFloat(winbackDetails.minOrderAmount.toString()) / 100).toString())
        }
        if (winbackDetails.maxOrderAmount > 0) {
            setValue("maxOrderAmount", (parseFloat(winbackDetails.maxOrderAmount.toString()) / 100).toString())
        }
    }, [winbackDetails])

    const onSubmit: SubmitHandler<any> = async (data) => {
        try {
            const smsEnabled = data["smsEnabled"]
            const emailEnabled = data["emailEnabled"]

            if (!emailEnabled && !smsEnabled) {
                setError("emailEnabled", { message: t("winbackonboarding.errors.communicationChannel") })
                return
            }

            const winbackDetails: IWinbackDetails[] = []
            const logos: (File | null)[] = []

            for (let i = 0; i < webshopIds.length; i++) {
                winbackDetails.push({
                    smsSenderName: data[`smsSenderName-${i}`],
                    signerName: data[`signer-${i}`],
                    minOrderAmount: parseFloat(data["minOrderAmount"]) * 100,
                    maxOrderAmount: parseFloat(data["maxOrderAmount"]) * 100,
                    orderPriceLimitationsCurrency: data["orderPriceLimitationsCurrency"],
                    useWebshopRedirect: data[`useWebshopRedirect-${i}`],
                    smsEnabled: !!smsEnabled,
                    emailEnabled: !!emailEnabled,
                } as IWinbackDetails)

                const files = data[`webshopLogo-${i}`]
                if (files && files.length > 0) {
                    logos.push(files[0])
                } else {
                    logos.push(null)
                }
            }

            await dispatch(winbackOnboardingThunk(swMerchantId, webshopIds, winbackDetails, !isEditMode, logos))

            onOnboarded()
        } catch {
            //Catch to stop showing spinner
        }
    }

    return (
        <>
            {showCancelService && (
                <CancelService
                    serviceName={serviceName}
                    cancelText={t("cancelService.featureCanceledCommon.cancelText", { serviceName })}
                    customCancelledText={t("cancelService.featureCanceledCommon.cancelledText", {
                        serviceName,
                    })}
                    onGoBack={() => setShowCancelService(false)}
                    onCancel={async () => {
                        await dispatch(setWebshopFeaturesThunk(webshopIds, "Winback", false, swMerchantId))

                        await dispatch(
                            sendServiceCancellationEmailThunk(swMerchantId, "GeneralServiceCancellationInstantEmail", serviceName)
                        )
                    }}
                />
            )}
            <SpinnerContainer showSpinner={!!isEditMode && !winbackDetails}>
                <PageWrapper className={cn({ "d-none": showCancelService })}>
                    {isEditMode && (
                        <GoBackContainer goBackText={t("commononboardingtexts.backToAllService")} onGoBack={onGoBack} />
                    )}
                    <PageContainer noHeader={isEditMode} disableDefaultPaddingBottom>
                        <div className="winback-onboarding-container">
                            <div className="winback-form-head">
                                <img className="winback-logo" src={SwiipeWinbackLogo} />
                            </div>

                            <div className="winback-info text-center mt-4">{t("winbackonboarding.info")}</div>

                            <Form onSubmit={handleSubmit(onSubmit)} className="winback-form">
                                {webshopIds.map((id, i) => {
                                    return (
                                        <div key={id}>
                                            <FormGroup className="mt-4">
                                                <FloatingLabelInput
                                                    name={`webshopName-${i}`}
                                                    innerRef={register}
                                                    placeholder={t("winbackonboarding.webshopname")}
                                                    defaultValue={removeSchemeFromHostName(
                                                        merchantDetails.webshops.find((w) => w.webshopId === webshopIds[i])!
                                                            .hostName
                                                    )}
                                                    disabled
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <FloatingLabelInput
                                                    name={`smsSenderName-${i}`}
                                                    innerRef={register(
                                                        valFuncPattern(
                                                            validationPatterns.smsSenderName,
                                                            t("winbackonboarding.errors.smsSenderName")
                                                        )
                                                    )}
                                                    maxLength={smsSenderMaxLength}
                                                    placeholder={t("winbackonboarding.smsSenderName.placeholder")}
                                                    textInsideInput={statusInsideInput}
                                                />
                                            </FormGroup>
                                            {showNoticeUnderInput && (
                                                <Alert color="warning">
                                                    {t("winbackonboarding.smsSenderName.approvalNotice")}
                                                </Alert>
                                            )}

                                            <FormGroup>
                                                <FloatingLabelInput
                                                    name={`signer-${i}`}
                                                    innerRef={register(valFuncRequired(t("winbackonboarding.errors.signer")))}
                                                    placeholder={t("winbackonboarding.signer")}
                                                />
                                            </FormGroup>

                                            <p className="input-legend mb-0">{t("winbackonboarding.uploadlogo")}</p>
                                            <FileInput
                                                name={`webshopLogo-${i}`}
                                                triggerValidation={trigger}
                                                maxFileSizeBytes={megabyteInBytes * 1}
                                                clearError={clearErrors}
                                                setError={setError}
                                                setValue={setValue}
                                                getValues={getValues}
                                                register={register}
                                                maxFilesAmount={1}
                                                accept="image/jpeg, image/png"
                                            />

                                            {webshopDetails?.webshopLogoName && (
                                                <>
                                                    <p className="input-legend mb-0">{t("common.uploadedLogo")}</p>
                                                    <UploadedFilesOverviewText fileNames={[webshopDetails.webshopLogoName]} />
                                                </>
                                            )}

                                            <DisclaimerToggle
                                                toggleShowText={t("winbackonboarding.seeadvancedsettings")}
                                                toggleHideText={t("winbackonboarding.hideadvancedsettings")}
                                                className="my-3"
                                            >
                                                <FormGroupWithCheckbox
                                                    defaultChecked={!isEditMode}
                                                    name={`useWebshopRedirect-${i}`}
                                                    innerRef={register}
                                                    hint={t("winbackonboarding.redirecturlhint")}
                                                >
                                                    <div className="checkbox">{t("winbackonboarding.useredirecturl")}</div>
                                                </FormGroupWithCheckbox>
                                                {isEditMode && !!webshopRedirectUrl ? (
                                                    <Input defaultValue={webshopRedirectUrl} disabled />
                                                ) : (
                                                    <div className="alert alert-danger deleted">
                                                        <TextTranslatedWithLinks
                                                            textTranslationKey="winbackonboarding.redirecturlpending"
                                                            linkKeyInText="{{linkToPortal}}"
                                                            linkTranslationKey="winbackonboarding.redirecturlpendinglink"
                                                            linkAddress="/plugins"
                                                            openInNewTab
                                                        />
                                                    </div>
                                                )}
                                            </DisclaimerToggle>
                                        </div>
                                    )
                                })}

                                <p className="input-legend mb-2 mt-4">{t("winbackonboarding.selectchannels")}</p>
                                <div className="checkbox-container">
                                    <FormGroupWithCheckbox name="emailEnabled" innerRef={register} className="mb-1">
                                        <div className="checkbox">{t("common.email.label")}</div>
                                    </FormGroupWithCheckbox>
                                    <TextButton
                                        className="see"
                                        text={t("common.seeAnExample")}
                                        fontStyle="italic"
                                        color="grey"
                                        textDecoration="underline"
                                        onClick={async () => {
                                            const baseData = {
                                                customersName: "Hans Christian Andersen",
                                                webshopName: getValues()["webshopName-0"],
                                                signer: getValues()["signer-0"],
                                                winbackLink: "javascript:void(0)",
                                                nemIDAppLink: "javascript:void(0)",
                                                mitIdLink: "javascript:void(0)",
                                            }

                                            const image = getValues("webshopLogo-0")[0]

                                            const data =
                                                image || webshopDetails?.webshopLogoUrl
                                                    ? {
                                                          ...baseData,
                                                          webshopLogoUrl: image
                                                              ? await toBase64(image)
                                                              : webshopDetails!.webshopLogoUrl,
                                                      }
                                                    : { ...baseData }
                                            const email = await dispatch(
                                                getExampleEmail(swMerchantId, "WinbackNotification", getLanguage(), data)
                                            )

                                            await dispatch(
                                                addModalThunk({
                                                    type: "email",
                                                    title: email.subject,
                                                    body: email.body,
                                                })
                                            )
                                        }}
                                    />
                                </div>
                                <div className="checkbox-container">
                                    <FormGroupWithCheckbox name="smsEnabled" innerRef={register}>
                                        <div className="checkbox">SMS</div>
                                    </FormGroupWithCheckbox>
                                    <TextButton
                                        className="see"
                                        text={t("common.seeAnExample")}
                                        fontStyle="italic"
                                        color="grey"
                                        textDecoration="underline"
                                        onClick={async () => {
                                            const data = {
                                                customersName: "Hans",
                                                webshopName: getValues()["webshopName-0"],
                                                signer: getValues()["signer-0"],
                                                winbackLink: "<link>",
                                                nemIDAppLink: "<link>",
                                            }

                                            const sms = await dispatch(
                                                getExampleSms(swMerchantId, "WinbackNotification", getLanguage(), data)
                                            )

                                            await dispatch(
                                                addModalThunk({
                                                    type: "sms",
                                                    title: getValues()["smsSenderName-0"] || undefined,
                                                    body: sms.body,
                                                })
                                            )
                                        }}
                                    />
                                </div>

                                <FormGroup>
                                    <p className="input-legend mb-3 mt-4">
                                        {t("winbackonboarding.orderPriceLimits.title")}
                                        <HintIconWithTextOnClick
                                            showHintOnHover
                                            hintText={
                                                <>
                                                    {t("winbackonboarding.orderPriceLimits.hint.neither")}
                                                    <br />
                                                    <br />
                                                    {t("winbackonboarding.orderPriceLimits.hint.min")}
                                                    <br />
                                                    <br />
                                                    {t("winbackonboarding.orderPriceLimits.hint.max")}
                                                </>
                                            }
                                        />
                                    </p>
                                    <div className="order-price-limits" style={{ gap: 5 }}>
                                        <FloatingLabelInput
                                            name="minOrderAmount"
                                            type="number"
                                            step={0.01}
                                            inputMode="decimal"
                                            innerRef={register}
                                            placeholder={t("winbackonboarding.orderPriceLimits.min")}
                                        />
                                        <FloatingLabelInput
                                            className="mt-5"
                                            name="maxOrderAmount"
                                            type="number"
                                            step={0.01}
                                            inputMode="decimal"
                                            innerRef={register}
                                            placeholder={t("winbackonboarding.orderPriceLimits.max")}
                                        />
                                        <DropDownList
                                            className="order-price-limits__currency-select"
                                            name="orderPriceLimitationsCurrency"
                                            options={supportedCurrencies}
                                            defaultValue="DKK"
                                            innerRef={register}
                                        />
                                    </div>
                                </FormGroup>

                                <div className="winback-onboarding-form mt-4 mb-3">
                                    <ShowErrorMessages formState={formState} />

                                    <SubmitButton dark noBorder noBorderRadius containerClass="submit-btn" formState={formState}>
                                        {isEditMode ? t("winbackonboarding.save") : t("commononboardingtexts.submit")}
                                    </SubmitButton>
                                </div>
                            </Form>

                            {!isEditMode && (
                                <TextButton
                                    text={t("commononboardingtexts.back")}
                                    className="mt-3 mb-4"
                                    onClick={async () => onGoBack()}
                                />
                            )}
                        </div>
                    </PageContainer>
                    {isEditMode && (
                        <SharedCancelContainer
                            cancelText={t("winbackonboarding.cancellation.cancel")}
                            onCancel={() => setShowCancelService(true)}
                        />
                    )}
                </PageWrapper>
            </SpinnerContainer>
        </>
    )
}

const useSmsSenderInputConfig = (swMerchantId: string, control: Control | undefined) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const [inputValue, setInputValue] = useState<string | undefined>()
    const [senderNameStatus, setSenderNameStatus] = useState<ITextInsideInput | undefined>()
    const [showApprovalNotice, setShowApprovalNotice] = useState(false)

    const smsSenderMaxLength = 11
    const inputName = "smsSenderName-0"
    const senderNameWatch = useWatch({
        control,
        name: [inputName],
    })

    const winbackSmsSenderNameStatuses = useSelector((state: StoreState) =>
        merchantSelectors.winbackSmsSenderNameStatuses(state, swMerchantId)
    )
    useEffect(() => {
        dispatch(getWinbackSmsSenderNameStatusesThunk(swMerchantId))
    }, [])

    useEffect(() => {
        const senderNameValue = control?.getValues(inputName)
        if (inputValue === senderNameValue) {
            return
        }
        setInputValue(senderNameValue)
    }, [senderNameWatch])

    useEffect(() => {
        const defaultTextInsideInput: ITextInsideInput = {
            text: t("winbackonboarding.smsSenderName.maxCharacters"),
            color: "grey",
            fontStyle: "italic",
        }

        if (!inputValue || !winbackSmsSenderNameStatuses) {
            setSenderNameStatus(defaultTextInsideInput)
            setShowApprovalNotice(false)
            return
        }

        const status = winbackSmsSenderNameStatuses.find((s) => s.smsSenderName === inputValue)
        if (!status) {
            setSenderNameStatus({
                ...defaultTextInsideInput,
                color: inputValue.length >= smsSenderMaxLength ? "red" : "grey",
            })
            setShowApprovalNotice(true)
            return
        }

        if (status.smsSenderNameStatus === "Approved") {
            setSenderNameStatus({
                text: t("winbackonboarding.smsSenderName.status.approved"),
                color: "green",
                fontStyle: "italic",
            })
            setShowApprovalNotice(false)
            return
        }

        if (status.smsSenderNameStatus === "Pending") {
            setSenderNameStatus({
                text: t("winbackonboarding.smsSenderName.status.awaitingApproval"),
                color: "red",
                fontStyle: "italic",
            })
            setShowApprovalNotice(false)
            return
        }
    }, [inputValue, winbackSmsSenderNameStatuses])

    return { statusInsideInput: senderNameStatus, showNoticeUnderInput: showApprovalNotice, smsSenderMaxLength }
}

export default WinbackOnboardingPage
