import { FieldError } from "react-hook-form"
import { getKeys } from "swiipe.portal.shared"

export function prepareFormData<T extends { [key: string]: any }, K extends keyof T>(form: T, checkboxProps: K[]) {
    ;(getKeys(form) as K[]).forEach(function (key: K, index) {
        const value = form[key]
        if (typeof value === "string") {
            form[key] = value.trim()
            if ((key as string).toLowerCase().indexOf("email") >= 0) {
                // Email should always be lower case
                form[key] = form[key].toLowerCase()
            }

            if (checkboxProps.indexOf(key) >= 0) {
                // Form checkbox value is string ("on") instead of boolean (true/false)
                // Make it into a boolean
                ;(form[key] as boolean) = value === "on"
            }
        }
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                // Currently only supports array of objects, not array of values
                form[key] = value.map((v: { [key: string]: any }) => prepareFormData(v, []))
            } else {
                form[key] = prepareFormData(value, [])
            }
        }
    })
    return form
}

export function getFieldNameWithWildcardIndex(fieldName: string) {
    return fieldName.replace(/\[[0-9]+\]/g, "[*]")
}

export function extractFieldErrors<T extends object>(errorsObj: T, fieldName = "") {
    const errors: Array<{ fieldName: string; error: FieldError }> = []

    const isFieldError = (value: unknown): value is FieldError => {
        return (
            typeof value === "object" && value !== null && "message" in value && typeof (value as FieldError).message === "string"
        )
    }

    if (!errorsObj) {
        return errors
    }

    getKeys(errorsObj).forEach((key) => {
        const value = errorsObj[key as keyof T]

        if (!value || typeof value !== "object" || value === null) {
            return
        }

        if (value instanceof Array) {
            for (let i = 0; i < value.length; i++) {
                const itemPath = `${fieldName}${key}[${i}].`
                errors.push(...extractFieldErrors(value[i], itemPath))
            }
            return
        }

        if (isFieldError(value)) {
            errors.push({ fieldName: fieldName + key, error: value as FieldError })
            return
        }

        const itemPath = `${fieldName}${key}.`
        errors.push(...extractFieldErrors(value, itemPath))
    })

    return errors
}

export function serializeRequestAsFormData(requestData?: any) {
    if (!requestData) {
        return
    }

    const formData = new FormData()

    const serializeObject = (obj: any, prefix?: string) => {
        Object.keys(obj).forEach((key) => {
            const propName = prefix ? `${prefix}[${key}]` : key
            const propValue = obj[key]

            if (typeof propValue === "undefined") {
                return
            }

            //handle arrays
            if (Array.isArray(propValue)) {
                propValue.forEach((el) => {
                    formData.append(propName, el)
                })
                return
            }

            //handle objects
            if (Object.prototype.toString.call(propValue) === "[object Object]") {
                serializeObject(propValue, propName)
                return
            }

            formData.append(propName, propValue)
        })
    }

    serializeObject(requestData)

    return formData
}

export function formToSelectors(formOrSubForm: any): any[] | undefined {
    if (typeof formOrSubForm === "object") {
        if (Array.isArray(formOrSubForm)) {
            return formOrSubForm
                .map((value, index) => {
                    const subSelectors = formToSelectors(value)
                    if (!subSelectors) {
                        return `[${index}]`
                    }
                    return subSelectors.map((subSelector) => `[${index}].${subSelector}`)
                })
                .flat()
        } else {
            return Object.keys(formOrSubForm)
                .map((key) => {
                    const subSelectors = formToSelectors(formOrSubForm[key])
                    if (!subSelectors) {
                        return key
                    }
                    return subSelectors.map((subSelector) => `${key}.${subSelector}`)
                })
                .flat()
        }
    } else {
        return undefined
    }
}

export function getValueFromSelector(selector: string, formOrSubForm: any): string | number | boolean {
    if (!formOrSubForm) {
        return formOrSubForm
    }
    const parts = selector.split(".")
    if (parts.length === 0 || (parts.length === 1 && !parts[0])) {
        return formOrSubForm
    }
    let key: string | number = parts[0]
    if (parts[0].startsWith("[")) {
        key = parseInt(parts[0].replace("[", "").replace("]", ""))
    }
    return getValueFromSelector(parts.slice(1, parts.length).join("."), formOrSubForm[key])
}
