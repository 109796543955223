import React, { useEffect, useState } from "react"
import { MerchantOfferSteps, SpinnerContainer, hasValue, useSelector, userSelectors } from "swiipe.portal.shared"
import { isAllTermsAccepted } from "../../services/legalService"
import {
    getMerchantOfferCurrentStep,
    getRequiredTermsForMerchantPreOnboardingServices,
    isCurrentPageInMerchantOfferAcquiringOnboardingFlow,
    useMerchantPreOnboardingDetails,
} from "../../services/merchantOfferService"
import { clearhausSelectors } from "../../store/reducers/clearhausReducer"
import { legalSelectors } from "../../store/reducers/legalReducer"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { partnerSelectors } from "../../store/reducers/partnerReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { getClearhausApplicationStatesThunk } from "../../store/thunks/clearhausThunks"
import {
    merchantOfferCheckForCompletionThunk,
    merchantOfferNavigateToStepThunk,
    merchantOfferTryAddKycEventThunk,
} from "../../store/thunks/merchantPreOnboardingThunks"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { addDays } from "../../util/dateUtil"
import "./MerchantOfferBanner.scss"

interface IMerchantOfferBannerProps {}

const MerchantOfferBanner = ({}: IMerchantOfferBannerProps) => {
    const dispatch = useReduxDispatch()
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const preOnboardingDetails = useMerchantPreOnboardingDetails()
    const userData = useSelector(userSelectors.userData)
    const hasMasterPartner = !!useSelector(partnerSelectors.partnersContainMasterPartner)

    const termsStatuses = useSelector(legalSelectors.termsStatuses)
    const needsAcquiring = preOnboardingDetails?.preOnboarding?.requiresAcquiring ?? false
    const allClearhausApplicationStates = useSelector(clearhausSelectors.applicationStates)
    const relevantClearhausApplications =
        merchantDetails?.webshops.map((w) => allClearhausApplicationStates?.[w.webshopId]).filter(hasValue) ?? []
    const hasFetchedApplications = relevantClearhausApplications.length > 0

    const canShowModal = userData?.user.email === preOnboardingDetails?.owner?.email || !hasMasterPartner

    const [maxStepAllowed, setMaxStepAllowed] = useState<number>(-1)

    const hasRequiredData =
        !!preOnboardingDetails?.exists && !!termsStatuses && !!merchantDetails && (!needsAcquiring || hasFetchedApplications)

    useEffect(() => {
        if (
            preOnboardingDetails?.exists &&
            !preOnboardingDetails.preOnboarding.isCompleted &&
            merchantDetails &&
            needsAcquiring &&
            !preOnboardingDetails.preOnboarding.isAcquiringOnboarded
        ) {
            dispatch(
                getClearhausApplicationStatesThunk(
                    merchantDetails.swMerchant.swMerchantId,
                    merchantDetails.webshops.map((w) => w.webshopId),
                    false
                )
            )
        }
        if (preOnboardingDetails?.exists && preOnboardingDetails.preOnboarding.isCompleted) {
            dispatch(merchantOfferCheckForCompletionThunk())
        }
    }, [preOnboardingDetails, merchantDetails])

    useEffect(() => {
        if (preOnboardingDetails?.exists && !preOnboardingDetails.preOnboarding.isAcquiringOnboarded) {
            dispatch(merchantOfferTryAddKycEventThunk())
        }
    }, [preOnboardingDetails, relevantClearhausApplications])

    useEffect(() => {
        if (!hasRequiredData) {
            return
        }

        const termsRequired = getRequiredTermsForMerchantPreOnboardingServices(preOnboardingDetails.services)
        if (isAllTermsAccepted(termsRequired, termsStatuses)) {
            setMaxStepAllowed(3)
            if (
                canShowModal &&
                needsAcquiring &&
                !preOnboardingDetails.preOnboarding.isAcquiringOnboarded &&
                !isCurrentPageInMerchantOfferAcquiringOnboardingFlow()
            ) {
                const lastShown = localStorage.getItem("lastShownMerchantOfferKycModal-" + preOnboardingDetails.swMerchantId)
                if (!lastShown || addDays(new Date(lastShown), 1).getTime() < new Date().getTime()) {
                    localStorage.setItem(
                        "lastShownMerchantOfferKycModal-" + preOnboardingDetails.swMerchantId,
                        new Date().toISOString()
                    )
                    dispatch(
                        addModalThunk({
                            type: "merchantOfferNavigateKyc",
                            countryCode: merchantDetails.swMerchant.countryCode,
                        })
                    )
                }
            }
        } else {
            setMaxStepAllowed(2)
        }
    }, [currentUserRelation, hasRequiredData])

    if (!preOnboardingDetails?.exists || preOnboardingDetails.preOnboarding?.isCompleted) {
        return null
    }

    return (
        <div className="merchant-offer-banner">
            <SpinnerContainer showSpinner={!hasRequiredData} minHeight={60}>
                {hasRequiredData && (
                    <MerchantOfferSteps
                        currentStep={getMerchantOfferCurrentStep()}
                        maxStepAllowed={maxStepAllowed}
                        onChangeStep={(newStep) => {
                            dispatch(merchantOfferNavigateToStepThunk(newStep))
                        }}
                        includeKyc={needsAcquiring}
                    />
                )}
            </SpinnerContainer>
        </div>
    )
}

export default MerchantOfferBanner
