import cn from "classnames"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentRoute } from "react-navi"
import {
    DropDownList,
    ITableNavigationControl,
    ITableResetControl,
    PageContainer,
    PageWrapper,
    StandardButtonWithSpinner,
    TCreditCardType,
    TableContainer,
    getFormattedNumber,
    getQueryValueFromCurrentUrl,
    useSelector,
} from "swiipe.portal.shared"
import CreditCardTypeLogo from "../../components/form/paymentmethods/CreditCardTypeLogo"
import WebshopSelect, { useInitialWebshopValuesForWebshopSelect } from "../../components/merchant/WebshopSelect"
import OrdersFilters from "../../components/orders/OrdersFilters"
import { SingleOrderReview } from "../../components/orders/SingleOrderReview"
import ToolTipContainer from "../../components/text/ToolTipContainer"
import WinbackIcon from "../../images/swFeatureServices/winback-icon.png"
import { getFormattedDate } from "../../services/dateTimeService"
import { navigationService } from "../../services/navigationService"
import {
    getActionReportData,
    getBulkCancelModalAnswer,
    getBulkCaptureRefundModalAnswer,
    getIconFromPaymentType,
    getPlatformOrderIdFormatted,
    resolveOrderStatusText,
    resolvePaymentTypeNameForOrder,
    resolvePaymentTypeText,
    resolvePlusSellIcon,
    resolvePlusSellText,
    resolveReturnSellIcon,
    resolveReturnSellText,
    shouldShowPlusSellIcon,
    shouldShowReturnSellIcon,
    showPleaseSelectActionErrorModal,
    showPleaseSelectOrdersErrorModal,
} from "../../services/orderService"
import { getLogoByCardType } from "../../services/paymentService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { orderSelectors } from "../../store/reducers/orderReducer"
import { addModalThunk } from "../../store/thunks/modalThunks"
import {
    exportOrdersThunk,
    getOrderThunk,
    getOrdersPerWebshopThunk,
    updateOrderFiltersThunk,
} from "../../store/thunks/orderThunks"
import { TBulkActionType, voidThunk } from "../../store/thunks/transactionThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { Order, SortOrdersByPropType, TOrderStatusFilter } from "../../type/Order"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import { capitalizeFirstLetter } from "../../util/stringUtil"
import { merchantSelectors } from "./../../store/reducers/merchantReducer"
import "./OrdersPage.scss"

interface IOrdersPageProps {}
interface IOrdersPageInnerProps {
    merchantDetails: MixedMerchantDetails
}

const OrdersPage = ({}: IOrdersPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    if (!merchantDetails || merchantDetails.webshops.length === 0) {
        return null
    }

    return <OrdersPageInner merchantDetails={merchantDetails} />
}

const OrdersPageInner = ({ merchantDetails }: IOrdersPageInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const { initialWebshopId } = useInitialWebshopValuesForWebshopSelect(merchantDetails)
    const [selectedWebshopId, setSelectedWebshopId] = useState(initialWebshopId)

    const resetControl = useMemo<ITableResetControl>(() => ({}), [])
    const navigationControl = useMemo<ITableNavigationControl<Order>>(() => ({}), [])

    const route = useCurrentRoute()

    const [showSingleWithOrderId, setShowSingleWithOrderId] = useState(getQueryValueFromCurrentUrl("orderId") ?? undefined)

    const [exportType, setExportType] = useState("Csv")

    const webshopPlatform = merchantDetails.webshops.find((webshop) => webshop.webshopId === selectedWebshopId)?.platform

    const currentFilters = useSelector(orderSelectors.getOrderFilter)

    const orders = useSelector<StoreState, Order[] | undefined>((state) =>
        orderSelectors.getOrdersForWebshop(state, selectedWebshopId)
    )

    const continuationToken = useSelector<StoreState, string | undefined>((state) =>
        orderSelectors.getContinuationTokenPerMerchant(state, selectedWebshopId)
    )

    useEffect(() => {
        if (showSingleWithOrderId) {
            dispatch(getOrderThunk(selectedWebshopId, showSingleWithOrderId, false))
        }
    }, [showSingleWithOrderId])

    useEffect(() => {
        const orderId = route.url.query?.["orderId"]
        if (orderId) {
            setShowSingleWithOrderId(orderId)
        }
    }, [route])

    useEffect(() => {
        resetControl?.reset?.({
            resetPage: true,
        })
    }, [currentFilters])

    // TODO: Test can be removed? Should be same as currentFilters
    useEffect(() => {
        resetControl?.reset?.({
            resetPage: true,
        })
    }, [currentFilters.dateFrom, currentFilters.dateTo])

    useEffect(() => {
        resetControl?.reset?.({
            resetPage: true,
            resetSearch: true,
        })
    }, [selectedWebshopId])

    return (
        <>
            {showSingleWithOrderId && (
                <SingleOrderReview
                    onGoBack={() => {
                        navigationService.navigateSamePageChangeParams({}, "clearExistingParams")
                        setShowSingleWithOrderId(undefined)
                    }}
                    onShowAnotherOrder={(orderId) => setShowSingleWithOrderId(orderId)}
                    orderId={showSingleWithOrderId}
                    selectedWebshopId={selectedWebshopId}
                    merchantDetails={merchantDetails}
                    navigationControl={navigationControl}
                />
            )}
            <div className={cn({ "d-none": showSingleWithOrderId })}>
                <PageWrapper disableMinHeight widest>
                    <PageContainer>
                        <WebshopSelect
                            merchantDetails={merchantDetails}
                            onWebshopChange={(selectedWebshopId: string) => {
                                setSelectedWebshopId(selectedWebshopId)
                            }}
                        />
                    </PageContainer>
                </PageWrapper>
                <PageWrapper widest>
                    <PageContainer classNameBody="merchant-orders-page">
                        <TableContainer<Order, SortOrdersByPropType>
                            title={t("transactions.orders")}
                            emptyDescription={t("transactions.noOrders")}
                            items={orders}
                            getItemId={(o) => o.orderId}
                            onShowItemId={async (itemId: string) => {
                                navigationService.navigateSamePageChangeParams({ orderId: itemId }, "clearExistingParams")
                                setShowSingleWithOrderId(itemId)
                            }}
                            shownItemId={showSingleWithOrderId}
                            resetControl={resetControl}
                            navigationControl={navigationControl}
                            className="merchant-orders-spinner-container"
                            morePagesAvailable={!!continuationToken}
                            fetchItems={async (pageNumber, perPage, force) => {
                                await dispatch(getOrdersPerWebshopThunk(selectedWebshopId, pageNumber, perPage, force, force))
                            }}
                            fetchItemsHash={selectedWebshopId + JSON.stringify(currentFilters)}
                            showRefresh
                            search={{
                                searchPlaceholder: t("transactions.searchbyreference"),
                                handleSearch: async (search: string) => {
                                    dispatch(
                                        updateOrderFiltersThunk(
                                            (filters) => (filters.referenceOrderIdQuery = search ? search : undefined)
                                        )
                                    )
                                },
                            }}
                            bulkActions={{
                                items: [
                                    { title: t("transactions.bulkActionsDropDown.capture"), value: "capture" },
                                    { title: t("transactions.bulkActionsDropDown.refund"), value: "refund" },
                                    { title: t("transactions.bulkActionsDropDown.void"), value: "void" },
                                ],
                                onBulkAction: async (value, selectedOrders, refresh) => {
                                    if (!["capture", "refund", "void"].includes(value)) {
                                        showPleaseSelectActionErrorModal(dispatch)
                                        return
                                    }

                                    if (selectedOrders.length == 0) {
                                        showPleaseSelectOrdersErrorModal(dispatch, value)
                                        return
                                    }

                                    const modalAnswer =
                                        value === "void"
                                            ? await getBulkCancelModalAnswer(dispatch, selectedOrders)
                                            : await getBulkCaptureRefundModalAnswer(dispatch, selectedOrders, value)

                                    if (modalAnswer.type === "accepted") {
                                        const actionTitle = t(`transactions.${value}Modal.title`)
                                        await dispatch(
                                            addModalThunk({
                                                type: "bulkActions",
                                                title: actionTitle,
                                                actionType: capitalizeFirstLetter(value) as TBulkActionType,
                                                webshopId: selectedWebshopId,
                                                selectedOrders: selectedOrders,
                                                onUpdate: refresh,
                                            })
                                        )
                                    }
                                },
                            }}
                            tabs={{
                                tabItems: [
                                    { id: undefined, title: t("transactions.orderStatuses.all") },
                                    { id: TOrderStatusFilter.Authorized, title: t("transactions.orderStatuses.auth") },
                                    { id: TOrderStatusFilter.Captured, title: t("transactions.orderStatuses.captured") },
                                    { id: TOrderStatusFilter.Refunded, title: t("transactions.orderStatuses.refunded") },
                                    { id: TOrderStatusFilter.AuthFailed, title: t("transactions.orderStatuses.authFailed") },
                                    { id: TOrderStatusFilter.Voided, title: t("transactions.orderStatuses.voided") },
                                    { id: TOrderStatusFilter.WithWinback, title: "Win-back" },
                                    { id: TOrderStatusFilter.WithPlusSell, title: "Plus-Sell" },
                                    { id: TOrderStatusFilter.WithReturnSell, title: "Return-Sell" },
                                    { id: "paymentLinks", title: t("orderReview.paymentLink").toUpperCase() },
                                ],
                                initialTabId: currentFilters.isPaymentLinkOrders ? "paymentLinks" : undefined,
                                onTabChange: (id) => {
                                    dispatch(
                                        updateOrderFiltersThunk((filters) => {
                                            filters.orderStatus =
                                                !id || id === "paymentLinks" ? undefined : (id as TOrderStatusFilter)
                                            filters.isPaymentLinkOrders = id === "paymentLinks"
                                        })
                                    )
                                },
                            }}
                            sorting={{
                                initialSortingValue: SortOrdersByPropType.Created,
                                initialSortingDirection: currentFilters?.isOrderByDescending ?? true ? "descending" : "ascending",
                                onSortingChange: (value, direction) => {
                                    dispatch(
                                        updateOrderFiltersThunk((filters) => {
                                            filters.sortByProperty = value
                                            filters.isOrderByDescending = direction === "descending"
                                        })
                                    )
                                },
                                sortingItems: [
                                    { title: t("transactions.created"), value: SortOrdersByPropType.Created },
                                    { title: t("transactions.updated"), value: SortOrdersByPropType.Updated },
                                    {
                                        title:
                                            webshopPlatform === "prestashop"
                                                ? t("transactions.cartid")
                                                : t("transactions.orderid"),
                                        value: SortOrdersByPropType.PlatformOrderId,
                                    },
                                ],
                            }}
                            filtering={{
                                renderSubMenuFilters: (showFilters) => <OrdersFilters showFilters={showFilters} />,
                            }}
                            renderHeaderCells={() => [
                                {
                                    className: "merchant-orders-page__header-cell order__platform-order-id",
                                    content:
                                        webshopPlatform === "prestashop" ? t("transactions.cartid") : t("transactions.orderid"),
                                },
                                {
                                    className: "merchant-orders-page__header-cell payment__created-date",
                                    content: t("transactions.created"),
                                },
                                { className: "merchant-orders-page__header-cell", content: t("transactions.updated") },
                                {
                                    className: "merchant-orders-page__header-cell payment__currency",
                                    content: t("transactions.currency"),
                                },
                                {
                                    className: "merchant-orders-page__header-cell payment__amount",
                                    content: t("transactions.amount"),
                                },
                                {
                                    className: "merchant-orders-page__header-cell payment-type",
                                    content: t("transactions.payment"),
                                },
                                { className: "merchant-orders-page__header-cell payment__icons", content: "" },
                                { className: "merchant-orders-page__header-cell", content: t("transactions.status") },
                                {
                                    className: "merchant-orders-page__header-cell actions-column-header",
                                    content: t("transactions.actions"),
                                },
                            ]}
                            renderRowCells={(order, isSelected, selectOrder, refresh) => {
                                const po = order
                                const created = getFormattedDate(po.created, "slashes")
                                const updated = getFormattedDate(po.updated, "slashes")
                                const platformOrderIdFormatted = getPlatformOrderIdFormatted(po.platformOrderId)

                                return [
                                    {
                                        className: cn("merchant-orders-page__data-cell", "order__platform-order-id", {
                                            "order__platform-order-id--long": platformOrderIdFormatted.length > 7,
                                        }),
                                        onClick: async () => selectOrder(),
                                        content: (
                                            <ToolTipContainer
                                                centerY
                                                noWrap
                                                text={platformOrderIdFormatted ?? ""}
                                                disable={platformOrderIdFormatted ? platformOrderIdFormatted.length < 9 : true}
                                            >
                                                <span className="pl-order-id-clickable">
                                                    {platformOrderIdFormatted && platformOrderIdFormatted.length > 8
                                                        ? ".." +
                                                          platformOrderIdFormatted.substring(
                                                              platformOrderIdFormatted.length - 8,
                                                              platformOrderIdFormatted.length
                                                          )
                                                        : platformOrderIdFormatted}
                                                </span>
                                            </ToolTipContainer>
                                        ),
                                    },
                                    {
                                        className: "merchant-orders-page__data-cell payment__created-date",
                                        onClick: async () => selectOrder(),
                                        content: created,
                                    },
                                    {
                                        className: "merchant-orders-page__data-cell",
                                        onClick: async () => selectOrder(),
                                        content: updated,
                                    },
                                    {
                                        className: "merchant-orders-page__data-cell payment__currency",
                                        onClick: async () => selectOrder(),
                                        content: po.currency,
                                    },
                                    {
                                        className: "merchant-orders-page__data-cell payment__amount",
                                        onClick: async () => selectOrder(),
                                        content: getFormattedNumber(po.amount, { numberFormat: "forceTwoDecimals" }),
                                    },
                                    {
                                        className: "merchant-orders-page__data-cell payment-type__name",
                                        onClick: async () => selectOrder(),
                                        content: <span>{resolvePaymentTypeNameForOrder(po)}</span>,
                                    },
                                    {
                                        className: "merchant-orders-page__data-cell payment__icons",
                                        onClick: async () => selectOrder(),
                                        content: (
                                            <>
                                                <div className="payment-type__icon">
                                                    <PaymentTypeIcon order={po} />
                                                </div>

                                                {!!po.winbackOriginalOrderId && (
                                                    <ToolTipContainer
                                                        minWidth={150}
                                                        text={t("transactions.winback.orderIconDescription")}
                                                    >
                                                        <img src={WinbackIcon}></img>
                                                    </ToolTipContainer>
                                                )}
                                                {shouldShowPlusSellIcon(po) && (
                                                    <ToolTipContainer minWidth={150} text={resolvePlusSellText(po)}>
                                                        <img src={resolvePlusSellIcon(po)}></img>
                                                    </ToolTipContainer>
                                                )}
                                                {shouldShowReturnSellIcon(po) && (
                                                    <ToolTipContainer minWidth={150} text={resolveReturnSellText(po)}>
                                                        <img src={resolveReturnSellIcon(po)}></img>
                                                    </ToolTipContainer>
                                                )}
                                            </>
                                        ),
                                    },
                                    {
                                        className: "merchant-orders-page__data-cell",
                                        onClick: async () => selectOrder(),
                                        content: resolveOrderStatusText(po),
                                    },
                                    {
                                        className: "merchant-orders-page__data-cell pr-2",
                                        content: (
                                            <div className="actions-container">
                                                {po.actions && po.actions.canCancel && (
                                                    <StandardButtonWithSpinner
                                                        invertedBlue
                                                        isSmall
                                                        className="cancel-button"
                                                        onClick={async () => {
                                                            const modalAnswer = await dispatch(
                                                                addModalThunk({
                                                                    type: "cancelTransaction",
                                                                })
                                                            )

                                                            if (modalAnswer.type === "accepted") {
                                                                const reportData = getActionReportData(
                                                                    order,
                                                                    selectedWebshopId,
                                                                    merchantDetails.swMerchant.swMerchantId
                                                                )
                                                                await dispatch(
                                                                    voidThunk(
                                                                        selectedWebshopId,
                                                                        po.orderId,
                                                                        platformOrderIdFormatted,
                                                                        reportData
                                                                    )
                                                                )
                                                                await refresh()
                                                            }
                                                        }}
                                                    >
                                                        {t("transactions.action.cancel")}
                                                    </StandardButtonWithSpinner>
                                                )}
                                                {po.actions && po.actions.canCapture && (
                                                    <StandardButtonWithSpinner
                                                        isSmall
                                                        className="capture-button"
                                                        onClick={async () => {
                                                            dispatch(
                                                                addModalThunk({
                                                                    type: "captureRefund",
                                                                    minAmount: po.actions!.canPartialCapture
                                                                        ? 0.01
                                                                        : po.actions!.captureAmountLeft,
                                                                    maxAmount: po.actions!.captureAmountLeft,
                                                                    isRefund: false,
                                                                    orderId: po.orderId,
                                                                    webshopId: selectedWebshopId,
                                                                    currency: po.currency,
                                                                    paymentType: po.paymentType,
                                                                    reportData: getActionReportData(
                                                                        order,
                                                                        selectedWebshopId,
                                                                        merchantDetails.swMerchant.swMerchantId
                                                                    ),
                                                                    onUpdate: async () => {
                                                                        await refresh()
                                                                    },
                                                                })
                                                            )
                                                        }}
                                                    >
                                                        {t("transactions.action.capture")}
                                                    </StandardButtonWithSpinner>
                                                )}
                                                {po.actions && po.actions.canRefund && (
                                                    <StandardButtonWithSpinner
                                                        className="refund-button"
                                                        isSmall
                                                        onClick={async () => {
                                                            dispatch(
                                                                addModalThunk({
                                                                    type: "captureRefund",
                                                                    minAmount: po.actions!.canPartialRefund
                                                                        ? 0.01
                                                                        : po.actions!.refundAmountLeft,
                                                                    maxAmount: po.actions!.refundAmountLeft,
                                                                    isRefund: true,
                                                                    orderId: po.orderId,
                                                                    webshopId: selectedWebshopId,
                                                                    currency: po.currency,
                                                                    paymentType: po.paymentType,
                                                                    reportData: getActionReportData(
                                                                        order,
                                                                        selectedWebshopId,
                                                                        merchantDetails.swMerchant.swMerchantId
                                                                    ),
                                                                    onUpdate: async () => {
                                                                        await refresh()
                                                                    },
                                                                })
                                                            )
                                                        }}
                                                    >
                                                        {t("transactions.action.refund")}
                                                    </StandardButtonWithSpinner>
                                                )}
                                            </div>
                                        ),
                                    },
                                ]
                            }}
                        />
                    </PageContainer>
                </PageWrapper>
                <PageWrapper disableMinHeight widest>
                    <PageContainer>
                        <div className="merchant-orders-export-container">
                            <div className="export-text">{t("transactions.exportText")}</div>
                            <DropDownList
                                value={exportType}
                                onChange={(e) => {
                                    setExportType(e.target.value)
                                }}
                                options={[
                                    {
                                        text: t("transactions.exportTypes.excel"),
                                        value: "excel",
                                    },
                                    {
                                        text: t("transactions.exportTypes.csv"),
                                        value: "csv",
                                    },
                                ]}
                            />
                            <StandardButtonWithSpinner
                                title={t("common.download")}
                                isSmall
                                onClick={async () => {
                                    await dispatch(exportOrdersThunk(selectedWebshopId ?? "", exportType))
                                }}
                            />
                        </div>
                    </PageContainer>
                </PageWrapper>
            </div>
        </>
    )
}

const PaymentTypeIcon = (props: { order: Order }) => {
    const order = props.order
    return (
        <div className="payment-type-icon">
            {isPaymentTypeWithCardSubType(order) && (
                <ToolTipContainer text="" disable>
                    <CreditCardTypeLogo
                        paymentSubType={order.paymentSubType as TCreditCardType}
                        subscriptionType={order.subscriptionOrderType}
                        onDefaultEmpty
                    />
                </ToolTipContainer>
            )}
            <ToolTipContainer text={resolvePaymentTypeText(order)} disable={!order.paymentInfo && !order.paymentSubType}>
                {order.paymentType === "CreditCard" && (
                    <CreditCardTypeLogo
                        paymentSubType={order.paymentSubType as TCreditCardType}
                        subscriptionType={order.subscriptionOrderType}
                    />
                )}
                {order.paymentType !== "CreditCard" && (
                    <img src={getIconFromPaymentType(order.paymentType, order.paymentSubType)}></img>
                )}
            </ToolTipContainer>
        </div>
    )
}

const isPaymentTypeWithCardSubType = (order: Order) => {
    const isPaymentTypeWithCardSubType =
        order.paymentType === "Vipps" || order.paymentType === "MobilePayOnline" || order.paymentType === "ApplePay"
    return isPaymentTypeWithCardSubType && getLogoByCardType(order.paymentSubType as TCreditCardType, true)
}

export default OrdersPage
