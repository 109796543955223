import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { PageContainer, PageWrapper, TextButton, useSelector } from "swiipe.portal.shared"
import ContainerWithCloseButton from "../../components/layouts/ContainerWithCloseButton"
import WebshopSelect, { useInitialWebshopValuesForWebshopSelect } from "../../components/merchant/WebshopSelect"
import CreatePaymentLinkContainer from "../../components/merchant/paymentLink/CreatePaymentLinkContainer"
import CustomizePaymentLinkNotifications from "../../components/merchant/paymentLink/CustomizePaymentLinkNotifications"
import { navigationService } from "../../services/navigationService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { TUserRole } from "../../type/TUserRole"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import "./CreatePaymentLinkPage.scss"

interface ICreatePaymentLinkPage {}

const CreatePaymentLinkPage = ({}: ICreatePaymentLinkPage) => {
    useRequiredUserRelations([{ requiredType: "Merchant", requiredRole: TUserRole.Admin }])
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)

    if (!merchantDetails || !merchantDetails.webshops.length) {
        return null
    }

    return <CreatePaymentLinkPageInner merchantDetails={merchantDetails} />
}

interface IMerchantPluginsPageInnerProps {
    merchantDetails: MixedMerchantDetails
}

const CreatePaymentLinkPageInner = ({ merchantDetails }: IMerchantPluginsPageInnerProps) => {
    const paymentLinkPrefix = "PL"
    const { t } = useTranslation()

    const { initialWebshopId } = useInitialWebshopValuesForWebshopSelect(merchantDetails)
    const [selectedWebshopId, setSelectedWebshopId] = useState(initialWebshopId)

    const [openedContainer, setOpenedContainer] = useState("createPaymentLink")

    return (
        <>
            <PageWrapper disableMinHeight>
                <PageContainer>
                    <WebshopSelect
                        merchantDetails={merchantDetails}
                        onWebshopChange={(selectedWebshopId: string) => {
                            setSelectedWebshopId(selectedWebshopId)
                        }}
                    ></WebshopSelect>
                </PageContainer>
            </PageWrapper>
            <PageWrapper>
                <PageContainer id="create-pl-page" title={t("paymentLinkPage.title")} transparent>
                    <CreatePaymentLinkContainer
                        merchantDetails={merchantDetails}
                        selectedWebshopId={selectedWebshopId}
                        paymentLinkPrefix={paymentLinkPrefix}
                        isOpened={openedContainer === "createPaymentLink"}
                        setIsOpened={(isOpened) => setOpenedContainer(isOpened ? "createPaymentLink" : "")}
                    />
                    <CustomizePaymentLinkNotifications
                        merchantDetails={merchantDetails}
                        selectedWebshopId={selectedWebshopId}
                        isOpened={openedContainer === "customizeNotifications"}
                        setIsOpened={(isOpened) => setOpenedContainer(isOpened ? "customizeNotifications" : "")}
                    />
                    <ContainerWithCloseButton
                        className="see-orders-container"
                        titleClosed={
                            <TextButton
                                className="see-orders-container__link"
                                text={t("paymentLinkPage.seeAllOrders")}
                                textDecoration="underline"
                                onClick={async () => navigationService.navigate("/payment_link_history")}
                            />
                        }
                        titleOpened={null}
                        isOpened={false}
                    />
                </PageContainer>
            </PageWrapper>
        </>
    )
}

export default CreatePaymentLinkPage
