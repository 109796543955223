import React, { useEffect } from "react"
import { Control, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FloatingLabelInput, valFuncRequiredAndPattern, validationPatternsWithLocale } from "swiipe.portal.shared"
import { parseDateWithFormat } from "../../../util/dateUtil"

interface ICprInput {
    cprInputName: string
    country: string
    control: Control<any>

    onDateOfBirthParsedCallback?: (personDateOfBirth: Date | undefined) => void
}

const CprInput = ({ cprInputName, country, control, onDateOfBirthParsedCallback }: ICprInput) => {
    const { t } = useTranslation()

    const fieldWatch = useWatch({
        control,
        name: [cprInputName],
    })

    useEffect(() => {
        onChangeCprInput(control.getValues(cprInputName))
    }, [fieldWatch])

    const onChangeCprInput = (cprInputValue: string) => {
        // Denmark and Sweden
        if (country === "DK" || country === "SE") {
            const filteredValue = cprInputValue
                .replace(/\D/, "")
                .replace(/(\d{6})(\d+)/, "$1-$2")
                .slice(0, 11)

            if (filteredValue !== cprInputValue) {
                control.setValue(cprInputName, filteredValue)
            }

            if (!isValidCpr(filteredValue)) {
                return
            }

            const dateOfBirthInputPart = (filteredValue as string).slice(0, 6)
            const dateParsed = parseDateWithFormat(dateOfBirthInputPart, country === "DK" ? "ddMMyy" : "yyMMdd")

            if (onDateOfBirthParsedCallback) {
                onDateOfBirthParsedCallback(dateParsed)
            }
            return
        }

        // Poland
        if (country === "PL") {
            const filteredValue = cprInputValue.replace(/\D/, "").slice(0, 11)
            if (filteredValue !== cprInputValue) {
                control.setValue(cprInputName, filteredValue)
            }

            if (!isValidCpr(filteredValue)) {
                return
            }

            const dateOfBirthInputPart = (filteredValue as string).slice(0, 6)
            const dateParsed = parseDateWithFormat(dateOfBirthInputPart, "yyMMdd")

            if (onDateOfBirthParsedCallback) {
                onDateOfBirthParsedCallback(dateParsed)
            }
            return
        }

        // All other countries, just limit to 30 digits
        const filteredValue = cprInputValue.replace(/\D/, "").slice(0, 30)
        if (filteredValue !== cprInputValue) {
            control.setValue(cprInputName, filteredValue)
        }
    }

    const isValidCpr = (cpr: string) => {
        return validationPatternsWithLocale.cpr(country).test(cpr)
    }

    useEffect(() => {
        if (!onDateOfBirthParsedCallback) {
            return
        }

        onDateOfBirthParsedCallback(undefined)
    }, [country])

    return (
        <>
            <FloatingLabelInput
                name={cprInputName}
                placeholder={t("clearhausonboarding.inputfields.cpr")}
                type="tel"
                innerRef={control.register(
                    valFuncRequiredAndPattern(
                        validationPatternsWithLocale.cpr(country),
                        t("clearhausonboarding.inputfields.errormsg.cpr")
                    )
                )}
            />
        </>
    )
}

export default CprInput
