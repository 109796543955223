import cn from "classnames"
import React from "react"
import useDimensions from "react-cool-dimensions"
import { useTranslation } from "react-i18next"
import "./MerchantOfferSteps.scss"

export type EMerchantOfferStep = "info" | "terms" | "kyc" | "services"

export function parseMerchantOfferStep(step: string): EMerchantOfferStep {
    if (step === "terms") {
        return "terms"
    }
    if (step === "kyc") {
        return "kyc"
    }
    if (step === "services") {
        return "services"
    }
    return "info"
}

interface IMerchantOfferStepsProps {
    currentStep: EMerchantOfferStep | undefined
    className?: string
    onChangeStep: (newStep: EMerchantOfferStep) => void
    maxStepAllowed: number
    includeKyc: boolean
    marginBottom?: number
}

const steps = [
    { title: "merchantOffer.steps.offer", type: "info" },
    { title: "merchantOffer.steps.terms", type: "terms" },
    { title: "merchantOffer.steps.kyc", titleMobile: "merchantOffer.steps.kycMobile", type: "kyc" },
    { title: "merchantOffer.steps.services", type: "services" },
]

export const MerchantOfferSteps = ({
    currentStep,
    className,
    onChangeStep,
    maxStepAllowed,
    includeKyc,
    marginBottom,
}: IMerchantOfferStepsProps) => {
    const { t } = useTranslation()
    const { observe, currentBreakpoint } = useDimensions({
        breakpoints: { mobile: 0, normal: 600 },
    })

    return (
        <div ref={observe} className={cn("merchant-offer-steps", className)} style={{ marginBottom: marginBottom ?? 0 }}>
            <div className="merchant-offer-steps__line"></div>
            <div className="merchant-offer-steps__steps-container">
                {steps
                    .filter((s) => s.type !== "kyc" || includeKyc)
                    .map((step, index) => {
                        const stepNum = index + 1
                        return (
                            <div
                                className={cn(
                                    "merchant-offer-steps__step",
                                    step.type === currentStep && "merchant-offer-steps__step--active",
                                    stepNum <= maxStepAllowed &&
                                        step.type !== currentStep &&
                                        "merchant-offer-steps__step--can-select"
                                )}
                                key={step.type}
                                onClick={() => {
                                    if (stepNum <= maxStepAllowed && step.type !== currentStep) {
                                        onChangeStep(step.type as EMerchantOfferStep)
                                    }
                                }}
                            >
                                <div className="merchant-offer-steps__step-circle">{stepNum}</div>
                                <div className="merchant-offer-steps__step-title">
                                    {currentBreakpoint === "mobile" && step.titleMobile ? t(step.titleMobile) : t(step.title)}
                                </div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}
