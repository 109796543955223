import { ActionsUnion, createAction } from "swiipe.portal.shared"
import { IReleaseNote } from "../../type/releaseNotes/releaseNote"
import { StoreState } from "../StoreState"

export type ReleaseNoteState = Readonly<{
    releaseNotes?: IReleaseNote[]
}>

const initialState: ReleaseNoteState = {}

export const releaseNoteReducerActions = {
    setReleaseNotes: (releaseNotes: IReleaseNote[]) => createAction("APP/SET_RELEASE_NOTES", { releaseNotes }),
}

export type ReleaseNoteActions = ActionsUnion<typeof releaseNoteReducerActions>

export const releaseNoteReducer = (state: ReleaseNoteState = initialState, action: ReleaseNoteActions) => {
    switch (action.type) {
        case "APP/SET_RELEASE_NOTES": {
            return {
                ...state,
                releaseNotes: action.releaseNotes,
            }
        }
    }

    return state
}

export const releaseNoteSelectors = {
    releaseNotes: (state: StoreState) => state.releaseNotes.releaseNotes,
}
