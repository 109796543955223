import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form, ModalBody, Row } from "reactstrap"
import {
    BaseModal,
    FieldsetFormGroup,
    FloatingLabelInput,
    IModalBase,
    IModalControlProps,
    ModalFooter,
    StandardButton,
    StandardButtonWithSpinner,
} from "swiipe.portal.shared"
import { reportFraudThunk } from "../../store/thunks/orderThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { IReportFraudData } from "../../type/IReportFraudData"
import "./ModalReportFraud.scss"

export interface IModalReportFraud extends IModalBase {
    type: "reportFraud"
    reportData: IReportFraudData
}

interface IFormModel {
    reason: string
}

const ModalReportFraud = ({ title, reportData, callback, ...otherProps }: IModalReportFraud & IModalControlProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const { handleSubmit, register } = useForm<IFormModel>()

    const onSubmit: SubmitHandler<IFormModel> = async (data, e) => {
        try {
            await dispatch(reportFraudThunk(reportData.webshopId, reportData.orderId, reportData.swMerchantId, data.reason))

            callback("accepted")
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    const DataRow = (props: { attributeName: string; value: string }) => {
        return (
            <div>
                <span>{props.attributeName}</span>
                <span>{props.value}</span>
            </div>
        )
    }

    return (
        <BaseModal
            {...otherProps}
            modalClassName="report-fraud-modal"
            callback={callback}
            centered
            title={title || t("common.error_title")}
        >
            <ModalBody>
                <div className="order-info mb-4">
                    <DataRow attributeName={t("transactions.orderid")} value={reportData.platformOrderId} />
                    <DataRow attributeName={t("transactions.date")} value={reportData.orderDate} />
                    <DataRow attributeName={t("transactions.currency")} value={reportData.currency} />
                    <DataRow attributeName={t("transactions.amount")} value={reportData.amount} />
                </div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FieldsetFormGroup field>
                        <FloatingLabelInput
                            name="reason"
                            type="textarea"
                            textareaNoAutoGrow
                            rows={3}
                            innerRef={register}
                            placeholder={t("transactions.reportFraudModal.reason")}
                        />
                    </FieldsetFormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Row className="justify-content-between">
                    <div className="col-auto">
                        <StandardButton
                            inverted
                            onClick={() => {
                                callback("ignored")
                            }}
                            title={t("transactions.reportFraudModal.cancel")}
                            width={100}
                        />
                    </div>
                    <div className="col-auto">
                        <StandardButtonWithSpinner
                            onClick={async () => {
                                await handleSubmit(onSubmit)()
                            }}
                            width={100}
                            className="mr-2"
                        >
                            {t("contactform.send")}
                        </StandardButtonWithSpinner>
                    </div>
                </Row>
            </ModalFooter>
        </BaseModal>
    )
}

export default ModalReportFraud
