import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { TAllSharedModals } from "../components/modal/common/SharedSingleModal"
import { TModalResponseType } from "../components/modal/common/modalTypes"
import { SharedStoreState } from "../store/SharedStoreState"
import { modalReducerActions } from "../store/modalReducer"

export interface IModalResponse {
    type: TModalResponseType
    data?: any
}

export const addModalThunk =
    (modal: TAllSharedModals): ThunkAction<Promise<IModalResponse>, SharedStoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const modalReponse = await new Promise<IModalResponse>((outerResolve) => {
            dispatch(
                modalReducerActions.addModal({ ...modal, callback: (respType, data) => outerResolve({ type: respType, data }) })
            )
        })
        return modalReponse
    }
