import React from "react"
import { StandardBox, TStandardBoxWidth } from "swiipe.portal.shared"
import "./CloseBox.scss"

interface ICloseBoxProps {
    children?: React.ReactNode
    onClose: () => void
    width?: TStandardBoxWidth
}

const CloseBox = ({ children, onClose, width }: ICloseBoxProps) => {
    return (
        <div className="swiipe-close-box">
            <StandardBox width={width} margin={"none"}>
                {children}
            </StandardBox>
            <span onClick={() => onClose()} className="icon icon-close-blue close-cross"></span>
        </div>
    )
}

export default CloseBox
