import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { FloatingLabelInput } from "../../form/input/FloatingLabelInput"
import { BaseModal } from "../common/BaseModal"
import { ModalFooter } from "../common/ModalFooter"
import { IModalBase, IModalControlProps } from "../common/modalTypes"
import "./ModalVerify.scss"

export interface IModalVerify extends IModalBase {
    type: "verify"
    text: string
    content?: React.ReactNode
    emailToVerify?: string
    acceptButtonText?: string
    acceptButtonIsDelete?: boolean
}

const ModalVerify = ({
    title,
    text,
    callback,
    content,
    emailToVerify,
    acceptButtonText,
    acceptButtonIsDelete,
    ...otherProps
}: IModalVerify & IModalControlProps) => {
    const { t } = useTranslation()
    const [email, setEmail] = useState("")
    const [didTryToSubmit, setDidTryToSubmit] = useState(false)
    return (
        <BaseModal {...otherProps} modalClassName="modal-verify" callback={callback} centered title={title}>
            <ModalBody>
                <div className="text-container">
                    <p className={cn("mb-0 text-center", { "modal-verify-bold": !!emailToVerify })}>{text}</p>
                </div>
                {content && <div className="content-container">{content}</div>}
                {!!emailToVerify && (
                    <div className="modal-verify-input-container">
                        <FloatingLabelInput
                            type="text"
                            placeholder="Your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        ></FloatingLabelInput>
                        {didTryToSubmit && email.trim() !== emailToVerify.trim() && (
                            <div className="modal-verify-error">This is not your email address!</div>
                        )}
                    </div>
                )}
            </ModalBody>
            <ModalFooter
                leftButton={{ onClick: async () => callback("declined"), text: "Cancel" }}
                rightButton={{
                    isDelete: !!emailToVerify || acceptButtonIsDelete,
                    onClick: async () => {
                        if (emailToVerify) {
                            if (!didTryToSubmit) {
                                setDidTryToSubmit(true)
                            }
                            if (email.trim() === emailToVerify.trim()) {
                                callback("accepted", email)
                            }
                        } else {
                            callback("accepted")
                        }
                    },
                    text: acceptButtonText || (emailToVerify ? "I understand the risk" : "Save"),
                }}
            />
        </BaseModal>
    )
}

export default ModalVerify
