import React from "react"
import "./MerchantDashboardTileOverlayContent.scss"

interface IMerchantDashboardTileOverlayContent {
    children: React.ReactNode
}

const MerchantDashboardTileOverlayContent = ({ children }: IMerchantDashboardTileOverlayContent) => {
    return (
        <div className="merchant-dashboard-tile-overlay-content">
            <div className="merchant-dashboard-tile-overlay-content__overlay"></div>
            <div className="merchant-dashboard-tile-overlay-content__overlay-content">{children}</div>
        </div>
    )
}

export default MerchantDashboardTileOverlayContent
