import html2canvas from "html2canvas"
import React from "react"
import { downloadBlob } from "swiipe.portal.shared"

export async function makeScreenshotByRefAndDownload(
    elementRef: React.MutableRefObject<HTMLElement | null | undefined>,
    fileName: string
) {
    const element = elementRef.current
    if (!element) {
        return
    }

    const canvas: HTMLCanvasElement = await html2canvas(element, { backgroundColor: null })
    canvas.toBlob((blob) => {
        if (!blob) {
            return
        }
        downloadBlob(blob, fileName)
    })
}
