import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import {
    IAddInternalComment,
    IDeleteInternalComment,
    IInternalComment,
    IUpdateInternalComment,
    SharedStoreState,
    endpointsShared,
    requestThunk,
} from "swiipe.portal.shared"
import { commentReducerActions, commentSelectors } from "../store/commentReducer"
import { addModalThunk } from "./modalThunks"

export const addInternalCommentThunk =
    (data: IAddInternalComment): ThunkAction<Promise<void>, SharedStoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk<void>(endpointsShared.Identity.addInternalComment, {
                data: data,
            })
        )
        await dispatch(ensureInternalCommentsThunk(data.relationId, true))
    }

export const ensureInternalCommentsThunk =
    (relationId: string, force: boolean): ThunkAction<Promise<void>, SharedStoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const comments = commentSelectors.comments(getState(), relationId)
        if (comments && !force) {
            return
        }

        const res = await dispatch(
            requestThunk<{
                relationId: string
                comments: IInternalComment[]
            }>(endpointsShared.Identity.getInternalComments, {
                params: {
                    relationId,
                },
            })
        )
        dispatch(commentReducerActions.setComments(relationId, res.comments))
    }

const deleteInternalCommentThunk =
    (data: IDeleteInternalComment): ThunkAction<Promise<void>, SharedStoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const { commentId, relationId } = data
        await dispatch(
            requestThunk<void>(endpointsShared.Identity.deleteInternalComment(commentId), {
                data: { relationId },
            })
        )
        await dispatch(ensureInternalCommentsThunk(relationId, true))
    }

export const deleteInternalCommentModalThunk =
    (data: IDeleteInternalComment): ThunkAction<Promise<void>, SharedStoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const res = await dispatch(
            addModalThunk({
                type: "verify",
                title: "Delete comment",
                text: "Are you sure you want to delete this?",
                acceptButtonIsDelete: true,
                acceptButtonText: "Delete",
            })
        )
        if (res.type === "accepted") {
            await dispatch(deleteInternalCommentThunk(data))
        }
    }

export const updateInternalCommentThunk =
    (data: IUpdateInternalComment): ThunkAction<Promise<void>, SharedStoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const { commentId, relationId, comment } = data
        await dispatch(
            requestThunk<void>(endpointsShared.Identity.updateInternalComment(commentId), {
                data: { relationId, comment },
            })
        )
        await dispatch(ensureInternalCommentsThunk(data.relationId, true))
    }
