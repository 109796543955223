import { ActionsUnion, TPayment, createAction } from "swiipe.portal.shared"
import { TOnboardingStatus } from "../../type/IProvider"
import { StoreState } from "../StoreState"

export interface IGatewayDto {
    webshopId: string
    gatewayId: string
    paymentType: TPayment
    enabled: boolean
    onboardingStatus: TOnboardingStatus
    configuration?: any
}

export type GatewayState = Readonly<{
    gateways: {
        [merchantId: string]: IGatewayDto[]
    }
}>

const initialState: GatewayState = {
    gateways: {},
}

export const gatewayReducerActions = {
    setGatewaysForMerchant: (gateways: IGatewayDto[], merchantId: string) =>
        createAction("APP/SET_GATEWAYS", { gateways, merchantId }),
}

export type GatewayActions = ActionsUnion<typeof gatewayReducerActions>

export const gatewayReducer = (state: GatewayState = initialState, action: GatewayActions) => {
    switch (action.type) {
        case "APP/SET_GATEWAYS": {
            return {
                ...state,
                gateways: {
                    ...state.gateways,
                    [action.merchantId]: action.gateways,
                },
            }
        }
        default:
            return state
    }
}

export const gatewaySelectors = {
    getGatewaysForMerchant: (state: StoreState, merchantId: string) => state.gateway.gateways[merchantId],
    getSpecificGatewaysForWebshop: (state: StoreState, merchantId: string, webshopId: string, paymentTypes: TPayment[]) =>
        state.gateway.gateways[merchantId]?.filter(
            (gateway: IGatewayDto) =>
                gateway.webshopId === webshopId && paymentTypes.some((paymentType) => gateway.paymentType == paymentType)
        ),
    getGateway: (state: StoreState, merchantId: string, paymentType: TPayment, webshopId: string) =>
        state.gateway.gateways[merchantId]?.find(
            (gateway: IGatewayDto) => gateway.paymentType === paymentType && gateway.webshopId === webshopId
        ),
}
