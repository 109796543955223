import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { fetchOrganizationCompanyDetailsThunk, getKeys, prepareFormData, requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { setEmailResent } from "../../services/partnerService"
import { IPartnerContactDataForm } from "../../type/IPartnerContactDataForm"
import { TOrganizationType } from "../../type/TOrganizationType"
import { StoreState } from "../StoreState"
import { userRelationSelectors } from "../reducers/userRelationReducer"
import { IPartnerAddMerchantFormModel } from "./../../type/IPartnerAddMerchantFormModel"
import { IPartnerDetails } from "./../../type/IPartnerDetails"
import { IPartnerMerchantRelation } from "./../../type/IPartnerMerchantRelation"
import { IPartnerSignupFormModel } from "./../../type/IPartnerSignupFormModel"
import { partnerReducerActions, partnerSelectors } from "./../reducers/partnerReducer"
import { getSelectedCompanyDetailsFromSearch } from "./organizationThunks"
import { ensureUserRelationsThunk } from "./userRelationThunks"

export const ensurePartnerDataThunk =
    (force: boolean, partnerId?: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(ensureUserRelationsThunk())

        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!partnerId && (!currentUserRelation || currentUserRelation.relationType !== "Partner")) {
            return
        }

        const existingDetails = partnerSelectors.partnerDetails(getState())
        if (!force && !partnerId && existingDetails) {
            // Partner data already fetched
            return
        }

        const partnerDetails = await dispatch(
            requestThunk<IPartnerDetails>(endpoints.Core.getPartnerData(partnerId ? partnerId! : currentUserRelation!.id))
        )

        dispatch(
            fetchOrganizationCompanyDetailsThunk(
                currentUserRelation!.id,
                existingDetails?.countryCode ?? partnerDetails.countryCode,
                existingDetails?.cvr ?? partnerDetails.cvr,
                TOrganizationType.Partner
            )
        )

        dispatch(partnerReducerActions.setPartnerDetails(partnerId ? partnerId : currentUserRelation!.id, partnerDetails))
    }

export const updateCompanyDetailsPartnerThunk =
    (partnerId: string, name: string, cvr: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(endpoints.Identity.setCompanyDetails, {
                data: {
                    organizationId: partnerId,
                    organizationType: TOrganizationType.Partner,
                    name,
                },
            })
        )

        await dispatch(updatePartnerCvrThunk(partnerId, cvr))

        await dispatch(ensurePartnerDataThunk(true))
        await dispatch(ensureUserRelationsThunk(true))
    }

export const updatePartnerCvrThunk =
    (partnerId: string, cvr: string, withUpdate = false): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(endpoints.Identity.updatePartnerCvr, {
                data: {
                    partnerId,
                    cvr,
                },
            })
        )

        if (withUpdate) {
            await dispatch(ensurePartnerDataThunk(true))
        }
    }

export const ensureMasterPartnerThunk =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const userRelations = userRelationSelectors.userRelations(getState())

        if (!userRelations) {
            return
        }

        if (!force && partnerSelectors.partnersContainMasterPartner(getState())) {
            return
        }

        for (const rel of userRelations) {
            if (rel.relationType === "Partner") {
                if (rel.isMasterPartner) {
                    dispatch(partnerReducerActions.setHasMasterPartner(true, rel.id))
                    return
                }
            }
        }
    }

export const ensurePartnerMerchantRelationsThunk =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(ensureUserRelationsThunk())

        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType !== "Partner") {
            return
        }

        if (!force && partnerSelectors.partnerMerchantRelationsForId(getState(), currentUserRelation.id)) {
            // Partner data already fetched
            return
        }

        const partnerMerchantRelations = await dispatch(
            requestThunk<{ merchantRelations: IPartnerMerchantRelation[] }>(
                endpoints.Core.getPartnerMerchantRelations(currentUserRelation.id)
            )
        )
        dispatch(partnerReducerActions.setMerchantRelations(currentUserRelation.id, partnerMerchantRelations.merchantRelations))
    }

export interface IPartnerSwMerchantRelationsResponseDto {
    partnerToMerchantRelations: {
        [partnerId: string]: IPartnerMerchantRelation[]
    }[]
}

export const ensureAllPartnerMerchantRelationsThunk =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(ensureUserRelationsThunk())

        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation) {
            return
        }

        if (!force && getKeys(partnerSelectors.partnerMerchantRelationsAll(getState())).length > 0) {
            // Partner data already fetched
            return
        }

        const partnerMerchantRelations = await dispatch(
            requestThunk<IPartnerSwMerchantRelationsResponseDto>(endpoints.Core.getAllPartnerMerchantRelations)
        )

        const partnersIds = getKeys(partnerMerchantRelations.partnerToMerchantRelations)
        partnersIds.forEach((partnerId) => {
            dispatch(
                partnerReducerActions.setMerchantRelations(
                    partnerId,
                    partnerMerchantRelations.partnerToMerchantRelations[partnerId]
                )
            )
        })
    }

export const resendConfirmEmailThunk =
    (partnerId: string, merchantId: string, toEmail: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(requestThunk<void>(endpoints.Core.resendConfirmEmail(partnerId, merchantId, toEmail)))

        const partnerMerchantRelationsAll = partnerSelectors.partnerMerchantRelationsAll(getState())
        const partnerMerchantRelationsForPartnerId = partnerMerchantRelationsAll[partnerId]

        if (!partnerMerchantRelationsForPartnerId) {
            return
        }

        setEmailResent(partnerMerchantRelationsForPartnerId, merchantId)

        dispatch(partnerReducerActions.setMerchantRelations(partnerId, partnerMerchantRelationsForPartnerId))
    }

export const updatePartnerContactDataThunk =
    (form: IPartnerContactDataForm, partnerId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(endpoints.Core.updatePartnerContactData(partnerId), {
                data: {
                    ...prepareFormData(form, []),
                },
            })
        )
        await dispatch(ensurePartnerDataThunk(true))
    }

export const partnerSignupThunk =
    (form: IPartnerSignupFormModel): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(endpoints.Core.signupPartner, {
                data: {
                    ...prepareFormData(form, []),
                },
            })
        )
    }

export const partnerAddMerchantThunk =
    (form: IPartnerAddMerchantFormModel): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType !== "Partner") {
            return
        }

        const preparedForm = prepareFormData(form, [])

        const companyData = await dispatch(getSelectedCompanyDetailsFromSearch(form.companyNumber))

        await dispatch(
            requestThunk<void>(endpoints.Identity.partnerCreateMerchant, {
                data: {
                    userDetails: {
                        email: preparedForm.email,
                        country: preparedForm.country,
                        language: preparedForm.language,
                    },
                    webshopBaseInfo: {
                        hostname: preparedForm.hostname,
                        platform: preparedForm.platform,
                    },
                    companyDetails: {
                        ...companyData,
                        companyName: preparedForm.companyName,
                    },
                    companyRegistrationNumber: preparedForm.companyNumber,
                    addToPartnerId: currentUserRelation.id,
                },
            })
        )

        await dispatch(ensurePartnerMerchantRelationsThunk(true))
        await dispatch(ensureUserRelationsThunk(true))
    }
