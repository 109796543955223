import React from "react"
import { useTranslation } from "react-i18next"
import { BillingAddressDto, getCountryByISO, useSelector, userSelectors } from "swiipe.portal.shared"
import { ensureBillingAddressThunk } from "../../../store/thunks/userPersonalInfoThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IEditFormBase } from "../../../type/form/IEditFormBase"
import InfoBoxContainer from "../../infobox/InfoBoxContainer"
import InfoBoxEditor from "../../infobox/InfoBoxEditor"
import { InfoBoxState } from "../../infobox/InfoBoxState"
import AddressFormFields from "./AddressFormFields"

interface IUserBillingAddressFormProps extends IEditFormBase {}

const UserBillingAddressForm = ({ isInEditMode, onSwitchEditMode }: IUserBillingAddressFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const userData = useSelector(userSelectors.userData)
    if (!userData) {
        return null
    }
    const billingAddress = userData.billingAddresses ? userData.billingAddresses[0] : undefined

    return (
        <InfoBoxContainer isInEditMode={isInEditMode} iconClass="icon-billing-address" title={t("personal.billing_address")}>
            <InfoBoxEditor<BillingAddressDto>
                state={InfoBoxState.DEFAULT}
                iconClass="icon-billing-address"
                title={billingAddress ? t("personal.editbillingaddress") : t("personal.addadress")}
                isInEditMode={isInEditMode}
                editBtnTextType={billingAddress ? "editing" : "adding"}
                renderPreview={() =>
                    billingAddress ? (
                        <div>
                            {billingAddress.fullName}
                            <br />
                            {billingAddress.companyName}
                            <br />
                            {billingAddress.address}
                            <br />
                            {billingAddress.postCode}, {billingAddress.city}
                            <br />
                            {billingAddress.region && (
                                <span>
                                    {billingAddress.region}
                                    <br />
                                </span>
                            )}
                            {getCountryByISO(billingAddress.country || "")}
                        </div>
                    ) : (
                        <div>
                            <strong>{t("personal.addadress")}</strong>
                        </div>
                    )
                }
                renderFormFields={(register, setValue, getValues) => (
                    <AddressFormFields getValues={getValues} setValue={setValue} register={register} address={billingAddress} />
                )}
                onSave={async (model) => {
                    model.id = billingAddress ? billingAddress.id : undefined

                    await dispatch(ensureBillingAddressThunk(model))
                }}
                onSwitchEditMode={onSwitchEditMode}
            />
        </InfoBoxContainer>
    )
}

export default UserBillingAddressForm
