import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    PageContainer,
    PageWrapper,
    SpinnerContainer,
    StandardButtonWithSpinner,
    getFormattedNumber,
    sortDescending,
    useSelector,
} from "swiipe.portal.shared"
import BillingPaymentMethods from "../../components/billing/BillingPaymentMethods"
import MerchantInvoicingEanForm from "../../components/form/merchant/MerchantInvoicingEanForm"
import MerchantInvoicingInfoForm from "../../components/form/merchant/MerchantInvoicingInfoForm"
import PageBoxSection from "../../components/page/PageBoxSection"
import TitleSection from "../../components/sections/TitleSection"
import ToolTipContainer from "../../components/text/ToolTipContainer"
import DownloadIcon from "../../images/icons/download.svg"
import { getFormattedDate } from "../../services/dateTimeService"
import { getPaymentStatusConfig } from "../../services/invoicingService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { billingSelectors } from "../../store/reducers/billingReducer"
import { partnerSelectors } from "../../store/reducers/partnerReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { downloadInvoicePdfThunk, fetchSimpleInvoicesThunk } from "../../store/thunks/billingThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { IInvoiceSimple } from "../../type/billing/IInvoice"
import "./InvoicesPage.scss"

interface IInvoicesPageProps {}

const InvoicesPage = ({}: IInvoicesPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])

    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [formInEditMode, setFormInEditMode] = useState("")
    const hasMasterPartner = useSelector(partnerSelectors.partnersContainMasterPartner)

    const userRelation = useSelector(userRelationSelectors.currentUserRelation)
    const invoices = useSelector(billingSelectors.simpleInvoices)
    const sortedInvoices = sortDescending(invoices || [], (i) => i.created)

    useEffect(() => {
        dispatch(fetchSimpleInvoicesThunk(false))
    }, [userRelation])

    return (
        <PageWrapper wider>
            <PageContainer classNameBody="invoices-page" transparent>
                <PageBoxSection>
                    <BillingPaymentMethods />
                </PageBoxSection>
                <PageBoxSection>
                    <TitleSection isSmaller title={t("contactform.billingSectionTitle")} />
                    <div className={formInEditMode !== "merchantInvoicingInfo" ? "hover-effect" : ""}>
                        <MerchantInvoicingInfoForm
                            onSwitchEditMode={(isInEditMode) => setFormInEditMode(isInEditMode ? "merchantInvoicingInfo" : "")}
                            isInEditMode={formInEditMode === "merchantInvoicingInfo"}
                        />
                    </div>
                    <div className={formInEditMode !== "invoicingEan" ? "hover-effect" : ""}>
                        <MerchantInvoicingEanForm
                            onSwitchEditMode={(isInEditMode) => setFormInEditMode(isInEditMode ? "invoicingEan" : "")}
                            isInEditMode={formInEditMode === "invoicingEan"}
                        />
                    </div>
                </PageBoxSection>
                <PageBoxSection>
                    <SpinnerContainer className="invoices-spinner-container" showSpinner={!invoices}>
                        <span>
                            <TitleSection isSmaller title={t("invoices.title")} />
                            <table className="invoices-table">
                                <tbody>
                                    {invoices && invoices.length === 0 && (
                                        <tr className="invoices-empty">
                                            <td>{t("invoices.noInvoices")}</td>
                                        </tr>
                                    )}
                                    {invoices && invoices.length > 0 && (
                                        <>
                                            <tr>
                                                <th>{t("invoices.columns.created")}</th>
                                                <th>{t("invoices.columns.month")}</th>
                                                <th>{t("invoices.columns.currency")}</th>
                                                <th>{t("invoices.columns.amount")}</th>
                                                <th>{t("invoices.columns.status")}</th>
                                                <th className="actions-column-header">{t("invoices.columns.pdf")}</th>
                                            </tr>
                                            {sortedInvoices.map((inv, i) => {
                                                return (
                                                    <InvoiceTableSingleRow
                                                        invoice={inv}
                                                        key={inv.id}
                                                        hasMasterPartner={hasMasterPartner ?? false}
                                                    />
                                                )
                                            })}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </span>
                    </SpinnerContainer>
                </PageBoxSection>
            </PageContainer>
        </PageWrapper>
    )
}

interface IInvoiceTableSingleRow {
    invoice: IInvoiceSimple
    hasMasterPartner: boolean
}

const InvoiceTableSingleRow = ({ invoice, hasMasterPartner }: IInvoiceTableSingleRow) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const paymentStatusConfig = getPaymentStatusConfig(invoice)
    return (
        <tr className="invoices-table-row">
            <td>{getFormattedDate(invoice.created, "slashes")}</td>
            <td>{getFormattedDate(invoice.from, "comma-monthname-year", true)}</td>
            <td>{invoice.currency}</td>
            <td>{getFormattedNumber(invoice.totalIncludingVat, { numberFormat: "forceTwoDecimals" })}</td>
            <td>
                <ToolTipContainer text={t(paymentStatusConfig.descTextId)} minWidth={200}>
                    <span className={cn("status-text", paymentStatusConfig.alarmLevel)}>
                        {t(paymentStatusConfig.statusTextId)}
                    </span>
                    {hasMasterPartner && !!invoice.orderId && !!invoice.orderSwMerchantId && !!invoice.orderWebshopId && (
                        <>
                            {" "}
                            <a
                                href={`/orders?orderId=${invoice.orderId}&relationId=${invoice.orderSwMerchantId}&webshopId=${invoice.orderWebshopId}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Open Order
                            </a>
                        </>
                    )}
                </ToolTipContainer>
            </td>
            <td>
                <div className="actions-container">
                    {invoice.pdfAvailable && (
                        <>
                            <StandardButtonWithSpinner
                                invertedBlue
                                isSmall
                                className="pdf-button"
                                onClick={async () => {
                                    await dispatch(downloadInvoicePdfThunk(invoice, true))
                                }}
                            >
                                {t("invoices.showPdfInNewTab")}
                            </StandardButtonWithSpinner>
                            <StandardButtonWithSpinner
                                invertedBlue
                                isSmall
                                className="pdf-button ml-2"
                                onClick={async () => {
                                    await dispatch(downloadInvoicePdfThunk(invoice, false))
                                }}
                            >
                                <img src={DownloadIcon} /> {t("invoices.downloadPdf")}
                            </StandardButtonWithSpinner>
                        </>
                    )}
                </div>
            </td>
        </tr>
    )
}

export default InvoicesPage
