import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form, ModalBody } from "reactstrap"
import { BaseModal, FieldsetFormGroup, FloatingLabelInput, IModalBase, IModalControlProps } from "swiipe.portal.shared"
import SubmitButton from "../buttons/SubmitButton"

export interface IModalSalesHistoryCancel extends IModalBase {
    type: "salesHistoryCancel"
}

interface IFormModel {
    reason?: string
}

const ModalSalesHistoryCancel = ({ title, callback, ...otherProps }: IModalSalesHistoryCancel & IModalControlProps) => {
    const { t } = useTranslation()
    const { register, formState, handleSubmit } = useForm<IFormModel>()

    const onSubmit: SubmitHandler<IFormModel> = async (data, e) => {
        callback("accepted", { reason: data.reason })
    }
    return (
        <BaseModal {...otherProps} showCloseButton callback={callback} centered title={title}>
            <ModalBody>
                <span className="confirm-text mb-2">{t("offerhistory.allTab.modalCancelText")}</span>
                <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
                    <FieldsetFormGroup field>
                        <FloatingLabelInput
                            name="reason"
                            type="textarea"
                            innerRef={register}
                            rows={5}
                            autoFocus={false}
                            placeholder={t("offerhistory.allTab.modalCancelReasonPlaceholder")}
                        />
                    </FieldsetFormGroup>
                    <div className="col-12 mb-2 mt-5">
                        <SubmitButton
                            isDelete
                            isLarge
                            formState={formState}
                            title={t("offerhistory.allTab.modalCancelButtonTitle")}
                        />
                    </div>
                </Form>
            </ModalBody>
        </BaseModal>
    )
}

export default ModalSalesHistoryCancel
