import cn from "classnames"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import { CountryInputComp, TCountryCode, TCountryFilter, TextButton } from "swiipe.portal.shared"
import { navigationService } from "../../services/navigationService"
import SubmitButton from "../buttons/SubmitButton"
import TextBoxWithStatesContainer from "../text/TextBoxWithStatesContainer"
import "./CountrySelectDialog.scss"
import ServicesOnboardingPageWrapper from "./ServicesOnboardingPageWrapper"

interface IFormModel {
    countryCode: string
}

export interface ICountrySelectDialog {
    isPaymentType?: boolean
    serviceName: string
    noticeText?: string
    defaultValue?: string
    countryFilter?: TCountryFilter
    customCountryFilter?: TCountryCode[]
    isOptional?: boolean
    onCountrySelect: (countryCode: string) => void
    onGoBack?: () => void
}

export const CountrySelectDialog = ({
    serviceName,
    noticeText,
    defaultValue,
    countryFilter,
    customCountryFilter,
    isOptional,
    isPaymentType,
    onCountrySelect,
    onGoBack,
}: ICountrySelectDialog) => {
    const { t } = useTranslation()
    const { handleSubmit, register, formState } = useForm<IFormModel>()

    const onSubmit: SubmitHandler<IFormModel> = async (data) => {
        onCountrySelect(data.countryCode)
    }

    return (
        <ServicesOnboardingPageWrapper>
            <div className="country-select-dialog">
                <p className="country-select-dialog__header block-heading text-center mb-4">
                    {isPaymentType
                        ? t("swiipeservicesonboarding.chooseCountryPaymentType", { serviceName: serviceName })
                        : t("swiipeservicesonboarding.chooseCountryService", { serviceName: serviceName })}
                </p>
                {noticeText && (
                    <TextBoxWithStatesContainer className="country-select-dialog--notice" centerText info text={noticeText} />
                )}
                <Form onSubmit={handleSubmit(onSubmit)} className="country-select-form">
                    <CountryInputComp
                        inputName={"countryCode"}
                        defaultValue={defaultValue}
                        isOptional={isOptional}
                        countryFilter={countryFilter}
                        customCountryFilter={customCountryFilter}
                        register={register}
                        hidePlaceholder
                    />

                    <SubmitButton dark noBorder noBorderRadius className="mt-5" formState={formState}>
                        {t("swiipeservicesonboarding.continue")}
                    </SubmitButton>
                </Form>
                <TextButton
                    text={t("commononboardingtexts.goback")}
                    onClick={async () => {
                        onGoBack ? onGoBack() : navigationService.navigate("/plugins")
                    }}
                    className={cn("mt-3 mb-2")}
                />
            </div>
        </ServicesOnboardingPageWrapper>
    )
}

export default CountrySelectDialog
