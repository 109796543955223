import React from "react"
import { useTranslation } from "react-i18next"
import { PageContainer, PageWrapper, TextButton, useSelector } from "swiipe.portal.shared"
import SwiipeAcquiringLogoImage from "../../../src/images/swiipe-logo.png"
import TermsAndConditionsCheckbox from "../../components/form/checkboxes/TermsAndConditionsCheckbox"
import FormHeaderLogoContainer from "../../components/form/title/FormHeaderLogoContainer"
import LegalTextLinkPopup from "../../components/legal/LegalTextLinkPopup"
import PspPricingRows from "../../components/paymentsOnboarding/psp/PspPricingRows"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { chooseSwiipePspPlanThunk } from "../../store/thunks/swiipePaymentsThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { TSwiipePlan } from "../../type/IProvider"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import "./SwiipePspOnboardingPage.scss"

interface ISwiipePspOnboardingPageProps {
    isEditPage?: boolean
    onGoBack: () => Promise<void>
    onOnboarded: () => void
}
interface ISwiipePspOnboardingPageInnerProps extends ISwiipePspOnboardingPageProps {
    merchantDetails: MixedMerchantDetails
}

const SwiipePspOnboardingPage = (props: ISwiipePspOnboardingPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)

    if (!merchantDetails) {
        return null
    }

    return <SwiipePspOnboardingPageInner merchantDetails={merchantDetails} {...props} />
}

const SwiipePspOnboardingPageInner = ({ merchantDetails, onGoBack, onOnboarded }: ISwiipePspOnboardingPageInnerProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()

    const swMerchantId = merchantDetails.swMerchant.swMerchantId
    const swiipePlan = useSelector(merchantSelectors.swiipePlan)

    return (
        <PspPageWrapper>
            <p className="text-normal text-center">{t("psponboarding.texts.plan")}</p>
            <div className="text-regular text-center mb-3">{t("psponboarding.texts.youCanCancel")}</div>

            <div className="onboarding-option">
                <div className="price">
                    <PspPricingRows />
                </div>
                <hr />
                <LegalTextLinkPopup
                    legalTextBeforeLink=""
                    linkText={t("commononboardingtexts.terms.linktextCapital")}
                    termsType="TermsPSP"
                    centered
                />
            </div>

            <div className="activate-container">
                <TermsAndConditionsCheckbox
                    termsType="TermsPSP"
                    acceptText={t("psponboarding.buttons.activate")}
                    onAccept={async () => {
                        await dispatch(chooseSwiipePspPlanThunk(swMerchantId, swiipePlan, TSwiipePlan.Basic))
                        onOnboarded()
                    }}
                />
            </div>
            <TextButton text={t("commononboardingtexts.goback")} className="mt-4" onClick={onGoBack} />
        </PspPageWrapper>
    )
}

interface IPspPageWrapperProps {
    children: React.ReactNode
}
const PspPageWrapper = ({ children }: IPspPageWrapperProps) => {
    return (
        <PageWrapper>
            <PageContainer id="swiipe-psp-onboarding-container">
                <div className="onboarding-container">
                    <FormHeaderLogoContainer
                        mainLogo={{
                            src: SwiipeAcquiringLogoImage,
                            alt: "swiipe-acquiring-logo",
                            width: "140px",
                        }}
                    />
                    {children}
                </div>
            </PageContainer>
        </PageWrapper>
    )
}

export default SwiipePspOnboardingPage
