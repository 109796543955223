import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PageContainer, PageWrapper, useSelector } from "swiipe.portal.shared"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { ensureUsersRelatedForOrganizationThunk } from "../../store/thunks/userRelationThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { IRelationForOrganization } from "../../type/IRelationsForOrganization"
import { TUserRole } from "../../type/TUserRole"
import OrganizationUsersForm from "./../../components/form/organization/OrganizationUsersForm"
import { userRelationSelectors } from "./../../store/reducers/userRelationReducer"

interface IManageUsersPageProps {}

const ManageUsersPage = ({}: IManageUsersPageProps) => {
    const { t } = useTranslation()
    useRequiredUserRelations([
        { requiredType: "CallCenter", requiredRole: TUserRole.Admin },
        { requiredType: "Merchant", requiredRole: TUserRole.Admin },
        { requiredType: "Partner", requiredRole: TUserRole.Admin },
    ])
    const [formInEditMode, setFormInEditMode] = useState("")

    const dispatch = useReduxDispatch()
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)!
    const users = useSelector((state: StoreState) =>
        userRelationSelectors.relationsForOrganization(state, currentUserRelation.id)
    )
    useEffect(() => {
        dispatch(ensureUsersRelatedForOrganizationThunk(false))
    }, [currentUserRelation])

    const usersSorted = (users: IRelationForOrganization[]): IRelationForOrganization[] => {
        return users.sort((userOne, userTwo) => (userOne.userRole > userTwo.userRole ? -1 : 1))
    }

    const getOrganizationOwner = (users: IRelationForOrganization[]): IRelationForOrganization | undefined => {
        return users.find((u) => u.userRole == TUserRole.Owner)
    }

    return (
        <PageWrapper>
            <PageContainer transparent title={t("organizationUsers.manageusers")}>
                {!!users && (
                    <>
                        <OrganizationUsersForm
                            userRelation={undefined}
                            isInEditMode={formInEditMode === "-1"}
                            onSwitchEditMode={(inEditMode) => setFormInEditMode(inEditMode ? "-1" : "")}
                            isAddNew
                        />
                        {usersSorted(users).map((u) => (
                            <OrganizationUsersForm
                                key={u.userId}
                                userRelation={u}
                                organizationOwnerId={getOrganizationOwner(users)?.userId}
                                isInEditMode={formInEditMode === u.userId}
                                onSwitchEditMode={(inEditMode) => setFormInEditMode(inEditMode ? u.userId : "")}
                            />
                        ))}
                    </>
                )}
            </PageContainer>
        </PageWrapper>
    )
}

export default ManageUsersPage
