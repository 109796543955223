import { ICheckVatResponse } from "../types/company/ICheckVatResponse"
import { ICompanyFieldsBase } from "../types/company/ICompanyFieldsBase"
import { ICvrApiCompanyDetails } from "../types/company/ICvrApiCompanyDetails"

export function mapCvrApiResponseToCompanyFields(response: ICvrApiCompanyDetails): ICompanyFieldsBase {
    return {
        companyName: response.name,
        companyNumber: response.vat.toString(),
        companyAddress: response.address,
        companyPostcode: response.zipcode,
        companyCity: response.city,
        companyPhone: response.phone,
        companyEmail: response.email,
        companyType: response.companydesc,

        companyCountry: "DK",
        companyCode: response.companycode,
    }
}

export function mapVatCheckApiResponseToCompanyFields(response: ICheckVatResponse, countryCode: string): ICompanyFieldsBase {
    const address = getAddressFromVatCheckApi(response)
    const vat = response.vatNumber

    if (!address) {
        return {
            companyName: response.name,
            companyNumber: vat,

            companyCountry: countryCode,
        }
    }

    return {
        companyName: response.name,
        companyNumber: vat,
        companyAddress: address.address,
        companyPostcode: address.postCode,
        companyCity: address.city,

        companyCountry: countryCode,
    }
}

export function getFullVatNumber(companyInfo: ICompanyFieldsBase) {
    return `${companyInfo.companyCountry}${companyInfo.companyNumber}`
}

function getAddressFromVatCheckApi(response: ICheckVatResponse): { address: string; postCode: string; city: string } | undefined {
    //ipo: will work for countries where postcode contains digit in the end
    //witch is most of them, but before adding new country should be tested
    //later we can use switch for different countries

    //Tested for Swedish addresses
    const lines = response.address.split(/\r?\n/)

    if (!lines.length) {
        return undefined
    }

    const postCodeAndCity = lines.pop()!

    //city goes after last digit of a postcode
    const city = postCodeAndCity.split(/\d /).pop()!.trim()

    const regExp = new RegExp(` ${city}`)
    const postCode = postCodeAndCity.replace(regExp, "").trim()

    const address = lines.join(" ").trim()

    return {
        address: address,
        postCode: postCode,
        city: city,
    }
}
