import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { Alert } from "reactstrap"
import { getFullVatNumber } from "../../../services/companyDetailsMapper"
import { getCompanyNumberPlaceholder } from "../../../services/companySearchService"
import { ICompanyFieldsBase } from "../../../types/company/ICompanyFieldsBase"
import "./CompanyDetailsPreviewComp.scss"

interface ICompanyDetailsPreviewComp {
    details: ICompanyFieldsBase

    wrongCompanyAction?: () => void
}

export function CompanyDetailsPreviewComp({ details, wrongCompanyAction }: ICompanyDetailsPreviewComp) {
    const { t } = useTranslation()

    return (
        <div className="comp-details">
            <span className="header">{t("companysearch.companydetails")}</span>
            {wrongCompanyAction && (
                <span className={cn("text-btn mb-3 float-right d-sm-block d-none")} onClick={wrongCompanyAction}>
                    {t("companysearch.worngcompany")}
                </span>
            )}
            <Alert isOpen={true} className="info mb-0 mb-sm-2" color="dark">
                <span>
                    {details.companyName}
                    <br /> {details.companyAddress}
                    <br /> {details.companyPostcode}, {details.companyCity}
                </span>
                <br />
                <br />
                <span>
                    {getCompanyNumberPlaceholder(details.companyCountry === "DK" ? "DK" : "other") + ": "}
                    {getFullVatNumber(details)}
                </span>
            </Alert>
            {wrongCompanyAction && (
                <span className={cn("text-btn mb-3 float-right d-sm-none d-block")} onClick={wrongCompanyAction}>
                    {t("companysearch.worngcompany")}
                </span>
            )}
            <div className="clearfix"></div>
        </div>
    )
}
