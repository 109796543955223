import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    CompanyNameAndCVRInput,
    CountryInputComp,
    DropDownList,
    FieldsetFormGroup,
    FloatingLabelInput,
    ICompanyNameAndCVRInputModel,
    ShowErrorMessages,
    companySearchSelectors,
    euAndEaaCountryFilter,
    getAvailablePlatforms,
    getDomainValidationPattern,
    sharedConfigurationSelectors,
    useSelector,
    valFuncRequiredAndPattern,
} from "swiipe.portal.shared"
import { getPreferredLanguage } from "../../../services/languageService"
import { partnerAddMerchantThunk } from "../../../store/thunks/partnerThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IPartnerAddMerchantFormModel } from "../../../type/IPartnerAddMerchantFormModel"
import SubmitButton from "../../buttons/SubmitButton"
import EmailInputComp from "../input/EmailInputComp/EmailInputComp"
import FormSectionTitle from "../title/FormSectionTitle"
import FormSectionTitleWithHint from "../title/FormSetctionTitleWithHint"

interface IPartnerAddMerchantRelationFormProps {
    onAdded: (email: string) => void
}

const PartnerAddMerchantRelationForm = ({ onAdded }: IPartnerAddMerchantRelationFormProps) => {
    const { handleSubmit, getValues, setValue, setError, clearErrors, register, watch, errors, formState } = useForm<
        IPartnerAddMerchantFormModel & ICompanyNameAndCVRInputModel
    >()
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const env = useSelector(sharedConfigurationSelectors.environment)
    const selectedCompany = useSelector(companySearchSelectors.selected)

    const onSubmit: SubmitHandler<IPartnerAddMerchantFormModel> = async (data) => {
        try {
            if (selectedCompany) {
                data.companyName = selectedCompany.companyName
                data.companyNumber = selectedCompany.companyNumber
            } else {
                // If manual company entry remove country code from vat number
                data.companyNumber = data.companyNumber.replace(data.country, "")
            }

            data.language = getPreferredLanguage()

            await dispatch(partnerAddMerchantThunk(data))
            onAdded(data.email)
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="partner-add-merchant-form">
            <p className="text-center text-uppercase">{t("partnermerchantrelations.createaccount")}</p>
            <FormSectionTitle title={t("partnermerchantrelations.whatisthecompany")} />
            <FieldsetFormGroup field>
                <CountryInputComp
                    inputName="country"
                    readOnly={!!selectedCompany}
                    defaultValue="DK"
                    register={register}
                    countryFilter={euAndEaaCountryFilter}
                />
            </FieldsetFormGroup>
            <CompanyNameAndCVRInput
                register={register}
                getValues={getValues}
                setValue={setValue}
                clearError={clearErrors}
                setError={setError}
                watch={watch}
                errors={errors}
            />
            <FieldsetFormGroup field>
                <FloatingLabelInput
                    name="hostname"
                    innerRef={register(
                        valFuncRequiredAndPattern(getDomainValidationPattern(env), t("common.hostname.errorMessage"))
                    )}
                    placeholder={t("common.hostname.label")}
                    bottomHint={t(
                        env === "Production" ? "common.hostname.domainCheckHintProd" : "common.hostname.domainCheckHintNonProd"
                    )}
                    disableHintAfter={5}
                    autoCompleteOff
                />
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <DropDownList
                    innerRef={register({
                        required: true,
                    })}
                    name="platform"
                    options={getAvailablePlatforms()}
                />
            </FieldsetFormGroup>
            <FormSectionTitleWithHint
                title={t("partnermerchantrelations.whoisowner")}
                hintText={t("partnermerchantrelations.ownerhint")}
            ></FormSectionTitleWithHint>
            <FieldsetFormGroup field>
                <EmailInputComp inputName="email" register={register} />
            </FieldsetFormGroup>
            <ShowErrorMessages formState={formState} />
            <FieldsetFormGroup className="px-sm-5">
                <SubmitButton formState={formState} id="merchant-signup-btn" title={t("partnermerchantrelations.createbtn")} />
            </FieldsetFormGroup>
        </Form>
    )
}

export default PartnerAddMerchantRelationForm
