import React, { useEffect, useMemo } from "react"
import { FormState } from "react-hook-form"
import { Alert } from "reactstrap"
import { hasValue } from "../../utils/arrayUtil"
import { extractFieldErrors, getFieldNameWithWildcardIndex } from "../../utils/formUtil"

const ErrorMessage = ({ errorMessage, isLast }: { errorMessage?: string; isLast: boolean }) => {
    return (
        <>
            <span style={{ whiteSpace: "pre-line" }}>{errorMessage}</span>
            {!isLast && <br />}
        </>
    )
}

interface ISingleErrorFromCount {
    errorMessage: string
    errorsCount: number
}
interface IShowErrorMessagesProps<T> {
    formState: FormState<T>
    className?: string
    isHidden?: boolean
    onlyForFieldNames?: string[]
    hasErrorsCallback?: (hasErrors: boolean) => void
    singleErrorFromCount?: ISingleErrorFromCount
    dynamicError?: (errors: string[]) => string
}
export const ShowErrorMessages = <
    T extends {
        [key: string]: any
    }
>({
    formState,
    className,
    isHidden,
    onlyForFieldNames,
    hasErrorsCallback,
    singleErrorFromCount,
    dynamicError,
}: IShowErrorMessagesProps<T>) => {
    const errorMessages = useMemo(
        () =>
            extractFieldErrors(formState.errors)
                .filter((extract) =>
                    !onlyForFieldNames
                        ? true
                        : onlyForFieldNames.includes(extract.fieldName) ||
                          onlyForFieldNames.includes(getFieldNameWithWildcardIndex(extract.fieldName))
                )
                .map((extract) => extract.error)
                .map((fieldError) => fieldError.message)
                .filter(hasValue),
        [formState]
    )

    useEffect(() => {
        hasErrorsCallback?.(errorMessages.length === 0 ? false : true)
    }, [errorMessages])

    if (isHidden || !errorMessages.length) {
        return null
    }

    // We don't filter out empty messages from 'errorMessages'
    // because empty error is still an error, BUT:
    // prevent showing empty error messages here
    const errorMessagesToShow = errorMessages.filter((errMsg) => !!errMsg)
    if (errorMessagesToShow.length === 0) {
        return null
    }

    return (
        <Alert color="danger" className={className}>
            {dynamicError ? (
                <ErrorMessage errorMessage={dynamicError(errorMessages)} isLast={true} />
            ) : !!singleErrorFromCount && singleErrorFromCount.errorsCount === errorMessages.length ? (
                <ErrorMessage errorMessage={singleErrorFromCount.errorMessage} isLast={true} />
            ) : (
                errorMessages.map((msg, index) => (
                    <ErrorMessage key={index} errorMessage={msg} isLast={index === errorMessages.length - 1} />
                ))
            )}
            {}
        </Alert>
    )
}
