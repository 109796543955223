import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    ITableResetControl,
    PageContainer,
    PageWrapper,
    StandardButtonWithSpinner,
    TableContainer,
    TextButton,
    copyToClipboardText,
    delay,
    getFormattedNumber,
    getLanguage,
    useSelector,
} from "swiipe.portal.shared"
import WebshopSelect, { useInitialWebshopValuesForWebshopSelect } from "../../components/merchant/WebshopSelect"
import { getFormattedDate } from "../../services/dateTimeService"
import { getLanguageByISO } from "../../services/languageService"
import { navigationService } from "../../services/navigationService"
import { getPlatformOrderIdFormatted } from "../../services/orderService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { paymentLinksReducerActions, paymentLinksSelectors } from "../../store/reducers/paymentLinkReducer"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { getPaymentLinksPerWebshopThunk } from "../../store/thunks/paymentLinkThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { PaymentLinkDto, PaymentLinksFilter, SortPaymentLinksByPropType } from "../../type/PaymentLink"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import "./PaymentLinkHistoryPage.scss"

interface IPaymentLinkHistoryPageProps {}

interface IPaymentLinkHistoryPageInnerProps {
    merchantDetails: MixedMerchantDetails
}

const PaymentLinkHistoryPage = ({}: IPaymentLinkHistoryPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    if (!merchantDetails || merchantDetails.webshops.length === 0) {
        return null
    }

    return <PaymentLinkHistoryPageInner merchantDetails={merchantDetails} />
}

const PaymentLinkHistoryPageInner = ({ merchantDetails }: IPaymentLinkHistoryPageInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const { initialWebshopId } = useInitialWebshopValuesForWebshopSelect(merchantDetails)
    const [selectedWebshopId, setSelectedWebshopId] = useState(initialWebshopId)

    const resetControl = useMemo<ITableResetControl>(() => ({}), [])

    const currentFilters = useSelector(paymentLinksSelectors.getPaymentLinksFilter)

    const continuationToken = useSelector<StoreState, string | undefined>((state) =>
        paymentLinksSelectors.getContinuationTokenPerMerchant(state, selectedWebshopId)
    )

    const paymentLinks = useSelector<StoreState, PaymentLinkDto[] | undefined>((state) =>
        paymentLinksSelectors.getPaymentLinksPerMerchant(state, selectedWebshopId)
    )

    useEffect(() => {
        resetControl?.reset?.({
            resetPage: true,
        })
    }, [selectedWebshopId, currentFilters])

    const updateFilters = (filters: { sortByProperty?: SortPaymentLinksByPropType; isOrderByDescending?: boolean }) => {
        const paymentLinksFilter = { ...currentFilters } as PaymentLinksFilter

        paymentLinksFilter.sortByProperty = filters.sortByProperty
        paymentLinksFilter.isOrderByDescending = filters.isOrderByDescending

        dispatch(paymentLinksReducerActions.setPaymentLinksFilter(paymentLinksFilter))
    }

    return (
        <>
            <div>
                <PageWrapper disableMinHeight widest>
                    <PageContainer>
                        <WebshopSelect
                            merchantDetails={merchantDetails}
                            onWebshopChange={(selectedWebshopId: string) => {
                                setSelectedWebshopId(selectedWebshopId)
                            }}
                        ></WebshopSelect>
                    </PageContainer>
                </PageWrapper>
                <PageWrapper widest>
                    <PageContainer classNameBody="merchant-payment-links-container">
                        <div className="merchant-payment-links__navigation">
                            <TextButton
                                text={`< ${t("paymentLinksHistoryPage.goBack")}`}
                                color="grey"
                                onClick={async () => navigationService.navigate("/create_payment_link")}
                            />
                        </div>
                        <TableContainer<PaymentLinkDto, SortPaymentLinksByPropType>
                            title={t("paymentLinksHistoryPage.title")}
                            emptyDescription={t("paymentLinksHistoryPage.noPaymentLinksYet")}
                            getItemId={(i) => i.orderId}
                            morePagesAvailable={!!continuationToken}
                            items={paymentLinks}
                            fetchItems={async (pageNumber, perPage, force) => {
                                await dispatch(getPaymentLinksPerWebshopThunk(selectedWebshopId, pageNumber, perPage, force))
                            }}
                            fetchItemsHash={selectedWebshopId + JSON.stringify(currentFilters)}
                            resetControl={resetControl}
                            sorting={{
                                sortingItems: [
                                    { title: t("transactions.created"), value: SortPaymentLinksByPropType.Created },
                                    { title: t("transactions.orderid"), value: SortPaymentLinksByPropType.PlatformOrderId },
                                ],
                                initialSortingValue: SortPaymentLinksByPropType.Created,
                                initialSortingDirection: currentFilters?.isOrderByDescending ?? true ? "descending" : "ascending",
                                onSortingChange: (value, direction) => {
                                    updateFilters({
                                        sortByProperty: value,
                                        isOrderByDescending: direction === "descending",
                                    })
                                },
                            }}
                            renderHeaderCells={() => [
                                {
                                    className: "payment-links__table-cell payment-link-id-cell",
                                    content: t("paymentLinksHistoryPage.tableHeaders.orderId"),
                                },
                                {
                                    className: "payment-links__table-cell",
                                    content: t("paymentLinksHistoryPage.tableHeaders.created"),
                                },
                                {
                                    className: "payment-links__table-cell",
                                    content: t("paymentLinksHistoryPage.tableHeaders.currency"),
                                },
                                {
                                    className: "payment-links__table-cell",
                                    content: t("paymentLinksHistoryPage.tableHeaders.amount"),
                                },
                                {
                                    className: "payment-links__table-cell",
                                    content: t("paymentLinksHistoryPage.tableHeaders.customer"),
                                },
                                {
                                    className: "payment-links__table-cell",
                                    content: t("paymentLinksHistoryPage.tableHeaders.channel"),
                                },
                                {
                                    className: "payment-links__table-cell",
                                    content: t("paymentLinksHistoryPage.tableHeaders.language"),
                                },
                                {
                                    className: "payment-links__table-cell actions-table-header",
                                    content: t("paymentLinksHistoryPage.tableHeaders.actions"),
                                },
                            ]}
                            renderRowCells={(item, isSelected, selectItem, refresh) => {
                                const pl = item
                                const created = getFormattedDate(pl.created, "slashes")
                                const platformOrderIdFormatted = getPlatformOrderIdFormatted(pl.platformOrderId)
                                return [
                                    {
                                        className: "payment-links__table-cell payment-link-id-cell",
                                        content: platformOrderIdFormatted,
                                    },
                                    { className: "payment-links__table-cell", content: created },
                                    { className: "payment-links__table-cell", content: pl.currency },
                                    {
                                        className: "payment-links__table-cell",
                                        content: getFormattedNumber(pl.amount, { numberFormat: "forceTwoDecimals" }),
                                    },
                                    {
                                        className: "payment-links__table-cell",
                                        content: (
                                            <>
                                                {pl.customerFirstName && (
                                                    <>
                                                        {pl.customerFirstName}
                                                        <br />
                                                    </>
                                                )}
                                                {pl.customerEmail} <br />
                                                {pl.customerPhone}
                                            </>
                                        ),
                                    },
                                    {
                                        className: "payment-links__table-cell",
                                        content:
                                            pl.notifiedByEmail || pl.notifiedBySms
                                                ? [
                                                      pl.notifiedByEmail ? t("common.email.label") : undefined,
                                                      pl.notifiedBySms ? t("common.sms") : undefined,
                                                  ]
                                                      .filter((type) => !!type)
                                                      .join(", ")
                                                : "-",
                                    },
                                    {
                                        className: "payment-links__table-cell",
                                        content: getLanguageByISO(pl.language, getLanguage()),
                                    },
                                    {
                                        className: "payment-links__table-cell",
                                        content: (
                                            <div className="actions-table-container">
                                                {pl.hasTransactions ? (
                                                    <TextButton
                                                        text={t("paymentLinksHistoryPage.actions.goToOrder")}
                                                        textDecoration="underline"
                                                        onClick={async () =>
                                                            navigationService.navigate(`/orders?orderId=${pl.orderId}`)
                                                        }
                                                    />
                                                ) : (
                                                    <>
                                                        <StandardButtonWithSpinner
                                                            isSmall
                                                            inverted
                                                            onClick={async () => {
                                                                await delay(300)

                                                                copyToClipboardText(pl.url)
                                                            }}
                                                        >
                                                            {t("paymentLinksHistoryPage.actions.copyLink")}
                                                        </StandardButtonWithSpinner>

                                                        <StandardButtonWithSpinner
                                                            isSmall
                                                            invertedBlueBorder
                                                            onClick={async () => {
                                                                dispatch(
                                                                    addModalThunk({
                                                                        type: "paymentLinkSend",
                                                                        merchantDetails,
                                                                        paymentLinkData: {
                                                                            webshopId: selectedWebshopId,
                                                                            orderId: pl.orderId,
                                                                            platformOrderId: pl.platformOrderId,

                                                                            paymentLink: pl.url,

                                                                            customerLanguage: pl.language,
                                                                            customerEmail: pl.customerEmail,
                                                                            customerFirstName: pl.customerFirstName,

                                                                            customerPhone: pl.customerPhone,
                                                                        },
                                                                        enableSmsChannelByDefault:
                                                                            pl.notifiedBySms && !pl.notifiedByEmail,
                                                                        notificationSentCallback: () => {
                                                                            dispatch(
                                                                                addModalThunk({
                                                                                    type: "info",
                                                                                    text: t(
                                                                                        "paymentLinkPage.createdSuccess.paymentLinkSent"
                                                                                    ),
                                                                                    showCheckmark: true,
                                                                                    noBordersInside: true,
                                                                                    hideCloseButton: true,
                                                                                })
                                                                            )
                                                                        },
                                                                    })
                                                                )
                                                            }}
                                                        >
                                                            {pl.notifiedByEmail || pl.notifiedBySms
                                                                ? t("paymentLinksHistoryPage.actions.resend")
                                                                : t("paymentLinksHistoryPage.actions.send")}
                                                        </StandardButtonWithSpinner>
                                                    </>
                                                )}
                                            </div>
                                        ),
                                    },
                                ]
                            }}
                        />
                    </PageContainer>
                </PageWrapper>
            </div>
        </>
    )
}

export default PaymentLinkHistoryPage
