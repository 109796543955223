import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { StandardBox } from "swiipe.portal.shared"
import "./GoBackContainer.scss"

interface GoBackContainerProps {
    goBackText?: string
    onGoBack: () => void
    className?: string
    noBottomMargin?: boolean
}

const GoBackContainer = ({ onGoBack, goBackText, className, noBottomMargin }: GoBackContainerProps) => {
    const { t } = useTranslation()

    return (
        <>
            <StandardBox className={cn("goback-container", className, noBottomMargin && "goback-container__no-bot-margin ")}>
                <span
                    onClick={() => {
                        onGoBack()
                    }}
                >
                    {"< "}
                    {goBackText ? goBackText : t("common.goBack")}
                </span>
            </StandardBox>
        </>
    )
}

export default GoBackContainer
