import React from "react"
import cn from "classnames"
import "./FormHeaderLogoContainer.scss"

export interface IFormHeaderLogoContainer {
    mainLogo: IFormHeaderLogo
    additionalLogos?: IFormHeaderLogo[]
    columnAdditionalLogos?: boolean
}

export interface IFormHeaderLogo {
    src: string
    alt: string
    className?: string
    width: string
}

const FormHeaderLogoContainer = ({ mainLogo, additionalLogos, columnAdditionalLogos }: IFormHeaderLogoContainer) => {
    return (
        <div className={cn("form-head", { "form-head-logos mb-5": additionalLogos })}>
            <FormHeaderLogo
                src={mainLogo.src}
                className={cn("form-head--main-logo", "img-center", { "mb-5": !additionalLogos }, mainLogo.className)}
                alt={mainLogo.alt}
                width={mainLogo.width}
            />
            <div className={cn("additional-logos", { "additional-logos__column": columnAdditionalLogos })}>
                {additionalLogos && additionalLogos.map((logo) => FormHeaderLogo(logo))}
            </div>
        </div>
    )
}

const FormHeaderLogo = ({ src, alt, width: width, className }: IFormHeaderLogo) => {
    return <img key={src} src={src} alt={alt} className={cn("img-center", className)} style={{ width: width }} />
}

export default FormHeaderLogoContainer
