export type PaymentLinkDto = {
    platformOrderId: string
    orderId: string
    created: string
    currency: string
    amount: number

    customerEmail: string
    customerFirstName: string
    customerPhone: string

    notifiedBySms: boolean
    notifiedByEmail: boolean

    language: string

    url: string

    hasTransactions: string
}

export type PaymentLinksFilter = {
    sortByProperty?: SortPaymentLinksByPropType
    isOrderByDescending?: boolean
}

export enum SortPaymentLinksByPropType {
    Created = "Created",
    PlatformOrderId = "PlatformOrderId",
}
