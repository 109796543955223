import cn from "classnames"
import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    CleanHtml,
    FloatingLabelInput,
    PageContainer,
    PageWrapper,
    ShowErrorMessages,
    useSelector,
    valFuncRequired,
} from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import CancelService from "../../components/merchant/CancelService"
import GoBackContainer from "../../components/navigations/GoBackContainer"
import { SharedCancelContainer } from "../../components/servicesonboarding/SharedCancelContainer"
import ForbrugsforeningenLogo from "../../images/swCardLogos/forbrugsforeningen-card-logo.png"
import { getImage } from "../../services/imageLanguageService"
import { getMerchantContact } from "../../services/merchantService"
import { getProviderStatus } from "../../services/paymentService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { IMerchantProviderConfig, merchantServicesSelectors } from "../../store/reducers/merchantServicesReducer"
import {
    IForbrugsforeningenOnboardingData,
    forbrugsforeningenOnboardingThunk,
    getForbrugsforeningenOnboardingData,
    getOnboardingStatusThunk,
    setPiqOnboardingStatusThunk,
} from "../../store/thunks/swiipePaymentsThunks"
import { sendServiceCancellationEmailThunk } from "../../store/thunks/swiipeServicesThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import "./ForbrugsforeningenOnboardingPage.scss"

interface IForbrugsforeningenOnboardingPageProps {
    webshopIds?: string[]
    isEdit?: boolean
    onGoBack: () => void
    onOnboarded: () => void
}
interface IForbrugsforeningenOnboardingPageInnerProps extends IForbrugsforeningenOnboardingPageProps {
    merchantDetails: MixedMerchantDetails
    selectedWebshopId?: string
}

const ForbrugsforeningenOnboardingPage = (props: IForbrugsforeningenOnboardingPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const selectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)

    if (!merchantDetails || !merchantDetails.webshops.length) {
        return null
    }

    return (
        <ForbrugsforeningenOnboardingPageInner
            merchantDetails={merchantDetails}
            selectedWebshopId={selectedWebshopId}
            {...props}
        ></ForbrugsforeningenOnboardingPageInner>
    )
}

interface IFormModel {
    netsMerchantId: string
}

const ForbrugsforeningenOnboardingPageInner = ({
    merchantDetails,
    webshopIds,
    selectedWebshopId,
    isEdit,
    onGoBack,
    onOnboarded,
}: IForbrugsforeningenOnboardingPageInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [showCancelService, setShowCancelService] = useState(false)

    useEffect(() => {
        merchantDetails?.webshops.forEach(
            async (w) =>
                await dispatch(
                    getOnboardingStatusThunk(merchantDetails?.swMerchant.swMerchantId, w.webshopId, false, ["CreditCard"])
                )
        )
    }, [merchantDetails])

    useEffect(() => {
        if (isEdit && selectedWebshopId) {
            ;(async () => {
                const onboardingData = await dispatch(
                    getForbrugsforeningenOnboardingData(selectedWebshopId, merchantDetails.swMerchant.swMerchantId)
                )

                setValue("netsMerchantId", onboardingData.netsMerchantId)
            })()
        }
    }, [isEdit, selectedWebshopId])

    const onboardingStatuses = useSelector<StoreState, IMerchantProviderConfig | undefined>((state) =>
        merchantServicesSelectors.merchantStatuses(state, merchantDetails.swMerchant.swMerchantId)
    )

    const webshops = merchantDetails.webshops || []

    const { handleSubmit, register, setValue, formState } = useForm<IFormModel>()

    if (!webshopIds && !isEdit) {
        return null
    }

    const onSubmit: SubmitHandler<IFormModel> = async (data) => {
        try {
            const webshopids: string[] = webshopIds?.length ? webshopIds : webshops.map((w) => w.webshopId)
            const swMerchantContact = getMerchantContact(merchantDetails)

            const formData: IForbrugsforeningenOnboardingData = {
                swMerchantId: merchantDetails.swMerchant.swMerchantId,
                swMerchantEmail: swMerchantContact?.email || "",
                swMerchantName: merchantDetails.swMerchant.name,
                merchantContactName: swMerchantContact?.name || "",
                merchantContactPhone: swMerchantContact?.phone || "",
                webshopIds: webshopids.filter((wid) => {
                    const conf = onboardingStatuses ? onboardingStatuses[wid] : undefined
                    const providerStatus = getProviderStatus(conf?.gatewayProviders, "CreditCard", "ForbrugsforeningenEpay")
                    return !providerStatus || providerStatus === "ActionRequired"
                }),
                netsMerchantId: data.netsMerchantId,
            }

            await dispatch(forbrugsforeningenOnboardingThunk(formData))

            onOnboarded()
        } catch {
            //Catch to stop showing spinner
        }
    }

    return (
        <>
            {showCancelService && (
                <CancelService
                    serviceName={t("forbrugsforeningenonboarding.forbrugsforeningen")}
                    cancelText={t("cancelService.forbrugsforeningen.cancelText")}
                    customCancelledText={t("cancelService.forbrugsforeningen.cancelledText")}
                    hintText={t("cancelService.forbrugsforeningen.hintText")}
                    onCancel={async () => {
                        await dispatch(
                            setPiqOnboardingStatusThunk(merchantDetails.swMerchant.swMerchantId, [selectedWebshopId!], {
                                paymentType: "CreditCard",
                                providerName: "ForbrugsforeningenEpay",
                                providerType: "PspAndAquirer",
                                enabled: false,
                            })
                        )

                        await dispatch(
                            sendServiceCancellationEmailThunk(
                                merchantDetails.swMerchant.swMerchantId,
                                "GeneralCardPaymentsCancellationEmail",
                                t("serviceNames.forbrugsforeningen")
                            )
                        )
                    }}
                    onGoBack={() => setShowCancelService(false)}
                />
            )}
            <PageWrapper className={cn({ "d-none": showCancelService })}>
                {isEdit && <GoBackContainer goBackText={t("commononboardingtexts.backToAllService")} onGoBack={onGoBack} />}
                <PageContainer noHeader={isEdit} disableDefaultPaddingBottom>
                    <div className="forbrugsforeningen-onboarding-container">
                        <div className="forbrugsforeningen-form-head">
                            <img className="swiipe-payments" src={getImage("cardPaymentsLogo")} />
                            <img className="forbrugsforeningen" src={ForbrugsforeningenLogo} />
                        </div>

                        {!isEdit && (
                            <div className="forbrugsforeningen-text-1">{t("forbrugsforeningenonboarding.toactivate")}</div>
                        )}

                        {!isEdit && (
                            <CleanHtml
                                element="div"
                                className="forbrugsforeningen-text-2"
                                html={t("forbrugsforeningenonboarding.donthaveone")}
                            />
                        )}

                        <Form onSubmit={handleSubmit(onSubmit)} className="forbrugsforeningen-form">
                            <span className="netsMerchantId-id-text">{t("forbrugsforeningenonboarding.netsmerchantid")}</span>
                            <FloatingLabelInput
                                name="netsMerchantId"
                                innerRef={register(valFuncRequired(t("forbrugsforeningenonboarding.netsmerchantiderror")))}
                                placeholder=""
                                disabled={isEdit}
                            />

                            {!isEdit && (
                                <div className="forbrugsforeningen-onboarding-form mt-2">
                                    <ShowErrorMessages formState={formState} />

                                    <SubmitButton
                                        className="mt-4"
                                        dark
                                        noBorder
                                        noBorderRadius
                                        containerClass="submit-btn"
                                        formState={formState}
                                    >
                                        {t("commononboardingtexts.submit")}
                                    </SubmitButton>
                                </div>
                            )}
                        </Form>

                        {!isEdit && (
                            <div className="forbrugsforeningen-back-link mt-3">
                                <a
                                    onClick={() => {
                                        onGoBack()
                                    }}
                                >
                                    {t("commononboardingtexts.back")}
                                </a>
                            </div>
                        )}

                        {isEdit && (
                            <SharedCancelContainer
                                cancelText={t("forbrugsforeningenonboarding.cancel")}
                                onCancel={() => setShowCancelService(true)}
                            />
                        )}
                    </div>
                </PageContainer>
            </PageWrapper>
        </>
    )
}

export default ForbrugsforeningenOnboardingPage
