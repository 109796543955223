import createDOMPurify from "dompurify"
import { hasValue } from "./arrayUtil"
import { delay } from "./timeUtil"

const DOMPurify = createDOMPurify(window)

export function sanitizeData(data: string) {
    return DOMPurify.sanitize(data)
}

export function ensureIsInteger(input: string | number, defaultValue: number) {
    if (typeof input === "number") {
        return input
    }
    if (typeof input === "string") {
        const parsedInt = parseInt(input)
        return isNaN(parsedInt) ? defaultValue : parsedInt
    }
    return defaultValue
}

export function copyToClipboard(element: HTMLInputElement) {
    const isiOSDevice = navigator.userAgent.match(/ipad|iphone/i)

    if (isiOSDevice) {
        const editable = element.contentEditable
        const readOnly = element.readOnly

        element.contentEditable = "true"
        element.readOnly = false

        const range = document.createRange()
        range.selectNodeContents(element)

        const selection = window.getSelection()
        if (!selection) {
            return
        }
        selection.removeAllRanges()
        selection.addRange(range)

        element.setSelectionRange(0, 999999)
        element.contentEditable = editable
        element.readOnly = readOnly
    } else {
        element.select()
    }

    document.execCommand("copy")
}

export function copyToClipboardText(text: string) {
    const inputElement: HTMLInputElement = document.createElement("input")
    inputElement.value = text
    document.body.appendChild(inputElement)

    copyToClipboard(inputElement)

    document.body.removeChild(inputElement)
}

export function numberInputOnWheelPreventChange(e: React.WheelEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement
    target.blur()
    e.stopPropagation()
    delay(0).then(() => target.focus())
}

export function inputOnChangeForceCapitalLetters(
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    setValue: (name: string, value: any) => void
) {
    setValue(fieldName, e.target.value.toUpperCase())
}

export function isAnyOfKeysPressed(key: string, keyNames: string[]) {
    return keyNames.includes(key)
}

export const numberInputOnKeyDown = (props: {
    event: React.KeyboardEvent<HTMLInputElement>
    allowNegativeNumbers?: boolean
    isDecimal?: boolean
    maxDecimals?: number
}) => {
    const numberInputRestrictedKeys = [
        "e",
        "E",
        "+",
        props.allowNegativeNumbers ? undefined : "-",
        props.isDecimal ? undefined : ".",
        props.isDecimal ? undefined : ",",
    ].filter(hasValue)

    if (isAnyOfKeysPressed(props.event.key, numberInputRestrictedKeys)) {
        props.event.preventDefault()
    }

    if (!props.isDecimal) {
        return
    }

    if (isAnyOfKeysPressed(props.event.key, ["Backspace", "Delete", "ArrowLeft", "ArrowRight"])) {
        return
    }

    // CNE: TODO: This code prevents from adding before the decimal separator if number already has max decimals. Find a way to avoid this bug.

    // If already a decimal number with maxDecimals digits
    // after "dot or comma" prevent adding more digits
    // const decimalNumberRegex = new RegExp(`\\d{1,}[\\.,]\\d{${props.maxDecimals ? props.maxDecimals : 2}}`)
    // if (decimalNumberRegex.test(props.event.currentTarget.value)) {
    //     props.event.preventDefault()
    // }
}
