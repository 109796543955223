import React from "react"
import { useTranslation } from "react-i18next"
import {
    PageContainer,
    PageWrapper,
    SpinnerContainer,
    StandardBox,
    StandardButtonWithSpinner,
    useSelector,
} from "swiipe.portal.shared"
import { getFormattedDate } from "../../services/dateTimeService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { onboardingApplicationSelectors } from "../../store/reducers/onboardingApplicationReducer"
import { adminDownloadClearhausApplicationZipThunk, adminGetApplicationsListThunk } from "../../store/thunks/clearhausThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import "./DownloadOnboardingDataPage.scss"

const DownloadOnboardingDataPage = () => {
    useRequiredUserRelations([{ requiredType: "Partner", requiredMasterPartner: true }])

    const dispatch = useReduxDispatch()
    const { t } = useTranslation()

    dispatch(adminGetApplicationsListThunk())
    const applications = useSelector(onboardingApplicationSelectors.applications)

    return (
        <PageWrapper>
            <PageContainer id="download-onboarding-data-page" transparent>
                <p className="mb-2">{t("documentspage.applications")}</p>
                <SpinnerContainer showSpinner={!applications}>
                    {(applications ?? []).map((application) => {
                        const formattedDate = getFormattedDate(application.submitted, "dots")

                        return (
                            <StandardBox key={application.onboardingId}>
                                <div className="application-box">
                                    <span className="name">{application.companyName}</span>
                                    <span className="date">{formattedDate}</span>
                                    <StandardButtonWithSpinner
                                        isBlue
                                        noBorder
                                        noBorderRadius
                                        dark
                                        className="download-btn"
                                        onClick={async () => {
                                            await dispatch(
                                                adminDownloadClearhausApplicationZipThunk(
                                                    application.onboardingId,
                                                    application.companyName
                                                )
                                            )
                                        }}
                                    >
                                        {t("documentspage.download")}
                                    </StandardButtonWithSpinner>
                                </div>
                            </StandardBox>
                        )
                    })}
                </SpinnerContainer>
            </PageContainer>
        </PageWrapper>
    )
}

export default DownloadOnboardingDataPage
