import React, { useEffect, useMemo, useState } from "react"
import { Control, ErrorOption, FormState, SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import { FormFieldsSection, StandardButton, delay, getQueryValueFromCurrentUrl, getText } from "swiipe.portal.shared"
import { updateApplicationInfo } from "../../../store/thunks/clearhausThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IClearhausApplicationData } from "../../../type/clearhaus/IClearhausApplicationData"
import { IClearhausUpdateInfoForm } from "../../../type/clearhaus/IClearhausUpdateInfoForm"
import { IClearhausUpdateInfoRequiredFieldNames } from "../../../type/clearhaus/IClearhausUpdateInfoRequiredFieldNames"
import SubmitButton from "../../buttons/SubmitButton"
import { ClearhausOnboardingFooter } from "../../clearhaus/ClearhausOnboardingFooter"
import "./ClearhausUpdateInfoForm.scss"
import ClearhausUpdateBankAccountFields from "./updateInfoFieldsets/ClearhausUpdateBankAccountFields"
import ClearhausUpdateCompanyFields from "./updateInfoFieldsets/ClearhausUpdateCompanyFields"
import ClearhausUpdateDocumentsFields from "./updateInfoFieldsets/ClearhausUpdateDocumentsFields"
import ClearhausUpdateReasonFields from "./updateInfoFieldsets/ClearhausUpdateReasonFields"

interface IClearhausUpdateInfoFormProps {
    applicationData?: IClearhausApplicationData
    swMerchantId: string
    onGoBack: () => void
    onSubmitted: () => void
}

const ClearhausUpdateInfoForm = ({ applicationData, swMerchantId, onGoBack, onSubmitted }: IClearhausUpdateInfoFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const { control, formState, handleSubmit, trigger, setError, clearErrors } = useForm<IClearhausUpdateInfoForm>({
        mode: "onChange",
        reValidateMode: "onChange",
    })

    useEffect(() => {
        trigger()
    }, [applicationData])

    const [showErrors, setShowErrors] = useState(false)

    const commonFieldsSectionProps = {
        control,
        showErrors,
        formState,
        showValidationCheckmark: true,
    }

    const commonFieldsProps = {
        control,
        applicationData,
    }

    const validatedFieldsNames: IClearhausUpdateInfoRequiredFieldNames = useMemo(() => {
        return {
            company: ["company.current.name", "company.new.name", "company.current.cvr", "company.new.cvr"],
            reasonForChange: ["reasonForChange"],
            bank: ["bank.current.iban", "bank.new.iban", "bank.current.swiftCode", "bank.new.swiftCode"],
            confirmationDocument: ["confirmationDocument"],
        }
    }, [])

    // If none of ["company.new.name", "company.new.cvr", "bank.new.iban", "bank.new.swiftCode"]
    // are specified, add "please provide the new info" errors
    const onSubmitInvalid = useAnyNewInfoProvidedErrors(control, formState, trigger, clearErrors, setError)

    const onSubmit: SubmitHandler<IClearhausUpdateInfoForm> = async (data) => {
        await dispatch(
            updateApplicationInfo(
                swMerchantId,
                applicationData?.onboardingId || "",
                applicationData?.webshopIds || [getQueryValueFromCurrentUrl("webshopId")!],
                data
            )
        )
        onSubmitted()
    }

    return (
        <div className="clearhaus-update-info-form">
            <Form onSubmit={handleSubmit(onSubmit, onSubmitInvalid)}>
                <FormFieldsSection
                    className="clearhaus-update-info-form__company-section"
                    header={`1. ${t("clearhausUpdateInfo.sectionTitles.companyInfo")}`}
                    validatedFields={validatedFieldsNames.company}
                    {...commonFieldsSectionProps}
                    fieldsIndentLeft="smaller"
                    headerFont="smaller"
                >
                    <ClearhausUpdateCompanyFields {...commonFieldsProps} />
                </FormFieldsSection>
                <FormFieldsSection
                    header={`2. ${t("clearhausUpdateInfo.sectionTitles.reasonForChange")}`}
                    validatedFields={validatedFieldsNames.reasonForChange}
                    {...commonFieldsSectionProps}
                >
                    <ClearhausUpdateReasonFields {...commonFieldsProps} />
                </FormFieldsSection>
                <FormFieldsSection
                    className="clearhaus-update-info-form__bank-account-section"
                    header={`3. ${t("clearhausUpdateInfo.sectionTitles.bankAccount")}`}
                    validatedFields={validatedFieldsNames.bank}
                    {...commonFieldsSectionProps}
                    fieldsIndentLeft="smaller"
                >
                    <ClearhausUpdateBankAccountFields {...commonFieldsProps} />
                </FormFieldsSection>
                <FormFieldsSection
                    header={`4. ${t("clearhausUpdateInfo.sectionTitles.documentation")}`}
                    validatedFields={validatedFieldsNames.confirmationDocument}
                    {...commonFieldsSectionProps}
                >
                    <ClearhausUpdateDocumentsFields {...commonFieldsProps} setError={setError} clearErrors={clearErrors} />
                </FormFieldsSection>
                <ClearhausOnboardingFooter
                    left={
                        <StandardButton
                            className="clearhaus-update-info-form__go-back-button"
                            inverted
                            onClick={(e) => {
                                e.preventDefault()
                                onGoBack()
                            }}
                        >
                            {t("commononboardingtexts.goback")}
                        </StandardButton>
                    }
                    middle={
                        <SubmitButton
                            className="clearhaus-update-info-form__submit-button"
                            onClick={() => setShowErrors(true)}
                            formState={formState}
                            dark
                            isBlue
                            noBorder
                        >
                            {t("common.submit")}
                        </SubmitButton>
                    }
                />
            </Form>
        </div>
    )
}

const useAnyNewInfoProvidedErrors = (
    control: Control<IClearhausUpdateInfoForm>,
    formState: FormState<IClearhausUpdateInfoForm>,
    trigger: (name?: string) => Promise<boolean>,
    clearErrors: (name?: string) => void,
    setError: (name: string, error: ErrorOption) => void
) => {
    const [anyNewInfoProvided, setAnyNewInfoProvided] = useState(false)

    const fieldNamesToSetError = ["company.new.name", "bank.new.iban"]
    const watchedFieldNames = ["company.new.name", "company.new.cvr", "bank.new.iban", "bank.new.swiftCode"]

    useEffect(() => {
        const addErrors = () => {
            fieldNamesToSetError.forEach((fieldName) => {
                setError(fieldName, { message: getText("clearhausUpdateInfo.errors.pleaseProvideNewInfo") })
            })
        }

        if (anyNewInfoProvided) {
            fieldNamesToSetError.forEach((fieldName) => clearErrors(fieldName))
            fieldNamesToSetError.forEach((fieldName) => trigger(fieldName)) //in case other error is there
            return
        }
        const formInitialized = formState.isDirty
        if (!formInitialized) {
            const retrySetErrorsSeconds = [0.2, 0.5, 1, 2]
            retrySetErrorsSeconds.forEach((seconds) => delay(seconds * 1000).then(addErrors))
            return
        }
        addErrors()
    }, [anyNewInfoProvided])
    const fieldsWatch = useWatch({
        control,
        name: watchedFieldNames,
    })
    useEffect(() => {
        const values = control.getValues()
        const newCompanyInfoProvided = !!values.company.new.name || !!values.company.new.cvr
        const newBankInfoProvided = !!values.bank.new.iban || !!values.bank.new.swiftCode
        if (newCompanyInfoProvided || newBankInfoProvided) {
            setAnyNewInfoProvided(true)
            return
        }
        setAnyNewInfoProvided(false)
    }, [fieldsWatch])

    const onSubmitInvalid = () => {
        if (anyNewInfoProvided) {
            return
        }
        fieldNamesToSetError.forEach((fieldName) =>
            setError(fieldName, { message: getText("clearhausUpdateInfo.errors.pleaseProvideNewInfo") })
        )
    }

    return onSubmitInvalid
}

export default ClearhausUpdateInfoForm
