import { ActionsUnion, createAction } from "swiipe.portal.shared"
import { IGatewayProvider } from "../../type/IProvider"
import { StoreState } from "../StoreState"

export interface IPaymentIqMerchantData {
    piqMerchantId: string
    clientId: string
    clientSecret: string

    isExtraPiqMerchantEnabled: boolean
    extraPiqMerchantEnableThresholdAmountEUR: number
    extraPiqMerchantId: string
    extraPiqMerchantClientId: string
    extraPiqMerchantClientSecret: string
}

export interface IWebshopProviderConfig {
    gatewayProviders: IGatewayProvider[]

    isExtended: boolean

    piqMerchantId?: string
    piqMerchantClientId?: string
    piqMerchantClientSecret?: string

    isExtraPiqMerchantEnabled: boolean
    extraPiqMerchantEnableThresholdAmountEUR: number
    extraPiqMerchantId: string
    extraPiqMerchantClientId: string
    extraPiqMerchantClientSecret: string

    mobilePayExternalId: string
    mobilePayMcc: string
    mobilePayMerchantId: string
    mobilePayVatNumber: string
    mobilePayShopIconUrl: string
    mobilePayShopName: string
    netsDankortId: string
    netsForbrugsforeningenId: string
    swishId: string
    klarnaId: string
    klarnaKey: string
    vippsId: string
    applePayId: string
}

export interface IMerchantProviderConfig {
    [webshopId: string]: IWebshopProviderConfig
}

export type MerchantServicesState = Readonly<{
    statuses: {
        [merchantId: string]: IMerchantProviderConfig
    }
    // Temp hack to know if PIQ provider statuses was fetched. Currently statuses is a mix of PIQ specific data and general API data.
    // This needs to be cleaned up and separated
    piqStatusesFetched: { [webshopId: string]: boolean | undefined }
}>
const initialState: MerchantServicesState = { statuses: {}, piqStatusesFetched: {} }

export const merchantServicesReducerActions = {
    setWebshopServices: (merchantId: string, webshopId: string, status: IWebshopProviderConfig) =>
        createAction("APP/ADD_WEBSHOP_SERVICES", { merchantId, webshopId, status }),
    setGatewayProviders: (merchantId: string, webshopId: string, gatewayProviders: IGatewayProvider[]) =>
        createAction("APP/SET_GATEWAY_PROVIDERS", { merchantId, webshopId, gatewayProviders }),
    setPiqStatusesFetched: (webshopId: string) => createAction("APP/SET_PIQ_STATUSES_FETCHED", { webshopId }),
}

export type MerchantServicesActions = ActionsUnion<typeof merchantServicesReducerActions>

export const merchantServicesReducer = (state: MerchantServicesState = initialState, action: MerchantServicesActions) => {
    switch (action.type) {
        case "APP/ADD_WEBSHOP_SERVICES": {
            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    [action.merchantId]: {
                        ...state.statuses[action.merchantId],
                        [action.webshopId]: {
                            ...(state.statuses[action.merchantId]?.[action.webshopId] ?? {}),
                            ...action.status,
                            gatewayProviders: [
                                ...(state.statuses[action.merchantId]?.[action.webshopId]?.gatewayProviders.filter(
                                    (gp) =>
                                        !action.status.gatewayProviders.find(
                                            (newGp) =>
                                                newGp.paymentType === gp.paymentType && newGp.providerType === gp.providerType
                                        )
                                ) ?? []),
                                ...action.status.gatewayProviders,
                            ],
                        },
                    },
                },
            }
        }
        case "APP/SET_GATEWAY_PROVIDERS": {
            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    [action.merchantId]: {
                        ...state.statuses[action.merchantId],
                        [action.webshopId]: {
                            ...(state.statuses[action.merchantId]?.[action.webshopId] ?? {}),
                            gatewayProviders: [
                                ...(state.statuses[action.merchantId]?.[action.webshopId]?.gatewayProviders.filter(
                                    (gp) =>
                                        !action.gatewayProviders.find(
                                            (newGp) =>
                                                newGp.paymentType === gp.paymentType && newGp.providerType === gp.providerType
                                        )
                                ) ?? []),
                                ...action.gatewayProviders,
                            ],
                        },
                    },
                },
            }
        }
        case "APP/SET_PIQ_STATUSES_FETCHED": {
            return {
                ...state,
                piqStatusesFetched: {
                    ...state.piqStatusesFetched,
                    [action.webshopId]: true,
                },
            }
        }
    }

    return state
}

export const merchantServicesSelectors = {
    statuses: (state: StoreState, merchantId: string, webshopId: string) => {
        if (state.merchantServices.statuses[merchantId]) {
            return state.merchantServices.statuses[merchantId][webshopId]
        }
        return undefined
    },
    merchantStatuses: (state: StoreState, merchantId: string) => state.merchantServices.statuses[merchantId],
    isPiqStatusFetched: (state: StoreState, webshopId: string) => state.merchantServices.piqStatusesFetched[webshopId],
}
