import cn from "classnames"
import React from "react"
import "./StandardParagraph.scss"

interface IStandardParagraph {
    children?: React.ReactNode
    className?: string
    isSubTitle?: boolean
}

export const StandardParagraph = ({ children, className, isSubTitle }: IStandardParagraph) => {
    return <p className={cn("standard-paragraph", isSubTitle && "standard-paragraph--subtitle", className)}>{children}</p>
}
