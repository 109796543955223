import cn from "classnames"
import React, { useMemo } from "react"
import { TFunction, useTranslation } from "react-i18next"
import {
    IInvoicingPrice,
    IMerchantOnboardingServicesSubForm,
    StandardIcon,
    TCurrency,
    TInvoicingPriceCategory,
    TitleWithAbsoluteHint,
    TitleWithFoldableHint,
    WebshopDetails,
    getPlatformLogo,
    getPriceWithCurrency,
    monthlyPriceCategories,
    removeProtocolFromUrl,
    sumArray,
} from "swiipe.portal.shared"
import ImageA2A from "../../images/merchantOffer/merchant-offer-service-a2a.png"
import ImageApplePay from "../../images/merchantOffer/merchant-offer-service-apple-pay.png"
import ImageCardPayments from "../../images/merchantOffer/merchant-offer-service-card-payments.png"
import ImageCheckout from "../../images/merchantOffer/merchant-offer-service-checkout.png"
import ImageDankort from "../../images/merchantOffer/merchant-offer-service-dankort.png"
import ImageForbrugsforeningen from "../../images/merchantOffer/merchant-offer-service-forbrugsforeningen.png"
import ImageKlarna from "../../images/merchantOffer/merchant-offer-service-klarna.png"
import ImageMobilePay from "../../images/merchantOffer/merchant-offer-service-mobile-pay.png"
import ImagePlusSell from "../../images/merchantOffer/merchant-offer-service-plus-sell.png"
import ImageResurs from "../../images/merchantOffer/merchant-offer-service-resurs.png"
import ImageReturnSell from "../../images/merchantOffer/merchant-offer-service-return-sell.png"
import ImageSwish from "../../images/merchantOffer/merchant-offer-service-swish.png"
import ImageViaBill from "../../images/merchantOffer/merchant-offer-service-viabill.png"
import ImageWinBack from "../../images/merchantOffer/merchant-offer-service-win-back.png"
import { getMerchantOfferIncludesPsp } from "../../services/merchantOfferService"
import { getPriceWithCategory, getResolvedInvoicingPrice } from "../../services/priceService"
import "./MerchantOfferOrderSummary.scss"

interface IMerchantOfferOrderSummaryProps {
    className?: string
    prices: IInvoicingPrice[] | undefined
    services: IMerchantOnboardingServicesSubForm | undefined
    webshops: WebshopDetails[] | undefined
}

interface IMerchantOfferOrderItemPrice {
    category?: TInvoicingPriceCategory
    qty: number
    hint?: string
    title?: string
    amount?: number
}

interface IMerchantOfferOrderItem {
    isInBasePackage?: boolean
    translationsKey: string
    title?: string
    noTitle?: boolean
    image?: string
    sectionsWithBullets: number[]
    show: boolean
    prices: IMerchantOfferOrderItemPrice[][]
    renderOtherPrices?: React.ReactNode
}

const getOrderItems: (
    services: IMerchantOnboardingServicesSubForm,
    webshops: WebshopDetails[],
    t: TFunction<"translation", undefined>
) => IMerchantOfferOrderItem[] = (services, webshops, t) => {
    const partOfPspPrice: IMerchantOfferOrderItemPrice = {
        qty: 1,
        title: t("prices.other.partOfPSP"),
    }
    return [
        {
            isInBasePackage: true,
            translationsKey: "psp",
            noTitle: true,
            sectionsWithBullets: [2, 3],
            show: getMerchantOfferIncludesPsp(services),
            prices: [
                [
                    { category: "SwiipePspMonthly", qty: 1 },
                    { category: "SwiipePspExtraWebshopMonthly", qty: webshops.length - 1 },
                    { category: "SwiipePspTransaction", qty: 1 },
                ],
            ],
        },
        {
            isInBasePackage: true,
            translationsKey: "acquiringClearhaus",
            noTitle: true,
            sectionsWithBullets: [2, 0],
            show: !!services.useAcquiringClearhaus,
            prices: [
                [
                    {
                        amount: 0,
                        qty: 1,
                        title: t("prices.other.acquiringMonthlyFee"),
                    },
                    {
                        category: "AcquirerTransactionPct",
                        qty: 1,
                        title: t("prices.other.issuedCards"),
                    },
                    {
                        category: "AcquirerClearhaus3ds",
                        qty: 1,
                        hint: t("prices.comment.AcquirerClearhaus3ds"),
                    },

                    {
                        category: "AcquirerClearhausAuth",
                        qty: 1,
                        hint: t("prices.comment.AcquirerClearhausAuth"),
                    },
                ],
            ],
        },
        {
            translationsKey: "cardPayments",
            image: ImageCardPayments,
            sectionsWithBullets: [2],
            show: !!services.usePaymentsCreditCard,
            prices: [[partOfPspPrice]],
        },
        {
            translationsKey: "dankort",
            title: "Dankort",
            image: ImageDankort,
            sectionsWithBullets: [1],
            show: !!services.usePaymentsDankort,
            prices: [[partOfPspPrice]],
        },
        {
            translationsKey: "forbrugsforeningen",
            title: "Forbrugsforeningen",
            image: ImageForbrugsforeningen,
            sectionsWithBullets: [1],
            show: !!services.usePaymentsForbrugsforeningen,
            prices: [[partOfPspPrice]],
        },
        {
            translationsKey: "mobilePay",
            title: "MobilePay",
            image: ImageMobilePay,
            sectionsWithBullets: [1],
            show: !!services.usePaymentsMobilePayOnline,
            prices: [
                [
                    {
                        category: "MobilePayOnlineMonthly",
                        qty: 1,
                    },
                    {
                        category: "MobilePayOnlineTransactionStep1",
                        qty: 1,
                    },
                ],
            ],
        },
        {
            translationsKey: "klarna",
            title: "Klarna",
            image: ImageKlarna,
            sectionsWithBullets: [1],
            show: !!services.usePaymentsKlarna,
            prices: [
                [
                    {
                        category: "KlarnaTransaction",
                        qty: 1,
                    },
                ],
            ],
        },
        {
            translationsKey: "applePay",
            title: "Apple Pay",
            image: ImageApplePay,
            sectionsWithBullets: [1],
            show: !!services.usePaymentsApplePay,
            prices: [
                [
                    {
                        category: "ApplePayTransaction",
                        qty: 1,
                    },
                ],
            ],
        },
        {
            translationsKey: "viaBill",
            title: "ViaBill",
            image: ImageViaBill,
            sectionsWithBullets: [1],
            show: !!services.usePaymentsViaBill,
            prices: [[partOfPspPrice]],
        },
        {
            translationsKey: "swish",
            title: "Swish",
            image: ImageSwish,
            sectionsWithBullets: [1],
            show: !!services.usePaymentsSwish,
            prices: [
                [
                    {
                        category: "SwishTransaction",
                        qty: 1,
                    },
                ],
            ],
        },
        {
            translationsKey: "resurs",
            title: "Resurs",
            image: ImageResurs,
            sectionsWithBullets: [1],
            show: !!services.usePaymentsResurs,
            prices: [
                [
                    {
                        category: "ResursInvoicePctTransaction",
                        qty: 1,
                    },
                    {
                        category: "ResursPartPaymentPctTransaction",
                        qty: 1,
                    },
                    {
                        category: "ResursRevolvingCreditPctTransaction",
                        qty: 1,
                    },
                ],
            ],
        },
        {
            translationsKey: "checkout",
            image: ImageCheckout,
            sectionsWithBullets: [2],
            show: !!services.useFeatureCheckout,
            prices: [[getMerchantOfferIncludesPsp(services) ? partOfPspPrice : { category: "SwiipePspMonthly", qty: 1 }]],
        },
        {
            translationsKey: "winBack",
            title: "Win-back",
            image: ImageWinBack,
            sectionsWithBullets: [3],
            show: !!services.useFeatureWinBack,
            prices: [[{ category: "SwiipeWinbackPct", qty: 1, hint: t("prices.comment.SwiipeWinbackPct") }]],
        },
        {
            translationsKey: "plusSell",
            title: "Plus-sell",
            image: ImagePlusSell,
            sectionsWithBullets: [3],
            show: !!services.useFeaturePlusSell,
            prices: [[{ category: "SwiipePlusSellPct", qty: 1, hint: t("prices.comment.SwiipePlusSellPct") }]],
        },
        {
            translationsKey: "returnSell",
            title: "Return-sell",
            image: ImageReturnSell,
            sectionsWithBullets: [2],
            show: !!services.useFeatureReturnSell,
            prices: [
                [
                    { category: "SwiipeReturnSellMonthly", qty: 1 },
                    { category: "SwiipeReturnSellExtraWebshopMonthly", qty: 1 },
                    { category: "SwiipeReturnSellTransactionPct", qty: 1 },
                ],
            ],
        },

        {
            translationsKey: "a2a",
            title: "Account-to-account",
            image: ImageA2A,
            sectionsWithBullets: [],
            show: false,
            prices: [],
        },
    ]
}

export const MerchantOfferOrderSummary = ({ className, prices, services, webshops }: IMerchantOfferOrderSummaryProps) => {
    const { t } = useTranslation()

    const currency: TCurrency = !prices?.length ? "DKK" : prices[0].currency

    const orderItems = useMemo(() => (services && webshops ? getOrderItems(services, webshops, t) : []), [services, webshops])
    const orderTotalMonthly = useMemo(() => {
        return sumArray(
            orderItems
                .filter((oi) => oi.show)
                .map((oi) => oi.prices)
                .flat(2)
                .filter((p) => p.qty > 0 && p.category && monthlyPriceCategories.includes(p.category)),
            (p) => p.qty * (getPriceWithCategory(prices, p.category ?? "Custom")?.amount ?? 0)
        )
    }, [orderItems, prices])

    const renderItemRow = (item: IMerchantOfferOrderItem, index: number) => {
        const itemText = (textType: string) => {
            const key = `merchantOffer.services.${item.translationsKey}.${textType}`
            const translation = t(key)
            return translation !== key ? translation : ""
        }
        return (
            <React.Fragment key={index + (item.title ?? "")}>
                <div
                    className={cn(
                        "merchant-offer-order-summary__order-item-left",
                        index === 0 && "merchant-offer-order-summary__order-item-left--first",
                        item.noTitle && !item.image && "merchant-offer-order-summary__order-item-left--empty"
                    )}
                >
                    <div className="merchant-offer-order-summary__order-item-title">
                        {item.noTitle ? "" : item.title ?? itemText("title")}
                    </div>
                    {item.image && (
                        <div className="merchant-offer-order-summary__order-item-image">
                            <img src={item.image} />
                        </div>
                    )}
                </div>
                <div className="merchant-offer-order-summary__order-item-center">
                    {item.sectionsWithBullets.map((bulletCount, index) => {
                        return (
                            <React.Fragment key={index}>
                                {itemText(`section${index + 1}Title`) && (
                                    <div className="merchant-offer-order-summary__order-item-intro">
                                        {itemText(`section${index + 1}Title`)}
                                    </div>
                                )}
                                <div className="merchant-offer-order-summary__order-item-statements">
                                    {Array.from(Array(bulletCount)).map((_, bulletIndex) => (
                                        <div
                                            key={bulletIndex}
                                            className={cn(
                                                "merchant-offer-order-summary__order-item-statement",
                                                itemText(`section${index + 1}Title`) &&
                                                    "merchant-offer-order-summary__order-item-statement--has-title"
                                            )}
                                        >
                                            <StandardIcon
                                                className="merchant-offer-order-summary__order-item-statement-icon"
                                                standardType="checkmark"
                                                width={20}
                                                componentForegroundColor="#E06A2B"
                                            />

                                            <div className="merchant-offer-order-summary__order-item-statement-text">
                                                {itemText(`section${index + 1}Bullet${bulletIndex + 1}`)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </React.Fragment>
                        )
                    })}
                </div>
                <div className="merchant-offer-order-summary__order-item-right">
                    <div className="merchant-offer-order-summary__order-item-mobile-divider"></div>
                    <div className="merchant-offer-order-summary__order-item-prices">
                        {item.prices
                            .filter((pg) => pg.filter((p) => p.qty > 0).length > 0)
                            .map((priceGroup, groupIndex) => (
                                <div key={groupIndex} className="merchant-offer-order-summary__order-item-price-group">
                                    {priceGroup.map((price) => {
                                        return (
                                            <div key={price.category} className="merchant-offer-order-summary__order-item-price">
                                                <TitleWithAbsoluteHint
                                                    className={cn(
                                                        "merchant-offer-order-summary__order-item-price-name",
                                                        "merchant-offer-order-summary__order-item-price-name-desktop"
                                                    )}
                                                    titleClassName="merchant-offer-order-summary__order-item-price-name-title"
                                                    title={
                                                        (price.title ?? t("prices.categories." + price.category)) +
                                                        (price.category || typeof price.amount === "number" ? ":" : "")
                                                    }
                                                    hintIconRight
                                                    hintBoxWhite
                                                    iconType="default"
                                                    hintText={price.hint}
                                                    noIcon={!price.hint}
                                                    largeHint
                                                />
                                                <TitleWithFoldableHint
                                                    className={cn(
                                                        "merchant-offer-order-summary__order-item-price-name",
                                                        "merchant-offer-order-summary__order-item-price-name-mobile"
                                                    )}
                                                    titleClassName="merchant-offer-order-summary__order-item-price-name-title"
                                                    hint={price.hint ?? ""}
                                                    title={
                                                        (price.title ?? t("prices.categories." + price.category)) +
                                                        (price.category || typeof price.amount === "number" ? ":" : "")
                                                    }
                                                    icon="lightGray"
                                                    theme="white"
                                                    hintFontSize="16"
                                                />
                                                {(price.category || typeof price.amount === "number") && (
                                                    <div className="merchant-offer-order-summary__order-item-price-amount">
                                                        {price.category &&
                                                            getResolvedInvoicingPrice(prices, {
                                                                category: price.category,
                                                                numberFormat: "forceTwoDecimals",
                                                            })}
                                                        {typeof price.amount === "number" &&
                                                            getPriceWithCurrency(price.amount, currency, {
                                                                numberFormat: "forceTwoDecimals",
                                                            })}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            ))}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const serviceItems = orderItems.filter((oi) => !oi.isInBasePackage)
    const basePackageItems = orderItems.filter((oi) => oi.isInBasePackage)

    return (
        <div className={cn("merchant-offer-order-summary", className)}>
            <div className="merchant-offer-order-summary__title">{t("merchantOffer.order.title")}</div>
            <div className="merchant-offer-order-summary__divider"></div>
            <div className="merchant-offer-order-summary__webshops">
                {(webshops ?? []).map((w, index) => (
                    <React.Fragment key={w.webshopId}>
                        {index > 0 && <div className="merchant-offer-order-summary__divider-light"></div>}
                        <div className="merchant-offer-order-summary__webshop">
                            <div className="merchant-offer-order-summary__webshop-domain">
                                {removeProtocolFromUrl(w.hostName, true)}
                            </div>
                            <div className="merchant-offer-order-summary__webshop-platform">
                                <span>{t("common.platform")}:</span>
                                <span>
                                    <img className="platform-logo" src={getPlatformLogo(w.platform)} />
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <div className="merchant-offer-order-summary__order">
                <div className="merchant-offer-order-summary__order-header-left">
                    <span className="merchant-offer-order-summary__order-header-left-title-desktop">
                        {t("merchantOffer.order.service")}
                    </span>
                    <span className="merchant-offer-order-summary__order-header-left-title-mobile">
                        {t("merchantOffer.order.services")}
                    </span>
                </div>
                <div className="merchant-offer-order-summary__order-header-right">{t("merchantOffer.order.price")}</div>
                {serviceItems.filter((i) => i.show).map((item, index) => renderItemRow(item, index))}

                {basePackageItems.length > 0 && (
                    <>
                        <div className="merchant-offer-order-summary__order-header-left merchant-offer-order-summary__order-header-left--base">
                            {t("merchantOffer.order.basePackage")}
                        </div>
                        <div className="merchant-offer-order-summary__order-header-right merchant-offer-order-summary__order-header-right--base">
                            {t("merchantOffer.order.price")}
                        </div>
                        {basePackageItems.filter((i) => i.show).map((item, index) => renderItemRow(item, index))}
                        <div className="merchant-offer-order-summary__order-footer-base-left"></div>
                        <div className="merchant-offer-order-summary__order-footer-base-right"></div>
                    </>
                )}

                <div className="merchant-offer-order-summary__order-footer-left"></div>
                <div className="merchant-offer-order-summary__order-footer-right">
                    <div className="merchant-offer-order-summary__order-total-row">
                        <div className="merchant-offer-order-summary__order-total-title">
                            {t("merchantOffer.order.totalMonthly")}:
                        </div>
                        <div className="merchant-offer-order-summary__order-total-value">
                            {getPriceWithCurrency(orderTotalMonthly, currency, {
                                numberFormat: "forceTwoDecimals",
                            })}
                        </div>
                    </div>
                    <div className="merchant-offer-order-summary__order-total-extra-desc">
                        + {t("merchantOffer.order.transactionFees")}
                    </div>
                </div>
            </div>
        </div>
    )
}
