import { getLanguage } from "swiipe.portal.shared"

export const getFormattedDate = (date: string, type: TDateFormatType, useUtc = false, useCurrentLanguage = false) => {
    const d = new Date(date)

    const timeZone = { timeZone: useUtc ? "UTC" : undefined }

    const language = useCurrentLanguage ? getLanguage() : "en"

    const da = new Intl.DateTimeFormat(language, { day: "2-digit", ...timeZone }).format(d)
    const mo = new Intl.DateTimeFormat(language, { month: "2-digit", ...timeZone }).format(d)
    const moName = new Intl.DateTimeFormat(language, { month: "long", ...timeZone }).format(d)
    const ye = new Intl.DateTimeFormat(language, { year: "numeric", ...timeZone }).format(d)

    const hours = new Intl.DateTimeFormat(language, { hour: "2-digit", hour12: false, ...timeZone }).format(d)
    const hh = hours === "24" ? "00" : hours
    const mm = ("0" + new Intl.DateTimeFormat(language, { minute: "2-digit", ...timeZone }).format(d).toString()).slice(-2)

    switch (type) {
        case "dots":
            return `${da}.${mo}.${ye} ${hh}:${mm}`
        case "dots-only-date":
            return `${da}.${mo}.${ye}`
        case "slashes":
            return `${da}/${mo}/${ye} ${hh}:${mm}`
        case "slashes-month-year":
            return `${ye}-${mo}`
        case "comma-monthname-year":
            return `${ye}, ${moName}`
        case "dash-international":
            return `${ye}-${mo}-${da} ${hh}:${mm}`
        case "forfile":
            return `${da}.${mo}.${ye}`
        case "month-name-only":
            return `${moName}`
    }
}

export const getDateToLocalStringWithOptions = (
    date: string | number | Date,
    options?: Intl.DateTimeFormatOptions,
    addDays?: number
) => {
    return new Intl.DateTimeFormat(getLanguage(), options).format(new Date(date).addDays(addDays ?? 0))
}

export type TDateFormatType =
    | "dots"
    | "dots-only-date"
    | "slashes"
    | "forfile"
    | "dash-international"
    | "slashes-month-year"
    | "comma-monthname-year"
    | "month-name-only"

declare global {
    interface Date {
        addDays(days: number): Date
    }
}

Date.prototype.addDays = function (days: number) {
    const date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
}
