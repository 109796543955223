import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps } from "swiipe.portal.shared"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import CustomizePaymentLinkNotifications from "../merchant/paymentLink/CustomizePaymentLinkNotifications"
import "./ModalPaymentLinkCustomize.scss"

export interface IModalPaymentLinkCustomize extends IModalBase {
    type: "paymentLinkCustomize"
    merchantDetails: MixedMerchantDetails
    selectedWebshopId: string
}

const ModalPaymentLinkCustomize = ({
    merchantDetails,
    selectedWebshopId,
    callback,
    ...otherProps
}: IModalPaymentLinkCustomize & IModalControlProps) => {
    const { t } = useTranslation()
    return (
        <BaseModal
            {...otherProps}
            modalClassName="payment-link-customize-modal"
            callback={callback}
            size="pl-custom"
            centered
            showCloseButton
            noBordersInside
        >
            <ModalBody>
                <CustomizePaymentLinkNotifications
                    merchantDetails={merchantDetails}
                    selectedWebshopId={selectedWebshopId}
                    insideModal
                />
            </ModalBody>
        </BaseModal>
    )
}

export default ModalPaymentLinkCustomize
