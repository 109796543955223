import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk, sharedConfigurationSelectors } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { authService } from "../../services/authService"
import { IReleaseNote } from "../../type/releaseNotes/releaseNote"
import { StoreState } from "../StoreState"
import { partnerSelectors } from "../reducers/partnerReducer"
import { releaseNoteReducerActions, releaseNoteSelectors } from "../reducers/releaseNoteReducer"

export const fetchReleaseNotes =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentReleaseNotes = releaseNoteSelectors.releaseNotes(getState())

        if (currentReleaseNotes && !force) {
            return
        }

        const isMasterPartner = authService.getIsLoggedIn() && partnerSelectors.partnersContainMasterPartner(getState())
        const isSandbox = sharedConfigurationSelectors.environment(getState()) === "Sandbox"
        const releaseNotes = await dispatch(
            requestThunk<IReleaseNote[]>(
                isMasterPartner && !isSandbox ? endpoints.Content.getReleaseNotesInternal : endpoints.Content.getReleaseNotes
            )
        )

        dispatch(releaseNoteReducerActions.setReleaseNotes(releaseNotes))
    }
