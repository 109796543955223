import cn from "classnames"
import React from "react"
import useDimensions from "react-cool-dimensions"
import { sumArray } from "swiipe.portal.shared"
import { colorDarkBlue, colorDarkRed } from "../../util/colors"
import { splitIntoEqualParts } from "../../util/numberUtil"
import "./MerchantDashboardSmallGraph.scss"

interface IMerchantDashboardSmallGraphProps {
    className?: string
    values: number[]
    sections: number
    percent?: number
    isNegative?: boolean
}

const MerchantDashboardSmallGraph = ({ values, sections, isNegative, className }: IMerchantDashboardSmallGraphProps) => {
    const { observe, width, height } = useDimensions<HTMLDivElement>({})

    const sectionCount = Math.min(values.length, sections)
    const sectionSizes = splitIntoEqualParts(values.length, sectionCount) ?? []
    const avgValues: number[] = []
    let nextValueIndex = 0
    for (let i = 0; i < sectionSizes.length; i++) {
        const valueCountInSection = sectionSizes[i]
        const valuesInSection = values.slice(nextValueIndex, nextValueIndex + valueCountInSection)
        nextValueIndex += valueCountInSection
        avgValues.push(sumArray(valuesInSection, (v) => v) / valuesInSection.length)
    }
    const minValue = Math.min(...avgValues)
    const maxValue = Math.max(...avgValues)
    const range = maxValue - minValue
    const relativeValues = avgValues.map((v) => (v - minValue) / range)

    const color = isNegative ? colorDarkRed : colorDarkBlue

    const path =
        height <= 0 && width <= 0
            ? ""
            : relativeValues.reduce(
                  (res, val, index) =>
                      res +
                      ` ${index === 0 ? "M" : "L"}${(width / (relativeValues.length - 1)) * index} ${height - val * height}`,
                  ""
              )

    return (
        <div ref={observe} className={cn("merchant-dashboard-small-graph", className)}>
            <div className="graph">
                {height > 0 && width > 0 && (
                    <svg viewBox={`0 0 ${width} ${height}`}>
                        <defs>
                            <linearGradient
                                id={"grad1" + isNegative}
                                gradientUnits="userSpaceOnUse"
                                x1="50%"
                                y1="0%"
                                x2="50%"
                                y2="100%"
                            >
                                <stop offset="0%" stopColor={color} stopOpacity="20%" />
                                <stop offset="100%" stopColor={color} stopOpacity="0%" />
                            </linearGradient>
                        </defs>
                        <path fill={`url(#grad1${isNegative})`} strokeWidth={0} d={path + `L${width} ${height} L0 ${height} Z`} />
                        <path stroke={color} strokeWidth="2" fill="none" d={path} />
                    </svg>
                )}
            </div>
        </div>
    )
}

export default MerchantDashboardSmallGraph
