import cn from "classnames"
import Parser from "html-react-parser"
import React, { ReactNode } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import { ShowErrorMessages, SpinnerContainer, TextButton } from "swiipe.portal.shared"
import SubmitButton from "../../../../components/buttons/SubmitButton"
import FileInput from "../../../../components/form/input/FileInput"
import ApplePayOnboardingStepsContainer from "../../../../components/paymentsOnboarding/ApplePayOnboardingStepsContainer"
import { getImage } from "../../../../services/imageLanguageService"
import { IApplePayOnboardingData, applePayOnboardingThunk } from "../../../../store/thunks/applePayThunks"
import { useReduxDispatch } from "../../../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../../../type/mixedmerchantdetails"

interface ApplePayExistingCertsProps {
    merchantDetails: MixedMerchantDetails
    webshopId: string
    applePayId: string
    onNextStep: () => void
    onBackStep: () => Promise<void>
}

interface IFormModel {
    uploadedMerchantIdCertificate: File[]
    uploadedMerchantIdPrivateKey: File[]
    uploadedPayProcessPrivateKey: File[]
}

const ApplePayExistingCertsStep = ({
    merchantDetails,
    webshopId,
    applePayId,
    onNextStep,
    onBackStep,
}: ApplePayExistingCertsProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()

    const { handleSubmit, register, setValue, formState, trigger, setError, clearErrors, getValues } = useForm<IFormModel>()
    const onSubmit: SubmitHandler<IFormModel> = async (data) => {
        try {
            const formData: IApplePayOnboardingData = {
                applePayButtonLocation: "WebshopDomain",
                swMerchantId: merchantDetails.swMerchant.swMerchantId,
                swMerchantEmail: merchantDetails.swMerchantContacts[0]?.email,
                swMerchantName: merchantDetails.swMerchant.name,
                merchantContactName: merchantDetails.swMerchantContacts[0]?.name,
                merchantContactPhone: merchantDetails.swMerchantContacts[0]?.phone,
                webshopIds: [webshopId],
                applePayId: applePayId,
                merchantCertificateFile: data.uploadedMerchantIdCertificate[0],
                merchantCertificatePrivateKeyFile: data.uploadedMerchantIdPrivateKey[0],
                paymentProcessingPrivateKeyFile: data.uploadedPayProcessPrivateKey[0],
            }

            await dispatch(applePayOnboardingThunk(formData, false))
            onNextStep()
        } catch {
            //Catch to stop showing spinner
        }
    }

    return (
        <ApplePayOnboardingStepsContainer>
            <SpinnerContainer className="applepay-existing-cert-step" showSpinner={false}>
                <StepComponent>
                    <div className="applepay-form-head">
                        <img className="applepay-logo" src={getImage("applePayLogo")} />
                    </div>
                    <div className="applepay-summary">{Parser(t("applepayonboarding.description"))}</div>
                </StepComponent>

                <StepComponent label="1.">
                    <div className="applepay-grey-textbox">{Parser(t("applepayonboarding.uploadPayProcessPrivateKeyStep"))}</div>
                    <div className="applepay-upload-input">
                        <FileInput
                            name="uploadedPayProcessPrivateKey"
                            accept=".pem"
                            minFilesAmount={1}
                            maxFilesAmount={1}
                            triggerValidation={trigger}
                            setError={setError}
                            clearError={clearErrors}
                            setValue={setValue}
                            getValues={getValues}
                            register={register}
                            requiredErrorMessage={t("applepayonboarding.errorMessages.missingKey")}
                            multiple
                            slimButton
                        />
                    </div>
                    <div className="applepay-guidelink">{Parser(t("applepayonboarding.seeguide"))}</div>
                </StepComponent>

                <StepComponent label="2.">
                    <div className="applepay-grey-textbox">{Parser(t("applepayonboarding.uploadMerchantIdPrivateKeyStep"))}</div>
                    <div className="applepay-upload-input">
                        <FileInput
                            name="uploadedMerchantIdPrivateKey"
                            accept=".pem"
                            minFilesAmount={1}
                            maxFilesAmount={1}
                            triggerValidation={trigger}
                            setError={setError}
                            clearError={clearErrors}
                            setValue={setValue}
                            getValues={getValues}
                            register={register}
                            requiredErrorMessage={t("applepayonboarding.errorMessages.missingKey")}
                            multiple
                            slimButton
                        />
                    </div>
                    <div className="applepay-guidelink">{Parser(t("applepayonboarding.seeguide"))}</div>
                </StepComponent>

                <StepComponent label="3.">
                    <div className="applepay-grey-textbox">
                        {Parser(t("applepayonboarding.uploadMerchantIdCertificateExistStep"))}
                    </div>
                    <div className="applepay-upload-input">
                        <FileInput
                            name="uploadedMerchantIdCertificate"
                            accept=".cer"
                            minFilesAmount={1}
                            maxFilesAmount={1}
                            triggerValidation={trigger}
                            setError={setError}
                            clearError={clearErrors}
                            setValue={setValue}
                            getValues={getValues}
                            register={register}
                            requiredErrorMessage={t("applepayonboarding.errorMessages.missingCert")}
                            multiple
                            slimButton
                        />
                    </div>
                    <div className="applepay-guidelink">{Parser(t("applepayonboarding.seeguide"))}</div>
                </StepComponent>

                <StepComponent label="">
                    <Form onSubmit={handleSubmit(onSubmit)} className="applepay-submit-form">
                        <ShowErrorMessages formState={formState} />
                        <SubmitButton dark noBorder containerClass="submit-btn" formState={formState}>
                            {t("commononboardingtexts.submit")}
                        </SubmitButton>
                    </Form>
                    <TextButton text={t("commononboardingtexts.goback")} className="mt-4" onClick={onBackStep} />
                </StepComponent>
            </SpinnerContainer>
        </ApplePayOnboardingStepsContainer>
    )
}

const StepComponent = (props: { label?: string; children: ReactNode }) => {
    return (
        <section className={cn("step", { "step-centered": !props.label })}>
            {props.label && <div className="step-header">{props.label}</div>}
            <div className="step-content">{props.children}</div>
        </section>
    )
}

export default ApplePayExistingCertsStep
